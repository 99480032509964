import React, { Component } from 'react'
import PageSection from 'shared/components/PageSection'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import Text from 'shared/components/Text'
import Select from 'shared/components/Select'
import Page from 'shared/components/Page'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import { translatedFilterKeys } from 'shared/utils/data/defineMessages'
import camelCase from 'lodash.camelcase'
import startCase from 'lodash.startcase'
import Loading from 'shared/components/Loading'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import { List, RecordOf } from 'immutable'
import dateFormat from 'shared/utils/dateFormat'
import storageManager from 'shared/utils/storageManager'
import { CSSTransition } from 'react-transition-group'
import Hidden from '@material-ui/core/Hidden'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'
import Label from 'shared/components/Label'

type Vet = RecordOf<{
  companyName: string
  companyOrgUnit: string
  key: string
  lastUpdate: Date
  name: string
  status: string
  statusDate: string
  submitResponseBy: Date
}>

type Props = {
  vets: List<Vet>
  onClickVet: (vetId: string) => void
  isLoading?: boolean
  intl: IntlShape
}

type State = {
  vets?: List<Vet>
  filter: string
  filteredVets?: List<Vet>
  doNotShowSupplierVetDescription: boolean
  sortDirection: 'ASC' | 'DESC'
  sortBy: string
}

function sortVets(sortBy, sortDirection, vets) {
  return (
    vets &&
    vets.sort((vetA, vetB) => {
      const valueA =
        typeof vetA.get(sortBy) === 'string'
          ? vetA.get(sortBy).toLowerCase()
          : vetA.get(sortBy)
      const valueB =
        typeof vetB.get(sortBy) === 'string'
          ? vetB.get(sortBy).toLowerCase()
          : vetB.get(sortBy)

      if (sortDirection === 'ASC') {
        return valueA < valueB ? -1 : 1
      } else {
        return valueA > valueB ? -1 : 1
      }
    })
  )
}

export class MyVets extends Component<Props, State> {
  state: State = {
    vets: undefined,
    filter: '',
    filteredVets: undefined,
    doNotShowSupplierVetDescription: !!storageManager.getItem(
      'doNotShowSupplierVetDescription'
    ),
    sortDirection: 'DESC',
    sortBy: 'lastUpdate'
  }

  static getDerivedStateFromProps(props, state) {
    if (state.vets !== props.vets) {
      return {
        vets: props.vets,
        filteredVets: sortVets(state.sortBy, state.sortDirection, props.vets)
      }
    }
    return null
  }

  getRow = ({ index }) => {
    const row =
      this.state.filteredVets && this.state.filteredVets.size > 0
        ? this.state.filteredVets.get(index)
        : this.props.vets.get(index)

    return (
      row &&
      row.set(
        'status',
        translatedFilterKeys.collaborationStatus[camelCase(row.get('status'))]
          ? this.props.intl.formatMessage(
              translatedFilterKeys.collaborationStatus[
                camelCase(row.get('status'))
              ]
            )
          : startCase(row.get('status'))
      )
    )
  }

  handleRowClick = ({ index }) => {
    const { onClickVet } = this.props
    const vet =
      this.state.filteredVets && this.state.filteredVets.size > 0
        ? this.state.filteredVets.get(index)
        : this.props.vets.get(index)
    if (vet) {
      onClickVet(vet.get('key'))
    }
  }

  handleFilterChange = e => {
    const filter = e.target.value
    const { vets } = this.props
    const { sortBy, sortDirection } = this.state

    let filteredVets
    if (filter === '') {
      filteredVets = vets
    } else if (filter === 'Active') {
      filteredVets = vets.filter(vet => {
        return (
          vet.get('status') !== 'Chosen' &&
          vet.get('status') !== 'Not Chosen' &&
          vet.get('status') !== 'AcceptedWithCondition' &&
          vet.get('status') !== 'NotAccepted' &&
          vet.get('status') !== 'Cancelled'
        )
      })
    } else if (filter === 'Inactive') {
      filteredVets = vets.filter(vet => {
        return (
          vet.get('status') === 'Chosen' ||
          vet.get('status') === 'Not Chosen' ||
          vet.get('status') === 'AcceptedWithCondition' ||
          vet.get('status') === 'NotAccepted' ||
          vet.get('status') === 'Cancelled'
        )
      })
    }
    this.setState({
      filter: filter,
      filteredVets: sortVets(sortBy, sortDirection, filteredVets)
    })
  }

  handleClickCloseDescription = () => {
    storageManager.setItem('doNotShowSupplierVetDescription', true)
    this.setState({
      doNotShowSupplierVetDescription: true
    })
  }

  handleSortChange = ({ sortBy, sortDirection }) => {
    const { filteredVets } = this.state
    const newVets = sortVets(sortBy, sortDirection, filteredVets)

    this.setState({
      sortBy,
      sortDirection,
      filteredVets: newVets
    })
  }

  handleClickOpenDescription = () => {
    storageManager.setItem('doNotShowSupplierVetDescription', false)
    this.setState({
      doNotShowSupplierVetDescription: false
    })
  }

  render() {
    const { isLoading } = this.props
    let {
      filteredVets,
      filter,
      doNotShowSupplierVetDescription,
      sortDirection,
      sortBy
    } = this.state

    return (!filteredVets || filteredVets.size === 0) && isLoading ? (
      <Loading />
    ) : (
      <Page
        title={<FormattedMessage id='MyVets.MyRFIs' defaultMessage='RFI' />}
      >
        <ScrollToPosition />
        <CSSTransition
          in={!doNotShowSupplierVetDescription}
          timeout={300}
          classNames='tealbotHelp'
          unmountOnExit
        >
          <div className='bg-white br1 ba b--black-10 flex'>
            <div className='flex-auto'>
              <Text className='pt3 ph3'>
                <FormattedMessage
                  id='supplier.MyVets.whatAreRFIs1'
                  defaultMessage={`RFIs are opportunities where an existing or potential client
                      wants to clarify your interest and ability to deliver on a set of
                      requirements. You will be notified when you are invited to an RFI and
                      will be able to:`}
                />
              </Text>

              <ul>
                <li>
                  <Text>
                    <FormattedMessage
                      id='supplier.MyVets.reviewRequests'
                      defaultMessage='review the request in minutes'
                    />
                  </Text>
                </li>
                <li>
                  <Text>
                    <FormattedMessage
                      id='supplier.MyVets.indicateInterests'
                      defaultMessage='indicate your interest and capability'
                    />
                  </Text>
                </li>
                <li>
                  <Text>
                    <FormattedMessage
                      id='supplier.MyVets.profileResponse'
                      defaultMessage='provide a detailed response and/or pricing when requested'
                    />
                  </Text>
                </li>
                <li>
                  <Text>
                    <FormattedMessage
                      id='supplier.MyVets.findOutIfSelected'
                      defaultMessage='find out if you were selected or will be invited to the next stage of the procurement process.'
                    />
                  </Text>
                </li>
              </ul>
            </div>

            <Hidden smDown>
              <div className='ml3 w4 self-center'>
                <img src={TealbotCircleImage} alt='tealbot-tip' />
              </div>
            </Hidden>

            <div className='pa2'>
              <IconButton
                onClick={this.handleClickCloseDescription}
                style={{
                  width: 32,
                  height: 32
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </CSSTransition>

        <PageSection
          title={
            <FormattedMessage id='MyVets.MyRFIsTitle' defaultMessage='RFI' />
          }
          actions={
            <div className='flex items-center'>
              <Label htmlFor='sortvets' className='items-center'>
                <FormattedMessage
                  id='MyVets.SortBy'
                  defaultMessage='Sort by:'
                />
                &nbsp;
              </Label>
              <Select name='sortvets' onChange={this.handleFilterChange}>
                <FormattedMessage id='MyVets.AllOption' defaultMessage='All'>
                  {message => <option value=''>{message}</option>}
                </FormattedMessage>

                <FormattedMessage
                  id='MyVets.ActiveOption'
                  defaultMessage='Active'
                >
                  {message => <option value='Active'>{message}</option>}
                </FormattedMessage>

                <FormattedMessage
                  id='MyVets.InactiveOption'
                  defaultMessage='Inactive'
                >
                  {message => <option value='Inactive'>{message}</option>}
                </FormattedMessage>
              </Select>

              <CSSTransition
                in={doNotShowSupplierVetDescription}
                timeout={100}
                classNames='tealbotHelp'
                unmountOnExit
              >
                <TealbotHelpIcon
                  show={doNotShowSupplierVetDescription}
                  imageClassName='mh2 w2 dim pointer'
                  onClick={this.handleClickOpenDescription}
                />
              </CSSTransition>
            </div>
          }
          noPadding
        >
          {filteredVets && filteredVets.size > 0 ? (
            <Table
              minWidth={990}
              rowGetter={this.getRow}
              rowCount={filteredVets.size}
              onRowClick={this.handleRowClick}
              rowClassName='pointer dim'
              sort={this.handleSortChange}
              sortDirection={sortDirection}
              sortBy={sortBy}
            >
              <Column
                label={
                  <FormattedMessage
                    id='MyVets.ProjectNameLabel'
                    defaultMessage='Project Name'
                  />
                }
                dataKey='name'
                width={200}
                cellRenderer={({ cellData }) =>
                  cellData ? (
                    cellData
                  ) : (
                    <FormattedMessage
                      id='MyVets.Untitled'
                      defaultMessage='Untitled'
                    />
                  )
                }
              />
              <Column
                label={
                  <FormattedMessage
                    id='MyVets.LastUpdate'
                    defaultMessage='Last Update'
                  />
                }
                dataKey={'lastUpdate'}
                width={200}
                cellRenderer={({ cellData }) => dateFormat(cellData)}
              />
              <Column
                label={
                  <FormattedMessage
                    id='MyVets.ResponseByLabel'
                    defaultMessage='Response By'
                  />
                }
                dataKey={'submitResponseBy'}
                width={200}
                cellRenderer={({ cellData }) =>
                  cellData ? (
                    dateFormat(cellData)
                  ) : (
                    <FormattedMessage
                      id='MyVets.Unspecified'
                      defaultMessage='Unspecified'
                    />
                  )
                }
              />
              <Column
                label={
                  <FormattedMessage
                    id='MyVets.CompanyLabel'
                    defaultMessage='Company'
                  />
                }
                dataKey={'companyName'}
                width={200}
              />
              <Column
                label={
                  <FormattedMessage
                    id='MyVets.StatusLabel'
                    defaultMessage='Status'
                  />
                }
                dataKey={'status'}
                width={100}
              />
            </Table>
          ) : (
            <div className='pa4'>
              <Text>
                <FormattedMessage
                  id='MyVets.RFIDefaultMessage'
                  defaultMessage={'There are no {filter} RFIs'}
                  values={{
                    filter
                  }}
                />
              </Text>
            </div>
          )}
        </PageSection>
      </Page>
    )
  }
}

export default injectIntl(MyVets)
