import DiversitySection from '../../components/DiversitySection'
import companySelectors from '../../../shared/selectors/companySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { connect } from 'react-redux'
import { removeCertificate } from '../../actions'
import RootState from 'shared/models/RootState'

export default connect(
  (state: RootState) => ({
    certifications: companySelectors.getCertifications(state),
    isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
    isOrgUnitFrozen: state.getIn(['supplier', 'company', 'data', 'isFrozen']),
  }),
  {
    onRemoveCertificate: removeCertificate,
  }
)(DiversitySection)
