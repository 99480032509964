import React from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { Column, TableCellProps, TableRowProps } from 'react-virtualized'
import { defaultTableRowRenderer } from 'react-virtualized'

import Button from 'shared/components/Button'
import Divider from 'shared/components/Divider'
import Loading from 'shared/components/Loading'
import Label from '../../../shared/components/Label'
import {
  SapFileUploadHistoryRowData,
  FileUploadStatus,
  FileType
} from './types'
import Table from 'shared/components/Table'
import Tooltip from 'shared/components/Tooltip'
import ActionInfoOutline from '@material-ui/icons/InfoOutlined'

type Props = {
  data: Array<SapFileUploadHistoryRowData>
  onDownloadButtonClick: (uploadId: string, fileType: FileType) => void
  isLoading?: boolean
}

export const tableStyles = {
  [FileUploadStatus.FILE_PROCESSING_ERROR]: {
    cell: {
      color: 'rgba(233,74,51, 1)',
      display: 'flex'
    },
    row: { background: 'rgba(233,74,51, 0.07)' },
    icon: { marginRight: '4px' }
  },
  [FileUploadStatus.COMPLETE]: {
    cell: {
      color: 'rgb(2, 131, 131)'
    }
  }
}

const SapFileUploadHistory = ({
  data,
  onDownloadButtonClick,
  isLoading = false
}: Props) => {
  const getRow = ({ index }) => data && data[index]

  const dateCellRenderer = ({ cellData }: TableCellProps) =>
    moment(cellData).format('YYYY-MM-DD')

  const statusCellRenderer = ({ cellData, rowData }: TableCellProps) => {
    const statusText = {
      [FileUploadStatus.PROCESSING]: 'Processing',
      [FileUploadStatus.COMPLETE]: 'Complete',
      [FileUploadStatus.FILE_PROCESSING_ERROR]: 'File Error'
    }

    return (
      <span style={tableStyles[cellData]?.cell}>
        {cellData === FileUploadStatus.FILE_PROCESSING_ERROR && (
          <Tooltip
            placement='bottom-end'
            title={
              <>
                <p>
                  <strong>File Upload Error:</strong>
                </p>
                <p>{rowData?.errorMessage}</p>
              </>
            }
            arrow={true}
          >
            <ActionInfoOutline
              width='15'
              style={tableStyles[cellData]?.icon}
              titleAccess='error icon'
            />
          </Tooltip>
        )}{' '}
        <span>{statusText[cellData]}</span>
      </span>
    )
  }

  const downloadButtonCellRenderer = ({
    cellData,
    rowData
  }: TableCellProps) => {
    if (rowData?.status === FileUploadStatus.FILE_PROCESSING_ERROR) {
      return (
        <span style={tableStyles[rowData.status]?.cell}>
          Fix errors and re-upload file.
        </span>
      )
    }

    if (
      rowData?.fileType === 'Supplier' &&
      rowData?.status === FileUploadStatus.COMPLETE &&
      cellData
    ) {
      return (
        <Button
          onClick={() => {
            onDownloadButtonClick(cellData, rowData.fileType)
          }}
          fullWidth={false}
        >
          Download
        </Button>
      )
    }

    return <></>
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rowRenderer = (props: TableRowProps) => {
    const { rowData } = props
    let style

    if (rowData?.length === 0) {
      return <></>
    }

    if (rowData?.status) {
      style = tableStyles[rowData.status]?.row
    }

    return defaultTableRowRenderer({ ...props, style })
  }

  return (
    <>
      <Divider />
      <h2>Upload History</h2>
      <Table rowCount={data.length} rowGetter={getRow} rowHeight={60}>
        <Column label='File Name' dataKey='fileName' width={160} />
        <Column
          label='Upload Date'
          dataKey='uploadDate'
          cellRenderer={dateCellRenderer}
          width={100}
        />
        <Column label='Uploaded By' dataKey='uploadedBy' width={175} />
        <Column label='File Type' dataKey='fileType' width={100} />
        <Column
          label='Status'
          dataKey='status'
          cellRenderer={statusCellRenderer}
          width={100}
        />
        <Column
          dataKey='uploadId'
          width={175}
          cellRenderer={downloadButtonCellRenderer}
        />
      </Table>
      <div className='tc gray'>
        {isLoading && <Loading />}
        {!isLoading && data.length === 0 && (
          <Label>
            <FormattedMessage
              id='SAPLoader.noHistory'
              defaultMessage='There are no uploaded files'
            />
          </Label>
        )}
      </div>
    </>
  )
}

export default SapFileUploadHistory
