import { put, call, all, select, takeLeading } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify, requestSuccess } from 'shared/actions'
import { requestSuccess as requestSuccessActionType } from 'shared/actionTypes'
import { LOGIN } from 'accounts/actionTypes'
import user from 'shared/utils/api/user'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import storageManager from 'shared/utils/storageManager'
import auth from 'shared/utils/api/auth'
import { BECOME_TEALBOT } from '../../actionTypes'
import analytics from 'shared/utils/analytics'

export function* logoutBeforeTealbot(action: any) {
  // get org to login to info
  const { id, name, onetimeKey, reason } = action.payload
  const partnerConcierge = yield select(sessionSelectors.userHasRole, 'partner')

  // get the tokens for the tealbot user, if this goes through we are good to login
  const tokens = yield call(user.loginAsTealbot, id, onetimeKey, reason)

  yield put(
    notify({
      message: `Taking over tealbot at "${name}"`
    })
  )

  const tealbotAccessToken = storageManager.getItem('accessToken') || ''
  const tealbotRefreshToken = storageManager.getItem('refreshToken') || ''
  const urlBeforeBecomingTealbot = window.location.pathname

  // trigger a logout - this clears the current user tokens and the session
  yield call(auth.logout)

  // this saves the current user tokens into a separate key
  storageManager.setItem('tealbotAccessToken', tealbotAccessToken)
  storageManager.setItem('tealbotRefreshToken', tealbotRefreshToken)
  storageManager.setItem('tealbotUrl', urlBeforeBecomingTealbot)

  // store the new tokens in storage and trigger a login
  storageManager.setItem('accessToken', tokens.access)
  storageManager.setItem('refreshToken', tokens.refresh)
  return {
    partnerConcierge
  }
}

function* login(action) {
  const { partnerConcierge } = action?.payload
  const { userInfo, orgUnit } = yield call(user.get)
  analytics.identifyUser(userInfo, orgUnit)
  analytics.track('Signed In')
  yield put(
    requestSuccess(LOGIN, {
      userInfo,
      partnerConcierge,
      orgUnit
    })
  )
}
export default function* becomeTealbotSaga() {
  yield all([
    takeLeading(BECOME_TEALBOT, createApiRequestSaga(logoutBeforeTealbot)),
    takeLeading(
      requestSuccessActionType(BECOME_TEALBOT),
      createApiRequestSaga(login)
    )
  ])
}
