import React, { useState, ChangeEvent, useMemo } from 'react'
import Page from 'shared/components/Page'
import Button from 'shared/components/Button'
import Card from 'shared/components/Card'
import Text from 'shared/components/Text'
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import api from 'shared/utils/api'
import { apiRoute } from 'shared/utils/api/job/job'
import jobsSelectors from '../../selectors/jobsSelectors'
import exportData from 'shared/utils/exportData'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import { useDispatch } from 'react-redux'
import { bulkDeleteJobResults } from '../../actions'
import CloseIcon from '@material-ui/icons/Close'
import { RecordOf } from 'immutable'
import Job from 'shared/models/Job'
import startCase from 'lodash.startcase'
import Select from 'shared/components/Select'
import Label from 'shared/components/Label'
import Loading from 'shared/components/Loading'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DialogTitle from 'shared/components/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import { CategoryPills } from '../JobSow/SowHeader'
import SowSideOrg from '../JobSow/SowSideOrg'
import Tooltip from 'shared/components/Tooltip'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

const msToHMS = (ms: number) => {
  const hour = Math.trunc(moment.duration(ms).asHours())
  const min = moment.duration(ms).minutes()
  const sec = moment.duration(ms).seconds()
  const hh = !!hour ? `${hour} hour` : ''
  const mm = !!min ? `${min} min` : ''
  const ss = !!sec ? `${sec} sec` : ''
  return `${hh} ${mm} ${ss}`
}

const parseAnswersToOrgUnit = (answers: {
  [key: string]: string | string[] | object[]
}) => {
  let orgUnit: { [key: string]: any } = {
    supplier: {}
  }

  Object.keys(answers).forEach(key => {
    switch (key) {
      case 'name':
      case 'longDescription':
      case 'description':
        orgUnit[key] = answers[key]
        break
      case 'websiteUrl':
        orgUnit.supplier.links = { [key]: answers[key] }
        break
      case 'phoneNumber':
        orgUnit.supplier.phones = { office: [answers[key]] }
        break
      case 'naics':
        orgUnit.supplier.classificationCodes = (answers[key] as string[]).map(
          naics => {
            return {
              schema: 'NAICS',
              code: naics,
              source: 'SME'
            }
          }
        )
        break
      case 'employeeNumber':
      case 'yearlyRevenue':
      case 'yearFounded':
      case 'parentOrgName':
        // Org entity properties
        if (orgUnit.org) {
          orgUnit.org[key] = answers[key]
        } else {
          orgUnit.org = { [key]: answers[key] }
        }
        break
      default:
        orgUnit.supplier[key] = answers[key]
        break
    }
  })

  return orgUnit
}

const JobsResults = () => {
  const completeJobs = useSelector(jobsSelectors.getAllCompleteJobs)
  const loading = useSelector(jobsSelectors.isLoading)

  const dispatch = useDispatch()

  const [removeJobId, setRemoveJobId] = useState<string>('')
  const [filter, setFilter] = useState<'hil' | 'oneToOne' | 'oneToMany'>('hil')
  const [filterByUser, setFilterByUser] = useState<string>('')
  const [filterByOrgUnit, setFilterByOrgUnit] = useState<string>('')
  const [previewData, setPreviewData] = useState<Array<any> | undefined>([])
  const [previewQueryString, setPreviewQueryString] = useState<string>('')
  const [previewOpen, setPreviewOpen] = useState<boolean>(false)
  const [selectedOrgUnits, setSelectedOrgUnits] = useState<string[]>([])

  const tableData = useMemo(() => {
    return completeJobs
      ?.filter(
        job =>
          job.get('type') === filter &&
          (!filterByUser || filterByUser === job.getIn(['modified', 'user'])) &&
          (!filterByOrgUnit || filterByOrgUnit === job.get('orgUnitId'))
      )
      .sort((j1, j2) =>
        new Date(j2.getIn(['created', 'date'])) >
        new Date(j1.getIn(['created', 'date']))
          ? 1
          : -1
      )
      .map(job => {
        const completedBy = job.getIn(['modified', 'user'])
        const user = job.getIn(['expanded', 'User', completedBy])
        const start = job.get('startDate')
          ? moment(job.get('startDate'))
          : moment(job.getIn(['created', 'date']))
        const end = moment(job.getIn(['modified', 'date']))

        const modifiedOrg =
          job.getIn(['metadata', 'modifiedOrgUnit'])?.size || 0
        const modifiedAttr =
          job
            .getIn(['metadata', 'modifiedOrgUnit'])
            ?.toList()
            .reduce((total, value) => total + value, 0) || 0

        return job
          .set('timeSpent', end.diff(start))
          .set(
            'completedBy',
            `${user?.get('firstName')} ${user?.get('lastName')}`
          )
          .set('modifiedOrg', modifiedOrg)
          .set('modifiedAttr', modifiedAttr)
      })
  }, [completeJobs, filter, filterByUser, filterByOrgUnit])

  const totalTimeSpent = useMemo(() => {
    return tableData.reduce(
      (total, job) => total + (job.get('timeSpent') || 0),
      0
    )
  }, [tableData])

  const orgList = useMemo(() => {
    const orgUnits = completeJobs.map(job => ({
      orgUnitId: job.get('orgUnitId'),
      orgUnitName: job.get('orgUnitName')
    }))

    return orgUnits
      .filter(
        (org, index) =>
          org.orgUnitId &&
          orgUnits.findIndex(
            matchOrg => matchOrg.orgUnitId === org.orgUnitId
          ) === index
      )
      .toJS()
  }, [completeJobs])

  const userList = useMemo(() => {
    const userMap = completeJobs.reduce((result, job) => {
      result = Object.assign(
        {},
        result,
        job.getIn(['expanded', 'User'])?.toJS()
      )
      return result
    }, {})
    return Object.values(userMap)
      .map(({ id, firstName, lastName, email }) => ({
        id,
        firstName,
        lastName,
        email
      }))
      .sort((u1, u2) => {
        return `${u2.firstName} ${u2.lastName}`.toUpperCase() >
          `${u1.firstName} ${u1.lastName}`.toUpperCase()
          ? -1
          : 1
      })
  }, [completeJobs])

  const downloadReport = () => {
    const data: { [key: string]: string }[] = tableData?.toJS().map(job => {
      let additionalData: { [key: string]: string } = {}
      if (filter === 'hil') {
        additionalData.modifiedOrg = job.modifiedOrg
        additionalData.modifiedAttr = job.modifiedAttr
      }
      return {
        name: job.name,
        type: job.type?.toUpperCase(),
        completedBy: job.completedBy,
        timeSpent: msToHMS(job.timeSpent || 0),
        ...additionalData
      }
    })

    data.push({})
    data.push({
      timeSpent: `Total Time Spent: ${msToHMS(totalTimeSpent || 0)}`
    })

    exportData.exportCSV(data, 'report')
  }

  const downloadResults = async (job: RecordOf<Job>) => {
    const { id, name, type, queryString, created, modified } = job?.toJS()
    let cleanResponse
    if (type === 'hil') {
      await api.get(`${apiRoute}/hilResults/${id}`).then(response => {
        const mapChanges = response?.newList.reduce((orgUnitMap, item) => {
          orgUnitMap[item.orgUnitId] = Object.keys(item.answers)
          return orgUnitMap
        }, {})

        cleanResponse = {
          name,
          queryString,
          created,
          modified,
          originalList: response?.originalList.map((org, index) => {
            // only return the properties that have changed
            const orgChanges: string[] = mapChanges[org.orgUnitId]

            let cleanOriginalOrgUnit: { [key: string]: any } = {}
            cleanOriginalOrgUnit.rowNum = index
            cleanOriginalOrgUnit.orgUnitId = org.orgUnitId
            orgChanges.forEach(key => {
              cleanOriginalOrgUnit[key] = org[key] || ''
            })

            return cleanOriginalOrgUnit
          }),
          newList: response?.newList.map(
            ({ parents, expanded, siblings, ...restResponse }) => restResponse
          )
        }
      })
    } else {
      await api.get(`${apiRoute}/results/${id}`).then(response => {
        cleanResponse = response?.map(
          ({ parents, expanded, siblings, ...restResponse }) => restResponse
        )
      })
    }

    exportData.exportJsonString(cleanResponse, `${name}-${id}-results`)
  }

  const downloadOrgForLoader = () => {
    if (previewData) {
      const orgMap = previewData.reduce((map, org) => {
        map[org.orgUnitId] = org
        return map
      }, {})

      const data = selectedOrgUnits.map(orgUnitId => {
        const org = orgMap[orgUnitId]

        return Object.assign(
          {},
          { domains: org.domains, name: org.name },
          parseAnswersToOrgUnit(org.answers)
        )
      })

      exportData.exportJsonString(data, `loadFile`)
    }
  }

  const handleRemoveResults = (jobId: string) => {
    dispatch(bulkDeleteJobResults(jobId))
    setRemoveJobId('')
  }

  const handlePreviewResult = async (job: RecordOf<Job>) => {
    const { id, queryString } = job?.toJS()
    let data
    // get the result
    await api.get(`${apiRoute}/hilResults/${id}`).then(response => {
      const originalMap = response?.originalList.reduce(
        (reduction, org, index) => {
          reduction[org.orgUnitId] = {
            info: {
              oldRowNum: index,
              name: org.name,
              logoUrl: org.logoUrl,
              domains: org.domains,
              manualAdded: org.manualAdded
            },
            oldValues: org
          }
          return reduction
        },
        {}
      )
      // consolidate the both original list and new list into a preview data list
      data = response?.newList.map(
        ({ parents, expanded, siblings, ...restResponse }) => {
          const org = originalMap && originalMap[restResponse?.orgUnitId]

          let newOrg
          if (!org) {
            let domain
            try {
              const url = new URL(restResponse.answers.websiteUrl)
              domain = url.hostname
            } catch (e) {
              console.error(e)
            }
            newOrg = {
              name: restResponse.answers.name,
              domains: domain && [domain],
              isNew: true
            }
          } else {
            // eliminate existing values from the original for serviceAreas, offerings, naics, locations
            Object.keys(restResponse.answers).forEach(key => {
              switch (key) {
                case 'serviceAreas':
                case 'offerings':
                case 'naics':
                  restResponse.answers[key] = restResponse.answers[key].filter(
                    v => !org.oldValues[key].includes(v)
                  )
                  break
                case 'locations':
                  restResponse.answers[key] = restResponse.answers[key].filter(
                    v => {
                      const index = org.oldValues[key]?.findIndex(
                        loc => loc.address === v.address
                      )
                      return (
                        v.name ||
                        v.phone ||
                        v.type ||
                        v.primary === true ||
                        index === -1
                      )
                    }
                  )
                  break
                default:
                  break
              }
            })
          }
          return Object.assign({}, newOrg, restResponse, org?.info)
        }
      )
      setPreviewData(data)
      setPreviewQueryString(queryString || '')
      setPreviewOpen(true)
      setSelectedOrgUnits([])
    })
  }

  const handleOrgCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSelectedOrgUnits = e.currentTarget.checked
      ? [...selectedOrgUnits, e.currentTarget.value]
      : selectedOrgUnits.filter(org => org !== e.currentTarget.value)

    setSelectedOrgUnits(newSelectedOrgUnits)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Page title='Loader'>
      <Card>
        <div className='flex justify-between items-center mb3'>
          <div className='flex items-center'>
            <Label className='db f7 fw6 mr2' htmlFor='filter'>
              <FormattedMessage
                id='JobResults.FilterByType'
                defaultMessage={'Filter by Type'}
              />
            </Label>
            <Select
              name='filter'
              value={filter}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setFilter(
                  e.currentTarget.value as 'hil' | 'oneToOne' | 'oneToMany'
                )
              }
            >
              <option value='hil'>Human in the Loop Job</option>
              <option value='oneToMany'>Click Worker Job</option>
              <option value='oneToOne'>Survey Job</option>
            </Select>
            <Label className='db f7 fw6 mr2 ml4' htmlFor='filterByOrgUnit'>
              <FormattedMessage
                id='JobResults.FilterByOrgUnit'
                defaultMessage={'Filter by Org'}
              />
            </Label>
            <Select
              name='filterByOrgUnit'
              value={filterByOrgUnit}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setFilterByOrgUnit(e.currentTarget.value)
              }
            >
              <option value=''>All</option>
              {orgList.map(org => (
                <option key={org.orgUnitId} value={org.orgUnitId}>
                  {org.orgUnitName}
                </option>
              ))}
            </Select>
            <Label className='db f7 fw6 mr2 ml4' htmlFor='filterByUser'>
              <FormattedMessage
                id='JobsResults.FilterByUser'
                defaultMessage={'Filter by User'}
              />
            </Label>
            <Select
              name='filterByUser'
              value={filterByUser}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setFilterByUser(e.currentTarget.value)
              }
            >
              <option value=''>All</option>
              {userList.map(user => (
                <option
                  key={user.id}
                  value={user.id}
                >{`${user.firstName} ${user.lastName}`}</option>
              ))}
            </Select>
          </div>
          <Button onClick={downloadReport}>Download Report</Button>
        </div>
        {tableData.size > 0 && (
          <div className='bg-white ba br b--black-10'>
            <Table size='small' aria-label='Jobs Results'>
              <TableHead>
                <TableRow>
                  <TableCell>Job Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Completed By</TableCell>
                  <TableCell>Time Spent</TableCell>
                  {filter === 'hil' && (
                    <>
                      <TableCell style={{ width: 60 }}>Suppliers</TableCell>
                      <TableCell style={{ width: 60 }}>Attributes</TableCell>
                    </>
                  )}
                  <TableCell style={{ width: 150 }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map(job => (
                  <TableRow key={job.get('id')}>
                    <TableCell>
                      <Text>{job.get('name')}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{startCase(job.get('type'))?.toUpperCase()}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{job.get('completedBy')}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>{msToHMS(job.get('timeSpent') || 0)}</Text>
                    </TableCell>
                    {filter === 'hil' && (
                      <>
                        <TableCell>{job.get('modifiedOrg')}</TableCell>
                        <TableCell>{job.get('modifiedAttr')}</TableCell>
                      </>
                    )}
                    <TableCell style={{ width: 150 }}>
                      <div className='flex items-center justify-between'>
                        <Button
                          className='mr1'
                          size='small'
                          autoSize
                          onClick={() => downloadResults(job)}
                        >
                          Download
                        </Button>
                        {job.get('type') === 'hil' && (
                          <Tooltip title='Preview'>
                            <VisibilityIcon
                              aria-label='preview'
                              className='dim pointer'
                              onClick={() => handlePreviewResult(job)}
                            />
                          </Tooltip>
                        )}
                        <Tooltip title='Delete'>
                          <CloseIcon
                            aria-label='delete'
                            className='dim pointer'
                            onClick={() => setRemoveJobId(job.get('id'))}
                          />
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Text className='fw6 pv2'>Total Time Spent</Text>
                  </TableCell>
                  <TableCell>
                    <Text className='fw6 pv2'>{msToHMS(totalTimeSpent)}</Text>
                  </TableCell>
                  {filter === 'hil' && (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                  <TableCell style={{ width: 150 }}></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </Card>
      <ConfirmationDialog
        open={!!removeJobId}
        onClose={() => setRemoveJobId('')}
        onConfirm={() => handleRemoveResults(removeJobId)}
      >
        <Text>Are you sure you want to remove this result?</Text>
      </ConfirmationDialog>
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        fullWidth
      >
        <DialogTitle onClose={() => setPreviewOpen(false)}>
          Preview Result
        </DialogTitle>
        <DialogContent>
          <div>
            <CategoryPills queryString={previewQueryString} />
          </div>
          <div>
            {previewData
              ?.filter(org => !org.rejectReason)
              .map(org => {
                const changes = Object.keys(org.answers)
                return (
                  <div key={org.orgUnitId} className='flex'>
                    <input
                      type='checkbox'
                      className='mt4 mr2'
                      value={org.orgUnitId}
                      onChange={handleOrgCheckboxChange}
                      checked={selectedOrgUnits.includes(org.orgUnitId)}
                    />
                    <div className='flex-auto'>
                      <SowSideOrg
                        orgUnitId={org.orgUnitId}
                        isSelected={false}
                        isDisabled={false}
                        name={org.name}
                        logoUrl={
                          org.logoUrl ||
                          (org.domains
                            ? `https://logo.clearbit.com/${org.domains[0]}`
                            : '')
                        }
                        oldRowNum={org.oldRowNum}
                        rowNum={org.rowNum}
                        hideHandle
                      >
                        {(org.isNew || org.manualAdded) && (
                          <div className='mt1 fw6 f7 lh-copy'>
                            {org.isNew && 'New Supplier Suggested'}
                            {org.manualAdded && 'Manually Added'}
                          </div>
                        )}
                        {changes.length > 0 && (
                          <Text className='mt1'>
                            <strong>Changes:</strong>&nbsp;
                            {Object.keys(org.answers).join(', ')}
                          </Text>
                        )}
                      </SowSideOrg>
                    </div>
                  </div>
                )
              })}
          </div>
          <hr />
          <div>
            {previewData
              ?.filter(org => !!org.rejectReason)
              .map(org => {
                return (
                  <SowSideOrg
                    key={org.orgUnitId}
                    orgUnitId={org.orgUnitId}
                    isSelected={false}
                    isDisabled={false}
                    name={org.name}
                    logoUrl={
                      org.logoUrl ||
                      (org.domains
                        ? `https://logo.clearbit.com/${org.domains[0]}`
                        : '')
                    }
                    hideHandle
                  >
                    <Text className='mt1'>
                      <strong>Deprioritized Reason:</strong>&nbsp;
                      {org.rejectReason}
                    </Text>
                  </SowSideOrg>
                )
              })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={selectedOrgUnits.length === 0}
            onClick={downloadOrgForLoader}
          >
            Download Selected OrgUnit for Loader
          </Button>
          <Button secondary onClick={() => setPreviewOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default JobsResults
