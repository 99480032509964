import React, { ReactNode, useState, useMemo } from 'react'
import Page from 'shared/components/Page'
import Button from 'shared/components/Button'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Divider from 'shared/components/Divider'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'

type Props = {
  onDropIndex: () => void
  onReIndexSuppliers: () => void
  onReIndexUsers: () => void
  onAddUserMapping: () => void
  onBackupDatastore: () => void
  onFlushCache: (flushKey: string) => void
  onRunPatch: (patchBody: string) => void
  onReIndexSomeSuppliers: (supplierIds: string[]) => void
  onUpdateMapping: (mapping: object) => void
  inProduction?: boolean
}

type DialogProps = {
  type?:
    | 'dropIndex'
    | 'flushCache'
    | 'reindexSuppliers'
    | 'reindexUsers'
    | 'addUserMapping'
    | 'backupDatastore'
    | 'patch'
    | 'reindexSomeSuppliers'
    | 'updateMapping'
  title?: string
  content?: ReactNode
  buttonLabel?: string
}

const IndexingPage = (props: Props) => {
  const [flushKey, setFlushKey] = useState<string>('daily.*')
  const [patchBody, setPatchBody] = useState<string>('')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [selectedDialog, setSelectedDialog] = useState<DialogProps>({})
  const [mapping, setMapping] = useState<string>(
    `{
  "properties": {
    "sustainabilitySurveyCompleted": {
      "type": "boolean"
    }
  }
}`
  )
  const [supplierIds, setSupplierIds] = useState<string>('')

  const validMapping = useMemo(() => {
    try {
      JSON.parse(mapping)
      return true
    } catch (err) {
      return false
    }
  }, [mapping])

  const handleOpenDialog = dialog => {
    setOpenDialog(true)
    setSelectedDialog(dialog)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleClickDialogAction = () => {
    const {
      onDropIndex,
      onReIndexSuppliers,
      onReIndexUsers,
      onAddUserMapping,
      onBackupDatastore,
      onFlushCache
    } = props

    if (selectedDialog.type === 'dropIndex') {
      onDropIndex()
    } else if (selectedDialog.type === 'reindexSuppliers') {
      onReIndexSuppliers()
    } else if (selectedDialog.type === 'reindexUsers') {
      onReIndexUsers()
    } else if (selectedDialog.type === 'addUserMapping') {
      onAddUserMapping()
    } else if (selectedDialog.type === 'backupDatastore') {
      onBackupDatastore()
    } else if (selectedDialog.type === 'flushCache') {
      onFlushCache(flushKey)
    } else if (selectedDialog.type === 'patch') {
      props.onRunPatch(patchBody)
    } else if (selectedDialog.type === 'reindexSomeSuppliers') {
      props.onReIndexSomeSuppliers(
        supplierIds.split(',').map(s => s.trim())
      )
      setSupplierIds('')
    } else if (selectedDialog.type === 'updateMapping') {
      props.onUpdateMapping(JSON.parse(mapping))
      setMapping('')
    }

    handleCloseDialog()
  }

  const renderSection = (dialog: DialogProps) => {
    return (
      <Grid item xs={12} sm={6} key={dialog.type}>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleOpenDialog(dialog)
          }}
        >
          <Card className='h-100'>
            <CardContent>
              <Typography variant='h5' gutterBottom>
                {dialog.title}
              </Typography>
              <div style={{ minHeight: 50 }}>
                <Typography>{dialog.content}</Typography>
              </div>
              {dialog.type === 'flushCache' && (
                <>
                  <Label className='db mb1 f7 fw6'>Flush Key</Label>
                  <Input
                    value={flushKey}
                    onChange={e =>
                      setFlushKey(e.currentTarget.value)
                    }
                  />
                </>
              )}
              {dialog.type === 'patch' && (
                <>
                  <Label className='db mb1 f7 fw6'>Name of Patch (path)</Label>
                  <Input
                    required
                    value={patchBody}
                    onChange={e =>
                      setPatchBody(e.currentTarget.value)
                    }
                  />
                </>
              )}
              {dialog.type === 'updateMapping' && (
                <>
                  <Label className='db mb1 f7 fw6'>Update Mapping</Label>
                  <TextArea
                    required
                    value={mapping}
                    onChange={e =>
                      setMapping(e.currentTarget.value)
                    }
                  />
                </>
              )}
              {dialog.type === 'reindexSomeSuppliers' && (
                <>
                  <Label className='db mb1 f7 fw6'>Supplier Ids (comma separated)</Label>
                  <TextArea
                    required
                    value={supplierIds}
                    onChange={e =>
                      setSupplierIds(e.currentTarget.value)
                    }
                  />
                </>
              )}
            </CardContent>
            <CardActions style={{ paddingBottom: 12 }}>
              <Button type='submit' size='large' disabled={dialog.type === 'updateMapping' && !validMapping}>
                {dialog.buttonLabel}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Grid>
    )
  }

    const { inProduction } = props

    return (
      <Page title='Indexing'>
        <Typography variant='h6' gutterBottom>
          Suppliers
        </Typography>
        <Grid container spacing={2} className='pb3'>
          {renderSection({
            type: 'dropIndex',
            title: 'Drop Index',
            content:
              'This will drop all of the suppliers indexed in Elastic search. Please proceed with caution.',
            buttonLabel: 'Drop Index'
          })}

          {renderSection({
            type: 'reindexSuppliers',
            title: 'Re-Index Suppliers',
            content:
              'This will re-index all of the suppliers indexed in Elastic search. Please proceed with caution.',
            buttonLabel: 'Re-index all Suppliers'
          })}

          {renderSection({
            type: 'updateMapping',
            title: 'Update Mapping',
            content:
              'This will update existing mapping in Elastic search. Please proceed with caution.',
            buttonLabel: 'Update Mapping'
          })}

          {renderSection({
            type: 'reindexSomeSuppliers',
            title: 'Re-Index Given Suppliers',
            content:
              'This will re-index a list of the suppliers indexed in Elastic search. Please proceed with caution.',
            buttonLabel: 'Re-index the Suppliers'
          })}
        </Grid>

        <Typography variant='h6' gutterBottom>
          Users
        </Typography>
        <Grid container spacing={2} className='pb3'>
          {renderSection({
            type: 'addUserMapping',
            title: 'Drop User Index',
            content:
              'This will drop all of the users indexed in Elastic search. Please proceed with caution.',
            buttonLabel: 'Drop User Index'
          })}

          {renderSection({
            type: 'reindexUsers',
            title: 'Re-Index Users',
            content:
              'This will re-index all of the users indexed in Elastic search. Please proceed with caution.',
            buttonLabel: 'Re-index all Users'
          })}
        </Grid>

        <Typography variant='h6' gutterBottom>
          Server
        </Typography>
        <Grid container spacing={2}>
          {renderSection({
            type: 'flushCache',
            title: 'Flush The Cache',
            content:
              'This will flush the cache on server. Please proceed with caution.',
            buttonLabel: 'Flush The Cache'
          })}

          {renderSection({
            type: 'patch',
            title: 'Patch',
            content: 'This will run a patch.',
            buttonLabel: 'Run the Patch'
          })}
        </Grid>

        {inProduction && (
          <>
            <Divider className='mv4 ' />
            <Typography variant='h6' gutterBottom>
              Server
            </Typography>
            <Grid container spacing={2}>
              {renderSection({
                type: 'backupDatastore',
                title: 'Backup',
                content:
                  'Make a backup of the production datastore and deploy in BigQuery and Development system.',
                buttonLabel: 'Backup'
              })}
            </Grid>
          </>
        )}

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{selectedDialog.title}</DialogTitle>
          <DialogContent className='mt3'>
            {selectedDialog.content}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickDialogAction}>
              Confirm
            </Button>
            <Button secondary onClick={handleCloseDialog}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    )
}

export default IndexingPage
