export default function deepEqual(obj1, obj2) {
  // Check if both objects are null or undefined
  if (obj1 === obj2) {
    return true
  }

  // Check if either object is null or undefined
  if (obj1 == null || obj2 == null) {
    return false
  }

  // Check if the types of the objects are different
  if (typeof obj1 !== typeof obj2) {
    return false
  }

  // If the objects are primitive types, compare their values
  if (typeof obj1 !== 'object') {
    return obj1 === obj2
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    return arraysHaveSameValues(obj1, obj2)
  }

  // Get the keys of the objects
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if the number of keys are the same
  if (keys1.length !== keys2.length) {
    return false
  }

  // Check the values of each key recursively
  for (const key of keys1) {
    if (!deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  // If all checks pass, the objects are considered equal
  return true
}

function arraysHaveSameValues(arr1: string[], arr2: string[]): boolean {
  return (
    arr1.every(element => arr2.includes(element)) &&
    arr2.every(element => arr1.includes(element))
  )
}
