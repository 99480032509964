import React, { ReactElement, useState } from 'react'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import surveySelectors from '../../selectors/surveySelectors'
import BannerBadge from './BannerBadge'
import { getCurrentPosAndNextPathname, getPrevPathname } from './EsgNav'
import { useLocation, useHistory } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import paths from '../../../routes/paths'
import RootState from 'shared/models/RootState'
import Button from 'shared/components/Button'
import EditIcon from '@material-ui/icons/Edit'
import Link from 'shared/components/Link'
import PeopleIcon from '@material-ui/icons/People'
import { editSurvey, openCollaborateInviteDialog } from '../../actions'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { CSSTransition } from 'react-transition-group'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'
import CollaborationFaq from './CollaborationFaq'

const messages = defineMessages({
  needHelp: {
    id: 'SurveyBanner.NeedHelp',
    defaultMessage: 'Need help?'
  }
})

type Props = {
  title: string | ReactElement<FormattedMessage>
  showCloseButton: boolean
  showInstructions: boolean
  visitedPages: Object
}

const SurveyBanner = (props: Props) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const intl = useIntl()
  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const [isCollaborationFaqOpen, setIsCollaborationFaqOpen] = useState<boolean>(
    false
  )
  const [faqAnchorEl, setFaqAnchorEl] = useState<HTMLElement | null>(null)
  const attestedDate = useSelector((state: RootState) => {
    const survey = surveySelectors.getSurveyById(state, surveyId)
    return survey?.get('attestedDate')
  })
  const healthSafetyStarted = useSelector(surveySelectors.isHealthSafetyStarted)
  const healthSafetyCompleted = useSelector(
    surveySelectors.isHealthSafetyCompleted
  )
  const environmentStarted = useSelector(surveySelectors.isEnvironmentStarted)
  const environmentCompleted = useSelector(
    surveySelectors.isEnvironmentCompleted
  )
  const diversityInclusionStarted = useSelector(
    surveySelectors.isDiversityInclusionStarted
  )
  const diversityInclusionCompleted = useSelector(
    surveySelectors.isDiversityInclusionCompleted
  )
  const governanceStarted = useSelector(surveySelectors.isGovernanceStarted)
  const governanceCompleted = useSelector(surveySelectors.isGovernanceCompleted)
  const privacyStarted = useSelector(surveySelectors.isPrivacyStarted)
  const privacyCompleted = useSelector(surveySelectors.isPrivacyCompleted)

  const [currentPos, nextPathname] = getCurrentPosAndNextPathname(
    location.pathname
  )
  const prevPathname = getPrevPathname(location.pathname)

  const handleClose = () => {
    history.push(paths.company)
  }

  const handleEditClick = () => {
    dispatch(editSurvey(surveyId))
  }

  const handleAddCollaborators = () => {
    dispatch(openCollaborateInviteDialog())
  }

  const handleClickArrowForward = () => {
    history.push({
      pathname: nextPathname,
      state: { ...props.visitedPages, [location.pathname]: true }
    })
  }

  const handleClickArrowBack = () => {
    history.push({
      pathname: prevPathname,
      state: { ...props.visitedPages, [location.pathname]: true }
    })
  }
  const toggleCollaborationFaq = e => {
    setIsCollaborationFaqOpen(!isCollaborationFaqOpen)
    setFaqAnchorEl(e ? e.currentTarget : null)
  }
  return (
    <>
      <div className='flex items-center justify-between mt4 mb2'>
        <h1 className='f5 fw4 f3-ns fw6-ns mb3 mb4-ns'>{props.title}</h1>
        <div className='flex items-center'>
          {!attestedDate && (
            <Button
              autoSize
              onClick={handleAddCollaborators}
              startIcon={<PeopleIcon />}
            >
              <FormattedMessage
                id='AddCollaborators.Button'
                defaultMessage='Add Collaborators'
              />
            </Button>
          )}
          <CSSTransition
            in={!attestedDate}
            timeout={300}
            classNames='tealbotHelp'
            unmountOnExit
          >
            <TealbotHelpIcon
              show={!attestedDate}
              title={intl.formatMessage(messages.needHelp)}
              placement='top-start'
              imageClassName='ml2 w2 dim pointer'
              onClick={toggleCollaborationFaq}
            />
          </CSSTransition>
          <CSSTransition
            in={isCollaborationFaqOpen}
            timeout={300}
            classNames='tealbotHelp'
            unmountOnExit
          >
            <CollaborationFaq
              open={isCollaborationFaqOpen}
              anchorEl={faqAnchorEl}
              onClose={toggleCollaborationFaq}
            />
          </CSSTransition>
          {!!attestedDate && (
            <Button
              autoSize
              className='mr3'
              onClick={handleEditClick}
              secondary
            >
              <EditIcon fontSize='small' className='mr2' />
              <FormattedMessage
                id='SurveyBanner.EditResponses'
                defaultMessage='Edit Responses'
              />
            </Button>
          )}
          {props.showCloseButton && (
            <IconButton aria-label='Close' onClick={handleClose}>
              <CloseIcon fontSize='large' />
            </IconButton>
          )}
        </div>
      </div>
      {!props.showInstructions && (
        <div
          className=''
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '80%',
            margin: 'auto'
          }}
        >
          {currentPos !== 0 && (
            <ArrowBackIosIcon
              color='primary'
              style={{ fontSize: '35px' }}
              onClick={handleClickArrowBack}
            />
          )}
          <Link
            to={{
              pathname: paths.healthSafety,
              state: { ...props.visitedPages, [location.pathname]: true }
            }}
            ariaLabel='Health & Safety'
          >
            <BannerBadge
              pageId='healthSafety'
              started={currentPos === 0 || healthSafetyStarted}
              completed={healthSafetyCompleted}
              missing={
                (!healthSafetyStarted || !healthSafetyCompleted) &&
                currentPos > 0
              }
              smallOnly={currentPos === 0 ? false : true}
            />
          </Link>
          <Link
            to={{
              pathname: paths.environment,
              state: {
                ...props.visitedPages,
                [location.pathname]: true,
                [paths.healthSafety]: true
              }
            }}
            ariaLabel='Environment'
          >
            <BannerBadge
              pageId='environment'
              started={currentPos === 1 || environmentStarted}
              completed={environmentCompleted}
              missing={
                (!environmentStarted || !environmentCompleted) && currentPos > 1
              }
              smallOnly={currentPos === 1 ? false : true}
            />
          </Link>
          <Link
            to={{
              pathname: paths.diversityInclusion,
              state: {
                ...props.visitedPages,
                [location.pathname]: true,
                [paths.healthSafety]: true,
                [paths.environment]: true
              }
            }}
            ariaLabel='Diversity & Inclusion'
          >
            <BannerBadge
              pageId='diversityInclusion'
              started={currentPos === 2 || diversityInclusionStarted}
              completed={diversityInclusionCompleted}
              missing={
                (!diversityInclusionStarted || !diversityInclusionCompleted) &&
                currentPos > 2
              }
              smallOnly={currentPos === 2 ? false : true}
            />
          </Link>
          <Link
            to={{
              pathname: paths.governance,
              state: {
                ...props.visitedPages,
                [location.pathname]: true,
                [paths.healthSafety]: true,
                [paths.environment]: true,
                [paths.diversityInclusion]: true
              }
            }}
            ariaLabel='Governance'
          >
            <BannerBadge
              pageId='governance'
              started={currentPos === 3 || governanceStarted}
              completed={governanceCompleted}
              missing={
                (!governanceStarted || !governanceCompleted) && currentPos > 3
              }
              smallOnly={currentPos === 3 ? false : true}
            />
          </Link>
          <Link
            to={{
              pathname: paths.privacy,
              state: {
                ...props.visitedPages,
                [location.pathname]: true,
                [paths.healthSafety]: true,
                [paths.environment]: true,
                [paths.diversityInclusion]: true,
                [paths.governance]: true
              }
            }}
            ariaLabel='Privacy'
          >
            <BannerBadge
              pageId='privacy'
              started={currentPos === 4 || privacyStarted}
              completed={privacyCompleted}
              missing={(!privacyStarted || !privacyCompleted) && currentPos > 4}
              smallOnly={currentPos === 4 ? false : true}
            />
          </Link>
          {currentPos !== 4 && (
            <ArrowForwardIosIcon
              color='primary'
              style={{ fontSize: '35px' }}
              onClick={handleClickArrowForward}
            />
          )}
        </div>
      )}
    </>
  )
}

export default SurveyBanner
