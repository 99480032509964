import React, { useEffect, useState } from 'react'
import FileInput from 'shared/components/FileInput'
import Switch from 'shared/components/Switch'
import Divider from 'shared/components/Divider'
import Warning from 'shared/components/Warning'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import Page from 'shared/components/Page'
import api from 'shared/utils/api'
import Text from 'shared/components/Text'
import Slider from '@material-ui/core/Slider'
import apiRoutes from 'shared/routes/apiRoutes'
import { useDispatch, useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import {
  getDateFromQuarter,
  generateMarks,
  getTextFromQuarter,
  getQuarterFromDate
} from 'shared/utils/sliderUtilsForSpendAndLoader'
import moment from 'moment'
import startCase from 'lodash.startcase'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import { deleteClientSpendById } from 'admin/actions'

const styles = {
  section: 'mb3'
}

const ClientSpendModify = () => {
  const dispatch = useDispatch()

  const [computeTrendPressed, setComputeTrendPressed] = useState<boolean>(false)
  const [enableNewLoader, setEnableNewLoader] = useState<boolean>(false)
  const [sliderValue, setSliderValue] = useState<Array<number>>([])
  const [
    deleteSpendByTimeRangePressed,
    setDeleteSpendByTimeRangePressed
  ] = useState<boolean>(false)
  const [deleteSpendByIdPressed, setDeleteSpendByIdPressed] = useState<boolean>(
    false
  )
  const [
    deleteNoSpendAttributesPressed,
    setDeleteNoSpendAttributesPressed
  ] = useState<boolean>(false)
  const [apiResponse, setApiResponse] = useState<string>('')
  const [confirmDeleteSpendDialog, setConfirmDeleteSpendDialog] = useState<
    boolean
  >(false)
  const [fileUpload, setFileUpload] = useState<File | null>(null)

  const minSpendDate = useSelector((state: RootState) =>
    insightsSelectors.getMinSpendDate(state)
  )
  const maxSpendDate = useSelector((state: RootState) =>
    insightsSelectors.getMaxSpendDate(state)
  )
  const yearEnd = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'yearEnd'])
  )

  const handleChange = (event, sliderValue) => {
    setSliderValue(sliderValue)
  }

  useEffect(() => {
    if (maxSpendDate) {
      setSliderValue([
        getQuarterFromDate(
          moment(maxSpendDate).subtract(1, 'quarter'),
          yearEnd
        ),
        getQuarterFromDate(maxSpendDate, yearEnd)
      ])
    }
  }, [maxSpendDate, yearEnd])

  const deleteSpendByTimeRange = () => {
    return api
      .remove(
        `${apiRoutes.authService}/relationships/admin/deleteSpendDF`,
        undefined,
        {
          startDate: getDateFromQuarter(sliderValue[0], false, yearEnd),
          endDate: getDateFromQuarter(sliderValue[1], true, yearEnd)
        }
      )
      .then(response => {
        if (response && response.status) {
          setDeleteSpendByTimeRangePressed(false)
          setApiResponse(`${startCase(response.status)}!: Deleting spend range`)
        }
      })
  }

  const deleteSpendById = () => {
    setDeleteSpendByIdPressed(false)
    dispatch(
      deleteClientSpendById({
        attachedFile: fileUpload
      })
    )
    setFileUpload(null)
  }

  const handleDeleteClientSpend = e => {
    e.preventDefault()
    setApiResponse('')
    setConfirmDeleteSpendDialog(true)
    setDeleteSpendByIdPressed(true)
  }
  return (
    <>
      <Page title={'Spend Modify'}>
        <div className='flex items-center mb2'>
          <Text className='mr3 mt2'>{'Enable "/newLoader"'}</Text>
          <Switch
            checked={enableNewLoader}
            onChange={() =>
              setEnableNewLoader(enableNewLoader => !enableNewLoader)
            }
          />
        </div>
        <Divider className='mv3' />
        <Label>Delete Spend By Internal supplier Id and supplier id</Label>
        <Text>
          <FormattedMessage
            id='ClientSpendEdit.DeleteSpendById'
            defaultMessage='Note: load a file with supplier ids you want to delete from this buyer'
          />
        </Text>
        <Text>
          <FormattedMessage
            id='ClientSpendEdit.required'
            defaultMessage='Required: internalSupplierId AND supplierId for each row'
          />
        </Text>
        <Text>
          <FormattedMessage
            id='ClientSpendEdit.ExcelFile'
            defaultMessage='If using Excel: Sheet name is "Delete"'
          />
        </Text>
        <form onSubmit={handleDeleteClientSpend}>
          <div className={styles.section}>
            <FileInput
              accept='.csv, .xlsx'
              className='mt3'
              name='attachedFile'
              limit={1000}
              required
              value={fileUpload ? fileUpload.name : ''}
              onChange={(file: File | null) => {
                setFileUpload(file)
              }}
            />
            <Warning
              message={
                <FormattedMessage
                  id='Loader.MakeSureTheFileMeetsTheRequiredFormat'
                  defaultMessage='Make sure the file meets the required format'
                />
              }
              className='mt2 mb4'
            />
            <Button
              label={'Delete Spend By Id'}
              type='submit'
              autoSize
              className='mv2'
              disabled={!fileUpload}
            />
          </div>
        </form>
        <Divider className='mv3' />
        <Label>
          <FormattedMessage
            id='Loader.ComputeTrends'
            defaultMessage='Compute Trends'
          />
        </Label>
        <Text>
          <FormattedMessage
            id='Loader.ComputeTrendsNote'
            defaultMessage='Note: compute trends needs to be clicked after any spend load or edit has completed for a buyer'
          />
        </Text>
        <Button
          autoSize
          disabled={computeTrendPressed}
          onClick={event => {
            api.post(
              `${apiRoutes.loader}/${
                enableNewLoader ? 'util' : 'loader'
              }/computeTrends`
            )
            setComputeTrendPressed(true)
          }}
        >
          {!computeTrendPressed ? (
            <FormattedMessage
              id='Loader.ComputeTrends'
              defaultMessage='Compute Trends'
            />
          ) : (
            <FormattedMessage
              id='Loader.ComputeTrendsProcessing'
              defaultMessage='Computing Trends in Progress...'
            />
          )}
        </Button>
        <Divider className='mv3' />
        <Label>
          <FormattedMessage
            id='Loader.DeleteSpendDetails'
            defaultMessage='Delete Spend By Time Range'
          />
        </Label>
        <Text>
          <FormattedMessage
            id='Loader.SpendRangeDeleteInfo'
            defaultMessage='Note: use the slider to select a date range of spend details you would like to clear from all suppliers'
          />
        </Text>
        {minSpendDate && maxSpendDate && (
          <Slider
            aria-labelledby='range-slider'
            step={25}
            min={getQuarterFromDate(minSpendDate, yearEnd)}
            max={getQuarterFromDate(maxSpendDate, yearEnd)}
            onChange={handleChange}
            value={sliderValue}
            marks={generateMarks(
              getQuarterFromDate(minSpendDate, yearEnd),
              getQuarterFromDate(maxSpendDate, yearEnd),
              sliderValue
            )}
          />
        )}
        {sliderValue && sliderValue && (
          <div className='mv2'>
            <Text>
              <span className='mr1-ns'>
                <FormattedMessage
                  id='Loader.DateRange'
                  defaultMessage='Selected Date Range: '
                />
              </span>
              <FormattedMessage
                id='Loader.DateRangeVal'
                defaultMessage='{fromQuarter} to {toQuarter}'
                values={{
                  fromQuarter: getTextFromQuarter(sliderValue[0]),
                  toQuarter: getTextFromQuarter(sliderValue[1])
                }}
              />
            </Text>
          </div>
        )}
        <Button
          autoSize
          disabled={deleteSpendByTimeRangePressed}
          onClick={() => {
            setDeleteSpendByTimeRangePressed(true)
            setConfirmDeleteSpendDialog(true)
            setApiResponse('')
          }}
        >
          {!deleteSpendByTimeRangePressed ? (
            <FormattedMessage
              id='Loader.DeleteSpendRange'
              defaultMessage='Delete Spend'
            />
          ) : (
            <FormattedMessage
              id='Loader.DeletingSpendRange'
              defaultMessage='Deleting Selected Spend'
            />
          )}
        </Button>
        <Divider className='mv3' />
        <Label>Delete Attributes</Label>
        <div className='mv3'>
          <Text>
            <FormattedMessage
              id='Loader.DeleteAttributesInfo'
              defaultMessage='Note: click here to delete relationship attributes for suppliers without Spend Details'
            />
          </Text>
        </div>
        <Button
          autoSize
          disabled={deleteNoSpendAttributesPressed}
          onClick={event => {
            api
              .remove(
                `${apiRoutes.authService}/relationships/admin/deleteNoSpendAttributes`
              )
              .then(response => {
                if (response && response.status) {
                  setDeleteNoSpendAttributesPressed(false)
                  setApiResponse(
                    `${startCase(
                      response.status
                    )}!: Deleting relationship attributes`
                  )
                }
              })
            setDeleteNoSpendAttributesPressed(true)
            setApiResponse('')
          }}
        >
          {!deleteNoSpendAttributesPressed ? (
            <FormattedMessage
              id='Loader.DeleteRelAttributes'
              defaultMessage='Delete Relationship Attributes'
            />
          ) : (
            <FormattedMessage
              id='Loader.DeletingRelAttributes'
              defaultMessage='Deleting Relationship Attributes'
            />
          )}
        </Button>
        {apiResponse.includes('Success') && (
          <div className='dt'>
            <div className='dtc f6 v-mid pl2 pt4'>{apiResponse}</div>
          </div>
        )}{' '}
        {apiResponse && !apiResponse.includes('Success') && (
          <Warning message={apiResponse} className='pt4 mb4' />
        )}
      </Page>
      <ConfirmationDialog
        onClose={() => setConfirmDeleteSpendDialog(false)}
        open={confirmDeleteSpendDialog}
        onConfirm={() => {
          setConfirmDeleteSpendDialog(false)
          if (deleteSpendByTimeRangePressed) {
            deleteSpendByTimeRange()
          }
          if (deleteSpendByIdPressed) {
            deleteSpendById()
          }
        }}
      >
        <Text>
          <FormattedMessage
            id='Loader.ConfirmDelete'
            defaultMessage='Are you sure you want to delete these spend line items?'
          />
        </Text>
      </ConfirmationDialog>
    </>
  )
}

export default ClientSpendModify
