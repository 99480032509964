import React, { Component, Fragment } from 'react'
import Select from 'shared/components/Select'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'

type Props = {
  onChange: (e: string) => void
  value: string
}

class SortBy extends Component<Props> {
  static defaultProps = {
    value: 'Relevance'
  }
  render() {
    const { onChange, value } = this.props

    return (
      <Fragment>
        <span className='fw6 f7 mr2 dib w3 tr'>
          <Label htmlFor='sortrelevance' className='di'>
            <FormattedMessage id='SortBy.SortBy' defaultMessage='Sort By' />
          </Label>
        </span>
        <div className='dib w4'>
          <Select onChange={onChange} value={value} name='sortrelevance'>
            <FormattedMessage id='SortBy.Relevance' defaultMessage='Relevance'>
              {message => <option value='Relevance'>{message}</option>}
            </FormattedMessage>

            <FormattedMessage
              id='SortBy.SupplierName'
              defaultMessage='Supplier Name'
            >
              {message => <option value='SupplierName'>{message}</option>}
            </FormattedMessage>

            <FormattedMessage id='SortBy.FirstName' defaultMessage='First Name'>
              {message => <option value='FirstName'>{message}</option>}
            </FormattedMessage>

            <FormattedMessage id='SortBy.LastName' defaultMessage='Last Name'>
              {message => <option value='LastName'>{message}</option>}
            </FormattedMessage>
          </Select>
        </div>
      </Fragment>
    )
  }
}

export default SortBy
