import React, { Dispatch, SetStateAction } from 'react'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import { TableRow, withStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import startcase from 'lodash.startcase'
import {
  Credentials,
  CustomTableCell,
  Integrations
} from './AppConfigIntegrations'
import { updateSettings } from 'admin/actions'
import { useDispatch } from 'react-redux'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Warning from 'shared/components/Warning'
import { List, RecordOf } from 'immutable'
import ExternalLink from 'shared/components/ExternalLink'

export const CustomTableRow = withStyles({
  root: {
    verticalAlign: 'top'
  }
})(TableRow)

type Props = {
  integrator: string
  integrationConfig: RecordOf<Integrations>
  onHandleLinkIntegration: (integrator: string) => void
  onChangeCredentials: Dispatch<
    SetStateAction<{ [integrator: string]: Credentials }>
  >
  batchAssessmentSupplierErrors: List<
    RecordOf<{
      supplier: string
      name: string
      domains: string[]
      error: string
    }>
  >
  isLoadingSupplierList: boolean
  currentIntegrator: string
  errorCredentials?: string
  credentials: { [integrator: string]: Credentials }
  isConcierge: boolean
}

const IntegratorRow = ({
  integrator,
  integrationConfig,
  onHandleLinkIntegration,
  onChangeCredentials,
  batchAssessmentSupplierErrors,
  isLoadingSupplierList,
  currentIntegrator,
  errorCredentials,
  credentials,
  isConcierge
}: Props) => {
  const dispatch = useDispatch()
  return (
    <CustomTableRow key={integrator}>
      <CustomTableCell align='left'>
        <div className='mb1'>
          <Text>{startcase(integrator)}</Text>
        </div>
        <div className='mb1'>
          {!integrationConfig.get('credentialsSubmitted') &&
            integrator === 'ecovadis' && (
              <>
                <ExternalLink href={'https://invite.ecovadis.com/en/tealbook'}>
                  <Text className='teal no-underline dim pointer'>
                    <FormattedMessage
                      id='AppConfigIntegrations.EncourageEcovadisSignUp1'
                      defaultMessage='Need an EcoVadis Integrations Account?'
                    />
                  </Text>
                </ExternalLink>
              </>
            )}
        </div>
        {!!errorCredentials && integrator === currentIntegrator && (
          <Warning className='mv1' message={errorCredentials} />
        )}
        {isConcierge && integrationConfig.get('reportEnabled') && (
          <Button
            autoSize
            onClick={() => {
              onChangeCredentials(prevState => {
                return (prevState = {
                  ...prevState,
                  [integrator]: {
                    username: '',
                    password: ''
                  }
                })
              })
              dispatch(
                updateSettings({
                  integrations: {
                    [integrator]: {
                      reportEnabled: false,
                      username: '',
                      password: '',
                      initialAssessmentLink: integrationConfig.get(
                        'initialAssessmentLink'
                      )
                    }
                  }
                })
              )
            }}
            label={
              <FormattedMessage
                id='AppConfigIntegrations.TurnOffIntegration'
                defaultMessage='Turn off Integration'
              />
            }
          />
        )}
        {isConcierge && !integrationConfig.get('reportEnabled') && (
          <Button
            autoSize
            onClick={() => {
              dispatch(
                updateSettings({
                  integrations: {
                    [integrator]: {
                      reportEnabled: true,
                      initialAssessmentLink: integrationConfig.get(
                        'initialAssessmentLink'
                      )
                    }
                  }
                })
              )
            }}
            label={
              <FormattedMessage
                id='AppConfigIntegrations.TurnOnIntegration'
                defaultMessage='Turn on Integration'
              />
            }
          />
        )}
      </CustomTableCell>

      {//!integrationConfig.get('credentialsSubmitted') &&
      integrationConfig.get('reportEnabled') && (
        <>
          {!integrationConfig.get('credentialsSubmitted') ? (
            <CustomTableCell align='center'>
              <Input
                name={`${integrator}[username]`}
                type='text'
                onChange={e =>
                  onChangeCredentials(prevState => ({
                    ...prevState,
                    [integrator]: {
                      ...prevState[integrator],
                      username: e.target.value
                    }
                  }))
                }
                value={credentials[integrator]?.username || ''}
                autoComplete='off'
              />
            </CustomTableCell>
          ) : (
            <CustomTableCell align='left'>
              <Text>{integrationConfig.get('username')}</Text>
            </CustomTableCell>
          )}
          {!integrationConfig.get('credentialsSubmitted') ? (
            <>
              <CustomTableCell align='center'>
                <Input
                  name={`${integrator}[password]`}
                  type='password'
                  onChange={e =>
                    onChangeCredentials(prevState => ({
                      ...prevState,
                      [integrator]: {
                        ...prevState[integrator],
                        password: e.target.value
                      }
                    }))
                  }
                  value={credentials[integrator]?.password || ''}
                />
              </CustomTableCell>
              <CustomTableCell align='left'>
                <Button
                  autoSize
                  onClick={() => onHandleLinkIntegration(integrator)}
                  label={
                    isLoadingSupplierList ? (
                      <FormattedMessage
                        id='AppConfigIntegrations.LinkingAccount'
                        defaultMessage='Linking Account...'
                      />
                    ) : (
                      <FormattedMessage
                        id='AppConfigIntegrations.LinkAccount'
                        defaultMessage='Link Account'
                      />
                    )
                  }
                  disabled={isLoadingSupplierList}
                />
              </CustomTableCell>
            </>
          ) : (
            <>
              <CustomTableCell align='left'></CustomTableCell>
              <CustomTableCell align='left'></CustomTableCell>
              {!!batchAssessmentSupplierErrors?.size && (
                <CustomTableCell align='center'>
                  <div className='flex items-center'>
                    <ErrorOutlineIcon color='error' />
                    <Text className='red'>
                      {
                        <FormattedMessage
                          id='AppConfigIntegrations.Errors'
                          defaultMessage='Errors'
                        />
                      }
                    </Text>
                  </div>
                </CustomTableCell>
              )}
              <CustomTableCell align='center'>
                <div className='flex items-center'>
                  <Text>
                    {
                      <FormattedMessage
                        id='IntegratorRow.Linked'
                        defaultMessage='Linked'
                      />
                    }
                  </Text>
                </div>
              </CustomTableCell>
            </>
          )}

          {/* {integrationConfig.get('credentialsSubmitted') && (
              <CustomTableCell align='center'>
                <div className='flex items-center'>
                  <Text>
                    {
                      <FormattedMessage
                        id='IntegratorRow.Linked'
                        defaultMessage='Linked'
                      />
                    }
                  </Text>
                </div>
              </CustomTableCell>
            )} */}
        </>
      )}
    </CustomTableRow>
  )
}

export default IntegratorRow
