import React, { Component, ReactNode } from 'react'
import InputWithLabel from '../InputWithLabel'
import Input from '../Input'
import TextArea from '../TextArea'
import AutoSaveInput from '../AutoSaveInput'
import Select from '../Select'
import DatePicker from 'shared/components/DatePicker'
import Checkbox from '../Checkbox'
import RangeInput from '../RangeInput'
import FileInput from 'shared/components/FileInput'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

type Props = {
  name: string
  type:
    | 'text'
    | 'textarea'
    | 'boolean'
    | 'number'
    | 'multipleSelect'
    | 'select'
    | 'range'
    | 'date'
    | 'file'
  label: string
  placeholder: string
  required: boolean
  response: any
  onSave: Function
  options: string[]
  membershipId: string
  communityId: string
}

class Question extends Component<Props> {
  handleCheckboxMultipleSelectChange = e => {
    const { response, onSave, name } = this.props

    let newResponse = []

    if (e.target.checked) {
      newResponse = response
        ? response.push(e.target.value).toJS()
        : [e.target.value]
    } else if (response) {
      newResponse = response.filter(r => r !== e.target.value).toJS()
    }

    onSave({
      [name]: newResponse
    })
  }

  generateAutoSaveInput(inputProps) {
    const { name, placeholder, required, response, onSave } = this.props

    return (
      <AutoSaveInput
        name={name}
        value={response}
        placeholder={placeholder}
        required={required}
        onSave={onSave}
        {...inputProps}
      />
    )
  }

  getInput = (): ReactNode => {
    const {
      name,
      type,
      options,
      response,
      onSave,
      required,
      label,
      membershipId,
      communityId
    } = this.props
    switch (type) {
      case 'boolean':
        return (
          <Checkbox
            name={name}
            label='Yes'
            checked={!!response}
            onChange={e =>
              onSave({
                [name]: !response
              })
            }
          />
        )

      case 'multipleSelect':
        return (
          <div className='mt2'>
            {options &&
              options.map(option => (
                <Checkbox
                  key={option}
                  name={option}
                  value={option}
                  label={option}
                  checked={response && response.includes(option)}
                  onChange={this.handleCheckboxMultipleSelectChange}
                />
              ))}
          </div>
        )
      case 'select':
        return this.generateAutoSaveInput({
          component: Select,
          children: [
            <option key='A' value='' />,
            ...options.map(option => <option key={option}>{option}</option>)
          ]
        })

      case 'textarea':
        return this.generateAutoSaveInput({
          component: TextArea
        })

      case 'date':
        return (
          <DatePicker
            name={name}
            value={response && new Date(response)}
            onChange={value =>
              onSave({
                [name]: value
              })
            }
            required={required}
          />
        )

      case 'range':
        return this.generateAutoSaveInput({
          component: RangeInput,
          value: response && response.toJS(),
          validate: [
            val =>
              val.from > val.to && 'Minimum value must be lower than the Max',
            val => val.from === val.to && 'They should not be equal'
          ]
        })

      case 'number':
        return this.generateAutoSaveInput({
          component: Input,
          type: 'number'
        })

      case 'file':
        return (
          <>
            <FileInput
              name={name}
              accept='.pdf'
              onChange={value => {
                api
                  .putFile(
                    `${apiRoutes.authService}/community/supplier/upload/${membershipId}`,
                    {
                      form: value,
                      questionId: name,
                      communityId
                    }
                  )
                  .then(result => {
                    onSave({
                      [name]: {
                        fileName: result.fileName,
                        bucketName: result.bucketName,
                        date: new Date(),
                        label,
                        type: 'file',
                        description: result.description
                      }
                    })
                  })
              }}
            />
            {response && response.get('description') && (
              <Label>
                <FormattedMessage
                  id='Question.FileUploaded'
                  defaultMessage='{fileName} has been uploaded on {date}'
                  values={{
                    fileName: response.get('description'),
                    date: moment(response.get('date')).format('lll')
                  }}
                />
              </Label>
            )}
          </>
        )

      case 'text':
      default:
        return this.generateAutoSaveInput({
          component: Input
        })
    }
  }

  render() {
    const { label, required, name } = this.props
    return (
      <InputWithLabel
        label={label}
        labelFor={name}
        required={required}
        inputComponent={this.getInput()}
        editMode
      />
    )
  }
}

export default Question
