import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import startCase from 'lodash.startcase'
import storageManager from 'shared/utils/storageManager'

const apiRoute = `${apiRoutes.authService}/supplier`
const apiRouteVets = `${apiRoutes.authService}/vets`

export const get = () => api.get(apiRoute)

export const update = (newValues: Object) => api.put(apiRoute, newValues)

export const alreadyOnboarded = () => storageManager.getItem('alreadyOnboarded')
export const onboard = () => storageManager.setItem('alreadyOnboarded', true)

type updateParentOrgProps = {
  name: string
  yearFounded: number
  yearlyRevenue: string
  employeeNumber: string
}
export const updateParentOrg = ({
  name,
  yearFounded,
  yearlyRevenue,
  employeeNumber
}: updateParentOrgProps) =>
  api.put(`${apiRoute}/org`, {
    name,
    yearFounded,
    yearlyRevenue,
    employeeNumber
  })

export const getVets = () => {
  return api.get(`${apiRouteVets}/supplier`).then(vets => {
    return vets.map(({ status, lastUpdate, submitResponseBy, ...rest }) => {
      return {
        status: startCase(status),
        lastUpdate: new Date(lastUpdate),
        submitResponseBy: submitResponseBy && new Date(submitResponseBy),
        ...rest
      }
    })
  })
}

export const getVetById = (vetBuyerSupplierId: string) => {
  return api.get(`${apiRouteVets}/supplier/${vetBuyerSupplierId}`).then(
    ({
      vetBuyerSupplier: {
        collaboration: { status, ...colRest },
        ...vbsRest
      },
      ...rest
    }) => {
      return {
        vetBuyerSupplier: {
          collaboration: {
            status: startCase(status),
            ...colRest
          },
          ...vbsRest
        },
        ...rest
      }
    }
  )
}

export const openVet = (vetBuyerSupplierId: string) => {
  return api.post(`${apiRouteVets}/supplier/${vetBuyerSupplierId}/open`).then(
    ({
      vetBuyerSupplier: {
        collaboration: { status, ...colRest },
        ...vbsRest
      },
      ...rest
    }) => {
      return {
        vetBuyerSupplier: {
          collaboration: {
            status: startCase(status),
            ...colRest
          },
          ...vbsRest
        },
        ...rest
      }
    }
  )
}

type acceptVetCriteriaProps = {
  vetBuyerSupplierId: string
  meetCriteria: boolean
}
export const acceptVetCriteria = ({
  vetBuyerSupplierId,
  meetCriteria
}: acceptVetCriteriaProps) => {
  return api
    .post(`${apiRouteVets}/supplier/${vetBuyerSupplierId}/meetCriteria`, {
      meetCriteria
    })
    .then(
      ({
        vetBuyerSupplier: {
          collaboration: { status, ...colRest },
          ...vbsRest
        },
        ...rest
      }) => {
        return {
          vetBuyerSupplier: {
            collaboration: {
              status: startCase(status),
              ...colRest
            },
            ...vbsRest
          },
          ...rest
        }
      }
    )
}

type showInterestVetProps = {
  vetBuyerSupplierId: string
  interested: boolean
}
export const showInterestVet = ({
  vetBuyerSupplierId,
  interested
}: showInterestVetProps) => {
  return api
    .post(`${apiRouteVets}/supplier/${vetBuyerSupplierId}/interested`, {
      interested
    })
    .then(
      ({
        vetBuyerSupplier: {
          collaboration: { status, ...colRest },
          ...vbsRest
        },
        ...rest
      }) => {
        return {
          vetBuyerSupplier: {
            collaboration: {
              status: startCase(status),
              ...colRest
            },
            ...vbsRest
          },
          ...rest
        }
      }
    )
}

type acceptVetProps = {
  vetBuyerSupplierId: string
  description: string
}
export const acceptVet = ({
  vetBuyerSupplierId,
  description
}: acceptVetProps) => {
  return api
    .post(`${apiRouteVets}/supplier/${vetBuyerSupplierId}/accept`, {
      description: description
    })
    .then(
      ({
        vetBuyerSupplier: {
          collaboration: { status, ...colRest },
          ...vbsRest
        },
        ...rest
      }) => {
        return {
          vetBuyerSupplier: {
            collaboration: {
              status: startCase(status),
              ...colRest
            },
            ...vbsRest
          },
          ...rest
        }
      }
    )
}

type declineVetProps = {
  vetBuyerSupplierId: string
  description: string
}
export const declineVet = ({
  vetBuyerSupplierId,
  description
}: declineVetProps) => {
  return api
    .post(`${apiRouteVets}/supplier/${vetBuyerSupplierId}/decline`, {
      description: description
    })
    .then(
      ({
        vetBuyerSupplier: {
          collaboration: { status, ...colRest },
          ...vbsRest
        },
        ...rest
      }) => {
        return {
          vetBuyerSupplier: {
            collaboration: {
              status: startCase(status),
              ...colRest
            },
            ...vbsRest
          },
          ...rest
        }
      }
    )
}

type updateVetProposalProps = {
  vetBuyerSupplierId: string
  created
  modified
  opened
  interested
  meetCriteria
  submitted
  sendToContacts: Array<{ user: string }>
}
export const updateVetProposal = ({
  vetBuyerSupplierId,
  created,
  modified,
  opened,
  interested,
  meetCriteria,
  submitted,
  sendToContacts,
  ...body
}: updateVetProposalProps) =>
  api.post(`${apiRouteVets}/supplier/${vetBuyerSupplierId}/proposal`, {
    sendToContacts,
    proposal: body
  })

type uploadVetResponseProps = {
  file: File
  vetBuyerSupplierId: string
}
export const uploadVetResponse = ({
  file,
  vetBuyerSupplierId
}: uploadVetResponseProps) => {
  return api.postFile(
    `${apiRouteVets}/supplier/${vetBuyerSupplierId}/responseFile`,
    {
      document: file
    }
  )
}

type removeVetPresentationProps = {
  fileName: string
  vetBuyerSupplierId: string
}
export const removeVetPresentation = ({
  fileName,
  vetBuyerSupplierId
}: removeVetPresentationProps) => {
  return api.remove(
    `${apiRouteVets}/supplier/${vetBuyerSupplierId}/${fileName}`
  )
}

export const uploadFile = (fieldName: string, file: Blob) => {
  return api.postFile(`${apiRoute}/${fieldName}`, {
    [fieldName]: file
  })
}

export const addTags = (tagsFieldName: string, newTags: Array<string>) => {
  return api.post(`${apiRoute}/tag`, {
    [tagsFieldName]: Array.isArray(newTags) ? newTags : [newTags]
  })
}

export const deleteTags = (tagsFieldName: string, tagsToDelete: string) => {
  return api.remove(`${apiRoute}/tag`, {
    [tagsFieldName]: Array.isArray(tagsToDelete) ? tagsToDelete : [tagsToDelete]
  })
}

export const deleteLocation = (locationToDelete: Object) => {
  return api.remove(`${apiRoute}/location`, locationToDelete)
}

export const updateLocation = (locations: Array<Object>) => {
  return api.post(`${apiRoute}/locations`, locations)
}

type addContactProps = {
  userId: string
  contactFor: string
}
export const addContact = ({ userId, contactFor }: addContactProps) => {
  return api.put(`${apiRoute}/contacts/${userId}`, { contactFor })
}

export const removeContact = (userId: string) => {
  return api.remove(`${apiRoute}/contacts/${userId}`)
}
export const removeAllContacts = (users: Array<string>) => {
  return api.remove(`${apiRoute}/contacts/${users}`)
}

type setCertificationProps = {
  timeStamp: string | undefined
}
export const setCertification = ({
  timeStamp,
  ...restParams
}: setCertificationProps) => {
  if (timeStamp === undefined) {
    timeStamp = new Date().toISOString()
  }
  return api.putFile(`${apiRoute}/certification`, { timeStamp, ...restParams })
}

type removeCertificationProps = {
  category: string
  subCategory: string
  timeStamp: string
}
export const removeCertification = ({
  category,
  subCategory,
  timeStamp
}: removeCertificationProps) => {
  return api.remove(
    `${apiRoute}/certification/${category}/${subCategory}/${timeStamp}`
  )
}

export const getBuyerByDomain = (q: string, allowForbiddenDomain: boolean) =>
  api.get(`${apiRoutes.authService}/buyer/checkDomain`, {
    q,
    allowForbiddenDomain
  })

export const removeBackgroundPicture = () => {
  return api.remove(`${apiRoute}/backgroundPicture`)
}

export const deleteLogo = () => api.remove(`${apiRoute}/logo`)
export const deletePitchDeck = () => api.remove(`${apiRoute}/pitchDeckFile`)

export const addDomain = (domain: string) => {
  return api.post(`${apiRoute}/domain`, { domain })
}

export const getStripePublicKey = () => {
  return api.get(`${apiRoute}/stripePublicKey`)
}

export const makePayment = (token: Object) => {
  return api.post(`${apiRoute}/supplierPremium`, token)
}

export const cancelSubscription = () => {
  return api.get(`${apiRoute}/cancelSubscription`)
}

export const addClassificationCode = (
  classificationCode: Map<string, string>
) => {
  return api.put(`${apiRoute}/classificationCode`, classificationCode)
}

type removeClassificationCodeProps = {
  schema: string
  code: string
}
export const removeClassificationCode = ({
  schema,
  code
}: removeClassificationCodeProps) => {
  return api.remove(`${apiRoute}/classificationCode/${schema}/${code}`)
}
export const getSupplierByDomain = (q: string) =>
  api.get(`${apiRoute}/domain`, {
    q
  })
