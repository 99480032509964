import React from 'react'
import { ReactComponent as EditIcon } from 'shared/assets/icons/edit-teal.svg'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import IconButton from '../IconButton'
/**
 * The edit button to go to a form
 */

type Props = {
  onClick: ((any: any) => void) | undefined
  message?: string
  buttonTitle?: string
}

const EditProfileButton = (props: Props) => {
  const { buttonTitle, ...restProps } = props
  return (
    <FormattedMessage id='EditProfileButton.Edit' defaultMessage='Edit Profile'>
      {message => (
        <Tooltip title={message}>
          <IconButton
            {...restProps}
            style={{
              width: 32,
              height: 32,
              padding: 0
            }}
          >
            <EditIcon
              title={buttonTitle}
              style={{
                width: 20,
                height: 20
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </FormattedMessage>
  )
}

export default EditProfileButton
