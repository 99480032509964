import React from 'react'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import { TextField } from '@material-ui/core'

type Props = {
  buyers: string
  year: string
  quarter: number
  dataType: string
  spendType: string
}
const ViewLoadHeader = (props: Props) => {
  const { buyers, year, quarter, dataType, spendType } = props

  return (
    <div className='flex justify-between'>
      <div className='w-20'>
        <Label htmlFor='buyer'>
          <FormattedMessage
            id='UploadSpendDetail.Buyer'
            defaultMessage='Buyer'
          />
        </Label>
        <TextField
          defaultValue={buyers}
          variant='outlined'
          InputProps={{ readOnly: true }}
        ></TextField>
      </div>
      <div className='w-20 pl3'>
        <Label htmlFor='year'>
          <FormattedMessage id='UploadSpendDetail.Year' defaultMessage='Year' />
        </Label>
        <TextField
          defaultValue={year}
          variant='outlined'
          InputProps={{ readOnly: true }}
        ></TextField>
      </div>
      <div className='w-20 pl3'>
        <Label htmlFor='quarter'>
          <FormattedMessage
            id='UploadSpendDetail.Quarter'
            defaultMessage='Quarter'
          />
        </Label>
        <TextField
          defaultValue={`Q${quarter}`}
          variant='outlined'
          InputProps={{ readOnly: true }}
        ></TextField>
      </div>
      <div className='w-20 pl3'>
        <Label htmlFor='dataType'>
          <FormattedMessage
            id='UploadSpendDetail.DataType'
            defaultMessage='Data type'
          />
        </Label>
        <TextField
          defaultValue={dataType}
          variant='outlined'
          style={{ textTransform: 'capitalize' }}
          InputProps={{ readOnly: true }}
        ></TextField>
      </div>
      <div className='w-20 pl3'>
        <Label htmlFor='spendType'>
          <FormattedMessage
            id='UploadSpendDetail.SpendType'
            defaultMessage='Spend type'
          />
        </Label>
        <TextField
          defaultValue={spendType}
          variant='outlined'
          InputProps={{ readOnly: true }}
        ></TextField>
      </div>
    </div>
  )
}
export default ViewLoadHeader
