import React, { FunctionComponent, SyntheticEvent, ChangeEvent } from 'react'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import startCase from 'lodash.startcase'
import Divider from 'shared/components/Divider'
import { List } from 'immutable'
import Warning from 'shared/components/Warning'

import {
  buyerRoles,
  buyerRolesDescription,
  supplierRoles,
  supplierRolesDescription,
  orgAdminRoles,
  orgAdminRolesDescription,
  tealbotRoles,
  serverAdminRoles
} from './roles'

type Props = {
  isSupplierAdmin?: boolean
  isBuyerAdmin?: boolean
  isOrgAdmin?: boolean
  roles: List<string>
  stateRoles: List<string>
  isRolesDialogOpen: boolean
  handleCloseDialog: (event: SyntheticEvent<{}, Event>) => void
  toggleRole: (event: ChangeEvent<HTMLInputElement>) => void
  handleEditRoles: () => void
  handleUpdateRoles: () => void
  intl: IntlShape
  isTealbot?: boolean
  isServerAdmin?: boolean
}

export const EditUserRoles: FunctionComponent<Props> = props => {
  const {
    isSupplierAdmin,
    isBuyerAdmin,
    isOrgAdmin,
    stateRoles,
    isTealbot,
    isServerAdmin
  } = props

  const getRolesToAssign = () => {
    let roleLabels = {}
    let roleDescriptions = {}

    if (isBuyerAdmin) {
      roleLabels = Object.assign({}, roleLabels, buyerRoles)
      roleDescriptions = Object.assign(
        {},
        roleDescriptions,
        buyerRolesDescription
      )
    }

    if (isSupplierAdmin) {
      roleLabels = Object.assign({}, roleLabels, supplierRoles)
      roleDescriptions = Object.assign(
        {},
        roleDescriptions,
        supplierRolesDescription
      )
    }

    if (isOrgAdmin) {
      roleLabels = Object.assign({}, roleLabels, orgAdminRoles)
      roleDescriptions = Object.assign(
        {},
        roleDescriptions,
        orgAdminRolesDescription
      )
    }
    if (isTealbot) {
      roleLabels = Object.assign({}, roleLabels, tealbotRoles)
    }
    if (isServerAdmin) {
      roleLabels = Object.assign({}, roleLabels, serverAdminRoles)
    }

    return {
      roleLabels,
      roleDescriptions
    }
  }

  const { roleLabels, roleDescriptions } = getRolesToAssign()
  const {
    roles,
    isRolesDialogOpen,
    handleCloseDialog,
    intl,
    toggleRole,
    handleEditRoles,
    handleUpdateRoles
  } = props

  return (
    <>
      <div className='mb3 '>
        <h4 className='ma0 mb2 f7 fw6'>
          <FormattedMessage
            id='EditUserProfile.CurrentRoles'
            defaultMessage='Current Roles'
          />
        </h4>
        <Text>
          {roles &&
            roles
              .map(role =>
                roleLabels[role]
                  ? intl.formatMessage(
                      roleLabels[role],
                      roleLabels[role].values
                    )
                  : startCase(role)
              )
              .join(', ')}
        </Text>
      </div>
      <div className='mb3'>
        <Button
          label={
            <FormattedMessage
              id='EditUserProfile.EditUserRoles'
              defaultMessage='Edit User Roles'
            />
          }
          onClick={handleEditRoles}
        />
      </div>
      <Dialog open={isRolesDialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle id='editRoleHeader'>
          <FormattedMessage
            id='EditUserProfile.EditRoles'
            defaultMessage='Edit Roles'
          />
        </DialogTitle>
        <DialogContent className='mt3'>
          <div
            className='overflow-scroll'
            role='group'
            aria-labelledby='editRoleHeader'
          >
            {Object.entries<{
              id: string
              defaultMessage: string
              values?: { [key: string]: string }
            }>(roleLabels).map(([key, translatedLabel]) => (
              <label key={key} className='mv3 f7 flex-ns dib h2'>
                <input
                  type='checkbox'
                  name={key}
                  checked={stateRoles && stateRoles.includes(key)}
                  onChange={toggleRole}
                />
                <span className='w4 flex-shrink-0 ml2'>
                  {intl.formatMessage(translatedLabel, translatedLabel.values)}
                </span>
                {roleDescriptions[key] && (
                  <span className='ml3 dn dib'>
                    {intl.formatMessage(
                      roleDescriptions[key],
                      roleDescriptions[key].values
                    )}
                  </span>
                )}
              </label>
            ))}
          </div>

          {!stateRoles ||
            (!stateRoles.includes('buyer') &&
              !stateRoles.includes('supplier') &&
              !stateRoles.includes('clickWorker') && (
                <Warning
                  message={
                    <FormattedMessage
                      id='EditUserProfile.NeedBuyerOrSupplierRoleOrClickWorker'
                      defaultMessage='At least one of the roles Buyer or Supplier or Click Worker must be selected.'
                    />
                  }
                />
              ))}

          <div className='mt3'>
            <Button
              label={
                <FormattedMessage
                  id='EditUserProfile.ChangeRoles'
                  defaultMessage='Change Roles'
                />
              }
              autoSize
              onClick={handleUpdateRoles}
              className='mr3'
              disabled={
                !stateRoles ||
                (!stateRoles.includes('buyer') &&
                  !stateRoles.includes('supplier') &&
                  !stateRoles.includes('clickWorker'))
              }
              role='button'
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              autoSize
              secondary
              onClick={handleCloseDialog}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Divider className='mb3' />
    </>
  )
}

export default injectIntl(EditUserRoles)
