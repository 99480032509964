import React, { useRef, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Scrollable from 'shared/components/Scrollable'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Loading from 'shared/components/Loading'
import withOrg from 'shared/utils/withOrg'
import SuggestSupplierItem from 'buyer/shared/components/SuggestSuppliers/SuggestSupplierItem'
import { List, RecordOf } from 'immutable'
import Input from 'shared/components/Input'

const style = {
  root: {
    zIndex: 9999
  }
}
const OurCustomersSuggestSection = withOrg(SuggestSupplierItem)

type Props<T> = {
  placeholder: ReactNode
  open: boolean
  onClose: () => void
  companyName: string
  onChangeCompanyName: (companyName: string) => void
  loadingSuggestions?: boolean
  suggestedCustomers?: List<string>
  suggestQuery?: string
  openNewCustomerDialog?: () => void
  onHandleClickSuggestedOrgUnit?: (orgUnit: RecordOf<T>) => void
  onHandleClickSuggestedOrgUnitId?: (orgUnitId: string) => void
  onClickMenuItem?: () => void
  suggestedSuppliers?: List<RecordOf<T>>
  readOnly?: boolean
  name?: string
  inputId?: string
}

const LookupSuppliers = <
  T extends {
    id: string
    name: string
    logo?: string
    domains?: List<string>
  }
>({
  open,
  loadingSuggestions,
  suggestedSuppliers,
  openNewCustomerDialog,
  onClose,
  onHandleClickSuggestedOrgUnit,
  onHandleClickSuggestedOrgUnitId,
  suggestQuery,
  onClickMenuItem,
  suggestedCustomers,
  placeholder,
  onChangeCompanyName,
  companyName,
  readOnly,
  name,
  inputId
}: Props<T>) => {
  const anchorElRef = useRef<HTMLInputElement>(null)

  return (
    <>
      <Input
        name={name}
        placeholder={placeholder}
        onChange={e => onChangeCompanyName(e.target.value)}
        value={companyName}
        readOnly={readOnly}
        input={{
          ref: anchorElRef
        }}
        id={inputId}
      />
      <Popper
        className='br1 ba b--black-10 bg-white'
        style={style.root}
        transition
        placement='bottom-start'
        open={open}
        anchorEl={anchorElRef.current}
        modifiers={{
          flip: {
            enabled: true
          }
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            mouseEvent={open && 'onMouseUp'}
            onClickAway={() => onClose()}
          >
            <Scrollable maxHeight={300}>
              <Grow {...TransitionProps}>
                <Paper>
                  {loadingSuggestions && (
                    <div className='w5 mt2'>
                      <Loading />
                    </div>
                  )}

                  <MenuList>
                    {suggestedCustomers &&
                      suggestedCustomers.map(id => (
                        <OurCustomersSuggestSection
                          key={id}
                          orgUnitId={id}
                          id={id}
                          onClick={() =>
                            onHandleClickSuggestedOrgUnitId &&
                            onHandleClickSuggestedOrgUnitId(id)
                          }
                        />
                      ))}
                    {suggestedSuppliers &&
                      suggestedSuppliers.map(supplier => (
                        <SuggestSupplierItem
                          key={supplier.get('id')}
                          name={supplier.get('name')}
                          onClick={() =>
                            onHandleClickSuggestedOrgUnit &&
                            onHandleClickSuggestedOrgUnit(supplier)
                          }
                          logoUrl={supplier.get('logo')}
                          websiteUrl={supplier.get('domains')?.join(', ')}
                        />
                      ))}

                    {openNewCustomerDialog && onClickMenuItem && (
                      <MenuItem
                        onClick={() => {
                          onClickMenuItem()
                          openNewCustomerDialog()
                        }}
                      >
                        <FormattedMessage
                          id='LookUp.AddCustomer'
                          defaultMessage='Add Customer'
                        />
                        &nbsp;
                        <span className='black fw6'>{suggestQuery}</span>
                      </MenuItem>
                    )}
                  </MenuList>
                </Paper>
              </Grow>
            </Scrollable>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}

export default LookupSuppliers
