import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Field } from 'redux-form/immutable'
import Checkbox from 'shared/components/ReduxFormMaterialCheckbox'
import CheckIcon from '@material-ui/icons/Done'

type Props = {
  editMode: boolean
  label: ReactElement<FormattedMessage>
  fieldKey: string
  basicAccess: boolean
  businessAccess: boolean
  professionalAccess: boolean
  advancedAccess: boolean
  eliteAccess: boolean
}

const LicenseTableRow = ({
  editMode,
  label,
  fieldKey,
  basicAccess,
  businessAccess,
  professionalAccess,
  advancedAccess,
  eliteAccess
}: Props) => {
  return (
    <TableRow>
      <TableCell component='th' scope='row'>
        <div className='pv1'>{label}</div>
      </TableCell>
      <TableCell align='center'>
        {editMode ? (
          <Field
            name={`licenseConfig.basic.${fieldKey}`}
            component={Checkbox}
          />
        ) : basicAccess ? (
          <CheckIcon color='primary' />
        ) : null}
      </TableCell>
      <TableCell align='center'>
        {editMode ? (
          <Field
            name={`licenseConfig.business.${fieldKey}`}
            component={Checkbox}
          />
        ) : businessAccess ? (
          <CheckIcon color='primary' />
        ) : null}
      </TableCell>
      <TableCell align='center'>
        {editMode ? (
          <Field
            name={`licenseConfig.professional.${fieldKey}`}
            component={Checkbox}
          />
        ) : professionalAccess ? (
          <CheckIcon color='primary' />
        ) : null}
      </TableCell>
      <TableCell align='center'>
        {editMode ? (
          <Field
            name={`licenseConfig.advanced.${fieldKey}`}
            component={Checkbox}
          />
        ) : advancedAccess ? (
          <CheckIcon color='primary' />
        ) : null}
      </TableCell>
      <TableCell align='center'>
        {editMode ? (
          <Field
            name={`licenseConfig.elite.${fieldKey}`}
            component={Checkbox}
          />
        ) : eliteAccess ? (
          <CheckIcon color='primary' />
        ) : null}
      </TableCell>
    </TableRow>
  )
}

export default LicenseTableRow
