import { connect } from 'react-redux'
import Message from '../../components/Message'
import usersSelectors from 'shared/selectors/usersSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import RootState from 'shared/models/RootState'

type ContainerProps = {
  userId: string
}

export default connect((state: RootState, props: ContainerProps) => {
  const user = usersSelectors.getById(state, props.userId)
  const orgUnitId = user.get('orgUnitId')
  const org = orgsSelectors.getById(state, orgUnitId)
  return {
    profilePictureUrl: user?.get('profilePictureUrl'),
    firstName: user?.get('firstName'),
    lastName: user?.get('lastName'),
    domain: org?.getIn(['domains', 0])
  }
})(Message)
