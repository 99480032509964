import * as actionTypes from '../actionTypes'
import saveCompanyInfoSaga from './saveCompanyInfoSaga'
import setRecordAsFrozenSaga from './setRecordAsFrozenSaga'
import uploadProfilePictureSaga from './uploadProfilePictureSaga'
import { takeLatest, all, takeEvery, fork } from 'redux-saga/effects'
import takeFirst from 'shared/utils/sagas/takeFirst'
import updateTagsSaga from './updateTagsSaga'
import addContactSaga from './addContactSaga'
import removeContactSaga from './removeContactSaga'
import removeAllContactsSaga from './removeAllContactsSaga'
import updateDiversityCertificationSaga from './updateDiversityCertificationSaga'
import updateParentOrgSaga from './updateParentOrgSaga'
import addCustomerSaga from './addCustomerSaga'
import removeCustomerSaga from './removeCustomerSaga'
import getVerifiedSaga from './getVerifiedSaga'
import removeBackgroundPictureSaga from './removeBackgroundPictureSaga'
import uploadFileSaga from './uploadFileSaga'
import addDomainSaga from './addDomainSaga'
import supplier from 'shared/utils/api/supplier'
import addSuggestedTagsSaga from './addSuggestedTagsSaga'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import addClassificationCodeSaga from './addClassificationCodeSaga'
import removeClassificationCodeSaga from './removeClassificationCodeSaga'
import updateContactSaga from './updateContactSaga'
import removeLocationSaga from './removeLocationSaga'
import updateLocationSaga from './updateLocationSaga'

export default function* rootSaga() {
  yield all([
    fork(updateTagsSaga),
    fork(updateDiversityCertificationSaga),
    takeFirst(actionTypes.ADD_CONTACT, addContactSaga),
    takeFirst(actionTypes.UPDATE_CONTACT, updateContactSaga),
    takeEvery(actionTypes.REMOVE_CONTACT, removeContactSaga),
    takeEvery(actionTypes.REMOVE_LOCATION, removeLocationSaga),
    takeEvery(actionTypes.UPDATE_LOCATION, updateLocationSaga),
    takeLatest(actionTypes.UPLOAD_PROFILE_PICTURE, uploadProfilePictureSaga),
    takeEvery(actionTypes.SAVE_COMPANY_INFO, saveCompanyInfoSaga),
    takeEvery(actionTypes.SET_RECORD_AS_FROZEN, setRecordAsFrozenSaga),
    takeEvery(actionTypes.UPDATE_PARENT_ORG, updateParentOrgSaga),
    takeFirst(actionTypes.ADD_CUSTOMER, addCustomerSaga),
    takeEvery(actionTypes.REMOVE_CUSTOMER, removeCustomerSaga),
    takeFirst(actionTypes.GET_VERIFIED, getVerifiedSaga),
    takeEvery(
      actionTypes.REMOVE_BACKGROUND_PICTURE,
      removeBackgroundPictureSaga
    ),
    takeEvery(actionTypes.UPLOAD_FILE, uploadFileSaga),
    takeFirst(actionTypes.ADD_DOMAIN, addDomainSaga),
    takeFirst(
      actionTypes.DELETE_LOGO,
      createApiRequestSaga(supplier.deleteLogo)
    ),
    takeFirst(
      actionTypes.DELETE_PITCHDECK,
      createApiRequestSaga(supplier.deletePitchDeck)
    ),
    takeFirst(actionTypes.ADD_SUGGESTED_TAGS, addSuggestedTagsSaga),
    takeEvery(actionTypes.REMOVE_ALL_CONTACTS, removeAllContactsSaga),
    takeFirst(actionTypes.ADD_CLASSIFICATION_CODE, addClassificationCodeSaga),
    takeEvery(
      actionTypes.REMOVE_CLASSIFICATION_CODE,
      removeClassificationCodeSaga
    )
  ])
}
