import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import sustainabilityReportSelectors from '../../store/sustainabilityReportSelectors'
import SpendReportDetails from '../../../shared/components/SpendReportDetails'
import path from '../../routes/paths'
import RootState from 'shared/models/RootState'
import { FormattedMessage } from 'react-intl'
import Loading from 'shared/components/Loading'

type Props = {
  headerLabel?: ReactElement<FormattedMessage> | string
  show: 'qualified' | 'disqualified' | 'potential'
}

const SustainabilitySpendDetails = (props: Props) => {
  const { show = 'qualified', headerLabel } = props
  const [qualifiedData, qualifiedTotal] = useSelector(
    sustainabilityReportSelectors.getQualifiedDetailSpend
  )
  const [disqualifiedData, disqualifiedTotal] = useSelector(
    sustainabilityReportSelectors.getDisqualifiedDetailSpend
  )
  const [potentialData, potentialTotal] = useSelector(
    sustainabilityReportSelectors.getPotentialDetailSpend
  )
  const selectedSubCategories = useSelector(
    (state: RootState) =>
      state.get('buyer').get('diversityReport').selectedCategories
  )
  const totalSpend = useSelector((state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'totalSpend'])
  )
  const loading = useSelector(
    (state: RootState) => state.get('buyer').get('diversityReport').loading
  )

  const overviewSubCategories = useSelector(
    sustainabilityReportSelectors.getOverviewSubCategories
  )

  let detailsTableData
  if (show === 'qualified') {
    detailsTableData = qualifiedData
  } else if (show === 'disqualified') {
    detailsTableData = disqualifiedData
  } else {
    detailsTableData = potentialData
  }

  if (loading) {
    return <Loading />
  }

  return (
    <section className='pt4' id='overview'>
      {headerLabel && (
        <header className='pl3-5'>
          <div>
            <h5 className='f5 mv3 fw3'>{headerLabel}</h5>
          </div>
        </header>
      )}
      <SpendReportDetails
        show={show}
        qualificationType='Sustainability'
        path={path.sustainabilitySpendReport}
        qualifiedTotal={qualifiedTotal}
        disqualifiedTotal={disqualifiedTotal}
        potentialTotal={potentialTotal}
        totalSpend={totalSpend}
        detailsTableData={detailsTableData}
        selectedSubCategories={selectedSubCategories}
        overviewSubCategories={overviewSubCategories}
      />
    </section>
  )
}

export default SustainabilitySpendDetails
