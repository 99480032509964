import { call, put, fork, delay } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import createSubmitSaga from 'shared/utils/sagas/createSubmitSaga'
import { LOGIN } from '../../actionTypes'
import { requestSuccess } from 'shared/actions'
import auth from 'shared/utils/api/auth'
import user from 'shared/utils/api/user'
import storageManager from 'shared/utils/storageManager'
import supplierPaths from 'supplier/routes/paths'
import buyerPaths from 'buyer/routes/paths'
import accountsPaths from 'accounts/routes/paths'
import { displayWaitingToCreateOrg } from '../../actions'
import analytics from 'shared/utils/analytics'
import JwtDecode from 'jwt-decode'

export function* createPasswordSaga(action) {
  const {
    password,
    user: newUser,
    token,
    redirectTo,
    orgUnitId
  } = action.payload

  const { buyerOrgUnit, existingUser } = JwtDecode<{
    buyerOrgUnit: boolean
    existingUser: boolean
  }>(token)

  // display a waiting screen if the creating user is taking too long
  if (!buyerOrgUnit) {
    yield fork(displayWaitingToCreateOrgSaga)
  }

  const tokens = yield call(auth.createPassword, {
    password,
    token,
    user: newUser,
    orgUnitId
  })
  storageManager.setItem('accessToken', tokens.access)
  storageManager.setItem('refreshToken', tokens.refresh)

  if (!!buyerOrgUnit && !existingUser) {
    yield put(push(accountsPaths.requestAdminApproval))
    return
  }

  const { userInfo, orgUnit } = yield call(user.get)

  analytics.identifyUser(userInfo, orgUnit)

  auth.saveLastLoggedInUser({
    username: userInfo.email,
    selectedOrgUnit: userInfo.orgUnitId
  })

  yield put(
    requestSuccess(LOGIN, {
      userInfo,
      orgUnit
    })
  )

  // default to buyer app
  const startingRoute = userInfo.roles?.includes('buyer')
    ? buyerPaths.basePath
    : // new supplier users go through the supplier onboarding flow
    userInfo.roles?.includes('supplier') && newUser
    ? supplierPaths.onboarding
    : supplierPaths.basePath

  yield put(push(redirectTo || startingRoute))
}

export function* displayWaitingToCreateOrgSaga() {
  yield delay(1000)
  yield put(displayWaitingToCreateOrg())
}

export default createSubmitSaga(createPasswordSaga)
