import React from 'react'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import ExternalLink from 'shared/components/ExternalLink'
import chatbot from 'shared/utils/chatbot'

const DisclosureAgreement = () => {
  return (
    <>
      <Text className='mb3'>
        <FormattedMessage
          id='DiversityReport.Tier2HelpNDA1'
          defaultMessage='We are committed to protecting the information you entrust us with. {supplierNonDisclosureAgreement} signed by our CEO, Stephany Lapierre, outlines our promise to protect your confidential information.'
          values={{
            supplierNonDisclosureAgreement: (
              <ExternalLink
                href={`${window.location.host}/downloads/Tealbook_Supplier_NDA.pdf`}
              >
                <FormattedMessage
                  id='DiversityReport.DownloadAgreement'
                  defaultMessage='Our Supplier Non-Disclosure Agreement'
                />
              </ExternalLink>
            )
          }}
        />
      </Text>
      <Text>
        <FormattedMessage
          id='DiversityReport.Tier2HelpNDA2'
          defaultMessage='{kbArticle} explains how we handle the supplier data uploaded on TealBook. Please let us know if you have any questions about our Privacy Policy and Non-Disclosure Agreement through our {contactSupport}!'
          values={{
            kbArticle: (
              <ExternalLink
                href={`https://tealbookhelp.zendesk.com/hc/en-us/articles/8693890522004`}
              >
                <FormattedMessage
                  id='DiversityReport.kbArticle'
                  defaultMessage='This article'
                />
              </ExternalLink>
            ),
            contactSupport: (
              <span className='teal pointer dim' onClick={chatbot.open}>
                <FormattedMessage
                  id='DiversityReport.SupportCenter'
                  defaultMessage={`Support Center`}
                />
              </span>
            )
          }}
        />
      </Text>
    </>
  )
}

export default DisclosureAgreement
