import React, { ChangeEvent, useMemo, useState } from 'react'
import Page from 'shared/components/Page'
import Paper from 'shared/components/Paper'
import { FormattedMessage, useIntl } from 'react-intl'
import Label from 'shared/components/Label'
import paths from 'admin/routes/paths'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import DateRangeSelect from 'shared/components/DateRangeSelect'
import { DateRange } from 'materialui-daterange-picker'
import FileUploadTitle from './FileUploadTitle'
import { defineMessages } from 'react-intl'
import * as fns from 'date-fns'
import { BusinessContextAnswersType } from './FileUploadTab'

export const messages = defineMessages({
  deleteSpendData: {
    id: 'FileUploadStep2.deleteSpendData1',
    defaultMessage: 'Would you like any existing spend data to be removed?'
  },
  dateRangeForDeletion: {
    id: 'FileUploadStep2.dateRangeForDeletion',
    defaultMessage: 'Please provide the date range for removing spend data.'
  }
})

const useStyles = makeStyles(() => ({
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold'
  },
  button: {
    float: 'right',
    marginTop: '6px'
  }
}))
type Props = {
  onSaveAnswers: (answers: BusinessContextAnswersType) => void
}

const FileUploadStep2 = (props: Props) => {
  const history = useHistory()
  const classes = useStyles()
  const intl = useIntl()

  const [deleteSpendData, setDeleteSpendData] = useState<string>('')
  const [dateRangeForDeletion, setDateRangeForDeletion] = useState<DateRange>(
    {}
  )
  const allQuestionsAnswered = useMemo(() => {
    return deleteSpendData === 'no'
      ? true
      : !!dateRangeForDeletion.startDate && !!dateRangeForDeletion.endDate
  }, [deleteSpendData, dateRangeForDeletion])

  const formatDateRange = (dateRange: DateRange) => {
    const format = 'MM/DD/YYYY'
    const startDate = dateRange?.startDate
      ? fns.format(dateRange.startDate, format)
      : undefined

    const endDate = dateRange?.endDate
      ? fns.format(dateRange.endDate, format)
      : undefined
    return startDate || endDate ? `${startDate} - ${endDate}` : 'No'
  }

  const handleClickNextButton = () => {
    history.push(paths.fileUploadLastStep)

    const answers = {
      deleteSpendData,
      dateRangeForDeletion: formatDateRange(dateRangeForDeletion),
      modified: true
    }
    props.onSaveAnswers(answers)
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='FileUploadStep2.Title'
          defaultMessage='Upload your file'
        />
      }
    >
      <FileUploadTitle />
      <Paper>
        <div className='mt3'></div>
        <Grid
          container
          direction='row'
          alignItems='flex-start'
          justify='space-between'
          className='mt2'
          spacing={2}
        >
          <Grid item xs>
            <Box className='mb3'>
              <Label htmlFor='deleteSpendData'>
                {intl.formatMessage(messages.deleteSpendData)}
              </Label>
            </Box>

            <Select
              name='deleteSpendData'
              value={deleteSpendData}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setDeleteSpendData(e.target.value)
                setDateRangeForDeletion({})
              }}
            >
              <FormattedMessage
                id='FileUploadStep2.selectAnOption'
                defaultMessage='Select an option'
              >
                {message => <option value=''>{message}</option>}
              </FormattedMessage>
              <FormattedMessage id='FileUploadStep2.Yes' defaultMessage='Yes'>
                {message => <option value='yes'>{message}</option>}
              </FormattedMessage>
              <FormattedMessage id='FileUploadStep2.No' defaultMessage='No'>
                {message => <option value='no'>{message}</option>}
              </FormattedMessage>
            </Select>
            <br />
            {deleteSpendData === 'yes' && (
              <>
                <Label htmlFor='dateRangeForDeletion'>
                  {intl.formatMessage(messages.dateRangeForDeletion)}
                </Label>
                <DateRangeSelect
                  dateRange={dateRangeForDeletion}
                  onDateRangeChange={setDateRangeForDeletion}
                  id='dateRangeForDeletion'
                />
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Button
        className={classes.button}
        onClick={() => handleClickNextButton()}
        label={<FormattedMessage id='FileUpload.Next' defaultMessage='Next' />}
        disabled={!allQuestionsAnswered}
      />
    </Page>
  )
}

export default FileUploadStep2
