export const LOAD_ALL_SURVEY = 'supplier/Survey/LOAD_ALL_SURVEY'
export const LOAD_SURVEY_By_ID = 'supplier/Survey/LOAD_SURVEY_BY_ID'
export const SAVE_SURVEY = 'supplier/Survey/SAVE_SURVEY'
export const REMOVE_SURVEY = 'supplier/Survey/REMOVE_SURVEY'
export const SUBMIT_SURVEY = 'supplier/Survey/SUBMIT_SURVEY'
export const LOAD_SURVEY_RESPONSE = 'supplier/Survey/LOAD_SURVEY_RESPONSE'
export const SAVE_SURVEY_RESPONSE = 'supplier/Survey/SAVE_SURVEY_RESPONSE'
export const UPLOAD_SURVEY_ATTACHMENT =
  'supplier/Survey/UPLOAD_SURVEY_ATTACHMENT'
export const REMOVE_SURVEY_ATTACHMENT =
  'supplier/Survey/REMOVE_SURVEY_ATTACHMENT'
export const TAKE_ESG_SURVEY = 'supplier/Survey/TAKE_ESG_SURVEY'
export const EDIT_SURVEY = 'supplier/Survey/EDIT_SURVEY'
export const OPEN_COLLABORATE_INVITE_DIALOG =
  'supplier/Survey/OPEN_COLLABORATE_INVITE_DIALOG'
export const CLOSE_COLLABORATE_INVITE_DIALOG =
  'supplier/Survey/CLOSE_COLLABORATE_INVITE_DIALOG'
export const LOAD_ALL_SURVEY_INVITATIONS =
  'supplier/Survey/LOAD_ALL_SURVEY_INVITATIONS'
