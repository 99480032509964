import React, { Component } from 'react'
import VideosEditForm from './VideosEditForm'
import BlogUrlEditForm from './BlogUrlEditForm'

type Props = {
  isTealbot: boolean
  isFrozen: boolean
}
class ResourcesSection extends Component<Props> {
  render() {
    const { isFrozen, isTealbot } = this.props
    return (
      <div>
        <VideosEditForm isFrozen={isFrozen} isTealbot={isTealbot} />
        <BlogUrlEditForm isFrozen={isFrozen} isTealbot={isTealbot} />
      </div>
    )
  }
}

export default ResourcesSection
