import { call } from 'redux-saga/effects'
import insight from 'shared/utils/api/insight'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* loadSpendSaga(action) {
  const { diverse, preferred, ...rest } = action.payload
  const buyerTotalSpend = yield call(insight.getTotalSpend, rest)
  const overview = yield call(insight.loadBuyerSpend, {
    diverse,
    preferred,
    ...rest
  })
  overview.totalSpend = buyerTotalSpend.totalAmount
  return yield overview
}

export default createApiRequestSaga(loadSpendSaga)
