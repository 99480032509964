import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'

export function* updateLocationSaga(action) {
  let updatedOrgUnit = yield call(supplier.updateLocation, action.payload)

  return yield updatedOrgUnit.supplier.locations
}

export default createApiRequestSaga(updateLocationSaga)
