import React, { useState } from 'react'
import { DateRange, DateRangePicker } from 'materialui-daterange-picker'
import DateRangeIcon from '@material-ui/icons/DateRange'
import IconButton from '@material-ui/core/IconButton'
import * as fns from 'date-fns'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

type Props = {
  dateRange: DateRange
  onDateRangeChange: (dateRange: DateRange) => void
  id: string
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export const DateRangeSelect = (props: Props) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const format = 'MM/DD/YYYY'

  const toggle = () => setOpen(!open)

  const getDisplayDateRange = (dateRange: DateRange): string => {
    const startDate = dateRange?.startDate
      ? fns.format(dateRange.startDate, format)
      : undefined

    const endDate = dateRange?.endDate
      ? fns.format(dateRange.endDate, format)
      : undefined

    return startDate || endDate ? `${startDate} - ${endDate}` : ''
  }

  return (
    <>
      <TextField
        id={props.id}
        value={getDisplayDateRange(props.dateRange) || format}
        variant='outlined'
        onClick={toggle}
        InputProps={{
          endAdornment: (
            <IconButton>
              <DateRangeIcon />
            </IconButton>
          )
        }}
      />
      <Modal
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div>
            <DateRangePicker
              open={true}
              toggle={toggle}
              onChange={range => {
                props.onDateRangeChange(range)
                toggle()
              }}
              initialDateRange={props.dateRange}
            />
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default DateRangeSelect
