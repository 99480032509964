import React, {
  useState,
  ChangeEvent,
  useRef,
  FormEvent,
  ReactNode,
} from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Popover from '@material-ui/core/Popover'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import dateFormat from 'shared/utils/dateFormat'
import RadioButton from 'shared/components/RadioButton'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import TextArea from 'shared/components/TextArea'
import DatePicker from 'shared/components/DatePicker'
import { Moment } from 'moment'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import useThemeColors from 'shared/utils/useThemeColors'
import IconButton from 'shared/components/IconButton'
import { productLevelCertAgency } from 'shared/utils/api/insight/insight'

type CertValidation = {
  category: string
  subCategory: string
  timeStamp: string
  confirmed: boolean
  userId: string
  date: string
  validationExpires?: string
  certificationNumber?: string
  note?: string
}

type Props = {
  category?: 'diversity' | 'quality' | 'security' | 'sustainability' | 'food'
  subCategory: string
  categoryTimeStamp: string
  orgUnitName?: string
  validation?: CertValidation
  onAddValidation?: (validation: Partial<CertValidation>) => void
  onRemoveCertification?: () => void
  verifiedIcon?: ReactNode
  certAgency?: string
}

const CertificationValidation = (props: Props) => {
  const { category = 'diversity', certAgency = '' } = props
  const colors = useThemeColors()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openPopover, setOpenPopover] = useState<boolean>(false)
  const [form, setForm] = useState<Partial<CertValidation>>({})
  const popoverAnchorEl = useRef<HTMLElement>()

  const isProductLevelCertAgency = productLevelCertAgency.includes(certAgency)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const newValidation = Object.assign(
      {},
      {
        category,
        subCategory: props.subCategory,
        confirmed: !!form.confirmed,
        note: form.note,
        timeStamp: props.categoryTimeStamp,
      },
      form.confirmed && {
        validationExpires: form.validationExpires,
        certificationNumber: form.certificationNumber,
      }
    )

    if (!!props.onAddValidation) {
      props.onAddValidation(newValidation)
    }

    setOpenDialog(false)
    setOpenPopover(false)
  }
  return (
    <>
      {!props.validation ? (
        <button
          className={`${colors.primaryText} ${colors.primaryHover} f7 fw4 no-underline pointer bn bg-transparent`}
          onClick={() => setOpenDialog(true)}
          aria-label={`Verify certification for ${props.orgUnitName}`}
        >
          <FormattedMessage
            id='CertificationValidation.verifyThis'
            defaultMessage='Can you verify this?'
          />
        </button>
      ) : props.verifiedIcon ? (
        <IconButton
          buttonRef={popoverAnchorEl}
          onClick={() => {
            setOpenPopover(true)
          }}
          aria-label={`This certification for ${props.orgUnitName} of type ${
            props.subCategory
          } is ${
            props.validation.confirmed ? 'valid' : 'invalid'
          }, click for more details`}
        >
          {props.verifiedIcon}
        </IconButton>
      ) : (
        <Button
          buttonRef={popoverAnchorEl}
          onClick={() => setOpenPopover(true)}
          autoSize
          size='small'
          caution={!props.validation.confirmed}
          label={
            props.validation.confirmed ? (
              <FormattedMessage
                id='CertificationValidation.verified'
                defaultMessage='Verified...'
              />
            ) : (
              <FormattedMessage
                id='CertificationValidation.notvalid'
                defaultMessage='Invalid...'
              />
            )
          }
        />
      )}

      <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            <FormattedMessage
              id='CertificationValidation.dialogCaption'
              defaultMessage='Verify a certification'
            />
          </DialogTitle>
          <DialogContent>
            <RadioButtonGroup
              inline
              title={
                isProductLevelCertAgency ? (
                  <FormattedMessage
                    id='CertificationValidation.confirmProductLevelLabel'
                    defaultMessage={`Are the products you purchased from "{companyName}" certified according to the certifying agency's database?`}
                    values={{ companyName: props.orgUnitName }}
                  />
                ) : (
                  <FormattedMessage
                    id='CertificationValidation.confirmLabel'
                    defaultMessage={`Is this company "{companyName}"?`}
                    values={{ companyName: props.orgUnitName }}
                  />
                )
              }
              className='mt3'
              stack
            >
              <RadioButton
                name='confirmed'
                type='radio'
                label={
                  isProductLevelCertAgency ? (
                    <FormattedMessage
                      id='CertificationValidation.confirmProductLevelYes'
                      defaultMessage={`Yes, they are.`}
                    />
                  ) : (
                    <FormattedMessage
                      id='CertificationValidation.confirmYes'
                      defaultMessage={`Yes, it is.`}
                    />
                  )
                }
                required
                onChange={() =>
                  setForm({
                    ...form,
                    confirmed: true,
                  })
                }
              />
              <RadioButton
                name='confirmed'
                type='radio'
                label={
                  isProductLevelCertAgency ? (
                    <FormattedMessage
                      id='CertificationValidation.confirmProductLevelNo'
                      defaultMessage={`No, they are not.`}
                    />
                  ) : (
                    <FormattedMessage
                      id='CertificationValidation.confirmNo'
                      defaultMessage={`No, it is not.`}
                    />
                  )
                }
                required
                onChange={() =>
                  setForm({
                    ...form,
                    confirmed: false,
                  })
                }
              />
            </RadioButtonGroup>

            {!isProductLevelCertAgency && form.confirmed && (
              <>
                <Label htmlFor='certificationNumber'>
                  <FormattedMessage
                    id='CertificationValidation.certificateNumber'
                    defaultMessage={`Certificate number (optional)`}
                  />
                </Label>
                <Input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setForm({
                      ...form,
                      certificationNumber: e.target.value,
                    })
                  }}
                />

                <Label htmlFor='validationExpires'>
                  <FormattedMessage
                    id='CertificationValidation.validationExpires'
                    defaultMessage={`Valid until (optional)`}
                  />
                </Label>
                <DatePicker
                  name='validationExpires'
                  value={form.validationExpires}
                  minDate={new Date()}
                  onChange={(newDate: Moment) =>
                    setForm({
                      ...form,
                      validationExpires: newDate.toISOString(),
                    })
                  }
                />
              </>
            )}
            <Label htmlFor='note'>
              <FormattedMessage
                id='CertificationValidation.note'
                defaultMessage={`Note`}
              />
            </Label>
            <TextArea
              maxLength={200}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                setForm({
                  ...form,
                  note: e.target.value,
                })
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              label={
                <FormattedMessage
                  id='CertificationValidation.Ok'
                  defaultMessage='OK'
                />
              }
              type='submit'
              autoSize
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              onClick={() => setOpenDialog(false)}
              secondary
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>
      {props.validation && (
        <Popover
          open={openPopover}
          anchorEl={popoverAnchorEl.current}
          onClose={() => setOpenPopover(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div style={{ minWidth: 250 }} className='mw6'>
            {props.validation.userId && (
              <UserListItemContainer
                mini
                className='pa3 pb0'
                userId={props.validation.userId}
                secondaryText={
                  <FormattedMessage
                    id='CertificationValidation.verifiedDate'
                    defaultMessage={`verified {date}`}
                    values={{ date: dateFormat(props.validation.date) }}
                  />
                }
              />
            )}
            <div className='ph3 nmt2'>
              <Label>
                <FormattedMessage
                  id='CertificationValidation.certificationLabel'
                  defaultMessage={`Certification`}
                />
              </Label>
              {props.validation.confirmed ? (
                <Text>
                  <FormattedMessage
                    id='CertificationValidation.certificationValid'
                    defaultMessage={`Valid`}
                  />
                </Text>
              ) : (
                <Text>
                  <FormattedMessage
                    id='CertificationValidation.certificationInvalid'
                    defaultMessage={`Invalid`}
                  />
                </Text>
              )}

              {props.validation.confirmed &&
                props.validation.certificationNumber && (
                  <>
                    <Label>
                      <FormattedMessage
                        id='CertificationValidation.certificationNumberLabel'
                        defaultMessage={`Certificate Number`}
                      />
                    </Label>
                    <Text>{props.validation.certificationNumber}</Text>
                  </>
                )}

              {props.validation.confirmed &&
                props.validation.validationExpires && (
                  <>
                    <Label>
                      <FormattedMessage
                        id='CertificationValidation.validationExpiresLabel'
                        defaultMessage={`Valid Until`}
                      />
                    </Label>
                    <Text>
                      {dateFormat(props.validation.validationExpires)}
                    </Text>
                  </>
                )}

              {props.validation.note && (
                <>
                  <Label>
                    <FormattedMessage
                      id='CertificationValidation.noteLabel'
                      defaultMessage={`Note`}
                    />
                  </Label>
                  <Text>{props.validation.note}</Text>
                </>
              )}
            </div>

            <div className='ph3 pv2 bt b--light-gray tr mt2'>
              {!!props.onAddValidation && (
                <Button
                  onClick={() => setOpenDialog(true)}
                  autoSize
                  label={
                    <FormattedMessage
                      id='CertificationValidation.addNew'
                      defaultMessage='Add New'
                    />
                  }
                />
              )}

              {!!props.onRemoveCertification && (
                <Button
                  onClick={() => {
                    if (props.onRemoveCertification) {
                      setOpenPopover(false)
                      props.onRemoveCertification()
                    }
                  }}
                  autoSize
                  caution
                  className='ml2'
                  label={
                    <FormattedMessage
                      id='CertificationValidation.remove'
                      defaultMessage='Remove'
                    />
                  }
                />
              )}

              <Button
                secondary
                onClick={() => setOpenPopover(false)}
                className='ml2'
                autoSize
                label={
                  <FormattedMessage
                    id='CertificationValidation.Close'
                    defaultMessage='Close'
                  />
                }
              />
            </div>
          </div>
        </Popover>
      )}
    </>
  )
}

export default CertificationValidation
