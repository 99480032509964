import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import Page from 'shared/components/Page'
import ProfileCompleteness from '../../components/ProfileCompleteness'
import ViewsStatistics from '../ViewsStatistics'
import SearchMap from '../SearchMap'
import Connections from '../Connections'
import TagsEndorsement from '../TagsEndorsement'
import Loading from 'shared/components/Loading'
import statsSelectors from '../../../shared/selectors/statsSelectors'
import { FormattedMessage } from 'react-intl'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import { loadSupplierInsight } from 'supplier/shared/actions'

const InsightPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadSupplierInsight())
  }, [dispatch])

  const existing = useSelector(statsSelectors.getBuyerExisting)
  const preferred = useSelector(statsSelectors.getBuyerPreferred)
  const isLoading = useSelector(statsSelectors.isLoading)
  const hasStats = useSelector(statsSelectors.getStats).size > 1
  const getFollowerCount = useSelector(statsSelectors.getFollowerCount)
  const getConnectionCount = useSelector(statsSelectors.getConnectionCount)
  const showConnections =
    getFollowerCount ||
    !!getConnectionCount ||
    (existing && !!existing.size) ||
    (preferred && !!preferred.size)

  return isLoading ? (
    <Loading />
  ) : hasStats ? (
    <Page title='Insight'>
      <ScrollToPosition />
      <Main>
        <ProfileCompleteness />
        <ViewsStatistics />
        <SearchMap />
        <TagsEndorsement
          title={
            <FormattedMessage
              id='InsightPage.CustomerEndorsedOfferingsTitle'
              defaultMessage='Customer Endorsed Offerings'
            />
          }
          subtitle={
            <FormattedMessage
              id='InsightPage.CustomerEndorsedOfferingsSubtitle'
              defaultMessage='These offerings have been endorsed by existing and/or propspective customers.'
            />
          }
          fieldName='offerings'
        />
        <TagsEndorsement
          title={
            <FormattedMessage
              id='InsightPage.CustomerEndorsedDifferentiatorsTitle'
              defaultMessage='Customer Endorsed Differentiators'
            />
          }
          subtitle={
            <FormattedMessage
              id='InsightPage.CustomerEndorsedDifferentiatorsSubTitle'
              defaultMessage='These differentiators have been endorsed by existing and/or propspective customers.'
            />
          }
          fieldName='differentiators'
        />
      </Main>
      {showConnections && (
        <Aside>
          <Connections />
        </Aside>
      )}
    </Page>
  ) : null
}

export default InsightPage
