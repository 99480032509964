import { fromJS } from 'immutable'
import { requestSuccess, requestFailure, LOGOUT } from 'shared/actionTypes'
import {
  LOAD_SUPPLIER_PROFILE,
  UPDATE_RELATIONSHIP,
  REMOVE_RELATIONSHIP,
  UPDATE_RELATIONSHIP_ATTACHMENT,
  REMOVE_RELATIONSHIP_ATTACHMENT,
  RENEW_RELATIONSHIP_ATTACHMENT,
  ADD_SUPPLIER_COMMENT,
  REQUEST_BUYER_REFERENCE,
  TRANSFER_SPEND
} from '../../../SupplierProfile/actionTypes'

/**
 *
 * relationships: {
 *   byId,
 *   bySupplierId: relationshipId,
 *   isTransferringSpend: boolean
 * }
 *
 */
const initialState = fromJS({
  byId: {},
  bySupplierId: {},
  isTransferringSpend: false
})
const relationshipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case requestSuccess(LOAD_SUPPLIER_PROFILE):
      return action.payload.relationship.id
        ? state
          .setIn(
            ['byId', action.payload.relationship.id],
            fromJS(action.payload.relationship)
          )
          .setIn(
            ['bySupplierId', action.payload.supplier.id],
            action.payload.relationship.id
          )
        : state


    case TRANSFER_SPEND:
      return state.set('isTransferringSpend', true)

    case requestSuccess(TRANSFER_SPEND):
      return action.payload.transferFromRelationshipId
        ? state.updateIn(
          ['byId', action.payload.transferFromRelationshipId],
          relationship => {
            return relationship
              .updateIn(['internalSupplierId'], internalSupplierId => {
                return internalSupplierId?.filter(
                  id => id !== action.payload.internalSupplierId
                )
              })
              .updateIn(['spend', 'details'], details => {
                return details?.filter(
                  detail =>
                    detail.get('internalSupplierId') !==
                    action.payload.internalSupplierId
                )
              })
          }
        ).set('isTransferringSpend', false)
        : state
    case requestFailure(TRANSFER_SPEND):
      return state.set('isTransferringSpend', false)

    case requestSuccess(ADD_SUPPLIER_COMMENT):
      return action.payload.newRelationship
        ? mergeNewRelationship(state, action.payload.newRelationship)
        : state

    case requestSuccess(UPDATE_RELATIONSHIP):
      return action.payload.newRelationship
        ? mergeNewRelationship(state, action.payload.newRelationship)
        : state.mergeDeepIn(
          ['byId', action.payload.relationshipId],
          fromJS(action.payload.relationshipChanges)
        )

    case requestSuccess(REMOVE_RELATIONSHIP):
      return action.payload
        ? state
          .deleteIn(['byId', action.payload.relationshipId])
          .deleteIn(['bySupplierId', action.payload.supplierId])
        : state
    case requestSuccess(RENEW_RELATIONSHIP_ATTACHMENT):
    case requestSuccess(UPDATE_RELATIONSHIP_ATTACHMENT):
      const newState2 = action.payload.newRelationship
        ? mergeNewRelationship(state, action.payload.newRelationship)
        : state

      return newState2.updateIn(
        ['byId', action.payload.relationshipId, 'attachments'],
        attachments =>
          attachments
            ? attachments.push(fromJS(action.payload.attachment))
            : fromJS([action.payload.attachment])
      )

    case requestSuccess(REMOVE_RELATIONSHIP_ATTACHMENT):
      return state.updateIn(
        ['byId', action.payload.relationshipId, 'attachments'],
        attachments =>
          attachments.filterNot(
            attachment =>
              attachment.get('createdDate') ===
              action.payload.attachment.createdDate
          )
      )

    case requestSuccess(REQUEST_BUYER_REFERENCE):
      return state
        .update('bySupplierId', relsBySupplierId =>
          relsBySupplierId.set(action.payload.supplier, action.payload.id)
        )
        .update('byId', relsById =>
          relsById.has(action.payload.id)
            ? relsById.update(action.payload.id, relationship =>
              relationship.merge(fromJS(action.payload))
            )
            : relsById.set(action.payload.id, fromJS(action.payload))
        )

    case LOGOUT:
      return initialState
    default:
      return state
  }
}

function mergeNewRelationship(state, newRelationship) {
  return state
    .setIn(['byId', newRelationship.id], fromJS(newRelationship))
    .setIn(['bySupplierId', newRelationship.supplier], newRelationship.id)
}

export default relationshipsReducer
