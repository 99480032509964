import React, { useState } from 'react'
import Button from 'shared/components/Button'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import useThemeColors from 'shared/utils/useThemeColors'
import paths from '../../../routes/paths'
import Link from 'shared/components/Link'
import Text from 'shared/components/Text'
import { takeEsgSurvey } from '../../../Survey/actions'
import { useDispatch, useSelector } from 'react-redux'
import healthSafety from 'shared/assets/images/esg/icon-health-safety.svg'
import environment from 'shared/assets/images/esg/icon-environment.svg'
import diversity from 'shared/assets/images/esg/icon-diversity.svg'
import governance from 'shared/assets/images/esg/icon-governance.svg'
import privacy from 'shared/assets/images/esg/icon-privacy.svg'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import companySelectors from 'supplier/shared/selectors/companySelectors'
import RootState from 'shared/models/RootState'
import downloadAttachment from 'shared/utils/fileDownload/fileDownload'
import { RecordOf } from 'immutable'
import { AttachmentType } from 'supplier/Survey/reducers/surveyReducer/surveyReducer'

const messages = defineMessages({
  title: {
    id: 'Warning',
    defaultMessage: 'Warning'
  },
  continue: {
    id: 'Continue',
    defaultMessage: 'Continue'
  }
})

type Props = {
  surveyCertFile?: RecordOf<AttachmentType>
}

const SurveyResponse = (props: Props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const colors = useThemeColors()

  const [confirmRetake, setConfirmRetake] = useState<boolean>(false)
  const surveyCertFile = props.surveyCertFile
  const companyName = useSelector((state: RootState) =>
    companySelectors.getCompanyField(state, 'name')
  )
  const retakeSurvey = () => {
    dispatch(takeEsgSurvey())
  }

  return (
    <div className='mt3-5'>
      <div className='flex justify-between-ns flex-wrap'>
        <div className='ma2 mr4-ns flex flex-column items-center'>
          <img src={healthSafety} alt='Health and Safety' className='w2-5' />
          <Text>
            <FormattedMessage
              id='SustainabilitySurvey.HealthSafety'
              defaultMessage='Health and Safety'
            />
          </Text>
        </div>
        <div className='ma2 mr4-ns flex flex-column items-center'>
          <img
            src={environment}
            alt='Environment'
            className='w2-5'
            style={{ transform: 'scaleX(-1)' }}
          />
          <Text>
            <FormattedMessage
              id='SustainabilitySurvey.Environment'
              defaultMessage='Environment'
            />
          </Text>
        </div>
        <div className='ma2 mr4-ns flex flex-column items-center'>
          <img src={diversity} alt='Diversity' className='w2-5' />
          <Text>
            <FormattedMessage
              id='SustainabilitySurvey.Diversity'
              defaultMessage='Diversity'
            />
          </Text>
        </div>
        <div className='ma2 mr4-ns flex flex-column items-center'>
          <img src={governance} alt='Governance' className='w2-5' />
          <Text>
            <FormattedMessage
              id='SustainabilitySurvey.Governance'
              defaultMessage='Governance'
            />
          </Text>
        </div>
        <div className='ma2 mr4-ns flex flex-column items-center'>
          <img src={privacy} alt='Privacy' className='w2-5' />
          <Text>
            <FormattedMessage
              id='SustainabilitySurvey.Privacy'
              defaultMessage='Privacy'
            />
          </Text>
        </div>
      </div>
      <div className='mt2 flex justify-between items-center'>
        <div className='flex items-center'>
          <Text className='pr3'>
            <Link
              to={paths.esgSurvey}
              ariaLabel='View Survey Responses'
              className={colors.primaryText}
            >
              <FormattedMessage
                id='SurveyResponse.ResultLink'
                defaultMessage='View Survey Responses'
              />
            </Link>
          </Text>
          {surveyCertFile && (
            <Button
              variant='text'
              aria-label='Download certificate of completion'
              className={colors.primaryText}
              onClick={() =>
                downloadAttachment(
                  surveyCertFile?.get('fileName'),
                  'survey/sustainabilityCertification',
                  `${companyName} Certificate of Completion`
                )
              }
            >
              <FormattedMessage
                id='SurveyResponse.Certificate'
                defaultMessage='Download certificate of completion'
              />
            </Button>
          )}
        </div>
        <Button onClick={() => setConfirmRetake(true)}>
          <FormattedMessage
            id='SurveyResponse.Retake'
            defaultMessage='Retake Survey'
          />
        </Button>
      </div>
      <ConfirmationDialog
        open={confirmRetake}
        onClose={() => setConfirmRetake(false)}
        onConfirm={retakeSurvey}
        title={intl.formatMessage(messages.title)}
        confirmLabel={intl.formatMessage(messages.continue)}
      >
        <Text>
          <FormattedMessage
            id='SurveyResponse.RetakeWarning'
            defaultMessage='This action will overwrite your previous survey submission and you will lose all your saved answers and uploaded attachments. Do you wish to continue?'
          />
        </Text>
      </ConfirmationDialog>
    </div>
  )
}

export default SurveyResponse
