import React, { useState, ChangeEvent, FormEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import Text from 'shared/components/Text'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import isEmail from 'validator/lib/isEmail'
import { logout } from 'shared/actions'
import paths from 'accounts/routes/paths'

type Props = {
  open: boolean
  onVerified: () => void
}

const VerifyEmailDialog = (props: Props) => {
  const { open, onVerified } = props
  const dispatch = useDispatch()
  const currentUser = useSelector(sessionSelectors.getUser)
  const validEmail = currentUser?.get('email')
  const domain = validEmail?.split('@')[1]

  const [isVerificationFailure, setIsVerificationFailure] = useState<boolean>(
    false
  )
  const [showCreateAccount, setShowCreateAccount] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')

  const handleVerifyClick = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (email.trim().toLowerCase() === currentUser.get('email').toLowerCase()) {
      onVerified()
    } else {
      setIsVerificationFailure(true)
      setShowCreateAccount(true)
    }
  }

  const handleCreateAccountClick = () => {
    dispatch(logout({ redirect: `${paths.register}?campaign=sustainability` }))
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
    if (!e.currentTarget.value) {
      setIsVerificationFailure(false)
    }
  }

  return (
    <Dialog aria-labelledby='dialogTitle' open={!!open} disableBackdropClick>
      <DialogTitle id='dialogTitle'>
        <FormattedMessage
          id='VerifyEmailDialog.Header'
          defaultMessage={'Sustainability Survey Request'}
        />
      </DialogTitle>
      <form onSubmit={handleVerifyClick}>
        <DialogContent>
          <Text className='mt3'>
            <FormattedMessage
              id='VerifyEmailDialog.Message'
              defaultMessage={
                'Please enter the email address that received the survey invitation in order to begin the survey'
              }
            />
          </Text>
          <Label htmlFor='email'>
            <FormattedMessage
              id='VerifyEmailDialog.EmailAddress'
              defaultMessage={'Email Address'}
            />
          </Label>
          <Input name='email' value={email} onChange={handleEmailChange} />
          {isVerificationFailure && !!email ? (
            <Text className='dark-red'>
              <FormattedMessage
                id='VerifyEmailDialog.Invalid'
                defaultMessage={
                  'Please enter the correct email address to proceed.'
                }
              />
            </Text>
          ) : (
            <Text secondary>
              <FormattedMessage
                id='VerifyEmailDialog.Domain'
                defaultMessage={'Valid email domain: @{domain}'}
                values={{ domain }}
              />
            </Text>
          )}
        </DialogContent>
        <DialogActions>
          <div className='w-100 flex items-end justify-between'>
            <div>
              {showCreateAccount && (
                <>
                  <Text>
                    <FormattedMessage
                      id='VerifyEmailDialog.NoAccess'
                      defaultMessage={`Don't have access?`}
                    />
                  </Text>
                  <Button
                    variant='outlined'
                    onClick={handleCreateAccountClick}
                    className='ph0'
                  >
                    <FormattedMessage
                      id='VerifyEmailDialog.Create'
                      defaultMessage={`Create a TealBook Account`}
                    />
                  </Button>
                </>
              )}
            </div>
            <Button
              type='submit'
              autoSize
              disabled={!isEmail(email) || !email.endsWith(domain)}
            >
              <FormattedMessage
                id='VerifyEmailDialog.Verify'
                defaultMessage={'Verify'}
              />
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default VerifyEmailDialog
