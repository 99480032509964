import SessionRecord from './SessionRecord'
import { LOGIN, DISPLAY_WAITING_TO_CREATE_ORG } from 'accounts/actionTypes'
import { LOGOUT, requestSuccess, requestFailure } from 'shared/actionTypes'
import { actionTypes } from 'redux-form'
import { AccessControlRecord } from 'shared/models/AccessControl'

const sessionReducer = (state = new SessionRecord(), action) => {
  switch (action.type) {
    case requestSuccess(LOGIN):
      return state.merge({
        logoutRedirect: '',
        statusIsKnown: true,
        loggedIn: true,
        userId: action.payload.userInfo.id,
        partnerConcierge: !!action.payload.partnerConcierge,
        accessControl: new AccessControlRecord({
          access: action.payload.accessControl
        })
      })
    case requestFailure(LOGIN):
      return state.set('statusIsKnown', true)

    case LOGOUT:
      return state.merge({
        loggedIn: false,
        userId: '',
        partnerConcierge: false,

        // status known is reset when leaving tealbot
        statusIsKnown: !action.payload?.isTealbot,
        logoutRedirect: action.payload?.redirect || ''
      })

    case DISPLAY_WAITING_TO_CREATE_ORG:
      return state.set('displayCreatingOrgPage', true)

    case actionTypes.STOP_SUBMIT:
      return action.meta.form === 'createPassword'
        ? state.set('displayCreatingOrgPage', false)
        : state

    default:
      return state
  }
}

export default sessionReducer
