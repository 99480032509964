import { List, Map } from 'immutable'
import { createSelector } from 'reselect'
import startCase from 'lodash.startcase'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import RootState from 'shared/models/RootState'

export const getSearch = (state: RootState) => state.getIn(['buyer', 'search'])
export const getQ = state => getSearch(state).get('q')
export const getSearchId = state => state.getIn(['buyer', 'search', 'searchId'])
// export const getResultSupplierIds = state =>
//   getSearch(state).get('resultSupplierIds')
export const getResults = state => getSearch(state).get('results')
export const getResultItemBySupplierId = (state, supplierId) => {
  return getResults(state).find(r => r.getIn(['supplier', 'id']) === supplierId)
}
export const getSupplierIds = createSelector(getResults, results =>
  results.map(item => item.getIn(['supplier', 'id']))
)

export const getResultsInGroup = createSelector(getResults, results => {
  let newResults = List()
  results.forEach(item => {
    const index = newResults.findIndex(
      newItem =>
        newItem.get('orgUnit') === item.getIn(['supplier', 'parentOrgId'])
    )
    newResults =
      index !== -1 && index !== undefined
        ? newResults.updateIn([index, 'orgUnits'], orgUnits =>
            orgUnits.push(item)
          )
        : newResults.push(
            Map({
              orgUnit: item.getIn(['supplier', 'parentOrgId']),
              orgName: item.getIn(['supplier', 'parentName']),
              numOrgUnits: item.getIn(['supplier', 'numOrgUnits']),
              orgUnits: List([item])
            })
          )
  })
  return newResults
})

export const getOrgUnitsByGroupIndex = createSelector(
  (state, index) => getResultsInGroup(state).getIn([index, 'orgUnits']),
  orgUnits => orgUnits
)

export const showDetails = state =>
  state.getIn(['buyer', 'search', 'showDetails'])
export const getFormattedResults = createSelector(
  sessionSelectors.getUserId,
  getResults,
  (userId, results) => {
    return results.map(result => {
      const myCard = result
        .get('cards')
        .find(card => card.getIn(['parents', 'User']) === userId)
      const myTags = (myCard && myCard.get('tags')) || List([])
      const peerTags = result.getIn(['relationship', 'peerTags']) || Map({})
      const tags = peerTags
        .map((v, k) =>
          v.merge({
            key: k,
            mine: myTags.includes(k)
          })
        )
        .toList()
        .sort((a, b) => {
          let isMine
          if (a.get('mine') === b.get('mine')) {
            isMine = 0
          } else if (b.get('mine')) {
            isMine = 1
          } else {
            isMine = -1
          }
          return isMine || b.get('count') - a.get('count')
        })
      return result.merge({ tags: tags, myCardId: myCard && myCard.get('id') })
    })
  }
)
export const getResultsCount = state => getSearch(state).get('resultsCount')
export const isSearching = (state: RootState) =>
  state.getIn(['buyer', 'search', 'searching'])
export const isAggregating = (state: RootState) =>
  state.getIn(['buyer', 'search', 'aggregating'])

const defaultMap = Map({})
export const getAggregations = state =>
  getSearch(state).get('aggregations') || defaultMap
export const getScrollPosition = state => getSearch(state).get('scrollPosition')

export const getAggregationsKeyValue = (state: RootState, key: string) =>
  state.getIn(['buyer', 'search', 'aggregations', key]) || List([])

const getAttachments = state =>
  getAggregations(state).get('attachments') || List([])
export const getSupplierAuthorizations = createSelector(
  getAttachments,
  settingsSelectors.getAttachmentTypes,
  (attachments, attachmentTypes) => {
    return attachments.map(att => {
      const buyerAtt = attachmentTypes && attachmentTypes.get(att.get('key'))
      return {
        value: att.get('key'),
        label: buyerAtt ? buyerAtt.get('label') : att.get('key'),
        count: att.get('doc_count')
      }
    })
  }
)
const getAttributes = state =>
  getAggregations(state).get('attributes') || List([])
export const getCorporateRelationship = createSelector(
  getAttributes,
  settingsSelectors.getRelationshipAttributes,
  (attributes, relationshipAttributes) => {
    return attributes.map(att => {
      const buyerAtt =
        relationshipAttributes && relationshipAttributes.get(att.get('key'))
      return {
        value: att.get('key'),
        label: buyerAtt ? buyerAtt.get('label') : startCase(att.get('key')),
        count: att.get('doc_count')
      }
    })
  }
)

export const getCertificationCategory = (state, category) =>
  getAggregations(state).get(category) || List([])
export const getCertificationRootCategory = (state, category) =>
  getCertificationCategory(state, category).find(c => c.get('key') === category)

export const getCertSubTypes = createSelector(
  (state: RootState, cert: string) =>
    getAggregations(state).get(`${cert}.subTypes`),
  subTypes => subTypes
)

export const getCommunitiesName = state =>
  state.getIn(['buyer', 'search', 'communities'])

export const getCommunities = createSelector(
  state => getAggregations(state).get('communities') || List([]),
  getCommunitiesName,
  (communities, communitiesLabel) => {
    return communities.map(comm => {
      return {
        value: comm.get('key'),
        label: communitiesLabel.get(comm.get('key')),
        count: comm.get('doc_count')
      }
    })
  }
)

export const getCommunityStatus = createSelector(
  state => getAggregations(state).get('communityStatus') || List([]),
  status =>
    status.map(s => ({
      value: s.get('key'),
      label: s.get('key'),
      count: s.get('doc_count')
    }))
)

export const getTags = state => {
  const tags = getAggregations(state).get('tags') || List([])
  return tags.map(tag => {
    return {
      value: tag.get('key'),
      label: startCase(tag.get('key')),
      count: tag.get('doc_count')
    }
  })
}

const getSuggestionTags = state =>
  state.getIn(['buyer', 'search', 'aggregations', 'tags']) || List([])
const getSuggestionSupplierTags = state =>
  state.getIn(['buyer', 'search', 'aggregations', 'supplierTags']) || List([])

export const getSuggestion = createSelector(
  getSuggestionTags,
  getSuggestionSupplierTags,
  (peerTags, supplierTags) => {
    const tags = peerTags
      .map(tag => startCase(tag.get('key')))
      .toOrderedSet() // remove duplicated tag
      .toList()

    const suggestions = supplierTags
      .map(tag => startCase(tag.get('key')))
      .toOrderedSet() // remove duplicated tag
      .toList()
      .filter(tag => !tags.includes(tag))

    return tags.concat(suggestions).map(tag => {
      return { value: tag, label: tag }
    })
  }
)

export const getLabel = state => {
  const labels = getAggregations(state).get('label') || List([])
  return labels.map(label => {
    return {
      value: label.get('key'),
      label: label.get('key'),
      count: label.get('doc_count')
    }
  })
}

export const getInternalCategory = createSelector(
  state => getAggregations(state).get('internalCategory'),
  (labels = List([])) =>
    labels.map(label => ({
      value: label.get('key'),
      label: label.get('key'),
      count: label.get('doc_count')
    }))
)

export const getRelationshipRating = state => {
  return getAggregations(state).get('relationshipRating') || List([])
}

const createLocationAggregatorSelector = (key: string) =>
  createSelector(
    state => getAggregations(state),
    aggregations => {
      const locations = aggregations.get(key) || List([])
      return locations.map(location => {
        const value = location.get('key')
        const valueArray = value.split('::')
        const label = valueArray[valueArray.length - 1] || 'Unspecified'
        return {
          value,
          label: label,
          count: location.get('doc_count')
        }
      })
    }
  )
export const getCountryAggregations = createLocationAggregatorSelector(
  'country'
)
export const getStateAggregations = createLocationAggregatorSelector('state')
export const getCityAggregations = createLocationAggregatorSelector('city')

export const getLocationQuery = state =>
  state.getIn(['buyer', 'search', 'location', 'locationQuery'])
export const isLocationSearching = state =>
  state.getIn(['buyer', 'search', 'location', 'searching'])
export const getLocationResults = createSelector(
  getLocationQuery,
  state => state.getIn(['buyer', 'search', 'location', 'results']),
  (query, results) => {
    if (results.size === 0) {
      return List([])
    }

    const country = results.get('country') || List([])
    const state = results.get('state') || List([])
    const city = results.get('city') || List([])

    return country
      .concat(state.concat(city))
      .filter(location => {
        return !location
          .get('key')
          .split('::')
          .includes('')
      })
      .map(location => {
        const keyArray = location.get('key').split('::')
        return {
          value: location.get('key'),
          label: keyArray.reverse().join(', '),
          count: location.get('doc_count')
        }
      })
  }
)

export const getRecentPercentile = createSelector(
  getAggregations,
  aggregations => {
    const recentPercentile = aggregations.get('recentPercentile') || List([])
    const mapLabels = [
      'No Spend',
      'Very Low',
      'Low',
      'Medium',
      'High',
      'Highest'
    ]

    return recentPercentile
      .sort((p1, p2) => p2.get('key') - p1.get('key'))
      .map(percentile => ({
        value: percentile.get('key'),
        label: mapLabels[percentile.get('key')],
        count: percentile.get('doc_count')
      }))
  }
)

export const getWarnings = createSelector(
  state => getAggregations(state).get('warnings'),
  (warnings = List([])) =>
    warnings.map(warning => ({
      value: warning.get('key'),
      label: startCase(warning.get('key')).replace(
        /\w\S*/g,
        (txt: string) =>
          `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`
      ),
      count: warning.get('doc_count')
    }))
)

export const getEmailAvailable = createSelector(
  state => getAggregations(state).get('emailAvailable'),
  (emailAvailable = List([])) =>
    emailAvailable.map(email => ({
      id: `emailAvailable-${email.get('key')}`,
      value: email.get('key_as_string'),
      label: email.get('key_as_string') === 'true' ? 'Yes' : 'No',
      count: email.get('doc_count')
    }))
)

export const getSustainabilitySurveyCompleted = createSelector(
  state => getAggregations(state).get('sustainabilitySurveyCompleted'),
  (supplierUpdated = List([])) =>
    supplierUpdated.map(supplier => ({
      id: `sustainabilitySurveyCompleted-${supplier.get('key')}`,
      value: supplier.get('key_as_string'),
      label: supplier.get('key_as_string') === 'true' ? 'Completed' : 'Incomplete',
      count: supplier.get('doc_count')
    }))
)

export const getSupplierUpdated = createSelector(
  state => getAggregations(state).get('supplierUpdated'),
  (supplierUpdated = List([])) =>
    supplierUpdated.map(supplier => ({
      id: `supplierUpdated-${supplier.get('key')}`,
      value: supplier.get('key_as_string'),
      label: supplier.get('key_as_string') === 'true' ? 'Yes' : 'No',
      count: supplier.get('doc_count')
    }))
)

export const getSupplierType = createSelector(
  state => getAggregations(state).get('supplierType'),
  (supplierType = List([])) =>
    supplierType
      .filter(type => !!type.get('key'))
      .map(type => ({
        id: `supplierType-${type.get('key')}`,
        value: type.get('key'),
        label: startCase(type.get('key')),
        count: type.get('doc_count')
      }))
)

export const getAvailableLogosCount = state =>
  getResults(state).filter(supplier => {
    return supplier.getIn(['supplier', 'supplier', 'logo'])
  }).size

export const getCardId = state => state.getIn(['buyer', 'search', 'cardId'])
export const getSupplierId = state =>
  state.getIn(['buyer', 'search', 'supplierId'])
export const getMyTags = createSelector(
  (state, supplierId, cardId) => ({ supplierId, cardId }),
  getResults,
  (ids, results) => {
    const result = results.find(
      result => result.getIn(['supplier', 'id']) === ids.supplierId
    )
    return ids.cardId
      ? {
          tags:
            (result && result.getIn(['cards', ids.cardId, 'tags'])) || List([]),
          peerTags:
            (result && result.getIn(['relationship', 'peerTags'])) || Map({})
        }
      : {
          tags: [],
          peerTags:
            (result && result.getIn(['relationship', 'peerTags'])) || Map({})
        }
  }
)
export const getReviewCard = state =>
  state.getIn(['buyer', 'search', 'reviewCard'])
export const getQueryStringByRef = (state, ref) =>
  state.getIn(['buyer', 'search', 'queryStringByRef', ref])

export const showFilterDialog = state =>
  state.getIn(['buyer', 'search', 'showFilterDialog'])

export const getSpendGroups = createSelector(
  state => getAggregations(state).get('spendGroups'),
  labels =>
    labels &&
    labels.map(label => ({
      value: label.get('key'),
      label: label.get('key'),
      count: label.get('doc_count')
    }))
)

export const getNaicsAggregationsByKey = createSelector(
  getAggregationsKeyValue,
  values =>
    values &&
    values.map(value => ({
      value: value.get('key'),
      label: value.get('name'),
      count: value.get('doc_count')
    }))
)
