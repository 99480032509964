import api from '../api'
import apiRoutes from 'shared/routes/apiRoutes'
import UploadedFile from 'shared/models/UploadedFile'
import Immutable, { RecordOf } from 'immutable'

const apiRoute = `${apiRoutes.authService}`
const fileToSignedUrl = (
    file: UploadedFile | RecordOf<UploadedFile>,
    apiPath
) => {
    if (file) {
        const fileObject = Immutable.isImmutable(file) ? file.toJS() : file
        const bucketName = fileObject.bucketName
        const fileName = fileObject.fileName
        if (bucketName && fileName) {
            return api
                .get(`${apiRoute}/${apiPath}`, {
                    fileName: fileName,
                    bucketName: bucketName
                })
                .then(response => {
                    return response.signedUrl
                })
                .catch(err => console.log('err', err))
        }
    }
}

export default fileToSignedUrl
