import { call } from 'redux-saga/effects'
import staging from 'shared/utils/api/staging'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* publishSpendSagaT2(action) {
  const { stagingId, listType, buyerIds } = action.payload
  return yield call(
    staging.publishSupplierSpendT2,
    { stagingId, listType, buyerIds }
  )
}

export default createApiRequestSaga(publishSpendSagaT2)
