import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Map, RecordOf, List } from 'immutable'
import { useSelector } from 'react-redux'

import Aside from 'shared/components/Layout/Aside'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import Main from 'shared/components/Layout/Main'
import Page from 'shared/components/Page'
import PageSection from 'shared/components/PageSection'
import PageSideSection from 'shared/components/PageSideSection'
import ScrollToPosition from 'shared/components/ScrollToPosition'

import createTagsForm from 'shared/utils/createTagsForm'
import analytics from 'shared/utils/analytics'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

import Contact from '../../../../shared/models/Contact'
import profileSelectors from '../../selectors/profileSelectors'

import AddAttachmentForm from '../../containers/AddAttachmentForm'
import CommentsAboutSupplier from '../../containers/CommentsAboutSupplier'
import PersonalTags from '../../containers/PersonalTags'
import RelationshipAttachments from '../../containers/RelationshipAttachments'
import ReviewsSectionContainer from '../../containers/ReviewsSectionContainer'
import SupplierVetsContainer from '../../containers/SupplierVetsContainer'

import {
  ADD_PERSONAL_RELATIONSHIP,
  REMOVE_PERSONAL_RELATIONSHIP,
} from '../../actionTypes'
import ContactsSection from '../ContactsSection'

const PersonalRelationshipsForm = createTagsForm({
  formName: 'buyer/personal/relationshps',
  fieldName: 'attributes',
  addTagActionType: ADD_PERSONAL_RELATIONSHIP,
  removeTagActionType: REMOVE_PERSONAL_RELATIONSHIP,
  tagsSelector: profileSelectors.getPersonalRelationship,
})

type Props = {
  hasPersonalEndorsements: boolean
  contacts: List<RecordOf<Contact>>
  isEditingContact: boolean
  isTealbot: boolean
  isBuyerAdmin: boolean
  supplierId: string
  contactToEdit: RecordOf<Contact>
  supplierName: string
  onRemoveContact: (arg: string) => void
  onRemoveContactRelationship: (params: {
    contactId: string
    supplierId: string
  }) => void
  onEditContact: (arg: Contact) => void
  onUpdateContact: (arg: Map<{}, {}>) => void
  onCancelEdit: () => void
  hasAttachments: boolean
}

const PersonalPage = (props: Props) => {
  const {
    hasPersonalEndorsements,
    contacts,
    isEditingContact,
    isTealbot,
    isBuyerAdmin,
    supplierId,
    onRemoveContact,
    onRemoveContactRelationship,
    onEditContact,
    onUpdateContact,
    onCancelEdit,
    contactToEdit,
    supplierName,
    hasAttachments,
  } = props

  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  useEffect(() => {
    analytics.track('Navigation Tab Clicked', {
      eventSource: 'Supplier Profile',
      action: 'Clicked',
      tabName: 'Personal',
      orgUnitId,
      orgUnitName,
    })
  }, [orgUnitId, orgUnitName])

  return (
    <Page title='Personal Notes'>
      <ScrollToPosition to={300} strict='up' />
      <Main>
        <ReviewsSectionContainer onlyMine />
        <CommentsAboutSupplier onlyMine />

        <PageSection
          title={
            <FormattedMessage
              id='PersonalPage.PersonalRelationship'
              defaultMessage='Personal Relationship'
            />
          }
          noPadding
        >
          <FormattedMessage
            id='PersonalPage.WorkedWithLikeFollowUp'
            defaultMessage='Worked With, Like, Follow Up'
          >
            {(message) => (
              <PersonalRelationshipsForm
                addLabel={
                  <FormattedMessage
                    id='PersonalPage.AddWhatDescribesYourRealtionship'
                    defaultMessage='Add what describes your relationship'
                  />
                }
                inputPlaceholder={message}
              />
            )}
          </FormattedMessage>
        </PageSection>
        <PageSection
          title={
            <FormattedMessage
              id='PersonalPage.MyContacts'
              defaultMessage='My Contacts'
            />
          }
          noPadding
        >
          <ContactsSection
            isEditable={true}
            contacts={contacts && contacts.toList()}
            isEditingContact={isEditingContact}
            isTealbot={isTealbot}
            isBuyerAdmin={isBuyerAdmin}
            supplierId={supplierId}
            onRemoveContact={onRemoveContact}
            onRemoveContactRelationship={onRemoveContactRelationship}
            onEditContact={onEditContact}
            onUpdateContact={onUpdateContact}
            onCancelEdit={onCancelEdit}
            contactToEdit={contactToEdit}
            supplierName={supplierName}
          />
        </PageSection>
        <PageSection
          noPadding
          title={
            <FormattedMessage
              id='MyCompanyPage.Attachments'
              defaultMessage='Attachments'
            />
          }
        >
          {hasAttachments && (
            <div className='ph3-5 pb3'>
              <RelationshipAttachments onlyMine />
            </div>
          )}
          <AddAttachmentForm />
        </PageSection>
        <ClearbitCredit />
      </Main>

      <Aside>
        <div className='pt4-l'>
          {hasPersonalEndorsements && (
            <PageSideSection
              title={
                <FormattedMessage
                  id='PersonalPage.PersonalEndorsements'
                  defaultMessage='Personal Endorsements'
                />
              }
            >
              <div className='mt3'>
                <PersonalTags />
              </div>
            </PageSideSection>
          )}
        </div>
        <SupplierVetsContainer onlyMine />
      </Aside>
    </Page>
  )
}

export default PersonalPage
