import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  SyntheticEvent,
  ChangeEvent
} from 'react'
import { useHistory, useLocation } from 'react-router'
import { connect, useDispatch } from 'react-redux'
import Immutable, { List, Map, RecordOf } from 'immutable'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import qs from 'qs'
import debounce from 'debounce'
import paths from '../../../routes/paths'
import { suggestSupplier } from '../../actions'
import { openFiltersDialog } from '../../../Search/actions'
import suggestSelectors from '../../selectors/suggestSelectors'
import searchSelectors from '../../../Search/selectors/searchSelectors'
import filterOptionsSelectors from '../../../Search/selectors/filterOptionsSelectors'
import parsePath from 'shared/utils/parsePath'
import SuggestSupplierItem from '../../components/SuggestSuppliers/SuggestSupplierItem'
import ImageSearch from 'shared/assets/icons/search.svg'
import ImageClear from 'shared/assets/icons/clear.svg'
import withOrg from 'shared/utils/withOrg'
import Hidden from '@material-ui/core/Hidden'
import FilterIcon from 'shared/assets/icons/filter.svg'
import NavIcon from 'shared/components/NavIcon'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import Button from 'shared/components/Button'
import SearchFaq from '../../../Search/components/SearchFaq'
import { CSSTransition } from 'react-transition-group'
import Scrollable from 'shared/components/Scrollable'
import { createSelector } from 'reselect'
import { Menu } from '@material-ui/core'
import useSmallScreen from 'shared/utils/useSmallScreen'
import MenuItem from 'shared/components/MenuItem'
import RootState from '../../../../shared/models/RootState'
import chatbot from 'shared/utils/chatbot'
import SearchOptions from '../../../Search/components/SearchOptions'
import Paper from 'shared/components/Paper'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'
import IconButton from 'shared/components/IconButton'
import { makeStyles } from '@material-ui/styles'

const SuggestSupplierContainer = withOrg(SuggestSupplierItem)
const navTopClass = 'pa2 pl3 bl b--light-gray tl f7'
const resultsPageClass =
  'pv2 ph2 ba b--black-10 bg-white br2 tl f5 flex-auto relative flex items-center justify-between'

const messages = defineMessages({
  searchForSupplier: {
    id: 'BuyerNavSearch.SearchForSupplier',
    defaultMessage: 'Search suppliers'
  },
  search: {
    id: 'BuyerNavSearch.Search',
    defaultMessage: 'Search'
  },
  needHelp: {
    id: 'MyVets.NeedHelp',
    defaultMessage: 'Need help?'
  },
  advancedOptionsTab: {
    id: 'BuyerNavSearch.AdvancedOptionsTab',
    defaultMessage: 'Advanced Options Tab'
  }
})

type Props = {
  disableSuggest?: boolean
  showButton?: boolean
  defaultPathOverride?: boolean
  isFilterActive: boolean
  suggest: RecordOf<{
    cache: Map<string, any>
  }>
  resultsInGroup: List<
    RecordOf<{
      orgUnit: string
      orgName: string
      numOrgUnits: number
      orgUnits: List<any>
    }>
  >
  isSearching: boolean
  q: string
  sort: string
  scope: string
  filter: object
  fixedFilters: Map<string, any>
  includeUnQualified: List<string>
  attachmentsExpiredExcluded: boolean
  similarSuppliers: List<string>
  suggestSupplier: (name: string) => void
}
const useStyles = makeStyles(() => ({
  searchPlaceHolder: {
    '&::placeholder': {
      color: '#707070',
      opacity: 1
    }
  },
  hoverOutline: {
    transition: 'filter 0.3s linear',
    '&:hover': {
      filter: 'brightness(0.9)'
    }
  }
}))

export const BuyerNavSearch: FC<Props> = props => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const [isSearchFaqOpen, setIsSearchFaqOpen] = useState<boolean>(false)
  const [isTealbotMenuOpen, setIsTealbotMenuOpen] = useState<boolean>(false)
  const [faqAnchorEl, setFaqAnchorEl] = useState<HTMLElement | null>(null)
  const [
    tealbotMenuAnchorEl,
    setTealbotMenuAnchorEl
  ] = useState<HTMLElement | null>(null)
  const [search, setSearch] = useState<{ q: string }>({ q: '' })
  const [showSuggest, setShowSuggest] = useState<boolean>(false)
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const debounceSuggestSupplier = debounce(props.suggestSupplier, 25)

  const searchFieldDiv = useRef<HTMLDivElement>(null)
  const advancedBtn = useRef<HTMLDivElement>(null)
  
  const updateQueryString = useCallback(
    (query: object = {}) => {
      const {
        defaultPathOverride,
        sort,
        scope,
        attachmentsExpiredExcluded,
        includeUnQualified,
        similarSuppliers
      } = props

      const { filter } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        arrayLimit: 100
      })
      const similarSuppliersMutable = Immutable.isImmutable(similarSuppliers)
        ? similarSuppliers.toJS()
        : similarSuppliers

      const { q } = search
      const queryString = Object.assign(
        {},
        {
          q,
          sort,
          scope,
          filter: filter,
          attachmentsExpiredExcluded,
          includeUnQualified: includeUnQualified?.toJS(),
          similarSuppliers: similarSuppliersMutable
        },
        query
      )

      history.push({
        pathname: defaultPathOverride ? location.pathname : paths.search,
        search: qs.stringify(queryString)
      })
    },
    [props, search, history, location.pathname, location.search]
  )

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    setShowSuggest(false)
    updateQueryString()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { suggest, disableSuggest } = props
    const { value } = e.target

    if (
      !disableSuggest &&
      value.length > 2 &&
      !suggest.get('cache').has(value)
    ) {
      debounceSuggestSupplier(value)
    }

    setSearch({ q: value })
    setAnchorEl(e.target)
    setShowSuggest(value !== '' && !disableSuggest)
  }

  const handleClearSearchString = () => {
    const queryString = { q: '' }
    setSearch({ q: '' })
    updateQueryString(queryString)
  }

  const toggleTealbotMenu = e => {
    setIsTealbotMenuOpen(!isTealbotMenuOpen)
    setTealbotMenuAnchorEl(e ? e.currentTarget : null)
  }

  const toggleSearchFaq = (e?: SyntheticEvent<HTMLElement>) => {
    if (!isSearchFaqOpen) {
      toggleTealbotMenu(e)
    }
    setIsSearchFaqOpen(!isSearchFaqOpen)
    setFaqAnchorEl(e ? e.currentTarget : null)
  }

  const goToSupplierProfile = (supplierId: string) => {
    setShowSuggest(false)
    history.push(parsePath(paths.supplierProfile, { supplierId }))
  }

  const handleOnClickFilter = () => {
    dispatch(openFiltersDialog())
  }

  useEffect(() => {
    const { q = '', ...otherSearchParams } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })

    setSearch({ q, ...otherSearchParams })
  }, [location.search])

  const { showButton, isFilterActive } = props
  const suppliers = props.suggest.getIn(['cache', search.q]) || List()

  const matchMobile = useSmallScreen()

  return (
    <>
      <form
        className='flex justify-between items-center'
        onSubmit={handleSubmit}
      >
        <div
          className={showButton ? resultsPageClass : navTopClass}
          ref={searchFieldDiv}
        >
          <img src={ImageSearch} alt='search' className='v-mid mr2' />
          <input
            type='text'
            name='search'
            placeholder={
              intl.formatMessage(messages.searchForSupplier) as string
            }
            value={search.q}
            onChange={handleChange}
            className={
              'f6 fw4 input-reset outline-0 b--none mid-gray pa0 v-mid dib flex-auto ' +
              classes.searchPlaceHolder
            }
            autoComplete='off'
            style={{ background: 'none' }}
            aria-label={
              intl.formatMessage(messages.searchForSupplier) as string
            }
          />
          {showButton && (
            <Hidden smDown>
              <Button
                size='small'
                autoSize
                secondary
                className='mr2'
                onClick={() => setShowOptions(true)}
                ref={advancedBtn}
              >
                <FormattedMessage
                  id='BuyerNavSearch.AvancedOptions'
                  defaultMessage='Advanced'
                />
              </Button>
            </Hidden>
          )}

          {showButton && (
            <IconButton
              onClick={handleClearSearchString}
              style={{ minWidth: 'auto' }}
            >
              <img
                src={ImageClear}
                alt='Clear search queries'
                className={'pointer ' + classes.hoverOutline}
                style={{ width: '20px' }}
              />
            </IconButton>
          )}

          {suppliers && suppliers.size > 0 && (
            <ClickAwayListener onClickAway={() => setShowSuggest(false)}>
              <Popper
                open={Boolean(showSuggest)}
                anchorEl={anchorEl}
                placement='bottom-start'
                style={{ marginTop: 10, zIndex: 101 }}
              >
                <div
                  className='bg-white bt br bb bl b--black-10 w-auto pa2 ma0 shadow-1'
                  style={
                    matchMobile
                      ? {
                          maxWidth: '330px',
                          minWidth: '250px',
                          boxShadow:
                            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
                        }
                      : {
                          minWidth: '250px',
                          boxShadow:
                            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
                        }
                  }
                  id='relevant'
                >
                  <Scrollable>
                    {suppliers.map(supplier => {
                      return (
                        <SuggestSupplierContainer
                          key={supplier.get('id')}
                          orgUnitId={supplier.get('id')}
                          onClick={() =>
                            goToSupplierProfile(supplier.get('id'))
                          }
                        />
                      )
                    })}
                  </Scrollable>
                </div>
              </Popper>
            </ClickAwayListener>
          )}
        </div>
        {showButton ? (
          <>
            <Hidden smDown>
              <Button
                type='submit'
                className='dtc ml2 fr'
                wrapperStyle={{ width: 100 }}
                size='large'
              >
                {intl.formatMessage(messages.search)}
              </Button>
              <CSSTransition
                in={showButton}
                timeout={300}
                classNames='tealbotHelp'
                unmountOnExit
              >
                <TealbotHelpIcon
                  show={showButton}
                  title={intl.formatMessage(messages.needHelp)}
                  placement='top-start'
                  imageClassName='w2 ml2 pointer'
                  onClick={toggleSearchFaq}
                />
              </CSSTransition>
              <CSSTransition
                in={isSearchFaqOpen}
                timeout={300}
                classNames='tealbotHelp'
                unmountOnExit
              >
                <SearchFaq
                  open={isSearchFaqOpen}
                  anchorEl={faqAnchorEl}
                  onClose={toggleSearchFaq}
                />
              </CSSTransition>
            </Hidden>
            <Hidden mdUp>
              <div className='flex items-center ml2 fr'>
                <Button type='submit' autoSize size='large'>
                  {intl.formatMessage(messages.search)}
                </Button>
                <IconButton onClick={handleOnClickFilter}>
                  <div className='dib mh1 mh2-ns pt1'>
                    <NavIcon
                      src={FilterIcon}
                      numberOfNotifications={isFilterActive ? 1 : 0}
                    />
                  </div>
                </IconButton>
                <div>
                  <TealbotHelpIcon
                    show={showButton}
                    title={intl.formatMessage(messages.needHelp)}
                    placement='top-start'
                    imageClassName='w2 pointer'
                    onClick={toggleTealbotMenu}
                  />
                  <CSSTransition
                    in={isSearchFaqOpen}
                    timeout={300}
                    classNames='tealbotHelp'
                    unmountOnExit
                  >
                    <SearchFaq
                      open={isSearchFaqOpen}
                      anchorEl={faqAnchorEl}
                      onClose={toggleSearchFaq}
                    />
                  </CSSTransition>
                  <Menu
                    open={isTealbotMenuOpen}
                    anchorEl={tealbotMenuAnchorEl}
                    onClose={toggleTealbotMenu}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    getContentAnchorEl={null}
                  >
                    <MenuItem onClick={toggleSearchFaq}>
                      <FormattedMessage
                        id='BuyerNavSearch.ViewHelp'
                        defaultMessage='View Help'
                      />
                    </MenuItem>
                    <MenuItem onClick={chatbot.open}>
                      <FormattedMessage
                        id='BuyerNavSearch.HelpMeFindSuppliers'
                        defaultMessage='Help Me Find Suppliers'
                      />
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </Hidden>
          </>
        ) : null}
      </form>
      {showOptions && (
        <ClickAwayListener onClickAway={() => setShowOptions(false)}>
          <Popper
            open={Boolean(showOptions)}
            anchorEl={searchFieldDiv?.current}
            placement='bottom-start'
            style={{ marginTop: 10, zIndex: 101 }}
            role='dialog'
            aria-label={intl.formatMessage(messages.advancedOptionsTab)}
          >
            <div
              style={{
                width: searchFieldDiv?.current
                  ? searchFieldDiv.current.clientWidth
                  : 1024
              }}
            >
              <Paper className='shadow-2 pt3-5'>
                <SearchOptions
                  searchString={search.q}
                  onDone={() => {
                    setShowOptions(false)
                  }}
                  onDoneKeyboard={() => {
                    advancedBtn?.current?.focus()
                    setShowOptions(false)
                  }}
                />
              </Paper>
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  )
}

const getFilterOptionsAsJS = createSelector(
  filterOptionsSelectors.getFilterOptions,
  (filterOptions: any) => filterOptions.toJS()
)

export default connect(
  (state: RootState) => {
    return {
      isFilterActive: filterOptionsSelectors.isActive(state),
      suggest: suggestSelectors.getSuggest(state),
      isSearching: suggestSelectors.isSearching(state),
      resultsInGroup: searchSelectors.getResultsInGroup(state),
      q: searchSelectors.getQ(state),
      sort: filterOptionsSelectors.getSort(state),
      scope: filterOptionsSelectors.getFilterScope(state),
      filter: getFilterOptionsAsJS(state),
      fixedFilters: settingsSelectors.getFixedFilters(state),
      includeUnQualified: filterOptionsSelectors
        .getByKey(state, 'includeUnQualified'),
      attachmentsExpiredExcluded: filterOptionsSelectors.getByKey(
        state,
        'attachmentsExpiredExcluded'
      ),
      similarSuppliers: filterOptionsSelectors.getByKey(
        state,
        'similarSuppliers'
      )
    }
  },
  {
    suggestSupplier
  }
)(BuyerNavSearch)
