import React, { Component, ReactNode, Fragment } from 'react'
import PageSection from 'shared/components/PageSection'
import Label from 'shared/components/Label'
import Select from 'shared/components/Select'
import Divider from 'shared/components/Divider'
import dateFormat from 'shared/utils/dateFormat'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import FileInput from 'shared/components/FileInput'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Text from 'shared/components/Text'
import PitchDeck from 'shared/components/PitchDeck'
import Warning from 'shared/components/Warning'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import { RecordOf, List } from 'immutable'
import downloadAttachment, {
  downloadAttachmentToURL
} from 'shared/utils/fileDownload/fileDownload'

type Props = {
  vetId?: string
  canModifyAdditions?: boolean
  additions: List<
    RecordOf<{
      active: string
      type: string
      date: string
      title: string
      description: string
      attachment: string
    }>
  >
  cancelVetAddition?: (arg: { vetId: string; index: string }) => void
  addVetAddition?: (arg: {
    vetId: string
    type: string
    description?: string
    attachment?: string
  }) => void
  allowWordDocs?: boolean
}

type State = {
  missingRequiredFields?: boolean
  newAddition: {
    type: string
    description?: string
    attachment?: string
  }
  dialogOpen?: string
  message?: string | ReactNode
  fileURLs: (string | void)[]
}

export class VetAdditions extends Component<Props, State> {
  state: State = {
    missingRequiredFields: undefined,
    newAddition: {
      type: '',
      description: '',
      attachment: ''
    },
    dialogOpen: undefined,
    message: undefined,
    fileURLs: []
  }

  componentDidMount() {
    const { additions } = this.props
    Promise.all(
      additions.map(addition =>
        this.getFilePreview(addition.getIn(['attachment', 'fileName']))
      )
    ).then(allFileUrls => this.setState({ fileURLs: allFileUrls }))
  }
  componentDidUpdate(prevProps) {
    const { additions } = this.props
    const newAttachmentDifference = additions.filter(
      x => !prevProps.additions.includes(x)
    )
    if (!!newAttachmentDifference.size) {
      this.getFilePreview(
        newAttachmentDifference.get(0)?.getIn(['attachment', 'fileName'])
      ).then(newFileUrls =>
        this.setState({ fileURLs: [...this.state.fileURLs, newFileUrls] })
      )
    }
  }
  handleDownload = fileName => {
    const { vetId } = this.props
    downloadAttachment(fileName, `vets/${vetId}/documentFile`)
  }

  getFilePreview = fileName => {
    const { vetId } = this.props
    return downloadAttachmentToURL(
      fileName,
      `vets/${vetId}/documentFile/signedUrl`
    )
  }
  closeDialog = () => {
    this.setState({
      dialogOpen: undefined
    })
  }

  confirmAdditions = () => {
    this.setState({
      message: (
        <FormattedMessage
          id='VetAdditions.NoMoreAdditions'
          defaultMessage='You will not be able to edit this Addition once added.'
        />
      ),
      dialogOpen: 'confirm'
    })
  }

  renderAdditionField = (label: ReactNode, value: string) => {
    if (!value) {
      return null
    }

    return (
      <div className='mt2 flex-ns'>
        <span className='f6 fw7 w4-ns db flex-shrink-0'>{label}:</span>
        <Text>{value}</Text>
      </div>
    )
  }

  handleChangeNewAddition = e => {
    this.setState({
      newAddition: {
        ...this.state.newAddition,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSubmitNewAddition = e => {
    e.preventDefault()
    const { newAddition } = this.state

    if (!newAddition.description && !newAddition.attachment) {
      this.setState({
        missingRequiredFields: true
      })
      return
    }
    this.confirmAdditions()
  }

  handleAddConfirmed = () => {
    const { addVetAddition, vetId } = this.props
    const { newAddition } = this.state

    if (addVetAddition && vetId) {
      addVetAddition({
        ...newAddition,
        vetId
      })
      this.setState({
        missingRequiredFields: false,
        newAddition: {
          type: ''
        },
        dialogOpen: undefined
      })
    }
  }

  handleClickCancel = () => {
    this.setState({
      newAddition: {
        type: ''
      }
    })
  }

  handleClickCancelVetAddition = index => () => {
    const { vetId, cancelVetAddition } = this.props
    if (vetId && cancelVetAddition) {
      cancelVetAddition({
        vetId,
        index
      })
    }
  }

  handleChangeNewAdditionAttachment = file => {
    this.setState({
      newAddition: {
        ...this.state.newAddition,
        attachment: file
      }
    })
  }

  render() {
    const { canModifyAdditions, additions, allowWordDocs } = this.props
    const { newAddition, missingRequiredFields, fileURLs } = this.state

    // if there are no additions and can't add new ones then don't show anything
    if ((!additions || additions.size < 1) && !canModifyAdditions) {
      return null
    }
    const fileTypes = [
      '.pdf',
      '.pptx',
      '.ppt',
      '.xlsx',
      '.csv',
      '.doc',
      '.docx'
    ]
    //filter for settings selector 'allowWordDocs'
    const acceptedFileTypes = allowWordDocs
      ? fileTypes.join(', ')
      : fileTypes.filter(doc => doc !== '.doc' && doc !== '.docx').join(', ')

    return (
      <PageSection
        title={
          <FormattedMessage
            id='VetAdditions.Additions'
            defaultMessage='Additions'
          />
        }
        noPadding
      >
        <Dialog
          onClose={this.closeDialog}
          open={this.state.dialogOpen === 'confirm'}
          fullWidth
        >
          <DialogTitle>
            <FormattedMessage
              id='VetAdditions.Confirmation'
              defaultMessage='Confirmation'
            />
          </DialogTitle>
          <DialogContent>
            <Text className='mt3'>{this.state.message}</Text>
          </DialogContent>
          <DialogActions>
            {this.state.dialogOpen === 'confirm' && (
              <Button
                autoSize
                label={
                  <FormattedMessage
                    id='VetAdditions.ADD'
                    defaultMessage='Add'
                  />
                }
                onClick={this.handleAddConfirmed}
              />
            )}

            <Button
              autoSize
              secondary={this.state.dialogOpen === 'confirm'}
              label={<FormattedMessage id='CancelButton' />}
              onClick={this.closeDialog}
            />
          </DialogActions>
        </Dialog>

        {additions && additions.size > 0 && (
          <div className='ph3-5 pb3-5'>
            {additions.map((addition, index) => {
              return (
                <Fragment key={index}>
                  <div className='flex mt3-5'>
                    <div className='flex-auto'>
                      <p className='f6 fw7 mb2 mt0'>
                        <span
                          className={!addition.get('active') ? 'strike' : ''}
                        >
                          <FormattedMessage
                            id='VetAdditions.addiontNumber'
                            defaultMessage='Addition &#35;{index}'
                            values={{ index: index + 1 }}
                          />
                        </span>
                        {!addition.get('active') && (
                          <span className='red ml3'>
                            <FormattedMessage
                              id='VetAdditions.Cancelled'
                              defaultMessage='Cancelled'
                            />
                          </span>
                        )}
                      </p>
                      {this.renderAdditionField(
                        <FormattedMessage
                          id='VetAdditions.Type'
                          defaultMessage='Type'
                        />,
                        addition.get('type')
                      )}
                      {this.renderAdditionField(
                        <FormattedMessage
                          id='VetAdditions.Date'
                          defaultMessage='Date'
                        />,
                        dateFormat(addition.get('date'))
                      )}
                      {this.renderAdditionField(
                        <FormattedMessage
                          id='VetAdditions.Title'
                          defaultMessage='Title'
                        />,
                        addition.get('title')
                      )}
                      {this.renderAdditionField(
                        <FormattedMessage
                          id='VetAdditions.Description'
                          defaultMessage='Description'
                        />,
                        addition.get('description')
                      )}

                      {addition.get('attachment') && (
                        <div className='mt3'>
                          <PitchDeck
                            url={
                              fileURLs.find(
                                fileUrl =>
                                  fileUrl &&
                                  fileUrl.includes(
                                    addition.getIn(['attachment', 'fileName'])
                                  )
                              ) || ''
                            }
                            fileName={addition.getIn([
                              'attachment',
                              'fileName'
                            ])}
                            onDownload={() =>
                              this.handleDownload(
                                addition.getIn(['attachment', 'fileName'])
                              )
                            }
                          />
                        </div>
                      )}
                    </div>

                    {canModifyAdditions && addition.get('active') && (
                      <DropDownMenu>
                        <MenuItem
                          onClick={this.handleClickCancelVetAddition(index)}
                        >
                          <FormattedMessage id='CancelButton' />
                        </MenuItem>
                      </DropDownMenu>
                    )}
                  </div>

                  {index < additions.size - 1 && <Divider className='mt3-5' />}
                </Fragment>
              )
            })}
          </div>
        )}

        {canModifyAdditions && (
          <form
            className='ph3-5 pb3-5 bg-near-white'
            onSubmit={this.handleSubmitNewAddition}
          >
            <Label htmlFor='type'>
              <FormattedMessage
                id='VetAdditions.AddanAddition'
                defaultMessage='Add an Addition'
              />
            </Label>
            <Select
              name='type'
              value={newAddition.type}
              onChange={this.handleChangeNewAddition}
              fullWidth
            >
              <FormattedMessage
                id='VetAdditions.SelectAdditionType'
                defaultMessage='Please select an Addition type'
              >
                {message => <option value=''>{message}</option>}
              </FormattedMessage>

              <FormattedMessage
                id='VetAdditions.Addition'
                defaultMessage='Addition'
              >
                {message => <option value='Addition'>{message}</option>}
              </FormattedMessage>

              <FormattedMessage
                id='VetAdditions.Attachment'
                defaultMessage='Attachment'
              >
                {message => <option value='Attachment'>{message}</option>}
              </FormattedMessage>

              <FormattedMessage
                id='VetAdditions.Clarification'
                defaultMessage='Clarification'
              >
                {message => <option value='Clarification'>{message}</option>}
              </FormattedMessage>

              <FormattedMessage
                id='VetAdditions.Correction'
                defaultMessage='Correction'
              >
                {message => <option value='Correction'>{message}</option>}
              </FormattedMessage>
            </Select>

            {!!newAddition.type && (
              <Fragment>
                <div className='pt3'>
                  <Label>
                    <FormattedMessage
                      id='VetAdditions.Title'
                      defaultMessage='Title'
                    />
                  </Label>
                  <FormattedMessage
                    id='VetAdditions.TitlePlaceholder'
                    defaultMessage='Title for this additional information'
                  >
                    {message => (
                      <Input
                        name='title'
                        maxLength={150}
                        onChange={this.handleChangeNewAddition}
                        placeholder={message as string}
                      />
                    )}
                  </FormattedMessage>

                  <Label>
                    <FormattedMessage
                      id='VetAdditions.Description'
                      defaultMessage='Description'
                    />
                  </Label>
                  <FormattedMessage
                    id='VetAdditions.WhatToShare'
                    defaultMessage='What would you like to share with the supplier?'
                  >
                    {message => (
                      <TextArea
                        name='description'
                        onChange={this.handleChangeNewAddition}
                        placeholder={message as string}
                      />
                    )}
                  </FormattedMessage>

                  <Label>
                    <FormattedMessage
                      id='VetAdditions.Attachment'
                      defaultMessage='Attachment'
                    />
                  </Label>
                  <FileInput
                    onChange={this.handleChangeNewAdditionAttachment}
                    accept={acceptedFileTypes}
                    required={newAddition.type === 'Attachment'}
                  />
                </div>

                {missingRequiredFields && (
                  <Warning
                    className='mt3'
                    message={
                      <FormattedMessage
                        id='VetAddition.missingErrorMessage'
                        defaultMessage='Please enter a description or add an attachment'
                      />
                    }
                  />
                )}

                <div className='pt3 tr'>
                  <Button
                    label={<FormattedMessage id='CancelButton' />}
                    autoSize
                    secondary
                    className='mr3'
                    onClick={this.handleClickCancel}
                  />
                  <Button
                    type='submit'
                    autoSize
                    label={
                      <FormattedMessage
                        id='VetAdditions.Add'
                        defaultMessage='Add'
                      />
                    }
                  />
                </div>
              </Fragment>
            )}
          </form>
        )}
      </PageSection>
    )
  }
}

export default VetAdditions
