import React from 'react'
import Page from 'shared/components/Page'
import Paper from 'shared/components/Paper'
import { FormattedMessage, useIntl } from 'react-intl'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import paths from 'admin/routes/paths'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import ExternalLink from 'shared/components/ExternalLink'
import Button from 'shared/components/Button'
import FileUploadTitle from './FileUploadTitle'
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  vendorMasterTemplate: {
    id: 'FileUploadStep1.VendorMasterTemplate',
    defaultMessage: 'Vendor Master Template'
  },
  supplierName: {
    id: 'FileUploadStep1.supplierName',
    defaultMessage: 'Supplier/Vendor Company Name'
  },
  internalSupplierId: {
    id: 'FileUploadStep1.internalSupplierId',
    defaultMessage: 'Internal Supplier ID or Vendor Number'
  },
  address: {
    id: 'FileUploadStep1.address',
    defaultMessage:
      'Complete Supplier/Vendor Address (Street Address, City, State, Zip Code, Country)'
  },
  spendAmount: {
    id: 'FileUploadStep1.spendAmount',
    defaultMessage: 'Spend Amount'
  },
  spendDate: {
    id: 'FileUploadStep1.spendDate',
    defaultMessage: 'Spend Date'
  },
  spendInUSD: {
    id: 'FileUploadStep1.spendAmount',
    defaultMessage: 'Spend Currency in USD only'
  }
})

const useStyles = makeStyles(() => ({
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold'
  },
  button: {
    float: 'right',
    marginTop: '6px'
  },
  templateLink: {
    '&:hover, &:focus': {
      color: '#002525'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  formLabel: {
    display: 'inline'
  }
}))

const FileUploadStep1 = () => {
  const history = useHistory()
  const classes = useStyles()
  const intl = useIntl()

  const handleClickNextButton = () => {
    history.push(paths.fileUploadStep2)
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='FileUploadStep1.Title'
          defaultMessage='Upload your file'
        />
      }
    >
      <FileUploadTitle />
      <Paper>
        <div className='mt3'>
          <Label>
            <FormattedMessage
              id='FileUploadStep1.Description1'
              defaultMessage='Please ensure the data provided is in the correct format (utilizing our {vendorMasterTemplate}) and includes all mandatory fields and information.  This will expedite the  processing time and lead to more accurate match results.'
              values={{
                vendorMasterTemplate: (
                  <form
                    className={classes.formLabel}
                    method='get'
                    action={`/downloads/TealBook_Supplier_Master_And_Spend_Template.xlsx`}
                  >
                    <Button
                      variant='text'
                      autoSize
                      size='small'
                      type='submit'
                      className={classes.templateLink}
                    >
                      {intl.formatMessage(messages.vendorMasterTemplate)}
                    </Button>
                  </form>
                )
              }}
            />
          </Label>
        </div>
        <Box className='mt4'>
          <Text>
            <FormattedMessage
              id='FileUploadStep1.Description2'
              defaultMessage={`The following data is required in order to continue processing your file:`}
            />
          </Text>
          <ul>
            <li>
              <Text>{intl.formatMessage(messages.supplierName)}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage(messages.internalSupplierId)}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage(messages.address)}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage(messages.spendAmount)}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage(messages.spendDate)}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage(messages.spendInUSD)}</Text>
            </li>
          </ul>
        </Box>

        <Label>
          <FormattedMessage
            id='FileUploadStep1.Description3'
            defaultMessage='Our <link>Formatting Guide</link> is here to answer any questions you may have.  If you need additional assistance, please reach out to {email}.  We’re here to help!'
            values={{
              link: link => (
                <ExternalLink
                  className='fw6'
                  href='https://rise.articulate.com/share/BxQHls0VyzG5rP3bl96_9uwfgGfcVzfz'
                >
                  {link}
                </ExternalLink>
              ),
              email: (
                <a
                  className='teal f7 fw4 dim no-underline pointer'
                  href='mailto:support@tealbook.com'
                >
                  support@tealbook.com
                </a>
              )
            }}
          />
        </Label>
      </Paper>
      <Button
        className={classes.button}
        onClick={() => handleClickNextButton()}
        label={<FormattedMessage id='FileUpload.Next' defaultMessage='Next' />}
      />
    </Page>
  )
}

export default FileUploadStep1
