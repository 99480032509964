import { call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import tasks from 'shared/utils/api/tasks'

export function* createBulkOutreachSaga(action) {
  const response = yield call(tasks.createBulkOutreach, action.payload)
  return response
}

export default createApiRequestSaga(createBulkOutreachSaga, {
  notifyOnError: true
})
