import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'shared/components/Link'
import paths from 'admin/routes/paths'

const FileUploadTitle = () => {
  return (
    <div>
      <nav>
        <Link to={paths.fileUpload} className='f7'>
          &lsaquo;{' '}
          <FormattedMessage
            id='FileUpload.BackToFileHistory'
            defaultMessage='Back to file history'
          />
        </Link>
      </nav>
      <h2 className='f5 f3-l fw4 mv3'>
        <FormattedMessage
          id='FileUpload.Title'
          defaultMessage='Upload your file'
        />
      </h2>
    </div>
  )
}

export default FileUploadTitle
