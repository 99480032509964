import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import tasks from 'shared/utils/api/tasks'
import paths from 'accounts/routes/paths'

export function* requestToRegisterSaga(action) {
  const requestBody = action.payload.toJS()
  yield call(tasks.sendConciergeRequest, {
    requestType: 'requestToRegister',
    ...requestBody
  })
  yield put(push(paths.requestRegistrationComplete))
}

export default createApiRequestSaga(requestToRegisterSaga)
