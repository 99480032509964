import React, { ReactNode } from 'react'
import Text from '../Text'

type Props = {
  children: ReactNode
}

const Alert = (props: Props) => {
  return (
    <div role='alert' className={'black bg-washed-red pa2 br1'}>
      <Text>{props.children}</Text>
    </div>
  )
}

export default Alert
