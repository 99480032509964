export const LOAD_SUPPLIER_PROFILE =
  'buyer/supplierProfile/LOAD_SUPPLIER_PROFILE'
export const ADD_TAG = 'buyer/supplierProfile/ADD_TAG'
export const REMOVE_TAG = 'buyer/supplierProfile/REMOVE_TAG'
export const DELETE_PRIVATE_OFFERINGS =
  'buyer/supplierProfile/DELETE_PRIVATE_OFFERINGS'

export const VERIFY_SUPPLIER = 'buyer/supplierProfile/VERIFY_SUPPLIER'
export const ADD_CONTACT = 'buyer/supplierProfile/ADD_CONTACT'
export const ADD_OUTREACH_CONTACT = 'buyer/supplierProfile/ADD_OUTREACH_CONTACT'
export const EDIT_CONTACT = 'buyer/supplierProfile/EDIT_CONTACT'
export const CANCEL_EDIT_CONTACT = 'buyer/supplierProfile/CANCEL_EDIT_CONTACT'
export const UPDATE_CONTACT = 'buyer/supplierProfile/UPDATE_CONTACT'
export const SELECT_NEW_USER_FOR_CONTACT =
  'buyer/supplierProfile/SELECT_NEW_USER_FOR_CONTACT'
export const OPEN_ADD_OUTREACH_CONTACT_FORM =
  'buyer/supplierProfile/OPEN_ADD_OUTREACH_CONTACT_FORM'
export const SELECT_USER_FOR_CONTACT =
  'buyer/supplierProfile/SELECT_USER_FOR_CONTACT'
export const CANCEL_ADDING_CONTACT =
  'buyer/supplierProfile/CANCEL_ADDING_CONTACT'
export const CANCEL_ADDING_OUTREACH_CONTACT =
  'buyer/supplierProfile/CANCEL_ADDING_OUTREACH_CONTACT'
export const REMOVE_CONTACT = 'buyer/supplierProfile/REMOVE_SUPPLIER_CONTACT'
export const REMOVE_CONTACT_RELATIONSHIP =
  'buyer/supplierProfile/REMOVE_CONTACT_RELATIONSHIP'

export const UPDATE_CARD = 'buyer/supplierProfile/UPDATE_CARD'
export const UPDATE_RELATIONSHIP = 'buyer/supplierProfile/UPDATE_RELATIONSHIP'
export const REMOVE_RELATIONSHIP = 'buyer/supplierProfile/REMOVE_RELATIONSHIP'
export const UPDATE_RELATIONSHIP_ATTACHMENT =
  'buyer/supplierProfile/UPDATE_RELATIONSHIP_ATTACHMENT'
export const REMOVE_RELATIONSHIP_ATTACHMENT =
  'buyer/supplierProfile/REMOVE_RELATIONSHIP_ATTACHMENT'
export const RENEW_RELATIONSHIP_ATTACHMENT =
  'buyer/supplierProfile/RENEW_RELATIONSHIP_ATTACHMENT'
export const GET_VET_BUYER_SUPPLIERS =
  'buyer/supplierProfile/GET_VET_BUYER_SUPPLIERS'
export const ADD_TO_VET = 'buyer/supplierProfile/ADD_TO_VET'

export const ADD_PERSONAL_RELATIONSHIP =
  'buyer/supplierProfile/ADD_PERSONAL_RELATIONSHIP'
export const REMOVE_PERSONAL_RELATIONSHIP =
  'buyer/supplierProfile/REMOVE_PERSONAL_RELATIONSHIP'
export const BATCH_ADD_PERSONAL_RELATIONSHIP =
  'buyer/supplierProfile/BATCH_ADD_PERSONAL_RELATIONSHIP'

export const REQUEST_FOR_PROFILE_UPDATE =
  'buyer/supplierProfile/REQUEST_FOR_PROFILE_UPDATE'
export const REQUEST_FOR_SELF_CERTIFY =
  'buyer/supplierProfile/REQUEST_FOR_SELF_CERTIFY'
export const REQUEST_FOR_ESG_SURVEY =
  'buyer/supplierProfile/REQUEST_NEW_CONTACT_FOR_ESG_SURVEY'
export const REQUEST_NEW_CONTACT_FOR_ESG_SURVEY =
  'buyer/supplierProfile/REQUEST_FOR_NEW_ESG_SURVEY_CONTACT'

export const UPVOTE_RESPONSE = 'buyer/supplierProfile/UPVOTE_RESPONSE'
export const CLEAR_VOTE_RESPONSE = 'buyer/supplierProfile/CLEAR_VOTE_RESPONSE'

export const DELETE_REVIEW = 'buyer/supplierProfile/DELETE_REVIEW'

export const LOAD_SOCIAL_FEED = 'buyer/supplierProfile/LOAD_SOCIAL_FEED'

export const ADD_SUPPLIER_COMMENT = 'buyer/supplierProfile/ADD_SUPPLIER_COMMENT'

export const ADD_CERTIFICATION_VALIDATION =
  'buyer/supplierProfile/ADD_CERTIFICATION_VALIDATION'
export const REMOVE_CERTIFICATION_VALIDATION =
  'buyer/supplierProfile/REMOVE_CERTIFICATION_VALIDATION'
export const UPDATE_SUPPLIER_STATUS =
  'buyer/supplierProfile/UPDATE_SUPPLIER_STATUS'
export const REQUEST_BUYER_REFERENCE =
  'buyer/supplierProfile/REQUEST_BUYER_REFERENCE'
export const REQUEST_ECOVADIS_SUPPLIER_RATING =
  'buyer/supplierProfile/REQUEST_ECOVADIS_SUPPLIER_RATING'

export const RETRIEVE_ECOVADIS_ASSESSMENT_STATUS =
  'buyer/supplierProfile/RETRIEVE_ECOVADIS_ASSESSMENT_STATUS'

export const RETRIEVE_ECOVADIS_SCORECARD =
  'buyer/supplierProfile/RETRIEVE_ECOVADIS_SCORECARD'

export const LOAD_SURVEY = 'buyer/supplierProfile/LOAD_SURVEY'
export const LOAD_SURVEY_RESPONSE = 'buyer/supplierProfile/LOAD_SURVEY_RESPONSE'

export const OPEN_ESG_REQUEST_DIALOG =
  'buyer/supplierProfile/OPEN_ESG_REQUEST_DIALOG'
export const CLOSE_ESG_REQUEST_DIALOG =
  'buyer/supplierProfile/CLOSE_ESG_REQUEST_DIALOG'
export const INVITE_ANOTHER_ESG_CONTACT =
  'buyer/supplierProfile/INVITE_ANOTHER_ESG_CONTACT'

export const LOAD_ESG_REQUEST_DETAILS =
  'buyer/supplierProfile/LOAD_ESG_REQUEST_DETAILS'

export const OPEN_VERIFY_ESG_ATTACHMENT_DIALOG =
  'buyer/supplierProfile/OPEN_VERIFY_ESG_ATTACHMENT_DIALOG'
export const CLOSE_VERIFY_ESG_ATTACHMENT_DIALOG =
  'buyer/supplierProfile/CLOSE_VERIFY_ESG_ATTACHMENT_DIALOG'
export const VERIFY_ESG_ATTACHMENT =
  'buyer/supplierProfile/VERIFY_ESG_ATTACHMENT'

export const TRANSFER_SPEND = 'buyer/supplierProfile/TRANSFER_SPEND'
