import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, put, select } from 'redux-saga/effects'
import users from 'shared/utils/api/users'
import companySelectors from '../../../shared/selectors/companySelectors'
import reverseParsedUser from 'shared/utils/data/reverseParsedUser'
import FormError from 'shared/utils/FormError'
import supplier from 'shared/utils/api/supplier'
import { updateUser } from 'shared/actions'
import usersSelectors from 'shared/selectors/usersSelectors'
import survey from 'shared/utils/api/survey'

export function* addSurveyCollaborateContactSaga(action) {
  let { user, message, isPublicSupplierContact } = action.payload.toJS()

  const existingUser = user.id
    ? yield select(usersSelectors.getById, user.id)
    : undefined
  const isSustainabilityContact = existingUser
    ? existingUser.get('isSustainabilityContact')
    : false

  const orgUnitId = yield select(companySelectors.getCompanyField, 'id')
  //need to create non-existing users
  if (!existingUser) {
    if (!user.email) {
      throw new FormError('Please select a user')
    }
    // creating a new user, need to convert location (string) to location (object)
    if (user.location) {
      user.location = { address: user.location }
    }
    user.isSustainabilityContact = true
    user = yield call(users.inviteUser, {
      user: reverseParsedUser(user),
      orgUnitId,
      message
    })
  } else if (!isSustainabilityContact) {
    yield put(
      updateUser({
        id: user.id,
        isSustainabilityContact: true
      })
    )
  }

  if (isPublicSupplierContact) {
    yield call(supplier.addContact, {
      userId: user.id
    })
  }

  yield call(survey.requestCollaborator, {
    requestedContactId: user.id
  })

  return yield {
    user,
    isCreatingNewUser: !existingUser,
    isPublicSupplierContact
  }
}

export default createApiRequestSaga(addSurveyCollaborateContactSaga)
