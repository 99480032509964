import React, { useState, useMemo } from 'react'
import { Map, List, RecordOf } from 'immutable'
import { connect, useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import { DiversityDetails } from '../../../store/diversityReportReducer'
import DiversityCategory from 'shared/models/DiversityCategory'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import {
  getQualifiedDetailSpend,
  getDisqualifiedDetailSpend,
  getPotentialDetailSpend,
  getOverviewSubCategories
} from '../../../store/diversityReportSelectors'
import insightsSelectors from '../../../store/insightsSelectors'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import Paper from 'shared/components/Paper'
import Table from 'shared/components/Table'
import { Column } from 'react-virtualized'
import { FormattedMessage, useIntl } from 'react-intl'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../../routes/paths'
import insightsPaths from '../../../routes/paths'
import Link from 'shared/components/Link'
import CertificationValidation from '../../../../shared/components/CertificationValidation'
import dateFormat from 'shared/utils/dateFormat'
import numberFormat from 'shared/utils/numberFormat'
import User from 'shared/models/User'
import {
  addCertificationValidation,
  removeCertificationValidation
} from '../../../../SupplierProfile/actions'
import Loading from 'shared/components/Loading'
import Divider from 'shared/components/Divider'
import Text from 'shared/components/Text'
import DropdownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import startCase from 'lodash.startcase'
import { ReactComponent as Check } from 'shared/assets/icons/check-solid.svg'
import { ReactComponent as Question } from 'shared/assets/icons/question-solid.svg'
import { ReactComponent as Export } from 'shared/assets/icons/external-link-alt-solid.svg'
import Tooltip from 'shared/components/Tooltip'
import {
  alertCodes,
  alertCodesDescriptions
} from 'shared/utils/data/defineMessages'
import ExternalLink from 'shared/components/ExternalLink'
import {
  createDiversityReportForExport,
  exportEconomicImpactAssessment
} from '../exportUtils'
import analytics from 'shared/utils/analytics'

type Props = ContainerProps & {
  isTealbot: boolean
  loading: boolean
  detailsTableData: List<RecordOf<DiversityDetails>>
  currentUserId: string
  selectedCategories: List<DiversityCategory>
  allUsers: Map<string, RecordOf<User>>
  addCertificationValidation: typeof addCertificationValidation
  removeCertificationValidation: typeof removeCertificationValidation
  qualifiedTotal: number
  disqualifiedTotal: number
  potentialTotal: number
  totalSpend: number
  overviewSubCategories: List<DiversityCategory>
  currentGrouping?: 'subCategory' | 'category' | 'country' | 'spendGroup'
  startDate: string
  endDate: string
  exportReady: boolean
  spendCategories: List<string>
}
type DiversitySortByDetails = {
  name: string
  totalAmount: number
}

const DiversityReportDetails = (props: Props) => {
  const intl = useIntl()
  const [sortBy, setSortBy] = useState<keyof DiversitySortByDetails>('name')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')

  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const {
    loading,
    show,
    detailsTableData,
    currentUserId,
    selectedCategories,
    allUsers,
    addCertificationValidation,
    removeCertificationValidation,
    qualifiedTotal,
    disqualifiedTotal,
    potentialTotal,
    totalSpend,
    overviewSubCategories,
    currentGrouping,
    isTealbot,
    startDate,
    endDate,
    exportReady,
    spendCategories
  } = props

  let tableRef
  const setRef = ref => {
    tableRef = ref
  }

  const getRowHeight = row => {
    if (!row) {
      return 52
    }

    const record = sortedDetailsTableData.get(row.index)
    const numberOfCertifications =
      record && record.certifications && record.certifications.size

    const numberOfAgencies =
      record &&
      record.certifications &&
      record.certifications.reduce((result, certification) => {
        return result + certification.agencies.size
      }, 0)

    const maxRows = Math.max(numberOfCertifications || 0, numberOfAgencies || 0)
    return maxRows > 1 ? maxRows * 32 + 16 : 58
  }

  const groupingSubCategories = useMemo(() => {
    return detailsTableData
      .map(item => item.get('subCategories'))
      .flatten(2)
      .toSet()
      .toList()
  }, [detailsTableData])

  const sortedDetailsTableData = useMemo(() => {
    const data = detailsTableData

    return data.sort(
      (
        dataA: RecordOf<DiversityDetails>,
        dataB: RecordOf<DiversityDetails>
      ) => {
        const valueA =
          sortBy === 'name'
            ? dataA.get(sortBy) && dataA.get(sortBy).toLowerCase()
            : dataA.get(sortBy)

        const valueB =
          sortBy === 'name'
            ? dataB.get(sortBy) && dataB.get(sortBy).toLowerCase()
            : dataB.get(sortBy)

        if (!valueA || !valueB) {
          return 1
        }
        return sortDirection === 'DESC'
          ? valueA > valueB
            ? -1
            : 1
          : valueA < valueB
          ? -1
          : 1
      }
    )
  }, [detailsTableData, sortBy, sortDirection])

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
    tableRef.recomputeRowHeights()
  }

  const onTabClick = (tab: 'Qualified' | 'Potential' | 'Disqualified') => {
    analytics.track('Spend Details Tab Changed', {
      eventSource: 'Diversity Report',
      action: 'Changed',
      tab,
      orgUnitId,
      orgUnitName
    })
  }

  return loading ? (
    <Loading />
  ) : (
    <section className='pt4' id='details'>
      <header className='pl3-5'>
        <div>
          <h5 className='f5-l f6 fw6 ma0'>
            <FormattedMessage
              id='DiversityReportDetails.SpendDetailsTitleNoType'
              defaultMessage='Spend Details'
            />
          </h5>
        </div>
      </header>
      <main>
        <div className='pl3-5 pb1 flex items-center justify-between overflow-x-auto'>
          <NavBar>
            <NavLink
              label={
                <>
                  <FormattedMessage
                    id='DiversityReportDetails.Qualified'
                    defaultMessage='Qualified'
                  />
                  &nbsp;-&nbsp;${numberFormat(qualifiedTotal)} (
                  {((qualifiedTotal / totalSpend) * 100).toFixed(2)}
                  %)
                </>
              }
              onClick={() => onTabClick('Qualified')}
              to={`${insightsPaths.spendDiverse}?show=qualified`}
              isActive={() => show === 'qualified'}
            />
            <NavLink
              label={
                <>
                  <FormattedMessage
                    id='DiversityReportDetails.Potential'
                    defaultMessage='Potential'
                  />
                  &nbsp;-&nbsp;${numberFormat(potentialTotal)} (
                  {((potentialTotal / totalSpend) * 100).toFixed(2)}
                  %)
                </>
              }
              onClick={() => onTabClick('Potential')}
              to={`${insightsPaths.spendDiverse}?show=potential`}
              isActive={() => show === 'potential'}
            />
            <NavLink
              label={
                <>
                  <FormattedMessage
                    id='DiversityReportDetails.Disqualified'
                    defaultMessage='Disqualified'
                  />
                  &nbsp;-&nbsp;${numberFormat(disqualifiedTotal)} (
                  {((disqualifiedTotal / totalSpend) * 100).toFixed(2)}
                  %)
                </>
              }
              onClick={() => onTabClick('Disqualified')}
              to={`${insightsPaths.spendDiverse}?show=disqualified`}
              isActive={() => show === 'disqualified'}
            />
          </NavBar>
          {sortedDetailsTableData.size > 0 && (
            <DropdownMenu>
              <MenuItem
                disabled={!exportReady}
                onClick={() => {
                  analytics.track('Spend Details Export Clicked', {
                    eventSource: 'Diversity Report',
                    action: 'Clicked',
                    orgUnitId,
                    orgUnitName
                  })
                  createDiversityReportForExport(
                    sortedDetailsTableData,
                    !currentGrouping || currentGrouping === 'subCategory'
                      ? selectedCategories?.size > 0
                        ? selectedCategories
                        : overviewSubCategories
                      : groupingSubCategories,
                    show,
                    orgUnitId,
                    orgUnitName
                  )
                }}
              >
                <FormattedMessage
                  id='DiversityReportDetails.Export'
                  defaultMessage='Export'
                />
              </MenuItem>
              <MenuItem
                disabled={!exportReady}
                onClick={() => {
                  analytics.track('Spend Details Export by ID Clicked', {
                    eventSource: 'Diversity Report',
                    action: 'Clicked',
                    orgUnitId,
                    orgUnitName
                  })
                  createDiversityReportForExport(
                    sortedDetailsTableData,
                    !currentGrouping || currentGrouping === 'subCategory'
                      ? selectedCategories?.size > 0
                        ? selectedCategories
                        : overviewSubCategories
                      : groupingSubCategories,
                    show,
                    orgUnitId,
                    orgUnitName,
                    true
                  )
                }}
              >
                <FormattedMessage
                  id='DiversityReportDetails.ExportGroupedInternalID'
                  defaultMessage='Export Grouped by Internal Supplier ID'
                />
              </MenuItem>
              {isTealbot && (
                <MenuItem
                  disabled={!exportReady}
                  onClick={() => {
                    analytics.track('Spend Details Export Assessment Clicked', {
                      eventSource: 'Diversity Report',
                      action: 'Clicked',
                      orgUnitId,
                      orgUnitName
                    })
                    exportEconomicImpactAssessment(
                      sortedDetailsTableData
                        .map(s => s.get('orgUnitId'))
                        .toJS(),
                      spendCategories.toJS(),
                      startDate,
                      endDate,
                      show,
                      orgUnitId,
                      orgUnitName
                    )
                  }}
                >
                  <FormattedMessage
                    id='DiversityReportDetails.ExportEconomicImpactAssessment'
                    defaultMessage='Export Economic Impact Assessment'
                  />
                </MenuItem>
              )}
            </DropdownMenu>
          )}
        </div>
        <Divider className='mb3' />
        <Paper>
          <div className='mt3-5'>
            {sortedDetailsTableData.size > 0 ? (
              <Paper noPadding>
                <Table
                  tableRef={setRef}
                  minWidth={1000}
                  rowGetter={({ index }) => sortedDetailsTableData.get(index)}
                  rowCount={sortedDetailsTableData.size}
                  sort={handleSortChange}
                  sortDirection={sortDirection}
                  sortBy={sortBy}
                  rowHeight={getRowHeight}
                  rowStyle={({ index }) =>
                    index > -1 && {
                      alignItems: 'start'
                    }
                  }
                >
                  <Column
                    label={
                      <FormattedMessage
                        id='DiversityReport.Supplier'
                        defaultMessage='Supplier'
                      />
                    }
                    aria-label='Sort by Supplier'
                    dataKey='name'
                    width={300}
                    cellRenderer={({ rowData }) => {
                      return (
                        <>
                          <Link
                            className='db'
                            to={`${parsePath(paths.supplierProfile, {
                              supplierId: rowData.orgUnitId
                            })}?scrollToDiversity=true`}
                            onClick={() => {
                              analytics.track(
                                'Spend Details Supplier Profile Clicked',
                                {
                                  eventSource: 'Diversity Report',
                                  action: 'Clicked',
                                  supplier: rowData.name,
                                  supplierId: rowData.orgUnitId,
                                  orgUnitId,
                                  orgUnitName
                                }
                              )
                            }}
                          >
                            {rowData.name}
                          </Link>
                          <div className='mt2 f6 gray'>
                            <ExternalLink
                              href={rowData.domain}
                              onClick={() => {
                                analytics.track(
                                  'Spend Details Supplier External Link Clicked',
                                  {
                                    eventSource: 'Diversity Report',
                                    action: 'Clicked',
                                    supplier: rowData.name,
                                    supplierId: rowData.orgUnitId,
                                    orgUnitId,
                                    orgUnitName
                                  }
                                )
                              }}
                            >
                              {rowData.domain}
                            </ExternalLink>
                          </div>
                        </>
                      )
                    }}
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='DiversityReport.Spend'
                        defaultMessage='Spend'
                      />
                    }
                    aria-label='Sort by Spend'
                    dataKey='totalAmount'
                    width={100}
                    cellRenderer={({ cellData }) => (
                      <div className='mt2'>${numberFormat(cellData)}</div>
                    )}
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='DiversityReport.Type'
                        defaultMessage='Type'
                      />
                    }
                    dataKey='certifications'
                    width={60}
                    disableSort
                    cellRenderer={({ cellData }) =>
                      cellData.map(
                        (certification, index) =>
                          certification.subCategory && (
                            <div
                              className='pv2'
                              key={`${certification.subCategory}${index}`}
                              style={{
                                height:
                                  certification.agencies.size > 1
                                    ? 32 * certification.agencies.size
                                    : undefined
                              }}
                            >
                              {certification.subCategory.toUpperCase()}
                            </div>
                          )
                      )
                    }
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='DiversityReport.Authority'
                        defaultMessage='Authority'
                      />
                    }
                    dataKey='certifications'
                    width={280}
                    disableSort
                    cellRenderer={({ cellData }) => {
                      const toExport: { [key: string]: boolean } = {}

                      return cellData.map(
                        certification =>
                          certification.agencies &&
                          certification.agencies.map((agency, index) => {
                            let exportIcon
                            if (!toExport[certification.get('subCategory')]) {
                              exportIcon =
                                (show === 'qualified' &&
                                  agency.get('confirmed')) ||
                                (show === 'potential' &&
                                  agency.getIn(['info', 'potential'])) ||
                                show === 'disqualified' ? (
                                  <div className='mr1'>
                                    <Export height={12} fill='#555555' />
                                  </div>
                                ) : null
                              toExport[certification.get('subCategory')] = true
                            }
                            return (
                              <div
                                className='pv2 flex'
                                key={`${certification.subCategory}${index}`}
                              >
                                {agency.get('confirmed') ? (
                                  <div className='mr1'>
                                    <Tooltip
                                      title={
                                        <FormattedMessage
                                          id='DiversityReport.ConfirmedHelp1'
                                          defaultMessage='Qualified: Certificate is active and passed automated quality assurance.'
                                        />
                                      }
                                    >
                                      <Check
                                        height={12}
                                        fill='#31b800'
                                        tabIndex={0}
                                        aria-label='Check mark'
                                        aria-hidden='false'
                                      />
                                    </Tooltip>
                                  </div>
                                ) : agency.getIn(['info', 'potential']) ? (
                                  <div className='mr1'>
                                    <Tooltip
                                      title={
                                        <>
                                          <FormattedMessage
                                            id='DiversityReport.PotentialHelp1'
                                            defaultMessage='Potential: Issues with certificate are '
                                          />
                                          <br />
                                          {agency
                                            .getIn(['info', 'alertCodes'])
                                            ?.map(code => (
                                              <p key={code}>
                                                {`${
                                                  alertCodes[code]
                                                    ? intl.formatMessage(
                                                        alertCodes[code]
                                                      )
                                                    : startCase(code)
                                                }: ${
                                                  alertCodes[code]
                                                    ? intl.formatMessage(
                                                        alertCodesDescriptions[
                                                          code
                                                        ]
                                                      )
                                                    : startCase(code)
                                                }`}
                                              </p>
                                            ))}
                                        </>
                                      }
                                    >
                                      <Question
                                        height={12}
                                        fill='#fcba03'
                                        tabIndex={0}
                                        aria-label='Question mark'
                                        aria-hidden='false'
                                      />
                                    </Tooltip>
                                  </div>
                                ) : null}
                                {exportIcon}
                                <div>
                                  {agency.sourceURL ? (
                                    <ExternalLink
                                      href={agency.sourceURL}
                                      onClick={() => {
                                        analytics.track(
                                          'Spend Details Authority Clicked',
                                          {
                                            eventSource: 'Diversity Report',
                                            action: 'Clicked',
                                            supplier: cellData.name,
                                            supplierId: cellData.orgUnitId,
                                            orgUnitId,
                                            orgUnitName
                                          }
                                        )
                                      }}
                                    >
                                      {agency.certAgency}
                                    </ExternalLink>
                                  ) : (
                                    agency.certAgency
                                  )}
                                </div>
                              </div>
                            )
                          })
                      )
                    }}
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='DiversityReport.Expiry'
                        defaultMessage='Expiry'
                      />
                    }
                    dataKey='certifications'
                    width={80}
                    disableSort
                    cellRenderer={({ cellData }) =>
                      cellData.map(
                        certification =>
                          certification.agencies &&
                          certification.agencies.map((agency, index) => (
                            <div
                              className='pv2'
                              key={`${certification.subCategory}${index}`}
                              style={{
                                height: 32
                              }}
                            >
                              {agency.expiration &&
                                dateFormat(agency.expiration)}
                            </div>
                          ))
                      )
                    }
                  />
                  <Column
                    label={
                      <FormattedMessage
                        id='DiversityReport.Verified'
                        defaultMessage='Verified'
                      />
                    }
                    dataKey='certifications'
                    disableSort
                    width={195}
                    cellRenderer={({ rowData, cellData }) =>
                      cellData.map((certification, index) => {
                        const currentUser = allUsers.get(currentUserId)
                        const currentUserOrgUnitId =
                          currentUser && currentUser.get('orgUnitId')

                        const lastColleagueValidation =
                          certification.certificationValidations &&
                          certification.certificationValidations.findLast(
                            validation => {
                              const user = allUsers.get(validation.userId)
                              return (
                                user &&
                                user.get('orgUnitId') === currentUserOrgUnitId
                              )
                            }
                          )

                        return (
                          <div
                            key={`${certification.subCategory}${index}`}
                            style={{ height: '32px' }}
                            className='flex items-center'
                            onClick={() => {
                              analytics.track(
                                'Spend Details Verification Opened',
                                {
                                  eventSource: 'Diversity Report',
                                  action: 'Opened',
                                  orgUnitId,
                                  orgUnitName
                                }
                              )
                            }}
                          >
                            <CertificationValidation
                              orgUnitName={rowData.name}
                              subCategory={certification.subCategory}
                              categoryTimeStamp={certification.timeStamp}
                              validation={lastColleagueValidation}
                              onAddValidation={validation => {
                                analytics.track(
                                  'Spend Details Verification Submitted',
                                  {
                                    eventSource: 'Diversity Report',
                                    action: 'Submitted',
                                    orgUnitId,
                                    orgUnitName
                                  }
                                )
                                addCertificationValidation({
                                  diversityReport: true,
                                  supplierId: rowData.orgUnitId,
                                  supplierRelationshipId:
                                    rowData.supplierRelationshipId,
                                  ...validation
                                })
                              }}
                              onRemoveCertification={
                                lastColleagueValidation &&
                                currentUserId === lastColleagueValidation.userId
                                  ? () => {
                                      removeCertificationValidation({
                                        supplierId: rowData.orgUnitId,
                                        supplierRelationshipId:
                                          rowData.supplierRelationshipId,
                                        category:
                                          lastColleagueValidation.category,
                                        subCategory:
                                          lastColleagueValidation.subCategory,
                                        timeStamp:
                                          lastColleagueValidation.timeStamp,
                                        date: lastColleagueValidation.date
                                      })
                                    }
                                  : undefined
                              }
                            />
                          </div>
                        )
                      })
                    }
                  />
                </Table>
              </Paper>
            ) : (
              <Text>
                <FormattedMessage
                  id='DiversityReportDetails.noDataFound'
                  defaultMessage='There was no data found.'
                />
              </Text>
            )}
          </div>
        </Paper>
      </main>
    </section>
  )
}

type ContainerProps = {
  show: 'qualified' | 'disqualified' | 'potential'
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const [qualifiedData, qualifiedTotal] = getQualifiedDetailSpend(state)
    const [disqualifiedData, disqualifiedTotal] = getDisqualifiedDetailSpend(
      state
    )
    const [potentialData, potentialTotal] = getPotentialDetailSpend(state)
    const overviewSubCategories = getOverviewSubCategories(state)
    const exportReady = state.getIn([
      'buyer',
      'diversityReport',
      'detailsExportReady'
    ])
    const spendCategories = insightsSelectors.getSpendCategories(state)

    return {
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      loading: state.get('buyer').get('diversityReport').loading,
      selectedCategories: state.get('buyer').get('diversityReport')
        .selectedCategories,
      currentGrouping: state.getIn([
        'buyer',
        'diversityReport',
        'currentGrouping'
      ]),
      detailsTableData:
        props.show === 'qualified'
          ? qualifiedData
          : props.show === 'disqualified'
          ? disqualifiedData
          : potentialData,
      currentUserId: sessionSelectors.getUserId(state),
      allUsers: usersSelectors.getUsers(state),
      qualifiedTotal,
      disqualifiedTotal,
      potentialTotal,
      totalSpend: insightsSelectors.getSelectedSpend(state),
      overviewSubCategories,
      exportReady,
      spendCategories
    }
  },
  {
    addCertificationValidation,
    removeCertificationValidation
  }
)(DiversityReportDetails)
