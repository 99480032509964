import React, { useState, useMemo, useEffect, ChangeEvent } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import PageSection from 'shared/components/PageSection'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import { useDispatch } from 'react-redux'
import { getBulkEsgSuppliers, createBulkOutreach } from '../../store/actions'
import Loading from 'shared/components/Loading'
import { List, RecordOf } from 'immutable'
import {
  BulkSupplier,
  Contact,
  NewUser
} from '../../store/bulkActionsReducer/bulkActionsReducer'
import Paper from 'shared/components/Paper'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg'
import ContactItem from 'shared/components/ContactItem'
import fileToUrl from 'shared/utils/data/fileToUrl'
import CustomSelect from 'shared/components/Select'
import Button from 'shared/components/Button'
import {
  Dialog,
  DialogContent,
  DialogActions,
  withStyles,
  makeStyles,
  MenuItem,
  Select,
  ListItem,
  ListItemIcon
} from '@material-ui/core'
import DialogTitle from 'shared/components/DialogTitle'
import CheckMark from '@material-ui/icons/CheckCircle'
import Checkbox from 'shared/components/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useSmallScreen from 'shared/utils/useSmallScreen'
import AddOutreachContactForm from '../../containers/AddOutreachContactForm'
import Pagination from 'shared/components/Pagination/Pagination'
import AddIcon from '@material-ui/icons/AddCircle'

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '0.825rem',
    fontWeight: 400,
    padding: '5px',
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F5FAFA',
    border: '1px solid #028383'
  },
  bold: {
    fontWeight: 600,
    paddingRight: '4px'
  },
  button: {
    border: 'none',
    backgroundColor: 'inherit',
    cursor: 'pointer',
    color: '#028383'
  },
  centerAlignment: {
    display: 'flex',
    alignItems: 'center'
  },
  overflowVisible: {
    overflow: 'visible!important' as 'visible'
  },
  checkboxTeal: {
    accentColor: 'teal'
  }
}))

const CustomDialogAction = withStyles({
  root: {
    margin: 24,
    padding: 0
  },
  spacing: {
    '& > :not(:first-child)': {
      marginLeft: 16
    }
  }
})(DialogActions)

const messages = defineMessages({
  placeholderSearch: {
    id: 'BulkOutreach.SearchForASupplier',
    defaultMessage: 'Search for a Supplier'
  },
  ESGSurveyRequest: {
    id: 'BulkOutreach.ESGSurveyRequest',
    defaultMessage: 'Request Sustainability Survey'
  }
})
const bulkOutreachTypes = ['ESGSurveyRequest']
const pageSize = 100

type Props = {
  onClickNewUser: () => void
  onClickCancel: () => void
  currentOrgName: string
  loading: boolean
  suppliers: List<BulkSupplier>
  isCreatingOutreach: boolean
  isSupplierProfileLoadDone: boolean
  loadSupplierProfile: (string) => void
  newUser: NewUser
}

const BulkOutreach = (props: Props) => {
  const {
    onClickNewUser,
    onClickCancel,
    loading,
    suppliers,
    isCreatingOutreach,
    loadSupplierProfile,
    isSupplierProfileLoadDone,
    newUser
  } = props

  const classes = useStyles()
  const isMobile = useSmallScreen()
  const dispatch = useDispatch()
  const intl = useIntl()

  const [outreachType, setOutreachType] = useState<string>('ESGSurveyRequest')
  const styles = useStyles()

  useEffect(() => {
    dispatch(getBulkEsgSuppliers())
  }, [dispatch])
  const [selectedContacts, setSelectedContacts] = useState(
    new Map<string, string | undefined>()
  )
  const [searchString, setSearchString] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')
  const [sortBy, setSortBy] = useState<
    'supplier' | 'contacts' | 'ytdSpend' | ''
  >('')
  const [openBulkOutreachDialog, setOpenBulkOutreachDialog] = useState<boolean>(
    false
  )
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(
    false
  )
  const [requests, setRequests] = useState<any>(undefined)
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)

  const [
    suppliersWithoutContactNotSelected,
    setSuppliersWithoutContactNotSelected
  ] = useState<string[]>([])

  useMemo(() => {
    let defaultContacts = new Map()
    suppliers.forEach(supplier => {
      defaultContacts.set(
        supplier.supplier.orgUnitId,
        supplier.contacts[0] ? supplier.contacts[0].userId : undefined
      )
    })
    setSelectedContacts(new Map([...defaultContacts]))
  }, [suppliers])

  useEffect(() => {
    if (
      newUser?.userId &&
      selectedContacts.get(newUser.orgUnit) !== newUser.userId
    ) {
      const updatedSelectedContacts = new Map(selectedContacts)
      updatedSelectedContacts.set(newUser.orgUnit, newUser.userId)
      setSelectedContacts(updatedSelectedContacts)
      onClickCancel()
    }
  }, [newUser, selectedContacts, onClickCancel])

  const sortedSuppliers: List<BulkSupplier> = useMemo(() => {
    return suppliers
      ?.filter(supplier => {
        return (
          !searchString ||
          supplier.supplier.name
            .toLowerCase()
            .includes(searchString.toLowerCase())
        )
      })
      .sort((supplier1, supplier2) => {
        let sortValue1: number | string, sortValue2: number | string

        if (!sortBy) {
          return 0
        }

        if (sortBy === 'supplier') {
          sortValue1 = supplier1.supplier.name
          sortValue2 = supplier2.supplier.name
        } else if (sortBy === 'contacts') {
          const { contacts: contacts1 } = supplier1
          const { contacts: contacts2 } = supplier2

          const contact1: Contact | undefined =
            contacts1 &&
            contacts1.find(
              c =>
                c.userId === selectedContacts.get(supplier1.supplier.orgUnitId)
            )
          const contact2: Contact | undefined =
            contacts2 &&
            contacts2.find(
              c =>
                c.userId === selectedContacts.get(supplier2.supplier.orgUnitId)
            )

          sortValue1 = contact1
            ? `${contact1.firstName || ''} ${contact1.lastName || ''}`
            : ''
          sortValue2 = contact2
            ? `${contact2.firstName || ''} ${contact2.lastName || ''}`
            : ''
        } else {
          sortValue1 = supplier1.ytdSpend?.toString() || ''
          sortValue2 = supplier2.ytdSpend?.toString() || ''
        }

        if (sortDirection === 'ASC') {
          return sortValue1.toLowerCase() > sortValue2.toLowerCase() ? 1 : -1
        } else {
          return sortValue1.toLowerCase() < sortValue2.toLowerCase() ? 1 : -1
        }
      })
  }, [suppliers, sortBy, sortDirection, searchString, selectedContacts])

  const totalPages = Math.ceil(sortedSuppliers.size / pageSize)

  useEffect(() => {
    setPageNumber(pageNumber)
  }, [pageNumber, sortedSuppliers])

  const start = (pageNumber - 1) * pageSize + 1
  const end =
    pageNumber * pageSize > sortedSuppliers.size
      ? sortedSuppliers.size
      : pageNumber * pageSize

  const pageTable = sortedSuppliers.slice(start - 1, end)

  const getRow = ({ index }) => {
    return pageTable && pageTable.size > 0 && pageTable.get(index)
  }

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value)
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const value = e.currentTarget.value
    const index = selectedSuppliers.indexOf(value)
    if (index !== -1) {
      if (isAllSelected) {
        const suppliersWithNoContacts = pageOrgUnitIds.filter(
          orgUnitId => selectedContacts.get(orgUnitId) === undefined
        )
        setSuppliersWithoutContactNotSelected(oldList => {
          return oldList.filter(
            orgUnitId => !suppliersWithNoContacts.contains(orgUnitId)
          )
        })
      }
      selectedSuppliers.splice(index, 1)
      setSelectedSuppliers([...selectedSuppliers])
    } else {
      setSelectedSuppliers([...selectedSuppliers, value])
    }
  }

  const renderCheckboxCell = ({
    rowData
  }: {
    rowData: RecordOf<BulkSupplier>
  }) => {
    const value = rowData.supplier.orgUnitId
    const hasContacts = rowData.contacts?.length ? true : false

    return (
      <input
        type='checkbox'
        disabled={!hasContacts}
        className={classes.checkboxTeal}
        value={value}
        checked={selectedSuppliers.includes(value)}
        onChange={handleCheckboxChange}
        aria-label={`Supplier ${rowData.supplier.name}`}
      />
    )
  }

  const renderSupplierName = ({ cellData }: TableCellProps) => {
    return cellData.name
  }

  const handleChange = (e, company) => {
    if (e.target.value) {
      setSelectedContacts(
        new Map([...selectedContacts, [company, e.target.value]])
      )
    }
  }

  const renderContact = ({ cellData, rowData }: TableCellProps) => {
    const orgUnitId = rowData.supplier.orgUnitId
    const selectedUserId = selectedContacts.get(orgUnitId)
    const selectedContact = cellData.find(c => c.userId === selectedUserId)
    const supplierName = rowData?.supplier?.name || ''
    const contactLabelId = `${orgUnitId}ContactLabel`

    return selectedContact ? (
      <>
        <p id={contactLabelId} className='clip'>
          Select contact for {supplierName}
        </p>
        <Select
          value={selectedContact.userId}
          variant='outlined'
          className='w-75'
          onChange={e => handleChange(e, rowData.supplier.orgUnitId)}
          onOpen={() => {
            loadSupplierProfile(orgUnitId)
          }}
          style={{ height: '48px' }}
          labelId={contactLabelId}
        >
          {cellData.map((contact: Contact, index) => (
            <MenuItem key={contact.userId} value={contact.userId}>
              <ContactItem
                firstName={contact.firstName}
                lastName={contact.lastName}
                title={contact.title}
                profilePictureUrl={
                  contact.profilePictureUrl || fileToUrl(contact.profilePicture)
                }
                isSustainabilityContact={!!contact.sustainabilityContact}
                isEsgSurvey
                role=''
              />
            </MenuItem>
          ))}
          <ListItem button onClick={() => onClickNewUser()}>
            <ListItemIcon className='pr3' style={{ minWidth: 0 }}>
              <AddIcon color='primary' />
            </ListItemIcon>
            <span className='f6 dark-teal'>
              <FormattedMessage
                id='BulkOutReach.AddContact'
                defaultMessage='Add a New Contact'
              />
            </span>
          </ListItem>
        </Select>
      </>
    ) : (
      <Button
        onClick={() => {
          loadSupplierProfile(orgUnitId)
          onClickNewUser()
        }}
        color='primary'
        variant='text'
        startIcon={<AddIcon />}
        label={
          <FormattedMessage
            id='AddNewUserButton.AddContact'
            defaultMessage='Add a New Contact'
          />
        }
      />
    )
  }

  const renderYtdSpend = ({ cellData }: TableCellProps) => {
    return <div>{cellData ? `$${cellData.toLocaleString()}` : '-'}</div>
  }

  const suppliersMap = useMemo(() => {
    return suppliers.reduce((map, supplier) => {
      map[supplier.supplier.orgUnitId] = supplier
      return map
    }, {})
  }, [suppliers])

  const validateRequest = () => {
    const requestSuppliers = selectedSuppliers.map(orgUnitId => ({
      id: orgUnitId,
      name: suppliersMap[orgUnitId].supplier.name,
      requestedContactId: selectedContacts.get(orgUnitId)
    }))
    const validRequests = requestSuppliers.filter(
      supplier => !!supplier.requestedContactId
    )
    const invalidRequests = requestSuppliers.filter(
      supplier => !supplier.requestedContactId
    )
    return {
      validRequests,
      invalidRequests
    }
  }

  const handleCreateBulkRequest = () => {
    let { invalidRequests, validRequests } = validateRequest()
    setOpenBulkOutreachDialog(false)
    setOpenConfirmationDialog(true)
    setRequests({
      invalidRequestsAmount: invalidRequests.length,
      validRequestsAmount: validRequests.length
    })
    dispatch(
      createBulkOutreach({
        suppliers: validRequests,
        outreachType,
      })
    )
    setSelectedSuppliers([])
  }

  const pageOrgUnitIds = useMemo(
    () => pageTable.map(s => s.supplier.orgUnitId),
    [pageTable]
  )

  const isAllSelected = useMemo(() => {
    return pageOrgUnitIds.every(
      orgUnitId =>
        selectedSuppliers.includes(orgUnitId) ||
        suppliersWithoutContactNotSelected.includes(orgUnitId)
    )
  }, [selectedSuppliers, pageOrgUnitIds, suppliersWithoutContactNotSelected])

  const isAllWithoutContact = useMemo(() => {
    return pageOrgUnitIds.every(
      orgUnitId => selectedContacts.get(orgUnitId) === undefined
    )
  }, [pageOrgUnitIds, selectedContacts])

  const handleSelectAll = () => {
    setSelectedSuppliers(oldList => {
      const pageOrgUnitIdsWithContacts = pageOrgUnitIds
        .toJS()
        .filter(s => selectedContacts.get(s) !== undefined)
      const joinLists = oldList.concat(pageOrgUnitIdsWithContacts)
      return joinLists.filter((s, i, a) => a.indexOf(s) === i)
    })

    const pageOrgUnitIdsWithoutContacts = pageOrgUnitIds
      .toJS()
      .filter(orgUnitId => selectedContacts.get(orgUnitId) === undefined)
    if (pageOrgUnitIdsWithoutContacts.length !== 0) {
      setSuppliersWithoutContactNotSelected(oldList => {
        const joinLists = oldList.concat(pageOrgUnitIdsWithoutContacts)
        return joinLists.filter((s, i, a) => a.indexOf(s) === i)
      })
    }
  }

  const handleClearAll = () => {
    setSelectedSuppliers(oldList => {
      return oldList.filter(s => !pageOrgUnitIds.includes(s))
    })
    const suppliersWithNoContacts = pageOrgUnitIds.filter(
      orgUnitId => selectedContacts.get(orgUnitId) === undefined
    )
    setSuppliersWithoutContactNotSelected(oldList => {
      return oldList.filter(
        orgUnitId => !suppliersWithNoContacts.contains(orgUnitId)
      )
    })
  }

  const clearSelection = () => {
    setSelectedSuppliers([])
    setSuppliersWithoutContactNotSelected([])
  }

  return (
    <>
      <PageSection
        noPadding
        noPaper
        title={
          <FormattedMessage
            id='BulkOutreach.BulkAction'
            defaultMessage='Bulk Actions'
          />
        }
      >
        {loading ? (
          <Loading label='Loading bulk actions table...' />
        ) : (
          <>
            <div className='flex justify-between mb3'>
              <div className='w-50'>
                <Input
                  ariaLabel='Search for a Supplier'
                  placeholder={intl.formatMessage(messages.placeholderSearch)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  value={searchString}
                  onChange={handleSearchChange}
                />
              </div>
              <label htmlFor='bulkOutreach' className='visuallyhidden'>
                <FormattedMessage
                  id='BulkOutreach.SelectOutreachType'
                  defaultMessage={'Select type of Bulk Outreach:'}
                />
              </label>
              <div className='w50'>
                <CustomSelect
                  name='bulkOutreach'
                  onChange={e => setOutreachType(e.currentTarget.value)}
                  value={outreachType}
                >
                  {bulkOutreachTypes.map(type => (
                    <option key={type} value={type}>
                      {intl.formatMessage(messages[type])}
                    </option>
                  ))}
                </CustomSelect>
                <Button
                  autoSize
                  disabled={!selectedSuppliers.length || isCreatingOutreach}
                  className='ml2'
                  label={
                    <FormattedMessage
                      id='BulkOutreach.CreateBulkRequest'
                      defaultMessage='Create bulk request'
                    />
                  }
                  onClick={() => setOpenBulkOutreachDialog(true)}
                />
              </div>
            </div>
            <Paper noPadding>
              {selectedSuppliers.length > 0 && (
                <div className={classes.root}>
                  <div className={classes.centerAlignment}>
                    <div className={classes.bold}>
                      {selectedSuppliers.length}
                    </div>
                    <FormattedMessage
                      id='BulkOutreach.SuppliersSelected'
                      defaultMessage='{count, plural, one {supplier selected.} other {suppliers selected.}}'
                      values={{
                        count: selectedSuppliers.length.toLocaleString()
                      }}
                    />
                    {suppliersWithoutContactNotSelected.length !== 0 && (
                      <>
                        <div className={classes.bold + ' pl1'}>
                          {suppliersWithoutContactNotSelected.length}
                        </div>
                        <FormattedMessage
                          id='BulkOutreach.SuppliersWithoutContactsNotSelected'
                          defaultMessage={
                            ' {unselected, plural, one {supplier} other {suppliers}} not included due to missing contact.'
                          }
                          values={{
                            unselected: suppliersWithoutContactNotSelected.length.toLocaleString()
                          }}
                        />
                      </>
                    )}
                    <button
                      className={classes.button + ' ' + classes.bold}
                      onClick={() => clearSelection()}
                    >
                      <FormattedMessage
                        id='BulkOutreach.ClearSelection'
                        defaultMessage='Clear selection'
                      />
                    </button>
                  </div>
                  <IconButton
                    className={classes.button}
                    onClick={() => clearSelection()}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              <Table
                rowGetter={getRow}
                rowCount={pageTable.size}
                sort={handleSortChange}
                sortDirection={sortDirection}
                sortBy={sortBy}
                rowHeight={65}
                minWidth={1000}
              >
                <Column
                  label={
                    <Checkbox
                      disabled={isAllWithoutContact}
                      checked={!isAllWithoutContact && isAllSelected}
                      className={classes.checkboxTeal}
                      onChange={e => {
                        e.target.checked ? handleSelectAll() : handleClearAll()
                      }}
                      ariaLabel='Select all suppliers'
                    />
                  }
                  dataKey='id'
                  cellRenderer={renderCheckboxCell}
                  width={isMobile ? 44 : 24}
                  disableSort
                />
                <Column
                  label={
                    <FormattedMessage
                      id='BulkOutreach.SupplierName'
                      defaultMessage='Supplier Name'
                    />
                  }
                  aria-label='Supplier Name Column'
                  dataKey='supplier'
                  cellRenderer={renderSupplierName}
                  width={400}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='BulkOutreach.Contact'
                      defaultMessage='Contact'
                    />
                  }
                  aria-label='Contact Column'
                  dataKey='contacts'
                  cellRenderer={renderContact}
                  width={400}
                  className={styles.overflowVisible}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='BulkOutreach.YTDSpendAmount'
                      defaultMessage='YTD Spend'
                    />
                  }
                  aria-label='YTD Spend Column'
                  dataKey='ytdSpend'
                  cellRenderer={renderYtdSpend}
                  width={100}
                />
              </Table>
              {!sortedSuppliers?.size && (
                <Text className='tc pv4'>
                  <FormattedMessage
                    id='BulkOutreach.NoSupplier'
                    defaultMessage='No Supplier Found'
                  />
                </Text>
              )}
            </Paper>
            {sortedSuppliers.size > 0 && (
              <div className='flex items-center justify-between mv2'>
                <Text role='status'>
                  <FormattedMessage
                    id='BulkOutreach.Results'
                    defaultMessage={`{total, plural, one {Result 1 supplier} other {Results {localeStart} - {localeEnd} of {localeTotal} suppliers}}`}
                    values={{
                      total: sortedSuppliers?.size || 0,
                      localeTotal: (
                        sortedSuppliers?.size || 0
                      ).toLocaleString(),
                      localeStart: start.toLocaleString(),
                      localeEnd: end.toLocaleString()
                    }}
                  />
                </Text>
                {totalPages > 1 && (
                  <Pagination
                    totalPages={totalPages}
                    currentPage={pageNumber}
                    onChange={p => setPageNumber(p)}
                  />
                )}
              </div>
            )}
          </>
        )}
      </PageSection>
      {isSupplierProfileLoadDone && (
        <AddOutreachContactForm
          skipMessageDialog
          onClickCancel={onClickCancel}
        />
      )}
      <Dialog
        open={openBulkOutreachDialog}
        onClose={() => setOpenBulkOutreachDialog(false)}
      >
        <DialogTitle onClose={() => setOpenBulkOutreachDialog(false)}>
          <FormattedMessage
            id='BulkOutreach.SustainabilitySurveyRequest'
            defaultMessage='Sustainability Survey Request'
          />
        </DialogTitle>
        <DialogContent>
          <Text className='mb3'>
            <FormattedMessage
              id='BulkOutreach.generateEmail'
              defaultMessage='Are you sure you want to send this request?'
            />
          </Text>
        </DialogContent>
        <CustomDialogAction>
          <Button autoSize onClick={() => setOpenBulkOutreachDialog(false)}>
            <FormattedMessage
              id='BulkOutreach.Cancel'
              defaultMessage='Cancel'
            />
          </Button>
          <Button autoSize onClick={handleCreateBulkRequest}>
            <FormattedMessage
              id='BulkOutreach.SendRequest'
              defaultMessage='Send Request'
            />
          </Button>
        </CustomDialogAction>
      </Dialog>
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle onClose={() => setOpenConfirmationDialog(false)}>
          <FormattedMessage
            id='BulkOutreach.Confirmation'
            defaultMessage='Confirmation'
          />
        </DialogTitle>
        <DialogContent>
          <Text className='mb3'>
            <FormattedMessage
              id='BulkOutreach.RequestsSubmitted'
              defaultMessage='Sustainability survey requests submitted: {total}'
              values={{
                total: requests && (
                  <b>
                    {requests.validRequestsAmount +
                      requests.invalidRequestsAmount}
                  </b>
                )
              }}
            />
          </Text>
          <div className='flex items-center'>
            <CheckMark color='primary' />
            <Text className='ml2'>
              <FormattedMessage
                id='BulkOutreach.RequestsSent'
                defaultMessage='Sent successfully: {requestsSent}'
                values={{
                  requestsSent: requests && (
                    <b>{requests.validRequestsAmount}</b>
                  )
                }}
              />
            </Text>
          </div>
        </DialogContent>
        <CustomDialogAction>
          <Button autoSize onClick={() => setOpenConfirmationDialog(false)}>
            <FormattedMessage id='BulkOutreach.Done' defaultMessage='Done' />
          </Button>
        </CustomDialogAction>
      </Dialog>
    </>
  )
}

export default BulkOutreach
