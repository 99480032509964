import { fromJS, List, Map, RecordOf } from 'immutable'
import { requestSuccess, requestFailure } from 'shared/actionTypes'
import * as actionTypes from '../../actionTypes'

export type BodOutput = Map<
  string,
  RecordOf<{
    'Possible columns': List<string>
    'Proposed Column Map': string
  }>
>

export type BodResponse = RecordOf<{
  output: BodOutput
  status: string
  test: string
}>

type DataQuality = RecordOf<{
  loading: boolean
  isInitializing: boolean
  uploadingVendor: boolean
  uploadingSpend: boolean
  type: string
  submittingColumnsMappingVendor: boolean
  submittingColumnsMappingSpend: boolean
  bdoResponseVendor: BodResponse
  bdoResponseSpend: BodResponse
  vendorColumnsMapping: {[key: string]: string}
  spendColumnsMapping: {[key: string]: string}
  jobs: List<any>
  jobStatus: any
}>

const defaultState: DataQuality = fromJS({
  loading: false,
  isInitializing: false,
  uploadingVendor: false,
  uploadingSpend: false,
  type: 'vendor',
  submittingColumnsMappingVendor: false,
  submittingColumnsMappingSpend: false,
  bdoResponseVendor: {},
  bdoResponseSpend: {},
  vendorColumnsMapping: {},
  spendColumnsMapping: {},
  jobs: [],
  jobStatus: {}
})

const dataQualityReducer = (state = defaultState, action) => {
  let job, jobIndex
  switch (action.type) {
    case actionTypes.UPLOAD_DATA_QUALITY_FILE:
      return action.payload.type === 'vendor'
        ? state.set('uploadingVendor', true).set('bdoResponseVendor', fromJS({})).set('type', 'vendor').set('isInitializing', true)
        : state.set('uploadingSpend', true).set('bdoResponseSpend', fromJS({})).set('type', 'spend').set('isInitializing', true)

    case requestSuccess(actionTypes.UPLOAD_DATA_QUALITY_FILE):
      return action.payload.type === 'vendor'
        ? state
          .set('uploadingVendor', false)
          .set('bdoResponseVendor', fromJS(action.payload))
        : state
          .set('uploadingSpend', false)
          .set('bdoResponseSpend', fromJS(action.payload))

    case requestFailure(actionTypes.UPLOAD_DATA_QUALITY_FILE):
      return action.payload.type === 'vendor'
        ? state.set('uploadingVendor', false).set('bdoResponseVendor', fromJS({}))
        : state.set('uploadingSpend', false).set('bdoResponseSpend', fromJS({}))

    case actionTypes.GET_DATA_QUALITY_JOBS:
      return state.set('loading', true)

    case requestSuccess(actionTypes.GET_DATA_QUALITY_JOBS):
      return state.set('loading', false).set('jobs', fromJS(action.payload))

    case requestFailure(actionTypes.GET_DATA_QUALITY_JOBS):
      return state.set('loading', false).set('jobs', fromJS([]))

    case actionTypes.CONFIRM_COLUMNS_MAPPING:
      let parser = ({ jobId, filename, type, ...rest }):{[key: string]: string} => {
        return rest
      }
      return action.payload.type === 'vendor'
        ? state
        .set('vendorColumnsMapping', parser(action.payload))
        .set('submittingColumnsMappingVendor', true)
        : state
        .set('spendColumnsMapping', parser(action.payload))
        .set('submittingColumnsMappingSpend', true)

    case requestFailure(actionTypes.CONFIRM_COLUMNS_MAPPING):
      return action.payload.type === 'vendor'
        ? state.set('submittingColumnsMappingVendor', false)
        : state.set('submittingColumnsMappingSpend', false)

    case requestSuccess(actionTypes.CONFIRM_COLUMNS_MAPPING):
      jobIndex = state
        .get('jobs')
        .findIndex(job => job.get('id') === action.payload.jobId)
      return state.get('isInitializing')
        ? jobIndex !== -1
          ? ( action.payload.type === 'vendor'
            ? state
              .set('submittingColumnsMappingVendor', false)
              .updateIn(['jobs', jobIndex, 'status'], status => 'analyzing')
            : state
              .set('submittingColumnsMappingSpend', false)
              .updateIn(['jobs', jobIndex, 'status'], status => 'analyzing'))
          : (action.payload.type === 'vendor'
            ? state
              .set('submittingColumnsMappingVendor', false)
            : state
              .set('submittingColumnsMappingSpend', false)
              )
        : jobIndex !== -1
        ? ( action.payload.type === 'vendor'
          ? state
            .set('submittingColumnsMappingVendor', false)
            .set('bdoResponseVendor', fromJS({}))
            .updateIn(['jobs', jobIndex, 'status'], status => 'analyzing')
          : state
            .set('submittingColumnsMappingSpend', false)
            .set('bdoResponseSpend', fromJS({}))
            .updateIn(['jobs', jobIndex, 'status'], status => 'analyzing'))
        : (action.payload.type === 'vendor'
          ? state
            .set('submittingColumnsMappingVendor', false)
            .set('bdoResponseVendor', fromJS({}))
          : state
            .set('submittingColumnsMappingSpend', false)
            .set('bdoResponseSpend', fromJS({}))
            )

    case requestSuccess(actionTypes.GET_JOB_STATUS):
      jobIndex = state
        .get('jobs')
        .findIndex(job => job.get('id') === action.payload.jobId)
      job = jobIndex !== -1 && state.getIn(['jobs', jobIndex])

      return job?.get('status') !== action.payload.jobStatus.status
        ? state
            .set('jobStatus', fromJS(action.payload.jobStatus))
            .updateIn(
              ['jobs', jobIndex, 'status'],
              status => action.payload.jobStatus.status
            )
            .updateIn(
              ['jobs', jobIndex, 'type'],
              status => action.payload.jobStatus.type
            )
            .updateIn(['jobs', jobIndex, 'attachments'], attachments =>
              fromJS(action.payload.jobStatus.attachments)
            )
            .updateIn(['jobs', jobIndex, 'metadata'], metadata =>
              fromJS(action.payload.jobStatus.metadata)
            )
        : state.set('jobStatus', fromJS(action.payload.jobStatus))

    case actionTypes.CLEAR_JOB_STATUS:
      return state.set('jobStatus', fromJS({}))

    case actionTypes.RESET_JOB_STATE:
      return defaultState

    default:
      return state
  }
}

export default dataQualityReducer
