import { takeLatest, all, takeEvery, fork } from 'redux-saga/effects'
import * as actionTypes from '../actionTypes'
import searchForOrgSaga from './searchForOrgSaga'
import connectUserToOrgSaga from './connectUserToOrgSaga'
import createOrgSaga from './createOrgSaga'
import createCommunitySaga from './createCommunitySaga'
import joinCommunitySaga from './joinCommunitySaga'
import becomeTealbotSaga from './becomeTealbotSaga'
import makeSupplierABuyerSaga from './makeSupplierABuyerSaga'
import deleteSupplierSaga from './deleteSupplierSaga'
import sendVerificationCodeSaga from './sendVerificationCodeSaga'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import takeFirst from 'shared/utils/sagas/takeFirst'
import community from 'shared/utils/api/community'
import { notify } from 'shared/actions'
import { call, put } from 'redux-saga/effects'
import uploadFileToDataLoaderSaga from './uploadFileToDataLoaderSaga'
import dqService from 'shared/utils/api/dqService'

const elasticLoaderEndpoint = `${apiRoutes.loader}/elasticLoader`
const serverEndpoint = `${apiRoutes.authService}/server`
const suppliersEndpoint = `${apiRoutes.authService}/suppliers`

export default function* rootSaga() {
  yield fork(becomeTealbotSaga)
  yield all([
    takeFirst(
      actionTypes.UPDATE_MAPPING,
      createApiRequestSaga(
        (action) => api.post(`${elasticLoaderEndpoint}/updateMapping`, action.payload),
        {
          notifyOnError: true
        }
      )
    ),
    takeFirst(
      actionTypes.REINDEX_SOME_SUPPLIERS,
      createApiRequestSaga(
        (action) => api.post(`${elasticLoaderEndpoint}/indexSuppliers`, action.payload),
        {
          notifyOnError: true
        }
      )
    ),
    takeFirst(
      actionTypes.DROP_INDEX,
      createApiRequestSaga(
        () => api.get(`${elasticLoaderEndpoint}/addMapping`),
        {
          notifyOnError: true
        }
      )
    ),

    takeFirst(
      actionTypes.REINDEX_SUPPLIERS,
      createApiRequestSaga(() => api.get(`${elasticLoaderEndpoint}`), {
        notifyOnError: true
      })
    ),

    takeFirst(
      actionTypes.REINDEX_USERS,
      createApiRequestSaga(() => api.get(`${elasticLoaderEndpoint}/user`), {
        notifyOnError: true
      })
    ),

    takeFirst(
      actionTypes.ADD_USER_MAPPING,
      createApiRequestSaga(
        () => api.get(`${elasticLoaderEndpoint}/addUserMapping`),
        {
          notifyOnError: true
        }
      )
    ),

    takeFirst(
      actionTypes.BACKUP_DATASTORE,
      createApiRequestSaga(
        () => api.get(`${serverEndpoint}/userExportDatastore`),
        {
          notifyOnError: true
        }
      )
    ),

    takeFirst(
      actionTypes.FLUSH_CACHE,
      createApiRequestSaga(
        action =>
          api.post(`${serverEndpoint}/resetCache`, { key: action.payload }),
        {
          notifyOnError: true
        }
      )
    ),

    takeFirst(
      actionTypes.SYNC_DIVERSITY,
      createApiRequestSaga(
        action => api.get(`${serverEndpoint}/processDiversityStatus`),
        {
          notifyOnError: true
        }
      )
    ),
    takeFirst(
      actionTypes.RUN_PATCH,
      createApiRequestSaga(
        action =>
          api.get(
            `${apiRoutes.loader}/patch/${global.encodeURIComponent(
              action.payload
            )}`
          ),
        {
          notifyOnError: true
        }
      )
    ),

    takeFirst(
      actionTypes.MOVE_SUPPLIER,
      createApiRequestSaga(
        action =>
          api.post(
            `${suppliersEndpoint}/${action.payload.orgUnitId}/setParent`,
            { parentId: action.payload.parentId }
          ),
        {
          notifyOnError: true
        }
      )
    ),
    takeFirst(
      actionTypes.REMOVE_FROM_COMMUNITY,
      createApiRequestSaga(
        function*(action) {
          const { communityId, orgUnitId, name } = action.payload
          yield call(community.removeClientFromCommunity, {
            communityId,
            orgUnitId
          })
          yield put(
            notify({
              message: `"${name}" has removed from community "${communityId}"`
            })
          )
        },
        {
          notifyOnError: true
        }
      )
    ),
    takeLatest(actionTypes.SEARCH_FOR_ORGANIZATION, searchForOrgSaga),
    takeEvery(actionTypes.CONNECT_USER_TO_ORG, connectUserToOrgSaga),
    takeFirst(actionTypes.CREATE_ORG, createOrgSaga),
    takeFirst(actionTypes.CREATE_COMMUNITY, createCommunitySaga),
    takeLatest(actionTypes.MAKE_SUPPLIER_A_BUYER, makeSupplierABuyerSaga),
    takeFirst(actionTypes.DELETE_SUPPLIER, deleteSupplierSaga),
    takeFirst(
      actionTypes.UPLOAD_FILE_TO_DATA_LOADER,
      uploadFileToDataLoaderSaga
    ),
    takeFirst(actionTypes.JOIN_COMMUNITY, joinCommunitySaga),
    takeFirst(actionTypes.GET_REASON_KEY, sendVerificationCodeSaga),
    takeFirst(
      actionTypes.UPLOAD_DATA_QUALITY_FILE,
      createApiRequestSaga(
        function*(action) {
          const response = yield call(dqService.uploadFile, action.payload)
          yield put(
            notify({
              message: `Upload file successfully.`
            })
          )
          return yield response
        },
        {
          notifyOnError: true
        }
      )
    ),
    takeFirst(
      actionTypes.GET_DATA_QUALITY_JOBS,
      createApiRequestSaga(dqService.getJobs, {
        notifyOnError: true
      })
    ),
    takeFirst(
      actionTypes.CONFIRM_COLUMNS_MAPPING,
      createApiRequestSaga(function*(action) {
        yield call(dqService.confirmColumnsMapping, action.payload)
        yield put(
          notify({
            message: `Submit columns mapping successfully.`
          })
        )
        return yield {
          jobId: action.payload.jobId,
          type: action.payload.type
        }
      })
    ),
    takeFirst(
      actionTypes.GET_JOB_STATUS,
      createApiRequestSaga(function*(action) {
        const jobStatus = yield call(dqService.getJobStatus, action.payload)
        return yield {
          jobStatus,
          jobId: action.payload
        }
      })
    )
  ])
}
