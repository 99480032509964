export type Feature =
  | 'supplierOutreach'
  | 'reportSnapshot'
  | 'selfCertificationRequest'
  | 'ESGSurveyPhase2'

const appTeam = [
  'ed@tealbook.com',
  'william@tealbook.com',
  'ali@tealbook.com',
  'able@tealbook.com',
  'chukwuka@tealbook.com',
  'dana@tealbook.com',
  'nakul@tealbook.com',
  'elizabeth@tealbook.com',
  'john.doe@tealbook.com',
  'sanjukumar.rsystems@tealbook.com',
  'eric.karnis@tealbook.com',
  'ammarkhan.rsystems@tealbook.com',
  'jason.zhou@tealbook.com'
]
// const dqTeam = [
//   'timi.olanrewaju@tealbook.com',
//   'leo@tealbook.com',
//   'palvasha.rathi@tealbook.com',
//   'jillian@tealbook.com',
//   'himanshu.handa@tealbook.com',
//   'lucas@tealbook.com',
//   'aderimike@tealbook.com',
//   'tova@tealbook.com',
//   'louis@tealbook.com',
//   'moiz@tealbook.com',
//   'matthew.chang@tealbook.com'
// ]
const prodTeam = [
  'mouli@tealbook.com',
  'lucas@tealbook.com',
  'amanda@tealbook.com',
  'sarah@tealbook.com',
  'judy.lei@tealbook.com',
  'derek.bell@tealbook.com',
  'tkoopmans@tealbook.com',
  'jfranco@tealbook.com',
  'cherry.hung@tealbook.com',
  'andrea.eversfield@tealbook.com',
  'marina@tealbook.com'
]
const supplierExperienceTeam = [
  'abhay@tealbook.com',
  'amil.baljic@tealbook.com',
  'ercie.hamilton@tealbook.com',
  'robyn.vanek@tealbook.com'
]

const internalStakeholderTeam = [
  'kayli.bui@tealbook.com',
  'daniel.chan@tealbook.com'
]

// const csTeam = [
//   'aditi.singh@tealbook.com',
//   'alexandra.zhadan@tealbook.com',
//   'april.johnson@tealbook.com',
//   'jessica@tealbook.com',
//   'koryn.rooneywalters@tealbook.com',
//   'nicholas.lazzarato@tealbook.com',
//   'srividhya.raghunath@tealbook.com'
// ]
const marketingTeam = [
  'natalie@tealbook.com',
  'jimmy.lefever@tealbook.com',
  'annie.codynesser@tealbook.com'
]
// const qaTeam = [
//   'anurag.prasad@tealbook.com.'
// ]
const enabledFeaturesByUserEmail: { [key in Feature]: string[] } = {
  supplierOutreach: [
    'concierge@tealbook.com',
    'lisa@tealbook.com',
    'jfranco@tealbook.com',
    'sheri.rouse@parkland.ca'
  ]
    .concat(prodTeam)
    .concat(appTeam)
    .concat(supplierExperienceTeam),
  selfCertificationRequest: ['concierge@tealbook.com', 'lisa@tealbook.com']
    .concat(prodTeam)
    .concat(appTeam)
    .concat(supplierExperienceTeam),
  reportSnapshot: [
    'concierge@tealbook.com',
    'lisa@tealbook.com',
    'jillian@tealbook.com',
    'mtsoi@ea.com',
    'jessica.kiser@ttec.com',
    'tuhina.nandi@ttec.com'
  ]
    .concat(appTeam)
    .concat(prodTeam),
  ESGSurveyPhase2: ['concierge@tealbook.com']
    .concat(appTeam)
    .concat(prodTeam)
    .concat(supplierExperienceTeam)
    .concat(internalStakeholderTeam)
    .concat(marketingTeam)
}

type Options = {
  feature: Feature
  userEmail: string
}
const isFeatureEnabled = ({ feature, userEmail }: Options): boolean =>
  enabledFeaturesByUserEmail[feature].some(email => email === userEmail)

export default isFeatureEnabled
