import React, { ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'

import Label from 'shared/components/Label'
import Select from 'shared/components/Select'

import { Tier2Communities } from 'supplier/Insight/containers/Tier2InvitationContainer/Tier2InvitationContainer'

type Props = {
  buyer: string
  acceptedTier2Communities: Tier2Communities
  handleChangeBuyer: (value: string) => void
}

export const BuyerSelect = ({
  acceptedTier2Communities,
  buyer,
  handleChangeBuyer
}: Props) => (
  <>
    <Label htmlFor='buyer'>
      <FormattedMessage id='UploadSpendDetail.Buyer' defaultMessage='Buyer' />
    </Label>
    <Select
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        handleChangeBuyer(e.currentTarget.value)
      }
      value={buyer}
      fullWidth
      name='buyer'
    >
      <FormattedMessage
        id='UploadSpendDetail.SelectBuyer'
        defaultMessage='Select a buyer'
      >
        {msg => <option value=''>{msg}</option>}
      </FormattedMessage>
      {acceptedTier2Communities?.map(comm => (
        <option key={comm.get('buyerId')} value={comm.get('buyerId')}>
          {comm.get('buyerName')}
        </option>
      ))}
    </Select>
  </>
)
