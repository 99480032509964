import React, { useState } from 'react'
import LoaderState from 'admin/components/LoaderState'
import Page from 'shared/components/Page'
import ClientSpendModify from 'admin/components/ClientSpendModify'
import { Tab, Tabs } from '@material-ui/core'
import ClientSpendLoad from 'admin/components/ClientSpendLoad'

const LoaderContainer = () => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={(e, newTab) => setSelectedTab(newTab)}
        aria-label='client loader tabs'
        indicatorColor='primary'
        textColor='primary'
      >
        <Tab label='Load Client Spend' />
        <Tab label='Modify Client Spend' />
        <Tab label='Loader State' />
      </Tabs>

      <Page title={'Client Spend Loader'}>
        {selectedTab === 0 && <ClientSpendLoad />}
        {selectedTab === 1 && <ClientSpendModify />}
        {selectedTab === 2 && <LoaderState />}
      </Page>
    </>
  )
}

export default LoaderContainer
