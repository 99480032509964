import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import createAddForm from 'shared/utils/createAddForm'
import AddUserContactForm from 'shared/components/AddUserContactForm'
import { ADD_CONTACT } from '../../actionTypes'
import newContactSelectors from '../../selectors/newContactSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import profileSelectors from '../../selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import {
  cancelAddingContact,
  selectNewUserForContact,
  selectUserForContact
} from '../../actions'
import { getFormValues } from 'redux-form/immutable'
import { createSelector } from 'reselect'
import { RecordOf, List } from 'immutable'
import RootState from 'shared/models/RootState'
import Contact from 'shared/models/Contact'

const formName = 'buyer/supplierProfile/addContact'
const formValueSelector = getFormValues(formName)

const getUsers = createSelector(
  profileSelectors.getContacts,
  usersSelectors.getBySupplier,
  (contacts: List<RecordOf<Contact>>, supplierUsers) =>
    supplierUsers &&
    supplierUsers.filter(
      user =>
        user &&
        !contacts.some(c => c.get('user') === user.get('id')) &&
        user.get('email') !== '' &&
        !user.get('blocked')
    )
)

const getInitialValues = createSelector(
  newContactSelectors.getSelectedUser,
  newContactSelectors.getNewUser,
  (selectedUser, newUser) => ({
    user: selectedUser || newUser,
    isPrivate: false,
    message: 'I would like to add you as a contact in TealBook.',
    sendMessage: false
  })
)

type ContainerProps = {
  match: { params: { supplierId: string } }
  useList: boolean
  isClientApp: boolean
} & RouteComponentProps

type FormData = RecordOf<{
  user: RecordOf<{
    email: string
  }>
  sendMessage: string
}>

const AddContactForm = withRouter(
  connect(
    (state: RootState, props: ContainerProps) => {
      const selectedUser = newContactSelectors.getSelectedUser(state)
      const addSuccess = newContactSelectors.addSuccess(state)
      const formValues = formValueSelector(state) as FormData

      return {
        email: formValues && formValues.getIn(['user', 'email']),
        enablePublicPrivate: true,
        domains: profileSelectors.getDataField(state, 'domains') || [],
        addingContact: newContactSelectors.isAddingContact(state),
        creatingNewUser: newContactSelectors.isCreatingNewUser(state),
        addingMyself:
          selectedUser &&
          selectedUser.get('id') === sessionSelectors.getUserId(state),
        forContact: true,
        addSuccess,
        addedContactId: selectedUser && selectedUser.get('id'),
        addedContactName:
          selectedUser &&
          `${selectedUser.get('firstName') || ''} ${selectedUser.get(
            'lastName'
          ) || ''}`,
        supplierId: props.match.params.supplierId,
        suggestedUsers: getUsers(state, props.match.params.supplierId),
        users: usersSelectors.getBySupplier(
          state,
          props.match.params.supplierId
        ),
        initialValues: getInitialValues(state),
        sendMessage: formValues && formValues.getIn(['sendMessage'])
      }
    },
    {
      onClickCancel: cancelAddingContact,
      onClickSuggestedUser: selectUserForContact,
      onClickNewUser: selectNewUserForContact
    }
  )(
    createAddForm(
      {
        formName,
        submitActionType: ADD_CONTACT
      },
      AddUserContactForm
    )
  )
)

export default AddContactForm
