import React, { Component, Fragment, ReactElement } from 'react'
import { connect } from 'react-redux'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { List, Map, fromJS, RecordOf } from 'immutable'
import Naics from 'shared/models/Naics'
import ClassificationCode from 'shared/models/ClassificationCode'
import {
  addClassificationCode,
  removeClassificationCode
} from 'supplier/Company/actions'
import companySelectors from 'supplier/shared/selectors/companySelectors'
import CheckboxTreeView from 'shared/components/CheckboxTreeView'
import RootState from 'shared/models/RootState'
import searchSelectors from 'buyer/Search/selectors/searchSelectors'
import qs from 'qs'
import NaicsForSearchInput from 'shared/models/NaicsForSearchInput'

const PopoverWithStyles = withStyles({
  paper: {
    padding: '1rem',
    minWidth: 200
  }
})(Popover)

type Props = {
  customButtonLabel?: string | ReactElement<FormattedMessage>
  selected: List<string>
  addClassificationCode: (arg: ClassificationCode) => void
  removeClassificationCode: (arg: { schema: string; code: string }) => void
  onSearchInput?: (code: string) => void
  queryString: string
  buyerSearch?: boolean
  intl: IntlShape
}

type State = {
  naicsText: string
  showResults: boolean
  anchorEl: any
  parentNodes: List<Node>
  mapOfNodes: Map<string, Node>
}

type NaicsNode = RecordOf<{
  sectorCode: string
  numDigits: number
  code: string
  name: string
  parent: string
  children: List<string>
  level: number
}>

type Node = RecordOf<{
  key: string
  children: List<string>
  displayName: string
}>

export class NaicsSearchContainer extends Component<Props, State> {
  state: State = {
    naicsText: '',
    showResults: false,
    anchorEl: undefined,
    parentNodes: List(),
    mapOfNodes: Map()
  }

  handleNaicsChange = e => {
    this.setState({
      naicsText: e.target.value,
      anchorEl: e.target
    })
  }

  handleKeyPress = e => {
    if (e.charCode === 13) {
      this.handleSearchClick()
    }
  }

  handleSearchClick = (e?) => {
    const { naicsText } = this.state
    if (naicsText) {
      this.searchNaics(naicsText)
      this.setState({
        naicsText: '',
        showResults: true
      })
    }
  }

  // assign children, parent, and level prop to each node and put into a Map
  transformToNaicsNodeMap = (
    map: Map<string, string>
  ): Map<string, NaicsNode> => {
    const newMap: Map<string, NaicsNode> = Map()
    const nodeList = List(map).sort((a, b) => (a[0] <= b[0] ? -1 : 1))
    let levelMap: Map<string, number> = nodeList.reduce(
      (acc, x) => acc.set(x[0], 0),
      Map()
    )
    const nodesMap = nodeList.reduce((nodeMap, pair, i) => {
      if (i === 0) {
        nodeMap = nodeMap.set(pair[0], this.transformToNaicsNode(pair))
        levelMap = levelMap.update(pair[0], lvl => lvl + 1)
      } else {
        const prevNode = nodeList.get(i - 1)
        const prevNodeKey = prevNode && prevNode[0]
        const level = prevNodeKey && levelMap.get(prevNodeKey)
        if (prevNodeKey && pair[0].startsWith(prevNodeKey) && !!level) {
          nodeMap = nodeMap.set(
            pair[0],
            this.transformToNaicsNode(pair, prevNodeKey, level)
          )
          levelMap = levelMap.update(pair[0], lvl => level + 1)
          nodeMap = nodeMap.update(prevNodeKey, node => {
            let newNode: NaicsNode = node
            if (node) {
              newNode = this.transformToNaicsNode(
                [node.get('code'), node.get('name')],
                node.get('parent'),
                level - 1,
                node.get('children').push(pair[0])
              )
            }
            return newNode
          })
        } else {
          const length = pair[0].includes('-')
            ? pair[0].split('-')[0].length
            : pair[0].length
          nodeMap = nodeMap.set(pair[0], this.transformToNaicsNode(pair))
          levelMap = levelMap.update(pair[0], lvl => lvl + 1)
          for (let j = length - 1; j >= 2; j--) {
            const level = levelMap.get(pair[0].slice(0, j))
            if (nodeMap.has(pair[0].slice(0, j)) && !!level) {
              nodeMap = nodeMap.set(
                pair[0],
                this.transformToNaicsNode(pair, pair[0].slice(0, j), level)
              )
              levelMap = levelMap.update(pair[0], lvl => level + 1)
              if (j !== 2) {
                nodeMap = nodeMap.update(pair[0].slice(0, j), node => {
                  let newNode: NaicsNode = node
                  if (node) {
                    newNode = this.transformToNaicsNode(
                      [node.get('code'), node.get('name')],
                      node.get('parent'),
                      level - 1,
                      node.get('children').push(pair[0])
                    )
                  }
                  return newNode
                })
              }
              break
            }
          }
        }
      }
      return nodeMap
    }, newMap)
    return nodesMap
  }

  transformToNaicsNode = (
    pair: [string, string],
    parent: string = '',
    parentLevel: number = 0,
    children: List<string> = List()
  ): NaicsNode => {
    return fromJS({
      sectorCode: pair[0].slice(0, 2),
      numDigits: pair[0].includes('-')
        ? pair[0].split('-')[0].length
        : pair[0].length,
      code: pair[0],
      name: pair[1],
      parent: parent,
      children: children,
      level: parentLevel + 1
    })
  }

  getNaicsParents = (
    naicsNodeMap: Map<string, NaicsNode>,
    naicsKey: string
  ): Map<string, string> => {
    let branch: Map<string, string> = Map()
    if (!naicsNodeMap.has(naicsKey)) return branch
    // get current node
    let key = naicsKey
    let node = naicsNodeMap.get(key)
    if (node) branch = branch.set(key, node.get('name'))
    // get parents
    let length = key.includes('-') ? key.split('-')[0].length : key.length
    while (length > 2) {
      length--
      key = key.slice(0, length)
      node = naicsNodeMap.get(key)
      if (node) branch = branch.set(key, node.get('name'))
    }
    return branch
  }

  findMatches = (
    list: Map<string, NaicsNode>,
    query: string
  ): Map<string, NaicsNode> => {
    if (!!query) {
      return isNaN(+query.replace('-', ''))
        ? list.filter(node =>
            node
              .get('name')
              .toLowerCase()
              .includes(query.toLowerCase())
          )
        : list.filter(node => node.get('code').startsWith(query))
    }
    return Map()
  }

  // assign a flat map of matching naics codes to state
  searchNaics = (query: string): void => {
    const { buyerSearch } = this.props
    let map: Map<string, string> = Map()
    let NaicsKeys: Array<string> = []

    // //buyer search only reliable to search for 4 digit NAICS codes. Excluding single codes which are normally grouped ie 31,32,33
    if (buyerSearch) {
      NaicsKeys = Object.keys(NaicsForSearchInput)
    } else {
      NaicsKeys = Object.keys(Naics)
    }
    const naicsMap: Map<string, string> = NaicsKeys.reduce((acc, key) => {
      return acc.set(key, Naics[key]['defaultMessage'])
    }, map)
    const naicsNodeMap = this.transformToNaicsNodeMap(naicsMap)
    const nodeMatches = this.findMatches(naicsNodeMap, query)
    map = Map()
    const branchMatches = nodeMatches.reduce((acc, value, key) => {
      return acc.merge(this.getNaicsParents(naicsNodeMap, key))
    }, map)
    this.setState({
      mapOfNodes: this.transformToNaicsNodeMap(branchMatches).map(x =>
        fromJS({
          key: x.get('code'),
          displayName: `${x.get('code')} ${x.get('name')}`,
          children: x.get('children')
        })
      ),
      parentNodes: List(this.transformToNaicsNodeMap(branchMatches))
        .filter(x => x[1].get('level') === 1)
        .map(x =>
          fromJS({
            key: x[1].get('code'),
            displayName: `${x[1].get('code')} ${x[1].get('name')}`,
            children: x[1].get('children')
          })
        )
        .sort((a, b) => (a.get('key') <= b.get('key') ? -1 : 1))
    })
  }

  handleNaicsClick = (schema: string, code: string, remove: boolean) => {
    const {
      addClassificationCode,
      removeClassificationCode,
      onSearchInput
    } = this.props
    const selectedNaics: ClassificationCode = fromJS({
      schema: 'NAICS',
      code: code,
      source: 'Manual'
    })

    //updates queryString when selecting node in Checkbox Tree
    if (onSearchInput) {
      onSearchInput(code)
    } else {
      if (remove) {
        removeClassificationCode({ schema, code })
      } else {
        addClassificationCode(selectedNaics)
      }
    }
  }

  handleRequestClose = (e?) => {
    this.setState({
      showResults: false
    })
  }

  //get query string filter values to control CheckboxTree checkboxes
  getQueryStringToList = () => {
    const { filter } = qs.parse(this.props.queryString, {
      ignoreQueryPrefix: true
    })
    return !!filter ? List(Object.values<string[]>(filter).flat()) : List()
  }

  render() {
    const { parentNodes, mapOfNodes } = this.state
    const { selected, buyerSearch, intl } = this.props
    const selectedCheckBoxes = buyerSearch
      ? this.getQueryStringToList()
      : selected
    return (
      <Fragment>
        <Label htmlFor='inputNaics' className='db gray mb1 f7 pt1'>
          <FormattedMessage
            id='NaicsSearchContainer.SearchForNaicsCode'
            defaultMessage='Search for NAICS Code'
          />
        </Label>
        <div className='flex'>
          <div className='dib flex-auto'>
            <Input
              placeholder={intl.formatMessage({
                id: 'NaicsSearchContainer.EnterNaics',
                defaultMessage: 'Enter Code or Title'
              })}
              onChange={this.handleNaicsChange}
              onKeyPress={this.handleKeyPress}
              value={this.state.naicsText}
              name='inputNaics'
            />
          </div>
          <div className='dib pl2'>
            <Button
              label={
                this.props.customButtonLabel || (
                  <FormattedMessage
                    id='NaicsSearchContainer.Search'
                    defaultMessage='Search'
                  />
                )
              }
              onClick={this.handleSearchClick}
              aria-label='Search NAICS code'
            />
          </div>
        </div>
        <PopoverWithStyles
          open={this.state.showResults}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.handleRequestClose}
        >
          {mapOfNodes.size > 0 ? (
            <CheckboxTreeView
              selected={selectedCheckBoxes}
              initialExpanded={mapOfNodes
                .toList()
                .filter(x => x.get('children') && x.get('children').size > 0)
                .map(x => x.get('key'))
                .toArray()}
              onChangeCheckbox={(code: string, remove: boolean) =>
                this.handleNaicsClick('naics', code, remove)
              }
              parentNodeList={parentNodes}
              nodeMap={mapOfNodes}
            />
          ) : (
            <Text>
              <FormattedMessage
                id='NaicsSearchContainer.NoMatch'
                defaultMessage='No match found'
              />
            </Text>
          )}
        </PopoverWithStyles>
      </Fragment>
    )
  }
}

export default connect(
  (state: RootState) => {
    return {
      selected: companySelectors.getClassificationCodesBySchemaAsCodeList(
        state,
        'naics'
      ),
      queryString: searchSelectors.getQueryStringByRef(state, 'buyerSearch')
    }
  },
  {
    addClassificationCode,
    removeClassificationCode
  }
)(injectIntl(NaicsSearchContainer))
