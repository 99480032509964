export const LOAD_INSIGHTS = 'buyer/insight/LOAD_INSIGHT'
export const LOAD_SPEND = 'buyer/insight/LOAD_SPEND'
export const LOAD_SPEND_DETAIL = 'buyer/insight/LOAD_SPEND_DETAIL'
export const GET_SPEND_YEARS = 'buyer/insight/GET_SPEND_YEARS'
export const LOAD_REDUCED_DIVERSITY_REPORT =
  'buyer/insight/LOAD_REDUCED_DIVERSITY_REPORT'
export const GENERATE_DIVERSITY_REPORT =
  'buyer/insight/GENERATE_DIVERSITY_REPORT'
export const FILTER_OVERVIEW_CATEGORIES =
  'buyer/insight/FILTER_OVERVIEW_CATEGORIES'
export const SET_MATCH_ANY_CATEGORIES = 'buyer/insight/SET_MATCH_ANY_CATEGORIES'
export const SELECT_DIVERSITY_CATEGORY =
  'buyer/insight/SELECT_DIVERSITY_CATEGORY'
export const SET_DIVERSITY_CATEGORY = 'buyer/insight/SET_DIVERSITY_CATEGORY'
export const SELECT_ALL_DIVERSITY_CATEGORIES =
  'buyer/insight/SELECT_ALL_DIVERSITY_CATEGORIES'
export const CLEAR_ALL_DIVERSITY_CATEGORIES =
  'buyer/insight/CLEAR_DIVERSITY_CATEGORIES'
export const SELECT_DIVERSITY_AUTHORITY =
  'buyer/insight/SELECT_DIVERSITY_AUTHORITY'

export const SET_SPEND_CATEGORY = 'buyer/insight/SET_SPEND_CATEGORY'
export const SET_SPEND_COUNTRY = 'buyer/insight/SET_SPEND_COUNTRY'
export const SET_SPEND_SPEND_GROUP = 'buyer/insight/SET_SPEND_GROUP'
export const USE_SPEND_GROUP = 'buyer/insight/USE_SPEND_GROUP'
export const ONLY_PREFERRED = 'buyer/insight/ONLY_PREFERRED'
export const ONLY_DIVERSE = 'buyer/insight/ONLY_DIVERSE'

export const LOAD_CONSOLIDATION = 'buyer/insight/LOAD_CONSOLIDATION'

export const GET_TIER2_RANGE = 'buyer/insight/GET_TIER2_RANGE'
export const GET_TIER2_AGENCIES = 'buyer/insight/GET_TIER2_AGENCIES'
export const LOAD_TIER2_DATA = 'buyer/insight/GET_TIER2_DATA'
export const SELECT_TIER2_CATEGORY = 'buyer/insight/SELECT_TIER2_CATEGORY'
export const SELECT_ALL_TIER2_CATEGORIES =
  'buyer/insight/SELECT_ALL_TIER2_CATEGORIES'
export const CLEAR_ALL_TIER2_CATEGORIES =
  'buyer/insight/CLEAR_ALL_TIER2_CATEGORIES'

export const LOAD_TIER2_SUPPLIER_LIST = 'buyer/insight/LOAD_TIER2_SUPPLIER_LIST'
export const SEARCH_TIER2_SUPPLIERS = 'buyer/Search/SEARCH_TIER2_SUPPLIERS'
export const CREATE_TIER2_COMMUNITY = 'buyer/insight/CREATE_TIER2_COMMUNITY'
export const INVITE_TIER2_GROUP = 'buyer/insight/INVITE_TIER2_GROUP'
export const EXPORT_TIER2_SUPPLIER_TABLE =
  'buyer/insight/EXPORT_TIER2_SUPPLIER_TABLE'
export const SET_CUSTOM_RULES_TYPE = 'buyer/insight/SET_CUSTOM_RULES_TYPE'
export const CHANGE_DIVERSITY_QUALIFICATION_RULES =
  'buyer/insight/CHANGE_DIVERSITY_QUALIFICATION_RULES'

export const CHANGE_GROUPING = 'buyer/insight/CHANGE_GROUPING'
export const SELECT_GROUPING_VALUE = 'buyer/insight/SELECT_GROUPING_VALUE'
export const CLEAR_GROUPING_VALUE = 'buyer/insight/CLEAR_GROUPING_VALUE'

export const GET_SNAPSHOT_LIST = 'buyer/insight/GET_SNAPSHOT_LIST'
export const GET_SNAPSHOT = 'buyer/insight/GET_SNAPSHOT'
export const GET_SNAPSHOT_LINE_ITEMS = 'buyer/insight/GET_SNAPSHOT_LINE_ITEMS'
export const DELETE_SNAPSHOT = 'buyer/insight/DELETE_SNAPSHOT'
export const BULK_DELETE_SNAPSHOTS = 'buyer/insight/BULK_DELETE_SNAPSHOT'
export const SAVE_SNAPSHOT = 'buyer/insight/SAVE_SNAPSHOT'

export const LOAD_SUSTAINABILITY_REPORT_OVERVIEW =
  'buyer/insight/LOAD_SUSTAINABILITY_REPORT_OVERVIEW'
export const TOGGLE_ESG_REPORT_QUESTION =
  'buyer/insight/TOGGLE_ESG_REPORT_QUESTION'
export const COLLAPSE_ALL_ESG_REPORT_QUESTIONS =
  'buyer/insight/COLLAPSE_ALL_ESG_REPORT_QUESTIONS'
export const GET_ESG_REPORT_PAGE = 'buyer/insight/GET_ESG_REPORT_PAGE'
export const GET_BULK_ESG_SUPPLIERS = 'buyer/insight/GET_BULK_ESG_SUPPLIERS'
export const CREATE_BULK_OUTREACH = 'buyer/insight/CREATE_BULK_OUTREACH'
export const GET_OUTREACH_LIST = 'buyer/insight/GET_OUTREACH_LIST'

export const LOAD_SUSTAINABILITY_SPEND_REPORT =
  'buyer/insight/LOAD_SUSTAINABILITY_SPEND_REPORT'
export const LOAD_REDUCED_SUSTAINABILITY_SPEND_REPORT =
  'buyer/insight/LOAD_REDUCED_SUSTAINABILITY_SPEND_REPORT'
export const GET_SUPPLIERS_BY_QUESTION =
  'buyer/insight/GET_SUPPLIERS_BY_QUESTION'
export const CLOSE_SUPPLIERS_BY_QUESTION_DIALOG =
  'buyer/insight/CLOSE_SUPPLIERS_BY_QUESTION_DIALOG'
export const SWITCH_TO_HISTORICAL_SPEND_DATA =
  'buyer/insight/SWITCH_TO_HISTORICAL_SPEND_DATA'
