import React, { FunctionComponent } from 'react'
import CompanyStat from '../../components/CompanyStat'
import InsightTitle from 'shared/components/InsightTitle'
import Link from 'shared/components/Link'
import paths from '../../../routes/paths'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'

type Props = {
  numberOfUsers: number
  numberOfConnectedSuppliers: number
  numberOfVets: number
  numberOfLogins: number
  numberOfExistingSuppliers: number
  numberOfPreferredSuppliers: number
  numberOfSearches: number
}

export const CompanyStats: FunctionComponent<Props> = props => {
  return (
    <>
      <InsightTitle>
        <FormattedMessage id='CompanyStats.Company' defaultMessage='Company' />
      </InsightTitle>

      <Grid container spacing={1}>
        {props.numberOfUsers > 0 && (
          <Grid item xs={6}>
            <CompanyStat
              metricLabel={
                <FormattedMessage
                  id='CompanyStats.UserLogins'
                  defaultMessage='User Logins'
                />
              }
              metricValue={props.numberOfUsers}
            />
          </Grid>
        )}

        {props.numberOfConnectedSuppliers > 0 && (
          <Grid item xs={6}>
            <Link to={`${paths.search}?scope=ColleagueConnections`}>
              <CompanyStat
                metricLabel={
                  <FormattedMessage
                    id='CompanyStats.FollowedSuppliers'
                    defaultMessage='Followed Suppliers'
                  />
                }
                metricValue={props.numberOfConnectedSuppliers}
              />
            </Link>
          </Grid>
        )}

        {props.numberOfVets > 0 && (
          <Grid item xs={6}>
            <Link to={paths.vets}>
              <CompanyStat
                metricLabel={
                  <FormattedMessage
                    id='CompanyStats.ActiveRFI'
                    defaultMessage='Active RFIs'
                  />
                }
                metricValue={props.numberOfVets}
              />
            </Link>
          </Grid>
        )}

        {props.numberOfLogins > 0 && (
          <Grid item xs={6}>
            <CompanyStat
              metricLabel={
                <FormattedMessage
                  id='CompanyStats.RecentLogins'
                  defaultMessage='Recent Logins'
                />
              }
              metricValue={props.numberOfLogins}
            />
          </Grid>
        )}

        {props.numberOfExistingSuppliers > 0 && (
          <Grid item xs={6}>
            <Link
              to={`${paths.search}?q=&filter%5Battributes%5D%5B0%5D=existing`}
            >
              <CompanyStat
                metricLabel={
                  <FormattedMessage
                    id='CompanyStats.ExistingSuppliers'
                    defaultMessage='Existing Suppliers'
                  />
                }
                metricValue={props.numberOfExistingSuppliers}
              />
            </Link>
          </Grid>
        )}

        {props.numberOfSearches > 0 && (
          <Grid item xs={6}>
            <CompanyStat
              metricLabel={
                <FormattedMessage
                  id='CompanyStats.SearchesLastMonth'
                  defaultMessage='Searches Last Month'
                />
              }
              metricValue={props.numberOfSearches}
            />
          </Grid>
        )}
        {props.numberOfPreferredSuppliers > 0 && (
          <Grid item xs={6}>
            <Link
              to={`${paths.search}?q=&filter%5Battributes%5D%5B0%5D=preferred`}
            >
              <CompanyStat
                metricLabel={
                  <FormattedMessage
                    id='CompanyStats.PreferredSuppliers'
                    defaultMessage='Preferred Suppliers'
                  />
                }
                metricValue={props.numberOfPreferredSuppliers}
              />
            </Link>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default CompanyStats
