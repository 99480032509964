import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
const apiRoute = `${apiRoutes.authService}/survey`

export const getSurveys = (supplierId: string) => {
  return api.get(`${apiRoute}/surveyResponse/profile/${supplierId}`)
}

export const getSurveyResponses = (surveyId: string) => {
  return api
    .get(`${apiRoute}/surveyResponseDetail/profile/${surveyId}`)
    .then((response: any[]) => {
      // process surveyResponseDetail
      // group by concat ids
      return response?.reduce((result, detail) => {
        const key = `${detail.pageId}-${detail.parentQuestionId || ''}-${detail.questionId
          }`
        result[key] = detail
        return result
      }, {})
    })
}

export const loadAllSurvey = () => {
  return api.get(`${apiRoute}/surveyResponse`).then((response: any) => {
    return response.reduce((byId, survey) => {
      byId[survey.id] = survey
      return byId
    }, {})
  })
}

export const saveSurvey = ({ surveyId, ...response }) => {
  return surveyId
    ? api.put(`${apiRoute}/surveyResponse/${surveyId}`, response)
    : api.post(`${apiRoute}/surveyResponse`, response)
}

export const editSurvey = surveyId => {
  return api
    .post(`${apiRoute}/edit/surveyResponse/${surveyId}`)
    .then(response => {
      return {
        response: response.newSurveyResponse,
        responseDetails: response.copiedSurveyResponseDetail.reduce(
          (byId, detail) => {
            byId[detail.id] = detail
            return byId
          },
          {}
        )
      }
    })
}

export const removeSurvey = (surveyId: string) => {
  return api.remove(`${apiRoute}/surveyResponse/${surveyId}`)
}

export const loadSurveyResponseDetails = (surveyId: string) => {
  return api
    .get(`${apiRoute}/surveyResponse/${surveyId}`)
    .then((response: any[]) => {
      return response.reduce((result, detail) => {
        result[detail.id] = detail
        return result
      }, {})
    })
}

export const saveSurveyResponse = ({
  surveyResponseDetailId,
  surveyId,
  ...response
}) => {
  return surveyResponseDetailId
    ? api.put(
      `${apiRoute}/surveyResponseDetail/${surveyResponseDetailId}`,
      response
    )
    : api.post(`${apiRoute}/surveyResponseDetail/${surveyId}`, response)
}
//adds pdf file to survey
export const uploadSurveyAttachment = ({ file, ...rest }) =>
  api
    .postFile(`${apiRoute}/surveyResponseDetail/attachment`, { file, ...rest })
    .then(response => {
      return {
        attachments: response,
        attachmentType: 'doc',
        ...rest
      }
    })

//removes cert and pdf file from survey
export const removeSurveyAttachment = ({
  attachmentType,
  surveyResponseDetailId,
  attachment
}) => {
  return api.remove(
    `${apiRoute}/surveyResponseDetail/attachment/${surveyResponseDetailId}`,
    { attachmentType, attachment }
  )
}

export const submitSurvey = ({ surveyId, ...rest }) => {
  return api.post(`${apiRoute}/surveyResponse/submit/${surveyId}`, { ...rest })
}

export const loadSustainabilityReportOverview = () => {
  return api
    .get(`${apiRoute}/surveySummary`)
    .then(({ completedSuppliers, ...rest }) => {
      const {
        SurveyViewed = 0,
        Created = 0,
        WaitingForClient = 0,
        InProgress = 0,
        Completed = 0
      } = rest.numInvitationsByStatus

      return {
        overview: {
          numExistingSuppliers: rest.numExistingSuppliers || 0,
          numCompletedSurveys: rest.numCompletedSurveys || 0,
          numInvitations:
            SurveyViewed + Created + WaitingForClient + InProgress + Completed,
          numSurveyViewedInvitations: SurveyViewed,
          numCompletedInvitations: Completed,
          numCreatedInvitations: Created + WaitingForClient,
          numInProgressInvitations: InProgress,
          numCerts: rest.numCerts || 0,
          numPositiveActions: rest.numPositiveActions || 0
        },
        completedSuppliers
      }
    })
}

export const getSustainabilityReportSummaryDetail = surveyPageID => {
  return api.get(`${apiRoute}/surveySummaryStats/${surveyPageID}`)
}

export const verifyAttachment = ({ surveyResponseDetailId, ...rest }) => {
  return api.put(
    `${apiRoute}/surveyResponseDetail/verifyAttachment/${surveyResponseDetailId}`,
    rest
  )
}

export const getESGSurveyRequestDetails = supplierID => {
  return api
    .get(
      `${apiRoutes.authService}/tasks/getESGSurveyRequestDetails/${supplierID}`
    )
    .then((response: any[]) => {
      return response.reduce((result, taskDetails) => {
        result[taskDetails.id] = taskDetails
        return result
      }, {})
    })
}

export const getIncompleteEsgExistingSuppliers = () =>
  api.get(`${apiRoute}/suppliersForBulkOutreach`)

export const requestCollaborator = ({ requestedContactId }) => {
  return api.post(`${apiRoute}/requestCollaborator`, {
    requestedContactId
  })
}

export const uploadSustainabilitySurveyCert = ({ ...restParams }) => {
  return api.postFile(`${apiRoute}/sustainabilityCertification`, {
    ...restParams
  })
}
export const deleteSustainabilitySurveyCert = () =>
  api.remove(`${apiRoute}/sustainabilityCertification`)

export const getEsgSuppliersByQuestion = ({ answer, questionId, pageId, isNot, reason }) => api.post(`${apiRoute}/esgSuppliersByQuestion`, { pageId, questionId, answer, isNot, reason })
