import React, { SyntheticEvent, useState } from 'react'
import Divider from 'shared/components/Divider'
import Button from 'shared/components/Button'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router'
import { getCurrentPosAndNextPathname } from './EsgNav'
import paths from '../../../routes/paths'
import { useSelector, useDispatch } from 'react-redux'
import surveySelectors from '../../selectors/surveySelectors'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip'
import { submitSurvey, removeSurvey } from '../../actions'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import Text from 'shared/components/Text'
import RootState from 'shared/models/RootState'
import { AccessControl } from 'shared/models/AccessControl'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { logout } from 'shared/actions'
import analytics from 'shared/utils/analytics'

type Props = {
  handleNextClick: (event: SyntheticEvent<HTMLElement>) => void
}

const EsgFooter = ({ handleNextClick }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const accessControl: AccessControl = useSelector(
    sessionSelectors.getAccessControl
  )
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const isEditing = useSelector((state: RootState) => {
    return state.getIn(['supplier', 'survey', 'editing'])
  })
  const healthSafetyCompleted = useSelector(
    surveySelectors.isHealthSafetyCompleted
  )
  const environmentCompleted = useSelector(
    surveySelectors.isEnvironmentCompleted
  )
  const diversityInclusionCompleted = useSelector(
    surveySelectors.isDiversityInclusionCompleted
  )
  const surveyStatistics = useSelector(surveySelectors.getEsgQuickFacts)
  const governanceCompleted = useSelector(surveySelectors.isGovernanceCompleted)
  const privacyCompleted = useSelector(surveySelectors.isPrivacyCompleted)
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false)
  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState<boolean>(false)
  const [isAttestationChecked, setIsAttestationChecked] =
    useState<boolean>(false)

  const readyForSubmit =
    healthSafetyCompleted &&
    environmentCompleted &&
    diversityInclusionCompleted &&
    governanceCompleted &&
    privacyCompleted

  const [currentPos] = getCurrentPosAndNextPathname(location.pathname)

  const handleExitCLick = () => {
    if (accessControl.access === 'full') {
      history.push(paths.company)
    } else {
      dispatch(logout())
    }
  }

  const handleSubmitSurveyConfirm = () => {
    setShowDisclaimer(false)
    dispatch(
      submitSurvey({
        surveyId: surveyId,
        totalPositiveResponses: surveyStatistics.positiveResponses.total,
        totalCertificatesUploaded: surveyStatistics.certificatesAdded.total,
        totalDocumentsUploaded: surveyStatistics.documentsAdded.total,
        supplierParticipantIds: surveyStatistics.supplierParticipantIds,
      })
    )
    analytics.track('Sustainability Survey Submitted', {
      orgUnitId,
      orgUnitName,
    })
    // don't push to company if esgSurvey restriction
    if (accessControl.access === 'full') {
      history.push(paths.company)
    }
  }

  const handleSubmitSurveyCancel = () => {
    setShowDisclaimer(false)
    setIsDisclaimerChecked(false)
    setIsAttestationChecked(false)
  }

  const handleCancelChangesClick = () => {
    dispatch(removeSurvey(surveyId))
  }

  const confirmEnabled = isDisclaimerChecked && isAttestationChecked

  return (
    <div className='pa3 mt3 mt3-5-ns mb6'>
      <div className='mw8 center'>
        <Divider className='mv3' />
        {!isEditing && (
          <div className='flex justify-between'>
            <Button size='large' variant='text' onClick={handleExitCLick}>
              <ArrowBackIosIcon fontSize='small' />{' '}
              <FormattedMessage
                id='EsgFooter.SaveExit'
                defaultMessage='Save & Exit survey'
              />
            </Button>
            {currentPos === 4 ? (
              <div className='flex items-center'>
                <Button
                  size='large'
                  disabled={!readyForSubmit}
                  onClick={() => setShowDisclaimer(true)}
                >
                  <FormattedMessage
                    id='EsgFooter.Submit'
                    defaultMessage='Submit Survey'
                  />
                </Button>
                {!readyForSubmit && (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id='EsgFooter.Info'
                        defaultMessage='Please complete all the questions on each category before submit.'
                      />
                    }
                  >
                    <InfoIcon className='ml2' />
                  </Tooltip>
                )}
              </div>
            ) : (
              <Button size='large' onClick={handleNextClick}>
                <FormattedMessage id='EsgFooter.Next' defaultMessage='Next' />
              </Button>
            )}
          </div>
        )}
        {isEditing && (
          <div className='flex justify-end'>
            <Button
              size='large'
              disabled={!readyForSubmit}
              onClick={handleCancelChangesClick}
              secondary
              className='mr3'
            >
              <FormattedMessage id='EsgFooter.Cancel' defaultMessage='Cancel' />
            </Button>
            <Button
              size='large'
              disabled={!readyForSubmit}
              onClick={() => setShowDisclaimer(true)}
            >
              <FormattedMessage
                id='EsgFooter.SaveChanges'
                defaultMessage='Save Changes'
              />
            </Button>
          </div>
        )}
      </div>
      <ConfirmationDialog
        open={showDisclaimer}
        onClose={handleSubmitSurveyCancel}
        disabled={!confirmEnabled}
        onConfirm={handleSubmitSurveyConfirm}
      >
        {isEditing && (
          <Text className='fw6'>
            <FormattedMessage
              id='EsgFooter.EditingWarning'
              defaultMessage='Please note the following changes will override your previous submission. Do you wish to proceed?'
            />
          </Text>
        )}
        <Text className={isEditing ? 'mt4' : ''}>
          <input
            type='checkbox'
            aria-label='Disclaimer Checkbox'
            className='mr2 v-mid'
            checked={isDisclaimerChecked}
            onChange={() => setIsDisclaimerChecked(() => !isDisclaimerChecked)}
          />
          <FormattedMessage
            id='EsgFooter.Disclaimer'
            defaultMessage='I confirm that all of the documents that I have uploaded are corporate documents and that they do not contain any personally identifiable information (ex. full name, email address, phone number etc.).'
          />
        </Text>
        <Text className='mt4'>
          <input
            type='checkbox'
            aria-label='Attestation Checkbox'
            className='mr2 v-mid'
            checked={isAttestationChecked}
            onChange={() =>
              setIsAttestationChecked(() => !isAttestationChecked)
            }
          />
          <FormattedMessage
            id='EsgFooter.Attestation'
            defaultMessage="I hereby certify that all information provided in this survey is true and complete to the best of my knowledge. The undersigned certifies that they are authorized to sign on behalf of the Company represented in this survey and that all the information provided is true and accurate. This information I am sharing should be treated as valid until an updated survey is submitted. It is the supplier's responsibility to notify TealBook if supplier's size, classification, ownership or other relevant information changes. *"
          />
        </Text>
      </ConfirmationDialog>
    </div>
  )
}

export default EsgFooter
