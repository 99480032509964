import { fork, all, takeLatest, takeEvery, put } from 'redux-saga/effects'
import takeFirst from './shared/utils/sagas/takeFirst'
import accountsRootSaga from './accounts/sagas'
import { authSaga, sendMessageSaga } from './shared/sagas'
import loadUsersSaga from './shared/sagas/loadUsersSaga'
import loadUserMetricsSaga from './shared/sagas/loadUserMetricsSaga'
import loadUser from './shared/sagas/loadUserSaga'
import supplierRootSaga from './supplier/rootSaga'
import buyerRootSaga from './buyer/rootSaga'
import jobsRootSaga from './jobs/rootSaga'
import dataPortalRootSaga from './data-portal/store/sagas'
import adminRootSaga from './admin/sagas'
import postingRootSaga from './posting/sagas/rootSaga'
import suggestOrgUnitSaga from './shared/sagas/suggestOrgUnitSaga'
import clearNotificationsSaga from './shared/sagas/clearNotificationsSaga'
import loadMessagesSaga from './shared/sagas/loadMessagesSaga'
import addMessageSaga from './shared/sagas/addMessageSaga'
import updateMessageSaga from './shared/sagas/updateMessageSaga'
import removeMessageSaga from './shared/sagas/removeMessageSaga'
import loadOrgSaga from './shared/sagas/loadOrgSaga'
import loadMessageTopicsSaga from './shared/sagas/loadMessageTopicsSaga'
import messageUserSaga from './shared/sagas/messageUserSaga'
import loadConversationWithUserSaga from './shared/sagas/loadConversationWithUserSaga'
import readMessageSaga from './shared/sagas/readMessageSaga'
import updateUserSaga from './shared/sagas/updateUserSaga'
import updateUserRolesSaga from './shared/sagas/updateUserRolesSaga'
import loadDataSaga from './shared/sagas/loadDataSaga'
import updateDataSaga from './shared/sagas/updateDataSaga'
import deleteDataSaga from './shared/sagas/deleteDataSaga'
import preloadSaga from './shared/sagas/preloadSaga'
import createApiRequestSaga from './shared/utils/sagas/createApiRequestSaga'
import preSearchSaga from './shared/sagas/preSearchSaga'
import loadClientFilesSaga from './shared/sagas/loadClientFilesSaga'
import loadOrgUnitClientFilesSaga from './shared/sagas/loadOrgUnitClientFilesSaga'
import uploadSpendSaga from './shared/sagas/uploadSpendSaga'
import publishSpendSagaT2 from './shared/sagas/publishSpendSagaT2'
import removeSpendSaga from './shared/sagas/removeSpendSaga'
import remapSupplierSaga from './shared/sagas/remapSupplierSaga'
import staging from 'shared/utils/api/staging'
import { SEND_CONTACT_US_MESSAGE, requestSuccess } from 'shared/actionTypes'
import { LOAD_COMPANY } from './supplier/shared/actionTypes'
import { GET_SETTINGS } from './buyer/shared/actionTypes'
import { LOGIN } from './accounts/actionTypes'
import * as sharedActionTypes from './shared/actionTypes'
import { loadMessageTopics, preload, loadAllUsers } from './shared/actions'
import { loadCollection } from './buyer/Search/actions'
import { LOAD_COLLECTION } from './buyer/Search/actionTypes'
import { GET_OUTREACH_LIST } from './buyer/Insights/store/actionTypes'
import loadCollectionSaga from './buyer/Search/sagas/loadCollectionSaga'
import { Action, ActionCreator } from 'redux'
import loadInternalStatsSaga from 'shared/sagas/loadInternalStatsSaga'
import insight from 'shared/utils/api/insight'
import tasks from 'shared/utils/api/tasks'
import uploadTier2SpendSaga from 'shared/sagas/uploadTierSpendSaga'

export default function* rootSaga() {
  yield all([
    // sagas started on login
    takeLatest(requestSuccess(LOGIN), triggerAction(loadAllUsers)),
    takeLatest(requestSuccess(LOGIN), triggerAction(loadMessageTopics)),
    takeLatest(requestSuccess(LOGIN), triggerAction(loadCollection)),
    takeLatest(requestSuccess(LOGIN), triggerAction(preload)),

    // pre-fetch search
    takeLatest(
      [requestSuccess(LOAD_COMPANY), requestSuccess(GET_SETTINGS)],
      preSearchSaga
    ),

    // other shared sagas
    takeLatest(sharedActionTypes.LOAD_USER_METRICS, loadUserMetricsSaga),
    takeLatest(sharedActionTypes.LOAD_USER, loadUser),
    takeLatest(sharedActionTypes.LOAD_ALL_USERS, loadUsersSaga),
    takeLatest(sharedActionTypes.UPDATE_USER, updateUserSaga),
    takeLatest(sharedActionTypes.UPDATE_USER_ROLES, updateUserRolesSaga),
    takeLatest(sharedActionTypes.NOTIFY, clearNotificationsSaga),
    takeLatest(sharedActionTypes.LOAD_ORG, loadOrgSaga),
    takeLatest(sharedActionTypes.LOAD_MESSAGE_TOPICS, loadMessageTopicsSaga),
    takeLatest(sharedActionTypes.SUGGEST_ORG_UNIT, suggestOrgUnitSaga),
    takeFirst(sharedActionTypes.MESSAGE_USER, messageUserSaga),
    takeEvery([sharedActionTypes.LOAD_DATA, GET_OUTREACH_LIST], loadDataSaga),
    takeFirst(sharedActionTypes.UPDATE_DATA, updateDataSaga),
    takeFirst(sharedActionTypes.DELETE_DATA, deleteDataSaga),
    takeFirst(LOAD_COLLECTION, loadCollectionSaga),
    takeFirst(sharedActionTypes.PRELOAD, preloadSaga),
    takeFirst(sharedActionTypes.LOAD_CLIENT_FILES, loadClientFilesSaga),
    takeFirst(
      sharedActionTypes.LOAD_ORGUNIT_CLIENT_FILES,
      loadOrgUnitClientFilesSaga
    ),
    takeFirst(
      sharedActionTypes.LOAD_STAGING_HISTORY,
      createApiRequestSaga(staging.getStagingHistory, {
        usePayload: true
      })
    ),
    takeFirst(sharedActionTypes.UPLOAD_SPEND_FILE, uploadSpendSaga),
    takeFirst(sharedActionTypes.UPLOAD_TIER2_SPEND_FILE, uploadTier2SpendSaga),
    takeFirst(sharedActionTypes.PUBLISH_SPEND_T2, publishSpendSagaT2),
    takeFirst(sharedActionTypes.REMOVE_SPEND, removeSpendSaga),
    takeFirst(sharedActionTypes.REMAP_SUPPLIER, remapSupplierSaga),

    fork(loadConversationWithUserSaga),
    takeEvery(sharedActionTypes.READ_MESSAGE, readMessageSaga),
    fork(authSaga),
    fork(accountsRootSaga),
    fork(supplierRootSaga),
    fork(dataPortalRootSaga),
    fork(buyerRootSaga),
    fork(jobsRootSaga),
    fork(adminRootSaga),
    fork(postingRootSaga),
    takeLatest(SEND_CONTACT_US_MESSAGE, sendMessageSaga),
    takeLatest(sharedActionTypes.LOAD_COMMENTS, loadMessagesSaga),
    takeEvery(sharedActionTypes.ADD_COMMENT, addMessageSaga),
    takeLatest(sharedActionTypes.UPDATE_COMMENT, updateMessageSaga),
    takeEvery(sharedActionTypes.REMOVE_COMMENT, removeMessageSaga),
    takeEvery(
      sharedActionTypes.CREATE_CONCIERGE_REQUEST,
      createApiRequestSaga(tasks.sendConciergeRequest, {
        usePayload: true
      })
    ),
    takeFirst(sharedActionTypes.LOAD_INTERNAL_STATS, loadInternalStatsSaga),
    takeFirst(
      sharedActionTypes.LOAD_INTERNAL_STAT_KEYS,
      createApiRequestSaga(insight.internalStatKeys, { usePayload: true })
    )
  ])
}

export function triggerAction(action: ActionCreator<Action>): any {
  return function* saga({ payload }) {
    yield put(action(payload))
  }
}
