import { fromJS, List } from 'immutable'
import { requestSuccess } from 'shared/actionTypes'
import * as actionTypes from '../../actionTypes'

const mergeArraysFilterDuplicates = <T>(array1: T[], array2: T[]) => [
  ...new Set([...array1, ...array2])
]

const initialState = fromJS({
  byId: {},
  byUserId: {},
  sortByDate: [],
  recentSuppliers: [],
  recentConnectedSuppliers: [],
  trendingTags: [],
  byTag: {},
  unread: true,
  isLoading: true,
  recentSupplierTags: {},
  supplierTagsAndCommentsForExport: {}
})

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case requestSuccess(actionTypes.CREATE_POST):
      const tags: Array<string> = action.payload.tags
      const byTag = {}
      if (tags) {
        tags.forEach(t => {
          byTag[t] = state.getIn(['byTag', t])
            ? state.getIn(['byTag', t]).unshift(fromJS(action.payload))
            : fromJS([action.payload])
        })
      }

      return state
        .setIn(['byId', action.payload.id], fromJS(action.payload))
        .updateIn(['sortByDate'], sortedPosts =>
          sortedPosts.unshift(action.payload.id)
        )
        .updateIn(['trendingTags'], trendingTags => {
          return !tags
            ? trendingTags
            : trendingTags.concat(
                List(tags.filter(t => !trendingTags.includes(t)))
              )
        })
        .mergeIn(['byTag'], byTag)

    case requestSuccess(actionTypes.LOAD_POSTS):
      if (action.payload.userId) {
        state = state.setIn(
          ['byUserId', action.payload.userId],
          fromJS(Object.keys(action.payload.byId))
        )
      } else if (action.payload.orgUnitId) {
        state = state.setIn(
          ['byOrgUnitId', action.payload.orgUnitId],
          fromJS(Object.keys(action.payload.byId))
        )
      }

      const newSortByDate = mergeArraysFilterDuplicates(
        state.get('sortByDate').toJS() as string[],
        action.payload.sortByDate
      )
      const newTrendingTags = mergeArraysFilterDuplicates(
        state.get('trendingTags').toJS() as string[],
        action.payload.trendingTags
      )

      state = state.set('sortByDate', fromJS(newSortByDate))
      state = state.set('trendingTags', fromJS(newTrendingTags))
      state = state.set('isLoading', false)

      state = state.mergeIn(['byId'], fromJS(action.payload.byId))

      return state

    case requestSuccess(actionTypes.GET_RECENT_SUPPLIERS):
      const { recent = [], recentConnected = [] } = action.payload
      return state.merge({
        recentSuppliers: fromJS(recent),
        recentConnectedSuppliers: fromJS(recentConnected)
      })

    case requestSuccess(actionTypes.UP_VOTE):
      return state
        .updateIn(['byId', action.payload.postId, 'upVotes'], upVotes =>
          upVotes
            ? upVotes.push(action.payload.userId)
            : fromJS([action.payload.userId])
        )
        .updateIn(['byId', action.payload.postId, 'markRead'], markRead => {
          return action.payload.markRead
            ? markRead.push(fromJS({ user: action.payload.userId }))
            : markRead
        })
        .updateIn(
          ['byId', action.payload.postId, 'upVotesCount'],
          upVotesCount => (isNaN(upVotesCount) ? 1 : upVotesCount + 1)
        )
        .updateIn(
          ['byId', action.payload.postId, 'markReadCount'],
          markReadCount => {
            return action.payload.markRead
              ? isNaN(markReadCount)
                ? 1
                : markReadCount + 1
              : markReadCount
          }
        )

    case requestSuccess(actionTypes.CLEAR_VOTE):
      let index
      return state
        .updateIn(['byId', action.payload.postId, 'upVotes'], upVotes => {
          index = upVotes.indexOf(action.payload.userId)
          return index !== -1 ? upVotes.delete(index) : upVotes
        })
        .updateIn(
          ['byId', action.payload.postId, 'upVotesCount'],
          upVotesCount =>
            index !== -1
              ? isNaN(upVotesCount)
                ? 0
                : upVotesCount - 1
              : upVotesCount
        )

    case requestSuccess(actionTypes.DELETE_POST):
      return state
        .deleteIn(['byId', action.payload])
        .update('sortByDate', list => {
          const index = list.indexOf(action.payload)
          return list.delete(index)
        })

    case requestSuccess(actionTypes.ADD_POST_COMMENT):
      return state.updateIn(
        ['byId', action.payload.postId, 'comments'],
        comments => {
          return comments
            ? comments.push(fromJS(action.payload.comment))
            : fromJS([action.payload.comment])
        }
      )

    case requestSuccess(actionTypes.DELETE_POST_COMMENT):
      return state.updateIn(
        ['byId', action.payload.postId, 'comments'],
        comments => {
          return comments.delete(action.payload.index)
        }
      )

    case requestSuccess(actionTypes.MARK_POST_READ):
      return state
        .updateIn(['byId', action.payload.postId, 'markRead'], markRead => {
          return markRead.push(fromJS({ user: action.payload.user }))
        })
        .updateIn(
          ['byId', action.payload.postId, 'markReadCount'],
          markReadCount => (isNaN(markReadCount) ? 1 : markReadCount + 1)
        )

    case actionTypes.CLEAR_UNREAD:
      return state.set('unread', false)

    case requestSuccess(actionTypes.LOAD_POST_BY_TAG):
      return state
        .mergeIn(['byTag'], fromJS(action.payload.byTag))
        .mergeIn(['byId'], fromJS(action.payload.byId))

    case requestSuccess(actionTypes.GET_RECENT_SUPPLIERS_TAGS):
      return state.set('recentSupplierTags', fromJS(action.payload))

    case requestSuccess(actionTypes.GET_SUPPLIER_COMMENTS_AND_TAGS_FOR_EXPORT):
      return state.set(
        'supplierTagsAndCommentsForExport',
        fromJS(action.payload)
      )

    default:
      return state
  }
}

export default postsReducer
