import React from 'react'
import { connect } from 'react-redux'
import AdminTabs from './components/AdminTabs'
import AdminAppBar from './containers/AdminAppBar'
import settingsSelectors from '../buyer/shared/selectors/settingsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import RootState from 'shared/models/RootState'
import { Route, Switch, Redirect } from 'react-router'
import paths from './routes/paths'
import UserListContainer from './containers/Users/UserListContainer'
import ChangeHistoryContainer from './containers/ChangeHistoryContainer'
import SalesConfigContainer from './containers/SalesConfigContainer'
import TechConfig from './components/TechConfig'
import AppConfig from './components/AppConfig'
import Loading from 'shared/components/Loading'
import LoaderContainer from './containers/LoaderContainer'
import SapLoader from './components/SapLoader'
import FileUploadTab from './components/FileUploadTab'

type Props = {
  isBuyer: boolean
  isBuyerDataAdmin: boolean
  isTealbot: boolean
  isIntegrationPartner: boolean
  isPartnerConcierge: boolean
  isBuyerAdmin: boolean
  isSapIntegrationEnabled: boolean
  hasLoadedBuyerSettings: boolean
}

export const App = ({
  isBuyer,
  isBuyerDataAdmin,
  isTealbot,
  isIntegrationPartner,
  isPartnerConcierge,
  isBuyerAdmin,
  isSapIntegrationEnabled,
  hasLoadedBuyerSettings
}: Props) => {
  const availablePages = {
    [paths.appConfig]: isBuyer && (isBuyerAdmin || isBuyerDataAdmin),
    [paths.salesConfig]: isBuyer && isTealbot,
    [paths.techConfig]: isBuyer && (isTealbot || isIntegrationPartner),
    [paths.loader]: isBuyer && isTealbot && !isPartnerConcierge,
    [paths.fileUpload]: isBuyerDataAdmin && !isPartnerConcierge,
    [paths.changeHistory]: isBuyerDataAdmin && !isPartnerConcierge,
    [paths.sapLoader]: isSapIntegrationEnabled && (isBuyerAdmin || isTealbot)
  }

  if (isBuyer && !hasLoadedBuyerSettings) {
    return <Loading />
  }

  return (
    <>
      <AdminAppBar />
      <AdminTabs availableTabs={availablePages} />

      <div className='ph4-ns ph3 mw8 center'>
        <Switch>
          <Route path={paths.users} component={UserListContainer} />
          {availablePages[paths.techConfig] && (
            <Route path={paths.techConfig}>
              <TechConfig isTealbot={isTealbot} />
            </Route>
          )}
          {availablePages[paths.salesConfig] && (
            <Route path={paths.salesConfig} component={SalesConfigContainer} />
          )}
          {availablePages[paths.appConfig] && (
            <Route path={paths.appConfig}>
              <AppConfig isBuyerAdmin={isBuyerAdmin} isTealbot={isTealbot} />
            </Route>
          )}
          {availablePages[paths.loader] && (
            <Route path={paths.loader} component={LoaderContainer} />
          )}
          {availablePages[paths.fileUpload] && (
            <Route path={paths.fileUpload}>
              <FileUploadTab />
            </Route>
          )}
          {availablePages[paths.changeHistory] && (
            <Route
              path={paths.changeHistory}
              component={ChangeHistoryContainer}
            />
          )}
          {availablePages[paths.sapLoader] && (
            <Route path={paths.sapLoader} component={SapLoader} />
          )}
          <Redirect from={paths.basePath} to={paths.users} />
        </Switch>
      </div>
    </>
  )
}

export default connect((state: RootState) => ({
  isBuyer: sessionSelectors.userHasRole(state, 'buyer'),
  isBuyerDataAdmin: sessionSelectors.userHasRole(state, 'buyerDataAdmin'),
  isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
  isIntegrationPartner: sessionSelectors.userHasRole(
    state,
    'integrationPartner'
  ),
  isPartnerConcierge: state.get('session').get('partnerConcierge'),
  isBuyerAdmin: sessionSelectors.userHasRole(state, 'buyerAdmin'),
  hasLoadedBuyerSettings: !!settingsSelectors.getBuyerType(state),
  isSapIntegrationEnabled: !!settingsSelectors.getField(
    state,
    'sapIntegrationEnabled'
  )
}))(App)
