import React from 'react'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import HowToInstruction from './HowToInstruction'
import DisclosureAgreement from './DisclosureAgreement'

const TealbotHelp = () => {
  return (
    <>
      <Label>
        <FormattedMessage
          id='TealbotHelp.Tier2Help2'
          defaultMessage='To generate your diversity report:'
        />
      </Label>
      <HowToInstruction />
      <Label>
        <FormattedMessage
          id='TealbotHelp.Tier2HelpNDALabelUpdated'
          defaultMessage='Privacy Policy and Non-Disclosure Agreement: '
        />
      </Label>
      <DisclosureAgreement />
    </>
  )
}

export default TealbotHelp
