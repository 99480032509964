import React from 'react'
import { useIntl, defineMessages } from 'react-intl'

export const messages = defineMessages({
  Cancelled: {
    id: 'CloseStatusByCell.EcoVadisAssessmentCancelled',
    defaultMessage: 'Cancelled'
  },
  Accepted: {
    id: 'CloseStatusByCell.Accepted',
    defaultMessage: 'Accepted'
  },
  InProgress: {
    id: 'CloseStatusByCell.InProgress',
    defaultMessage: 'In Progress'
  },
  Expired: {
    id: 'CloseStatusByCell.Expired',
    defaultMessage: 'Expired'
  },
  Completed: {
    id: 'CloseStatusByCell.Completed',
    defaultMessage: 'Completed'
  },
  'Results Available': {
    id: 'CloseStatusByCell.ResultsAvailable',
    defaultMessage: 'Results Available'
  }
})

const CloseStatusByCell = ({ outreachStatus }) => {
  const intl = useIntl()
  const requestStatus = outreachStatus
  let colorCss
  if (requestStatus === 'InProgress') {
    colorCss = 'bg-dark-red white'
  } else if (
    requestStatus === 'Results Available' ||
    requestStatus === 'Completed'
  ) {
    colorCss = 'bg-teal white'
  } else if (requestStatus === 'Accepted') {
    colorCss = 'bg-dark-green white'
  } else if (requestStatus === 'Cancelled') {
    colorCss = 'bg-light-gray'
  } else if (requestStatus === 'Expired') {
    colorCss = 'bg-red white'
  } else {
    colorCss = 'bg-dark-gray white'
  }

  return (
    <div className={`dib br2 pv2 ph2 tc br-pill ${colorCss}`}>
      {messages[requestStatus]
        ? intl.formatMessage(messages[requestStatus])
        : requestStatus}
    </div>
  )
}

export default CloseStatusByCell
