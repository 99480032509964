import React, {
  ChangeEvent,
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from 'react'
import { connect } from 'react-redux'
import debounce from 'debounce'
import qs from 'qs'
import {
  searchSupplier,
  setFilterOptions,
  updateScrollPosition,
  clearCollection
} from '../../actions'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import searchSelectors from '../../selectors/searchSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import SearchResultsContainer from '../SearchResultsContainer'
import SearchFiltersContainer from '../SearchFiltersContainer'
import BuyerNavSearch from '../../../shared/containers/BuyerNavSearch'
import FiltersSummaryContainer from '../FiltersSummaryContainer'
import SearchCounter from '../../components/SearchCounter'
import CollectionContainer from '../CollectionContainer'
import SearchSortContainer from '../SearchSortContainer'
import SuggestionContainer from '../SuggestionContainer'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import InviteSupplier from '../../components/InviteSupplier'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import { RecordOf, Map, List } from 'immutable'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import suppliers from 'shared/utils/api/suppliers'
import { AccessControl } from 'shared/models/AccessControl'
import mergeFilters from 'shared/utils/mergeFilters'
import { useLocation } from 'react-router'
import useUserAccess from 'shared/utils/useUserAccess'
import PunchoutInitialCart from '../PunchoutInitialCart'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'
import useSmallScreen from 'shared/utils/useSmallScreen'
import Feedback from '../../components/Feedback'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import job from 'shared/utils/api/job'
import { notify } from 'shared/actions'
import ExportSuppliersButton from '../../components/ExportSuppliersButton'
import SowJobButton from '../../components/SowJobButton'
import TabFilter from '../../components/TabFilter'

type SearchQueryParamFilter = {
  scope?: 'All'
  sort?: string
  similarSuppliers?: string[]
  attachmentsExpiredExcluded?: boolean
  includeUnQualified: string[]
}
type SearchQueryStringParameters = {
  q: string
  filter: SearchQueryParamFilter
  scope: 'All'
  sort: string
  includeUnQualified: string[]
  attachmentsExpiredExcluded: 'true' | 'false'
  similarSuppliers: string[]
}
type ResultItemSupplier = RecordOf<{
  supplier: {
    name: string
    domains: List<string>
    primaryAddress: string
  }
  supplierUsers: List<
    RecordOf<{
      firstName: string
      lastName: string
      email: string
      primary?: boolean
    }>
  >
}>

type Props = {
  vetId?: string
  refKey: string
  resultsKey: string
  disableSuggest?: boolean
  topSideContent?: ReactElement<HTMLElement>
  isSearching: boolean
  searchId: string
  canExportForJob: boolean
  canVerify: boolean
  accessControl: AccessControl
  fixedFilters: Map<string, any>
  defaultRelevance: string
  resultsCount: number
  updateScrollPosition: (scrollTop: number) => void
  searchSupplier: typeof searchSupplier
  setFilterOptions: (filters) => void
  clearCollection: typeof clearCollection
  results: List<ResultItemSupplier>
  children?: ReactNode
  lastQueryString: string
  notify: ({ message }: { message: string }) => void
  showTabs?: boolean
  licenseType: 'basic' | 'business' | 'professional' | 'advanced' | 'elite'
}

export const SearchContainer = (props: Props) => {
  const location = useLocation()
  const debounceScrollPosition = debounce(props.updateScrollPosition, 500)
  const locationSearch = location.search
  const locationPathname = location.pathname
  const isSupplierApp = locationPathname.startsWith('/supplier')
  const userAccess = useUserAccess() as LicenseAccess
  const isMobile = useSmallScreen()
  const [jobDialog, setJobDialog] = useState<boolean>(false)
  const [jobTitle, setJobTitle] = useState<string>('')
  const [jobInstruction, setJobInstruction] = useState<string>('')

  const handleScroll = e => {
    debounceScrollPosition(
      document.documentElement.scrollTop || document.body.scrollTop
    )
  }
  const {
    vetId,
    refKey,
    resultsKey,
    searchSupplier,
    setFilterOptions,
    resultsCount,
    defaultRelevance,
    fixedFilters,
    canExportForJob,
    lastQueryString,
    showTabs,
    canVerify
  } = props

  useEffect(() => {
    if (resultsKey !== refKey || lastQueryString !== locationSearch) {
      let {
        q = '',
        filter = {} as SearchQueryParamFilter,
        scope = 'All',
        sort = defaultRelevance || 'Relevance',
        includeUnQualified,
        attachmentsExpiredExcluded,
        similarSuppliers
      } = qs.parse(locationSearch, {
        ignoreQueryPrefix: true,
        arrayLimit: 100
      }) as SearchQueryStringParameters

      // enforce the fixed filters
      filter = mergeFilters(filter, fixedFilters.toJS())
      filter.scope = scope
      filter.sort = sort
      filter.similarSuppliers = similarSuppliers
      filter.includeUnQualified = includeUnQualified

      if (attachmentsExpiredExcluded === 'true') {
        filter.attachmentsExpiredExcluded = true
      }

      setFilterOptions(filter)
      searchSupplier(q, { refKey, queryString: locationSearch })
    }
  }, [
    locationSearch,
    lastQueryString,
    defaultRelevance,
    fixedFilters,
    searchSupplier,
    setFilterOptions,
    refKey,
    resultsKey
  ])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const transformExportJob: (
    title: string,
    instruction: string,
    results: any[]
  ) => any = (title, instruction, results) => {
    const rows = results.map((item: { [key: string]: any }, index) => {
      const supplier = item?.supplier
      const row: any = {
        name: supplier?.name,
        logoUrl: supplier?.logoUrl,
        domains: supplier?.domains,
        description: supplier?.description?.en,
        // address: supplier?.primaryAddress,
        // phoneNumber: supplier?.phoneNumber,
        orgUnitId: supplier?.id,
        // status: supplier?.status,
        // websiteWarning: supplier?.websiteWarning,
        // offerings: supplier?.tags,
        classificationCodes: supplier?.classificationCodes,
        // certifications: supplier?.certifications,
        links: {
          websiteUrl: supplier?.websiteUrl,
          facebook: supplier?.websiteUrl,
          twitter: supplier?.twitter
        }
      }
      return row
    })
    return {
      name: title || 'Enter a job title',
      instructions: instruction || 'Provide instruction to the job',
      rows,
      questions: [
        {
          questionId: 'question1',
          answerType: 'NAICS'
        }
      ]
    }
  }

  const exportJobs: () => void = () => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })
    suppliers
      .searchSuppliers(Object.assign({}, params, { limit: 500 }))
      .then(({ hits }) => {
        const jobSize = 30
        if (hits && hits.length > 0) {
          let batch = 0
          let start = 0
          do {
            batch++
            const body = transformExportJob(
              `${jobTitle} ${batch}`,
              jobInstruction,
              hits.slice(start, batch * jobSize)
            )
            job.loadJob({ body })
            start = batch * jobSize
          } while (hits.length > start)
          props.notify({ message: `${batch} jobs created` })
        }
        setJobDialog(false)
        setJobTitle('')
        setJobInstruction('')
      })
  }

  const renderAside = (side: 'left' | 'right' = 'right') => {
    const {
      vetId,
      topSideContent,
      resultsCount,
      isSearching,
      accessControl,
      children,
      searchId,
      licenseType
    } = props

    return (
      <Aside className='mb5 nt4' left={side === 'left'}>
        {topSideContent}
        {!isSupplierApp && <CollectionContainer vetId={vetId} />}
        {accessControl.access === 'punchout' && <PunchoutInitialCart />}

        {(accessControl.access === 'punchout' ||
          userAccess.accessDiscoveryFilters) && (
          <SearchFiltersContainer
            hideCorporateRelationship={licenseType === 'basic'}
            hideQualification={licenseType === 'basic'}
            hideAttachment={licenseType === 'basic'}
            hidePersonalRelationship={licenseType === 'basic'}
            hideInternalCategory={licenseType === 'basic'}
            hideSustainabilitySurvey={licenseType === 'basic'}
            hideFollowing={
              isSupplierApp ||
              accessControl.access === 'punchout' ||
              licenseType === 'basic'
            }
            hideRating={
              isSupplierApp ||
              accessControl.access === 'punchout' ||
              licenseType === 'basic'
            }
            hideCommunity={
              isSupplierApp ||
              accessControl.access === 'punchout' ||
              licenseType === 'basic'
            }
            hideSpend={
              isSupplierApp ||
              accessControl.access === 'punchout' ||
              licenseType === 'basic'
            }
          />
        )}
        {!isSupplierApp &&
          (resultsCount !== 0 || isSearching) &&
          accessControl.access === 'full' && <InviteSupplier />}
        {children}
        {!isMobile && searchId && <Feedback open />}
      </Aside>
    )
  }

  const renderSort = () => (
    <div>
      <div
        className={`dib flex items-center justify-end ${isMobile ? 'mb2' : ''}`}
      >
        {canVerify && <SowJobButton />}
        <SearchSortContainer
          limitedSorts={isSupplierApp || accessControl.access === 'punchout'}
        />
        {!isSupplierApp &&
          accessControl.access === 'full' &&
          (userAccess.accessExportBasic ||
            userAccess.accessExportAdvanced ||
            userAccess.accessExportAll) && (
            <ExportSuppliersButton disabled={!resultsCount} />
          )}
        {canExportForJob && (
          <Button
            autoSize
            size='small'
            onClick={() => setJobDialog(true)}
            className='ml2'
            secondary
            disabled={!resultsCount}
          >
            <FormattedMessage
              id='SearchContainer.ForJob'
              defaultMessage='Job'
            />
          </Button>
        )}
      </div>
    </div>
  )

  const { disableSuggest, isSearching, accessControl } = props
  const notFound = resultsCount === 0 && !isSearching
  const { q } = qs.parse(locationSearch, {
    ignoreQueryPrefix: true,
    arrayLimit: 100
  })
  const filterOnLeft = accessControl.access === 'punchout'
  return (
    <div className='cf'>
      <div
        className={`dt w-100 mb3 mt3-ns mt0 ${
          vetId ? 'absolute' : 'fixed'
        } static-ns left-0 pa0-ns pa3 bb b--moon-gray bg-tb-gray bn-ns z-2`}
        role='search'
        aria-label='search and suggestions'
      >
        <BuyerNavSearch
          disableSuggest={disableSuggest}
          defaultPathOverride
          showButton
        />
        {!notFound && <SuggestionContainer />}
      </div>
      <div className={`mt6 mt0-ns`}>
        <div
          className='pt2-ns w-100 mt3'
          role='search'
          aria-label='search counter'
        >
          <SearchCounter
            q={q}
            resultsCount={resultsCount}
            isSearching={isSearching}
          />
        </div>
        <div
          className={`pv2 w-100 flex-ns items-center ${
            filterOnLeft ? 'justify-end mb2 bb b--black-10' : ''
          }`}
        >
          <div className={`w-100 fl-l`}>
            <div className={`dib v-btm w-100 mb2 mb0-l`}>
              <FiltersSummaryContainer />
            </div>
          </div>
        </div>
        {!notFound && isMobile && renderSort()}
        <div
          className={
            isMobile
              ? 'cf overflow-x-auto'
              : showTabs
              ? 'flex justify-between'
              : 'flex justify-end mb2'
          }
          role='main'
          aria-label='Tab Filter and Sort'
        >
          {!!showTabs && <TabFilter />}
          {!notFound && !isMobile && renderSort()}
        </div>
        <div
          className={!!showTabs ? 'cf pa3 ba b--black-10' : ''}
          style={{ marginTop: !!showTabs ? -1 : 0 }}
        >
          {filterOnLeft && renderAside('left')}
          <Main aria-label='supplier search results'>
            {notFound ? (
              <Fragment>
                <Label>
                  <FormattedMessage
                    id='SearchContainer.Suggestions'
                    defaultMessage='Suggestions:'
                  />
                </Label>
                <ul className='f7 fw3 mid-gray lh-copy'>
                  <li>
                    <FormattedMessage
                      id='SearchContainer.Suggestion1'
                      defaultMessage='Make sure that all words are spelled correctly.'
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id='SearchContainer.Suggestion2'
                      defaultMessage='If this was a supplier name, invite the supplier to join TealBook.'
                    />
                  </li>
                </ul>
                {accessControl.access === 'full' && (
                  <InviteSupplier buttonOnly />
                )}
              </Fragment>
            ) : (
              <SearchResultsContainer vetId={vetId} />
            )}
          </Main>
          {!filterOnLeft && renderAside()}
        </div>
      </div>
      <Dialog open={jobDialog} onClose={() => setJobDialog(false)} fullWidth>
        <DialogTitle>
          <FormattedMessage
            id='SearchContainer.JobDialogCaption'
            defaultMessage='Generate Jobs'
          />
        </DialogTitle>
        <DialogContent>
          <Label>
            <FormattedMessage
              id='SearchContainer.JobTitle'
              defaultMessage='Job Title'
            />
          </Label>
          <Input
            value={jobTitle}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setJobTitle(e.currentTarget.value)
            }
          />
          <Label>
            <FormattedMessage
              id='SearchContainer.JobInstruction'
              defaultMessage='Job Instruction'
            />
          </Label>
          <TextArea
            value={jobInstruction}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setJobInstruction(e.currentTarget.value)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!jobTitle || !jobInstruction} onClick={exportJobs}>
            <FormattedMessage id='Generate' defaultMessage='Generate' />
          </Button>
          <Button className='ml2' secondary onClick={() => setJobDialog(false)}>
            <FormattedMessage id='Cancel' defaultMessage='Cancel' />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

type ContainerProps = {
  refKey: string
}

export default connect(
  (state: RootState, { refKey }: ContainerProps) => {
    const isSearching: boolean = searchSelectors.isSearching(state)
    const defaultRelevance: string = settingsSelectors.getDefaultRelevance(
      state
    )
    const accessControl: AccessControl = sessionSelectors.getAccessControl(
      state
    )
    const resultsCount: number = searchSelectors.getResultsCount(state)
    const fixedFilters: Map<string, any> = settingsSelectors.getFixedFilters(
      state
    )
    const results: List<ResultItemSupplier> = searchSelectors.getResults(state)
    const resultsKey: string = state.getIn(['buyer', 'search', 'resultsKey'])
    const currentUser = sessionSelectors.getUser(state)
    const licenseType = currentUser.get('licenseType')
    const canExportForJob: boolean =
      sessionSelectors.userHasRole(state, 'clickWorker') &&
      (currentUser.get('email') === 'ian@tealbook.com' ||
        currentUser.get('email') === 'william@tealbook.com')
    const canVerify: boolean = sessionSelectors.userHasRole(state, 'hil')
    const searchId = searchSelectors.getSearchId(state)

    const availableTabs = state.getIn(['buyer', 'tabFilter', 'availableTabs'])
    const showTabs = !!searchSelectors.getQ(state) && availableTabs.size > 0

    return {
      isSearching,
      searchId,
      licenseType,
      accessControl,
      canExportForJob,
      canVerify,
      defaultRelevance,
      fixedFilters,
      resultsKey,
      resultsCount,
      results,
      lastQueryString: searchSelectors.getQueryStringByRef(state, refKey) || '',
      showTabs
    }
  },
  {
    searchSupplier,
    setFilterOptions,
    updateScrollPosition,
    clearCollection,
    notify
  }
)(SearchContainer)
