import React, { FC, useState, FormEvent, useEffect } from 'react'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import FileInput from 'shared/components/FileInput'
import { FormattedMessage, useIntl } from 'react-intl'
import Text from 'shared/components/Text'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from 'shared/components/Paper'
import { messages as businessContextMessages } from './FileUploadStep2'
import { BusinessContextAnswersType } from './FileUploadTab'
import { useHistory } from 'react-router'
import { defineMessages } from 'react-intl'
import { notify } from 'shared/actions'
import paths from 'admin/routes/paths'
import { useDispatch } from 'react-redux'

export const messages3 = defineMessages({
  missingResponse: {
    id: 'FileUploadStep3.missingResponse',
    defaultMessage: 'Responses are missing'
  }
})

type Props = {
  isBuyDataAdmin: boolean
  isUploading: boolean
  onUploadClientFile: ({ file, questionnaireResponses, history }) => void
  businessContextAnswers: BusinessContextAnswersType
}

const FileUploadStep3: FC<Props> = ({
  isBuyDataAdmin,
  isUploading,
  onUploadClientFile,
  businessContextAnswers
}) => {
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()

  const [file, setFile] = useState<File | undefined>(undefined)

  useEffect(() => {
    if (!businessContextAnswers?.modified) {
      dispatch(
        notify({
          message: intl.formatMessage(messages3.missingResponse)
        })
      )
      history.push(paths.fileUpload)
    }
  }, [businessContextAnswers, dispatch, history, intl])

  const handleFileChange = (file: File) => {
    setFile(file)
  }
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (file) {
      const questionnaireResponses = generateQuestionnaireResponses()
      onUploadClientFile({
        file,
        questionnaireResponses,
        history
      })
    }
    setFile(undefined)
  }

  const generateQuestionnaireResponses = () => {
    const userResponse: string[] = []
    const delimeter = ': '

    userResponse.push(
      businessContextMessages.deleteSpendData.defaultMessage +
        delimeter +
        businessContextAnswers.deleteSpendData
    )
    userResponse.push(
      businessContextMessages.dateRangeForDeletion.defaultMessage +
        delimeter +
        businessContextAnswers.dateRangeForDeletion
    )
    return userResponse
  }

  return isBuyDataAdmin ? (
    <Paper>
      <div className='mt3'>
        <Label>
          <FormattedMessage
            id='FileUploadStep3.FileLabel'
            defaultMessage='Please upload your file'
          />
        </Label>
      </div>
      <form onSubmit={handleSubmit}>
        {!isUploading ? (
          <>
            <FileInput
              accept='.csv, .xlsx, .xls'
              limit={1000}
              onChange={handleFileChange}
              aria-label='file-input'
            />
            <Button
              label={
                <FormattedMessage
                  id='FileUploadStep3.UploadFile'
                  defaultMessage='Upload File'
                />
              }
              type='submit'
              autoSize
              className='mt3'
              disabled={file === undefined || file === null}
            />
          </>
        ) : (
          <>
            <LinearProgress />
            <Text>
              <FormattedMessage
                id='FileUploadStep3.InProgress'
                defaultMessage='Upload in progress...'
              />
            </Text>
          </>
        )}
      </form>
    </Paper>
  ) : null
}

export default FileUploadStep3
