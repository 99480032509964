import React, { useEffect } from 'react'
import FilesHistory from '../../components/FilesHistory'
import { connect } from 'react-redux'
import { loadOrgUnitClientFiles } from '../../../shared/actions'
import dataSelectors from '../../../shared/selectors/dataSelectors'
import RootState from 'shared/models/RootState'
import Main from 'shared/components/Layout/Main'
import { List, Map } from 'immutable'
import Loading from 'shared/components/Loading'
import Page from 'shared/components/Page/Page'
import { FormattedMessage } from 'react-intl'
import CallToAction from 'supplier/Insight/components/DiversityReport/CallToAction'
import { useHistory } from 'react-router'
import adminPaths from 'admin/routes/paths'

type Props = {
  orgUnitClientFiles: List<Map<string, string>>
  loadOrgUnitClientFiles: typeof loadOrgUnitClientFiles
}

const title = <FormattedMessage id='App.Upload' defaultMessage='Upload' />

const FileUploadMainPage = ({
  orgUnitClientFiles,
  loadOrgUnitClientFiles
}: Props) => {
  useEffect(() => {
    loadOrgUnitClientFiles({
      entity: 'orgUnitClientFiles',
      query: 'loader/orgUnitClientFiles'
    })
  }, [loadOrgUnitClientFiles])

  const history = useHistory()

  return (
    <Page title={title}>
      <div className='mt4'>
        <Main>
          <h2 className='f5 f3-l fw4 mv3'>
            <FormattedMessage
              id='FileUpload.Upload'
              defaultMessage='Upload your file'
            />
          </h2>
          <CallToAction
            onHandleButtonClick={() => history.push(adminPaths.fileUploadStep1)}
          >
            <FormattedMessage
              id='CallToAction.UploadFile'
              defaultMessage={'Upload a file for TealBook'}
            />
          </CallToAction>
          {orgUnitClientFiles ? (
            <FilesHistory orgUnitClientFiles={orgUnitClientFiles} />
          ) : (
            <Loading />
          )}
        </Main>
      </div>
    </Page>
  )
}
export default connect(
  (state: RootState) => {
    return {
      orgUnitClientFiles: dataSelectors.getOrgUnitClientFiles(state)
    }
  },
  {
    loadOrgUnitClientFiles
  }
)(FileUploadMainPage)
