import React from 'react'

import numberFormat from 'shared/utils/numberFormat'

import { DirectSpendLabel } from './DirectSpentLabel'

type Props = {
  spend: number
}

const DirectSpendAggregateLine = ({ spend }: Props) => {
  return (
    <div className='flex items-center justify-between'>
      <DirectSpendLabel />
      <div className='flex items-end'>
        <span className='w-30 tr'>${numberFormat(spend, 2)}</span>
      </div>
    </div>
  )
}

export default DirectSpendAggregateLine
