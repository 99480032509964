import React, { Component } from 'react'
import paths from '../../../routes/paths'
import Link from 'shared/components/Link'
import { NavLink } from 'react-router-dom'
import SupplierAttributes from '../SupplierAttributes'
import Tooltip from 'shared/components/Tooltip'
import Popover from '@material-ui/core/Popover'
import Divider from 'shared/components/Divider'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import FaceIcon from '@material-ui/icons/Face'
import WebsiteWarning, {
  WebsiteWarningType
} from 'shared/components/WebsiteWarning'
import { List, RecordOf, Map } from 'immutable'
import Certification from 'shared/models/Certification'
import BlockIcon from '@material-ui/icons/Block'
import IconButton from 'shared/components/IconButton'

type Props = {
  disableActions?: boolean
  isPreferred?: boolean
  isDoNotUse?: boolean
  isRestricted?: boolean
  isExisting?: boolean
  isNonRecruit?: boolean
  isPrivate?: boolean
  isSanctionedCompany?: boolean
  isSanctionedCountry?: boolean
  sanctionedCountryList?: List<string>
  attachments?: List<
    RecordOf<{
      createdBy?: string
      createdDate?: string
      documentFileUrl?: string
      expirationDate?: string
      type?: string
    }>
  >
  websiteWarning?: WebsiteWarningType
  diversityProgram?: boolean
  profileUrl?: string
  myCompanyUrl?: string
  name?: string
  websiteUrl?: string
  certifications?: List<[string, List<RecordOf<Certification>>]>
  supplierUsers?: List<
    RecordOf<{
      id: string
      firstName: string
      lastName: string
      email: string
      blocked?: boolean
    }>
  >
  attachmentTypes: Map<string, Map<string, string>>
  relationshipTypes: Map<string, Map<string, string>>
  sustainabilitySurvey?: boolean
}

class SupplierListItemIcons extends Component<Props> {
  state = {
    openPopover: false,
    anchorEl: undefined
  }

  togglePopover = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    ev.stopPropagation()

    this.setState({
      openPopover: !this.state.openPopover,
      anchorEl: ev.currentTarget
    })
  }

  render() {
    const {
      isPreferred,
      isDoNotUse,
      isRestricted,
      isExisting,
      isNonRecruit,
      attachments,
      isPrivate,
      isSanctionedCountry,
      isSanctionedCompany,
      sanctionedCountryList,
      certifications,
      supplierUsers,
      websiteWarning,
      profileUrl,
      myCompanyUrl,
      name,
      diversityProgram,
      attachmentTypes,
      relationshipTypes,
      disableActions,
      websiteUrl,
      sustainabilitySurvey
    } = this.props

    return (
      <>
        {websiteWarning && <WebsiteWarning warning={websiteWarning} />}
        {supplierUsers && supplierUsers.size > 0 && (
          <>
            <Tooltip
              title={
                <FormattedMessage
                  id='SupplierListItem.PublicContacts'
                  defaultMessage={`{contacts} {contacts, plural, one {Public Contact} other {Public Contacts}}`}
                  values={{
                    contacts: supplierUsers.size
                  }}
                />
              }
            >
              {disableActions ? (
                <NavLink
                  to={profileUrl ? `${profileUrl}&scrollToTeam=true` : ''}
                >
                  <div
                    className='dib ml1 v-mid mid-gray pointer'
                    style={{ height: 20 }}
                    tabIndex={0}
                    role='link'
                    aria-label='Open contacts list'
                  >
                    <FaceIcon
                      style={{ fontSize: 20 }}
                      titleAccess='Open contacts list'
                    />
                  </div>
                </NavLink>
              ) : (
                <IconButton
                  className='dib ml1 v-mid mid-gray pointer'
                  onClick={this.togglePopover}
                  style={{ height: 20 }}
                  aria-label='Open contacts list'
                >
                  <FaceIcon
                    style={{ fontSize: 20 }}
                    titleAccess='Open contacts list'
                  />
                </IconButton>
              )}
            </Tooltip>
            <Popover
              open={this.state.openPopover}
              onClose={this.togglePopover}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
              }}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'top'
              }}
            >
              <div className='dib pa2 lh-copy f7 fw4 mid-gray'>
                <span className='db mb1 f7 fw6' id='contactsLabel'>
                  <FormattedMessage
                    id='SupplierListItem.ContactsLabel'
                    defaultMessage='Contacts:'
                  />
                </span>
                <Divider />
                <div role='listbox' aria-labelledby='contactsLabel'>
                  {supplierUsers.map(user => (
                    <Text
                      key={user.get('id')}
                      className='pa1 flex items-center'
                    >
                      <Link
                        to={paths.userprofile}
                        params={{ userId: user.get('id') }}
                      >
                        {user.get('firstName') ? (
                          <>
                            {user.get('firstName')}&nbsp;
                            {user.get('lastName')}
                          </>
                        ) : (
                          user.get('email')
                        )}
                      </Link>
                      {user.get('blocked') && (
                        <BlockIcon style={{ fontSize: 14 }} className='ml1' />
                      )}
                    </Text>
                  ))}
                </div>
              </div>
            </Popover>
          </>
        )}
        <div className='dib v-mid nt1'>
          <SupplierAttributes
            disableActions={disableActions}
            isExisting={isExisting}
            isPreferred={isPreferred}
            isRestricted={isRestricted}
            isNonRecruit={isNonRecruit}
            isDoNotUse={isDoNotUse}
            isPrivate={isPrivate}
            isSanctionedCountry={isSanctionedCountry}
            isSanctionedCompany={isSanctionedCompany}
            sanctionedCountryList={sanctionedCountryList}
            attachments={attachments && attachments.toJS()}
            certifications={certifications}
            linkToDiversity={
              profileUrl && `${profileUrl}&scrollToDiversity=true`
            }
            linkToAttachment={
              myCompanyUrl && `${myCompanyUrl}&scrollToAttachment=true`
            }
            linkToRelationship={
              myCompanyUrl && `${myCompanyUrl}&scrollToRelationship=true`
            }
            diversityProgram={diversityProgram}
            isSurveyCompleted={sustainabilitySurvey}
            attachmentTypes={attachmentTypes}
            relationshipTypes={relationshipTypes}
            supplierName={name}
            website={websiteUrl}
          />
        </div>
      </>
    )
  }
}

export default SupplierListItemIcons
