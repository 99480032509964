import exportData from 'shared/utils/exportData'
import { List, RecordOf } from 'immutable'
import mapKeys from 'lodash.mapkeys'
import { OverviewSpendItem } from '../../store/diversityReportSelectors'
import {
  DiversityCertification,
  DiversityDetails
} from '../../store/diversityReportReducer'
import DiversityCategory from 'shared/models/DiversityCategory'
import startCase from 'lodash.startcase'
import dateFormat from 'shared/utils/dateFormat'
import CertificationCategories from 'shared/models/CertificationCategories'
import { getTextFromQuarter } from 'shared/utils/sliderUtilsForSpendAndLoader'
import insight from 'shared/utils/api/insight'
import analytics from 'shared/utils/analytics'

const generateOverviewExportData = ({
  currentGrouping,
  spendItems,
  totalSpend
}: {
  spendItems: List<RecordOf<OverviewSpendItem>>
  totalSpend: any
  currentGrouping?: 'subCategory' | 'category' | 'country' | 'spendGroup'
}) => {
  let subTypesColumns: string[] = []
  let spreadSubTypes = {}
  let grouping: string = currentGrouping || ''
  let groupColumn = !grouping || grouping === 'subCategory' ? 'type' : grouping
  let data = spendItems
    .toJS()
    .map(({ [grouping]: groupingName, qualifiedAmount, subTypes, ...rest }) => {
      if (!grouping || grouping === 'subCategory') {
        const subTypeKeys = Object.keys(subTypes)
        spreadSubTypes = subTypeKeys.reduce((result, key) => {
          return Object.assign(
            {},
            result,
            mapKeys(subTypes[key], (v, k) => {
              subTypesColumns.push(`${key}-${k}`)
              return `${key}-${k}`
            })
          )
        }, {})
      }
      return {
        [groupColumn]: groupingName
          ? groupingName === 'null'
            ? currentGrouping === 'country'
              ? 'UNKNOWN'
              : 'N/A'
            : groupingName.toUpperCase()
          : 'OTHER',
        qualifiedAmount,
        qualifiedPercentage: `${((qualifiedAmount / totalSpend) * 100).toFixed(
          2
        )}%`,
        ...rest,
        ...spreadSubTypes
      }
    })

  return data
}

export const exportOverview = (
  spendItems: List<RecordOf<OverviewSpendItem>>,
  totalSpend,
  orgUnitId: string,
  orgUnitName: string,
  currentGrouping?: 'subCategory' | 'category' | 'country' | 'spendGroup',
  label?: string
) => {
  let subTypesColumns: string[] = []
  let grouping: string = currentGrouping || ''
  let groupColumn = !grouping || grouping === 'subCategory' ? 'type' : grouping

  const data = generateOverviewExportData({
    currentGrouping,
    spendItems,
    totalSpend
  })

  exportData.exportToCsv({
    data,
    columns: [
      groupColumn,
      'qualifiedAmount',
      'qualifiedCount',
      'qualifiedPercentage',
      'potentialAmount',
      'potentialCount',
      'disqualifiedAmount',
      'disqualifiedCount',
      ...subTypesColumns
    ],
    fileName: label ? label : 'Diversity Report Overview'
  })
  analytics.track('Report Exported', {
    type: 'Diversity Spend Report Overview',
    orgUnitId,
    orgUnitName
  })
}

const generateDiversityReportExportData = ({
  detailsTableData,
  categories = List([]),
  type = 'qualified',
  perLine,
  verificationColumns = false
}: {
  detailsTableData: List<RecordOf<DiversityDetails>>
  categories: List<DiversityCategory>
  type: 'qualified' | 'potential' | 'disqualified'
  perLine?: boolean
  verificationColumns?: boolean
}) => {
  let data = detailsTableData
    .map(item => {
      const certifications = categories.reduce((result, category) => {
        const catCertification:
          | RecordOf<DiversityCertification>
          | undefined = item
          .get('certifications')
          ?.filter(cert => {
            return cert.subCategory === category
          })
          ?.first()

        let subTypes = {}
        if (category === 'mbe') {
          subTypes[`${category.toUpperCase()} Ethnicity`] = catCertification
            ?.getIn(['agencies', 0, 'subTypes'])
            ?.map(s => startCase(s))
            .join(', ')
        }

        let certNaics = {}
        if (category === 'sbe') {
          certNaics[`${category.toUpperCase()} NAICS`] = catCertification
            ?.getIn(['agencies', 0, 'certNaics'])
            ?.map(n => (n.primary ? `${n.code} (primary)` : n.code))
            .join(', ')
        }

        const certTeamValidation = catCertification
          ?.getIn(['certificationValidations'])
          ?.filter(validation => validation.get('byColleague'))
          ?.last(undefined)

        const certTealbookValidation = catCertification
          ?.getIn(['certificationValidations'])
          ?.filter(validation => !validation.get('byColleague'))
          ?.last(undefined)

        let verifications = {}
        if (verificationColumns) {
          verifications[
            `${category.toUpperCase()} Verification`
          ] = certTeamValidation
            ? certTeamValidation.get('confirmed')
              ? 'Verified'
              : 'Invalid'
            : ''
          verifications[
            `${category.toUpperCase()} Tealbook Verification`
          ] = certTealbookValidation
            ? certTealbookValidation.get('confirmed')
              ? 'Verified'
              : 'Invalid'
            : ''
        }

        return {
          ...result,
          [`${category.toUpperCase()} Certification`]: catCertification
            ? catCertification.getIn(['agencies', 0, 'confirmed'])
              ? 'Yes'
              : catCertification.getIn(['agencies', 0, 'info', 'potential'])
              ? 'Potential'
              : 'No'
            : 'No',
          [`${category.toUpperCase()} Agency`]: catCertification?.getIn([
            'agencies',
            0,
            'certAgency'
          ]),
          ...certNaics,
          ...subTypes,
          [`${category.toUpperCase()} Cert Name`]: catCertification?.getIn([
            'agencies',
            0,
            'company',
            'name'
          ]),
          [`${category.toUpperCase()} Cert Address`]: catCertification?.getIn([
            'agencies',
            0,
            'company',
            'address'
          ]),
          [`${category.toUpperCase()} Cert Contact`]: catCertification?.getIn([
            'agencies',
            0,
            'company',
            'contact',
            'name'
          ]),
          [`${category.toUpperCase()} Cert Contact Email`]: catCertification?.getIn(
            ['agencies', 0, 'company', 'contact', 'email']
          ),
          [`${category.toUpperCase()} Cert Contact Phone`]: catCertification?.getIn(
            ['agencies', 0, 'company', 'contact', 'phone']
          ),
          [`${category.toUpperCase()} Cert Website`]: catCertification?.getIn([
            'agencies',
            0,
            'company',
            'websiteUrl'
          ]),
          [`${category.toUpperCase()} Cert Source`]: catCertification?.getIn([
            'agencies',
            0,
            'sourceURL'
          ]),
          [`${category.toUpperCase()} Cert Potential`]: catCertification?.getIn(
            ['agencies', 0, 'info', 'potential']
          )
            ? 'Yes'
            : 'No',
          [`${category.toUpperCase()} Cert Alert`]: catCertification
            ?.getIn(['agencies', 0, 'info', 'alertCodes'])
            ?.map(s => startCase(s))
            .join(', '),
          [`${category.toUpperCase()} Cert Info`]: catCertification
            ?.getIn(['agencies', 0, 'info', 'infoCodes'])
            ?.map(s => startCase(s))
            .join(', '),
          [`${category.toUpperCase()} Cert Validation`]: catCertification
            ?.getIn(['agencies', 0, 'info', 'validationCodes'])
            ?.map(s => startCase(s))
            .join(', '),
          [`${category.toUpperCase()} Cert DUNS`]: catCertification?.getIn([
            'agencies',
            0,
            'company',
            'duns'
          ]),
          [`${category.toUpperCase()} Cert Parent DUNS`]: catCertification?.getIn(
            ['agencies', 0, 'company', 'parentDuns']
          ),
          [`${category.toUpperCase()} Cert Expiry`]: catCertification?.getIn([
            'agencies',
            0,
            'expiration'
          ])
            ? dateFormat(
                catCertification.getIn(['agencies', 0, 'expiration']),
                'MM/DD/YYYY',
                true
              )
            : '',
          ...verifications
        }
      }, {})

      return {
        domain: item.get('domain'),
        name: item.get('name'),
        internalSupplierId: !!perLine
          ? item.get('internalSupplierId')
          : item.get('internalSupplierId').keySeq(),
        totalAmount: item.get('totalAmount'),
        qualified: type === 'qualified' ? 'Yes' : 'No',
        ...certifications
      }
    })
    .toJS()

  if (perLine) {
    data = data?.reduce((result, row) => {
      let splitRow: Array<any> = []
      if (row.internalSupplierId) {
        Object.keys(row.internalSupplierId).forEach(id => {
          splitRow.push(
            Object.assign({}, row, {
              internalSupplierId: id,
              totalAmount: row.internalSupplierId[id]?.totalAmount
            })
          )
        })
      }
      return [...result, ...splitRow]
    }, [])
  }

  return data
}

export const createDiversityReportForExport = (
  detailsTableData: List<RecordOf<DiversityDetails>>,
  categories: List<DiversityCategory> = List([]),
  type: 'qualified' | 'potential' | 'disqualified' = 'qualified',
  orgUnitId: string,
  orgUnitName: string,
  perLine?: boolean,
  label?: string
) => {
  /***
   * output format
   * domain, name, qualified, totalAmount, [{subCategory, Agencies, certNaics, Ethnicity, certifiedName, certifiedAddress, sourceURL, potential, reasons, duns, parentDuns, expiry}]
   */

  const data = generateDiversityReportExportData({
    detailsTableData,
    categories,
    type,
    perLine
  })
  exportData.exportCSV(data, label ? `${label} Report` : 'Diversity Report')
  analytics.track('Report Exported', {
    type: label
      ? `${label} Spend Report Details`
      : 'Diversity Spend Report Details',
    subtype: type,
    groupBy: perLine ? 'internalId' : '',
    orgUnitId,
    orgUnitName
  })
}

export const exportFilters = ({
  countries,
  categories,
  businessUnits,
  isUsingSpendGroups,
  label
}) => {
  const filters = generateFiltersExportData({
    countries,
    categories,
    businessUnits,
    isUsingSpendGroups
  })

  exportData.exportCSV([filters], label ? label : 'Diversity Report Filters')
}

const generateFiltersExportData = ({
  countries,
  categories,
  businessUnits,
  isUsingSpendGroups
}) => {
  return {
    countries: countries?.toJS() || [],
    categories: !isUsingSpendGroups ? categories?.toJS() || [] : [],
    businessUnits: isUsingSpendGroups ? businessUnits?.toJS() || [] : []
  }
}

const generateQualificationRulesExportData = ({
  qualRules,
  startDate,
  endDate,
  totalSpend
}) => {
  const allSubcategories = Object.keys(
    CertificationCategories.diversity.subCategories
  )
  let baseRules_subCategories = qualRules.baseRules?.toJS().subCategories
  baseRules_subCategories = baseRules_subCategories.length
    ? baseRules_subCategories
    : allSubcategories
  baseRules_subCategories = baseRules_subCategories.sort((k1, k2) =>
    k1 > k2 ? 1 : -1
  )
  const baseRules_certAgencies = qualRules.baseRules
    ?.toJS()
    .certAgencies.sort((k1, k2) => (k1 > k2 ? 1 : -1))

  return {
    startDate,
    endDate,
    totalSpend,
    baseRules_certAgencies,
    baseRules_countries: qualRules.baseRules?.toJS().countries,
    baseRules_subCategories,
    includeRules_attestation_selfCertified: qualRules.includeRules?.toJS()
      .attestation.selfCertified,
    includeRules_attestation_notVerifiedByTealbook: qualRules.includeRules?.toJS()
      .attestation.notVerifiedByTealbook,
    includeRules_attestation_certAgencies: qualRules.includeRules?.toJS()
      .attestation.certAgencies,
    includeRules_attestation_certAgenciesSelected: qualRules.includeRules?.toJS()
      .attestation.certAgenciesSelected,
    includeRules_completeness_attachmentAgencies: qualRules.includeRules?.toJS()
      .completeness.attachmentAgencies,
    includeRules_completeness_attachmentAgenciesSelected: qualRules.includeRules?.toJS()
      .completeness.attachmentAgenciesSelected,
    includeRules_myTeam: qualRules.includeRules?.toJS().myTeam,
    includeRules_tealbook: qualRules.includeRules?.toJS().tealbook,
    excludeRules_myTeam: qualRules.excludeRules?.toJS().myTeam
  }
}

export const exportQualificationRules = ({
  qualRules,
  startDate,
  endDate,
  label,
  totalSpend
}) => {
  const data = generateQualificationRulesExportData({
    qualRules,
    startDate,
    endDate,
    totalSpend
  })
  exportData.exportCSV([data], label)
}

const generateDiversitySummaryExportData = ({ diversityTotals }) => {
  const qualifiedSpend = diversityTotals.qualified?.amount || 0
  const potentialSpend = diversityTotals.potential?.amount || 0
  const disqualifiedSpend = diversityTotals.disqualified?.amount || 0
  const qualifiedSupplierCount = diversityTotals.qualified?.count || 0
  const potentialSupplierCount = diversityTotals.potential?.count || 0
  const disqualifiedSupplierCount = diversityTotals.disqualified?.count || 0
  const totalDiverseSpend = qualifiedSpend + potentialSpend + disqualifiedSpend
  const totalDiverseSuppliersCount =
    qualifiedSupplierCount + potentialSupplierCount + disqualifiedSupplierCount

  return {
    totalDiverseSpend,
    totalDiverseSuppliersCount,
    qualifiedSpend,
    qualifiedSupplierCount,
    potentialSpend,
    potentialSupplierCount,
    disqualifiedSpend,
    disqualifiedSupplierCount
  }
}

export const exportDiversitySummary = ({
  diversityTotals,
  label = 'Diversity Report Summary'
}) => {
  const data = generateDiversitySummaryExportData({ diversityTotals })
  exportData.exportCSV([data], label)
}

export const diversityReportSnapshot = ({
  reportName,
  startDate,
  endDate,
  overviewSpend,
  totalSpend,
  qualifiedData,
  disqualifiedData,
  potentialData,
  selectedCategories,
  overviewSubCategories,
  qualificationRules,
  diversityTotals,
  countries,
  categories,
  businessUnits,
  isUsingSpendGroups
}) => {
  const summary = generateDiversitySummaryExportData({ diversityTotals })
  let overview = generateOverviewExportData({
    currentGrouping: 'subCategory',
    spendItems: overviewSpend.spendItems,
    totalSpend
  })
  overview = overview?.reduce((map, row) => {
    map[row.type] = row
    return map
  }, {})

  const {
    startDate: extract1,
    endDate: extract2,
    totalSpend: extract3,
    ...restRules
  } = generateQualificationRulesExportData({
    qualRules: qualificationRules,
    startDate: startDate,
    endDate: endDate,
    totalSpend
  })

  const filters = generateFiltersExportData({
    countries,
    categories,
    businessUnits,
    isUsingSpendGroups
  })

  const qualifedSpendItems = generateDiversityReportExportData({
    detailsTableData: qualifiedData,
    categories:
      selectedCategories?.size > 0 ? selectedCategories : overviewSubCategories,
    type: 'qualified',
    perLine: false,
    verificationColumns: true
  })

  const potentialSpendItems = generateDiversityReportExportData({
    detailsTableData: potentialData,
    categories:
      selectedCategories?.size > 0 ? selectedCategories : overviewSubCategories,
    type: 'potential',
    perLine: false,
    verificationColumns: true
  })

  const disqualifiedSpendItems = generateDiversityReportExportData({
    detailsTableData: disqualifiedData,
    categories:
      selectedCategories?.size > 0 ? selectedCategories : overviewSubCategories,
    type: 'disqualified',
    perLine: false,
    verificationColumns: true
  })

  const lineItems = [
    ...qualifedSpendItems,
    ...potentialSpendItems,
    ...disqualifiedSpendItems
  ]
  return {
    name: reportName,
    reportingPeriod: [
      getTextFromQuarter(startDate),
      getTextFromQuarter(endDate)
    ],
    totalSpend,
    totalDiverseSpend: summary.totalDiverseSpend,
    qualifiedSpend: summary.qualifiedSpend,
    potentialSpend: summary.potentialSpend,
    disqualifiedSpend: summary.disqualifiedSpend,
    summary,
    overview,
    lineItems: lineItems.map(item => {
      return Object.keys(item)
        .filter(key => key.endsWith('Certification'))
        .reduce((newItem, key) => {
          newItem[key] = newItem[key] === 'Yes' ? 'Qualified' : newItem[key]
          return newItem
        }, item)
    }),
    rules: restRules,
    filters
  }
}

export const exportEconomicImpactAssessment = (
  supplierIds: string[] = [],
  categories: string[] = [],
  startDate: string,
  endDate: string,
  show,
  orgUnitId: string,
  orgUnitName: string
) => {
  insight
    .getEconomicImpactAssessment(supplierIds, categories, startDate, endDate)
    .then(response =>
      exportData.exportCSV(
        response,
        `EIA-${startCase(show)}:${startDate} to ${endDate}`
      )
    )
  analytics.track('Report Exported', {
    type: 'Economic Impact Assessment',
    orgUnitId,
    orgUnitName
  })
}
