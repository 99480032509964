import React, { Component, Fragment } from 'react'
import { Field } from 'redux-form/immutable'
import { Link } from 'react-router-dom'
import Card from 'shared/components/Card'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Warning from 'shared/components/Warning'
import Divider from 'shared/components/Divider'
import { FormattedMessage } from 'react-intl'
import ListItem from 'shared/components/List/ListItem'
import fileToUrl from 'shared/utils/data/fileToUrl'
import OrgLogo from 'shared/components/OrgLogo'
import Avatar from 'shared/components/Avatar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { List, RecordOf } from 'immutable'
import UploadedFile from 'shared/models/UploadedFile'
import Select from 'shared/components/Select'
import { supportedLanguages } from '../../../i18n'
import LanguageIcon from '@material-ui/icons/Language'

type OrgUnit = {
  orgUnitId: string
  orgUnitLogo: UploadedFile
  orgUnitName: string
  domains: Array<string>
}
type SelectedOrgUnit = {
  userProfilePicture: UploadedFile
  orgUnitName: string
}
type Props = {
  selectedOrgUnit?: SelectedOrgUnit
  orgUnits?: List<RecordOf<OrgUnit>>
  signupUrl: string
  client?: string
  resetPasswordUrl: string
  isSubmitting: boolean
  errorMessage?: string
  userNotFound: boolean
  loginOptions?: string
  language?: string
  email?: string
  apiError?: string
  onSubmit: () => void
  onSelectOrgUnit: (param?: RecordOf<OrgUnit>) => void
  onChangeUserName: (event: any) => void
  onCancel: () => void
  onChangeLanguage: (lang: string) => void
}

export class LoginForm extends Component<Props> {
  handleUnselectOrgUnit = () => {
    this.props.onSelectOrgUnit()
  }

  render() {
    const {
      errorMessage,
      isSubmitting,
      onSubmit,
      resetPasswordUrl,
      signupUrl,
      email,
      orgUnits,
      selectedOrgUnit,
      onSelectOrgUnit,
      onCancel,
      userNotFound,
      onChangeUserName,
      apiError,
      onChangeLanguage,
      language,
      loginOptions,
      client
    } = this.props

    return (
      <form className='tl' onSubmit={onSubmit}>
        <Card>
          <div
            style={{
              display: `${!orgUnits && !selectedOrgUnit ? '' : 'none'}`
            }}
          >
            <Label htmlFor='username' className='db mb1 f7 fw6'>
              <FormattedMessage id='LoginForm.email' defaultMessage='Email' />
            </Label>
            <Field
              component={Input}
              data-cy='login-username-input'
              name='username'
              type='email'
              placeholder='email@xyz.com'
              required
              onChange={onChangeUserName}
            />
          </div>

          {orgUnits && !selectedOrgUnit && (
            <Fragment>
              <p>
                <FormattedMessage
                  id='LoginForm.multipleOrgs'
                  defaultMessage={`
                    More than one company was found linked to this email.
                    Which one would you like to log into?
                  `}
                />
              </p>
              {orgUnits.map(orgUnit => (
                <div
                  key={orgUnit.orgUnitId}
                  className='pointer'
                  onClick={() => onSelectOrgUnit(orgUnit)}
                >
                  <ListItem
                    leftAvatar={
                      <OrgLogo
                        url={fileToUrl(orgUnit.orgUnitLogo)}
                        name={orgUnit.orgUnitName}
                      />
                    }
                    primaryText={orgUnit.orgUnitName}
                    secondaryText={orgUnit.domains && orgUnit.domains.join(' ')}
                  />
                </div>
              ))}
            </Fragment>
          )}

          <div hidden={!selectedOrgUnit}>
            <ListItem
              leftAvatar={
                <Avatar
                  url={fileToUrl(
                    selectedOrgUnit && selectedOrgUnit.userProfilePicture
                  )}
                  className='w2-5'
                />
              }
              primaryText={selectedOrgUnit && selectedOrgUnit.orgUnitName}
              secondaryText={email}
              rightContent={
                <IconButton
                  onClick={this.handleUnselectOrgUnit}
                  aria-label='Close'
                >
                  <CloseIcon />
                </IconButton>
              }
            />
            <Label htmlFor='password'>
              <FormattedMessage
                id='LoginForm.password'
                defaultMessage='Password'
              />
            </Label>
            <FormattedMessage id='LoginForm.password'>
              {text => (
                <Field
                  component={Input}
                  data-cy='login-password-input'
                  name='password'
                  type='password'
                  placeholder={text?.toString()}
                  required={!selectedOrgUnit ? false : true}
                />
              )}
            </FormattedMessage>
          </div>

          {orgUnits && !selectedOrgUnit && (
            <Button
              secondary
              className='mb3 mt3'
              onClick={onCancel}
              label={
                <FormattedMessage id='CancelButton' defaultMessage='Cancel' />
              }
            />
          )}

          <div hidden={orgUnits && !selectedOrgUnit}>
            {userNotFound && (
              <span className='red db mt3 f7'>
                <FormattedMessage
                  id='LoginForm.userNotFound'
                  defaultMessage='User not found, please make sure your email is correct or continue to sign up for a new account.'
                />
              </span>
            )}

            {apiError && <span className='red db mt3 f7'>{apiError}</span>}
            <Button
              disabled={isSubmitting}
              data-cy='login-continue-button'
              className='mv3'
              type='submit'
              label={
                selectedOrgUnit ? (
                  <FormattedMessage
                    id='LoginForm.login'
                    defaultMessage='Log In'
                  />
                ) : (
                  <FormattedMessage
                    id='LoginForm.continue'
                    defaultMessage='Continue'
                  />
                )
              }
            />
          </div>
          {errorMessage && <Warning className='mv3' message={errorMessage} />}
          <Divider />
          <div className='mt3 flex justify-between items-center'>
            <div className='flex items-center'>
              <LanguageIcon className='mr2' />
              <label htmlFor='selectlanguage' className='visuallyhidden'>
                <FormattedMessage
                  id='LoginForm.SelectLanguage'
                  defaultMessage={'Select language:'}
                />
              </label>
              <Select
                onChange={e => onChangeLanguage(e.currentTarget.value)}
                value={language}
                name='selectlanguage'
              >
                {supportedLanguages.map(({ key, title }) => (
                  <option key={key} value={key}>
                    {title}
                  </option>
                ))}
              </Select>
            </div>
            <Link
              to={`${resetPasswordUrl}?email=${email}${
                loginOptions ? `&options=${loginOptions}` : ''
              }${client ? `&client=${client}` : ''}`}
              className='tr f7 fw4 db gray no-underline underline-hover'
            >
              <FormattedMessage
                id='LoginForm.resetYourPassword'
                defaultMessage='Reset your password'
              />
            </Link>
          </div>
        </Card>
        <div className='pt4 tc'>
          <span className='f5 fw4 mid-gray'>
            <FormattedMessage
              id='LoginForm.haveAccount'
              defaultMessage={"Don't have an account?"}
            />
          </span>
          <Link
            to={`${signupUrl}?email=${email}${
              loginOptions ? `&options=${loginOptions}` : ''
            }${client ? `&client=${client}` : ''}`}
          >
            <b className='f5 fw6 dib teal no-underline underline-hover pl1 pointer'>
              <FormattedMessage
                id='LoginForm.joinForFree'
                defaultMessage='Join for free!'
              />
            </b>
          </Link>
        </div>
      </form>
    )
  }
}

export default LoginForm
