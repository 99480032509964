import React, { Component } from 'react'
import withUser from 'shared/utils/withUser'
import UserListItem from 'shared/components/UserListItem'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import RichTextEditor, { createValueFromString } from 'react-rte'
import fileToSignedUrl from 'shared/utils/data/fileToSignedUrl'

const UserItem = withUser(UserListItem)

const styles = {
  wrapper: 'bg-white bt bl br b--black-10 ph3-5 pb3-5 pt1 br--top',
  image: 'w-100 mt3',
  title: 'lh-copy f4 fw4',
  body: 'mt3'
}

type Props = {
  userId: string
  createdDate: string
  title: string
  body: string
  image: any
}

type State = {
  imageUrl: string
}
class PopupPost extends Component<Props, State> {
  state = {
    imageUrl: ''
  }
  componentDidMount() {
    if (this.props.image) {
      fileToSignedUrl(this.props.image, `posts/postedImage/signedUrl`)?.then(response => {
        this.setState({
          imageUrl: response
        })
      })
    }
  }
  render() {
    const { userId, createdDate, title, body } = this.props
    const { imageUrl } = this.state
    return (
      <div className={styles.wrapper}>
        <UserItem userId={userId} secondaryText={<Text>{createdDate}</Text>} />
        {title && <Label className={styles.title}>{title}</Label>}
        <div
          className='rte-readonly-wrapper mt3'
          style={{ wordWrap: 'break-word' }}
        >
          <RichTextEditor
            value={createValueFromString(body, 'markdown')}
            editorClassName='readonly'
            readOnly
          />
        </div>
        {imageUrl && <img src={imageUrl} alt='' className={styles.image} />}
      </div>
    )
  }
}

export default PopupPost
