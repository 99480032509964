import React, { Component, Fragment } from 'react'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'

const styles = {
  passwordList: 'pl3 mb0 mt2',
  passwordItem: 'f7 mt2',
  passwordPass: 'green fw6 f7',
  passwordFail: 'dark-red fw6 f7'
}

type Props = {
  password?: string | undefined
  passwordRules: {
    max?: number
    specialCount?: number
    numericCount?: number
    min?: number
    alphaLowerCount?: number
    alphaUpperCount?: number
    previousUniqueCount?: number
  }
}

class PasswordRules extends Component<Props> {
  render() {
    const { password, passwordRules } = this.props

    const specChar = password && password.match(/[!@#$%^&*)(+=._-]/g)
    const numCount = password && password.match(/[0-9]/g)
    const alphaLower = password && password.match(/[a-z]/g)
    const alphaUpper = password && password.match(/[A-Z]/g)

    if (!passwordRules) {
      return null
    }

    return (
      <Fragment>
        <Label className='db pt0 mb1 f7 fw6'>
          <FormattedMessage
            id='PasswordRules.PasswordRules'
            defaultMessage='Password Rules:'
          />
        </Label>
        {!passwordRules.min &&
        !passwordRules.max &&
        !passwordRules.specialCount &&
        !passwordRules.numericCount &&
        !passwordRules.alphaLowerCount &&
        !passwordRules.alphaUpperCount ? (
          <Text>
            <FormattedMessage
              id='PasswordRules.NoRule'
              defaultMessage='No rule'
            />
          </Text>
        ) : (
          <ul className={styles.passwordList}>
            {!!passwordRules.min && (
              <li className={styles.passwordItem}>
                <FormattedMessage
                  id='PasswordRules.CharacterCountMin'
                  defaultMessage={`At least {redCount} {characterCountMin, plural, one {character} other {characters}}.`}
                  values={{
                    characterCountMin: passwordRules.min,
                    redCount: (
                      <span
                        className={
                          password && password.length >= passwordRules.min
                            ? styles.passwordPass
                            : styles.passwordFail
                        }
                      >
                        {passwordRules.min}
                      </span>
                    )
                  }}
                />
              </li>
            )}
            {!!passwordRules.max && (
              <li className={styles.passwordItem}>
                <FormattedMessage
                  id='PasswordRules.CharacterCountMax'
                  defaultMessage={`At most {redCount} { characterCountMax, plural, one {character} other {characters}}.`}
                  values={{
                    characterCountMax: passwordRules.max,
                    redCount: (
                      <span
                        className={
                          password && password.length <= passwordRules.max
                            ? styles.passwordPass
                            : styles.passwordFail
                        }
                      >
                        {passwordRules.max}
                      </span>
                    )
                  }}
                />
              </li>
            )}
            {!!passwordRules.specialCount && (
              <li className={styles.passwordItem}>
                <FormattedMessage
                  id='PasswordRules.CharacterSpeacialCount'
                  defaultMessage={`At least {redCount} special { characterSepcialCount, plural, one {character} other {characters}}.`}
                  values={{
                    characterSpecialCount: passwordRules.specialCount,
                    redCount: (
                      <span
                        className={
                          specChar &&
                          specChar.length >= passwordRules.specialCount
                            ? styles.passwordPass
                            : styles.passwordFail
                        }
                      >
                        {passwordRules.specialCount}
                      </span>
                    )
                  }}
                />
              </li>
            )}
            {!!passwordRules.numericCount && (
              <li className={styles.passwordItem}>
                <FormattedMessage
                  id='PasswordRules.CharacterNumericCount'
                  defaultMessage={`At least {redCount} numeric { characterNumericCount, plural, one {character} other {characters}}.`}
                  values={{
                    characterNumericCount: passwordRules.numericCount,
                    redCount: (
                      <span
                        className={
                          numCount &&
                          numCount.length >= passwordRules.numericCount
                            ? styles.passwordPass
                            : styles.passwordFail
                        }
                      >
                        {passwordRules.numericCount}
                      </span>
                    )
                  }}
                />
              </li>
            )}
            {!!passwordRules.alphaLowerCount && (
              <li className={styles.passwordItem}>
                <FormattedMessage
                  id='PasswordRules.CharacterLowerCaseCount'
                  defaultMessage={`At least {redCount} lower case{ characterLowerCaseCount, plural, one {character} other {characters}}.`}
                  values={{
                    characterLowerCaseCount: passwordRules.alphaLowerCount,
                    redCount: (
                      <span
                        className={
                          alphaLower &&
                          alphaLower.length >= passwordRules.alphaLowerCount
                            ? styles.passwordPass
                            : styles.passwordFail
                        }
                      >
                        {passwordRules.alphaLowerCount}
                      </span>
                    )
                  }}
                />
              </li>
            )}
            {!!passwordRules.alphaUpperCount && (
              <li className={styles.passwordItem}>
                <FormattedMessage
                  id='PasswordRules.CharacterUpperCaseCount'
                  defaultMessage={`At least {redCount} upper case { characterUpperCaseCount, plural, one {character} other {characters}}.`}
                  values={{
                    characterUpperCaseCount: passwordRules.alphaUpperCount,
                    redCount: (
                      <span
                        className={
                          alphaUpper &&
                          alphaUpper.length >= passwordRules.alphaUpperCount
                            ? styles.passwordPass
                            : styles.passwordFail
                        }
                      >
                        {passwordRules.alphaUpperCount}
                      </span>
                    )
                  }}
                />
              </li>
            )}
          </ul>
        )}
      </Fragment>
    )
  }
}

export default PasswordRules
