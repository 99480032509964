import FileSaver from 'file-saver'
import api from '../api'
import apiRoutes from 'shared/routes/apiRoutes'

const apiRoute = `${apiRoutes.authService}`

const downloadAttachment = (attachmentName, apiPath, saveAs?) => {
  return api
    .download(`${apiRoute}/${apiPath}`, { fileName: attachmentName })
    .then(response => response.blob())
    .then(blob => {
      FileSaver.saveAs(blob, !!saveAs ? saveAs : attachmentName)
    })
    .catch(err => console.log('err', err))
}

export const downloadAttachmentToURL = (attachmentName, apiPath) => {
  return api
    .download(`${apiRoute}/${apiPath}`, { fileName: attachmentName })
    .then(response => response.text())
    .catch(err => console.log('err', err))
}
export default downloadAttachment
