import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import AddTagForm from '../../components/AddTagForm'
import { ADD_TAG } from '../../actionTypes'
import { FormattedMessage } from 'react-intl'

type Props = {
  handleSubmit: (arg: string) => () => void
  addTag: string
  numberOfTags: number
}
export class OfferingsAddTagForm extends Component<Props> {
  render() {
    const { handleSubmit, addTag, numberOfTags } = this.props

    return (
      <FormattedMessage
        id='OfferingsAddTagForm.SkillSupplierProfile'
        defaultMessage='Skill (ex: Advertising or Digital Marketing)'
      >
        {message => (
          <AddTagForm
            inputId='offeringsNewTag'
            onSubmit={handleSubmit(addTag)}
            inputPlaceholder={message}
            footerNote={
              <FormattedMessage
                id='OfferingsAddTagForm.NoteYourTagsAreAnonymous'
                defaultMessage='NOTE: Your tags will always remain anonymous outside of your company.'
              />
            }
            numberOfTags={numberOfTags}
          />
        )}
      </FormattedMessage>
    )
  }
}

const formName = 'buyer/supplier/offerings'

export default connectReduxForm(
  undefined,
  {
    addTag: createSubmitFormAction(formName, ADD_TAG)
  },
  {
    form: formName,
    initialValues: {
      tagsFieldName: 'offerings'
    }
  },
  OfferingsAddTagForm
)
