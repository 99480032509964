import React, { useEffect, useMemo, ChangeEvent, useState } from 'react'
import { getOutreachList } from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import OutreachTable from '../SupplierOutreach/OutreachTable'
import { getAllSupplierOutreachTasks } from '../../containers/SupplierOutreach/SupplierOutreachContainer'
import Input from 'shared/components/Input'
import { messages } from './CompletedSuppliers'
import { useIntl, FormattedMessage } from 'react-intl'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg'
import Loading from 'shared/components/Loading'
import RootState from 'shared/models/RootState'
import Card from 'shared/components/Card'
import Text from 'shared/components/Text'

const OpenSuppliers = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const tasks = useSelector(getAllSupplierOutreachTasks)
  const isLoading = useSelector((state: RootState) =>
    state.getIn(['data', 'isLoading', 'supplierOutreachTasks'])
  )

  const [filterValue, setFilterValue] = useState<string>('')

  useEffect(() => {
    dispatch(
      getOutreachList({
        entity: 'supplierOutreachTasks',
        query: 'tasks/supplierOutreach?type=ESGSurveyRequest'
      })
    )
  }, [dispatch])

  const openTasks = useMemo(() => {
    return tasks.filter(
      task =>
        !task.get('archived') &&
        (!filterValue ||
          task
            .get('supplier')
            ?.toLowerCase()
            .includes(filterValue.toLocaleLowerCase()))
    )
  }, [tasks, filterValue])

  const handleFilterValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.currentTarget.value)
  }

  if (isLoading) {
    return <Loading />
  }

  return tasks?.size > 0 ? (
    <>
      <div className='flex justify-between mb3'>
        <div className='w-50'>
          <Input
            ariaLabel='Search'
            value={filterValue}
            onChange={handleFilterValueChange}
            placeholder={intl.formatMessage(messages.filterPlaceholder)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className='w50'></div>
      </div>
      <OutreachTable
        tasks={openTasks}
        noTaskMessage={
          <FormattedMessage
            id='SupplierOutreach.OpenRequestNotFound'
            defaultMessage='There is no open request found'
          />
        }
      />
    </>
  ) : (
    <Card>
      <Text style={{ textAlign: 'center' }}>
        <FormattedMessage
          id='SupplierOutreach.OpenRequestNotFound'
          defaultMessage='There is no open request found'
        />
      </Text>
    </Card>
  )
}

export default OpenSuppliers
