import React, { useState } from 'react'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { fromJS, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import Label from 'shared/components/Label'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import {
  IncludeRulesType,
  ExcludeRulesType,
  BaseRulesType
} from '../../../buyer/Insights/store/diversityReportReducer'
import {
  getAvailableAuthorities,
  getQualificationRulesForSettings
} from '../../../buyer/Insights/store/diversityReportSelectors'
import { updateSettings } from '../../actions'
import Text from 'shared/components/Text'
import withUser from 'shared/utils/withUser'
import UserListItem from 'shared/components/UserListItem'
import dateFormat from 'shared/utils/dateFormat'
import QualificationRulesContent from '../../../buyer/Insights/containers/DiversityReport/DiversityReportButtonFilters/QualificationRulesContent'
import { useDispatch, useSelector } from 'react-redux'

const UserList = withUser(UserListItem)

const DiversityQualificationRules = () => {
  const dispatch = useDispatch()
  const authorities = useSelector((state: RootState) =>
    getAvailableAuthorities(state)
  )
  const qualificationRules = useSelector((state: RootState) =>
    getQualificationRulesForSettings(state)
  )
  const hasCorporateRules = qualificationRules.get('corporateRules')

  const [baseRules, setBaseRules] = useState<RecordOf<BaseRulesType>>(
    hasCorporateRules
      ? qualificationRules.getIn(['corporateRules', 'baseRules'])
      : qualificationRules.getIn(['noRules', 'baseRules'])
  )
  const [includeRules, setIncludeRules] = useState<RecordOf<IncludeRulesType>>(
    hasCorporateRules
      ? qualificationRules.getIn(['corporateRules', 'includeRules'])
      : qualificationRules.getIn(['noRules', 'includeRules'])
  )
  const [excludeRules, setExcludeRules] = useState<RecordOf<ExcludeRulesType>>(
    hasCorporateRules
      ? qualificationRules.getIn(['corporateRules', 'excludeRules'])
      : qualificationRules.getIn(['noRules', 'excludeRules'])
  )

  const [
    openDialogWarningCorporateRules,
    setOpenDialogWarningCorporateRules
  ] = useState<boolean>(false)

  const handleSubmit = e => {
    e.preventDefault()
    setOpenDialogWarningCorporateRules(true)
    setIncludeRules(
      includeRules
        .update('attestation', values =>
          values.get('certAgencies').size === 0
            ? values.set('certAgenciesSelected', false)
            : values
        )
        .update('completeness', values =>
          values.get('attachmentAgencies').size === 0
            ? values.set('attachmentAgenciesSelected', false)
            : values
        )
    )
  }

  const handleApplyCorporateRules = () => {
    dispatch(
      updateSettings({
        corporateDiversityQualificationRules: fromJS({
          baseRules,
          includeRules,
          excludeRules
        })
      })
    )
    setOpenDialogWarningCorporateRules(false)
  }

  const handleCancel = () => {
    setOpenDialogWarningCorporateRules(false)
  }
  const configuredInfo = hasCorporateRules
    ? qualificationRules.getIn(['corporateRules', 'configuredInfo', 'updated'])
    : undefined
  return (
    <form className='w-60' onSubmit={handleSubmit}>
      <div className='mb3'>
        <div className='flex-column'>
          <Label>
            {
              <FormattedMessage
                id='DiversityQualificationRules.DRQualificationRulesTitle'
                defaultMessage='Diversity Report Qualification Rules'
              />
            }
          </Label>
          {configuredInfo && (
            <>
              <Text>
                <FormattedMessage
                  id='DiversityQualificationRules.corporateRules'
                  defaultMessage='Corporate rules were last updated by: '
                />
              </Text>

              <UserList
                key={configuredInfo.get('user')}
                userId={configuredInfo.get('user')}
                secondaryText={dateFormat(configuredInfo.get('updatedDated'))}
                mini
                className='mt2'
                role=''
              />
            </>
          )}
        </div>
      </div>
      <div className='bg-white br1 ba b--black-10 pa3'>
        <QualificationRulesContent
          baseRules={baseRules}
          includeRules={includeRules}
          excludeRules={excludeRules}
          onChangeBaseRules={setBaseRules}
          onChangeIncludeRules={setIncludeRules}
          onChangeExcludeRules={setExcludeRules}
          authorities={authorities}
        />
        <Button type='submit'>
          <FormattedMessage id='DialogFilter.Apply' defaultMessage='Apply' />
        </Button>

        <Dialog
          fullWidth
          open={openDialogWarningCorporateRules}
          onClose={() => setOpenDialogWarningCorporateRules(false)}
        >
          <DialogTitle
            onClose={() => setOpenDialogWarningCorporateRules(false)}
          >
            <FormattedMessage
              id='DiversityReportButtonFilters.Warning'
              defaultMessage='Warning'
            />
          </DialogTitle>
          <DialogContent>
            <Text className='mv3'>
              <FormattedMessage
                id='DiversityReportButtonFilters.TrendingIssues'
                defaultMessage='Please note, changing the corporate rules will affect Diversity Spend trending'
              />
            </Text>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleApplyCorporateRules}>
              <FormattedMessage id='DialogFilter.Ok' defaultMessage='Ok' />
            </Button>
            <Button onClick={handleCancel} secondary>
              <FormattedMessage id='CancelButton' />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </form>
  )
}

export default DiversityQualificationRules
