import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from 'recharts'
import insightsSelectors from '../../store/insightsSelectors'
import numberFormat from 'shared/utils/numberFormat'
import { List } from 'immutable'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'

type Props = {
  colors: string[]
  values: List<string>
  highlightIndex?: number
  changeIndex: Function
  changeValues: (value: string) => void
  pieData: List<any>
  type: string
  additionalActions?: ReactNode
  title?: ReactNode
  disabled?: boolean
} & ContainerProps

const renderActiveShape = props => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill='#3C99FC'
      />
    </g>
  )
}

const renderHighlightShape = props => {
  const {
    cx,
    cy,
    outerRadius,
    startAngle,
    endAngle,
    percent,
    value,
    name,
    color
  } = props

  const activeText = {
    x: '50%',
    y: 10,
    anchor: 'middle',
    fontSize: 12
  }

  return (
    <g>
      <text
        x={activeText.x}
        y={activeText.y}
        textAnchor={activeText.anchor}
        fill='#333'
        style={{ fontSize: 12 }}
      >{`${name || 'No Category'}`}</text>
      <text
        x={activeText.x}
        y={activeText.y}
        dy={18}
        textAnchor={activeText.anchor}
        fill='#999'
        style={{ fontSize: 12 }}
      >
        {`${numberFormat(value)} (${(percent * 100).toFixed(2)}%)`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 11}
        outerRadius={outerRadius + 17}
        fill={color}
      />
    </g>
  )
}

export const SpendOverviewPie = (props: Props) => {
  const onPieEnter = (data, index) => {
    const { changeIndex, disabled } = props
    if (!disabled) {
      changeIndex(index)
    }
  }

  const onPieLeave = (data, index) => {
    const { changeIndex, disabled } = props
    if (!disabled) {
      changeIndex(undefined)
    }
  }

  const onPieClick = (data, index) => {
    const { changeValues, disabled } = props
    if (!disabled) {
      changeValues(data.name)
    }
  }

  const {
    pieData,
    highlightIndex,
    colors,
    values,
    type,
    additionalActions,
    title
  } = props
  const data = pieData && pieData.toJS()
  const activeIndex =
    values && values.size > 0
      ? data && data.filter(d => values.includes(d.name)).map(d => d.index)
      : undefined

  return data && data.length > 0 ? (
    <>
      <div className='mb2 flex justify-between items-center'>
        {title}
        <div className='tr'>{additionalActions}</div>
      </div>
      <ResponsiveContainer width='100%' height={340}>
        <PieChart>
          <Pie
            data={data}
            dataKey='value'
            nameKey='name'
            cy='55%'
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            onClick={onPieClick}
            className='pointer'
            startAngle={180}
            endAngle={-180}
          >
            {data.map((entry, index) => (
              <Cell
                key={entry.name}
                fill={colors[entry.index % colors.length]}
              />
            ))}
          </Pie>
          <Pie
            fill='none'
            stroke='none'
            activeIndex={highlightIndex}
            activeShape={renderHighlightShape}
            data={data}
            dataKey='value'
            nameKey='name'
            cy='55%'
            startAngle={180}
            endAngle={-180}
          >
            {data.map((entry, index) => (
              <Cell
                key={entry.name}
                color={colors[entry.index % colors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  ) : (
    <Text>
      {type === 'category' ? (
        <FormattedMessage
          id='SpendOverviewPie.SupplierCategories'
          defaultMessage='Supplier Categories'
        />
      ) : type === 'country' ? (
        <FormattedMessage
          id='SpendOverviewPie.SpendCountries'
          defaultMessage='Spend Countries'
        />
      ) : (
        <FormattedMessage
          id='SpendOverviewPie.BusinessUnits'
          defaultMessage='Business Units'
        />
      )}
      :{' '}
      <FormattedMessage
        id='SpendOverviewPie.NoData'
        defaultMessage='There was no data found.'
      />
    </Text>
  )
}

type ContainerProps = {
  type: 'country' | 'category' | 'spendgroup'
}

export default connect((state, props: ContainerProps) => {
  return {
    pieData: insightsSelectors.getSpendPieData(state, props.type)
  }
})(SpendOverviewPie)
