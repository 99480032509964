type AllowedFileUploadStatuses = '0' | '1' | '2'

export enum FileType {
  SUPPLIER_LIST = 'Supplier',
  DIVERSITY_MAPPING = 'Diversity'
}

export const FileUploadStatus: Record<string, AllowedFileUploadStatuses> = {
  PROCESSING: '0',
  COMPLETE: '1',
  FILE_PROCESSING_ERROR: '2'
}

export interface SapFileUploadHistoryRowData {
  uploadId: string
  fileName: string
  uploadDate: Date
  uploadedBy: string
  fileType: FileType
  status: AllowedFileUploadStatuses
  downloadLink?: string
  errorMessage?: string
}


export type DiversityTypeMetadata = {
  fileName: string
}

export type DiversityTypeRow = {
  locale: string | null
  displayName: string
  name: string
  id: string,
  tealbookId: string | null
}

export type DiversityTypeResponse = {
  metadata: DiversityTypeMetadata
  data: DiversityTypeRow[]
}
