import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import { CreateBulkOutreachPayload } from 'buyer/Insights/store/actions'

const apiRoute = `${apiRoutes.authService}/tasks`

export const sendConciergeRequest = requestBody => {
  return api.post(`${apiRoutes.authService}/email/concierge`, requestBody)
}
export const requestSelfCertify = ({
  supplierId,
  supplierName,
  requestedContactId
}) => {
  return api.post(`${apiRoute}/${supplierId}/requestSelfCertify`, {
    supplierName,
    requestedContactId
  })
}

export const requestESGSurvey = ({
  supplierId,
  supplierName,
  requestedContactId,
  additionalRequestContactIds
}) => {
  return api.post(`${apiRoute}/${supplierId}/requestESGSurvey`, {
    supplierName,
    requestedContactId,
    additionalRequestContactIds
  })
}

export const createBulkOutreach = (payload: CreateBulkOutreachPayload) =>
  api.post(`${apiRoute}/bulkOutreach`, payload)

export const loadAllSurveyInvitations = () => {
  return api.get(`${apiRoute}/getCorrespondingEsgTasks`)
}
