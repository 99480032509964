import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { getEsgReportPage } from '../../store/actions'
import ReportQuestion from './ReportQuestion'
import insightsSelectors from '../../store/insightsSelectors'
import Loading from 'shared/components/Loading'

const pageId = 'governance'

const GovernanceReport = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(insightsSelectors.isEsgReportPageLoading)

  useEffect(() => {
    dispatch(getEsgReportPage(pageId))
  }, [dispatch])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className='ba b--black-10 bg-light-gray ph3'>
      <ReportQuestion
        questionNumber={1}
        label={
          <FormattedMessage
            id='GovernanceReport.Question1'
            defaultMessage='Does your company have any of the following policies in place?'
          />
        }
        pageId={pageId}
        questionId='governanceQuestion1'
        subQuestionIds={[
          'codeOfConduct',
          'antiCorruption',
          'childForceLabour',
          'whistleblower'
        ]}
        showSubQuestions
      />
      <ReportQuestion
        questionNumber={2}
        pageId={pageId}
        questionId='complianceOfficer'
      />
      <ReportQuestion
        questionNumber={3}
        pageId={pageId}
        questionId='activeEngagementProgram'
      />
      <ReportQuestion
        questionNumber={4}
        pageId={pageId}
        questionId='requireAdhereCodeOfConduct'
      />
      <ReportQuestion
        questionNumber={5}
        pageId={pageId}
        questionId='annualEsgReport'
      />
      <ReportQuestion
        questionNumber={6}
        pageId={pageId}
        questionId='useSustainabilityReportingFramework'
      />
    </div>
  )
}

export default GovernanceReport
