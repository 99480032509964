import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import storageManager from '../../storageManager'
import isMobile from '../../isMobile'
import ApiError from '../ApiError'
import jwtDecode from 'jwt-decode'

type TokensResponse = {
  access: string
  refresh: string
}

export const getPasswordRules = (token: string) => {
  return window.fetch(`${apiRoutes.authService}/passwordRules`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

type CreatePasswordParams = {
  password: string
  user: string
  token: string
  orgUnitId?: string
}
export const createPassword = ({
  password,
  user,
  token,
  orgUnitId
}: CreatePasswordParams) => {
  return api
    .doOpenRequest(`${apiRoutes.authService}/password`, {
      body: JSON.stringify({ password, user, orgUnitId }),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .catch(err => {
      if (err.statusCode === 401) {
        return Promise.reject(
          new ApiError({
            clientErrorMessage:
              'Your token has expired, please try signing up or resetting your password again.'
          })
        )
      } else {
        return Promise.reject(err)
      }
    })
}

type LoginArgs = {
  username: string
  password: string
  orgUnitId?: string
}
export const login = ({ username, password, orgUnitId }: LoginArgs) => {
  return api
    .doOpenRequest<TokensResponse>(`${apiRoutes.authService}/login`, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username,
        password,
        orgUnitId,
        expiry: isMobile() ? '28 days' : undefined
      }),
      method: 'POST'
    })
    .then(tokens => {
      storageManager.setItem('accessToken', tokens.access)
      storageManager.setItem('refreshToken', tokens.refresh)
    })
}

export const logout = () =>
  api
    .post(`${apiRoutes.authService}/user/logout`)
    .catch(console.log)
    .then(() => {
      storageManager.removeItem('accessToken')
      storageManager.removeItem('refreshToken')
      storageManager.removeItem('tealbotAccessToken')
      storageManager.removeItem('tealbotRefreshToken')
      storageManager.removeItem('punchout')
      storageManager.removeItem('tealbotUrl')
    })

export const register = ({ email, language, orgUnitId, noTAndCs }) => {
  return api.post(`${apiRoutes.authService}/email/register`, {
    email,
    language,
    orgUnitId,
    noTAndCs,
    register: true
  })
}

/**
 * Gets new tokens (access & refresh) and stores them in local storage
 */
type RefreshResponse = {
  access: string
  refresh: string
}

let refreshTokenPromise: Promise<RefreshResponse | void> | null = null

export const refreshTokens = (refreshToken?: string) => {
  if (refreshTokenPromise) {
    return refreshTokenPromise
  } else if (!refreshToken) {
    return Promise.resolve()
  }
  refreshTokenPromise = window
    .fetch(`${apiRoutes.authService}/refresh`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    })
    .then(response =>
      response.ok ? (response.json() as Promise<RefreshResponse>) : undefined
    )
    .then(tokens => {
      if (tokens) {
        storageManager.setItem('accessToken', tokens.access)
        storageManager.setItem('refreshToken', tokens.refresh)
        return tokens
      }
    })
    .catch(console.error)
    .finally(() => {
      refreshTokenPromise = null
    })
  return refreshTokenPromise
}

export const validateToken = (token: string) => {
  return window
    .fetch(`${apiRoutes.authService}/user/validate`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => response.ok)
    .catch(err => {
      console.error(err)
      return false
    })
}

export const loginWithMagic = (token: string) => {
  const { origEmail } = jwtDecode(token)
  return api
    .doOpenRequest(`${apiRoutes.authService}/login/magic`, {
      body: JSON.stringify({ token }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(({ access, refresh, ...rest }) => {
      const { restriction } = jwtDecode(access)

      storageManager.setItem('accessToken', access)
      storageManager.setItem('refreshToken', refresh)

      return { ...rest, restriction, origEmail }
    })
}

export const loginWithSaml = (token: string) => {
  const { orgUnitId } = jwtDecode(token)
  const queryString = orgUnitId ? `?orgUnitId=${orgUnitId}` : ''
  return api
    .doOpenRequest<TokensResponse>(
      `${apiRoutes.authService}/saml/getToken${queryString}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then(tokens => {
      storageManager.setItem('accessToken', tokens.access)
      storageManager.setItem('refreshToken', tokens.refresh)
    })
}

export const loginWithPunchoutToken = (token: string) =>
  api
    .doOpenRequest(`${apiRoutes.authService}/partner/punchOutLogin`, {
      body: JSON.stringify({ token }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(({ access, refresh, setupPayload }) => {
      storageManager.setItem('punchout', setupPayload)
      storageManager.setItem('accessToken', access)
      storageManager.setItem('refreshToken', refresh)
      storageManager.removeItem('cart')

      return {
        punchoutPayload: setupPayload
      }
    })

export const resetPassword = ({ email, orgUnitId, userId }) => {
  return api.post(`${apiRoutes.authService}/email/reset`, {
    email,
    orgUnitId,
    userId,
    register: false
  })
}

export const checkOrgUnitByEmail = (domain: string) => {
  return api.doOpenRequest(`${apiRoutes.authService}/login/domain/lookup`, {
    body: JSON.stringify({ domain }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

let accessToken = ''
let accessTokenBeforeTealbot = ''

export const getAccessToken = () => accessToken
export const getAccessTokenBeforeTealbot = () => accessTokenBeforeTealbot
export const setAccessToken = token => (accessToken = token)
export const setAccessTokenBeforeTealbot = token =>
  (accessTokenBeforeTealbot = token)

export const getLastLoggedInUser = () =>
  storageManager.getItem('lastLoggedInUser') || {}
export const saveLastLoggedInUser = user =>
  storageManager.setItem('lastLoggedInUser', user)
export const removeLastLoggedInUser = () =>
  storageManager.removeItem('lastLoggedInUser')
