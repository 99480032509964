import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'shared/components/Select'
import Label from 'shared/components/Label'
import FilterHOC from '../FilterHOC'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'
import RootState from 'shared/models/RootState'

type Props = {
  limitedSorts?: boolean
  onChangeFilterOption: (arg: { key: string; value: string }) => void
  selected: string
  similarSuppliers: List<string>
}
export class SearchSortContainer extends Component<Props> {
  handleOnChange = e => {
    const { onChangeFilterOption } = this.props
    onChangeFilterOption({ key: 'sort', value: e.target.value })
  }

  render() {
    const {
      selected = 'Relevance',
      similarSuppliers,
      limitedSorts
    } = this.props
    const showSimilarSuppliers = similarSuppliers

    return (
      <>
        <Label className='f7 fw6 ma2' htmlFor='sortSelect'>
          <FormattedMessage
            id='SearchSortContainer.SortBy'
            defaultMessage='Sort By'
          />
        </Label>
        <div className='dib'>
          <Select
            fullWidth
            onChange={this.handleOnChange}
            value={
              !showSimilarSuppliers && selected === 'Similarity'
                ? 'Relevance'
                : selected
            }
            name='sortSelect'
          >
            {showSimilarSuppliers && (
              <FormattedMessage
                id='SearchSortContainer.Similarity'
                defaultMessage='Similarity'
              >
                {message => <option value='Similarity'>{message}</option>}
              </FormattedMessage>
            )}

            {!limitedSorts && (
              <FormattedMessage
                id='SearchSortContainer.Connection'
                defaultMessage='Connection'
              >
                {message => <option value='Connection'>{message}</option>}
              </FormattedMessage>
            )}

            <FormattedMessage
              id='SearchSortContainer.Relevance'
              defaultMessage='Relevance'
            >
              {message => <option value='Relevance'>{message}</option>}
            </FormattedMessage>

            {!limitedSorts && (
              <>
                <FormattedMessage
                  id='SearchSortContainer.Spend'
                  defaultMessage='Spend'
                >
                  {message => <option value='RecentAmount'>{message}</option>}
                </FormattedMessage>

                <FormattedMessage
                  id='SearchSortContainer.Rating'
                  defaultMessage='Rating'
                >
                  {message => <option value='Rating'>{message}</option>}
                </FormattedMessage>
              </>
            )}

            <FormattedMessage
              id='SearchSortContainer.Name'
              defaultMessage='Name'
            >
              {message => <option value='SupplierName'>{message}</option>}
            </FormattedMessage>

            <FormattedMessage
              id='SearchSortContainer.LastUpdated'
              defaultMessage='Last Updated'
            >
              {message => <option value='LastUpdated'>{message}</option>}
            </FormattedMessage>
          </Select>
        </div>
      </>
    )
  }
}

export default connect(
  (state: RootState) => {
    return {
      similarSuppliers: filterOptionsSelectors.getByKey(
        state,
        'similarSuppliers'
      ),
      selected: filterOptionsSelectors.getSort(state)
    }
  },
  {
    ...FilterHOC
  }
)(SearchSortContainer)
