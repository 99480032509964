import React, { useEffect, useState } from 'react'

import Paper from 'shared/components/Paper'
import SAPLoaderFileUpload from './SapLoaderFileUpload'
import SapFileUploadHistory from './SapFileUploadHistory'
import {
  DiversityTypeResponse,
  FileType,
  FileUploadStatus,
  SapFileUploadHistoryRowData
} from './types'
import loader from 'shared/utils/api/loader'
import { exportToCsv } from '../../../shared/utils/exportData/exportData'
import DialogTitle from '../../../shared/components/DialogTitle/DialogTitle'
import { FormattedMessage } from 'react-intl'
import DialogContent from '@material-ui/core/DialogContent'
import Text from '../../../shared/components/Text'
import DialogActions from '../../../shared/components/DialogActions/DialogActions'
import Button from '../../../shared/components/Button/Button'
import Dialog from '@material-ui/core/Dialog'

const sortUploadedFilesByDate = data =>
  data.sort(
    (a, b) =>
      new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime()
  )

const SapLoader = () => {
  const [uploadHistory, setUploadHistory] = useState<
    Array<SapFileUploadHistoryRowData>
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false)
  const [apiResponse, setApiResponse] = useState<string>('')

  const fileUploadHandler = async (attachedFile: File, fileType: string) => {
    const response = { ok: false, message: '' }
    try {
      await loader.sapLoaderFileUpload({ attachedFile, fileType })
      response.ok = true
      response.message = 'File uploaded successfully'
      getUploadHistory()
      return response
    } catch (error) {
      response.message = error.message
      return response
    }
  }

  const closeErrorDialog = () => {
    setApiResponse('')
    setOpenErrorDialog(false)
  }

  const getUploadHistory = async () => {
    setIsLoading(true)
    const response = { ok: false, message: '' }

    try {
      const apiResponse: any[] = await loader.sapDiversityFileUploadHistory()
      response.ok = true
      const mappedResponse = apiResponse.map(row => {
        return {
          ...row,
          status: FileUploadStatus[String(row.status).toUpperCase()]
        }
      })

      const sortedFilesByDate = sortUploadedFilesByDate(mappedResponse)

      setUploadHistory(sortedFilesByDate)
    } catch (error) {
      response.message = error.message
    }

    setIsLoading(false)
    return response
  }

  const fileColumns = {
    [FileType.DIVERSITY_MAPPING]: ['name', 'displayName', 'locale'],
    [FileType.SUPPLIER_LIST]: [
      'erpVendorId',
      'sourceSystem',
      'region',
      'smVendorId',
      'diversityType',
      'supplierStory',
      'url',
      'active'
    ]
  }

  const onDownloadButtonClick = async (
    uploadId: string,
    fileType: FileType
  ) => {
    try {
      let fileData: any[] = []
      let fileName = ''

      switch (fileType) {
        case FileType.DIVERSITY_MAPPING:
          const response: DiversityTypeResponse = await loader.getSapDiversityFile(
            uploadId
          )
          fileData = response.data
          fileName = response.metadata.fileName.replace('.csv', '')
          break
        case FileType.SUPPLIER_LIST:
          fileData = await loader.getSupplierListFile(uploadId)
          fileName = `${fileType.toString()}-${
            uploadId?.split('-')[1]
          }-tealbookMapped`
          break
      }

      if (!fileData || !fileData.length) {
        throw new Error('Requested file is empty.')
      }

      exportToCsv({
        data: fileData,
        columns: fileColumns[fileType],
        fileName,
        hasDisclaimer: false,
        modifyHeader: false
      })
    } catch (error) {
      setApiResponse(error.message)
      setOpenErrorDialog(true)
    }
  }

  useEffect(() => {
    getUploadHistory()
  }, [])

  return (
    <>
      <Paper className='mt4 pt3-5'>
        <SAPLoaderFileUpload fileUploadHandler={fileUploadHandler} />
        <SapFileUploadHistory
          isLoading={isLoading}
          data={uploadHistory}
          onDownloadButtonClick={onDownloadButtonClick}
        />
      </Paper>

      <Dialog
        fullWidth
        open={openErrorDialog}
        onClose={() => closeErrorDialog()}
      >
        <DialogTitle onClose={() => closeErrorDialog()}>
          <FormattedMessage
            id='SAPLoader.FileDownloadErrorTitle'
            defaultMessage='We could not get the file you requested.'
          />
        </DialogTitle>
        <DialogContent>
          <Text className='mv3 red'>{apiResponse}</Text>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'right' }}>
          <Button autoSize onClick={() => closeErrorDialog()}>
            <FormattedMessage id='CloseButton' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SapLoader
