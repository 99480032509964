import React, { useState, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import Select from 'react-select'

import { DirectSpendLabel } from './DirectSpentLabel'

const messages = defineMessages({
  placeholder: {
    id: 'AddDiverseSupplierSpendV2.AddDiverseSupplier',
    defaultMessage: 'Add a diverse supplier'
  },
  noOptions: {
    id: 'AddDiverseSupplierSpendV2.NoOptions',
    defaultMessage: 'No Suppliers Found'
  },
  directSpend: {
    id: 'AddDiverseSupplierSpendV2.DirectSpend',
    defaultMessage: 'Direct Spend'
  }
})

export type DiverseSupplier = {
  id: string
  name: string
  spend: number
  allocation?: number
  logoUrl?: string
}

type Props = {
  onClickSuggestedSupplier: (supplier: DiverseSupplier) => void
  diverseSuppliers: DiverseSupplier[]
  readOnly?: boolean
}

const AddDiverseSupplierSpendV2 = (props: Props) => {
  const { diverseSuppliers, readOnly, onClickSuggestedSupplier } = props
  const [selectInput, setSelectInput] = useState<string>('')
  const [value] = useState<DiverseSupplier | null>(null)
  const intl = useIntl()

  const handleSelectInputChange = (inputValue: string) => {
    setSelectInput(inputValue)
  }

  const handleSupplierSelected = (data, metaAction) => {
    onClickSuggestedSupplier(data)
    setSelectInput('')
  }

  const sortSuppliers = useMemo(() => {
    return diverseSuppliers
      ? diverseSuppliers
          .filter(supplier => !!supplier.spend)
          .sort((supplier1, supplier2) =>
            supplier1.name.toLowerCase() > supplier2.name.toLowerCase() ? 1 : -1
          )
      : []
  }, [diverseSuppliers])

  const filterOption = (props, inputValue) => {
    const { name } = props.data
    const orgName = name.toLowerCase()

    return orgName.includes(inputValue.toLowerCase())
  }

  return (
    <div className='flex items-center'>
      <DirectSpendLabel />

      <div className='flex-auto'>
        {!readOnly && (
          <Select
            inputId='add-direct-spend-input'
            InputValue={selectInput}
            onInputChange={handleSelectInputChange}
            onChange={handleSupplierSelected}
            placeholder={intl.formatMessage(messages.placeholder)}
            options={sortSuppliers}
            getOptionLabel={option => {
              return option.name
            }}
            value={value}
            filterOption={filterOption}
            getOptionValue={opt => opt.id || opt.value}
            noOptionsMessage={inputValue =>
              intl.formatMessage(messages.noOptions)
            }
            controlShouldRenderValue={true}
            isDisabled={readOnly}
            styles={{
              control: base => ({
                ...base,
                height: 28,
                borderRadius: 2,
                ':hover': {
                  borderColor: '#000000'
                }
              }),
              input: base => ({
                ...base,
                fontSize: '.875rem'
              }),
              placeholder: base => ({
                ...base,
                fontSize: '.875rem'
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              noOptionsMessage: base => ({
                ...base,
                fontSize: '.875rem'
              }),
              singleValue: base => ({
                fontSize: '.875rem'
              }),
              option: (base, { isDisabled, isFocused }) => ({
                ...base,
                fontSize: '.875rem',
                backgroundColor: isDisabled
                  ? 'inherit'
                  : isFocused
                  ? '#E0E0E0'
                  : 'inherit'
              })
            }}
            menuPortalTarget={document.body}
          />
        )}
      </div>
    </div>
  )
}

export default AddDiverseSupplierSpendV2
