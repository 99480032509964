import React, { Component, Fragment, ChangeEvent } from 'react'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Avatar from 'shared/components/Avatar'
import UserListItem from 'shared/components/UserListItem'
import SelectCommunity from '../../containers/SelectCommunity'
import SelectAudience from '../../containers/SelectAudience'
import CameraIcon from '@material-ui/icons/CameraEnhance'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Warning from 'shared/components/Warning'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import clientPaths from 'buyer/routes/paths'
import supplierPaths from 'supplier/routes/paths'
import RichTextEditor from 'react-rte'
import loadImage from 'blueimp-load-image'
import Hidden from '@material-ui/core/Hidden'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  IntlShape
} from 'react-intl'
import Switch from 'shared/components/Switch'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import { List } from 'immutable'
import CreatableSelect from 'react-select/creatable'
import { components } from 'react-select'
import InfoOutline from '@material-ui/icons/InfoOutlined'

export const rteMessages = defineMessages({
  normalLabel: {
    id: 'NewPostForm.NormalLabel',
    defaultMessage: 'Normal'
  },
  headingLabel: {
    id: 'NewPostForm.HeadingLabel',
    defaultMessage: 'Heading'
  }
})

const messages = defineMessages({
  startsWithHash: {
    id: 'NewPostForm.StartsWithHash',
    defaultMessage: 'Please start with a # with no space on tag'
  }
})

const reader = new FileReader()

const postImageValidTypes = '.png,.jpg,.jpeg,.svg,.gif'
const ignoreKeysForTags = [
  '~',
  '!',
  '@',
  '$',
  '%',
  '&',
  '+',
  '-',
  '=',
  '?',
  '"',
  "'",
  ':',
  ';',
  '/',
  '\\'
]
type Props = {
  firstName: string
  lastName: string
  profilePictureUrl: string
  role?: string
  onSubmit: Function
  isClientApp?: boolean
  canBroadcast?: boolean
  creatingPost?: boolean
  communities?: List<string>
  onClickCancel?: () => void
  push?: (clientPaths: string) => void
  intl: IntlShape
  trendingTags: List<string>
}

type State = {
  title: string
  body: string
  visibility: string
  postImageUrl: string
  postImageFile: any
  postImageInvalidType: boolean
  creatingPost?: boolean
  hasError: boolean
  broadcast: boolean
  internal: boolean
  rteValue: any
  selectedCommunities: {}
  fileTooBig: boolean
  tags: Array<string>
  msToBlob: boolean
}

const initialState = {
  creatingPost: false,
  title: '',
  body: '',
  selectedCommunities: {},
  communities: [],
  visibility: '',
  postImageUrl: '',
  postImageFile: null,
  postImageInvalidType: false,
  hasError: false,
  broadcast: false,
  internal: false,
  rteValue: RichTextEditor.createEmptyValue(),
  fileTooBig: false,
  tags: [],
  msToBlob: false
}

const maxHashTags = 5

export class NewPostForm extends Component<Props, State> {
  refs: any = { postImage: { value: '' } }
  state: State = {
    ...initialState,
    creatingPost: this.props.creatingPost
  }
  selectInput: any = null

  handleSubmit = e => {
    const { onSubmit } = this.props
    const {
      title,
      rteValue,
      selectedCommunities,
      visibility,
      postImageFile,
      broadcast,
      tags
    } = this.state

    // prevent native submit
    e.preventDefault()

    if (!this.validateForm()) {
      this.setState({
        hasError: true
      })
      return
    }

    // trigger submit
    onSubmit({
      title,
      body: rteValue
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? rteValue.toString('markdown')
        : '',
      community: Object.keys(selectedCommunities),
      visibility,
      postImageFile,
      broadcast,
      tags: tags.length > 0 ? tags : undefined
    })

    this.refs.postImage.value = ''
    this.setState(initialState)
  }

  validateForm = () => {
    const { title, rteValue, postImageFile } = this.state
    return (
      !!title ||
      !!rteValue
        .getEditorState()
        .getCurrentContent()
        .hasText() ||
      !!postImageFile
    )
  }

  collapsePostForm = () => {
    this.setState({
      creatingPost: false
    })
  }

  expandPostForm = () => {
    const { creatingPost } = this.state

    if (!creatingPost) {
      this.setState({
        creatingPost: true,
        title: '',
        rteValue: RichTextEditor.createEmptyValue()
      })
    }
  }

  handleChangeCommunities = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name, checked } = e.target
    const { selectedCommunities } = this.state
    const updateCommunities = Object.assign({}, selectedCommunities)

    if (!checked) {
      delete updateCommunities[value]
    } else {
      updateCommunities[value] = name
    }

    this.setState({
      selectedCommunities: { ...updateCommunities }
    })
  }

  handleChangeTitle = e => {
    this.setState({
      title: e.target.value
    })
  }

  handleRTEChange = value => {
    this.setState({
      rteValue: value
    })
  }

  handleChangeVisibility = e => {
    this.expandPostForm()
    this.setState({
      visibility: e.target.value
    })
  }

  handleClickAddImage = () => {
    this.expandPostForm()
    this.refs.postImage.click()
  }

  handleChangeBroadcast = () => {
    this.setState({
      broadcast: !this.state.broadcast,
      internal: false,
      selectedCommunities: {}
    })
    this.expandPostForm()
  }

  handleChangeInternal = () => {
    this.setState({
      internal: !this.state.internal,
      broadcast: false,
      visibility: '',
      selectedCommunities: {}
    })
    this.expandPostForm()
  }

  handleFileChange = e => {
    const bytesLimit = 4 * 1048576
    const file = e.target.files[0]

    if (file) {
      if (file.size > bytesLimit) {
        this.setState({ fileTooBig: true })
        e.target.value = ''
      } else if (
        !postImageValidTypes
          .split(',')
          .some(fType => file.name.toLowerCase().endsWith(fType))
      ) {
        this.setState({
          postImageInvalidType: true
        })
        e.target.value = ''
      } else {
        this.setState({
          fileTooBig: false,
          postImageInvalidType: false
        })

        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            this.setState({
              postImageUrl: reader.result
            })
          }

          // dont resize if the file is a gif
          if (file.type.endsWith('gif')) {
            this.setState({
              postImageFile: file
            })
          } else {
            loadImage(
              file,
              canvas => {
                const image = this.refs.displayImage
                if (
                  image &&
                  (image.naturalWidth > 1024 || image.naturalHeight > 800)
                ) {
                  const filename = file.name.split('.')
                  if (canvas.toBlob) {
                    canvas.toBlob(blob => {
                      this.setState({
                        postImageFile: new File(
                          [blob],
                          `${filename[0]}.${filename[filename.length - 1]}`,
                          { type: blob.type }
                        )
                      })
                    })
                  } else if (!canvas.toBlob) {
                    this.setState({
                      msToBlob: true
                    })
                  }
                } else {
                  this.setState({
                    postImageFile: file
                  })
                }
              },
              {
                maxWidth: 800,
                maxHeight: 600,
                canvas: true
              }
            )
          }
        }
        reader.readAsDataURL(file)
      }
    }
  }

  handleRemoveImage = () => {
    this.refs.postImage.value = ''
    this.setState({
      postImageUrl: ''
    })
  }

  handleJoinButton = () => {
    const { push, isClientApp } = this.props
    if (push) {
      push(isClientApp ? clientPaths.communities : supplierPaths.communities)
    }
  }

  filterOption = (props: { label: string; data: any }, inputValue: string) => {
    if (props.data.__isNew__) {
      return true
    }
    return (
      this.state.tags.length < maxHashTags &&
      props.label.toUpperCase().includes(inputValue.toUpperCase())
    )
  }

  render() {
    const {
      firstName,
      lastName,
      role,
      profilePictureUrl,
      communities,
      onClickCancel,
      isClientApp,
      canBroadcast,
      intl,
      trendingTags
    } = this.props

    const {
      creatingPost,
      title,
      visibility,
      postImageUrl,
      postImageInvalidType,
      fileTooBig,
      selectedCommunities,
      hasError,
      broadcast,
      internal
    } = this.state

    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'BLOCK_TYPE_DROPDOWN',
        'HISTORY_BUTTONS'
      ],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_DROPDOWN: [
        {
          label: intl.formatMessage(rteMessages.normalLabel),
          style: 'unstyled'
        },
        {
          label: intl.formatMessage(rteMessages.headingLabel),
          style: 'header-three'
        }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
      ]
    }

    return (
      <Fragment>
        {communities && communities.size > 0 ? (
          <form onSubmit={this.handleSubmit} className='h-100'>
            <div className='pa3 dt w-100'>
              {!creatingPost ? (
                <div className='w3 dtc'>
                  <Avatar
                    url={profilePictureUrl}
                    className='w-100'
                    name={`${firstName || ''} ${lastName || ''}`}
                  />
                </div>
              ) : (
                <UserListItem
                  firstName={firstName}
                  lastName={lastName}
                  secondaryText={role}
                  profilePictureUrl={profilePictureUrl}
                />
              )}

              {creatingPost && (
                <div className='flex items-center justify-between mb3'>
                  <Input
                    maxLength={150}
                    placeholder={intl.formatMessage({
                      id: 'NewPostForm.WhatIsTheHeadlinePlaceholder',
                      defaultMessage: 'What is the headline?'
                    })}
                    className='bn'
                    wrapperClassName='w-100'
                    name='title'
                    value={title}
                    onChange={this.handleChangeTitle}
                    required
                  />
                  <IconButton
                    onClick={this.collapsePostForm}
                    style={{ marginLeft: 10 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}

              {creatingPost ? (
                <>
                  <RichTextEditor
                    value={this.state.rteValue}
                    onChange={this.handleRTEChange}
                    toolbarConfig={toolbarConfig}
                    placeholder={
                      <FormattedMessage
                        id='NewPostForm.WriteHerePlaceholder'
                        defaultMessage='Write here to share an article, photo or an idea.'
                      />
                    }
                    editorClassName='react-rte-editor'
                    toolbarClassName='react-rte-toolbar'
                  />
                  <FormattedMessage
                    id='NewPostForm.HashTagsPlaceholder'
                    defaultMessage='Add a hashtag to your post eg. #Covid19. (Maximum {maxHashTags} hashtags)'
                    values={{ maxHashTags }}
                  >
                    {message => (
                      <CreatableSelect
                        name='tags'
                        ref={ref => {
                          this.selectInput = ref
                        }}
                        placeholder={message}
                        className='mt2'
                        isMulti
                        onChange={(newValue: Array<any>, actionMeta: any) => {
                          this.setState({
                            tags: newValue ? newValue.map(t => t.value) : []
                          })
                        }}
                        onKeyDown={e => {
                          const { inputValue, value } = this.selectInput.state
                          if (
                            e.keyCode === 32 &&
                            !ignoreKeysForTags.includes(e.key) &&
                            inputValue.length > 1 &&
                            inputValue[0] === '#'
                          ) {
                            e.preventDefault()
                            let newValue
                            if (value) {
                              newValue = [
                                ...value,
                                {
                                  label: inputValue,
                                  value: inputValue,
                                  __isNew__: true
                                }
                              ]
                            } else {
                              newValue = [
                                {
                                  label: inputValue,
                                  value: inputValue,
                                  __isNew__: true
                                }
                              ]
                            }
                            this.selectInput.onChange(newValue, {
                              action: 'create-option'
                            })
                            this.selectInput.onInputChange('', {
                              action: 'input-change'
                            })
                          } else if (
                            e.keyCode === 32 ||
                            ignoreKeysForTags.includes(e.key) ||
                            (value && value.length === maxHashTags)
                          ) {
                            e.preventDefault()
                          }
                        }}
                        options={
                          trendingTags &&
                          trendingTags.toJS().map(t => ({ label: t, value: t }))
                        }
                        noOptionsMessage={({ inputValue }) => {
                          return inputValue && !inputValue.startsWith('#')
                            ? intl.formatMessage(messages.startsWithHash)
                            : null
                        }}
                        formatCreateLabel={inputValue => {
                          return (
                            <FormattedMessage
                              id='NewPostForm.CreateLabel'
                              defaultMessage='Add {hashtag}'
                              values={{ hashtag: inputValue }}
                            />
                          )
                        }}
                        filterOption={this.filterOption}
                        components={{ Menu }}
                        menuPortalTarget={document.body}
                        isValidNewOption={(
                          inputValue: string,
                          selectValue: [any],
                          selectOptions: [any]
                        ) => {
                          return (
                            !!inputValue &&
                            inputValue.startsWith('#') &&
                            inputValue.length > 1 &&
                            inputValue.indexOf(' ') === -1 &&
                            selectValue.length < maxHashTags
                          )
                        }}
                        styles={{
                          control: base => ({
                            ...base,
                            border: base['border']
                          }),
                          input: base => ({
                            ...base,
                            fontSize: '.875rem'
                          }),
                          placeholder: base => ({
                            ...base,
                            fontSize: '.875rem'
                          }),
                          option: (base, { isDisabled, isFocused }) => ({
                            ...base,
                            fontSize: '.875rem',
                            backgroundColor: isDisabled
                              ? 'inherit'
                              : isFocused
                              ? '#E0E0E0'
                              : 'inherit',
                            ':hover': {
                              ...base[':hover'],
                              backgroundColor: !isDisabled && '#e0e0e0'
                            }
                          }),
                          noOptionsMessage: base => ({
                            ...base,
                            fontSize: '.875rem'
                          }),
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          multiValue: base => ({
                            ...base,
                            background: '#F8F8Fa'
                          }),
                          multiValueRemove: base => ({
                            ...base,
                            ':hover': {
                              backgroundColor: '#DCF6F4'
                            }
                          })
                        }}
                      />
                    )}
                  </FormattedMessage>
                  <Label className='db mv2 mb1 f7 fw6 flex items-center'>
                    <InfoOutline
                      className='v-mid dib'
                      style={{ height: '19px' }}
                    />{' '}
                    <FormattedMessage
                      id='NewPostForm.HashtagNote'
                      defaultMessage='If you post with a hashtag to a public community, it will be visible to everyone in TealBook.'
                    />
                  </Label>
                </>
              ) : (
                <div className='flex items-center justify-between pl3'>
                  <Input
                    name='body'
                    placeholder={intl.formatMessage({
                      id: 'NewPostForm.WriteHerePlaceholder',
                      defaultMessage: 'Write here to share an article, photo or an idea.'
                    })}
                    wrapperClassName='w-100'
                    onClick={this.expandPostForm}
                  />
                  <IconButton
                    onClick={this.expandPostForm}
                    style={{ marginLeft: 10 }}
                  >
                    <img src={ImageArrowDown} alt='Expand' />
                  </IconButton>
                </div>
              )}

              {postImageUrl && (
                <div className='mt2'>
                  <img ref='displayImage' src={postImageUrl} alt='post' />
                  <IconButton
                    onClick={this.handleRemoveImage}
                    className='v-top'
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}

              {postImageInvalidType && (
                <Warning
                  message={
                    <FormattedMessage
                      id='NewPostForm.WarningInvlaidFileType'
                      defaultMessage='Invalid File type. Must be one of the following: {postImageValidTypes}'
                      values={{ postImageValidTypes }}
                    />
                  }
                  className='mt3'
                />
              )}

              {fileTooBig && (
                <Warning
                  message={
                    <FormattedMessage
                      id='NewPostForm.WarningFileIsTooBig'
                      defaultMessage='The file is too big. Maximum file size is 4 MB'
                    />
                  }
                />
              )}
            </div>

            <input
              type='file'
              ref='postImage'
              onChange={this.handleFileChange}
              accept={postImageValidTypes}
              hidden
            />

            {hasError && (
              <Warning
                className='mb2 ml3'
                message={
                  <FormattedMessage
                    id='NewPostForm.invalidForm'
                    defaultMessage='Please enter a title, message or an image'
                  />
                }
              />
            )}

            <div className='bt pa3 b--light-gray cf mb5 mb0-ns flex-ns items-center'>
              <Button secondary onClick={this.handleClickAddImage}>
                <CameraIcon className='mr2' />
                <FormattedMessage
                  id='NewPostForm.AddImageButton'
                  defaultMessage='Add Image'
                />
              </Button>

              <div className='ml3-ns flex-auto mv3 mv0-ns'>
                {canBroadcast && (
                  <div className='mb2'>
                    <Switch
                      label={
                        <FormattedMessage
                          id='NewPostForm.Broadcast'
                          defaultMessage='Broadcast'
                        />
                      }
                      ariaLabel='Broadcast'
                      onChange={this.handleChangeBroadcast}
                      checked={broadcast}
                      labelRight
                    />
                  </div>
                )}
                <Switch
                  label={
                    <FormattedMessage
                      id='NewPostForm.Internal'
                      defaultMessage='Internal'
                    />
                  }
                  ariaLabel='Internal'
                  onChange={this.handleChangeInternal}
                  checked={internal}
                  labelRight
                />
              </div>

              {!broadcast && !internal && (
                <div className='ml3-ns mb3 mb0-ns f5'>
                  <SelectCommunity
                    onChange={this.handleChangeCommunities}
                    value={selectedCommunities}
                  />
                </div>
              )}

              {!internal && communities && communities.size > 0 && (
                <div className='ml3-ns'>
                  <label htmlFor='visibility' className='visuallyhidden'>
                    <FormattedMessage
                      id='NewPostForm.SelectAudience'
                      defaultMessage={'Select an audience:'}
                    />
                  </label>
                  <SelectAudience
                    communityIds={Object.keys(selectedCommunities)}
                    isClientApp={isClientApp}
                    onChange={this.handleChangeVisibility}
                    name='visibility'
                    value={visibility}
                    required
                  />
                </div>
              )}

              <Hidden smDown>
                <div className='ml3-ns'>
                  <Button
                    label={
                      <FormattedMessage
                        id='NewPostForm.PostLabel'
                        defaultMessage='Post'
                      />
                    }
                    autoSize
                    type='submit'
                  />
                </div>
              </Hidden>
            </div>

            <Hidden mdUp>
              <div className='bottom-0 right-0 fixed w-100 bg-white bt b--moon-gray pt2'>
                <DialogActions>
                  <Button type='submit' size='large'>
                    <FormattedMessage
                      id='NewPostForm.PostMaterialButton'
                      defaultMessage='Post'
                    />
                  </Button>
                  <Button onClick={onClickCancel} secondary size='large'>
                    <FormattedMessage id='CancelButton' />
                  </Button>
                </DialogActions>
              </div>
            </Hidden>
          </form>
        ) : (
          <div className='pa3-5'>
            <Text className='dib w-70 v-mid'>
              <FormattedMessage
                id='NewPostForm.JoinACommunityAndStartSharing'
                defaultMessage='Want to make your own announcement or share an idea? Join a community and start sharing.'
              />
            </Text>
            <div className='dib w-30 v-mid tr'>
              <Button
                label={
                  <FormattedMessage
                    id='NewPostForm.JoinNowButton'
                    defaultMessage='Join now'
                  />
                }
                autoSize
                onClick={this.handleJoinButton}
              />
            </div>
          </div>
        )}
        <Dialog
          open={this.state.msToBlob}
          onClose={() => this.setState({ msToBlob: false })}
          fullWidth
        >
          <DialogTitle>
            <FormattedMessage
              id='NewPostForm.BrowserErrorMessage'
              defaultMessage='Operation could not be completed'
            />
          </DialogTitle>
          <DialogContent>
            <Text className='mt3'>
              Please update the browser to the latest version OR change the
              image size
            </Text>
            <Text className='mt3'>
              Acceptable image sizes for your browser:{' '}
            </Text>
            <Text className='mt1 ml3'> - width: less than 1024 pixels</Text>
            <Text className='mt1 ml3'> - length: less than 800 pixels</Text>
          </DialogContent>
          <DialogActions>
            <FormattedMessage
              id='NewPostForm.ErrorMessageClose'
              defaultMessage='Close'
            >
              {label => (
                <Button
                  autoSize
                  label={label}
                  onClick={() => this.setState({ msToBlob: false })}
                />
              )}
            </FormattedMessage>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const Menu = props => {
  const optionSelectedLength = props.getValue().length
  return (
    <components.Menu {...props}>
      {optionSelectedLength < maxHashTags ? (
        props.children
      ) : (
        <div className='ph3 pv2'>
          <FormattedMessage
            id='NewPostForm.MaxHashtags'
            defaultMessage='Maximum of {maxHashTags} hashtags'
            values={{ maxHashTags }}
          />
        </div>
      )}
    </components.Menu>
  )
}

export default injectIntl(NewPostForm)
