import React, { Component, ReactNode } from 'react'
import Label from '../Label'
import Text from '../Text'

type Props = {
  label: ReactNode
  inputComponent: ReactNode
  editMode?: boolean
  required?: boolean
  displayValue?: string
  placeholder?: ReactNode
  labelFor?: string
}

class InputWithLabel extends Component<Props> {
  render() {
    const {
      label,
      placeholder,
      displayValue,
      required,
      editMode,
      inputComponent,
      labelFor
    } = this.props

    return editMode || displayValue ? (
      <>
        <Label required={required} htmlFor={labelFor}>
          {label}
        </Label>
        {editMode ? inputComponent : <Text>{displayValue}</Text>}
      </>
    ) : (
      <Text secondary className='pt3-5'>
        {placeholder}
        {required && <span className='red ml2'>*</span>}
      </Text>
    )
  }
}

export default InputWithLabel
