import React, { useState, useMemo } from 'react'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import numberFormat from 'shared/utils/numberFormat'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import SubCategoryRow from './SubCategoryRow'
import Paper from 'shared/components/Paper'
import Text from 'shared/components/Text'

type Props = {
  isReadOnly?: boolean
  quarter?: string
  date?: string
  initalSubCategories: Array<{
    subCategory: string
    subType?: string
    spend: number
  }>
  onSave?: (subCategories) => void
  onCancel?: () => void
  onRemove?: () => void
  addSummary?: string
}

const SummaryRow = (props: Props) => {
  const {
    quarter,
    date,
    initalSubCategories = [],
    isReadOnly,
    onSave,
    onCancel,
    onRemove,
    addSummary
  } = props

  const header = quarter || moment(date).format('YYYY-MM')
  const [subCategories, setSubCategories] = useState<
    Array<{
      subCategory: string
      subType?: string
      spend: number | undefined
    }>
  >(initalSubCategories)

  const total = useMemo(() => {
    return subCategories?.reduce((total, subCategory) => {
      return total + (subCategory?.spend || 0)
    }, 0)
  }, [subCategories])

  const removeSubCategoryRow = index => {
    subCategories.splice(index, 1)
    setSubCategories([...subCategories])
  }

  const changeSubCategoryRow = (index, prop, value) => {
    subCategories.splice(index, 1, {
      ...subCategories[index],
      [prop]: isNaN(value) ? value : Number(value)
    })
    setSubCategories([...subCategories])
  }

  const addNewCategory = () => {
    setSubCategories([
      ...subCategories,
      {
        subCategory: '',
        spend: undefined
      }
    ])
  }

  const handleSave = () => {
    // consolidate dup sub categories & subTypes
    const cleanSubCategories = subCategories
      .filter(s => s.spend !== undefined && !!s.subCategory)
      .reduce(
        (
          result: Array<{
            subCategory: string
            subType?: string | undefined
            spend: number | undefined
          }>,
          subCategory
        ) => {
          const index = result.findIndex(
            r =>
              r.subCategory === subCategory.subCategory &&
              r.subType === subCategory.subType
          )
          if (index === -1) {
            result.push(subCategory)
          } else {
            ;(result[index].spend as number) += subCategory.spend as number
          }
          return result
        },
        []
      )

    if (!!cleanSubCategories.length && onSave) {
      onSave(cleanSubCategories)
    }
  }

  return (
    <div>
      <Paper className='mt3'>
        {addSummary && (
          <div className='mt3 ml3'>
            <Label>
              <FormattedMessage
                id='LoadSummary.enterAggregate'
                defaultMessage='Enter your aggregate spend information.'
              />
            </Label>
            <Text className='mt4'>
              <FormattedMessage
                id='LoadSummary.completeForm'
                defaultMessage='Complete the form below to add your total spend amount in USD for each diversity category within the calendar quarter.{br}{br}When you’re finished, click {save} and navigate to the {share} tab to allocate diversity spend amounts to your customer.
                  '
                values={{
                  br: <br />,
                  invitation: <span className='b'>Invitation</span>,
                  save: <span className='b'>Save</span>,
                  share: <span className='b'>Share</span>
                }}
              />
            </Text>
          </div>
        )}
        <div className='ph3 pt3 mb3 br3 mt3 mr3 ml3 ba b--black-10 bg-near-white'>
          <div className='mb3'>
            <div className='flex items-center justify-between'>
              <h3 className='flex-auto mb0 mt0 fw6 f5'>{header}</h3>
              <span className='fw6 f5 mr3' data-testid='test_sum'>
                ${numberFormat(total)} USD
              </span>
              {isReadOnly
                ? onRemove && (
                    <Button size='small' caution autoSize onClick={onRemove}>
                      <FormattedMessage
                        id='SummaryRow.Remove'
                        defaultMessage='Remove'
                      />
                    </Button>
                  )
                : ''}
            </div>
            <hr />
            <Label noPadding>
              <FormattedMessage
                id='SummaryRow.DiverseCategory'
                defaultMessage='Diverse Category'
              />
            </Label>
            <div>
              {subCategories?.map((item, index) => {
                return (
                  <SubCategoryRow
                    key={`row-${item.subCategory}-${item.subType}-${index}`}
                    subCategory={item.subCategory}
                    subType={item.subType}
                    spend={item.spend}
                    isReadOnly={isReadOnly}
                    onSubCategoryChange={v =>
                      changeSubCategoryRow(index, 'subCategory', v)
                    }
                    onSubTypeChange={v =>
                      changeSubCategoryRow(index, 'subType', v)
                    }
                    onSpendChange={v => changeSubCategoryRow(index, 'spend', v)}
                    onRemove={() => removeSubCategoryRow(index)}
                  />
                )
              })}
              {!isReadOnly && (
                <Button autoSize onClick={addNewCategory}>
                  <FormattedMessage
                    id='SummaryRow.NewCategory'
                    defaultMessage='New Category'
                  />
                </Button>
              )}
            </div>
          </div>
        </div>
      </Paper>
      {!isReadOnly ? (
        <div className='flex items-center justify-end mt3'>
          <Button size='small' secondary className='mr2' onClick={onCancel}>
            <FormattedMessage id='SummaryRow.Cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            aria-label='Save'
            size='small'
            onClick={handleSave}
            disabled={!subCategories.length}
          >
            <FormattedMessage id='SummaryRow.Save' defaultMessage='Save' />
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default SummaryRow
