import { ComponentType } from 'react'
import { connect } from 'react-redux'
import searchSelectors from '../../../Search/selectors/searchSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import RootState from 'shared/models/RootState'
import {
  updateSupplierReview,
  openAddTagDialog,
  openAddNoteDialog,
  addTagToSupplier,
  removeTagFromSupplier,
  removeLabelFromSupplier
} from '../../../Search/actions'

type Props = {
  supplierId: string
  mini?: boolean
  showSustainabilitySurvey?: boolean
}

const withSearchResultItem = <P>(component: ComponentType<P>) => {
  return connect(
    (state: RootState, props: Props) => {
      const resultItem = searchSelectors.getResultItemBySupplierId(
        state,
        props.supplierId
      )
      const relationship = resultItem.get('relationship')
      const supplier = resultItem.get('supplier')
      const supplierUsers = resultItem.get('supplierUsers')
      const logoUrl =
        supplier.get('logoUrl') ||
        (supplier.get('domains') && supplier.get('domains').size > 0
          ? `https://logo.clearbit.com/${supplier.get('domains').first()}`
          : '')
      const sanctionedCompanyIds = supplier.get('bannedCompanyIds')
      const sanctionedCountry = supplier.get('flagCountry')
      const additional = !!resultItem.get('additional')

      const language = state.get('i18n').locale
      const description = supplier.get('description')

      return {
        id: supplier.get('id'),
        additional,
        name: supplier.get('name'),
        description:
          !description || typeof description === 'string'
            ? description
            : description.get(language) ||
              description.get('en') ||
              description
                .toList()
                .filter((d: string) => !!d)
                .first(),
        reputation: supplier.get('reputation'),
        websiteUrl: supplier.get('websiteUrl'),
        logoUrl,
        supplierType: supplier.get('supplierType'),
        status: supplier.get('status'),
        relationship: resultItem.get('relationship'),
        isPreferred: relationship.getIn(['attributes', 'preferred']),
        isDoNotUse: relationship.getIn(['attributes', 'doNotUse']),
        isRestricted: relationship.getIn(['attributes', 'restricted']),
        isExisting: relationship.getIn(['attributes', 'existing']),
        isNonRecruit: !!relationship.getIn(['attributes', 'nonRecruit']),
        isPrivate: !!relationship.getIn(['attributes', 'privateToClients']),
        isSanctionedCompany:
          sanctionedCompanyIds && sanctionedCompanyIds.size > 0,
        isSanctionedCountry: sanctionedCountry && sanctionedCountry.size > 0,
        sanctionedCountryList: sanctionedCountry,
        attachments: relationship.get('attachments'),
        certifications: supplier.get('certifications'),
        diversityProgram: supplier.get('diversityProgram'),
        tags: resultItem.get('tags'),
        labels: resultItem.get('labels'),
        myCardId: resultItem.get('myCardId'),
        showDetails: searchSelectors.showDetails(state),
        supplierUsers,
        isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
        isSupplierApp: routingSelectors
          .getPathname(state)
          .includes('supplier/'),
        accessControl: sessionSelectors.getAccessControl(state),
        websiteWarning: supplier.get('websiteWarning'),
        sustainabilitySurvey:
          props.showSustainabilitySurvey &&
          supplier.get('sustainabilitySurveyCompleted')
      }
    },
    {
      onRemoveTag: removeTagFromSupplier,
      onRemoveLabel: removeLabelFromSupplier,
      onClickStarsRating: updateSupplierReview,
      onClickAddTag: openAddTagDialog,
      onClickAddNote: openAddNoteDialog,
      onAddTag: addTagToSupplier
    }
    //@ts-ignore
  )(component)
}

export default withSearchResultItem
