import React, { ChangeEvent, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FileInput from 'shared/components/FileInput'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import Input from 'shared/components/Input'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import moment, { Moment } from 'moment'
import communitiesSelectors from 'buyer/Communities/selectors/communitiesSelectors'
import DatePicker from 'shared/components/DatePicker'
import Divider from 'shared/components/Divider'
import { getCommunities } from 'buyer/Communities/actions'
import { generateUserToken } from '../../actions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import Switch from 'shared/components/Switch'

const AppConfigTools = () => {
  const dispatch = useDispatch()
  const [fileUpload, setFileUpload] = useState<File | null>(null)
  const [communityId, setCommunityId] = useState<string>('')
  const [expireTime, setExpireTime] = useState<Date>(
    moment()
      .endOf('day')
      .toDate()
  )
  const [redirectTo, setRedirectTo] = useState<string>('')
  const [createUser, setCreateUser] = useState<boolean>(false)
  const [sustainabilityMagicLink, setSustainabilityMagicLink] = useState<
    boolean
  >(false)
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
  const [isCreateClicked, setIsCreateClicked] = useState<boolean>(false)

  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const isUploading = useSelector((state: RootState) =>
    state.getIn(['admin', 'upload', 'isUploading'])
  )
  const communitiesList = useSelector(communitiesSelectors.getCommunitiesList)

  const isUploadCompleted = isCreateClicked && !isUploading

  useEffect(() => {
    if (!communitiesList.size) {
      dispatch(getCommunities())
    }
  }, [dispatch, communitiesList?.size])

  useEffect(() => {
    if (isUploadCompleted) {
      setIsCreateClicked(false)
      setIsOpenDialog(true)
    }
  }, [isUploadCompleted])

  const handleGenerateTokenClick = () => {
    let normalizeRedirectTo = redirectTo
    if (redirectTo && !redirectTo.startsWith('/')) {
      normalizeRedirectTo = `/${normalizeRedirectTo}`
      setRedirectTo(normalizeRedirectTo)
    }
    dispatch(
      generateUserToken({
        orgUnitId,
        file: fileUpload,
        communityId: sustainabilityMagicLink ? '' : communityId,
        expireTime: expireTime.toISOString(),
        redirectTo: sustainabilityMagicLink ? '' : normalizeRedirectTo,
        createUser: !communityId && !sustainabilityMagicLink && createUser,
        sustainabilityMagicLink
      })
    )
    setIsCreateClicked(true)
  }

  return (
    <>
      <div className='flex items-center'>
        <Label htmlFor='attachedFile' noPadding>
          <FormattedMessage
            id='AppConfigTools.BulkInvitation'
            defaultMessage='Bulk Invitation Links'
          />
        </Label>
        <Tooltip
          title={
            <FormattedMessage
              id='AppConfigTools.BulkInvitationTip'
              defaultMessage='The CSV document must contain headers with email, firstName, lastName, and roles. If not creating a new user, filling in first name, last name, and roles columns are optional. When creating a new user and no roles are entered, if there are default organization roles, they will be applied, else supplier and supplierAdmin roles will be applied. When entering roles use a pipe ie. "buyer | buyerAdmin".'
            />
          }
        >
          <InfoOutlinedIcon className='ml1' />
        </Tooltip>
      </div>
      <FileInput
        accept='.csv'
        className='mt3'
        name='attachedFile'
        limit={1000}
        required
        isUploading={isUploading}
        value={fileUpload ? fileUpload.name : ''}
        onChange={(file: File | null) => {
          setFileUpload(file)
        }}
      />
      <div className='pt3'>
        <Switch
          ariaLabel='create magic link'
          label={
            <FormattedMessage
              id='AppConfigTools.MagicLink'
              defaultMessage='Generate magic link for sustainability survey'
            />
          }
          checked={sustainabilityMagicLink}
          onChange={() => setSustainabilityMagicLink(prevState => !prevState)}
        />
      </div>
      {!sustainabilityMagicLink && (
        <div>
          <Label htmlFor='communityId'>
            <FormattedMessage
              id='AppConfigTools.Community'
              defaultMessage='Community'
            />
          </Label>
          <Select
            name='communityId'
            value={communityId}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              setCommunityId(e.currentTarget.value)
            }
          >
            <FormattedMessage
              id='AppConfigTools.NA'
              defaultMessage='Not Applicable'
            >
              {message => <option value=''>{message}</option>}
            </FormattedMessage>
            {communitiesList?.size &&
              communitiesList.map(community => (
                <option key={community.get('id')} value={community.get('id')}>
                  {community.get('name')}
                </option>
              ))}
          </Select>
        </div>
      )}
      {!communityId && !sustainabilityMagicLink && (
        <div className='pt3'>
          <Switch
            ariaLabel='create new user'
            label={
              <FormattedMessage
                id='AppConfigTools.CreateUser'
                defaultMessage='Create a new user if email does not exist in the database'
              />
            }
            checked={createUser}
            onChange={() => setCreateUser(() => !createUser)}
          />
        </div>
      )}
      <div className='dib w-100 w-25-ns'>
        <Label htmlFor='expireTime'>
          <FormattedMessage
            id='AppConfigTools.ExpireTime'
            defaultMessage='Expiry Date'
          />
        </Label>
        <DatePicker
          noKeyboard
          name='expireTime'
          minDate={new Date()}
          value={expireTime}
          onChange={(newDate: Moment) => {
            setExpireTime(newDate.endOf('day').toDate())
          }}
        />
        <Text secondary className='mt1'>
          <FormattedMessage
            id='AppConfigTools.ExpireTimeHelp'
            defaultMessage='All links will expire at 11:59pm'
          />
        </Text>
      </div>
      {!sustainabilityMagicLink && (
        <div>
          <Label htmlFor='redirectTo'>
            <FormattedMessage
              id='AppConfigTools.RedirectTo'
              defaultMessage='Redirection Link'
            />
          </Label>
          <div className='flex items-center'>
            <Text className='fw6'>https://app.tealbook.com</Text>
            <Input
              name='redirectTo'
              wrapperClassName='ml2 flex-auto'
              value={redirectTo}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setRedirectTo(e.currentTarget.value)
              }
              placeholder='/your/url'
            />
          </div>
          <Text secondary className='mt1'>
            <FormattedMessage
              id='AppConfigTools.RedirectToHelp'
              defaultMessage='Note: If no URL is entered the receiver will be directed to the Tealbook home page. The user will receive an email notification once the links are ready for download.'
            />
          </Text>
        </div>
      )}
      <Divider className='mv3' />
      <div className='flex justify-end'>
        <Button
          onClick={handleGenerateTokenClick}
          disabled={!fileUpload || isUploading}
        >
          <FormattedMessage
            id='AppConfigTools.Create'
            defaultMessage='Create'
          />
        </Button>
      </div>
      <Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
        <DialogTitle>
          <FormattedMessage
            id='AppConfigTools.DialogTitle'
            defaultMessage='Request Complete'
          />
        </DialogTitle>
        <DialogContent>
          <Text>
            <FormattedMessage
              id='AppConfigTools.DialogContent'
              defaultMessage='You will receive email notification once it is ready.'
            />
          </Text>
        </DialogContent>
        <DialogActions>
          <Button autoSize onClick={() => setIsOpenDialog(false)}>
            <FormattedMessage id='Done' defaultMessage='Done' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AppConfigTools
