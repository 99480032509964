import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import ExternalLink from 'shared/components/ExternalLink'
import VideoIcon from '@material-ui/icons/OndemandVideo'
import RssFeedIcon from '@material-ui/icons/RssFeed'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import analytics from 'shared/utils/analytics'

type Props = {
  blogUrl?: string
  videoUrl?: string
}

const logVideoClick = (orgUnitId: string, orgUnitName: string) =>
  analytics.track('Video Clicked', {
    eventSource: 'Supplier Profile',
    action: 'Clicked',
    orgUnitId,
    orgUnitName,
  })

const logBlogClick = (orgUnitId: string, orgUnitName: string) =>
  analytics.track('Blog Clicked', {
    eventSource: 'Supplier Profile',
    action: 'Clicked',
    orgUnitId,
    orgUnitName,
  })

const ResourceLinks: FunctionComponent<Props> = (props) => {
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  return (
    <>
      {props.videoUrl && (
        <>
          <h3 className='f7 fw6 mid-gray ma0 mb2 mt3'>
            <FormattedMessage id='ResourceLinks.Video' defaultMessage='Video' />
          </h3>
          <ExternalLink
            href={props.videoUrl}
            onClick={() => logVideoClick(orgUnitId, orgUnitName)}
          >
            <div className='flex items-center dim'>
              <VideoIcon fontSize='large' />
              <span className='ml2'>
                <FormattedMessage
                  id='ResourceLinks.OpenVideoLink'
                  defaultMessage='Open video'
                />
              </span>
            </div>
          </ExternalLink>
        </>
      )}

      {props.blogUrl && (
        <>
          <h3 className='f7 fw6 mid-gray ma0 mb2 mt3'>
            <FormattedMessage id='ResourceLinks.Blog' defaultMessage='Blog' />
          </h3>
          <ExternalLink
            href={props.blogUrl}
            onClick={() => logBlogClick(orgUnitId, orgUnitName)}
          >
            <div className='flex items-center dim'>
              <RssFeedIcon fontSize='large' />
              <span className='ml2'>
                <FormattedMessage
                  id='ResourceLinks.VisitOurBlogText'
                  defaultMessage='Visit our blog'
                />
              </span>
            </div>
          </ExternalLink>
        </>
      )}
    </>
  )
}

export default ResourceLinks
