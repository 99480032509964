import React, { Component, FunctionComponent, ReactNode } from 'react'
import ErrorPage from '../ErrorPage'

const LogErrors: FunctionComponent<{
  error: Error
  info: { componentStack: string }
}> = () => {
  return <ErrorPage />
}

type Props = {
  children: ReactNode
}

type State =
  | {
      hasError: true
      error: Error
      info: { componentStack: string }
    }
  | {
      hasError: false
    }

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error,
      info
    })
  }

  render() {
    const { children } = this.props

    return this.state.hasError ? (
      <LogErrors error={this.state.error} info={this.state.info} />
    ) : (
      children
    )
  }
}

export default ErrorBoundary
