import { put, select } from 'redux-saga/effects'
import searchSelectors from '../../../buyer/Search/selectors/searchSelectors'
import companySelectors from '../../../supplier/shared/selectors/companySelectors'
import settingsSelectors from '../../../buyer/shared/selectors/settingsSelectors'
import createApiRequestSaga from '../../utils/sagas/createApiRequestSaga'
import { searchSupplier, setFilterOptions } from '../../../buyer/Search/actions'
import qs from 'qs'
import sessionSelectors from 'shared/selectors/sessionSelectors'

export function* preSearchSaga() {
  const isSupplierApp = window.location.pathname?.startsWith('/supplier')
  const userHasBuyerRole = yield select(sessionSelectors.userHasRole, 'buyer')
  const isSearching: boolean = yield select(searchSelectors.isSearching)
  const hasSearched: boolean =
    (yield select(searchSelectors.getResultsCount)) > 0

  if (!userHasBuyerRole && !isSupplierApp) {
    return
  }

  if (!isSearching && !hasSearched) {
    if (!isSupplierApp && userHasBuyerRole) {
      const defaultFilters = yield select(settingsSelectors.getDefaultFilters)
      const filters = Object.assign(
        {
          scope: 'All',
          sort: 'Relevance',
        },
        defaultFilters ? defaultFilters.toJS() : {}
      )
      yield put(setFilterOptions(filters))
      const {
        scope,
        sort,
        ...restFilters
      } = filters
      yield put(
        searchSupplier('', {
          refKey: 'buyerSearch',
          queryString: `?${qs.stringify({
            q: '',
            filter: restFilters,
            scope: scope,
            sort: sort,
          })}`
        })
      )
    } else {
      const fixedFilters = yield select(
        companySelectors.getCompanySupplierField,
        'searchableCommunities'
      )
      if (fixedFilters && fixedFilters.size > 0) {
        const filters = {
          scope: 'All',
          sort: 'Relevance',
          communities: [fixedFilters.toJS()],
        }
        yield put(setFilterOptions(filters))
        yield put(
          searchSupplier('', {
            refKey: 'communitySearch',
            queryString: `?${qs.stringify({
              q: '',
              filter: { communities: [fixedFilters.toJS()] },
              scope: filters.scope,
              sort: filters.sort,
            })}`
          })
        )
      }
    }
  }
}

export default createApiRequestSaga(preSearchSaga)
