import React, { useState, useEffect, ChangeEvent } from 'react'
import Divider from 'shared/components/Divider'
import Select from 'shared/components/Select'
import Button from 'shared/components/Button'
import { BodOutput } from '../../store/reducers/dataQualityReducer/dataQualityReducer'
import { useDispatch } from 'react-redux'
import { confirmColumnMapping } from '../../store/actions'
import { useSelector } from 'react-redux'
import dataQualitySelectors from '../../store/selectors/dataQualitySelectors'

type Props = {
  jobId: string
  type: string
  filename: string
  columnsMapping: BodOutput
  altMapping?: { [key: string]: string }
  altFilename?: string
  altJobId?: string
}

const DataQualityColumnsMapping = (props: Props) => {
  const dispatch = useDispatch()

  const {
    jobId,
    filename,
    columnsMapping,
    type,
    altMapping,
    altFilename,
    altJobId
  } = props

  const [mapping, setMapping] = useState<{ [key: string]: string }>({})
  const isSubmittingColumnsMapping = useSelector(
    type === 'vendor'
      ? dataQualitySelectors.isSubmittingColumnsMappingVendor
      : dataQualitySelectors.isSubmittingColumnsMappingSpend
  )

  useEffect(() => {
    // set initial mapping
    if (columnsMapping) {
      const initMap = columnsMapping
        .entrySeq()
        .toJS()
        .reduce((map, entry) => {
          map[entry[0]] = entry[1]['Proposed Column Map'] || ''
          return map
        }, {})
      setMapping(initMap)
    }
  }, [columnsMapping])

  const handleMappingChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const key = e.currentTarget.name
    const value = e.currentTarget.value
    setMapping(Object.assign({}, mapping, { [key]: value }))
  }

  const handleConfirmMapping = () => {
    if (altMapping && altJobId && altFilename) {
      dispatch(
        confirmColumnMapping({
          jobId: jobId,
          filename: filename,
          type: type,
          ...{
            spend: mapping,
            vendor: altMapping,
            vmJobId: altJobId,
            vmFilename: altFilename
          }
        })
      )
    } else {
      dispatch(
        confirmColumnMapping({
          jobId: jobId,
          filename: filename,
          type: type,
          ...mapping
        })
      )
    }
  }

  return (
    <>
      <Divider className='mv3' />
      <label className='db mb1 f7 fw6'>{type} Columns Mapping</label>
      {columnsMapping
        .entrySeq()
        .sort(([key1], [key2]) => {
          return key1 > key2 ? 1 : -1
        })
        .map(([key, value]) => (
          <div key={key} className='flex items-center mv2'>
            <div className='w-40'>
              <label htmlFor={key} className={mapping[key] ? '' : 'red'}>
                {key}
              </label>
            </div>
            <div className='flex-auto'>
              <Select
                fullWidth
                name={key}
                value={mapping[key]}
                onChange={handleMappingChange}
              >
                <option value=''>None</option>
                {/* <option value=''>Select the correct column mapping</option> */}
                {value.get('Possible columns').map((option, index) => (
                  <option key={`${key}-${index}`} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        ))}
      <div className='mt3'>
        <Button
          autoSize
          onClick={handleConfirmMapping}
          disabled={isSubmittingColumnsMapping}
        >
          Confirm {type} Mapping
        </Button>
      </div>
    </>
  )
}

export default DataQualityColumnsMapping
