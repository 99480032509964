import React, { useState, useEffect } from 'react'
import SurveyBanner from './SurveyBanner'
import EsgNav, { getCurrentPosAndNextPathname } from './EsgNav'
import EsgFooter from './EsgFooter'
import { FormattedMessage } from 'react-intl'
import paths from '../../../routes/paths'
import { Switch, Route, Redirect } from 'react-router-dom'
import DiversityInclusion from './DiversityInclusion'
import Environment from './Environment'
import Governance from './Governance'
import HealthSafety from './HealthSafety'
import Privacy from './Privacy'
import { saveSurvey, loadSurveyResponse } from '../../actions'
import surveySelectors from '../../selectors/surveySelectors'
import { useSelector, useDispatch } from 'react-redux'
import ReadonlyHealthSafety from 'buyer/SupplierProfile/components/EsgSurvey/HealthSafety'
import ReadonlyEnvironment from 'buyer/SupplierProfile/components/EsgSurvey/Environment'
import ReadonlyDiversityInclusion from 'buyer/SupplierProfile/components/EsgSurvey/DiversityInclusion'
import ReadonlyGovernance from 'buyer/SupplierProfile/components/EsgSurvey/Governance'
import ReadonlyPrivacy from 'buyer/SupplierProfile/components/EsgSurvey/Privacy'
import RootState from 'shared/models/RootState'
import CollaborateInviteDialogContainer from '../../containers/CollaborateInviteDialogContainer'
import SurveyInstructions from './SurveyInstructions'
import { AccessControl } from 'shared/models/AccessControl'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { useHistory, useLocation } from 'react-router'
import CongratsSurveyCompleteDialog from '../../../Company/components/CongratsSurveyCompleteDialog'
import Loading from 'shared/components/Loading'
import Text from 'shared/components/Text'

const EsgSurvey = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const isLoadingSurvey = useSelector(surveySelectors.isLoading)
  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const surveyDaysRemaining = useSelector(
    surveySelectors.getEsgSurveyDaysRemaining
  )
  const hasSubmitted = useSelector((state: RootState) =>
    surveySelectors.hasSubmitted(state, surveyId)
  )
  const accessControl: AccessControl = useSelector(
    sessionSelectors.getAccessControl
  )

  const [showInstructions, setShowInstructions] = useState<boolean>(false)

  const handleExitInstructionsPage = () => {
    setShowInstructions(false)
  }
  const [currentPos, nextPathname] = getCurrentPosAndNextPathname(
    location.pathname
  )
  useEffect(() => {
    // show instruction if no surveyId or direct link to survey
    if (!surveyId || accessControl.access === 'esgSurvey') {
      setShowInstructions(true)
    }

    // save survey container if it does not exist
    if (!isLoadingSurvey && !surveyId) {
      dispatch(saveSurvey({ type: 'esg' }))
    } else if (surveyId) {
      dispatch(loadSurveyResponse(surveyId))
    }
  }, [dispatch, surveyId, accessControl.access, isLoadingSurvey])
  if (isLoadingSurvey) {
    return <Loading />
  }

  const handleNextClick = () => {
    if (currentPos < 5) {
      history.push({
        pathname: nextPathname,
        state: {
          ...location.state,
          [location.pathname]: true
        }
      })
    }
  }
  const isVisited = location.state ? location.state[location.pathname] : false

  return (
    <>
      {!isNaN(surveyDaysRemaining) && surveyDaysRemaining > 0 && (
        <div className='bg-light-gray tc pv1'>
          {surveyDaysRemaining <= 1 && (
            <Text className='fw6 dark-red'>
              <FormattedMessage
                id='EsgSurvey.OneDayRemaining'
                defaultMessage='Survey due in 1 day'
              />
            </Text>
          )}
          {surveyDaysRemaining >= 1 && (
            <Text className='fw6'>
              <FormattedMessage
                id='EsgSurvey.DaysRemaining'
                defaultMessage='Survey due in {daysLeft} days'
                values={{ daysLeft: surveyDaysRemaining }}
              />
            </Text>
          )}
        </div>
      )}
      <div className='mb3 mw8 center'>
        <SurveyBanner
          title={
            <FormattedMessage
              id='EsgSurvey.Title'
              defaultMessage={'Sustainability Survey'}
            />
          }
          showCloseButton={hasSubmitted}
          showInstructions={showInstructions}
          visitedPages={location.state}
        />
      </div>
      {showInstructions && (
        <SurveyInstructions
          needVerification={accessControl.access === 'esgSurvey'}
          handleExitInstructionsPage={handleExitInstructionsPage}
        />
      )}
      {!showInstructions && (
        <>
          <EsgNav />
          {surveyId && (
            <div className='mb3 mw8 center'>
              <Switch>
                <Route
                  path={paths.healthSafety}
                  render={props =>
                    hasSubmitted ? (
                      <ReadonlyHealthSafety />
                    ) : (
                      <HealthSafety showMissing={isVisited} />
                    )
                  }
                />
                <Route
                  path={paths.environment}
                  render={props =>
                    hasSubmitted ? (
                      <ReadonlyEnvironment />
                    ) : (
                      <Environment showMissing={isVisited} />
                    )
                  }
                />
                <Route
                  path={paths.diversityInclusion}
                  render={props =>
                    hasSubmitted ? (
                      <ReadonlyDiversityInclusion />
                    ) : (
                      <DiversityInclusion showMissing={isVisited} />
                    )
                  }
                />
                <Route
                  path={paths.governance}
                  render={props =>
                    hasSubmitted ? (
                      <ReadonlyGovernance />
                    ) : (
                      <Governance showMissing={isVisited} />
                    )
                  }
                />
                <Route
                  path={paths.privacy}
                  render={props =>
                    hasSubmitted ? (
                      <ReadonlyPrivacy />
                    ) : (
                      <Privacy showMissing={isVisited} />
                    )
                  }
                />
                <Redirect to={`${paths.healthSafety}${location.search}`} />
              </Switch>
            </div>
          )}
          {!hasSubmitted && <EsgFooter handleNextClick={handleNextClick} />}
        </>
      )}
      <CollaborateInviteDialogContainer />
      <CongratsSurveyCompleteDialog />
    </>
  )
}

export default EsgSurvey
