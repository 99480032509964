import React from 'react'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as Tealbot } from 'shared/assets/images/Tealbot-Celebratory.svg'
import Button from 'shared/components/Button'

type Props = {
  onClose: (redirect?: boolean) => void
}

const GeneralMessage = (props: Props) => {
  const { onClose } = props

  return (
    <>
      <div className='tc'>
        <div className='dib w-100 w-70-ns'>
          <Tealbot />
        </div>
      </div>
      <div className='tc mb3'>
        <Typography variant='h5'>
          <FormattedMessage
            id='CongratsSurveyDialog.Header'
            defaultMessage={'Congratulations'}
          />
        </Typography>
        <p className='lh-copy f5 fw4 mid-gray ma3'>
          <FormattedMessage
            id='CongratsSurveyDialog.Body1'
            defaultMessage={'You have completed the Sustainability Survey!'}
          />
        </p>
        <p className='lh-copy f5 fw4 mid-gray ma3'>
          <FormattedMessage
            id='CongratsSurveyDialog.Body2'
            defaultMessage={`Customers and prospects can now view and learn more about your organization's sustainability initiatives.`}
          />
        </p>
        <Button autoSize onClick={() => onClose()} aria-label='Close Dialog'>
          <div className='dib ph4'>
            <FormattedMessage
              id='CongratsSurveyDialog.Done'
              defaultMessage={'Done'}
            />
          </div>
        </Button>
      </div>
    </>
  )
}

export default GeneralMessage
