import React, { Component } from 'react'
import { connect } from 'react-redux'
import cardsSelectors from '../../selectors/cardsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { connectToSupplier } from '../../actions'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import analytics from 'shared/utils/analytics'

type Props = {
  orgUnitId: string
  orgUnitName: string
  isFollowing: boolean
  isTealbot: boolean
  connectToSupplier: (arg: { supplierId: string; fromSearch: boolean }) => void
} & ContainerProps

export class ConnectContainer extends Component<Props> {
  handleOnClick = () => {
    const {
      orgUnitId,
      orgUnitName,
      supplierId,
      connectToSupplier,
      supplierName,
    } = this.props
    connectToSupplier({ supplierId, fromSearch: true })

    analytics.track('Supplier Followed', {
      eventSource: 'Supplier Search',
      action: 'Followed',
      supplierId: supplierId,
      supplierName: supplierName,
      orgUnitId,
      orgUnitName,
    })
  }

  render() {
    const { isFollowing, isTealbot } = this.props

    return (
      <div className='dib v-mid'>
        {isFollowing ? (
          <Button
            className='ma0 f8 fw4 teal dib v-mid mr1'
            variant='text'
            style={{ height: '25px' }}
            ariaDisabled
            disableRipple
          >
            <FormattedMessage
              id='ConnectContainer.Following'
              defaultMessage='Following'
            />
          </Button>
        ) : !isTealbot ? (
          <Button
            className='ma0 f8 fw4 gray dib v-mid hover-teal pointer mr1'
            variant='text'
            onClick={this.handleOnClick}
            style={{ height: '25px' }}
          >
            <FormattedMessage
              id='ConnectContainer.Follow'
              defaultMessage='Follow'
            />
          </Button>
        ) : null}
      </div>
    )
  }
}
type ContainerProps = {
  supplierId: string
  supplierName: string
}
export default connect(
  (state, props: ContainerProps) => ({
    orgUnitId: sessionSelectors.getOrgUnitId(state),
    orgUnitName: orgsSelectors.getCurrentUserOrgUnitName(state),
    isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
    isFollowing: cardsSelectors
      .getBySupplier(state, props.supplierId)
      .get('connected'),
  }),
  {
    connectToSupplier,
  }
)(ConnectContainer)
