export const numberFormat = (number, decimal = 0) => {
  const value = parseFloat(number?.toString())
  return typeof value === 'number'
    ? value.toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      })
    : value
}

export default numberFormat
