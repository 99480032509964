import React, {
  ReactElement,
  ChangeEvent,
  useCallback,
  useState,
  useEffect
} from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'
import { useSelector, useDispatch } from 'react-redux'
import { saveSurveyResponse } from '../../actions'
import RootState from 'shared/models/RootState'
import surveySelectors from '../../selectors/surveySelectors'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Text from 'shared/components/Text'
import {
  noOptions,
  preferNotToAnswerOptions
} from '../ParentQuestion/ParentQuestion'

const messages = defineMessages({
  placeholder: {
    id: 'TextQuestion.specify',
    defaultMessage: 'Please provide detail'
  }
})

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  question?: string | ReactElement<FormattedMessage>
  placeholder?: string
  className?: string
  fieldName?: 'answer' | 'reason'
  fieldType?: 'input' | 'textarea'
}

const TextQuestion = (props: Props) => {
  const intl = useIntl()
  const {
    pageId,
    parentQuestionId,
    questionId,
    question,
    placeholder,
    className,
    fieldName = 'answer',
    fieldType = 'input'
  } = props
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState<string>('')
  const [initialized, setInitialized] = useState<boolean>(false)
  const debouncedSaveSurveyResponse = useDebouncedCallback((payload: any) => {
    dispatch(saveSurveyResponse(payload))
  }, 500)

  const surveyId = useSelector(surveySelectors.getEsgSurveyId)
  const response = useSelector((state: RootState) => {
    return surveySelectors.getResponseDetailByIds(
      state,
      pageId,
      questionId,
      parentQuestionId
    )
  })

  let stateFieldValue = ''
  if (
    response &&
    !(
      noOptions.includes(response.get(fieldName, '')) ||
      preferNotToAnswerOptions.includes(response.get(fieldName, ''))
    )
  ) {
    stateFieldValue = response.get(fieldName, '')
  }

  useEffect(() => {
    if (!initialized) {
      setInputValue(stateFieldValue || '')
      setInitialized(true)
    }
  }, [stateFieldValue, initialized])

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.currentTarget

      setInputValue(value)
      debouncedSaveSurveyResponse.callback({
        surveyId,
        surveyResponseDetailId: response?.get('id'),
        pageId,
        questionId,
        parentQuestionId,
        [fieldName]: value,
        answerType: 'text',
        answerSources: ['customText']
      })
    },
    [
      debouncedSaveSurveyResponse,
      fieldName,
      pageId,
      parentQuestionId,
      questionId,
      response,
      surveyId
    ]
  )

  return (
    <div className={className}>
      {question && <Text className='fw6'>{question}</Text>}
      {fieldType === 'input' ? (
        <Input
          wrapperClassName='mt1'
          placeholder={placeholder || intl.formatMessage(messages.placeholder)}
          value={inputValue}
          onChange={handleInputChange}
        />
      ) : (
        <TextArea
          placeholder={placeholder || intl.formatMessage(messages.placeholder)}
          value={inputValue}
          onChange={handleInputChange}
          maxLength={1000}
        />
      )}
    </div>
  )
}

export default TextQuestion
