import React, { MouseEvent } from 'react'
import OrgLogo from 'shared/components/OrgLogo'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../routes/paths'
import supplierPaths from 'supplier/routes/paths'
import Link from 'shared/components/Link'
import SupplierStatus from '../../../shared/components/SupplierStatus'
import SpendGauge from '../../../shared/components/SpendGauge'
import SpendTrend from '../../../shared/components/SpendTrend'
import ConnectionsStackContainer from 'buyer/shared/containers/ConnectionsStackContainer'
import StarsRating from 'shared/components/StarsRating'
import ConnectContainer from 'buyer/shared/containers/ConnectContainer'
import AddSupplierListItemContainer from 'buyer/shared/containers/AddSupplierListItemContainer'
import SupplierListDetails from '../../../shared/components/SupplierListItem/SupplierListDetails'
import { List, RecordOf, Map } from 'immutable'
import Certification from 'shared/models/Certification'
import Hidden from '@material-ui/core/Hidden'
import SupplierListItemIcons from '../../../shared/components/SupplierListItem/SupplierListItemIcons'
import Reputation from 'shared/components/Reputation'
import { WebsiteWarningType } from 'shared/components/WebsiteWarning'
import { FormattedMessage } from 'react-intl'
import { AccessControl } from 'shared/models/AccessControl'
import useUserAccess from 'shared/utils/useUserAccess'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'

type Props = {
  id: string
  name: string
  additional?: boolean
  logoUrl: string
  description: string
  reputation: number
  websiteUrl: string
  vetId?: string
  searchId: string
  relationship: RecordOf<{
    spend: {
      recentAmount: number
      recentPercentile: number
      recentTrend: number
      year: number
    }
    relationshipRating: number
  }>
  onAddTag: (param: {
    supplierId: string
    cardId: string
    tagsFieldName: string
    newTag: string
  }) => void
  onRemoveTag: (param: {
    supplierId: string
    cardId: string
    removeTag: string
  }) => void
  onRemoveLabel: (param: {
    fieldName: string
    tagToRemove: string
    supplierId: string
  }) => void
  isCurrentSupplier?: boolean
  redirectFrom?: string
  isPreferred?: boolean
  isDoNotUse?: boolean
  isRestricted?: boolean
  isExisting?: boolean
  isNonRecruit?: boolean
  isPrivate?: boolean
  isSanctionedCompany?: boolean
  isSanctionedCountry?: boolean
  sanctionedCountryList?: List<string>
  attachments?: List<
    RecordOf<{
      createdBy?: string
      createdDate?: string
      documentFileUrl?: string
      expirationDate?: string
      type?: string
    }>
  >
  diversityProgram?: boolean
  sustainabilitySurvey?: boolean
  websiteWarning?: WebsiteWarningType
  status: string
  certifications?: List<[string, List<RecordOf<Certification>>]>
  myCardId: string
  tags?: List<
    RecordOf<{
      key: string
      name: string
      count: number
      mine: boolean
      label: boolean
    }>
  >
  labels?: List<RecordOf<{ key: string; name: string }>>
  isTealbot?: boolean
  supplierUsers?: List<
    RecordOf<{
      id: string
      firstName: string
      lastName: string
      email: string
      blocked?: boolean
    }>
  >
  onClickStarsRating: (param: {
    supplierId: string
    supplierName: string
  }) => void
  onClickAddTag: (param: { supplierId: string; cardId: string }) => void
  onClickAddNote: (param: { supplierId: string; cardId: string }) => void
  isSupplierApp?: boolean
  accessControl: AccessControl
  attachmentTypes: Map<string, Map<string, string>>
  relationshipTypes: Map<string, Map<string, string>>
}

const SupplierListItem = (props: Props) => {
  const {
    id,
    name,
    description,
    reputation,
    websiteUrl,
    isPreferred,
    isDoNotUse,
    isRestricted,
    isExisting,
    isNonRecruit,
    attachments,
    isPrivate,
    isSanctionedCountry,
    isSanctionedCompany,
    sanctionedCountryList,
    logoUrl,
    redirectFrom,
    vetId,
    searchId,
    relationship,
    status,
    certifications,
    onAddTag,
    onRemoveTag,
    onRemoveLabel,
    myCardId,
    tags,
    labels,
    isTealbot,
    supplierUsers,
    websiteWarning,
    isSupplierApp,
    accessControl,
    diversityProgram,
    sustainabilitySurvey,
    additional,
    attachmentTypes,
    relationshipTypes,
    onClickStarsRating,
    onClickAddTag,
    onClickAddNote
  } = props

  const userAccess = useUserAccess() as LicenseAccess

  const handleClickStarsRating = (e: MouseEvent<HTMLButtonElement>) => {
    onClickStarsRating({
      supplierId: id,
      supplierName: name
    })
  }

  const handleClickAddTag = () => {
    onClickAddTag({
      supplierId: id,
      cardId: myCardId
    })
  }

  const handleClickAddNote = () => {
    onClickAddNote({
      supplierId: id,
      cardId: myCardId
    })
  }

  const queryString = `?${searchId ? `searchId=${searchId}&` : ''}${
    vetId ? `vetId=${vetId}&` : ''
  }redirectFrom=${redirectFrom}`
  const profileUrl = `${parsePath(
    isSupplierApp ? supplierPaths.supplierProfile : paths.supplierProfile,
    {
      supplierId: id
    }
  )}${queryString}`
  const myCompanyUrl = `${parsePath(
    isSupplierApp ? supplierPaths.supplierProfile : paths.supplierProfile,
    {
      supplierId: id
    }
  )}/mycompany${queryString}`

  return (
    <div className='bg-white bb b--black-10 w-100'>
      <div className={`flex pa3`}>
        <div className='v-top'>
          <div
            className='pa1 pointer v-mid ba b--black-10 br2 bg-white flex items-center'
            style={{
              height: '50px',
              width: '50px',
              minWidth: '50px',
              maxHeight: '50px'
            }}
          >
            <Link to={profileUrl} aria-label={`Link to ${name}'s Profile`}>
              <OrgLogo url={logoUrl} />
            </Link>
          </div>
          {!isSupplierApp &&
            accessControl.access === 'full' &&
            reputation !== undefined &&
            reputation > 40 && (
              <Reputation
                reputation={reputation}
                linkTo={`${profileUrl}&scrollToReference=true`}
              />
            )}
          <div className='fl-ns absolute-ns'>
            <SupplierStatus
              status={status}
              websiteWarning={websiteWarning}
              mini
            />
          </div>
        </div>
        <div className='flex-auto w-100'>
          <div className='dib v-top pr3-ns pl3 w-60 w-70-ns'>
            <Link to={profileUrl} aria-label={`Link to ${name}'s Profile`}>
              <h5
                className={`f5 ma0 dib fw6 underline-hover hover-teal v-mid mr2`}
                style={{ wordBreak: 'break-word' }}
              >
                {name}
              </h5>
            </Link>

            <Hidden smDown>
              <SupplierListItemIcons
                disableActions={
                  isSupplierApp || accessControl.access === 'punchout'
                }
                profileUrl={profileUrl}
                name={name}
                myCompanyUrl={myCompanyUrl}
                isPreferred={isPreferred}
                isDoNotUse={isDoNotUse}
                isRestricted={isRestricted}
                isExisting={isExisting}
                isNonRecruit={isNonRecruit}
                attachments={attachments}
                isPrivate={isPrivate}
                isSanctionedCountry={isSanctionedCountry}
                isSanctionedCompany={isSanctionedCompany}
                sanctionedCountryList={sanctionedCountryList}
                certifications={certifications}
                supplierUsers={supplierUsers}
                websiteWarning={websiteWarning}
                diversityProgram={diversityProgram}
                attachmentTypes={attachmentTypes}
                relationshipTypes={relationshipTypes}
                sustainabilitySurvey={sustainabilitySurvey}
              />
            </Hidden>

            <div className='mt2 v-mid w-100'>
              <Hidden mdUp>
                <SupplierListItemIcons
                  disableActions={
                    isSupplierApp || accessControl.access === 'punchout'
                  }
                  profileUrl={profileUrl}
                  name={name}
                  myCompanyUrl={myCompanyUrl}
                  isPreferred={isPreferred}
                  isDoNotUse={isDoNotUse}
                  isRestricted={isRestricted}
                  isExisting={isExisting}
                  isNonRecruit={isNonRecruit}
                  attachments={attachments}
                  isPrivate={isPrivate}
                  isSanctionedCountry={isSanctionedCountry}
                  isSanctionedCompany={isSanctionedCompany}
                  sanctionedCountryList={sanctionedCountryList}
                  certifications={certifications}
                  supplierUsers={supplierUsers}
                  websiteWarning={websiteWarning}
                  diversityProgram={diversityProgram}
                  attachmentTypes={attachmentTypes}
                  relationshipTypes={relationshipTypes}
                  sustainabilitySurvey={sustainabilitySurvey}
                />
              </Hidden>
            </div>
            {additional && (
              <div className='dib br2 ba b--light-silver f9 gray pa1'>
                <FormattedMessage
                  id='SupplierListItemMini.RelatedOrganization'
                  defaultMessage='Related Organization'
                />
              </div>
            )}
            {!isSupplierApp && accessControl.access === 'full' && (
              <div className='mt2 mb1 v-mid w-100'>
                <ConnectContainer supplierId={id} supplierName={name} />
                <AddSupplierListItemContainer vetId={vetId} supplierId={id} />
              </div>
            )}
          </div>

          {!isSupplierApp && accessControl.access === 'full' && (
            <div className='dib v-top pl0 w-30-ns w-40 tr'>
              <ConnectionsStackContainer supplierId={id} mini rightAlign />

              <div className='mt1'>
                {userAccess.accessSupplierSpend &&
                  relationship.getIn(['spend', 'recentPercentile']) !== 0 && (
                    <SpendGauge
                      recentPercentile={relationship.getIn([
                        'spend',
                        'recentPercentile'
                      ])}
                      recentAmount={relationship.getIn([
                        'spend',
                        'recentAmount'
                      ])}
                      linkTo={`${myCompanyUrl}&scrollToAttribute=true`}
                      supplierName={name}
                    />
                  )}

                {userAccess.accessSupplierSpend &&
                  relationship.getIn(['spend', 'recentTrend']) !== 0 && (
                    <SpendTrend
                      recentTrend={relationship.getIn(['spend', 'recentTrend'])}
                      linkTo={`${myCompanyUrl}&scrollToAttribute=true`}
                      supplierName={name}
                    />
                  )}

                <StarsRating
                  value={relationship.get('relationshipRating') || 0}
                  mini
                  onClick={!isTealbot ? handleClickStarsRating : undefined}
                />
              </div>
            </div>
          )}
          {accessControl.access === 'punchout' && (
            <div className='dib v-top pl0 w-30-ns w-40 tr'>
              <AddSupplierListItemContainer
                vetId={vetId}
                supplierId={id}
                buttonStyle
                showRemove
              />
            </div>
          )}
          <div className='pl3 v-top w-100'>
            <SupplierListDetails
              supplierId={id}
              cardId={myCardId}
              description={description}
              website={websiteUrl}
              tags={tags}
              labels={labels}
              addTagToSupplier={onAddTag}
              removeTagFromSupplier={onRemoveTag}
              removeLabelFromSupplier={onRemoveLabel}
              onAddTagsClick={handleClickAddTag}
              onAddNoteClick={handleClickAddNote}
              isTealbot={isTealbot}
              disableActions={
                isSupplierApp || accessControl.access === 'punchout'
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupplierListItem
