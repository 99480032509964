import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'
import { List } from 'immutable'

export const loadInsights = createAction(actionTypes.LOAD_INSIGHTS)
export const loadSpend = createAction(actionTypes.LOAD_SPEND)
export const loadSpendDetail = createAction(actionTypes.LOAD_SPEND_DETAIL)
export const getSpendYears = createAction(actionTypes.GET_SPEND_YEARS)

type GenerateDiversityReportPayload = {
  startDate: string
  endDate: string
  preferred?: boolean
  countries?: string[]
  categories?: string[]
  spendgroups?: string[]
  reduce?: boolean
  historical?: boolean
}
export const generateDiversityReport = createAction<
  GenerateDiversityReportPayload
>(actionTypes.GENERATE_DIVERSITY_REPORT)

export const loadReducedDiversityReport = createAction<
  GenerateDiversityReportPayload
>(actionTypes.LOAD_REDUCED_DIVERSITY_REPORT)

export const filterOverviewCategories = createAction<List<string>>(
  actionTypes.FILTER_OVERVIEW_CATEGORIES
)

export const setMatchAnyCategories = createAction<boolean>(
  actionTypes.SET_MATCH_ANY_CATEGORIES
)

export const selectDiversityCategory = createAction<string | ''>(
  actionTypes.SELECT_DIVERSITY_CATEGORY
)
export const setDiversityCategory = createAction<string | ''>(
  actionTypes.SET_DIVERSITY_CATEGORY
)
export const selectAllDiversityCategories = createAction<List<string | ''>>(
  actionTypes.SELECT_ALL_DIVERSITY_CATEGORIES
)

export const clearAllDiversityCategories = createAction(
  actionTypes.CLEAR_ALL_DIVERSITY_CATEGORIES
)

export const selectDiversityAuthority = createAction<List<string>>(
  actionTypes.SELECT_DIVERSITY_AUTHORITY
)

export const loadConsolidation = createAction(actionTypes.LOAD_CONSOLIDATION)

export const setSpendCategory = createAction(actionTypes.SET_SPEND_CATEGORY)
export const setSpendCountry = createAction(actionTypes.SET_SPEND_COUNTRY)
export const setSpendSpendGroup = createAction(
  actionTypes.SET_SPEND_SPEND_GROUP
)
export const setUseSpendGroup = createAction(actionTypes.USE_SPEND_GROUP)
export const setOnlyPreferred = createAction(actionTypes.ONLY_PREFERRED)
export const setOnlyDiverse = createAction(actionTypes.ONLY_DIVERSE)

export const getTier2Range = createAction(actionTypes.GET_TIER2_RANGE)
export const getTier2Agencies = createAction(actionTypes.GET_TIER2_AGENCIES)
export const loadTier2Data = createAction(actionTypes.LOAD_TIER2_DATA)
export const selectTier2Category = createAction(
  actionTypes.SELECT_TIER2_CATEGORY
)
export const selectAllTier2Categories = createAction(
  actionTypes.SELECT_ALL_TIER2_CATEGORIES
)
export const clearAllTier2Categories = createAction(
  actionTypes.CLEAR_ALL_TIER2_CATEGORIES
)

export const loadTier2SupplierList = createAction(
  actionTypes.LOAD_TIER2_SUPPLIER_LIST
)
export const searchTier2Suppliers = createAction(
  actionTypes.SEARCH_TIER2_SUPPLIERS
)
export const createTier2Community = createAction(
  actionTypes.CREATE_TIER2_COMMUNITY
)
export const inviteTier2Group = createAction(actionTypes.INVITE_TIER2_GROUP)
export const exportTier2SupplierTable = createAction(
  actionTypes.EXPORT_TIER2_SUPPLIER_TABLE
)
export const setCustomRulesType = createAction(
  actionTypes.SET_CUSTOM_RULES_TYPE
)
export const changeDiversityQualificationRules = createAction(
  actionTypes.CHANGE_DIVERSITY_QUALIFICATION_RULES
)

export const changeGrouping = createAction(actionTypes.CHANGE_GROUPING)
export const selectGroupingValue = createAction(
  actionTypes.SELECT_GROUPING_VALUE
)
export const clearGroupingValue = createAction(actionTypes.CLEAR_GROUPING_VALUE)

export const getSnapshotList = createAction(actionTypes.GET_SNAPSHOT_LIST)
export const getSnapshot = createAction(actionTypes.GET_SNAPSHOT)
export const getSnapshotLineItems = createAction(
  actionTypes.GET_SNAPSHOT_LINE_ITEMS
)
export const deleteSnapshot = createAction(actionTypes.DELETE_SNAPSHOT)
export const bulkDeleteSnapshots = createAction(
  actionTypes.BULK_DELETE_SNAPSHOTS
)
export const saveSnapshot = createAction(actionTypes.SAVE_SNAPSHOT)

export const loadSustainabilityReportOverview = createAction(
  actionTypes.LOAD_SUSTAINABILITY_REPORT_OVERVIEW
)
export const toggleEsgReportQuestion = createAction(
  actionTypes.TOGGLE_ESG_REPORT_QUESTION
)
export const collapseAllEsgReportQuestions = createAction(
  actionTypes.COLLAPSE_ALL_ESG_REPORT_QUESTIONS
)
export const getEsgReportPage = createAction(actionTypes.GET_ESG_REPORT_PAGE)

export const getBulkEsgSuppliers = createAction(
  actionTypes.GET_BULK_ESG_SUPPLIERS
)
export type CreateBulkOutreachPayload = {
  suppliers: Array<{
    id: string
    name: string
    requestedContactId?: string
  }>
  outreachType: string
}
export const createBulkOutreach = createAction<CreateBulkOutreachPayload>(
  actionTypes.CREATE_BULK_OUTREACH
)

export const getOutreachList = createAction(actionTypes.GET_OUTREACH_LIST)

type SustainabilitySpendReportPayload = {
  startDate: string
  endDate: string
  reduce?: boolean
}
export const loadSustainabilitySpendReport = createAction<
  SustainabilitySpendReportPayload
>(actionTypes.LOAD_SUSTAINABILITY_SPEND_REPORT)

export const loadReducedSustainabilitySpendReport = createAction<
  SustainabilitySpendReportPayload
>(actionTypes.LOAD_REDUCED_SUSTAINABILITY_SPEND_REPORT)

export const getSuppliersByQuestion = createAction(
  actionTypes.GET_SUPPLIERS_BY_QUESTION
)
export const closeSuppliersByQuestionDialog = createAction(
  actionTypes.CLOSE_SUPPLIERS_BY_QUESTION_DIALOG
)
export const switchToHistoricalSpendData = createAction(
  actionTypes.SWITCH_TO_HISTORICAL_SPEND_DATA
)
