import React, { useState, useMemo, ChangeEvent } from 'react'
import PageSection from 'shared/components/PageSection'
import Page from 'shared/components/Page'
import Select from 'shared/components/Select'
import { List, RecordOf } from 'immutable'
import Task from 'shared/models/Task'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { startCase } from 'lodash'
import BulkOutreachContainer from '../../containers/BulkOutreachContainer'
import useIsFeatureEnabled from 'shared/utils/featureFlags/useIsFeatureEnabled'
import OutreachTable from './OutreachTable'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg'
import useUserAccess from 'shared/utils/useUserAccess'

const messages = defineMessages({
  ExpiringContract: {
    id: 'SupplierOutreach.ExpiringContract',
    defaultMessage: 'Contract Renewal'
  },
  EcoVadisAssessment: {
    id: 'SupplierOutreach.EcoVadisAssessment',
    defaultMessage: 'EcoVadis Assessment'
  },
  SelfCertifyRequest: {
    id: 'SupplierOutreach.SelfCertifyRequest',
    defaultMessage: 'Self Certify'
  },
  ESGSurveyRequest: {
    id: 'SupplierOutreach.ESGSurveyRequest',
    defaultMessage: 'Sustainability Survey'
  },
  filterPlaceholder: {
    id: 'SupplierOutreach.FilterPlaceholder',
    defaultMessage: 'Search for a Supplier'
  }
})

type Props = {
  supplierOutreachTasks: List<RecordOf<Task>>
}

const SupplierOutreach = (props: Props) => {
  const intl = useIntl()
  const userAccess = useUserAccess()
  const [taskType, setTaskType] = useState<string>('')
  const [openFilterValue, setOpenFilterValue] = useState<string>('')
  const [closedFilterValue, setClosedFilterValue] = useState<string>('')

  const openTasks = useMemo(() => {
    return props.supplierOutreachTasks.filter(
      task =>
        !task.get('archived') &&
        (!taskType || task.get('type') === taskType) &&
        (!openFilterValue ||
          task
            .get('supplier')
            ?.toLowerCase()
            .includes(openFilterValue.toLocaleLowerCase()))
    )
  }, [props.supplierOutreachTasks, taskType, openFilterValue])

  const closedTasks = useMemo(() => {
    return props.supplierOutreachTasks.filter(
      task =>
        !!task.get('archived') &&
        (!taskType || task.get('type') === taskType) &&
        (!closedFilterValue ||
          task
            .get('supplier')
            ?.toLowerCase()
            .includes(closedFilterValue.toLocaleLowerCase()))
    )
  }, [props.supplierOutreachTasks, taskType, closedFilterValue])

  const handleFilterByTaskType = e => {
    setTaskType(e.target.value)
  }

  const requestTypeFilterOptions = taskTypeName => {
    let filterOption
    if (taskTypeName === 'ExpiringContract') {
      filterOption = intl.formatMessage(messages.ExpiringContract)
    } else if (taskTypeName === 'EcoVadisAssessment') {
      filterOption = intl.formatMessage(messages.EcoVadisAssessment)
    } else if (taskTypeName === 'SelfCertifyRequest') {
      filterOption = intl.formatMessage(messages.SelfCertifyRequest)
    } else if (taskTypeName === 'ESGSurveyRequest') {
      filterOption = intl.formatMessage(messages.ESGSurveyRequest)
    } else {
      filterOption = startCase(taskTypeName)
    }
    return filterOption
  }

  const taskTypesSelector = (tasks: List<RecordOf<Task>>) => {
    //array of sorted task types without duplicates used to populate Select component
    return tasks.reduce<Array<string>>((result, curr) => {
      if (result.indexOf(curr.get('type')) < 0) {
        result.push(curr.get('type'))
      }
      return result.sort()
    }, [])
  }

  const esgSurveyPhase2Access = useIsFeatureEnabled('ESGSurveyPhase2')

  return (
    <>
      <Page title='Supplier Outreach'>
        {userAccess.accessSustainabilitySurveyReport &&
          esgSurveyPhase2Access && <BulkOutreachContainer />}
        <PageSection
          noPadding
          noPaper
          title={
            <div className='tr'>
              <span>
                <FormattedMessage
                  id='SupplierOutreach.OpenRequests'
                  defaultMessage='Open Requests'
                />
              </span>
            </div>
          }
          actions={
            <>
              <Label htmlFor='filterbytasktype' className='di'>
                <FormattedMessage
                  id='SupplierOutreach.SortBy'
                  defaultMessage={'Sort by: '}
                />
              </Label>
              <div className='w6 dib ml2'>
                <Select
                  fullWidth
                  onChange={handleFilterByTaskType}
                  name='filterbytasktype'
                >
                  <FormattedMessage
                    id='SupplierOutreach.AllRequestTypes'
                    defaultMessage='All Request Types'
                  >
                    {msg => <option value=''>{msg}</option>}
                  </FormattedMessage>
                  {taskTypesSelector(props.supplierOutreachTasks).map(
                    (typeName, index) => (
                      <option key={index} value={typeName}>
                        {requestTypeFilterOptions(typeName)}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </>
          }
        >
          <div className='flex justify-between mb3'>
            <div className='w-50'>
              <Input
                ariaLabel='Search Open Requests'
                value={openFilterValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setOpenFilterValue(e.currentTarget.value)
                }
                placeholder={intl.formatMessage(messages.filterPlaceholder)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className='w50'></div>
          </div>
          {openTasks && (
            <OutreachTable
              tasks={openTasks}
              noTaskMessage={
                <FormattedMessage
                  id='SupplierOutreach.OpenRequestNotFound'
                  defaultMessage='There is no open request found'
                />
              }
            />
          )}
        </PageSection>

        <PageSection
          noPadding
          noPaper
          title={
            <div className='tr'>
              <span>
                <FormattedMessage
                  id='SupplierOutreach.ClosedRequests'
                  defaultMessage='Closed Requests'
                />
              </span>
            </div>
          }
        >
          <div className='flex justify-between mb3'>
            <div className='w-50'>
              <Input
                ariaLabel='Search Closed Requests'
                value={closedFilterValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setClosedFilterValue(e.currentTarget.value)
                }
                placeholder={intl.formatMessage(messages.filterPlaceholder)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className='w50'></div>
          </div>
          {closedTasks && (
            <OutreachTable
              tasks={closedTasks}
              isClosed
              noTaskMessage={
                <FormattedMessage
                  id='SupplierOutreach.ClosedRequestNotFound'
                  defaultMessage='There is no closed request found'
                />
              }
            />
          )}
        </PageSection>
      </Page>
    </>
  )
}

export default SupplierOutreach
