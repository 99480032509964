import startCase from 'lodash.startcase'
import FileSaver from 'file-saver'

/**
 * This version takes a list of columns separate from the data
 * in order to support dynamic columns per row
 */
type CsvExport = {
  data: Array<{ [key: string]: string }>
  columns: string[]
  fileName?: string
  modifyHeader?: boolean
  hasDisclaimer?: boolean
}
const appendDisclaimer = string => {
  string =
    string +
    '\r\nDisclaimer: Notice: This "Tealbook Report" represents data available as part of a subscription service available from Tealbook Inc. ("Tealbook") and does not represent statements of fact. Each Tealbook Report is valid as of its original publication date and is subject to change without notice.'
  return string
}
export const exportToCsv = ({
  data,
  columns,
  fileName = 'untitled',
  modifyHeader = true,
  hasDisclaimer = true
}: CsvExport) => {
  const csvHeaders: string = modifyHeader
    ? columns.map(startCase).join(',')
    : columns.join(',')
  const csvRows = data
    .map(row => columns.map(column => `"${row[column] || ''}"`).join(','))
    .join('\r\n')
  let csvFileContents = [csvHeaders, csvRows].join('\r\n')
  csvFileContents = hasDisclaimer
    ? appendDisclaimer(csvFileContents)
    : csvFileContents
  const blob = new Blob([csvFileContents], { type: 'text/csv;charset=utf-8' })
  FileSaver.saveAs(blob, `${fileName}.csv`)
}

export const exportCSV = (
  jsonData: Array<object> | string,
  fileName: string,
  showLabel: boolean = true,
  type: string = 'csv',
  delimiter: string = ', ',
  hasDisclaimer: boolean = true
) => {
  const arrayData: Array<object> =
    typeof jsonData !== 'object' ? JSON.parse(jsonData) : jsonData
  let CSV = ''

  if (arrayData.length > 0) {
    if (showLabel) {
      let row = ''
      for (let key in arrayData[0]) {
        row += startCase(key) + ','
      }
      row = row.slice(0, -1)
      CSV += row + '\r\n'
    }
    for (let i = 0; i < arrayData.length; i++) {
      let row = ''
      for (let key in arrayData[i]) {
        let value = ''
        if (typeof arrayData[i][key] === 'string') {
          value = arrayData[i][key]
        } else if (Array.isArray(arrayData[i][key])) {
          value = arrayData[i][key].join(
            key === 'classificationCodes' ? '|' : delimiter
          )
        } else if (arrayData[i][key]) {
          value = arrayData[i][key]['value'] || arrayData[i][key]
        }

        row += `"${value}",`
      }
      row = row.slice(0, -1)
      CSV += row + '\r\n'
    }
  }
  CSV = hasDisclaimer ? appendDisclaimer(CSV) : CSV
  var blob = new Blob([CSV], { type: `text/${type};charset=utf-8` })
  FileSaver.saveAs(blob, `${fileName || 'untitled'}.${type}`)
}

export const exportDoc = (element: HTMLDivElement, fileName?: string) => {
  const preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>`
  const postHtml = `</body></html>`
  const html = `${preHtml}${element.innerHTML}${postHtml}`

  const blob = new Blob(['\ufeff', html], {
    type: 'application/msword'
  })

  FileSaver.saveAs(blob, `${fileName || 'untitled'}.doc`)
}

export const exportJsonString = (
  jsonData: object,
  fileName: string,
  type: string = 'json'
) => {
  try {
    var blob = new Blob([JSON.stringify(jsonData)], {
      type: `text/${type};charset=utf-8`
    })
    FileSaver.saveAs(blob, `${fileName || 'untitled'}.${type}`)
  } catch (e) {
    console.error(e)
  }
}

export const exportList = (
  arrayData: Array<string>,
  fileName: string,
  ext: string = 'txt',
  addDisclaimer: boolean = true
) => {
  var output = ''

  if (arrayData.length > 0) {
    for (let i = 0; i < arrayData.length; i++) {
      let row = arrayData[i]
      output += row + '\r\n'
    }
  }
  if (addDisclaimer) {
    output = appendDisclaimer(output)
  }

  var blob = new Blob([output], { type: `text/plain;charset=utf-8` })
  FileSaver.saveAs(blob, `${fileName || 'untitled'}.${ext}`)
}
