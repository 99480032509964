import React, { useMemo, ChangeEvent } from 'react'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Select from 'shared/components/Select'
import Keywords from './Keywords'
import Naics, { NaicsName } from './Naics'
import Scrollable from 'shared/components/Scrollable'
import Locations from './Locations'
import Location from './Location'
import ExternalLink from 'shared/components/ExternalLink'

const employeesOptions = [
  '1-10',
  '10-25',
  '25-50',
  '50-100',
  '100-200',
  '200-500',
  '500-1,000',
  '1,000-10,000',
  '10,000-100,000',
  '>100,000'
]

const revenueOptions = [
  '$0-$1M',
  '$1M-$10M',
  '$10M–$50M',
  '$50M-$100M',
  '$100M-$250M',
  '$250M-$500M',
  '$500M-$1B',
  '$1B-$10B',
  '>$10B'
]

type Props = {
  fieldName: string
  questionType:
    | 'text'
    | 'textArea'
    | 'keyword'
    | 'naics'
    | 'locations'
    | 'numberOfEmployees'
    | 'annualRevenue'
  label?: string
  placeholder?: string
  buttonLabel?: string
  height?: number
  sideLabel?: boolean
  value: string | Array<string>
  editMode: boolean
  editNote?: string
  maxLength?: number
  onChange?: ({ fieldName, value }: { fieldName: string; value: any }) => void
}

const SowField = (props: Props) => {
  const {
    questionType,
    label,
    sideLabel,
    fieldName,
    value,
    editMode,
    height,
    placeholder,
    buttonLabel,
    onChange,
    editNote,
    maxLength
  } = props

  const normalizeValue = useMemo(() => {
    // do not sort if it is locations
    return Array.isArray(value)
      ? value.sort((a, b) =>
          questionType === 'locations'
            ? 0
            : a.toUpperCase() > b.toUpperCase()
            ? 1
            : -1
        )
      : value
  }, [value, questionType])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onChange) {
      onChange({ fieldName, value: e.currentTarget.value })
    }
  }

  const handleListAdd = (value: string) => {
    if (onChange && Array.isArray(normalizeValue)) {
      const newValue = [...normalizeValue, value]
      const uniqueValue = Array.from(new Set(newValue))

      onChange({
        fieldName,
        value: uniqueValue
      })
    }
  }

  const handleListDelete = (index: number) => {
    if (onChange && Array.isArray(normalizeValue)) {
      normalizeValue.splice(index, 1)
      onChange({
        fieldName,
        value: [...normalizeValue]
      })
    }
  }

  const handleListUpdate = (index: number, value: string) => {
    if (onChange && Array.isArray(normalizeValue)) {
      normalizeValue.splice(index, 1, value)
      onChange({
        fieldName,
        value: [...normalizeValue]
      })
    }
  }

  const renderStringValue = (value: string) => {
    return fieldName === 'websiteUrl' ? (
      <Text>
        <ExternalLink href={value}>{value}</ExternalLink>
      </Text>
    ) : (
      <Text>{value}</Text>
    )
  }

  const renderArrayValue = (value: Array<string>) => {
    if (fieldName === 'serviceAreas' && value.length === 0) {
      return <Text>Global</Text>
    }
    return (
      <Scrollable maxHeight={200}>
        {value.map(v =>
          questionType === 'locations' ? (
            <Location key={v} location={v} />
          ) : (
            <Text key={v} className='mb2'>
              {questionType === 'naics' ? (
                <>
                  {v} - <NaicsName code={v} />
                </>
              ) : (
                v
              )}
            </Text>
          )
        )}
      </Scrollable>
    )
  }

  return (
    <>
      <div className={sideLabel ? 'flex items-top mb1' : 'mb1'}>
        {label && (
          <label
            className={`f7 fw6 lh-copy mid-gray ${
              sideLabel && editMode ? 'pt2' : ''
            }`}
            style={{ flex: sideLabel ? '0 0 120px' : '100%' }}
            htmlFor={fieldName}
          >
            {label}
          </label>
        )}
        <div className={sideLabel ? 'flex-auto' : ''}>
          {editMode ? (
            <>
              {questionType === 'text' && (
                <Input
                  name={fieldName}
                  value={normalizeValue}
                  onChange={handleChange}
                  placeholder={placeholder}
                />
              )}
              {questionType === 'textArea' && (
                <TextArea
                  name={fieldName}
                  value={normalizeValue}
                  onChange={handleChange}
                  height={height}
                  placeholder={placeholder}
                  maxLength={maxLength}
                />
              )}
              {questionType === 'keyword' && (
                <Keywords
                  keywords={normalizeValue as string[]}
                  onAdd={handleListAdd}
                  onDelete={handleListDelete}
                  placeholder={placeholder}
                  buttonLabel={buttonLabel}
                />
              )}
              {questionType === 'naics' && (
                <Naics
                  naics={normalizeValue as string[]}
                  onAdd={handleListAdd}
                  onDelete={handleListDelete}
                />
              )}
              {questionType === 'locations' && (
                <Locations
                  locations={normalizeValue as string[]}
                  onAdd={handleListAdd}
                  onDelete={handleListDelete}
                  onUpdate={handleListUpdate}
                />
              )}
              {questionType === 'numberOfEmployees' && (
                <Select
                  onChange={handleChange}
                  value={normalizeValue}
                  fullWidth
                  name={fieldName}
                >
                  <option>Please select a value</option>
                  {employeesOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              )}
              {questionType === 'annualRevenue' && (
                <Select
                  onChange={handleChange}
                  value={normalizeValue}
                  fullWidth
                  name={fieldName}
                >
                  <option>Please select a value</option>
                  {revenueOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              )}
            </>
          ) : typeof normalizeValue === 'string' ||
            typeof normalizeValue === 'number' ? (
            renderStringValue(normalizeValue)
          ) : (
            renderArrayValue(normalizeValue)
          )}
        </div>
      </div>
      {editMode && editNote && (
        <Text className='mt2' secondary>
          {editNote}
        </Text>
      )}
    </>
  )
}

export default SowField
