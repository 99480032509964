import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEsgReportPage } from '../../store/actions'
import ReportQuestion from './ReportQuestion'
import insightsSelectors from '../../store/insightsSelectors'
import Loading from 'shared/components/Loading'

const pageId = 'diversityInclusion'

const DiversityReport = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(insightsSelectors.isEsgReportPageLoading)

  useEffect(() => {
    dispatch(getEsgReportPage(pageId))
  }, [dispatch])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className='ba b--black-10 bg-light-gray ph3'>
      <ReportQuestion
        questionNumber={1}
        pageId={pageId}
        questionId='diversityCommitment'
        subQuestionIds={['publishedExternally']}
      />
      <ReportQuestion
        questionNumber={2}
        pageId={pageId}
        questionId='formalPolicies'
      />
      <ReportQuestion
        questionNumber={3}
        pageId={pageId}
        questionId='diversityTraining'
      />
      <ReportQuestion
        questionNumber={4}
        pageId={pageId}
        questionId='integrateProcess'
      />
      <ReportQuestion
        questionNumber={5}
        pageId={pageId}
        questionId='diversityCertification'
      />
      <ReportQuestion
        questionNumber={6}
        pageId={pageId}
        questionId='51PercentOwned'
      />
    </div>
  )
}

export default DiversityReport
