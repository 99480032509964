import { call, put, select } from 'redux-saga/effects'
import loader from 'shared/utils/api/loader'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { push } from 'connected-react-router'
import routingSelector from 'shared/selectors/routingSelectors'
import diversityPaths from 'supplier/Insight/diversityPaths'
import { loadStagingHistory, notify } from 'shared/actions'

export function* uploadTier2SpendSaga(action: {
  payload: { year: any; quarter: any; spendType?: string }
}) {
  const { spendType, year, quarter } = action.payload

  const response = yield call(loader.uploadTier2Spend, action.payload)
  yield put(loadStagingHistory({ listType: 'supplierTier2Load' }))
  if (response?.status === 'ok') {
    if (spendType) {
      yield put(push(`${diversityPaths.diversityLoad}?show=${year}-${quarter}`))
    } else {
      const activeQuarter = yield select(routingSelector.getSearchParam, 'show')
      if (activeQuarter !== `${year}-${quarter}`) {
        yield put(push(`?show=${year}-${quarter}`))
      }
    }
    yield put(notify({ message: 'Your file was successfully uploaded' }))
    return yield []
  }
  if (response?.status === 'error') {
    return response.fileLoadErrors
  }
}

export default createApiRequestSaga(uploadTier2SpendSaga)
