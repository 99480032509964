import { makeStyles } from '@material-ui/core'

const useThemeColors = makeStyles(theme => ({
  primaryContained: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  primaryLightContained: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light)
  },
  primaryText: {
    color: theme.palette.primary.main
  },
  primaryHover: {
    '&:hover, &:focus': {
      color: theme.palette.primary.dark
    }
  },
  primaryLightContainedHover: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.getContrastText(theme.palette.primary.light)
    }
  },
  primaryLinkHover: {
    '&:hover, &:focus': {
      color: theme.palette.getContrastText(theme.palette.primary.light)
    }
  },
  primaryBorder: {
    borderColor: theme.palette.primary.main
  }
}))

export default useThemeColors
