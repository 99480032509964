import { put, call, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import routingSelectors from 'shared/selectors/routingSelectors'
import { startSubmit, stopSubmit, SubmissionError } from 'redux-form/immutable'
import { reset, clearSubmitErrors } from 'redux-form'
import {
  requestFailure,
  requestSuccess,
  logout,
  stopEditMode,
  notify
} from '../../../actions'
import ApiError from '../../api/ApiError'
import FormError from '../../FormError'

type ApiRequestSagaOptions = {
  payloadParser?: (data: any) => any
  notifyOnSuccessMessage?: string
  notifyOnError?: boolean
  usePayload?: boolean
  returnPayload?: boolean
  dontResetForm?: boolean
}
const createApiRequestSaga = (
  saga,
  {
    notifyOnError = true,
    notifyOnSuccessMessage,
    usePayload,
    payloadParser,
    returnPayload,
    dontResetForm
  }: ApiRequestSagaOptions = {}
) => {
  return function* apiRequestSaga(action, ...args) {
    const payload = payloadParser
      ? payloadParser(action.payload)
      : action.payload
    const formName = action.meta && action.meta.formName
    const redirectTo = action.meta && action.meta.redirectTo

    try {
      // start redux-form
      if (formName) {
        yield put(clearSubmitErrors(formName))
        yield put(startSubmit(formName))
      }

      // perform saga
      const successResult = yield call(
        saga,
        usePayload ? payload : action,
        ...args
      )

      // stop redux-form
      if (formName) {
        yield put(stopSubmit(formName))
        if (!dontResetForm) {
          yield put(reset(formName))
        }
        if (action.meta.stopEditModeOnSave) {
          yield put(stopEditMode(formName))
        }
      }

      // trigger request success action
      yield put(
        requestSuccess(action.type, returnPayload ? payload : successResult)
      )

      // notify that it succeded
      if (notifyOnSuccessMessage) {
        yield put(
          notify({
            message: notifyOnSuccessMessage
          })
        )
      }

      // redirect if necessary
      if (redirectTo) {
        const pathname = yield select(routingSelectors.getPathname)
        const search = yield select(routingSelectors.getSearch)
        yield put(push(`${redirectTo}?redirectFrom=${pathname}${search}`))
      }
    } catch (err) {
      // default error message
      let errorMessage = `An unexpected error has occurred. Please try again later.`

      // if it is an API error or user defined error then use its message
      if (err instanceof ApiError || err instanceof FormError) {
        errorMessage = err.message || errorMessage
      } else {
        // log the error for debug
        console.error(err)
      }

      if (err instanceof ApiError && err.statusCode === 401) {
        // logout when it is a 401
        yield put(logout())
      } else {
        // trigger request failure action
        yield put(requestFailure(action.type, errorMessage))

        // send redux-form error
        if (formName) {
          yield put(
            stopSubmit(formName, new SubmissionError(errorMessage as any))
          )
        } else if (notifyOnError) {
          yield put(
            notify({
              message: errorMessage
            })
          )
        }
      }
    }
  }
}

export default createApiRequestSaga
