import React, { Component } from 'react'
import moment from 'moment'

type Props = {
  date: Date
  status: string
}

class CompleteByDate extends Component<Props> {
  render() {
    const { date, status } = this.props

    const today = moment.now()
    const responseByDate = moment(date)
    const dateDiff = responseByDate.diff(today, 'days')
    const bgColor =
      status === 'Completed'
        ? ''
        : dateDiff < 0
        ? 'white bg-red'
        : dateDiff < 2
        ? 'black bg-gold'
        : 'white bg-dark-green'

    return (
      <div className={`dib br2 pv2 ph2 tc f6 ${bgColor}`}>
        {moment(date).format('LL')}
      </div>
    )
  }
}

export default CompleteByDate
