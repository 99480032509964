import React, { ChangeEvent } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  placeholder: {
    '&::placeholder': {
      color: '#707070',
      opacity: 1
    }
  }
})
type Props = {
  placeholder?: string
  additionalClass?: string
  id?: string
  input?: { value: any }
  meta?: { error?: string; touched?: boolean }
  resize?:
    | 'vertical'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'block'
    | 'both'
    | 'horizontal'
    | 'inline'
    | 'none'
  height?: string | number
  maxLength?: number
  value?: any
  name?: string
  className?: string
  required?: boolean
  readOnly?: boolean
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
}
/**
 * Creates a textarea element
 */
const TextArea = (props: Props) => {
  const classes = useStyles()
  const {
    input,
    meta = {},
    resize,
    height,
    additionalClass = '',
    maxLength,
    ...rest
  } = props

  const length = input
    ? input.value
      ? input.value.length
      : 0
    : rest.value
    ? rest.value.length
    : 0

  return (
    <div>
      <textarea
        {...input}
        maxLength={maxLength}
        className={`f6 ba b--black-70 br2 pv2-5 pa2 w-100 border-box ${additionalClass} ${classes.placeholder}`}
        style={{
          resize: resize || 'vertical',
          height: height || 70
        }}
        {...rest}
      />
      {maxLength && (
        <div className='f7 fw4 gray tr'>{`${length} / ${maxLength}`}</div>
      )}

      {meta.touched && meta.error && (
        <span className='f7 red'>{meta.error}</span>
      )}
    </div>
  )
}

export default TextArea
