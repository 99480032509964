import React, { ReactNode } from 'react'
import {
  Table as VirtualTable,
  AutoSizer,
  TableRowProps
} from 'react-virtualized'
import useThemeColors from 'shared/utils/useThemeColors'
import { List } from 'immutable'
import { makeStyles } from '@material-ui/core'

const maxHeight = 420

const styles = {
  table: 'tl',
  scrollTable: 'tl overflow-auto',
  headerRow: 'bb b--black-10 f7 fw6 mid-gray ttc pa2 bg-near-white',
  headerCell: '',
  body: 'f7 fw4 mid-gray',
  bodyEvenRow: 'pa2 bb b--black-10 bg-animate',
  bodyOddRow: 'pa2 bb b--black-10 bg-animate'
}
const useStyles = makeStyles(() => ({
  error: {
    backgroundColor: '#FEF3F5'
  }
}))

type Props = {
  minWidth?: number
  highlightIndexes?: List<number> | Array<number>
  rowHeight?: number | ((row?: any) => number)
  rowCount: number
  headerHeight?: number
  onRow?: boolean
  rowGetter: ({ index: number }) => any
  headerClassName?: string
  headerRowClassName?: string
  rowClassName?: string
  errorIndexes?: List<number>
  height?: number
  rowStyle?: ({ index: number }) => any
  onRowClick?: ({ rowData, index }: { index?: number; rowData?: any }) => void
  sort?: ({
    sortBy,
    sortDirection
  }: {
    sortBy: string
    sortDirection: 'ASC' | 'DESC'
  }) => void
  sortDirection?: 'ASC' | 'DESC'
  sortBy?: string
  onRowMouseOver?: ({ rowData }: { rowData: any }) => void
  onRowMouseOut?: () => void
  tableRef?: any
  children: ReactNode
  rowCustomRenderer?: (props: TableRowProps) => React.ReactNode
}

const Table = (props: Props) => {
  const {
    headerHeight = 52,
    rowHeight = 52,
    rowCount,
    headerRowClassName,
    onRowClick,
    highlightIndexes,
    errorIndexes
  } = props

  const palette = useThemeColors()
  const classes = useStyles()

  const calcHeight = () => {
    const height =
      typeof rowHeight === 'function'
        ? rowHeight({ index: 0 }) * rowCount + headerHeight
        : rowHeight * rowCount + headerHeight
    return height > maxHeight ? maxHeight : height
  }

  const rowClassNames = ({ index }) => {
    const ifError =
      errorIndexes && errorIndexes.includes(index) ? classes.error : ''
    return index < 0
      ? headerRowClassName || styles.headerRow
      : index % 2 === 0
      ? `${styles.bodyEvenRow} ${palette.primaryLightContainedHover} ${
          onRowClick ? 'pointer' : ''
        } ${
          highlightIndexes && highlightIndexes.includes(index)
            ? palette.primaryLightContained
            : ''
        } ${ifError}`
      : `${styles.bodyOddRow} ${palette.primaryLightContainedHover} ${
          onRowClick ? 'pointer' : ''
        } ${
          highlightIndexes && highlightIndexes.includes(index)
            ? palette.primaryLightContained
            : ''
        } ${ifError}`
  }

  const {
    children,
    minWidth,
    height,
    headerClassName,
    tableRef,
    rowCustomRenderer,
    ...rest
  } = props
  const tableHeight = height || calcHeight()
  return (
    <AutoSizer disableHeight style={{ width: '100%' }}>
      {({ width: autoWidth }) => {
        const useAutoWidth = !(minWidth && minWidth > autoWidth)
        return (
          <VirtualTable
            {...rest}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            className={useAutoWidth ? styles.table : styles.scrollTable}
            width={useAutoWidth ? autoWidth - 2 : (minWidth as number)}
            height={tableHeight}
            rowClassName={rowClassNames}
            headerClassName={headerClassName || styles.headerCell}
            gridClassName={styles.body}
            ref={tableRef}
            rowRenderer={rowCustomRenderer}
          >
            {children}
          </VirtualTable>
        )
      }}
    </AutoSizer>
  )
}

export default Table
