import React, { Component } from 'react'
import { connect } from 'react-redux'
import Page from 'shared/components/Page'
import MostActiveUsers from '../MostActiveUsers'
import { loadInsights } from '../../store/actions'
import Loading from 'shared/components/Loading'
import insightsSelectors from '../../store/insightsSelectors'
import CompanyStats from '../../components/CompanyStats'
import CompanyTags from '../CompanyTags'
import MostActiveSupplier from '../MostActiveSuppliers'
import NumberOfSearchesGraph from '../NumberOfSearchesGraph'
import CommonSearchTerms from '../CommonSearchTerms'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf, Map } from 'immutable'
import Grid from '@material-ui/core/Grid'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import Text from 'shared/components/Text'

type Props = {
  isLoading: boolean
  commonSearchTerms: Map<string, number>
  searchGraphData: List<
    RecordOf<{
      monthYear: string
      numberOfSearches: number
    }>
  >
  companyStats: RecordOf<{
    numberOfUsers: number
    numberOfConnectedSuppliers: number
    numberOfVets: number
    numberOfLogins: number
    numberOfExistingSuppliers: number
    numberOfPreferredSuppliers: number
    numberOfSearches: number
  }>
  hasNoData: boolean
}

export class GeneralPage extends Component<Props> {
  render() {
    const {
      isLoading,
      searchGraphData,
      companyStats,
      commonSearchTerms,
      hasNoData
    } = this.props

    if (!isLoading && hasNoData) {
      return (
        <Text className='tc mt3-5'>
          <FormattedMessage
            id='GeneralPage.Insights.NoData'
            defaultMessage='No data to display'
          />
        </Text>
      )
    }

    return (
      <Page
        title={
          <FormattedMessage
            id='GeneralPage.Insights'
            defaultMessage='General'
          />
        }
      >
        <ScrollToPosition />

        {isLoading && <Loading />}

        {!isLoading && (
          <Grid container spacing={2}>
            {(companyStats.get('numberOfUsers') !== 0 ||
              companyStats.get('numberOfConnectedSuppliers') !== 0 ||
              companyStats.get('numberOfVets') !== 0 ||
              companyStats.get('numberOfLogins') !== 0 ||
              companyStats.get('numberOfExistingSuppliers') !== 0 ||
              companyStats.get('numberOfPreferredSuppliers') !== 0 ||
              companyStats.get('numberOfSearches') !== 0) && (
              <Grid item md={6} xs={12}>
                <CompanyStats
                  numberOfUsers={companyStats.get('numberOfUsers')}
                  numberOfConnectedSuppliers={companyStats.get(
                    'numberOfConnectedSuppliers'
                  )}
                  numberOfVets={companyStats.get('numberOfVets')}
                  numberOfLogins={companyStats.get('numberOfLogins')}
                  numberOfExistingSuppliers={companyStats.get(
                    'numberOfExistingSuppliers'
                  )}
                  numberOfPreferredSuppliers={companyStats.get(
                    'numberOfPreferredSuppliers'
                  )}
                  numberOfSearches={companyStats.get('numberOfSearches')}
                />
              </Grid>
            )}

            <MostActiveUsers />

            {searchGraphData && searchGraphData.size > 0 && (
              <Grid item md={6} xs={12}>
                <NumberOfSearchesGraph searchGraphData={searchGraphData} />
              </Grid>
            )}

            <MostActiveSupplier />

            {commonSearchTerms && commonSearchTerms.size > 0 && (
              <Grid item md={6} xs={12}>
                <CommonSearchTerms commonSearchTerms={commonSearchTerms} />
              </Grid>
            )}

            <CompanyTags />
          </Grid>
        )}
      </Page>
    )
  }
}

export default connect(
  state => {
    const isLoading = insightsSelectors.isLoading(state)
    const companyStats = insightsSelectors.getCompanyStats(state)
    const searchGraphData = insightsSelectors.getSearchGraph(state)
    const commonSearchTerms = insightsSelectors.getCommonSearchTermsMap(state)

    const activeUsers = insightsSelectors.getMostActiveUsers(state)
    const activeSuppliers = insightsSelectors.getMostActiveSuppliers(state)
    const tags = insightsSelectors.getCompanyTagsMap(state)
    const hasNoData =
      companyStats &&
      companyStats.get('numberOfUsers') === 0 &&
      companyStats.get('numberOfConnectedSuppliers') === 0 &&
      companyStats.get('numberOfVets') === 0 &&
      companyStats.get('numberOfLogins') === 0 &&
      companyStats.get('numberOfExistingSuppliers') === 0 &&
      companyStats.get('numberOfPreferredSuppliers') === 0 &&
      companyStats.get('numberOfSearches') === 0 &&
      activeUsers?.size < 1 &&
      searchGraphData?.size < 1 &&
      activeSuppliers?.size < 1 &&
      commonSearchTerms?.size < 1 &&
      tags?.size < 1

    return {
      isLoading,
      companyStats,
      searchGraphData,
      commonSearchTerms,
      hasNoData
    }
  },
  {
    loadInsights
  }
)(GeneralPage)
