import { call, put } from 'redux-saga/effects'
import { notify } from 'shared/actions'
import relationships from 'shared/utils/api/relationships'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* deleteClientSpendByIdSaga(action) {
  let { attachedFile } = action.payload
  yield call(relationships.deleteClientSpendById, {
    file: attachedFile
  })
  yield put(
    notify({
      message: 'Client Spend Deleted'
    })
  )
}

export default createApiRequestSaga(deleteClientSpendByIdSaga)
