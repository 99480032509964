import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const loadTier2AllocatedSpend = createAction(
  actionTypes.LOAD_TIER2_ALLOCATED_SPEND
)
export const updateTier2AllocatedSpend = createAction(
  actionTypes.UPDATE_TIER2_ALLOCATED_SPEND
)
export const getTier2Communities = createAction(
  actionTypes.GET_TIER2_COMMUNITIES
)

export const loadTier2DiverseSummary = createAction(
  actionTypes.LOAD_TIER2_DIVERSE_SUMMARY
)
export const postTier2DiverseSummary = createAction(
  actionTypes.POST_TIER2_DIVERSE_SUMMARY
)
export const removeTier2DiverseSummary = createAction(
  actionTypes.REMOVE_TIER2_DIVERSE_SUMMARY
)

export const addTier2Buyer = createAction(actionTypes.ADD_TIER2_BUYER)

export const updateAllocation = createAction(actionTypes.UPDATE_ALLOCATION)
