import React from 'react'
import paths from '../../../routes/paths'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import useThemeColors from 'shared/utils/useThemeColors'

export const navigation = [
  paths.healthSafety,
  paths.environment,
  paths.diversityInclusion,
  paths.governance,
  paths.privacy
]

export const getCurrentPosAndNextPathname = (
  pathname: string
): [number, string] => {
  for (let i = 0; i < navigation.length; i++) {
    if (pathname.startsWith(navigation[i])) {
      if (i + 1 < navigation.length) {
        return [i, navigation[i + 1]]
      } else {
        return [i, navigation[i]]
      }
    }
  }
  return [0, pathname]
}

export const getPrevPathname = (pathname: string): string => {
  const pathIndex = navigation.findIndex(navPath =>
    pathname.startsWith(navPath)
  )
  return pathIndex === 0 ? navigation[pathIndex] : navigation[pathIndex - 1]
}

const EsgNav = () => {
  const location = useLocation()
  const colors = useThemeColors()
  const [currentPos] = getCurrentPosAndNextPathname(location.pathname)
  const currentPageName = () => {
    if (currentPos === 0) {
      return (
        <div className={colors.primaryText}>
          <FormattedMessage
            id='EsgNav.HealthSafety'
            defaultMessage='Health & Safety'
          />
        </div>
      )
    } else if (currentPos === 1) {
      return (
        <div className={colors.primaryText}>
          <FormattedMessage
            id='EsgNav.Environment'
            defaultMessage='Environment'
          />
        </div>
      )
    } else if (currentPos === 2) {
      return (
        <div className={colors.primaryText}>
          <FormattedMessage
            id='EsgNav.Diversity'
            defaultMessage='Diversity & Inclusion'
          />
        </div>
      )
    } else if (currentPos === 3) {
      return (
        <div className={colors.primaryText}>
          <FormattedMessage
            id='EsgNav.Governance'
            defaultMessage='Governance'
          />
        </div>
      )
    } else if (currentPos === 4) {
      return (
        <div className={colors.primaryText}>
          <FormattedMessage id='EsgNav.Privacy' defaultMessage='Privacy' />
        </div>
      )
    }
  }

  return (
    <div className='bg-near-white pa3 mv3 mv4-ns'>
      <div className='mw8 center'>
        <div className='flex justify-center  '>{currentPageName()}</div>
      </div>
    </div>
  )
}

export default EsgNav
