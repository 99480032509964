import RootState from 'shared/models/RootState'
import { createSelector } from 'reselect'

export const isUploadingVendor = (state: RootState) => {
  return state.getIn(['dataPortal', 'dataQuality', 'uploadingVendor'])
}

export const isUploadingSpend = (state: RootState) => {
  return state.getIn(['dataPortal', 'dataQuality', 'uploadingSpend'])
}

export const isUploaded = (state: RootState) => {
  return state.getIn(['dataPortal', 'dataQuality', 'uploaded'])
}

export const getColumnsMappingVendor = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'bdoResponseVendor', 'output'])

export const getCompletedColumnsMappingVendor = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'vendorColumnsMapping'])

export const getCompletedColumnsMappingSpend = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'spendColumnsMapping'])

export const getColumnsMappingSpend = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'bdoResponseSpend', 'output'])

export const getVendorUploadJobId = (state: RootState) => {
  const bdoResponse = state
    .getIn(['dataPortal', 'dataQuality', 'bdoResponseVendor'])
    .toJS()
  const jobId = Object.keys(bdoResponse).find(key => key.startsWith('Job-'))
  return jobId
}

export const getSpendUploadJobId = (state: RootState) => {
  const bdoResponse = state
    .getIn(['dataPortal', 'dataQuality', 'bdoResponseSpend'])
    .toJS()
  const jobId = Object.keys(bdoResponse).find(key => key.startsWith('Job-'))
  return jobId
}

export const getVendorUploadFilename = (state: RootState) => {
  const jobId = getVendorUploadJobId(state)
  return (
    jobId && state.getIn(['dataPortal', 'dataQuality', 'bdoResponseVendor', jobId])
  )
}

export const getSpendUploadFilename = (state: RootState) => {
  const jobId = getSpendUploadJobId(state)
  return (
    jobId && state.getIn(['dataPortal', 'dataQuality', 'bdoResponseSpend', jobId])
  )
}

export const isSubmittingColumnsMappingVendor = (state: RootState) => {
  return state.getIn(['dataPortal', 'dataQuality', 'submittingColumnsMappingVendor'])
}

export const isSubmittingColumnsMappingSpend = (state: RootState) => {
  return state.getIn(['dataPortal', 'dataQuality', 'submittingColumnsMappingSpend'])
}

export const getJobs = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'jobs'])

export const isLoading = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'loading'])

export const getJob = createSelector(
  (state: RootState, jobId) => jobId,
  getJobs,
  (jobId, jobs) => {
    return jobs?.find(job => job.get('id') === jobId)
  }
)

export const getJobStatus = (state: RootState) =>
  state.getIn(['dataPortal', 'dataQuality', 'jobStatus'])

export const getJobStatusSummary = createSelector(getJobStatus, jobStatus =>
  jobStatus.get('dqRowsStatus')
)
export const getPostJobStatusSummary = createSelector(getJobStatus, jobStatus =>
  jobStatus.get('dqPostStatus')
)

export const getJobDerelictRows = createSelector(getJobStatus, jobStatus =>
  jobStatus.get('derelictRows')
)
