import React, { Component } from 'react'
import SupplierProfileHeaderContainer from '../../containers/SupplierProfileHeaderContainer'
import Divider from 'shared/components/Divider'
import SupplierProfileNavBar from '../SupplierProfileNavBar'
import qs from 'qs'
import { RouteComponentProps } from 'react-router'
import { AccessControl } from 'shared/models/AccessControl'

type Props = {
  supplierId: string
  accessControl: AccessControl
  isTealbot: boolean
  loadSocialFeed?: (arg: { twitter?: string; supplierId?: string }) => void
  twitter?: string
  hasCommunityFormResponse: boolean
  customPageName?: string
} & RouteComponentProps

type State = {
  redirectFrom?: string
  searchId?: string
  vetId?: string
}

class SupplierProfilePage extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const {
      location: { search },
      loadSocialFeed,
      twitter,
      supplierId
    } = this.props

    const queryParams = qs.parse(search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })

    if (
      queryParams &&
      queryParams.redirectFrom &&
      queryParams.redirectFrom.indexOf('search') !== -1
    ) {
      const {
        filter,
        scope,
        sort,
        q,
        includeUnQualified,
        attachmentsExpiredExcluded,
        similarSuppliers
      } = queryParams
      const queryString = qs.stringify({
        filter,
        scope,
        sort,
        q,
        includeUnQualified,
        attachmentsExpiredExcluded,
        similarSuppliers
      })

      queryParams.redirectFrom += queryString ? `&${queryString}` : ''
    }

    this.setState({
      redirectFrom: queryParams && queryParams.redirectFrom,
      searchId: queryParams && queryParams.searchId,
      vetId: queryParams && queryParams.vetId
    })

    if (loadSocialFeed) {
      loadSocialFeed({
        twitter,
        supplierId
      })
    }
  }

  render() {
    const {
      location: { pathname },
      supplierId,
      isTealbot,
      accessControl,
      children,
      hasCommunityFormResponse,
      customPageName
    } = this.props
    const { redirectFrom, searchId, vetId } = this.state
    const queryString: Array<string> = []
    if (searchId) {
      queryString.push(`searchId=${searchId}`)
    }
    if (vetId) {
      queryString.push(`vetId=${vetId}`)
    }
    if (redirectFrom) {
      queryString.push(`redirectFrom=${redirectFrom}`)
    }

    return (
      <div className='bg-white' style={{ height: '100%', minHeight: '100vh' }}>
        <SupplierProfileHeaderContainer redirectFrom={redirectFrom} />

        {accessControl.access === 'full' && (
          <div style={{ position: 'sticky', top: 50 }} className='bg-white z-3'>
            <div className='mw8 center ph3'>
              <SupplierProfileNavBar
                supplierId={supplierId}
                pathname={pathname}
                queryString={
                  queryString.length > 0 ? queryString.join('&') : ''
                }
                isTealbot={isTealbot}
                hasCommunityFormResponse={hasCommunityFormResponse}
                customPageName={customPageName}
              />
            </div>
            <Divider />
          </div>
        )}
        {children}
      </div>
    )
  }
}

export default SupplierProfilePage
