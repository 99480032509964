import React from 'react'
import { Route, Redirect, Switch } from 'react-router'
import paths from './paths'
import Vets from '../Vets'
import SearchPage from '../shared/components/SearchPage'
import UserProfileContainer from 'shared/containers/UserProfileContainer'
import EditUserProfileContainer from 'shared/containers/EditUserProfileContainer'
import SupplierProfile from '../SupplierProfile'
import Communities from '../Communities'
import PostingApp from 'posting/App'
import MessagesApp from 'messages/App'
import Insights from '../Insights'
import ContactsApp from '../Contacts/App'
import { AccessControl } from 'shared/models/AccessControl'
import TealbookContributorsContainer from 'shared/containers/TealbookContributorsContainer'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'
import EsgSurvey from '../SupplierProfile/components/EsgSurvey'

const createRoutes = (
  hasFixedFilters: boolean,
  accessControl: AccessControl,
  userAccess: LicenseAccess
) => {
  return (
    <Switch>
      {accessControl.access === 'full' && userAccess.accessNews && (
        <Route path={paths.tealboard} component={PostingApp} />
      )}
      {accessControl.access === 'full' && userAccess.accessRFI && (
        <Route path={paths.vets} component={Vets} />
      )}
      {accessControl.access === 'full' && userAccess.accessContacts && (
        <Route path={paths.people} component={ContactsApp} />
      )}
      {accessControl.access === 'full' && userAccess.accessCommunities && (
        <Route path={paths.communities} component={Communities} />
      )}

      {accessControl.access === 'full' && userAccess.accessChat && (
        <Route path={paths.messageTopic} component={MessagesApp} />
      )}
      {accessControl.access === 'full' && userAccess.accessChat && (
        <Route path={paths.messages} component={MessagesApp} />
      )}
      {accessControl.access === 'full' && userAccess.accessInsights && (
        <Route path={paths.insight} component={Insights} />
      )}

      <Route path={paths.search}>
        <SearchPage disableSuggest={hasFixedFilters} />
      </Route>

      <Route
        path={paths.editUserProfile}
        render={props => (
          <EditUserProfileContainer
            basePath={paths.basePath}
            userId={props.match.params.userId}
            history={props.history}
          />
        )}
      />

      <Route
        path={paths.userprofile}
        render={props => (
          <UserProfileContainer
            basePath={paths.basePath}
            userId={props.match.params.userId}
            history={props.history}
          />
        )}
      />
      {userAccess.accessSustainabilitySurveyReport && (
        <Route path={paths.supplierEsgSurvey} component={EsgSurvey} />
      )}
      <Route path={paths.supplierProfile} component={SupplierProfile} />
      <Route
        path={paths.contributors}
        component={TealbookContributorsContainer}
      />
      <Redirect from={paths.basePath} to={paths.search} />
    </Switch>
  )
}

export default createRoutes
