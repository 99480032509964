import React, { Component } from 'react'
import CustomizePitchDeck from '../../components/CustomizePitchDeck'
import { connect } from 'react-redux'
import { updateCustomizePitchDeck } from '../../actions'
import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import dataSelectors from 'shared/selectors/dataSelectors'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import companySelectors from '../../selectors/companySelectors'
import Loading from 'shared/components/Loading'
import { deleteData } from 'shared/actions'

const supplierRelationshipsEntity = 'supplierRelationships'

export class CustomizePitchDeckContainer extends Component {
  handleSaveCustomizedPitchDeck = ({ description, pitchDeckFile }) => {
    const {
      supplierRelationship,
      supplierId,
      clientId,
      updateCustomizePitchDeck
    } = this.props

    updateCustomizePitchDeck({
      supplierRelationshipId: supplierRelationship.get('id'),
      supplierRelationship: {
        supplierCollaboration: {
          description
        }
      },
      pitchDeckFile,
      supplierId,
      clientId
    })
  }

  handleDeletePitchDeck = () => {
    const { supplierRelationship, deleteData } = this.props

    deleteData({
      entity: supplierRelationshipsEntity,
      recordId: supplierRelationship.get('id'),
      endpoint: `${supplierRelationshipsEntity}/${supplierRelationship.get(
        'id'
      )}/pitchDeckFile`,
      fieldName: 'supplierCollaboration.pitchDeckFile'
    })
  }

  render() {
    const { isLoading, supplierRelationship } = this.props

    if (isLoading) {
      return <Loading />
    }

    return (
      <CustomizePitchDeck
        description={supplierRelationship.getIn([
          'supplierCollaboration',
          'description'
        ])}
        pitchDeckUrl={fileToUrl(
          supplierRelationship.getIn(['supplierCollaboration', 'pitchDeckFile'])
        )}
        onSave={this.handleSaveCustomizedPitchDeck}
        onDeletePitchDeck={this.handleDeletePitchDeck}
        pitchDeckFileName={supplierRelationship.getIn([
          'supplierCollaboration',
          'pitchDeckFile',
          'fileName'
        ])}
        relationshipId={supplierRelationship.get('id')}
      />
    )
  }
}

const supplierRelationshipSelector = createSelector(
  state => dataSelectors.getByEntity(state, supplierRelationshipsEntity),
  (state, clientId) => clientId,
  (supplierRelationships, clientId) =>
    supplierRelationships.toList().find(rel => rel.get('buyer') === clientId) ||
    fromJS({})
)

export default connect(
  (state, props) => ({
    isLoading: dataSelectors.isLoading(state, supplierRelationshipsEntity),
    supplierId: companySelectors.getCompanyField(state, 'id'),
    isPremium:
      companySelectors.getCompanySupplierField(state, 'supplierType') ===
      'basic',
    supplierRelationship: supplierRelationshipSelector(state, props.clientId)
  }),
  {
    updateCustomizePitchDeck,
    deleteData
  }
)(CustomizePitchDeckContainer)
