import React from 'react'
import ActionInfoOutline from '@material-ui/icons/InfoOutlined'
import { FormattedMessage } from 'react-intl'

import Tooltip from 'shared/components/Tooltip'

export const DirectSpendLabel = () => (
  <label
    className='f7 fw6 mr4 mv2 pv1 flex items-center'
    htmlFor='add-direct-spend-input'
  >
    <FormattedMessage
      id='SpendAllocation.DirectSpend'
      defaultMessage={'Direct Spend'}
    />
    <Tooltip
      title={
        <FormattedMessage
          id='SpendAllocation.DirectSpendTooltip'
          defaultMessage={
            "Select the suppliers to allocate diverse spend from. For each one, enter the percentage you'd like to allocate to your customer. Amounts are deducted from your total diverse spend as you allocate them to your customer."
          }
        />
      }
    >
      <ActionInfoOutline
        className='ml2'
        fontSize='small'
        aria-label='Direct Spend Info'
      />
    </Tooltip>
  </label>
)
