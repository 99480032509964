import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select, put } from 'redux-saga/effects'
import users from 'shared/utils/api/users'
import paths from '../../routes/paths'
import parsePath from '../../utils/parsePath'
import sessionSelectors from '../../selectors/sessionSelectors'
import user from 'shared/utils/api/user'
import Immutable from 'immutable'
import { changeLanguage } from 'i18n/store/actions'
import usersSelectors from 'shared/selectors/usersSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import analytics from 'shared/utils/analytics'

export function* updateUserSaga(action) {
  const { history, basePath } = action.meta || {}
  const {
    id,
    profilePicture,
    address,
    language,
    ...userChanges
  } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  const currentUserId = yield select(sessionSelectors.getUserId)
  const isAdminOrTealbot = yield select(sessionSelectors.userHasRole, [
    'buyerAdmin',
    'tealbot'
  ])

  // can only change notification settings by the actual user
  if (currentUserId !== id) {
    delete userChanges.options
    delete userChanges.disableAllEmails
  }

  if (!isAdminOrTealbot) {
    delete userChanges.licenseType
  }

  // update the user
  yield call(users.updateById, id, {
    language,
    location: { address },
    ...userChanges
  })

  if (userChanges?.status === 'Blocked') {
    const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
    const orgUnitName = yield select(orgsSelectors.getCurrentUserOrgUnitName)
    const user = yield select(state => usersSelectors.getById(state, id))
    const email = user?.get('email')
    const userName = `${user?.get('firstName')} ${user?.get('lastName')}`

    analytics.track('User Blocked', {
      eventSource: 'User Profile Edit',
      action: 'Clicked',
      orgUnitId,
      orgUnitName,
      blockedUserId: id,
      blockedUserEmail: email,
      blockedUser: userName
    })
  }

  // upload the profile picture
  if (profilePicture) {
    // need to call different apis for concierge
    if (currentUserId === id) {
      userChanges.profilePictureUrl = yield call(
        user.uploadProfilePicture,
        profilePicture
      )
    } else {
      userChanges.profilePictureUrl = yield call(
        users.updateProfilePictureById,
        id,
        profilePicture
      )
    }
  }

  if (language) {
    yield put(changeLanguage(language))
  }

  if (history && basePath) {
    yield call(
      history.push,
      parsePath(`${basePath}${paths.userProfile}`, {
        userId: id
      })
    )
  }

  return yield {
    id,
    location: { address },
    language,
    ...userChanges
  }
}

export default createApiRequestSaga(updateUserSaga)
