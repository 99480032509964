import React from 'react'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'

const HowToInstruction = () => {
  return (
    <ol>
      <li>
        <FormattedMessage
          id='DiversityReport.Tier2Help3'
          defaultMessage='Accept any invitations you see on this page from your customers.'
        />
      </li>
      <li>
        <FormattedMessage
          id='DiversityReport.Tier2Help4'
          defaultMessage='Download the template below and import your spend data. It is important for the column names to match exactly.'
        />
        <Text className='mv2 mr1'>
          <span className={'dark-red fw6 mr2'}>
            <FormattedMessage
              id='DiversityReport.PrivacyNotice'
              defaultMessage='PRIVACY NOTE:'
            />
          </span>
          <FormattedMessage
            id='DiversityReport.Tier2Help6'
            defaultMessage='We will not share this detailed data with any other company. When you choose to share an allocation with a customer, we will only share a summarized version. This will contain no vendor details.'
          />
        </Text>
      </li>
      <li>
        <FormattedMessage
          id='DiversityReport.Tier2Help7'
          defaultMessage='Upload the completed template and review mapping for each file before you add it to your report.'
        />
        <Text className='mv2 mr1'>
          <span className={'dark-red fw6 mr2'}>
            <FormattedMessage id='DiversityReport.PrivacyNotice' />
          </span>
          <FormattedMessage
            id='DiversityReport.Tier2Help9b'
            defaultMessage='We will only share your summarized spend information by diverse category if you specifically select the customer to share it with. If you choose to share, your customer will be able to see a summary of the diverse spend per quarter you allocated to them grouped by diversity category. We do not share information about your suppliers with your customers.'
          />
        </Text>
      </li>
      <li>
        <FormattedMessage
          id='DiversityReport.Tier2Hel10'
          defaultMessage='Review your diversity report. It will show how much of the spend in the quarter was with small, women-owned, minority-owned, and other categories of diverse companies.'
        />
      </li>
      <li>
        <FormattedMessage
          id='DiversityReport.Tier2Help11'
          defaultMessage='Allocate your customers a percentage of your diversity spend based on how much of your total income they represent and click the Share button.'
        />
      </li>
    </ol>
  )
}

export default HowToInstruction
