import React, { useState, useMemo } from 'react'
import { List, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import { DiversityDetails as SpendDetails } from '../../../Insights/store/diversityReportReducer'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import Paper from 'shared/components/Paper'
import Table from 'shared/components/Table'
import { Column } from 'react-virtualized'
import { FormattedMessage, useIntl } from 'react-intl'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../routes/paths'
import Link from 'shared/components/Link'
import CertificationValidation from '../CertificationValidation'
import { ReactComponent as VerifiedIcon } from 'shared/assets/icons/verified.svg'
import dateFormat from 'shared/utils/dateFormat'
import numberFormat from 'shared/utils/numberFormat'
import {
  addCertificationValidation,
  removeCertificationValidation
} from '../../../SupplierProfile/actions'
import Divider from 'shared/components/Divider'
import Text from 'shared/components/Text'
import DropdownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import startCase from 'lodash.startcase'
import { ReactComponent as Check } from 'shared/assets/icons/check-solid.svg'
import { ReactComponent as Question } from 'shared/assets/icons/question-solid.svg'
import { ReactComponent as Export } from 'shared/assets/icons/external-link-alt-solid.svg'
import Tooltip from 'shared/components/Tooltip'
import {
  alertCodes,
  alertCodesDescriptions
} from 'shared/utils/data/defineMessages'
import ExternalLink from 'shared/components/ExternalLink'
import { useSelector, useDispatch } from 'react-redux'
import {
  createDiversityReportForExport,
  exportEconomicImpactAssessment
} from '../../../Insights/containers/DiversityReport/exportUtils'

type SortByDetails = {
  name: string
  totalAmount: number
}

type Props = {
  hideExports?: boolean
  qualificationType?:
    | 'Sustainability'
    | 'Diversity'
    | 'Quality'
    | 'Security'
    | 'Food'
  show: 'qualified' | 'potential' | 'disqualified'
  path: string
  detailsTableData: List<RecordOf<SpendDetails>>
  qualifiedTotal: number
  disqualifiedTotal: number
  potentialTotal: number
  totalSpend: number
  selectedSubCategories?: List<string> // for export
  overviewSubCategories?: List<string> // for export
  currentGrouping?: 'subCategory' | 'category' | 'country' | 'spendGroup'
  startDate?: string // for export Economic Impact Assessment
  endDate?: string // for export Economic Impact Assessment
  spendCategories?: List<string> // for export Economic Impact Assessment
}

const SpendReportDetails = (props: Props) => {
  const {
    hideExports,
    show,
    path,
    detailsTableData,
    selectedSubCategories,
    qualifiedTotal,
    potentialTotal,
    disqualifiedTotal,
    totalSpend,
    overviewSubCategories,
    currentGrouping,
    startDate,
    endDate,
    qualificationType,
    spendCategories
  } = props

  const intl = useIntl()
  const dispatch = useDispatch()

  const isTealbot = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, 'tealbot')
  )
  const currentUserId = useSelector(sessionSelectors.getUserId)
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )
  const allUsers = useSelector(usersSelectors.getUsers)
  const exportReady = useSelector((state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'detailsExportReady'])
  )

  const [sortBy, setSortBy] = useState<keyof SortByDetails>('name')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')

  let tableRef
  const setRef = ref => {
    tableRef = ref
  }

  const getRowHeight = row => {
    if (!row) {
      return 52
    }

    const record = sortedDetailsTableData.get(row.index)
    const numberOfCertifications =
      record && record.certifications && record.certifications.size

    const numberOfAgencies =
      record &&
      record.certifications &&
      record.certifications.reduce((result, certification) => {
        return result + certification.agencies.size
      }, 0)

    const maxRows = Math.max(numberOfCertifications || 0, numberOfAgencies || 0)
    return maxRows > 1 ? maxRows * 32 + 16 : 58
  }

  const groupingSubCategories = useMemo(() => {
    return detailsTableData
      .map(item => item.get('subCategories'))
      .flatten(2)
      .toSet()
      .toList()
  }, [detailsTableData])

  const sortedDetailsTableData = useMemo(() => {
    const data = detailsTableData

    return data.sort(
      (dataA: RecordOf<SpendDetails>, dataB: RecordOf<SpendDetails>) => {
        const valueA =
          sortBy === 'name'
            ? dataA.get(sortBy) && dataA.get(sortBy).toLowerCase()
            : dataA.get(sortBy)

        const valueB =
          sortBy === 'name'
            ? dataB.get(sortBy) && dataB.get(sortBy).toLowerCase()
            : dataB.get(sortBy)

        if (!valueA || !valueB) {
          return 1
        }
        return sortDirection === 'DESC'
          ? valueA > valueB
            ? -1
            : 1
          : valueA < valueB
          ? -1
          : 1
      }
    )
  }, [detailsTableData, sortBy, sortDirection])

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
    tableRef.recomputeRowHeights()
  }

  const handleAddCertificationValidation = payload => {
    dispatch(addCertificationValidation(payload))
  }

  const handleRemoveCertificationValidation = payload => {
    dispatch(removeCertificationValidation(payload))
  }

  return (
    <>
      <div className='pl3-5 pb1 flex items-center justify-between overflow-x-auto'>
        <NavBar>
          <NavLink
            label={
              <>
                <FormattedMessage
                  id='DiversityReportDetails.Qualified'
                  defaultMessage='Qualified'
                />
                &nbsp;-&nbsp;${numberFormat(qualifiedTotal)} (
                {((qualifiedTotal / totalSpend) * 100).toFixed(2)}
                %)
              </>
            }
            to={`${path}?show=qualified`}
            isActive={() => show === 'qualified'}
          />
          <NavLink
            label={
              <>
                <FormattedMessage
                  id='DiversityReportDetails.Potential'
                  defaultMessage='Potential'
                />
                &nbsp;-&nbsp;${numberFormat(potentialTotal)} (
                {((potentialTotal / totalSpend) * 100).toFixed(2)}
                %)
              </>
            }
            to={`${path}?show=potential`}
            isActive={() => show === 'potential'}
          />
          <NavLink
            label={
              <>
                <FormattedMessage
                  id='DiversityReportDetails.Disqualified'
                  defaultMessage='Disqualified'
                />
                &nbsp;-&nbsp;${numberFormat(disqualifiedTotal)} (
                {((disqualifiedTotal / totalSpend) * 100).toFixed(2)}
                %)
              </>
            }
            to={`${path}?show=disqualified`}
            isActive={() => show === 'disqualified'}
          />
        </NavBar>
        {!hideExports && sortedDetailsTableData.size > 0 && (
          <DropdownMenu>
            <MenuItem
              disabled={!exportReady}
              onClick={() =>
                !!selectedSubCategories &&
                !!overviewSubCategories &&
                createDiversityReportForExport(
                  sortedDetailsTableData,
                  !currentGrouping || currentGrouping === 'subCategory'
                    ? selectedSubCategories?.size > 0
                      ? selectedSubCategories
                      : overviewSubCategories
                    : groupingSubCategories,
                  show,
                  orgUnitId,
                  orgUnitName,
                  false,
                  qualificationType
                )
              }
            >
              <FormattedMessage
                id='DiversityReportDetails.Export'
                defaultMessage='Export'
              />
            </MenuItem>
            <MenuItem
              disabled={!exportReady}
              onClick={() =>
                !!selectedSubCategories &&
                !!overviewSubCategories &&
                createDiversityReportForExport(
                  sortedDetailsTableData,
                  !currentGrouping || currentGrouping === 'subCategory'
                    ? selectedSubCategories?.size > 0
                      ? selectedSubCategories
                      : overviewSubCategories
                    : groupingSubCategories,
                  show,
                  orgUnitId,
                  orgUnitName,
                  true,
                  qualificationType
                )
              }
            >
              <FormattedMessage
                id='DiversityReportDetails.ExportGroupedInternalID'
                defaultMessage='Export Grouped by Internal Supplier ID'
              />
            </MenuItem>
            {isTealbot && startDate && endDate && (
              <MenuItem
                onClick={() =>
                  exportEconomicImpactAssessment(
                    sortedDetailsTableData.map(s => s.get('orgUnitId')).toJS(),
                    spendCategories?.toJS(),
                    startDate,
                    endDate,
                    show,
                    orgUnitId,
                    orgUnitName
                  )
                }
              >
                <FormattedMessage
                  id='DiversityReportDetails.ExportEconomicImpactAssessment'
                  defaultMessage='Export Economic Impact Assessment'
                />
              </MenuItem>
            )}
          </DropdownMenu>
        )}
      </div>
      <Divider className='mb3' />
      <Paper>
        <div className='mt3-5'>
          {sortedDetailsTableData.size > 0 ? (
            <Paper noPadding>
              <Table
                tableRef={setRef}
                minWidth={1100}
                rowGetter={({ index }) => sortedDetailsTableData.get(index)}
                rowCount={sortedDetailsTableData.size}
                sort={handleSortChange}
                sortDirection={sortDirection}
                sortBy={sortBy}
                rowHeight={getRowHeight}
                rowStyle={({ index }) =>
                  index > -1 && {
                    alignItems: 'start'
                  }
                }
              >
                <Column
                  label={
                    <FormattedMessage
                      id='DiversityReport.Supplier'
                      defaultMessage='Supplier'
                    />
                  }
                  aria-label='Sort by Supplier'
                  dataKey='name'
                  width={260}
                  cellRenderer={({ rowData }) => {
                    return (
                      <>
                        <Link
                          className='db'
                          to={`${parsePath(paths.supplierProfile, {
                            supplierId: rowData.orgUnitId
                          })}?scrollToDiversity=true`}
                        >
                          {rowData.name}
                        </Link>
                        <div className='mt2 f6 gray'>
                          <ExternalLink href={rowData.domain}>
                            {rowData.domain}
                          </ExternalLink>
                        </div>
                      </>
                    )
                  }}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='DiversityReport.Spend'
                      defaultMessage='Spend'
                    />
                  }
                  aria-label='Sort by Spend'
                  dataKey='totalAmount'
                  width={100}
                  cellRenderer={({ cellData }) => (
                    <div className='mt2'>${numberFormat(cellData)}</div>
                  )}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='DiversityReport.Type'
                      defaultMessage='Type'
                    />
                  }
                  dataKey='certifications'
                  width={120}
                  disableSort
                  cellRenderer={({ cellData }) =>
                    cellData.map(
                      (certification, index) =>
                        certification.subCategory && (
                          <div
                            className='pv2'
                            key={`${certification.subCategory}${index}`}
                            style={{
                              height:
                                certification.agencies.size > 1
                                  ? 32 * certification.agencies.size
                                  : undefined
                            }}
                          >
                            {startCase(certification.subCategory).toUpperCase()}
                          </div>
                        )
                    )
                  }
                />
                <Column
                  label={
                    <FormattedMessage
                      id='DiversityReport.Authority'
                      defaultMessage='Authority'
                    />
                  }
                  dataKey='certifications'
                  width={280}
                  disableSort
                  cellRenderer={({ cellData }) => {
                    const toExport: { [key: string]: boolean } = {}

                    return cellData.map(
                      certification =>
                        certification.agencies &&
                        certification.agencies.map((agency, index) => {
                          let exportIcon
                          if (!toExport[certification.get('subCategory')]) {
                            exportIcon =
                              (show === 'qualified' &&
                                agency.get('confirmed')) ||
                              (show === 'potential' &&
                                agency.getIn(['info', 'potential'])) ||
                              show === 'disqualified' ? (
                                <div className='mr1'>
                                  <Export height={12} fill='#555555' />
                                </div>
                              ) : null
                            toExport[certification.get('subCategory')] = true
                          }
                          return (
                            <div
                              className='pv2 flex'
                              key={`${certification.subCategory}${index}`}
                            >
                              {agency.get('confirmed') ? (
                                <div className='mr1'>
                                  <Tooltip
                                    title={
                                      <FormattedMessage
                                        id='DiversityReport.ConfirmedHelp1'
                                        defaultMessage='Qualified: Certificate is active and passed automated quality assurance.'
                                      />
                                    }
                                  >
                                    <Check
                                      height={12}
                                      fill='#31b800'
                                      tabIndex={0}
                                      aria-label='Check mark'
                                      aria-hidden='false'
                                    />
                                  </Tooltip>
                                </div>
                              ) : agency.getIn(['info', 'potential']) ? (
                                <div className='mr1'>
                                  <Tooltip
                                    title={
                                      <>
                                        <FormattedMessage
                                          id='DiversityReport.PotentialHelp1'
                                          defaultMessage='Potential: Issues with certificate are '
                                        />
                                        <br />
                                        {agency
                                          .getIn(['info', 'alertCodes'])
                                          ?.map(code => (
                                            <p key={code}>
                                              {`${
                                                alertCodes[code]
                                                  ? intl.formatMessage(
                                                      alertCodes[code]
                                                    )
                                                  : startCase(code)
                                              }: ${
                                                alertCodes[code]
                                                  ? intl.formatMessage(
                                                      alertCodesDescriptions[
                                                        code
                                                      ]
                                                    )
                                                  : startCase(code)
                                              }`}
                                            </p>
                                          ))}
                                      </>
                                    }
                                  >
                                    <Question
                                      height={12}
                                      fill='#fcba03'
                                      tabIndex={0}
                                      aria-label='Question mark'
                                      aria-hidden='false'
                                    />
                                  </Tooltip>
                                </div>
                              ) : null}
                              {exportIcon}
                              <div>
                                {agency.sourceURL ? (
                                  <ExternalLink href={agency.sourceURL}>
                                    {agency.certAgency || (
                                      <FormattedMessage
                                        id='DiversityReport.NotAvailable'
                                        defaultMessage='Not Available'
                                      />
                                    )}
                                  </ExternalLink>
                                ) : (
                                  agency.certAgency || (
                                    <FormattedMessage
                                      id='DiversityReport.NotAvailable'
                                      defaultMessage='Not Available'
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          )
                        })
                    )
                  }}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='DiversityReport.Expiry'
                      defaultMessage='Expiry'
                    />
                  }
                  dataKey='certifications'
                  width={70}
                  disableSort
                  cellRenderer={({ cellData }) =>
                    cellData.map(
                      certification =>
                        certification.agencies &&
                        certification.agencies.map((agency, index) => (
                          <div
                            className='pv2'
                            key={`${certification.subCategory}${index}`}
                            style={{
                              height: 32
                            }}
                          >
                            {agency.expiration && dateFormat(agency.expiration)}
                          </div>
                        ))
                    )
                  }
                />
                <Column
                  label={
                    <FormattedMessage
                      id='DiversityReport.Verified'
                      defaultMessage='Verified'
                    />
                  }
                  dataKey='certifications'
                  disableSort
                  width={195}
                  cellRenderer={({ rowData, cellData }) =>
                    cellData.map((certification, index) => {
                      const currentUser = allUsers.get(currentUserId)
                      const currentUserOrgUnitId =
                        currentUser && currentUser.get('orgUnitId')

                      const lastColleagueValidation =
                        certification.certificationValidations &&
                        certification.certificationValidations.findLast(
                          validation => {
                            const user = allUsers.get(validation.userId)
                            return (
                              user &&
                              (!user.get('roles') ||
                                !user.get('roles').includes('tealbot')) &&
                              user.get('orgUnitId') === currentUserOrgUnitId
                            )
                          }
                        )

                      const lastTealbotValidation =
                        certification.certificationValidations &&
                        certification.certificationValidations.findLast(val => {
                          const user = allUsers.get(val.userId)
                          return (
                            user &&
                            user.get('roles') &&
                            user.get('roles').includes('tealbot')
                          )
                        })

                      return (
                        <div
                          key={`${certification.subCategory}${index}`}
                          style={{ height: '32px' }}
                          className='flex items-center'
                        >
                          <CertificationValidation
                            orgUnitName={rowData.name}
                            category={
                              certification.category ||
                              qualificationType?.toLowerCase()
                            }
                            subCategory={certification.subCategory}
                            categoryTimeStamp={certification.timeStamp}
                            certAgency={certification.getIn([
                              'agencies',
                              0,
                              'certAgency'
                            ])}
                            validation={lastColleagueValidation}
                            onAddValidation={validation => {
                              handleAddCertificationValidation({
                                diversityReport:
                                  !qualificationType ||
                                  qualificationType === 'Diversity'
                                    ? true
                                    : false,
                                supplierId: rowData.orgUnitId,
                                supplierRelationshipId:
                                  rowData.supplierRelationshipId,
                                ...validation
                              })
                            }}
                            onRemoveCertification={
                              lastColleagueValidation &&
                              currentUserId === lastColleagueValidation.userId
                                ? () => {
                                    handleRemoveCertificationValidation({
                                      supplierId: rowData.orgUnitId,
                                      supplierRelationshipId:
                                        rowData.supplierRelationshipId,
                                      category:
                                        lastColleagueValidation.category,
                                      subCategory:
                                        lastColleagueValidation.subCategory,
                                      timeStamp:
                                        lastColleagueValidation.timeStamp,
                                      date: lastColleagueValidation.date
                                    })
                                  }
                                : undefined
                            }
                          />

                          {lastTealbotValidation && (
                            <div className='ml1'>
                              <CertificationValidation
                                orgUnitName={rowData.name}
                                category={certification.category}
                                subCategory={certification.subCategory}
                                categoryTimeStamp={certification.timeStamp}
                                validation={lastTealbotValidation}
                                verifiedIcon={
                                  <VerifiedIcon height={16} width={16} />
                                }
                              />
                            </div>
                          )}
                        </div>
                      )
                    })
                  }
                />
              </Table>
            </Paper>
          ) : (
            <Text>
              <FormattedMessage
                id='DiversityReportDetails.noDataFound'
                defaultMessage='There was no data found.'
              />
            </Text>
          )}
        </div>
      </Paper>
    </>
  )
}

export default SpendReportDetails
