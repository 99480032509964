import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import AddTagForm from '../../../SupplierProfile/components/AddTagForm'
import { UPDATE_VET } from '../../actionTypes'
import { FormattedMessage } from 'react-intl'
import { InjectedFormProps } from 'redux-form'
import { Map } from 'immutable'

type Props = {
  addTag: (params?: {}) => void
  vetId: string
} & InjectedFormProps

export class KeywordsAddTagForm extends Component<Props> {
  render() {
    const { handleSubmit, addTag } = this.props

    return (
      <div className='mt2'>
        <FormattedMessage
          id='KeywordsAddTagForm.KeywordsForRFI'
          defaultMessage='Keywords for this RFI (ex: Advertising or Digital Marketing)'
        >
          {message => (
            <AddTagForm
              inputId='keywordsNewTag'
              onSubmit={handleSubmit((values: Map<string, string>) =>
                addTag({
                  tagsFieldName: 'keywords',
                  vetId: this.props.vetId,
                  ...values.toJSON()
                })
              )}
              inputPlaceholder={message}
            />
          )}
        </FormattedMessage>
      </div>
    )
  }
}

const formName = 'buyer/vet/keywords'

export default connectReduxForm(
  null,
  {
    addTag: createSubmitFormAction(formName, UPDATE_VET)
  },
  {
    form: formName
  },
  KeywordsAddTagForm
)
