import { call, put } from 'redux-saga/effects'
import loader from 'shared/utils/api/loader'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { notify } from 'shared/actions'

export function* loadClientDataSaga(action) {
  const {
    attachedFile,
    body,
    deleteSpendLineItems,
    enableNewLoader
  } = action.payload.toJS()
  const parseBody = !deleteSpendLineItems
    ? body
    : body
    ? JSON.stringify(
        Object.assign({}, JSON.parse(body), { deleteSpendLineItems })
      )
    : JSON.stringify({ deleteSpendLineItems })
  const response = yield call(loader.loadClientData, {
    file: attachedFile,
    body: parseBody,
    enableNewLoader
  })

  if (response.status === 'error') {
    yield put(
      notify({
        message: response.fileLoadErrors.join('')
      })
    )
  } else {
    yield put(
      notify({
        message: 'Client data loaded'
      })
    )
  }
}

export default createApiRequestSaga(loadClientDataSaga, {
  notifyOnError: true
})
