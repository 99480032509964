import React from 'react'
import createRoutes from './routes/createRoutes'
import InsightNavBar from './components/InsightNavBar'
import InsightNavBarWrapper from 'shared/components/InsightNavBarWrapper'
import settingsSelectors from '../shared/selectors/settingsSelectors'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import useUserAccess from 'shared/utils/useUserAccess'
import { useLocation } from 'react-router-dom'

export const Insights = () => {
  const diversityReportEnabled = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'diversityReportEnabled')
  )
  const isTealbot = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, 'tealbot')
  )
  const location = useLocation()
  const userAccess = useUserAccess()
  const hideNavBar = location.pathname.includes('report-snapshot/')

  return (
    <>
      {!hideNavBar && (
        <InsightNavBarWrapper>
          <InsightNavBar
            diversityReportEnabled={diversityReportEnabled}
            isTealbot={isTealbot}
          />
        </InsightNavBarWrapper>
      )}
      {createRoutes({
        userAccess
      })}
    </>
  )
}

export default Insights
