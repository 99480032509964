import React, { Component } from 'react'
import Avatar from 'shared/components/Avatar'
import Divider from 'shared/components/Divider'
import Pill from 'shared/components/Pill'
import Link from 'shared/components/Link'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { List } from 'immutable'

const styles = {
  wrapper: 'mt3',
  avatar: 'v-mid w2',
  name: 'f7 fw4 mid-gray ma0'
}

type Props = {
  userId: string
  firstName: string
  lastName: string
  profilePictureUrl: string
  tags: List<string>
}

class ColleagueCard extends Component<Props> {
  render() {
    const { userId, firstName, lastName, profilePictureUrl, tags } = this.props
    return (
      <div className={styles.wrapper}>
        <Link to={`${parsePath(paths.userprofile, { userId })}`}>
          <Avatar
            url={profilePictureUrl}
            className={styles.avatar}
            name={`${firstName || ''} ${lastName || ''}`}
          />
        </Link>
        <div className='v-mid dib ml3'>
          <Link to={`${parsePath(paths.userprofile, { userId })}`}>
            <h5 className={styles.name}>
              {firstName} {lastName}
            </h5>
          </Link>
        </div>
        {tags && (
          <div className='mv2' role='list'>
            {tags.map(tag => (
              <Pill key={tag} label={tag} />
            ))}
          </div>
        )}
        <Divider />
      </div>
    )
  }
}

export default ColleagueCard
