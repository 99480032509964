import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { fromJS, RecordOf, List } from 'immutable'
import { createSelector } from 'reselect'
import moment from 'moment'
import { getOutreachList } from '../../store/actions'
import SupplierOutreach from '../../components/SupplierOutreach'
import RootState from 'shared/models/RootState'
import Task from 'shared/models/Task'
import fileToUrl from 'shared/utils/data/fileToUrlImmutable'
import Loading from 'shared/components/Loading'

type Props = {
  supplierOutreachTasks: List<RecordOf<Task>>
  isLoading: boolean
}

const SupplierOutreachContainer = (props: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getOutreachList({
        entity: 'supplierOutreachTasks',
        query: 'tasks/supplierOutreach'
      })
    )
  }, [dispatch])

  return props.isLoading ? <Loading /> : <SupplierOutreach {...props} />
}

export const getAllSupplierOutreachTasks = createSelector(
  (state: RootState) => state.getIn(['data', 'supplierOutreachTasks']),
  allSupplierOutreachTasks => {
    return (allSupplierOutreachTasks || fromJS({}))
      .toList()
      .sort(
        (task1: RecordOf<Task>, task2: RecordOf<Task>) =>
          new Date(task2.getIn(['created', 'date'])).valueOf() -
          new Date(task1.getIn(['created', 'date'])).valueOf()
      )
      .map((supplierOutreachTask: RecordOf<Task>) =>
        supplierOutreachTask.merge({
          expiryDate: new Date(
            supplierOutreachTask.getIn([
              'form',
              'attachment',
              'expiry',
              'date_time'
            ])
          ),
          dateStarted: supplierOutreachTask.getIn(['form', 'dateStarted']),
          dateLastViewed: supplierOutreachTask.getIn([
            'form',
            'dateLastViewed'
          ]),
          dateLastAccessed: supplierOutreachTask.getIn([
            'form',
            'dateLastAccessed'
          ]),
          status: supplierOutreachTask.get('status'),
          id: supplierOutreachTask.get('id'),
          requestStatusOpen: true,
          supplierId: supplierOutreachTask.getIn(['form', 'supplierId']),
          relationshipId: supplierOutreachTask.getIn([
            'form',
            'relationshipID'
          ]),
          supplier: supplierOutreachTask.getIn(['form', 'supplier']),
          type: supplierOutreachTask.get('type'),
          dateCreated: moment(
            supplierOutreachTask.getIn(['created', 'date'])
          ).format(),
          dateCompleted: moment(
            supplierOutreachTask.getIn(['statusChanges', -1, 'date'])
          ).format(),
          completedById: supplierOutreachTask.getIn([
            'statusChanges',
            -1,
            'user'
          ]),
          requestedContactId: supplierOutreachTask.getIn([
            'form',
            'requestedContactId'
          ]),
          additionalRequestContactIds: supplierOutreachTask.getIn([
            'form',
            'additionalRequestContactIds'
          ]),
          certAgency: supplierOutreachTask.getIn(['form', 'certAgency']),
          subCategory: supplierOutreachTask.getIn(['form', 'subCategory']),
          certUploadedTime: new Date(
            supplierOutreachTask.getIn(['form', 'certUploadedTime'])
          ),
          expiringAttachment: {
            created: {
              date: supplierOutreachTask.getIn([
                'form',
                'attachment',
                'created',
                'date'
              ]),
              user: supplierOutreachTask.getIn([
                'form',
                'attachment',
                'created',
                'user'
              ])
            },
            documentFileUrl: fileToUrl(
              supplierOutreachTask.getIn(['form', 'attachment', 'document'])
            ),
            expiry: supplierOutreachTask.getIn([
              'form',
              'attachment',
              'expiry',
              'date_time'
            ]),
            note: supplierOutreachTask.getIn(['form', 'attachment', 'note']),
            type: supplierOutreachTask.getIn(['form', 'attachment', 'type'])
          }
        })
      )
  }
)

export default connect((state: RootState) => {
  return {
    isLoading: state.getIn(['data', 'isLoading', 'supplierOutreachTasks']),
    supplierOutreachTasks: getAllSupplierOutreachTasks(state)
  }
})(SupplierOutreachContainer)
