import { fromJS, OrderedMap, Map, List } from 'immutable'
import { createSelector } from 'reselect'
import usersSelectors from 'shared/selectors/usersSelectors'

export const getByEntity = (state, entityName) =>
  state.getIn(['data', entityName], fromJS({}))

export const getById = (state, entityName, entityId) => {
  return state.getIn(['data', entityName, entityId], fromJS({}))
}

export const isLoading = (state, entityName) =>
  !!state.getIn(['data', 'isLoading', entityName])

// supplier relationships selectors
const supplierRelationshipEntityName = 'supplierRelationships'
const orgUnitsEntityName = 'orgUnits'

export const getSupplierClients = createSelector(
  state => getByEntity(state, supplierRelationshipEntityName),
  state => getByEntity(state, orgUnitsEntityName),
  (supplierRelationships, orgUnits) =>
    !supplierRelationships
      ? fromJS([])
      : supplierRelationships
          .toList()
          .filter(item => !!item.getIn(['supplierCollaboration', 'isClient']))
          .sort((relA, relB) => {
            const buyerA = orgUnits.getIn([relA.get('buyer'), 'name']) || ''
            const buyerB = orgUnits.getIn([relB.get('buyer'), 'name']) || ''
            return buyerA.toUpperCase() > buyerB.toUpperCase() ? 1 : -1
          })
)

export const getSupplierClientsID = createSelector(
  getSupplierClients,
  clients => clients.map(item => item.get('id'))
)

export const getSupplierRelationshipByBuyerId = createSelector(
  (state, buyerId) => getByEntity(state, supplierRelationshipEntityName),
  (state, buyerId) => buyerId,
  (supplierRelationships, buyerId) => {
    return (
      supplierRelationships &&
      supplierRelationships.find(sr => sr.get('buyer') === buyerId)
    )
  }
)

export const getClientFiles = state => state.getIn(['data', 'clientFiles'])

export const getOrgUnitClientFiles = state =>
  state.getIn(['data', 'orgUnitClientFiles'])

export const getTier2CommunityBuyers = createSelector(
  state =>
    state.getIn([
      'supplier',
      'tier2',
      'tier2Communities',
      'tier2CommMemberships'
    ]),
  tier2Communities => {
    tier2Communities = tier2Communities?.filter(
      comm => comm.get('status') === 'Accepted'
    )
    if (tier2Communities && tier2Communities.size) {
      return tier2Communities.reduce((result, currentCommunity) => {
        if (!result.get(currentCommunity.get('buyerId'))) {
          result = result.set(
            currentCommunity.get('buyerId'),
            fromJS({
              buyerId: currentCommunity.get('buyerId'),
              buyerName: currentCommunity.get('buyerName')
            })
          )
        }
        return result
      }, Map())
    } else {
      return Map()
    }
  }
)
const createStagingHistoryByStatusSelector = status => {
  return createSelector(
    state => state.getIn(['data', 'stagingHistory']),
    usersSelectors.getUsers,
    getTier2CommunityBuyers,
    (stagingHistory, users, tier2CommByBuyer) => {
      return (
        stagingHistory &&
        stagingHistory
          .filter(staging => {
            if (
              staging.get('listType') === 'supplierTier2Load' ||
              staging.get('ui')
            ) {
              return true
            }
            if (
              staging.get('clientFileName') &&
              (!status || staging.get('status') === status)
            ) {
              return true
            } else {
              return false
            }
          })
          .map(staging => {
            return staging
              .updateIn(['uploadBy'], uploadBy => {
                const user = users.get(uploadBy)
                return user
                  ? `${user.get('firstName') || ''} ${user.get('lastName') ||
                      ''}`
                  : ''
              })
              .updateIn(['buyers'], buyer => {
                let tempStagingBuyer = List([])
                if (!!buyer && buyer.size) {
                  buyer.forEach(buyerId => {
                    if (tier2CommByBuyer.get(buyerId)) {
                      tempStagingBuyer = tempStagingBuyer.push(
                        tier2CommByBuyer.get(buyerId)
                      )
                    }
                  })
                  return tempStagingBuyer
                } else {
                  return tier2CommByBuyer.valueSeq()
                }
              })
          })
          .sort((staging1, staging2) => {
            if (
              `${staging1.get('year')}${staging1.get('quarter')}` >
              `${staging2.get('year')}${staging2.get('quarter')}`
            ) {
              return -1
            } else if (
              `${staging1.get('year')}${staging1.get('quarter')}` <
              `${staging2.get('year')}${staging2.get('quarter')}`
            ) {
              return 1
            } else {
              return staging1.get('uploadDate') < staging2.get('uploadDate')
                ? 1
                : -1
            }
          })
          .reduce((result, staging) => {
            return result.has(
              `${staging.get('year')}-${staging.get('quarter')}`
            )
              ? result.updateIn(
                  [`${staging.get('year')}-${staging.get('quarter')}`],
                  list => list.push(staging)
                )
              : result.set(
                  `${staging.get('year')}-${staging.get('quarter')}`,
                  fromJS([staging])
                )
          }, OrderedMap({}))
      )
    }
  )
}

export const getCompletedStaging = createStagingHistoryByStatusSelector(
  'complete'
)
export const getStagingHistory = createStagingHistoryByStatusSelector()

export const getStagingSerp = createSelector(
  state => state.getIn(['data', 'stagingHistory']),
  usersSelectors.getUsers,
  (stagingHistory, users) => {
    return (
      stagingHistory &&
      stagingHistory
        .filter(staging => !!staging.get('clientFileName'))
        .map(staging =>
          staging.updateIn(['uploadBy'], uploadBy => {
            const user = users.get(uploadBy)
            return user
              ? `${user.get('firstName') || ''} ${user.get('lastName') || ''}`
              : ''
          })
        )
        .sort((staging1, staging2) => {
          return staging1.get('uploadDate') < staging2.get('uploadDate')
            ? 1
            : -1
        })
    )
  }
)

export const getStagingById = createSelector(
  (state, stagingId) => state.getIn(['data', 'stagingHistory']),
  (state, stagingId) => stagingId,
  getTier2CommunityBuyers,
  (stagingHistory, stagingId, tier2CommByBuyer) => {
    return stagingHistory
      ?.find(staging => staging.get('id') === stagingId)
      ?.updateIn(['buyers'], buyer => {
        let tempStagingBuyer = List([])
        if (!!buyer && buyer.size) {
          buyer.forEach(buyerId => {
            if (tier2CommByBuyer.get(buyerId)) {
              tempStagingBuyer = tempStagingBuyer.push(
                tier2CommByBuyer.get(buyerId)
              )
            }
          })
          return tempStagingBuyer
        } else {
          return tier2CommByBuyer.valueSeq()
        }
      })
  }
)
