import React from 'react'
import { List } from 'immutable'
import Text from 'shared/components/Text'
import { FormattedMessage, useIntl } from 'react-intl'
import { alertCodes } from 'shared/utils/data/defineMessages'
import startCase from 'lodash.startcase'
import { RecordOf } from 'immutable'
import ExternalLink from 'shared/components/ExternalLink'
import Tooltip from 'shared/components/Tooltip'
import Naics from 'shared/models/Naics'
import { alertCodesDescriptions } from 'shared/utils/data/defineMessages'

type Props = {
  certName?: string
  certAddress?: string
  certDuns?: string
  certParentDuns?: string
  websiteUrl?: string
  contact?: RecordOf<{
    name: string
    email: string
    phone: string
  }>
  confirmed?: boolean
  potential?: boolean
  alertCodes?: List<string>
  infoCodes?: List<string>
  validationCodes?: List<string>
  naicsCodes?: List<RecordOf<{ code: string; primary: boolean }>>
}

const CertAttributes = (props: Props) => {
  const intl = useIntl()

  return (
    <>
      {props.certName && (
        <Text>
          <FormattedMessage
            id='DiversitySectionContainer.CertName'
            defaultMessage='Certified Name'
          />
          : {props.certName}
        </Text>
      )}
      {props.certAddress && (
        <Text>
          <FormattedMessage
            id='DiversitySectionContainer.CertAddress'
            defaultMessage='Certified Address'
          />
          : {props.certAddress}
        </Text>
      )}
      {(props.certDuns || props.certParentDuns) && (
        <Text>
          {props.certDuns && (
            <>
              <FormattedMessage
                id='DiversitySectionContainer.CertDuns'
                defaultMessage='Certified DUNS'
              />
              : {props.certDuns}
            </>
          )}
          {props.certParentDuns && (
            <>
              {props.certDuns && ', '}
              <FormattedMessage
                id='DiversitySectionContainer.CertParentDuns'
                defaultMessage='Certified Parent DUNS'
              />
              : {props.certParentDuns}
            </>
          )}
        </Text>
      )}
      {props.websiteUrl && (
        <Text>
          <FormattedMessage
            id='DiversitySectionContainer.Website'
            defaultMessage='Website'
          />
          :{' '}
          <ExternalLink href={props.websiteUrl}>
            {props.websiteUrl}
          </ExternalLink>
        </Text>
      )}
      {props.contact && (
        <Text>
          <FormattedMessage
            id='DiversitySectionContainer.Contact'
            defaultMessage='Contact'
          />
          : {props.contact.get('name')}{' '}
          {props.contact.get('email') && `, ${props.contact.get('email')}`}{' '}
          {props.contact.get('phone') && `, ${props.contact.get('phone')}`}
        </Text>
      )}
      {!!props.naicsCodes?.size && (
        <div>
          <Tooltip
            title={
              <div>
                {props.naicsCodes
                  .sort(naics => (naics.get('primary') ? -1 : 1))
                  .map(naics => (
                    <div
                      key={naics.get('code')}
                      className='dt mv1'
                      aria-label={`${naics.get('code')} ${
                        Naics[naics.get('code')]?.defaultMessage
                      }`}
                    >
                      <div className='dtc w3'>
                        <Text>{naics.get('code')}</Text>
                      </div>
                      <div className='dtc w-auto'>
                        <Text>
                          {Naics[naics.get('code')] ? (
                            <FormattedMessage {...Naics[naics.get('code')]} />
                          ) : (
                            naics.get('code')
                          )}
                          &nbsp;
                          {naics.get('primary') && (
                            <FormattedMessage
                              id='Primary'
                              defaultMessage='Primary'
                            >
                              {message => <strong>({message})</strong>}
                            </FormattedMessage>
                          )}
                        </Text>
                      </div>
                    </div>
                  ))}
              </div>
            }
            aria-live='polite'
          >
            <span className='dib underline f7 fw6 mid-gray pointer'>
              <FormattedMessage
                id='CertAttributes.Naics'
                defaultMessage='{count} NAICS {count, plural, one {Category} other {Categories}}'
                values={{ count: props.naicsCodes.size }}
              />
            </span>
          </Tooltip>
        </div>
      )}
      {!!props.validationCodes?.size && (
        <div>
          <Tooltip
            title={
              <div>
                {props.validationCodes?.map(code => (
                  <Text
                    className='mv2'
                    key={code}
                    aria-label={`${
                      alertCodes[code]
                        ? alertCodesDescriptions[code]
                        : startCase(code)
                    }`}
                  >
                    {`${
                      alertCodes[code]
                        ? intl.formatMessage(alertCodes[code])
                        : startCase(code)
                    } (${code}): ${
                      alertCodes[code]
                        ? intl.formatMessage(alertCodesDescriptions[code])
                        : startCase(code)
                    }`}
                  </Text>
                ))}
              </div>
            }
            aria-live='polite'
          >
            <div className='dib underline f7 fw6 mid-gray pointer'>
              <FormattedMessage
                id='CertAttributes.ValidationCodes'
                defaultMessage='{count} Validation {count, plural, one {Code} other {Codes}}'
                values={{ count: props.validationCodes.size }}
              />
            </div>
          </Tooltip>
        </div>
      )}
      {!!props.alertCodes?.size && (
        <div>
          <Tooltip
            title={
              <div>
                {props.alertCodes?.map(code => (
                  <Text
                    className='mv2'
                    key={code}
                    aria-label={`${
                      alertCodes[code]
                        ? alertCodesDescriptions[code].defaultMessage
                        : startCase(code)
                    }`}
                  >
                    {`${
                      alertCodes[code]
                        ? intl.formatMessage(alertCodes[code])
                        : startCase(code)
                    } (${code}): ${
                      alertCodes[code]
                        ? intl.formatMessage(alertCodesDescriptions[code])
                        : startCase(code)
                    }`}
                  </Text>
                ))}
              </div>
            }
            aria-live='polite'
          >
            <div className='dib underline f7 fw6 gold pointer'>
              <FormattedMessage
                id='CertAttributes.AlertCodes'
                defaultMessage='{count} Alert {count, plural, one {Code} other {Codes}}'
                values={{ count: props.alertCodes.size }}
              />
            </div>
          </Tooltip>
        </div>
      )}
      {!!props.infoCodes?.size && (
        <div>
          <Tooltip
            title={
              <div>
                {props.infoCodes?.map(code => (
                  <Text
                    className='mv2'
                    key={code}
                    aria-label={`${
                      alertCodes[code]
                        ? alertCodesDescriptions[code].defaultMessage
                        : startCase(code)
                    }`}
                  >
                    {`${
                      alertCodes[code]
                        ? intl.formatMessage(alertCodes[code])
                        : startCase(code)
                    } (${code}): ${
                      alertCodes[code]
                        ? intl.formatMessage(alertCodesDescriptions[code])
                        : startCase(code)
                    }`}
                  </Text>
                ))}
              </div>
            }
            aria-live='polite'
          >
            <div className='dib underline f7 fw6 mid-gray pointer'>
              <FormattedMessage
                id='CertAttributes.InfoCodes'
                defaultMessage='{count} Info {count, plural, one {Code} other {Codes}}'
                values={{ count: props.infoCodes.size }}
              />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  )
}

export default CertAttributes
