import jsPDF from 'jspdf-yworks'
import certificateBackground from './sustainabilitySurveyCertificate.png'
import moment from 'moment'

type cert = {
  company: string
  signBy: string
  signOn: Date
  email: string
  certId: string
}

function toDataURL(url: string, callback: (data) => void) {
  var xhr = new XMLHttpRequest()
  xhr.onload = function() {
    var reader = new FileReader()
    reader.onloadend = function() {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

const generateSustainabilitySurveyCertificate = (data: cert) => {
  const { company, signBy, signOn, email, certId } = data

  return new Promise((resolve, reject) => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [1640, 1148]
    })

    toDataURL(certificateBackground, dataURL => {
      try {
        doc.addImage(
          dataURL,
          'JPG',
          0,
          0,
          doc.internal.pageSize.width,
          doc.internal.pageSize.height
        )
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(56)
        doc.text(company, doc.internal.pageSize.width / 2, 430, {
          align: 'center'
        })

        doc.setFontSize(28)

        doc.text(company, 382, 925)

        doc.text(`${signBy}, ${company}, ${email}`, 262, 977, { maxWidth: 600 })

        doc.text(certId, 1311, 980, { maxWidth: 300 })

        doc.text(moment(signOn).format('LL'), 1311, 1020)

        const blobPdf = new Blob([doc.output('blob')], {
          type: 'application/pdf'
        })
        blobPdf['filename'] = 'certificate.pdf'
        resolve(blobPdf)
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  })
}

export default generateSustainabilitySurveyCertificate
