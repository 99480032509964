export const agencyGroup = {
  top: [
    `Self Certify`,
    `System for Award Management`,
    `Federal Aviation Administration`,
    `U.S. Department of Veterans Affairs (certified by SBA effective 1/1/2023)`,
    `U.S. Department of Housing and Urban Development (HUD)`,
    `Women‘s Business Enterprise National Council (WBENC)`,
    `National Minority Supplier Development Council (NMSDC)`,
    `National LGBT Chamber of Commerce (NGLCC)`,
    `Canadian Council for Aboriginal Business (CCAB)`,
    `Canadian Aboriginal and Minority Supplier Council (CAMSC)`,
    `WEConnect International`,
    `National Veteran Business Development Council (NVBDC)`,
    `U.S. Small Business Administration (SBA)`,
    `Crown-Indigenous Relations and Northern Affairs Canada (CIRNAC)`,
  ].sort((a1, a2) => {
    return a1 === `Self Certify`
      ? -1
      : a2 === `Self Certify`
      ? 1
      : a1 < a2
      ? -1
      : 1
  }),
  state: [
    `State of Texas`,
    `Virginia Department of Small Business and Supplier Diversity`,
    `Cal eProcure`,
    `NYC Small Business Services`,
    `State of North Carolina electronic Vendor Portal System`,
    `Maryland Department of Transportation`,
    `The Port Authority of New York & New Jersey`,
    `New York State Contract System`,
    `State of Connecticut`,
    `Florida Department of Management Services`,
    `Texas Unified Certification Program (TUCP)`,
    `Washington’s Electronic Business Solution (WEBS)`,
    `State of Illinois`,
    `Pennsylvania Department of General Services`,
    `State of New Jersey Department of Treasury`,
    `Oregon Certification Office for Business Inclusion & Diversity`,
    `Commonwealth of Massachusetts`,
    `California Department of Transportation (Caltrans)`,
    `Washington State Office of Minority & Women's Business Enterprises`,
  ].sort(),
}

const rank = (agency: string = '') => {
  switch (agency) {
    case 'Self Certify':
      return 100
    case 'System for Award Management':
      return 95
    case 'federal aviation administration':
      return 94
    case 'U.S. Department of Veterans Affairs (certified by SBA effective 1/1/2023)':
      return 93
    case 'U.S. Department of Housing and Urban Development (HUD)':
      return 92
    case `Women‘s Business Enterprise National Council (WBENC)`:
      return 80
    case 'National Minority Supplier Development Council (NMSDC)':
      return 79
    case 'National LGBT Chamber of Commerce (NGLCC)':
      return 78
    case 'Canadian Council for Aboriginal Business (CCAB)':
      return 77
    case 'Canadian Aboriginal and Minority Supplier Council (CAMSC)':
      return 76
    case 'WEConnect International':
      return 75
    case 'National Veteran Business Development Council (NVBDC)':
      return 74
    case 'Crown-Indigenous Relations and Northern Affairs Canada (CIRNAC)':
      return 73
    case 'State of Texas':
      return 60
    case 'Virginia Department of Small Business and Supplier Diversity':
      return 59
    case 'Cal eProcure':
      return 58
    case 'NYC Small Business Services':
      return 57
    case 'State of North Carolina electronic Vendor Portal System':
      return 56
    case 'Maryland Department of Transportation':
      return 55
    case 'The Port Authority of New York & New Jersey':
      return 54
    case 'New York State Contract System':
      return 53
    case 'State of Connecticut':
      return 52
    case 'Florida Department of Management Services':
      return 51
    case 'Texas Unified Certification Program (TUCP)':
      return 50
    case `Washington’s Electronic Business Solution (WEBS)`:
      return 49
    case 'State of Illinois':
      return 48
    case 'Pennsylvania Department of General Services':
      return 47
    case 'State of New Jersey Department of Treasury':
      return 46
    case 'Oregon Certification Office for Business Inclusion & Diversity':
      return 45
    case 'Commonwealth of Massachusetts':
      return 44
    case 'California Department of Transportation (Caltrans)':
      return 43
    case `Washington State Office of Minority & Women's Business Enterprises`:
      return 42
    case `U.S. Small Business Administration (SBA)`:
      return 41
    default:
      return 10
  }
}

export default rank
