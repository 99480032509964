import { DiversityTypeResponse } from '../../../../admin/components/SapLoader/types'
import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import staging from 'shared/utils/api/staging'

const SapLoaderRoutes = {
  diversityInfo: 'partner/sap/diversityInfo',
  supplierList: 'client/sap/supplierList'
}

export const loadClientData = data => {
  const endpointUrl = data.enableNewLoader
    ? 'client/client'
    : 'client/clientLegacy'
  return api.postFile(`${apiRoutes.loader}/${endpointUrl}`, data)
}

export const getStatus = () => api.get(`${apiRoutes.authService}/stat/status`)

export const getClientLoaderStatus = (labels?: string) =>
  api.get(
    `${apiRoutes.authService}/stat/loaderStats${
      labels ? `?label=${labels}` : ''
    }`
  )

export const uploadSpend = data =>
  api.postFile(`${apiRoutes.loader}/client/client`, data).then(() => {
    return staging.getStagingHistory({
      listType: data.listType
    })
  })

export const sapLoaderFileUpload = ({
  attachedFile,
  fileType
}: {
  attachedFile: File
  fileType: string
}) => {
  return api.postFile(`${apiRoutes.loader}/${SapLoaderRoutes[fileType]}`, {
    fileType,
    file: attachedFile
  })
}

export const uploadTier2Spend = data =>
  api.postFile(`${apiRoutes.loader}/tier2/tier2SpendFile`, data)

export const sapDiversityFileUploadHistory = () => {
  return api.get(`${apiRoutes.loader}/partner/sap/mapping/list`)
}

export const getSapDiversityFile = (
  uploadId: string
): Promise<DiversityTypeResponse> => {
  return api.get(
    `${apiRoutes.loader}/partner/sap/diversityInfo/mapped/${uploadId}`
  )
}

export const getSupplierListFile = (uploadId: string): Promise<any[]> => {
  return api.get(`${apiRoutes.loader}/partner/sap/vendorDiversity/${uploadId}`)
}
