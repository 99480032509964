import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import profileSelectors from 'buyer/SupplierProfile/selectors/profileSelectors'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import PageSection from 'shared/components/PageSection'
import PageAnswers from 'buyer/Communities/components/PageAnswers'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import { useParams } from 'react-router'
import Select from 'shared/components/Select'
import { List, RecordOf } from 'immutable'

type Props = {
  formsByCommunityId: List<
    RecordOf<{
      form: RecordOf<{
        pages: List<
          RecordOf<{
            id: string
            title: string
            questions: List<RecordOf<{ question: string }>>
          }>
        >
      }>
      id: string
      membershipId: string
    }>
  >
  selectForm: List<RecordOf<{ key: string; label: string }>>
}
const FormResponse = memo(({ formsByCommunityId, selectForm }: Props) => {
  const [selectedForm, setSelectedForm] = useState<string>('')

  return (
    <Page
      title={
        <FormattedMessage
          id='FormResponse.FormResponses'
          defaultMessage='Form Responses'
        />
      }
    >
      <Main>
        {selectForm.size === 1 ? (
          formsByCommunityId.map(community =>
            community.getIn(['form', 'pages']).map(page => {
              return (
                <PageSection key={page.get('id')} title={page.get('title')}>
                  <PageAnswers
                    pageQuestions={page.get('questions')}
                    membershipId={community.get('membershipId')}
                  />
                </PageSection>
              )
            })
          )
        ) : (
          <>
            <label htmlFor='selectform' className='visuallyhidden'>
              <FormattedMessage
                id='FormResponse.SelectForm'
                defaultMessage={'Select a form: '}
              />
            </label>
            <Select
              name='selectform'
              value={selectedForm}
              onChange={e => setSelectedForm(e.target.value)}
            >
              <FormattedMessage
                id='FormResponse.PlaceholderSelectForm'
                defaultMessage='Select a form to view'
              >
                {message => (
                  <option disabled value=''>
                    {message}
                  </option>
                )}
              </FormattedMessage>

              {selectForm.map((form, index) => (
                <option key={form.get('key')} value={form.get('key')}>
                  {form.get('label')}
                </option>
              ))}
            </Select>
            {selectedForm &&
              formsByCommunityId
                .filter(form => form.get('id') === selectedForm)
                .map(community =>
                  community.getIn(['form', 'pages']).map(page => {
                    return (
                      <PageSection
                        key={page.get('id')}
                        title={page.get('title')}
                      >
                        <PageAnswers
                          pageQuestions={page.get('questions')}
                          membershipId={community.get('membershipId')}
                        />
                      </PageSection>
                    )
                  })
                )}
          </>
        )}
      </Main>
    </Page>
  )
})

const FormResponseContainer = () => {
  const params = useParams<{ supplierId: string }>()
  const communityForms = useSelector((state: RootState) =>
    profileSelectors.getFormResponses(state, params.supplierId)
  )
  const formsByCommunityId = communityForms.get('formsByCommunityId')
  const selectForm = communityForms.get('availableForms')
  return formsByCommunityId ? (
    <FormResponse
      formsByCommunityId={formsByCommunityId}
      selectForm={selectForm}
    />
  ) : null
}

export default FormResponseContainer
