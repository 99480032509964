import React from 'react'
import Link from 'shared/components/Link'
import { RecordOf, List } from 'immutable'
import Avatar from 'shared/components/Avatar'
import Popper from 'shared/components/Popper'
import Text from 'shared/components/Text'
import OrgLogo from 'shared/components/OrgLogo'
import ListItem from 'shared/components/List/ListItem'
import paths from 'buyer/routes/paths'
import { RecentSuppliers } from 'posting/App'
import dateFormat from 'shared/utils/dateFormat'
import Scrollable from 'shared/components/Scrollable'

const styles = {
  avatar: 'br-100 w-75',
  avatarWrapper: 'dtc w2',
  popper: 'mw5',
  contentWrapper: 'dtc v-top',
  name: 'f7 fw4 ma0 mid-gray pointer lh-copy dib',
  date: 'f8 fw4 ma0 gray lh-copy dib ml2',
  comment: 'f8 fw4 ma0 mid-gray lh-copy mt1'
}

type Props = {
  recentSuppliers: List<RecordOf<RecentSuppliers>>
}
const RecentCommentsOnSupplier = ({ recentSuppliers }: Props) => {
  return (
    <Scrollable role='list'>
      {recentSuppliers.map((supplier, i) => (
        <ListItem
          key={supplier.get('name')}
          mini
          avatarAlignTop
          leftAvatar={
            <Link
              to={paths.supplierProfile}
              params={{ supplierId: supplier.get('id') }}
            >
              <OrgLogo
                url={
                  supplier.get('logoUrl') ||
                  `https://logo.clearbit.com/${supplier.get('domains').first()}`
                }
                name={supplier.get('name')}
              />
            </Link>
          }
          primaryText={
            <Link
              to={paths.supplierProfile}
              params={{ supplierId: supplier.get('id') }}
            >
              {supplier.get('name')}
            </Link>
          }
          secondaryText={
            <Popper
              id={`recent-activity-popper${i}`}
              styles={styles.popper}
              content={
                <>
                  <div className={styles.avatarWrapper}>
                    {supplier.getIn(['activeUser', 'profilePictureUrl']) ? (
                      <Avatar
                        url={supplier.getIn([
                          'activeUser',
                          'profilePictureUrl'
                        ])}
                        className={styles.avatar}
                        name={`${supplier.getIn(['activeUser', 'firstName']) ||
                          ''} ${supplier.getIn(['activeUser', 'lastName']) ||
                          ''}`}
                      />
                    ) : (
                      <Avatar
                        className={styles.avatar}
                        name={`${supplier.getIn(['activeUser', 'firstName']) ||
                          ''} ${supplier.getIn(['activeUser', 'lastName']) ||
                          ''}`}
                      />
                    )}
                  </div>
                  <div className={styles.contentWrapper}>
                    <h5 className={styles.name}>
                      {supplier.getIn(['activeUser', 'firstName'])}{' '}
                      {supplier.getIn(['activeUser', 'lastName'])}
                    </h5>
                    <h5 className={styles.date}>
                      {dateFormat(
                        supplier.getIn(['recentMessage', 'created', 'date'])
                      )}
                    </h5>
                  </div>
                  <div className={styles.comment}>
                    {supplier.getIn(['recentMessage', 'body'])}
                  </div>
                </>
              }
            >
              <Link
                to={`${paths.supplierProfile}/mycompany?scrollToActivities=true`}
                params={{
                  supplierId: supplier.get('id')
                }}
              >
                <Text
                  secondary
                  truncate
                  truncateLines={2}
                  hideShowMoreButton={true}
                >
                  {supplier.getIn(['recentMessage', 'body'])}
                </Text>
              </Link>
            </Popper>
          }
        />
      ))}
    </Scrollable>
  )
}

export default RecentCommentsOnSupplier
