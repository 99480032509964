import React, { useMemo } from 'react'
import T2LoadPage from '../../components/LoadPage/T2LoadPage'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import { List, RecordOf, OrderedMap } from 'immutable'
import { publishSpendT2, loadStagingHistory, notify } from 'shared/actions'
import dataSelectors from 'shared/selectors/dataSelectors'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import paths from '../../diversityPaths'
import { Switch, Route } from 'react-router'
import companySelectors from 'supplier/shared/selectors/companySelectors'
import { UploadHistoryTypeT2 } from 'supplier/Insight/components/LoadPage/UploadSpendDetail/UploadSpendDetail'
import sessionSelectors from 'shared/selectors/sessionSelectors'

type Tier2Communities = List<
  RecordOf<{
    communityId: string
    buyerName: string
    status: string
  }>
>
export type UploadHistoryType = {
  id: string
  clientFileName: string
  year: number
  quarter: string
  uploadDate: Date
  uploadBy: string
  status: string
  fileLoadErrors: Array<string>
}
type Props = {
  uploadHistoryV2?: OrderedMap<string, List<RecordOf<UploadHistoryTypeT2>>>
  publishSpendT2: (payload: {
    stagingId: string
    listType: string
    buyerIds: Array<string>
  }) => void
  communities: Tier2Communities
  loadStagingHistory: (payload: { listType: string }) => void
  notify: (payload: { message: string }) => void
  country?: string
  isLoading: boolean
  hasConciergeRole: boolean
}

const LoadPageContainer = ({
  uploadHistoryV2,
  publishSpendT2,
  loadStagingHistory,
  communities,
  notify,
  isLoading,
  hasConciergeRole
}: Props) => {
  const hasAcceptedInvite = useMemo(
    () =>
      communities &&
      communities.some(supplier => supplier.get('status') === 'Accepted'),
    [communities]
  )

  return !hasAcceptedInvite ? (
    <Text className='mt3'>
      <FormattedMessage
        id='LoadPageContainer.NoDataMessage'
        defaultMessage='You need to accept at least one invitation to start loading spend.'
      />
    </Text>
  ) : (
    <Switch>
      <Route exact path={paths.diversityLoad}>
        <T2LoadPage
          uploadHistory={uploadHistoryV2}
          publishSpendT2={publishSpendT2}
          loadStagingHistory={loadStagingHistory}
          notify={notify}
          isLoading={isLoading}
          hasConciergeRole={hasConciergeRole}
        />
      </Route>
    </Switch>
  )
}

export default connect(
  (state: RootState) => {
    const locations = companySelectors.getCompanySupplierField(
      state,
      'locations'
    )
    const clientConcierge = sessionSelectors.userHasRole(
      state,
      'clientConcierge'
    )
    const supplierConcierge = sessionSelectors.userHasRole(
      state,
      'supplierConcierge'
    )
    const isConcierge = sessionSelectors.userHasRole(state, 'tealbot')
    return {
      uploadHistoryV2: dataSelectors.getStagingHistory(state),
      communities: state.getIn([
        'supplier',
        'tier2',
        'tier2Communities',
        'tier2CommMemberships'
      ]),
      country: locations?.getIn([0, 'components', 'country']),
      isLoading: dataSelectors.isLoading(state, 'stagingHistory'),
      hasConciergeRole: clientConcierge || supplierConcierge || isConcierge
    }
  },
  {
    publishSpendT2,
    loadStagingHistory,
    notify
  }
)(LoadPageContainer)
