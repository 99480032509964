import React, {
  Fragment,
  useEffect,
  FunctionComponent,
  useState,
  ReactNode,
} from 'react'
import { FormattedMessage } from 'react-intl'
import Loading from 'shared/components/Loading'
import bowser from 'bowser'
import Button from '../Button'

const browser = bowser.getParser(window.navigator.userAgent)
const browserInfo = browser.getBrowser()
const osName = browser.getOSName(true) // optional argument: toLowerCase => set to true

type Props = {
  url: string
  mobile?: boolean
  height?: number
  postfix?: string
  label?: string
  fileName?: string
  onDownload?: () => void
}

const PitchDeck: FunctionComponent<Props> = (props) => {
  const {
    url,
    mobile,
    height = 335,
    postfix,
    label,
    fileName,
    onDownload,
  } = props
  const [fileOverLimit, setFileOverLimit] = useState<boolean | null>(null)

  useEffect(() => {
    if (fileOverLimit === null && url) {
      const http = new XMLHttpRequest()
      http.open('HEAD', url)
      http.onreadystatechange = () => {
        if (http.status === 200) {
          const fileSize = Number(http.getResponseHeader('content-length'))
          // 1 MB in bytes * 10
          if (fileSize > 1048576 * 10) {
            setFileOverLimit(true)
          } else {
            setFileOverLimit(false)
          }
        } else {
          setFileOverLimit(true)
        }
      }
      http.send()

      return () => {
        http.abort()
      }
    }
  }, [url, fileOverLimit])

  // android has issues showing the attached files
  if (
    osName === 'android' ||
    (browserInfo.name === 'Internet Explorer' &&
      browserInfo.version &&
      browserInfo.version.startsWith('11'))
  ) {
    return <DownloadAttachment url={url} postfix={postfix} label={label} />
  }

  if (fileName?.endsWith('pdf')) {
    return (
      <Fragment>
        <object
          data={url}
          width='100%'
          height={mobile ? undefined : height}
          type={browserInfo.name === 'Safari' ? undefined : 'application/pdf'}
          aria-label='company pdf'
        >
          <div className='flex flex-column justify-center'>
            <b>
              <FormattedMessage
                id='PitchDeck.PitchDeckNotAccessible'
                defaultMessage='Pitch Deck is not accessible.'
              />
            </b>
          </div>
        </object>
      </Fragment>
    )
  }

  if (fileOverLimit === null) {
    return <Loading />
  }

  if (
    !fileOverLimit &&
    (fileName?.endsWith('pptx') ||
      fileName?.endsWith('ppt') ||
      fileName?.endsWith('xlsx') ||
      fileName?.endsWith('doc') ||
      fileName?.endsWith('docx'))
  ) {
    return (
      <div className='tc'>
        <b>
          <FormattedMessage
            id='PitchDeck.PowerpointNotSupport'
            defaultMessage='Microsoft Office files (pptx, ppt, xlsx, doc, docx) are not supported in preview.'
          />
        </b>
        <FormattedMessage
          id='PitchDeck.DownloadLink'
          defaultMessage='Download the file'
        >
          {(text) => (
            <DownloadAttachment
              url={url}
              postfix={postfix}
              label={label || text}
              onDownload={onDownload}
              noWrapper
            />
          )}
        </FormattedMessage>
      </div>
    )
  }
  return (
    <DownloadAttachment
      url={url}
      postfix={postfix}
      label={label}
      onDownload={onDownload}
    />
  )
}

type DownloadAttachmentProps = {
  url: string
  postfix?: string
  label?: string | ReactNode
  noWrapper?: boolean
  onDownload?: () => void
}
const DownloadAttachment: FunctionComponent<DownloadAttachmentProps> = (
  props
) => {
  return (
    <div className={props.noWrapper ? '' : 'pa3-5 bg-near-white'}>
      <FormattedMessage
        id='PitchDeck.DownloadAttachment'
        defaultMessage='Download Attachment'
      >
        {(text) => (
          <Button autoSize variant='text' onClick={props.onDownload}>
            {props.label
              ? props.label
              : `${text} ${props.postfix ? props.postfix : ''}`}
          </Button>
        )}
      </FormattedMessage>
    </div>
  )
}

export default PitchDeck
