import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import dataSelectors from 'shared/selectors/dataSelectors'
import { List, RecordOf } from 'immutable'
import {
  uploadSpendFile,
  removeSpend,
  loadStagingHistory,
  notify
} from 'shared/actions'
import Page from 'shared/components/Page'
import SerpSupplierMatching from 'data-portal/components/SupplierMatching/SerpLoadPage'
import { UploadHistoryType } from 'data-portal/components/SupplierMatching/SerpLoadPage/SerpLoadPage'

type Props = {
  isUploading: boolean
  uploadError: boolean
  uploadSpendFile: (payload: { file: File; listType: string }) => void
  removeSpend: (payload: { stagingId: string; listType: string }) => void
  loadStagingHistory: (payload: { listType: string }) => void
  notify: (payload: { message: string }) => void
  uploadHistorySerp: List<RecordOf<UploadHistoryType>>
  isLoading: boolean
}

const SerpContainer = (props: Props) => {
  const { loadStagingHistory } = props
  useEffect(() => {
    loadStagingHistory({ listType: 'serp' })
  }, [loadStagingHistory])

  return (
    <Page title='SERP'>
      <SerpSupplierMatching {...props} />
    </Page>
  )
}

export default connect(
  (state: RootState) => {
    return {
      isUploading: state.getIn(['admin', 'upload', 'isUploading']),
      uploadError: state.getIn(['admin', 'upload', 'uploadError']),
      uploadHistory: dataSelectors.getStagingHistory(state),
      uploadHistorySerp: dataSelectors.getStagingSerp(state),
      isLoading: state.getIn(['data', 'isLoading', 'stagingHistory'])
    }
  },
  {
    uploadSpendFile,
    removeSpend,
    loadStagingHistory,
    notify
  }
)(SerpContainer)
