import React, { ReactNode } from 'react'
import classNames from 'classnames'
import MaterialButton, { ButtonProps } from '@material-ui/core/Button'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => ({
  root: {
    padding: '4px 8px',
    '&:active': {
      boxShadow: 'none'
    },
    minWidth: 48
  },
  containedPrimary: {
    boxShadow: 'none',
    border: `${theme.palette.primary.dark} 1px solid`,
    '&:disabled': {
      border: '1px rgba(0, 0, 0, 0.12) solid'
    }
  },
  containedSecondary: {
    boxShadow: 'none',
    background: '#f8f8fa',
    border: '1px lightgray solid',
    '&:hover': {
      background: '#e8e8e8'
    }
  },
  sizeSmall: {
    minWidth: 36,
    padding: '2px 6px'
  },
  sizeLarge: {
    minWidth: 60,
    padding: '6px 12px'
  },
  focusVisible: {
    outline: '3px solid',
    outlineColor: '#000000'
  },
  text: {
    '&:hover, &:focus': {
      color: '#002525'
    },
    '&:focus': {
      outline: 'auto black'
    },
    '&:disabled': {
      color: '#707070',
      opacity: '1'
    }
  }
})

type Props = {
  type?: string
  label?: string | ReactNode
  disabled?: boolean
  secondary?: boolean
  caution?: boolean
  autoSize?: boolean
  className?: string
  wrapperStyle?: object
  onClick?: (any: any) => void
  component?: ReactNode
  to?: string
  ariaDisabled?: boolean
} & ButtonProps &
  WithStyles<typeof styles>

/**
 * Just like a regular button, but with better automatic formatting and label as a prop instead of being inside the button tags
 */
const Button = React.forwardRef((props: Props, ref: any) => {
  const {
    onClick,
    type = 'button',
    disabled,
    label,
    secondary,
    caution,
    autoSize,
    className,
    wrapperStyle,
    variant = 'contained',
    style,
    ariaDisabled,
    ...otherParams
  } = props

  return (
    <span
      className={classNames([className, { dib: autoSize, db: !autoSize }])}
      style={wrapperStyle}
      ref={ref}
    >
      <MaterialButton
        ref={ref}
        color={secondary || caution ? 'secondary' : 'primary'}
        variant={variant}
        onClick={onClick}
        type={type}
        disabled={disabled}
        fullWidth={!autoSize}
        style={Object.assign(
          {},
          style,
          caution && { color: '#E60028' },
          ariaDisabled && { color: '#707070' }
        )}
        disableFocusRipple
        aria-disabled={ariaDisabled}
        {...otherParams}
      >
        {label || props.children}
      </MaterialButton>
    </span>
  )
})

export default withStyles(styles)(Button)
