import React, { Component } from 'react'
import Input from 'shared/components/Input'
import TitleText from 'shared/components/TitleText'
import { Field } from 'redux-form/immutable'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'

type Props = {
  editMode: boolean
  email: string
  onClickEdit: () => void
  handleSubmit: () => void // for redux-form
}

class EnterEmail extends Component<Props> {
  render() {
    const { editMode, handleSubmit, email, onClickEdit } = this.props

    return (
      <div>
        {editMode && (
          <form onSubmit={handleSubmit} className='center tc mw6'>
            <TitleText className='mb4'>
              <label htmlFor='email'>
                <FormattedMessage
                  id='EnterEmail.EnterClientsEmailAddress'
                  defaultMessage="Enter client's email address"
                />
              </label>
            </TitleText>
            <Field
              component={Input}
              name='email'
              type='email'
              required
              wrapperClassName='w-75 dib mr2'
            />
            <Button
              type='submit'
              label={
                <FormattedMessage
                  id='EnterEmail.Submit'
                  defaultMessage='Submit'
                />
              }
              className='pa2 ph4'
              autoSize
            />
          </form>
        )}

        {!editMode && (
          <div>
            <h5 className='f6 f5-m f5-l fw3 mt3 mb4'>
              {email}
              <Button
                secondary
                onClick={onClickEdit}
                label={
                  <FormattedMessage
                    id='EnterEmail.Edit'
                    defaultMessage='edit'
                  />
                }
                autoSize
                className='ml2'
              />
            </h5>
          </div>
        )}
      </div>
    )
  }
}

export default EnterEmail
