import React from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import { FormattedMessage } from 'react-intl'
import Divider from 'shared/components/Divider'
import useUserAccess from 'shared/utils/useUserAccess'

const SustainabilityReportNav = () => {
  const userAccess = useUserAccess()

  return (
    <div className='mw8 center ph3'>
      <div className='flex justify-between items-center'>
        <NavBar>
          {userAccess.accessSustainabilitySpendReport && (
            <NavLink
              label={
                <FormattedMessage
                  id='SustainabilityReportNav.SpendReport'
                  defaultMessage='Sustainability Certifications Spend Report'
                />
              }
              to={paths.sustainabilitySpendReport}
            />
          )}
          {userAccess.accessSustainabilitySurveyReport && (
            <NavLink
              label={
                <FormattedMessage
                  id='SustainabilityReportNav.Overview'
                  defaultMessage='Sustainability Survey Overview'
                />
              }
              to={paths.sustainabilityReportHealthSafety}
            />
          )}
        </NavBar>
      </div>
      <Divider />
    </div>
  )
}

export default SustainabilityReportNav
