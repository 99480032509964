import React, { useState, useMemo, useEffect } from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { List, RecordOf } from 'immutable'
import { Alert } from '@material-ui/lab'

import companySelectors from 'supplier/shared/selectors/companySelectors'
import RootState from 'shared/models/RootState'
import { uploadTier2SpendFile, loadStagingHistory } from 'shared/actions'

import Button from 'shared/components/Button'
import Link from 'shared/components/Link'
import Page from 'shared/components/Page'
import Paper from 'shared/components/Paper'

import { UploadHistoryType } from 'supplier/Insight/containers/LoadPageContainer/LoadPageContainer'
import { Tier2Communities } from 'supplier/Insight/containers/Tier2InvitationContainer/Tier2InvitationContainer'
import AddSummaryRow from '../../LoadSummary/AddSummaryRow'
import { postTier2DiverseSummary } from '../../../store/actions'
import UploadSection from '../UploadSection'
import diversityPaths from '../../../diversityPaths'

import { getOptions } from './getOptions'
import { BuyerSelect } from './BuyerSelect'
import { YearSelect } from './YearSelect'
import { QuarterSelect } from './QuarterSelect'
import { DataTypeSelect } from './DataTypeSelect'
import { SpendTypeSelect } from './SpendTypeSelect'
import { ErrorDialog } from './ErrorDialog'
import { SpendInformationText } from './SpendInformationText'
import SuggestSpendLoad from './SuggestSpendLoad'

type Modify<T, R> = Omit<T, keyof R> & R

export type UploadHistoryTypeT2 = UploadHistoryType & {
  buyers: List<RecordOf<{ buyerName: string; buyerId: string }>>
  spendType: 'direct' | 'indirect' | 'fullSpend'
  dataType: 'file' | 'aggregate'
  subCategories?: List<
    RecordOf<{ subCategory: string; spend: number; subType?: string }>
  >
}

export type ModifiedUploadHistoryType = Modify<
UploadHistoryTypeT2,
  {
    buyers: List<string>
  }
>

const messages = defineMessages({
  file: {
    id: 'UploadSpendDetail.File',
    defaultMessage: 'File'
  },
  aggregate: {
    id: 'UploadSpendDetail.Aggregate',
    defaultMessage: 'Aggregate'
  },
  direct: {
    id: 'UploadSpendDetail.Direct',
    defaultMessage: 'Direct'
  },
  indirect: {
    id: 'UploadSpendDetail.Indirect',
    defaultMessage: 'Indirect'
  },
  fullSpend: {
    id: 'UploadSpendDetail.FullSpend',
    defaultMessage: 'Full spend'
  }
})

const today = new Date()

const ErrorAlert: React.FC = ({ children }) => (
  <Alert severity='error' className='mv3' icon={false}>
    {children}
  </Alert>
)

const UploadSpendDetail = () => {
  const dispatch = useDispatch()

  const [buyer, setBuyer] = useState<string>('')
  const [year, setYear] = useState<string>('')
  const [quarter, setQuarter] = useState<string>('')
  const [dataType, setDataType] = useState<string>('')
  const [spendType, setSpendType] = useState<string>('')
  const [showNextStep, setShowNextStep] = useState<boolean>(false)
  const [recentUpload, setRecentUpload] = useState<boolean>(false)

  useEffect(() => {
    dispatch(loadStagingHistory({ listType: 'supplierTier2Load' }))
  }, [dispatch])

  const tier2Communities: Tier2Communities = useSelector((state: RootState) =>
    state.getIn([
      'supplier',
      'tier2',
      'tier2Communities',
      'tier2CommMemberships'
    ])
  )
  const fileLoadErrors: List<
    string | { [key: string]: any }
  > = useSelector((state: RootState) =>
    state.getIn(['supplier', 'tier2', 'fileLoadErrors'])
  )
  const locations = useSelector((state: RootState) =>
    companySelectors.getCompanySupplierField(state, 'locations')
  )
  const stagings: List<RecordOf<
    ModifiedUploadHistoryType
  >> = useSelector((state: RootState) =>
    state.getIn(['data', 'stagingHistory'])
  )

  const acceptedTier2Communities = tier2Communities?.filter(
    comm => comm.get('status') === 'Accepted'
  )
  const country = locations?.getIn([0, 'components', 'country'])

  const selectedQuarterStagings = useMemo(
    () =>
      stagings?.filter(
        staging =>
          staging.get('year').toString() === year &&
          staging.get('quarter').toString() === quarter
      ),
    [stagings, year, quarter]
  )

  const errorStagings = useMemo(
    () => selectedQuarterStagings?.filter(s => s.get('status') === 'error'),
    [selectedQuarterStagings]
  )

  const activeStagings = useMemo(
    () => selectedQuarterStagings?.filter(s => s.get('status') !== 'error'),
    [selectedQuarterStagings]
  )

  const suggestedStaging: RecordOf<ModifiedUploadHistoryType> = useMemo(() => {
    const publishedStagings = activeStagings?.filter(staging => {
      const type = dataType === 'detailedCsv' ? 'file' : dataType

      return (
        staging.get('status') === 'published' &&
        staging.get('dataType') === type &&
        ['indirect', 'fullSpend'].includes(staging.get('spendType')) &&
        ['indirect', 'fullSpend'].includes(spendType) &&
        // buyers not empty (empty = all buyers) and not includes buyer
        staging.get('buyers').size > 0 &&
        !staging.get('buyers').includes(buyer)
      )
    })
    return publishedStagings?.first()
  }, [activeStagings, buyer, dataType, spendType])

  const buyerHasDirectAggregate = useMemo(() => {
    return !!activeStagings?.some(
      staging =>
        staging.get('dataType') === 'aggregate' &&
        staging.get('spendType') === 'direct' &&
        staging.get('buyers').includes(buyer)
    )
  }, [activeStagings, buyer])

  const fullSpendFileStagings = useMemo(
    () =>
      activeStagings?.filter(
        staging =>
          staging.get('dataType') === 'file' &&
          staging.get('spendType') === 'fullSpend'
      ),
    [activeStagings]
  )

  const quarterHasFullSpendNotPublishedNotFirstBuyer = useMemo(
    () =>
      fullSpendFileStagings?.every(
        staging =>
          staging.get('status') !== 'published' &&
          !staging.get('buyers').includes(buyer)
      ),

    [fullSpendFileStagings, buyer]
  )

  const [dataTypeOptions, spendTypeOptions] = useMemo(
    () => getOptions(activeStagings, fullSpendFileStagings, buyer, dataType),
    [activeStagings, fullSpendFileStagings, buyer, dataType]
  )

  const quarterHasAllErrors =
    selectedQuarterStagings?.size === errorStagings?.size

  // logic to show, hide, or disable components

  const showFullSpendAlreadyUploadedError =
    dataType !== '' &&
    spendType === 'fullSpend' &&
    quarterHasFullSpendNotPublishedNotFirstBuyer &&
    !quarterHasAllErrors &&
    !suggestedStaging

  const showDirectAggregateAlreadyUploadedError =
    buyerHasDirectAggregate &&
    !showFullSpendAlreadyUploadedError &&
    !quarterHasAllErrors &&
    !suggestedStaging

  const showErrorDialog = !!fileLoadErrors.size && recentUpload
  const disableUpload = !buyer || !year || !quarter || !dataType || !spendType
  const showUploadSection = showNextStep && dataType === 'file'

  const showAddSummaryRow =
    showNextStep && dataType === 'aggregate' && year && quarter

  const showNextButton = !suggestedStaging && !showNextStep
  const disableNextButton =
    !buyer ||
    !year ||
    !quarter ||
    !dataType ||
    !spendType ||
    showFullSpendAlreadyUploadedError ||
    showDirectAggregateAlreadyUploadedError

  // functions to handle state changes

  const handleNextClick = () => setShowNextStep(true)
  const handleCancelAggregate = () => setShowNextStep(false)

  const handleChangeBuyer = (value: string) => {
    setBuyer(value)
    setSpendType('')
    setDataType('')
    setShowNextStep(false)
  }

  const handleChangeQuarter = (value: string) => {
    setQuarter(value)
    setSpendType('')
    setDataType('')
    setShowNextStep(false)
  }

  const handleChangeYear = (value: string) => {
    setYear(value)
    setSpendType('')
    setDataType('')
    setShowNextStep(false)
  }

  const handleChangeDataType = (value: string) => {
    setDataType(value)
    setSpendType('')
    setShowNextStep(false)
  }

  const handleFileUpload = ({ file }) => {
    dispatch(
      uploadTier2SpendFile({
        buyer,
        spendType,
        file,
        year: Number(year),
        quarter: Number(quarter),
        listType: 'supplierTier2Load'
      })
    )
    setRecentUpload(true)
    setShowNextStep(false)
  }

  const handleSaveAggregate = (
    subCategories: Array<{
      subCategory: string
      subType?: string
      spend: number
    }>
  ) => {
    const fiscalStart = moment(new Date(`${year}/1/1`))
    const startDate =
      Number(quarter) > 1
        ? fiscalStart.add(Number(quarter) - 1, 'quarter')
        : fiscalStart
    const dates = [startDate.format('YYYY/MM/DD')]

    const summary = {
      buyer,
      spendType,
      year,
      quarter,
      dates,
      domain: 'missing.link',
      category: 'diversity',
      subCategories
    }
    dispatch(postTier2DiverseSummary(summary))
    setShowNextStep(false)
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='UploadSpendDetail.AddSpendInformation'
          defaultMessage='Add your supplier spend information'
        />
      }
    >
      <nav>
        <Link to={diversityPaths.diversityLoad} className='f7'>
          &lsaquo;{' '}
          <FormattedMessage
            id='UploadSpendDetail.BackToFileHistory'
            defaultMessage='Back to file history'
          />
        </Link>
      </nav>
      <h2 className='f5 fw3'>
        <FormattedMessage
          id='UploadSpendDetail.Title1'
          defaultMessage='Add your supplier spend information'
        />
      </h2>

      <SpendInformationText />

      {showFullSpendAlreadyUploadedError && (
        <ErrorAlert>
          <FormattedMessage
            id='UploadSpendDetail.DuplicateFullSpend'
            defaultMessage='A full spend file has already been uploaded for this quarter. Please publish the file so the spend can be shared with this buyer'
          />
        </ErrorAlert>
      )}

      {showDirectAggregateAlreadyUploadedError && (
        <ErrorAlert>
          <FormattedMessage
            id='UploadSpendDetail.DuplicateAggregateSpend'
            defaultMessage='There is already an existing direct aggregate spend published for this buyer and quarter. If you need to make changes, please delete the aggregate spend and re-load.'
          />
        </ErrorAlert>
      )}

      <div className='flex justify-between'>
        <div className='w-20'>
          <BuyerSelect
            acceptedTier2Communities={acceptedTier2Communities}
            buyer={buyer}
            handleChangeBuyer={handleChangeBuyer}
          />
        </div>
        <div className='w-20 pl3'>
          <YearSelect
            handleChangeYear={handleChangeYear}
            today={today}
            year={year}
          />
        </div>
        <div className='w-20 pl3'>
          <QuarterSelect
            handleChangeQuarter={handleChangeQuarter}
            quarter={quarter}
          />
        </div>
        <div className='w-20 pl3'>
          <DataTypeSelect
            dataTypeOptions={dataTypeOptions}
            dataType={dataType}
            messages={messages}
            handleChangeDataType={handleChangeDataType}
          />
        </div>
        <div className='w-20 pl3'>
          <SpendTypeSelect
            messages={messages}
            setSpendType={setSpendType}
            spendTypeOptions={spendTypeOptions}
            spendType={spendType}
          />
        </div>
      </div>

      {suggestedStaging && (
        <SuggestSpendLoad
          stagingId={suggestedStaging.get('id')}
          buyerId={buyer}
        />
      )}

      {showNextButton && (
        <div className='flex justify-end mv3'>
          <Button disabled={disableNextButton} onClick={handleNextClick}>
            <FormattedMessage
              id='UploadSpendDetail.Next'
              defaultMessage='Next'
            />
          </Button>
        </div>
      )}

      {showUploadSection && (
        <Paper noPadding className='mt3'>
          <div className='ph3 pb3'>
            <UploadSection
              onUpload={handleFileUpload}
              hideQuarter
              disabledUpload={disableUpload}
            />
          </div>
        </Paper>
      )}

      {showAddSummaryRow && (
        <AddSummaryRow
          country={country}
          year={year}
          quarter={quarter}
          onSave={handleSaveAggregate}
          onCancel={handleCancelAggregate}
        />
      )}

      <ErrorDialog
        fileLoadErrors={fileLoadErrors}
        open={showErrorDialog}
        setRecentUploadFalse={() => setRecentUpload(false)}
      />
    </Page>
  )
}
export default UploadSpendDetail
