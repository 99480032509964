import React, { useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RootState from 'shared/models/RootState'
import dataQualitySelectors from '../../store/selectors/dataQualitySelectors'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Divider from 'shared/components/Divider'
import {
  getDataQualityJobStatus,
  clearDataQualityJobStatus
} from '../../store/actions'
import DataQualityColumnsMapping from './DataQualityColumnsMapping'
import Button from 'shared/components/Button'
import api from 'shared/utils/api'
import { apiRoute } from 'shared/utils/api/dqService/dqService'
import FileSaver from 'file-saver'
import { notify } from 'shared/actions'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import usersSelectors from 'shared/selectors/usersSelectors'
import startCase from 'lodash.startcase'
import { List, RecordOf, Map } from 'immutable'
import { Grid } from '@material-ui/core'

type Props = {
  jobId: string
}

const DataQualityJobDetail = (props: Props) => {
  const { jobId } = props
  const dispatch = useDispatch()
  const job = useSelector((state: RootState) =>
    dataQualitySelectors.getJob(state, jobId)
  )
  const relatedJob = useSelector((state: RootState) =>
    dataQualitySelectors.getJob(state, job?.getIn(['metadata', 'relatedId']))
  )
  
  const jobType = job?.get('type') === 'dqSpend' ? 'spend' : 'vendor'

  const jobStatus = useSelector(dataQualitySelectors.getJobStatus)
  const jobStatusSummary: List<RecordOf<{
    category: string
    count: string
    progress: string
  }>> = useSelector(dataQualitySelectors.getJobStatusSummary)
  const postJobStatusSummary: List<RecordOf<{
    category: string
    count: string
    progress: string
  }>> = useSelector(dataQualitySelectors.getPostJobStatusSummary)
  const jobStatusDerelictRows: List<Map<
    string,
    string | null | number
  >> = useSelector(dataQualitySelectors.getJobDerelictRows)
  const users = useSelector(usersSelectors.getUsers)
  const user = users?.get(job?.getIn(['created', 'user']))

  const DerelictRowKeys = useMemo(() => {
    const keys =
      jobStatusDerelictRows?.size > 0
        ? jobStatusDerelictRows
            .get(0)
            ?.keySeq()
            .toJS()
        : []
    return keys
      ?.filter(
        (k: string) =>
          !(
            k.startsWith('reason') ||
            k.startsWith('Internal') ||
            k.startsWith('Company')
          )
      )
      .sort()
  }, [jobStatusDerelictRows])

  const updateJobDetail = useCallback(() => {
    if (
      job?.get('status') === 'analyzing' ||
      job?.get('status') === 'initialized'
    ) {
      dispatch(getDataQualityJobStatus(jobId))
    }
  }, [dispatch, jobId, job])

  useEffect(() => {
    dispatch(getDataQualityJobStatus(jobId))

    const interval = global.setInterval(() => {
      updateJobDetail()
    }, 2000)

    return () => {
      global.clearInterval(interval)
      dispatch(clearDataQualityJobStatus())
    }
  }, [updateJobDetail, dispatch, jobId])

  const handleDownload = downloadPath => {
    const filename = downloadPath.split('/')[2]
    api
      .download(`${apiRoute}/downloadFile/${downloadPath}`)
      .then(response => response.blob())
      .then(blob => FileSaver.saveAs(blob, `${filename}`))
      .catch(err =>
        dispatch(
          notify({
            message: err.message
          })
        )
      )
  }

  const getDerelictRow = ({ index }) => {
    return jobStatusDerelictRows.get(index)
  }
  const getJobStatusRow = ({ index }) => {
    return jobStatusSummary.get(index)
  }
  const getPostJobStatusRow = ({ index }) => {
    return postJobStatusSummary.get(index)
  }
  const sourceFilePath = job?.getIn(['attachments', 'uploadedFilePath'])
  const derelictFilePath = job?.getIn(['attachments', 'derelictFilePath'])
  const completedFilePath = job?.getIn(['attachments', 'completedFilePath'])
  const postdqmFilePath = job?.getIn(['attachments', 'postdqmFilePath'])

  return jobStatus ? (
    <div>
      <label className='f5 fw5 mb3 mt4 db'>
        {job.get('name')} ({jobId})
      </label>
      <Text>
        <span className='fw6 dib w4'>Created By:</span> {user?.get('firstName')}{' '}
        {user?.get('lastName')}
      </Text>
      <Text>
        <span className='fw6 dib w4'>Type:</span>{' '}
        {startCase(jobType)}
      </Text>
      <Text>
        <span className='fw6 dib w4'>Filename:</span>{' '}
        {job.getIn(['attachments', 'description'])}
      </Text>
      <Text>
        <span className='fw6 dib w4'>Status:</span>{' '}
        {startCase(job.get('status'))}
      </Text>
      {jobType === 'spend' &&(
        <Text>
          <span className='fw6 dib w4'>Related Vendor Job:</span>{' '}
          {job?.getIn(['metadata', 'relatedId'])}
        </Text>
      )}
      {jobType === 'vendor' && jobStatus.get('status') === 'initialized' &&
        job.getIn(['metadata', 'output']) && (
          <DataQualityColumnsMapping
            jobId={job.get('id')}
            type="vendor"
            filename={job.getIn(['attachments', 'fileName'])}
            columnsMapping={job.getIn(['metadata', 'output'])}
          />
        )}
      {jobType === 'spend' && jobStatus.get('status') === 'initialized' && (relatedJob?.get('status') === 'complete' || relatedJob?.get('status') === 'analyzing') &&
        job.getIn(['metadata', 'output']) && relatedJob?.getIn(['metadata', 'mapping']) && (
          <DataQualityColumnsMapping
            jobId={job.get('id')}
            type="spend"
            filename={job.getIn(['attachments', 'fileName'])}
            columnsMapping={job.getIn(['metadata', 'output'])}
            altFilename={relatedJob?.getIn(['attachments', 'fileName'])}
            altJobId={relatedJob?.get('id')}
            altMapping={Object.fromEntries(relatedJob?.getIn(['metadata', 'mapping']))}
          />
        )}
      {jobType === 'spend' && jobStatus.get('status') === 'initialized' && !(relatedJob?.get('status') === 'complete' || relatedJob?.get('status') === 'analyzing') &&
        (
          <Text>
          <span className='fw6 dib'>To perform spend column mapping, please complete the column mapping for the related vendor job with ID: </span>{' '}
          {job?.getIn(['metadata', 'relatedId'])}
        </Text>
        )}
      {(jobStatus.get('status') === 'complete' ||
        jobStatus.get('status') === 'analyzing') && (
        <>
          {!!jobStatusSummary?.size && (
            <div>
              <Label>Summary</Label>
              <Divider />
              <Grid container justify='space-between' className='mt3 mb3' >
                <Grid item xs={12} sm={5} md={5} className='ba b--black-10 mw6'>
                  <Table
                    rowGetter={getJobStatusRow}
                    rowCount={jobStatusSummary.size}
                    headerHeight={35}
                    rowHeight={33}
                  >
                    <Column
                      label='Category'
                      dataKey={'category'}
                      width={260}
                      cellRenderer={({ cellData }) => startCase(cellData)}
                    />
                    <Column label='Row Count' dataKey={'count'} width={100} />
                    <Column
                      label='Progress'
                      dataKey={'progress'}
                      width={80}
                      cellRenderer={({ cellData }) => {
                        if (cellData) {
                          return `${cellData}%`
                        }
                      }}
                    />
                  </Table>
                </Grid>
                {!!postJobStatusSummary?.size && jobType === 'vendor' && (
                  <Grid item xs={12} sm={5} md={5} className='ba b--black-10 mw6'>
                    <Table
                      rowGetter={getPostJobStatusRow}
                      rowCount={postJobStatusSummary.size}
                      headerHeight={35}
                      rowHeight={33}
                    >
                      <Column
                        label='Category'
                        dataKey={'category'}
                        width={260}
                        cellRenderer={({ cellData }) => startCase(cellData)}
                      />
                      <Column label='Percentage' dataKey={'count'} width={100} />
                      <Column
                        label='Progress'
                        dataKey={'progress'}
                        width={80}
                        cellRenderer={({ cellData }) => {
                          if (cellData) {
                            return `${cellData}%`
                          }
                        }}
                      />
                    </Table>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
          {jobStatusDerelictRows?.size > 0 && (
            <>
              <Divider />
              <Label>Derelict Rows</Label>
              <div className='ba b--black-10 mt3'>
                <Table
                  rowGetter={getDerelictRow}
                  rowCount={jobStatusDerelictRows.size}
                  minWidth={5000}
                >
                  <Column
                    label={'Internal Supplier ID'}
                    dataKey={'Internal Supplier ID or Vendor Number'}
                    width={200}
                  />
                  <Column
                    label={'Company Name'}
                    dataKey={'Company Name'}
                    width={200}
                  />
                  <Column label={'Reason'} dataKey={'reason'} width={400} />
                  {DerelictRowKeys?.map(key => (
                    <Column key={key} label={key} dataKey={key} width={200} />
                  ))}
                </Table>
              </div>
            </>
          )}
          <div className='mt3'>
            {completedFilePath && (
              <Button
                className='mr3'
                autoSize
                onClick={() => handleDownload(completedFilePath)}
              >
                Completed File
              </Button>
            )}
            {derelictFilePath && (
              <Button
                className='mr3'
                autoSize
                onClick={() => handleDownload(derelictFilePath)}
              >
                Derelict File
              </Button>
            )}
            {postdqmFilePath && jobType === 'vendor' && (
              <Button
                className='mr3'
                autoSize
                onClick={() => handleDownload(postdqmFilePath)}
              >
                Post DQM File
              </Button>
            )}
            {sourceFilePath && (
              <Button
                secondary
                autoSize
                onClick={() => handleDownload(sourceFilePath)}
              >
                Source File
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  ) : null
}

export default DataQualityJobDetail
