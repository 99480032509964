import { loadESGRequestDetails } from 'buyer/SupplierProfile/actions'
import { call, put, select } from 'redux-saga/effects'
import tasks from 'shared/utils/api/tasks'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import analytics from 'shared/utils/analytics'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

export function* requestForESGSurveySaga(action) {
  const response = yield call(tasks.requestESGSurvey, action.payload)
  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const orgUnitName = yield select(orgsSelectors.getCurrentUserOrgUnitName)

  analytics.track('Sustainability Survey Request Submitted', {
    eventSource: 'Supplier Profile',
    action: 'Submitted',
    supplierOrgUnitId: action.payload.supplierId,
    supplierName: action.payload.supplierName,
    orgUnitId,
    orgUnitName,
  })

  yield put(loadESGRequestDetails(action.payload.supplierId))
  return yield response
}

export default createApiRequestSaga(requestForESGSurveySaga)
