import sessionSelectors from 'shared/selectors/sessionSelectors'
import dataSelectors from 'shared/selectors/dataSelectors'
import statsSelectors from 'supplier/shared/selectors/statsSelectors'
import { createSelector } from 'reselect'
import { fromJS, List, Map, RecordOf } from 'immutable'
import supplier from 'shared/utils/api/supplier'
import RootState from 'shared/models/RootState'
import ClassificationCode from 'shared/models/ClassificationCode'
import Certification from 'shared/models/Certification'
import Location from 'shared/models/Location'

export const isLoading = state => getCompany(state).loading
export const isEditingHeader = state => getCompany(state).editingHeader
export const getLoadingError = state => getCompany(state).loadError

export const getLogoUrl = state =>
  convertFileToUrl(getCompanyData(state).getIn(['supplier', 'logo']))
export const getCoverUrl = state =>
  convertFileToUrl(
    getCompanyData(state).getIn(['supplier', 'backgroundPicture'])
  )

export const getCompanyField = (state, fieldName) => {
  return state.getIn(['supplier', 'company', 'data', fieldName])
}

export const getParentOrg = state => {
  const parentOrg = state.getIn(['supplier', 'company', 'data', 'parentOrg'])
  return state.getIn([
    'supplier',
    'company',
    'data',
    'expanded',
    'Org',
    parentOrg
  ])
}

export const getOrgId = state =>
  state.getIn(['supplier', 'company', 'data', 'parents', 'Org'])

export const getClassificationCodesBySchema = createSelector(
  (state: RootState, schema: string | undefined) =>
    getCompanySupplierField(state, 'classificationCodes'),
  (state: RootState, schema: string | undefined) => schema,
  (
    classificationCodes: List<ClassificationCode>,
    schema: string | undefined
  ) => {
    const filteredCodes =
      classificationCodes &&
      (schema
        ? classificationCodes.filter(
            classification =>
              !!classification.get('schema') &&
              classification.get('schema').toLowerCase() ===
                schema.toLowerCase()
          )
        : classificationCodes.filter(
            classification => !!classification.get('schema')
          ))

    const classificationCodesBySchema: Map<string, List<ClassificationCode>> =
      filteredCodes &&
      filteredCodes.reduce((result, code) => {
        const schema = code.get('schema').toLowerCase()
        if (result.has(schema)) {
          return result.updateIn([schema], (list: List<ClassificationCode>) => {
            return list.push(code)
          })
        } else {
          return result.set(schema, List([code]))
        }
      }, Map())

    return classificationCodesBySchema
  }
)

export const getClassificationCodesBySchemaAsCodeList = createSelector(
  (state, schema: string) => [
    getClassificationCodesBySchema(state, schema),
    schema
  ],
  ([classificationCodesBySchema, schema]: [
    Map<string, List<ClassificationCode>>,
    string
  ]) => classificationCodesBySchema?.get(schema)?.map(x => x.get('code'))
)

export const getNaicsLeaf = createSelector(
  state => getClassificationCodesBySchemaAsCodeList(state, 'naics'),
  list => {
    return (
      list &&
      list
        .sort((code1, code2) => {
          return parseInt(code1.padEnd(6, '0')) - parseInt(code2.padEnd(6, '0'))
        })
        .filter((code, index, list) => {
          const nextCode = list?.get(index + 1)
          if (nextCode?.startsWith(code)) {
            return false
          }
          return true
        })
    )
  }
)

export const getPitchDeck = state => {
  return convertFileToUrl(getCompanySupplierField(state, 'pitchDeckFile'))
}

export const getCertifications = createSelector(
  state => getCompanySupplierField(state, 'certification'),
  certifications =>
    certifications && certifications.groupBy(c => c.get('category')).entrySeq()
)

export const getCertificationByTimeStamp = createSelector(
  (state, timeStamp) => getCompanySupplierField(state, 'certification'),
  (state, timeStamp) => timeStamp,
  (certifications, timeStamp) =>
    certifications?.find(cert => cert.get('timeStamp') === timeStamp)
)

export const getCompanySupplierField = (state, fieldName) => {
  return state.getIn(['supplier', 'company', 'data', 'supplier', fieldName])
}

export const getCompanyHeaderInfo = createSelector(
  (state: RootState) => getCompany(state).data,
  (state: RootState) => state.get('i18n').locale,
  (state: RootState) => sessionSelectors.userHasRole(state, 'tealbot'),
  (state: RootState) => getPhoneNumber(state),
  (data, lang, isTealbot, phoneNumber) => {
    const locations = data.getIn(['supplier', 'locations'])
    const primaryLocation = (locations && locations.first()) || fromJS({})
    const cpiLocation =
      locations &&
      locations.reduce((result, location) => {
        return !result
          ? location
          : location.getIn(['components', 'countryCpi'], 100) <
            result.getIn(['components', 'countryCpi'], 100)
          ? location
          : result
      })

    const description = data.get('description')
    const longDescription = data.get('longDescription')

    return {
      name: data.get('name'),
      supplierType: data.getIn(['supplier', 'supplierType']),
      alternativeNames: data.get('alternativeNames'),
      domains: data.get('domains').toJS(),
      websiteUrl: data.getIn(['supplier', 'links', 'websiteUrl']),
      logoUrl: convertFileToUrl(data.getIn(['supplier', 'logo'])),
      coverUrl: convertFileToUrl(data.getIn(['supplier', 'backgroundPicture'])),
      address: primaryLocation,
      phone: phoneNumber,
      country:
        primaryLocation.get('addCountry') &&
        primaryLocation.getIn(['components', 'country']),
      description:
        !description || typeof description === 'string'
          ? description
          : description.get(lang) ||
            description.get('en') ||
            description
              .toList()
              .filter((d: string) => !!d)
              .first(),
      longDescription:
        !longDescription || typeof longDescription === 'string'
          ? longDescription
          : longDescription.get(lang) || longDescription.get('en'),
      linkedInUrl: data.getIn(['supplier', 'links', 'linkedInUrl']),
      facebookUrl: data.getIn(['supplier', 'links', 'facebookUrl']),
      twitterId: data.getIn(['supplier', 'links', 'twitterId']),
      cpiLocation,
      websiteWarning: isTealbot
        ? data.getIn(['supplier', 'websiteWarning'])
        : '',
      created: data.getIn(['created', 'date'])
    }
  }
)

export const getCompanyDomainsOptions = createSelector(
  state => {
    const domains = getCompanyField(state, 'domains')
    return domains && domains.map(d => fromJS({ label: d, value: d }))
  },
  domainsOptions => domainsOptions
)

export const getCompanyHeaderInitialValues = state => {
  const description = getCompanyField(state, 'description')
  let initialValues: any = {
    name: getCompanyField(state, 'name'),
    description:
      !description || typeof description === 'string'
        ? fromJS({ en: description })
        : description,
    alternativeNames: getCompanyField(state, 'alternativeNames') || fromJS([]),
    supplier: {
      supplierType: getCompanySupplierField(state, 'supplierType'),
      links: {
        websiteUrl: getLink(state, 'websiteUrl'),
        linkedInUrl: getLink(state, 'linkedInUrl'),
        facebookUrl: getLink(state, 'facebookUrl'),
        twitterId:
          getLink(state, 'twitterId') &&
          !getLink(state, 'twitterId').startsWith('@')
            ? `@${getLink(state, 'twitterId')}`
            : getLink(state, 'twitterId')
      },
      diversityProgram: getCompanySupplierField(state, 'diversityProgram'),
      phones: { office: getPhoneNumber(state) }
    }
  }

  if (sessionSelectors.userHasRole(state, 'tealbot')) {
    initialValues.name = getCompanyField(state, 'name')
    initialValues.domains = getCompanyField(state, 'domains')
    initialValues.supplier.showSupplier = getCompanySupplierField(
      state,
      'showSupplier'
    )
    initialValues.status = getCompanyField(state, 'status')
    initialValues.statusDate = getCompanyField(state, 'statusDate')
      ? new Date(getCompanyField(state, 'statusDate')).getUTCFullYear()
      : ''
    initialValues.supplier.flagCountry = getCompanySupplierField(
      state,
      'flagCountry'
    )
    initialValues.supplier.bannedCompanyIds = getCompanySupplierField(
      state,
      'bannedCompanyIds'
    )
    initialValues.supplier.websiteWarning = getCompanySupplierField(
      state,
      'websiteWarning'
    )
  }

  return initialValues
}

export const getSupplierContacts = state =>
  getCompanySupplierField(state, 'contacts') || fromJS([])

export function getLink(state, linkName) {
  return getCompanyData(state).getIn(['supplier', 'links', linkName])
}

export const requiresOnboarding = state => {
  const websiteUrl = getLink(state, 'websiteUrl')
  const name = getCompanyField(state, 'name')
  const alreadyOnboarded = supplier.alreadyOnboarded()

  // after the loading is done
  return (
    !isLoading(state) &&
    // if email is used as domain then just need a name
    (getCompanyField(state, 'isEmailDomain')
      ? !name
      : (!websiteUrl && !alreadyOnboarded) || !name)
  )
}

export const getSimilarSuppliers = state => {
  return state.getIn([
    'supplier',
    'company',
    'data',
    'supplier',
    'relatedCompany'
  ])
}

export const hasSimilarSuppliers = state => {
  const similarSuppliers = getSimilarSuppliers(state)
  return (
    similarSuppliers &&
    similarSuppliers.size > 0 &&
    sessionSelectors.userHasRole(state, 'beta')
  )
}

export const getOrgUnitById = (state, orgUnitId) => {
  return state.getIn([
    'supplier',
    'company',
    'data',
    'expanded',
    'OrgUnit',
    orgUnitId
  ])
}

function getCompany(state) {
  return state.getIn(['supplier', 'company'])
}

function getCompanyData(state) {
  return getCompany(state).data
}

// function getLocation (state, index) {
//   return getCompanyData(state).getIn(['supplier', 'locations', index, 'address'])
// }

function convertFileToUrl(file) {
  if (file) {
    const bucketName = file.get('bucketName')
    const fileName = file.get('fileName')
    if (bucketName && fileName) {
      return `https://storage.googleapis.com/${bucketName}/${fileName}`
    }
  }
}

const getCompleteness = createSelector(
  (state: RootState) => state.getIn(['supplier', 'company', 'data']),
  dataSelectors.getSupplierClients,
  (data, clients: List<any>) => {
    const description = data.get('description')
    const longDescription = data.get('longDescription')
    const supplier = data.get('supplier')
    const address = supplier && supplier.getIn(['locations', 0, 'address'])
    const pitchDeckFile =
      supplier && supplier.getIn(['pitchDeckFile', 'fileName'])
    const contacts = supplier && supplier.get('contacts')
    const offerings = supplier && supplier.get('offerings')
    const differentiators = supplier && supplier.get('differentiators')
    const serviceAreas = supplier && supplier.get('serviceAreas')
    const links = supplier && supplier.get('links')
    const certifications = supplier && supplier.get('certification')

    return {
      baseline: 12,
      description:
        description ||
        (links &&
          (links.get('linkedInUrl') ||
            links.get('twitterId') ||
            links.get('facebookUrl')))
          ? 11
          : 0,
      aboutUs: longDescription || pitchDeckFile ? 11 : 0,
      team: contacts && contacts.size ? 11 : 0,
      skills:
        (offerings && offerings.size) ||
        (differentiators && differentiators.size)
          ? 11
          : 0,
      locations: serviceAreas && serviceAreas.size ? 11 : 0,
      address: address ? 11 : 0,
      resources:
        (links && (links.get('blogUrl') || links.getIn(['videoUrls', 0]))) ||
        (certifications && certifications.size > 0)
          ? 11
          : 0,
      customers: clients && clients.size ? 11 : 0
    }
  }
)

export const getProfileCompleteness = createSelector(
  getCompleteness,
  completeness => fromJS(completeness)
)

export const isPremium = state =>
  getCompanySupplierField(state, 'supplierType') === 'basic'

export const getLongDescription = createSelector(
  state => getCompanyField(state, 'longDescription'),
  longDescription =>
    !longDescription || typeof longDescription === 'string'
      ? fromJS({ en: longDescription })
      : longDescription
)

export const getReferenceScores = createSelector(
  getCompleteness,
  dataSelectors.getSupplierClients,
  (state: RootState) => state.getIn(['supplier', 'vets', 'items']),
  statsSelectors.getFollowerCount,
  statsSelectors.getConnectionCount,
  (
    completeness: { [key: string]: number },
    clients: List<any>,
    vets,
    followers: number,
    contacts: number
  ) => {
    return {
      completeness: Object.keys(completeness).reduce(
        (result, value) => result + completeness[value],
        0
      ),
      buyers:
        clients &&
        clients.filter(
          c => c.getIn(['buyerCollaboration', 'verifyClient']) === 'agree'
        ).size,
      vets: vets && vets.size,
      followers,
      contacts
    }
  }
)

export const getMultiLangField = createSelector(getCompanyField, fileValue => {
  return !fileValue || typeof fileValue === 'string'
    ? fromJS({ en: fileValue })
    : fileValue
})

export const getPhoneNumber = createSelector(getCompanyData, data => {
  let phoneNumber = data.getIn(['supplier', 'phones', 'office', 0])
  if (!phoneNumber) {
    const locations = data.getIn(['supplier', 'locations']) || List([])
    const locationWithPhone = locations.find(loc => !!loc.get('phone'))
    phoneNumber = locationWithPhone && locationWithPhone.get('phone')
  }
  return phoneNumber || ''
})

export const getLocationById = createSelector(
  (state: RootState, locationId: string) =>
    getCompanySupplierField(state, 'locations'),
  (state: RootState, locationId: string) => locationId,
  (locations: List<RecordOf<Location>>, locationId: string) => {
    return (
      locations &&
      locationId &&
      locations.find(location => location.get('location') === locationId)
    )
  }
)

export const getValidLocations = createSelector(
  (state: RootState) => getCompanySupplierField(state, 'locations'),
  (locations: List<RecordOf<Location>>) => {
    return locations && locations.size > 0
      ? locations.filter(
          location => !!location.getIn(['components', 'countryAbbrev'])
        )
      : undefined
  }
)

export const getUsableValidLocations = createSelector(
  getValidLocations,
  (state: RootState) => getCompanySupplierField(state, 'certification'),
  (state: RootState, subCategory: string, locationId: string) => locationId,
  (state: RootState, subCategory: string, locationId: string) => subCategory,
  (
    locations: List<RecordOf<Location>>,
    certifications: List<RecordOf<Certification>>,
    locationId: string,
    subCategory: string
  ) => {
    const currentCountry = locations
      ?.find(location => location.get('location') === locationId)
      ?.getIn(['components', 'countryAbbrev'])
    const certifyingCountries = certifications
      ?.filter(cert => {
        return (
          cert.get('subCategory') === subCategory &&
          !!cert.get('certifyingLocation')
        )
      })
      .map(cert =>
        cert.getIn(['certifyingLocation', 'components', 'countryAbbrev'])
      )
    return (
      locations &&
      locations.filter(location => {
        return (
          location.getIn(['components', 'countryAbbrev']) === currentCountry ||
          !certifyingCountries ||
          !certifyingCountries.includes(
            location.getIn(['components', 'countryAbbrev'])
          )
        )
      })
    )
  }
)
