import React, { useEffect, useState, useMemo, ChangeEvent } from 'react'
import Page from 'shared/components/Page'
import Card from 'shared/components/Card'
import { useDispatch } from 'react-redux'
import { getDataQualityJobs } from '../../store/actions'
import { useSelector } from 'react-redux'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import usersSelectors from 'shared/selectors/usersSelectors'
import dataQualitySelectors from '../../store/selectors/dataQualitySelectors'
import dateFormat from 'shared/utils/dateFormat'
import startCase from 'lodash.startcase'
import Loading from 'shared/components/Loading'
import Input from 'shared/components/Input'
import DataQualityJobDetail from './DataQualityJobDetail'
import { List } from 'immutable'

const DataQualityServiceResults = () => {
  const dispatch = useDispatch()

  const users = useSelector(usersSelectors.getUsers)
  const jobs = useSelector(dataQualitySelectors.getJobs)
  const loading = useSelector(dataQualitySelectors.isLoading)

  const [filterValue, setFilterValue] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')
  const [sortBy, setSortBy] = useState<
    'created' | 'createdBy' | 'attachments' | 'status' | 'type'
  >('created')
  const [selectedJobId, setSelectedJobId] = useState<string>('')

  useEffect(() => {
    dispatch(getDataQualityJobs())
  }, [dispatch])

  const sortedJobs: List<any> = useMemo(() => {
    return jobs
      .filter(job => {
        const user = users?.get(job.getIn(['created', 'user']))
        const value = `${user?.get('firstName')} ${user?.get(
          'lastName'
        )} ${job.getIn(['attachments', 'description'])}`.toLowerCase()
        const filterKeys = filterValue
          .trim()
          .toLowerCase()
          .split(' ')

        if (!filterKeys.length) {
          return true
        } else {
          for (let i = 0; i < filterKeys.length; i++) {
            if (value.indexOf(filterKeys[i]) === -1) {
              return false
            }
          }
          return true
        }
      })
      .sort((job1, job2) => {
        let sortValue1, sortValue2
        if (sortBy === 'created') {
          sortValue1 = new Date(job1.getIn(['created', 'date']))
          sortValue2 = new Date(job2.getIn(['created', 'date']))
        } else if (sortBy === 'createdBy') {
          const user1 = users?.get(job1.getIn(['created', 'user']))
          const user2 = users?.get(job2.getIn(['created', 'user']))
          sortValue1 = user1
            ? `${user1?.get('firstName')} ${user1?.get('lastName')}`
            : ''
          sortValue2 = user2
            ? `${user2?.get('firstName')} ${user2?.get('lastName')}`
            : ''
        } else if (sortBy === 'attachments') {
          sortValue1 = job1.getIn(['attachments', 'description'])?.toLowerCase()
          sortValue2 = job2.getIn(['attachments', 'description'])?.toLowerCase()
        } else if (sortBy === 'status') {
          sortValue1 = job1.getIn(['status']) || ''
          sortValue2 = job2.getIn(['status']) || ''
        } else if (sortBy === 'type') { 
          sortValue1 = job1.getIn(['type'])
          sortValue2 = job2.getIn(['type'])
        } else {
          sortValue1 = job1.getIn(['id'])
          sortValue2 = job2.getIn(['id'])
        }

        if (sortDirection === 'ASC') {
          return sortValue1 > sortValue2 ? 1 : -1
        } else {
          return sortValue1 < sortValue2 ? 1 : -1
        }
      })
  }, [jobs, sortBy, sortDirection, users, filterValue])

  const selectedRowIndex = useMemo(() => {
    const index = sortedJobs.findIndex(job => job.get('id') === selectedJobId)
    return index !== -1 ? [index] : []
  }, [selectedJobId, sortedJobs])

  const getRow = ({ index }) => {
    return sortedJobs && sortedJobs.size > 0 && sortedJobs.get(index)
  }

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const renderCreatedBy = ({ rowData }: TableCellProps) => {
    const user = users?.get(rowData.getIn(['created', 'user']))
    return user ? `${user?.get('firstName')} ${user?.get('lastName')}` : ''
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Page title='Data Quality Service - Results'>
      <Card>
        <Input
          value={filterValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFilterValue(e.currentTarget.value)
          }
          placeholder='Enter here to filter the list'
        />
        <div className='ba b--black-10 mt3'>
          <Table
            rowGetter={getRow}
            rowCount={sortedJobs.size}
            sort={handleSortChange}
            sortDirection={sortDirection}
            sortBy={sortBy}
            onRowClick={({ index, rowData }) => {
              if (index !== undefined) {
                setSelectedJobId(rowData.get('id'))
              }
            }}
            highlightIndexes={selectedRowIndex}
          >
            <Column label='ID' dataKey='id' width={175} />
            <Column
              label='Upload Date'
              dataKey='created'
              width={150}
              cellRenderer={({ cellData }: TableCellProps) =>
                cellData?.get('date') && dateFormat(cellData?.get('date'))
              }
            />
            <Column
              label='Upload By'
              dataKey='createdBy'
              width={150}
              cellRenderer={renderCreatedBy}
            />
            <Column
              label='Filename'
              dataKey='attachments'
              width={400}
              cellRenderer={({ cellData }: TableCellProps) =>
                cellData?.get('description')
              }
            />
            <Column
              label='Status'
              dataKey='status'
              width={150}
              cellRenderer={({ cellData }: TableCellProps) =>
                startCase(cellData)
              }
            />
            <Column
              label='Type'
              dataKey='type'
              width={150}
              cellRenderer={({ cellData }: TableCellProps) =>
                startCase(cellData)
              }
            />
          </Table>
        </div>
        {selectedRowIndex.length > 0 && selectedJobId && (
          <DataQualityJobDetail jobId={selectedJobId} />
        )}
      </Card>
    </Page>
  )
}

export default DataQualityServiceResults
