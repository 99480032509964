import React, {
  ReactNode,
  useCallback,
  FocusEvent,
  FunctionComponent,
  useState,
  useEffect,
  ReactElement,
} from 'react'
import { Field } from 'redux-form/immutable'
import Input from '../Input'
import Label from '../Label'
import Text from '../Text'
import UserLookup from '../UserLookup'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../DialogTitle'
import DialogActions from '../DialogActions'
import Button from '../Button'
import Warning from '../Warning'
import SelectPublicPrivate from '../SelectPublicPrivate'
import TextArea from '../TextArea'
import AddInfoButton from '../AddInfoButton'
import Popper from '@material-ui/core/Popper'
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  IntlShape,
} from 'react-intl'
import { List, RecordOf } from 'immutable'
import Downshift from 'downshift'
import { useLocation } from 'react-router-dom'

import MenuItem from 'shared/components/MenuItem'
import User from 'shared/models/User'
import UserPickList from 'shared/components/UserPickList'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import ImageArrowUp from 'shared/assets/icons/arrow-up.svg'
import { AddFormProps } from '../AddForm'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'
import ConnectMessageDialogContainer from 'buyer/shared/containers/ConnectMessageDialogContainer'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { bulkAddUsers } from 'admin/actions'
import { useSelector, useDispatch } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import RootState from 'shared/models/RootState'
import BulkAddUsers from '../BulkAddUsers'
import Checkbox from 'shared/components/ReduxFormMaterialCheckbox'
import { FormControlLabel, withStyles } from '@material-ui/core'
import ConnectRequestDialogContainer from 'buyer/shared/containers/ConnectRequestDialogContainer'
import analytics from 'shared/utils/analytics'

const StyledCheckbox = withStyles({
  root: {
    paddingRight: 14,
    width: 0,
  },
})(Checkbox)

const messages = defineMessages({
  domainShouldMatch: {
    id: 'AddUserForm.EmailShouldMatchTheDomainOfTheCompany',
    defaultMessage: 'Email should match the domain of the company',
  },
  exactMatch: {
    id: 'AddUserForm.EmailShouldMatchExactlyAsRegisteredDomain',
    defaultMessage: 'Email should match exactly as registered domain',
  },
  add: {
    id: 'AddUserForm.Add',
    defaultMessage: 'Add',
  },
})

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index

  return (
    <MenuItem
      {...itemProps}
      key={suggestion}
      selected={isHighlighted}
      component='div'
    >
      {suggestion}
    </MenuItem>
  )
}

function isEmailDomainMatching(emailDomain, domains) {
  return (
    emailDomain === '' ||
    domains.some((domain) => domain.startsWith(emailDomain))
  )
}

export type AddUserFormProps = {
  addingContact: boolean
  valid: boolean
  addingMyself: boolean
  onSubmit: (params: string) => void
  domains: List<string>
  creatingNewUser: boolean
  exactMatch: boolean
  userLookupLabel: ReactNode
  users: List<RecordOf<User>>
  suggestedUsers: List<RecordOf<User>>
  onClickSuggestedUser: (user: RecordOf<User>) => void
  change: (arg0: string, arg1: any) => void
  onClickNewUser: (newUserName?: string) => void
  submitButtonLabel: ReactNode
  enablePublicPrivate: boolean
  isTealbot: boolean
  errorMessage: string
  onClickCancel: () => void
  email: string
  isSubmitting: boolean
  caption: string | ReactElement<FormattedMessage>
  hideLookup: boolean
  useList?: boolean
  sendMessage?: boolean | undefined
  intl: IntlShape
  forContact?: boolean
  addSuccess?: boolean
  addedContactId?: string
  addedContactName?: string
  supplierId?: string
  supplierName?: string
  uploadUsersFile?: boolean
  defaultMessage?: string
  requestSelfCertify?: boolean
  resendOutreachRequest?: boolean
  resendOutreachForESGSurvey?: boolean
  requestESGSurvey?: boolean
  contactSupplierForOutreach?: boolean
  sendCollaborateRequest?: boolean
  taskId?: string
  currentUserId?: string
  skipMessageDialog?: boolean | undefined
  message?: string
  inviteAnotherContact?: boolean
  requestProfileUpdate?: boolean
} & AddFormProps &
  WithWidthProps

export const AddUserForm: FunctionComponent<AddUserFormProps> = (props) => {
  const dispatch = useDispatch()
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )
  const isUploading = useSelector((state: RootState) =>
    state.getIn(['admin', 'upload', 'isUploading'])
  )

  const [fileUpload, setFileUpload] = useState<File | null>(null)
  const [showMessageDialog, setShowMessageDialog] = useState<
    boolean | undefined
  >(false)
  const [showList, setShowList] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [selectedTab, setSelectedTab] = useState(0)

  const location = useLocation()

  const eventSource = location.pathname.includes('/client/suppliers/OrgUnit')
    ? 'Supplier Profile'
    : 'Unknown'

  const {
    submitButtonLabel = 'Add & Invite',
    userLookupLabel = 'Add User',
    domains = List([]),
    enablePublicPrivate,
    addingContact,
    creatingNewUser,
    users,
    suggestedUsers,
    onClickSuggestedUser,
    onClickNewUser,
    children,
    exactMatch,
    isTealbot,
    errorMessage,
    onClickCancel,
    email,
    addingMyself,
    forContact,
    addSuccess,
    addedContactId,
    addedContactName,
    supplierId,
    isSubmitting,
    caption,
    intl,
    hideLookup,
    useList,
    width,
    requestSelfCertify,
    resendOutreachRequest,
    resendOutreachForESGSurvey,
    inviteAnotherContact,
    requestESGSurvey,
    sendCollaborateRequest,
    contactSupplierForOutreach,
    taskId,
    currentUserId,
    skipMessageDialog,
    supplierName,
    change,
    uploadUsersFile,
    defaultMessage,
    requestProfileUpdate,
  } = props

  useEffect(() => {
    setShowMessageDialog(addSuccess)
  }, [addSuccess])

  const handleSubmit = (e) => {
    e.preventDefault()

    analytics.track('Contact Add Submitted', {
      eventSource,
      action: 'Submitted',
      orgUnitId,
      orgUnitName,
    })

    if (selectedTab === 0 && uploadUsersFile) {
      dispatch(
        bulkAddUsers({
          orgUnitId,
          file: fileUpload,
        })
      )
    } else if (selectedTab === 1 || !uploadUsersFile) {
      const { valid, onSubmit } = props
      if (valid) {
        onSubmit(e)
      }
    }
  }

  const handleUploadFileChange = (file: File | null) => {
    setFileUpload(file)
  }

  const verifyEmail = useCallback(
    (value, allValues) => {
      if (
        allValues.get('override') ||
        allValues.get('user')?.get('skipEmailValidation')
      ) {
        return undefined
      }
      const errorMessage = !exactMatch
        ? !domains.some((domain) => {
            const parsedDomain = domain.split('@')
            return (
              value &&
              (value
                .toUpperCase()
                .endsWith(
                  `@${parsedDomain[parsedDomain.length - 1].toUpperCase()}`
                ) ||
                value
                  .toUpperCase()
                  .endsWith(
                    `.${parsedDomain[parsedDomain.length - 1].toUpperCase()}`
                  ))
            )
          })
          ? intl.formatMessage(messages.domainShouldMatch)
          : undefined
        : !domains.some(
            (domain) => domain.toUpperCase() === (value && value.toUpperCase())
          )
        ? intl.formatMessage(messages.exactMatch)
        : undefined

      return errorMessage
    },
    [domains, exactMatch, intl]
  )

  const handleEmailOnFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleEmailOnBlur = useCallback(
    (e) => {
      const value = e.currentTarget.value.trim()
      const user =
        users &&
        users.find((user) => {
          const email = user.get('email')
          return email && value && email.toUpperCase() === value.toUpperCase()
        })

      if (user) {
        onClickSuggestedUser(user)
      }
    },
    [users, onClickSuggestedUser]
  )

  const handleChangeEmailValue = useCallback(
    (email) => {
      change('user.email', email)
    },
    [change]
  )

  const handleEmailDomainAddUserClick = () => {
    const { domains, users, onClickSuggestedUser, onClickNewUser } = props

    const user =
      users && users.find((user) => user.get('email') === domains.get(0))

    if (user) {
      onClickSuggestedUser(user)
    } else {
      onClickNewUser()
    }
  }

  const matchMobile = width && ['xs', 'sm'].includes(width)

  const parsedDomains = exactMatch
    ? domains
    : domains.map((domain) => {
        const parsedDomain = domain.toLowerCase().split('@')
        return parsedDomain[parsedDomain.length - 1]
      })

  const [emailName, emailDomain] = email
    ? email.toLowerCase().split('@')
    : ['', '']

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const onClickAddContact = () => {
    analytics.track('Contact Add Opened', {
      eventSource,
      action: 'Opened',
      orgUnitId,
      orgUnitName,
    })

    onClickNewUser()
  }

  const renderCheckbox = ({ input, label, inputProps }) => (
    <div>
      <FormControlLabel
        control={
          <StyledCheckbox
            checked={input.value}
            onChange={input.onChange}
            input={input}
            {...inputProps}
          />
        }
        label={label}
      />
    </div>
  )

  return (
    <>
      {!hideLookup && !exactMatch && (
        <div className='bg-near-white ph3-5 pb3-5'>
          {useList ? (
            <>
              {suggestedUsers && suggestedUsers.size > 0 && (
                <>
                  <div
                    className='pointer dt w-100'
                    onClick={() => setShowList((showList) => !showList)}
                  >
                    <Label className='db pv3 pb1 f7 fw6 pointer dtc dim'>
                      <FormattedMessage
                        id='AddUserForm.ContactListSection'
                        defaultMessage='Other Team Members ({users})'
                        values={{ users: suggestedUsers.size }}
                      />
                    </Label>
                    <div className='dtc w-10 tr'>
                      <img
                        src={showList ? ImageArrowUp : ImageArrowDown}
                        className='v-mid'
                        alt=''
                      />
                    </div>
                  </div>
                  {showList && (
                    <UserPickList
                      users={suggestedUsers}
                      actionLabel={intl.formatMessage(messages.add)}
                      action={onClickSuggestedUser}
                    />
                  )}
                </>
              )}
              <div className='bg-near-white pt3'>
                <Text className='dib w-70 v-mid'>
                  <FormattedMessage
                    id='AddUserForm.NewUser'
                    defaultMessage={`Don't see someone you know?`}
                  />
                </Text>
                <div className='dib w-30 v-mid tr'>
                  <Button
                    label={
                      <FormattedMessage
                        id='AddUserForm.AddContact'
                        defaultMessage='Add a Contact'
                      />
                    }
                    onClick={onClickAddContact}
                  />
                </div>
              </div>
            </>
          ) : (
            <UserLookup
              users={suggestedUsers}
              label={userLookupLabel}
              onClickNewUser={onClickNewUser}
              onClickSuggestedUser={onClickSuggestedUser}
            />
          )}
        </div>
      )}

      {!hideLookup && exactMatch && (
        <AddInfoButton
          label={userLookupLabel}
          onClick={handleEmailDomainAddUserClick}
        />
      )}

      <Dialog
        open={addingContact}
        onClose={onClickCancel}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <DialogTitle>
          {caption || (
            <FormattedMessage
              id='AddUserForm.UserTealbookInvitation'
              defaultMessage='Invitation'
            />
          )}
        </DialogTitle>
        <DialogContent>
          {uploadUsersFile && (
            <AppBar position='static'>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                aria-label='add new user tabs'
                indicatorColor='primary'
                variant='fullWidth'
              >
                <Tab label='Add Bulk Users' />
                <Tab label='Add Single User' />
              </Tabs>
            </AppBar>
          )}
          <form
            id='addUserDialog'
            data-testid='form'
            onSubmit={handleSubmit}
            autoComplete='form'
          >
            {uploadUsersFile && selectedTab === 0 && (
              <BulkAddUsers
                isUploading={isUploading}
                handleUploadFileChange={handleUploadFileChange}
                fileUpload={fileUpload}
              />
            )}
            {((uploadUsersFile && selectedTab === 1) || !uploadUsersFile) && (
              <>
                <Label>
                  <FormattedMessage
                    id='AddUserForm.FirstName'
                    defaultMessage='First Name'
                  />
                </Label>
                <Field
                  component={Input}
                  name='user[firstName]'
                  readOnly={!creatingNewUser}
                  required
                  autoComplete='given-name'
                />

                <Label>
                  <FormattedMessage
                    id='AddUserForm.LastName'
                    defaultMessage='Last Name'
                  />
                </Label>
                <Field
                  component={Input}
                  name='user[lastName]'
                  readOnly={!creatingNewUser}
                  autoComplete='new-user-last-name'
                  required={sendCollaborateRequest}
                />

                <Label>
                  <FormattedMessage
                    id='AddUserForm.Email'
                    defaultMessage='Email'
                  />
                </Label>
                <Downshift onSelect={handleChangeEmailValue}>
                  {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    highlightedIndex,
                    isOpen,
                    selectedItem,
                  }) => {
                    return (
                      <div>
                        <Field
                          component={Input}
                          name='user[email]'
                          required
                          type='email'
                          readOnly={!creatingNewUser}
                          validate={verifyEmail}
                          {...(getInputProps({
                            onFocus: handleEmailOnFocus,
                            onBlur: handleEmailOnBlur,
                            autoComplete: 'off',
                          }) as any)}
                        />
                        <Popper
                          open={
                            isOpen &&
                            !!email &&
                            isEmailDomainMatching(emailDomain, parsedDomains)
                          }
                          anchorEl={anchorEl}
                          placement='bottom-start'
                          style={{ zIndex: 2000 }}
                        >
                          <div
                            className='bg-white shadow-1'
                            {...(isOpen
                              ? getMenuProps({}, { suppressRefError: true })
                              : {})}
                          >
                            {email &&
                              parsedDomains
                                .filter(
                                  (domain) =>
                                    !emailDomain ||
                                    domain.startsWith(emailDomain)
                                )
                                .map((domain, index) =>
                                  renderSuggestion({
                                    suggestion: `${emailName}@${domain}`,
                                    index,
                                    itemProps: getItemProps({
                                      item: `${emailName}@${domain}`,
                                    }),
                                    highlightedIndex,
                                    selectedItem,
                                  })
                                )}
                          </div>
                        </Popper>
                      </div>
                    )
                  }}
                </Downshift>
                {!exactMatch ? (
                  <Text className='dark-gray i'>
                    <FormattedMessage
                      id='AddUserForm.ValidEmailDomain'
                      defaultMessage='Valid email {numberOfDomains, plural, one {domain} other {domains}}: {parsedDomains}'
                      values={{
                        numberOfDomains: parsedDomains.size > 1,
                        parsedDomains: `${parsedDomains
                          .map((domain) => `@${domain}`)
                          .join(', ')}`,
                      }}
                    />
                  </Text>
                ) : (
                  <Text className='dark-gray i'>
                    <FormattedMessage
                      id='AddUserForm.ValidEmail'
                      defaultMessage='Valid email: {parsedDomains}'
                      values={{
                        parsedDomains: parsedDomains?.join(', '),
                      }}
                    />
                  </Text>
                )}

                {isTealbot && creatingNewUser && (
                  <Field
                    component={renderCheckbox}
                    name='user[skipEmailValidation]'
                    inputProps={{
                      'aria-label': 'skip email validation',
                    }}
                    label={
                      <FormattedMessage
                        id='AddUserForm.SkipEmailValidation'
                        defaultMessage='Skip email validation'
                      />
                    }
                  />
                )}
                {isTealbot && creatingNewUser && (
                  <div>
                    <Field name='override' component='input' type='checkbox' />{' '}
                    <span className='f7 fw4'>
                      <FormattedMessage
                        id='AddUserForm.OverrideEmailaddressMismatch'
                        defaultMessage='Override email address mismatch'
                      />
                    </span>
                  </div>
                )}

                {children}

                {enablePublicPrivate && (
                  <Field
                    name='isPrivate'
                    component={SelectPublicPrivate}
                    className='mt3 mr3'
                  />
                )}

                {email && creatingNewUser && props.message && !errorMessage && (
                  <>
                    <Label htmlFor='sendMessage'>
                      {forContact ? (
                        <FormattedMessage
                          id='AddUserForm.NewContactToInvite'
                          defaultMessage='New Contact to Invite'
                        />
                      ) : (
                        <FormattedMessage
                          id='AddUserForm.NewUserToInvite'
                          defaultMessage='New User to Invite'
                        />
                      )}
                    </Label>
                    {!errorMessage && (
                      <Field
                        component={renderCheckbox}
                        name='sendMessage'
                        inputProps={{
                          'aria-label': 'send a message',
                        }}
                        label={
                          <FormattedMessage
                            id='AddUserForm.PersonalizeTheirInvitation'
                            defaultMessage='Personalize their invitation'
                          />
                        }
                      />
                    )}

                    {props.sendMessage && (
                      <>
                        <Label>
                          <FormattedMessage
                            id='AddUserForm.Message'
                            defaultMessage='Message'
                          />
                        </Label>
                        <Field
                          component={TextArea}
                          name='message'
                          maxLength={150}
                        />
                      </>
                    )}
                  </>
                )}

                {errorMessage && (
                  <Warning message={errorMessage} className='mt3' />
                )}
              </>
            )}
          </form>
        </DialogContent>

        <DialogActions>
          <div className='mt3-ns fixed static-ns bottom-2'>
            <Button
              disabled={
                !uploadUsersFile || selectedTab === 1
                  ? isSubmitting
                  : !fileUpload || isUploading
              }
              type='submit'
              form='addUserDialog'
              autoSize
              size={matchMobile ? 'large' : undefined}
              label={
                exactMatch ? (
                  submitButtonLabel
                ) : (
                  <FormattedMessage id='AddUserForm.Add' defaultMessage='Add' />
                )
              }
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              autoSize
              secondary
              onClick={onClickCancel}
              className='ml3'
            />
          </div>
        </DialogActions>
      </Dialog>
      {addedContactId && !skipMessageDialog && (
        <ConnectMessageDialogContainer
          supplierId={supplierId}
          userId={addedContactId}
          open={!addingMyself && !!showMessageDialog}
          connectContact={false}
          defaultMessage={defaultMessage}
          requestESGSurvey={requestESGSurvey}
          resendOutreachRequest={resendOutreachRequest}
          resendOutreachForESGSurvey={resendOutreachForESGSurvey}
          inviteAnotherContact={inviteAnotherContact}
          sendCollaborateRequest={sendCollaborateRequest}
          contactSupplierForOutreach={contactSupplierForOutreach}
          taskId={taskId}
          currentUserId={currentUserId}
          supplierName={supplierName}
          description={
            <FormattedMessage
              id='AddUserForm.MessageDescription'
              defaultMessage='{name} has been added. Reach out, and start a conversation.'
              values={{ name: addedContactName }}
            />
          }
          onClose={onClickCancel}
        />
      )}
      {addedContactId && (requestProfileUpdate || requestSelfCertify) && (
        <ConnectRequestDialogContainer
          userId={addedContactId}
          open={!addingMyself}
          onClose={onClickCancel}
          supplierId={supplierId}
          supplierName={supplierName}
          isProfileUpdateRequest={requestProfileUpdate}
          title={
            requestProfileUpdate ? (
              <FormattedMessage
                id='SupplierProfileHeader.RequestProfileUpdate'
                defaultMessage='Request Profile Update'
              />
            ) : (
              <FormattedMessage
                id='SupplierProfileHeader.Self Certification Request'
                defaultMessage='Self Certification Request'
              />
            )
          }
          description={
            requestProfileUpdate ? (
              <FormattedMessage
                id='AddUserForm.ProfileUpdateDescription'
                defaultMessage='{name} has been added as a new supplier contact. You can now send your Profile Update request to this contact.'
                values={{ name: addedContactName }}
              />
            ) : (
              <FormattedMessage
                id='AddUserForm.SelfCertificationDescription'
                defaultMessage='{name} has been added as a new supplier contact. You can now send your Self Certification request to this contact.'
                values={{ name: addedContactName }}
              />
            )
          }
        />
      )}
    </>
  )
}

export default withWidth()(injectIntl(AddUserForm))
