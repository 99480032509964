import React from 'react'
import SustainabilityReportNav from './SustainabilityReportNav'
import SustainabilityOverview from './SustainabilityOverview'
import SustainabilitySpendReport from '../SustainabilitySpendReport'
import { Switch, Route, Redirect } from 'react-router-dom'
import paths from '../../routes/paths'
import useUserAccess from 'shared/utils/useUserAccess'

const SustainabilityReport = () => {
  const userAccess = useUserAccess()

  return (
    <>
      <SustainabilityReportNav />
      <Switch>
        {userAccess.accessSustainabilitySpendReport && (
          <Route path={paths.sustainabilitySpendReport} exact>
            <SustainabilitySpendReport />
          </Route>
        )}
        {userAccess.accessSustainabilitySurveyReport && (
          <Route path={paths.sustainabilityReportOverview}>
            <SustainabilityOverview />
          </Route>
        )}
        <Redirect from={paths.basePath} to={paths.sustainabilitySpendReport} />
      </Switch>
    </>
  )
}
export default SustainabilityReport
