import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'
import parseRelationship from '../../data/parseRelationship'
import parseSupplierRelationships from '../../data/parseSupplierRelationships'
import parseSupplier from '../../data/parseSupplier'
import parseUser from 'shared/utils/data/parseUser'
import parseCard from 'shared/utils/data/parseCard'
import mapValues from 'lodash.mapvalues'
import mapKeys from 'lodash.mapkeys'
import camelCase from 'lodash.camelcase'
import startCase from 'lodash.startcase'
import communityParser from '../../data/community/parser'
import parseTags from '../../../utils/data/parseTags'
import fileToUrl from '../../data/fileToUrl'
import OrgUnit from '../../../models/OrgUnit'

const apiRoute = `${apiRoutes.authService}/suppliers`

type SearchCard = {
  id: string
  connected: boolean
  label: string[]
  parents: { User: string }
  tags: string[]
  attributes: string[]
}

type SearchRelationship = {
  id: string
  peerTags: { [tag: string]: { name: string; count: number } }
}

type SearchSupplier = OrgUnit & {
  supplier: {
    tags: string[]
    clientTags: string[]
  }
}

type SearchUser = {
  id: string
  blocked: boolean
  email: string
  primary: boolean
  firstName: string
  lastName: string
}

type SearchHit = {
  cards: { [cardId: string]: SearchCard }
  relationship: SearchRelationship
  supplier: SearchSupplier
  supplierUsers: SearchUser[]
}

type SearchResult = {
  aggregations: { [key: string]: any }
  communities?: { [key: string]: any }
  hits: Array<SearchHit>
  searchId: string
  total: { value: number }
}

export const searchSuppliers = (params, vetId?, currentUserId?) => {
  const { filter, ...restParams } = params
  // remove upper filter keys if lower level key is also selected
  // eg. if country, state, city are selected, no reason to pass country and state
  // same with sector, subsector, group

  if (filter && filter.group) {
    delete filter.subsector
    delete filter.sector
  }
  if (filter && filter.subsector) {
    delete filter.sector
  }
  if (filter && filter.city) {
    delete filter.state
    delete filter.country
  }
  if (filter && filter.state) {
    delete filter.country
  }

  return (
    vetId
      ? api.get(`${apiRoute}/search/${vetId}`, { filter, ...restParams })
      : api.get(`${apiRoute}/search`, { filter, ...restParams })
  ).then(({ hits, ...restResponse }: SearchResult) => {
    const newHits: Array<any> = hits.map(
      ({ relationship, supplier, ...result }) => {
        // extract client and supplier tags
        // filter unique tags
        // change to object matching peerTags structure
        // combine with peerTags
        // supplier.clientTags contains tags from other tealbots
        const clientTags = supplier.supplier.clientTags
        const supplierTags = supplier.supplier.tags
        const dictionary = {}

        const myCard = Object.values(result.cards).find(
          (card) => card.parents.User === currentUserId
        )
        const myTags = (myCard && myCard.tags) || []
        const myLabels = (myCard && myCard.attributes) || []

        // add other tealbots tags to peerTags if available
        // peerTags only contains tags from your orgUnit
        // relationship can be empty object
        const newPeerTags = clientTags?.reduce((peerTags, tag) => {
          if (!peerTags[camelCase(tag)]) {
            peerTags[camelCase(tag)] = {
              name: tag,
              count: 1,
            }
          }
          return peerTags
        }, relationship.peerTags || {})
        if (newPeerTags && Object.keys(newPeerTags).length !== 0) {
          relationship['peerTags'] = newPeerTags
        }

        const otherTags =
          clientTags && supplierTags
            ? supplierTags
                .concat(supplierTags)
                .filter((tag) => {
                  const exist = dictionary[tag.toUpperCase()]
                  dictionary[tag.toUpperCase()] = true
                  return !exist
                })
                .reduce((obj, tag) => {
                  obj[camelCase(tag)] = {
                    name: tag,
                    count: 0,
                  }
                  return obj
                }, {})
            : {}

        const combinedTags =
          Object.assign({}, otherTags, relationship.peerTags || {}) || {}

        const tags = Object.entries(combinedTags)
          .map(([key, value]) => ({
            ...value,
            key,
            mine: myTags.includes(key),
          }))
          .sort((a, b) => {
            let isMine
            if (a.mine === b.mine) {
              isMine = 0
            } else if (b.mine) {
              isMine = 1
            } else {
              isMine = -1
            }
            return isMine || b.count - a.count
          })

        return {
          ...result,
          supplier: supplier.relatedCompany
            ? {
                ...parseSupplier(supplier),
                relatedCompany: supplier.relatedCompany,
              }
            : parseSupplier(supplier),
          relationship: Object.keys(relationship).length
            ? parseRelationship(relationship)
            : relationship,
          tags,
          labels: myLabels.map((label) => ({
            key: camelCase(label),
            name: label,
          })),
          myCardId: myCard && myCard.id,
        }
      }
    )
    return {
      hits: newHits,
      ...restResponse,
    }
  })
}

export const vetSearchSuppliers = (params) => {
  return api.get(`${apiRoute}/search/:vet`, params)
}

export const suggestSuppliers = (params) => {
  return api.get(`${apiRoute}/suggest`, params)
}

export const similar = ({ supplierId, otherSupplier, ...rest }) =>
  api.post(`${apiRoute}/${supplierId}/similar`, {
    otherSupplier,
    ...rest,
  })

export const getById = (supplierId, params) => {
  const { supplier, ...restParams } = params || {}
  return api
    .get(`${apiRoute}/${supplierId}${supplier ? '/supplier' : ''}`, restParams)
    .then(
      ({
        supplierRelationships,
        relationship,
        supplier,
        cards,
        users,
        tags,
        communities,
        ...rest
      }) => {
        const supplierCommunities = mapKeys(
          mapValues(communities, (res) =>
            communityParser(res.community, res.communityPlanMembership)
          ),
          (community) => community.id
        )
        const supplierMemberships = mapKeys(
          mapValues(communities, (res) => res.communityPlanMembership),
          (comm) => comm.id
        )

        const parsedCards = cards && mapValues(cards, parseCard)
        let offeringsBy = {}
        let diferentiatorsBy = {}
        let privateOfferingsBy = {}
        if (parsedCards) {
          Object.values(parsedCards).forEach(
            (card: {
              offerings: string[]
              differentiators: string[]
              privateOfferings: string[]
              owner: string
            }) => {
              Object.keys(card.offerings).forEach((offering) => {
                if (offeringsBy[offering]) {
                  offeringsBy[offering].push(card.owner)
                } else {
                  offeringsBy[offering] = [card.owner]
                }
              })
              Object.keys(card.differentiators).forEach((differentiator) => {
                if (diferentiatorsBy[differentiator]) {
                  diferentiatorsBy[differentiator].push(card.owner)
                } else {
                  diferentiatorsBy[differentiator] = [card.owner]
                }
              })
              Object.keys(card.privateOfferings).forEach((privateOffering) => {
                if (privateOfferingsBy[privateOffering]) {
                  privateOfferingsBy[privateOffering].push(card.owner)
                } else {
                  privateOfferingsBy[privateOffering] = [card.owner]
                }
              })
            }
          )
        }

        if (relationship && relationship.offerings) {
          const relationshipOfferings = parseTags(relationship.offerings)
          Object.keys(relationshipOfferings).forEach((offering) => {
            tags.offerings[offering] = tags.offerings[offering]
              ? Object.assign({}, tags.offerings[offering], {
                  colleagueTagged: true,
                  count: tags.offerings[offering].count + 1,
                })
              : {
                  name: relationshipOfferings[offering],
                  count: 1,
                  supplierTagged: false,
                  colleagueTagged: true,
                }
          })
        }

        if (relationship && relationship.contacts) {
          relationship.contacts = relationship.contacts.reduce(
            (result, contact) => {
              result[contact.user] = Object.assign({}, contact, {
                source: 'company',
                isPrivate: false,
              })
              return result
            },
            {}
          )
        }
        Object.keys(offeringsBy).forEach((offering) => {
          if (tags.offerings[offering]) {
            tags.offerings[offering].colleagues = offeringsBy[offering]
          } else {
            // other tealbots tag
            tags.offerings[offering] = {
              count: 1,
              name: startCase(offering),
              colleagueTagged: true,
              colleagues: offeringsBy[offering],
            }
          }
        })
        Object.keys(diferentiatorsBy).forEach((differentiator) => {
          if (tags.differentiators[differentiator]) {
            tags.differentiators[differentiator].colleagues =
              diferentiatorsBy[differentiator]
          } else {
            // other tealbots tag
            tags.differentiators[differentiator] = {
              count: 1,
              name: startCase(differentiator),
              colleagueTagged: true,
              colleagues: diferentiatorsBy[differentiator],
            }
          }
        })
        Object.keys(privateOfferingsBy).forEach((privateOffering) => {
          if (tags.privateOfferings[privateOffering]) {
            tags.privateOfferings[privateOffering].colleagues =
              privateOfferingsBy[privateOffering]
          } else {
            // other tealbots tag
            tags.privateOfferings[privateOffering] = {
              count: 1,
              name: startCase(privateOffering),
              colleagueTagged: true,
              colleagues: privateOfferingsBy[privateOffering],
            }
          }
        })

        return {
          ...rest,
          communities: {
            byId: supplierCommunities,
            bySupplierId: { [supplierId]: Object.keys(supplierCommunities) },
            membershipsById: supplierMemberships,
            membershipsBySupplierId: {
              [supplierId]: Object.keys(supplierMemberships),
            },
          },
          users: mapValues(users, parseUser),
          cards: parsedCards,
          tags,
          supplier: parseSupplier(supplier),
          relationship: relationship && parseRelationship(relationship),
          supplierRelationships:
            supplierRelationships &&
            parseSupplierRelationships(supplierRelationships),
        }
      }
    )
}

export const getTagsForSupplier = (supplierId) => {
  return api.get(`${apiRoute}/tags/${supplierId}`)
}

export const requestUpdate = ({ supplierId, requestedContactId }) => {
  return api.put(`${apiRoute}/${supplierId}/requestUpdate`, {
    requestedContactId,
  })
}

export const create = ({ name, websiteUrl }) => {
  return api.post(apiRoute, {
    name,
    domains: [websiteUrl],
  })
}

export const deleteSupplier = ({
  orgUnitId,
  ignoreCards,
  replacementOrgUnitId,
}) => {
  return api.remove(`${apiRoute}/${orgUnitId}`, undefined, {
    ignoreCards,
    replacementOrgUnitId,
  })
}

export const getRecentSuppliers = (exportRecentComments) => {
  return api
    .get(`${apiRoute}/recent/${exportRecentComments}`)
    .then((response) => mapValues(response, (v) => v.map(parseSupplier)))
}

export const updateStatus = ({ supplierId, ...rest }) =>
  api.post(`${apiRoute}/status/${supplierId}`, { ...rest })

export const searchTier2Suppliers = (params) => {
  const { filter, ...restParams } = params
  return api
    .get(`${apiRoute}/search`, { filter, ...restParams })
    .then((results) => {
      results.hits = results.hits.map(({ logo, ...result }) => {
        const logoUrl = logo && fileToUrl(JSON.parse(logo))
        return {
          ...result,
          logo: logoUrl,
        }
      })
      return results
    })
}

export const getExportColumns = () => {
  return api.get(`${apiRoute}/exportColumns`)
}

export const downloadExportFile = ({
  supplierIds,
  exportColumns,
  exportType = 'csv',
}) => {
  return api.post(`${apiRoute}/export`, {
    supplierIds,
    exportColumns,
    exportType,
  })
}

export const getRecentSuppliersTags = (exportRecentTags) => {
  return api.get(`${apiRoute}/recentTags/${exportRecentTags}`).then((cards) =>
    cards.filter((card) => {
      return (
        !!card.differentiators.length ||
        !!card.offerings.length ||
        !!card.privateOfferings.length
      )
    })
  )
}
