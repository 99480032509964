import React, { FunctionComponent } from 'react'
import SearchContainer from '../../../../buyer/Search/containers/SearchContainer'
import Page from '../../../../shared/components/Page/Page'
import { FormattedMessage } from 'react-intl'

const title = <FormattedMessage id='App.Suppliers' defaultMessage='Suppliers' />

const SearchPage: FunctionComponent = props => {
  return (
    <Page title={title}>
      <div className='mw8 w-100 center border-box pt3-ns ph3 ph3-ns ph4-m ph0-ns cf'>
        <SearchContainer disableSuggest refKey='communitySearch' />
      </div>
    </Page>
  )
}

export default SearchPage
