import React, { Component, MouseEvent } from 'react'
import Avatar from 'shared/components/Avatar'
import ListItem from '../List/ListItem'
import BlockIcon from '@material-ui/icons/Block'

type Props = {
  firstName: string
  lastName: string
  orgUnitName: string
  profilePictureUrl: string
  status: string
  onClick?: (e: MouseEvent) => void
}

class UserLookupItem extends Component<Props> {
  render() {
    const {
      firstName,
      lastName,
      orgUnitName,
      status,
      profilePictureUrl,
      onClick
    } = this.props

    return (
      <ListItem
        leftAvatar={
          <Avatar
            url={profilePictureUrl}
            className='w2'
            name={`${firstName || ''} ${lastName || ''}`}
          />
        }
        primaryText={
          <div className='flex items-center'>
            <span role='link'>
              {firstName} {lastName}
            </span>
            {status === 'Blocked' && (
              <BlockIcon className='ml1' style={{ fontSize: '16px' }} />
            )}
          </div>
        }
        secondaryText={orgUnitName}
        className='ma0'
        // className='w5 pointer pa2 v-mid bg-animate hover-bg-near-white f6 gray bb b--black-10'
        onClick={onClick}
        mini
      />
    )
  }
}

export default UserLookupItem
