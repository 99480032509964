import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { List } from 'immutable'
import { FormattedMessage } from 'react-intl'

import LoadPageErrorParser from '../LoadPageErrorParser'

type Props = {
  fileLoadErrors: List<
    | string
    | {
        [key: string]: any
      }
  >
  open: boolean
  setRecentUploadFalse: () => void
}

export const ErrorDialog = ({
  fileLoadErrors,
  open,
  setRecentUploadFalse
}: Props) => (
  <Dialog
    open={open}
    onClose={setRecentUploadFalse}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
  >
    <DialogTitle id='alert-dialog-title'>
      <Grid container justify='space-between' alignItems='center'>
        <h5>We could not process the file you uploaded</h5>
        <IconButton onClick={setRecentUploadFalse}>
          <Close />
        </IconButton>
      </Grid>
    </DialogTitle>
    <DialogContent style={{ whiteSpace: 'pre-line' }}>
      <LoadPageErrorParser fileLoadErrors={fileLoadErrors?.toJS()} />
    </DialogContent>
    <DialogActions style={{ padding: '1.5rem' }}>
      <Button
        onClick={setRecentUploadFalse}
        autoFocus
        style={{ padding: '0.25rem 1.5rem' }}
      >
        <FormattedMessage id='CloseButton' />
      </Button>
    </DialogActions>
  </Dialog>
)
