import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

type OpenUserDialogPayload = React.MouseEvent<HTMLElement, MouseEvent>
export const openUserDialog = createAction<OpenUserDialogPayload>(
  actionTypes.OPEN_USER_DIALOG
)
export const closeUserDialog = createAction(actionTypes.CLOSE_USER_DIALOG)
export const addUser = createAction(actionTypes.ADD_USER)
export const bulkAddUsers = createAction(actionTypes.BULK_ADD_USERS)
export const loadUsers = createAction(actionTypes.LOAD_USERS)
export const startEditingUser = createAction(actionTypes.START_EDITING_USER)
export const cancelEditingUser = createAction(actionTypes.CANCEL_EDITING_USER)

export const updateSettings = createAction(actionTypes.UPDATE_SETTINGS)
export const loadClientData = createAction(actionTypes.LOAD_CLIENT_DATA)

export const uploadClientFile = createAction(actionTypes.UPLOAD_CLIENT_FILE)
export const bulkDeleteUsers = createAction(actionTypes.BULK_DELETE_USERS)
export const bulkUpdateStatus = createAction(actionTypes.BULK_UPDATE_STATUS)
export const bulkUpdateRoles = createAction(actionTypes.BULK_UPDATE_ROLES)
export const selectBulkUsers = createAction(actionTypes.SELECT_BULK_USERS)
export const resendUserInvite = createAction(actionTypes.RESEND_USER_INVITE)

export const generateUserToken = createAction(actionTypes.GENERATE_USER_TOKEN)

export const linkBuyerWithIntegration = createAction(
  actionTypes.LINK_BUYER_WITH_INTEGRATION
)
export const closeRequestRatingDialog = createAction(
  actionTypes.CLOSE_REQUEST_RATING_DIALOG
)
export const closeBulkAssessmentErrorsDialog = createAction(
  actionTypes.CLOSE_BULK_ASSESSMENT_ERRORS_DIALOG
)

export const loadWebhookConfigs = createAction(actionTypes.LOAD_WEBHOOK_CONFIGS)
export const saveWebhookConfig = createAction(actionTypes.SAVE_WEBHOOK_CONFIG)
export const deleteClientSpendById = createAction(
  actionTypes.DELETE_CLIENT_SPEND_BY_ID
)
