import React from 'react'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import FileUploadStep3 from '../../components/FileUploadTab/FileUploadStep3'
import { connect } from 'react-redux'
import { uploadClientFile } from '../../actions'
import RootState from 'shared/models/RootState'
import Main from 'shared/components/Layout/Main'
import Page from 'shared/components/Page/Page'
import { FormattedMessage } from 'react-intl'
import FileUploadTitle from 'admin/components/FileUploadTab/FileUploadTitle'
import { BusinessContextAnswersType } from 'admin/components/FileUploadTab/FileUploadTab'

type Props = {
  isBuyDataAdmin: boolean
  isUploading: boolean
  uploadClientFile: ({ file, questionnaireResponses, history }) => void
  businessContextAnswers: BusinessContextAnswersType
}

const title = <FormattedMessage id='App.Upload' defaultMessage='Upload' />

const FileUploadStep3Container = ({
  isBuyDataAdmin,
  isUploading,
  uploadClientFile,
  businessContextAnswers
}: Props) => {
  return (
    <Page title={title}>
      <FileUploadTitle />
      <Main>
        <FileUploadStep3
          isBuyDataAdmin={isBuyDataAdmin}
          isUploading={isUploading}
          onUploadClientFile={uploadClientFile}
          businessContextAnswers={businessContextAnswers}
        />
      </Main>
    </Page>
  )
}
export default connect(
  (state: RootState) => {
    return {
      isBuyDataAdmin: sessionSelectors.userHasRole(state, 'buyerDataAdmin'),
      isUploading: state.getIn(['admin', 'upload', 'isUploading'])
    }
  },
  {
    uploadClientFile
  }
)(FileUploadStep3Container)
