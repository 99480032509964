import { createSelector } from 'reselect'
import { fromJS, List, RecordOf } from 'immutable'
import {
  DiversityDetailsRecord as SpendReportDetailsRecord,
  DiversityDetails as SpendReportDetails,
  DiversityCertification as Certification,
  DiversityDetailsByInternalId as SpendReportDetailsByInternalId,
  DiverseQualificationRules as QualificationRules,
  IncludeRulesType,
  ExcludeRulesType,
  Location,
  Category,
  SpendGroup
} from '../diversityReportReducer'
import { OverviewSpendItem } from '../../store/diversityReportSelectors/diversityReportSelectors'
import RootState from 'shared/models/RootState'
import usersSelectors from 'shared/selectors/usersSelectors'
import moment, { Moment } from 'moment'
import certAgencyRank from 'shared/utils/data/certAgencyRank'

// export type OverviewSpendItem = {
//   subCategory?: string
//   category?: string
//   country?: string
//   spendGroup?: string
//   qualifiedAmount: number
//   qualifiedCount: number
//   potentialAmount?: number
//   potentialCount?: number
//   disqualifiedAmount?: number
//   disqualifiedCount?: number
//   subTypes?: Map<
//     string,
//     RecordOf<{
//       qualifiedAmount: number
//       qualifiedCount: number
//       potentialAmount?: number
//       potentialCount?: number
//       disqualifiedAmount?: number
//       disqualifiedCount?: number
//     }>
//   >
// }

export type OverviewTotalType = {
  qualifiedTotalAmount: number
  potentialTotalAmount: number
  disqualifiedTotalAmount: number
}

export type OverviewSpendList = {
  spendItems: List<RecordOf<OverviewSpendItem>>
  qualifiedTotalAmount: number
  potentialTotalAmount?: number
  disqualifiedTotalAmount?: number
  dupCounts: { [key: string]: boolean }
}

export const filterCertificationBySubCategories = (
  spendItem: RecordOf<SpendReportDetails>,
  selectedSubCategories: List<string>
) => {
  // remove the certifications that don't match the give list
  return selectedSubCategories.size > 0
    ? spendItem.update('certifications', certifications =>
        certifications.filter(cert =>
          selectedSubCategories.includes(cert.subCategory)
        )
      )
    : spendItem
}

/**
 *  getDetailsBySupplier
 *  reduces supplier ids into unique orgUnitId
 */
export const getDetailsBySupplier = createSelector<
  RootState,
  List<RecordOf<SpendReportDetailsByInternalId>>,
  List<string | undefined>,
  List<RecordOf<SpendReportDetails>>
>(
  (state: RootState) => state.getIn(['buyer', 'diversityReport', 'details']),
  usersSelectors.getAllColleagueIds,
  (
    details: List<RecordOf<SpendReportDetailsByInternalId>>,
    colleagueIds: List<string>
  ) => {
    return (
      details &&
      details
        .map(row => {
          return row.update(
            'certifications',
            (certs: List<RecordOf<Certification>>) =>
              certs.map(cert =>
                cert.update('certificationValidations', validations =>
                  // remove expired validation if set
                  validations
                    ?.filter(
                      v =>
                        !v.get('validationExpires') ||
                        moment(v.get('validationExpires')).isSameOrAfter(
                          moment(),
                          'day'
                        )
                    )
                    .map(v =>
                      v.set(
                        'byColleague',
                        colleagueIds?.includes(v.get('userId'))
                      )
                    )
                )
              )
          )
        })
        .reduce(
          (
            result: List<RecordOf<SpendReportDetails>>,
            detail: RecordOf<SpendReportDetailsByInternalId>
          ) => {
            const index = result.findIndex(
              (row: RecordOf<SpendReportDetails>) =>
                row.get('orgUnitId') === detail.get('orgUnitId')
            )
            return index === -1
              ? // add new row
                result.push(
                  SpendReportDetailsRecord(
                    detail.merge(
                      fromJS({
                        internalSupplierId: {
                          [detail.get('internalSupplierId')]: {
                            totalAmount: detail.get('totalAmount')
                          }
                        }
                      })
                    )
                  )
                )
              : // update existing row
                result.updateIn([index], (row: RecordOf<SpendReportDetails>) => {
                  return row
                    .updateIn(['internalSupplierId'], internalSupplierId =>
                      internalSupplierId.set(
                        detail.get('internalSupplierId'),
                        fromJS({ totalAmount: detail.get('totalAmount') })
                      )
                    )
                    .updateIn(
                      ['totalAmount'],
                      totalAmount => totalAmount + detail.get('totalAmount')
                    )
                    .updateIn(
                      ['categories'],
                      (categories: List<RecordOf<Category>>) => {
                        let updatedCategories = categories
                        const incomingCategories = detail.get('categories')
                        incomingCategories?.forEach(ic => {
                          const catIndex = categories.findIndex(
                            cat => cat.get('category') === ic.get('category')
                          )
                          updatedCategories =
                            catIndex !== -1 // sum amount
                              ? updatedCategories.updateIn(
                                  [catIndex, 'amount'],
                                  amount => amount + ic.get('amount')
                                )
                              : updatedCategories.push(ic)
                        })
                        return updatedCategories
                      }
                    )
                    .updateIn(
                      ['spendGroups'],
                      (spendGroups: List<RecordOf<SpendGroup>>) => {
                        let updatedSpendGroups = spendGroups
                        const incomingSpendGroups = detail.get('spendGroups')
                        incomingSpendGroups?.forEach(isg => {
                          const sgIndex = spendGroups.findIndex(
                            sgroup =>
                              sgroup.get('spendGroup') === isg.get('spendGroup')
                          )
                          updatedSpendGroups =
                            sgIndex !== -1 // sum amount
                              ? updatedSpendGroups.updateIn(
                                  [sgIndex, 'amount'],
                                  amount => amount + isg.get('amount')
                                )
                              : updatedSpendGroups.push(isg)
                        })
                        return updatedSpendGroups
                      }
                    )
                    .updateIn(
                      ['locations'],
                      (locations: List<RecordOf<Location>>) => {
                        let updatedLocations = locations
                        const incomingLocations = detail.get('locations')
                        incomingLocations?.forEach(iloc => {
                          const locIndex = locations.findIndex(
                            loc =>
                              loc.get('countryAbbrev') ===
                              iloc.get('countryAbbrev')
                          )
                          updatedLocations =
                            locIndex !== -1 // sum amount
                              ? updatedLocations.updateIn(
                                  [locIndex, 'amount'],
                                  amount => amount + iloc.get('amount')
                                )
                              : updatedLocations.push(iloc)
                        })
                        return updatedLocations
                      }
                    )
                })
          },
          List([])
        )
    )
  }
)

/**
 * isCertificationRejectByMyTeam
 * @param certification 
 * @param colleagueIds 
 * @returns boolean
 * checks if the certification was last rejected by my team
 */
export const isCertificationRejectByMyTeam = (
  certification: RecordOf<Certification>,
  colleagueIds: List<string>
) => {
  // find latest validation by my team
  const myTeamValidation =
    certification.certificationValidations &&
    certification.certificationValidations.findLast(valid =>
      colleagueIds.includes(valid.userId)
    )
  return (
    !!myTeamValidation &&
    (!myTeamValidation.validationExpires ||
      moment(myTeamValidation.validationExpires).isSameOrAfter(
        new Date(),
        'day'
      )) &&
    !myTeamValidation.confirmed
  )
}

/**
 * isCertificationConfirmByMyTeam
 * @param certification 
 * @param colleagueIds 
 * @returns boolean
 * checks if the certification was last confirmed by my team
 */
export const isCertificationConfirmByMyTeam = (
  certification: RecordOf<Certification>,
  colleagueIds: List<string>
) => {
  // find latest validation by my team
  const myTeamValidation =
    certification.certificationValidations &&
    certification.certificationValidations.findLast(valid =>
      colleagueIds.includes(valid.userId)
    )
  return (
    !!myTeamValidation &&
    (!myTeamValidation.validationExpires ||
      moment(myTeamValidation.validationExpires).isSameOrAfter(
        new Date(),
        'day'
      )) &&
    myTeamValidation.confirmed
  )
}

/**
 * isCertificationConfirmByOther
 * @param certification 
 * @param colleagueIds 
 * @returns boolean
 * checks if the certification was last confirmed by tealbook
 */
export const isCertificationConfirmByOther = (
  certification: RecordOf<Certification>,
  colleagueIds: List<string>
) => {
  // find latest validation by my team
  const otherValidation =
    certification.certificationValidations &&
    certification.certificationValidations.findLast(
      valid => !colleagueIds.includes(valid.userId)
    )
  return (
    !!otherValidation &&
    (!otherValidation.validationExpires ||
      moment(otherValidation.validationExpires).isSameOrAfter(
        new Date(),
        'day'
      )) &&
    otherValidation.confirmed
  )
}

/**
 * isCertificationVerified
 * @param certification 
 * @param selectedAuthorities 
 * @returns boolean
 * checks if the certification matches authorities (if given) and not expired
 */
export const isCertificationVerified = (
  certification: RecordOf<Certification>,
  selectedAuthorities: List<string>
) => {
  // check a certification is valid
  let certAgencies = certification.agencies || List([])
  if (selectedAuthorities.size > 0) {
    certAgencies = certAgencies.filter(agency =>
      selectedAuthorities.includes(agency.certAgency)
    )
  }
  certAgencies = certAgencies.filter(agency => {
    return (
      agency.certAgency && agency.confirmed
      // !blackListedAgencies.includes(agency.certAgency.toLowerCase()) &&
      // (!agency.expiration || new Date(agency.expiration) > new Date())
    )
  })

  return certAgencies.size > 0
}

/**
 * isCertificationMatchAgencies
 * @param certification 
 * @param selectedAuthorities 
 * @returns boolean
 * checks if the certification matches authorities (if given)
 */
export const isCertificationMatchAgencies = (
  certification: RecordOf<Certification>,
  selectedAuthorities: List<string>
) => {
  // check a certification matches any from the list
  let certAgencies = certification.agencies || List([])
  if (selectedAuthorities.size > 0) {
    certAgencies = certAgencies.filter(agency =>
      selectedAuthorities.includes(agency.certAgency)
    )
  }
  return certAgencies.size > 0
}

/**
 * isCertPassQualificationRules
 * @param cert 
 * @param rules 
 * @param colleagueIds 
 * @param debug
 * @returns boolean
 * checks if the certification passes qualification rules
 */
export const isCertPassQualificationRules = (
  cert: RecordOf<Certification>,
  rules: RecordOf<QualificationRules>,
  colleagueIds: List<string>,
  debug: boolean = false
) => {
  const myTeam = rules.includeRules.myTeam
  const tealbook = rules.includeRules.tealbook
  const attachmentAgencies =
    rules.includeRules.completeness.get('attachmentAgencies') || List([])
  const attestation = rules.includeRules.attestation
  const attestationAgencies = attestation.get('certAgencies')

  const myTeamConfirmed = isCertificationConfirmByMyTeam(cert, colleagueIds)
  const myTeamRejected = isCertificationRejectByMyTeam(cert, colleagueIds)
  const tealbookConfirmed = isCertificationConfirmByOther(cert, colleagueIds)
  const passBaseRules = isCertificationVerified(
    cert,
    rules.baseRules.certAgencies
  )
  const passAdditionalRules =
    (attachmentAgencies.size === 0 ||
      (isCertificationMatchAgencies(cert, attachmentAgencies) &&
        cert.attachment)) &&
    (!attestation.get('selfCertified') ||
      !cert.agencies.some(agency => agency.certAgency === 'Self Certify') ||
      !!cert.lastModifiedTimeStamp) &&
    (!attestation.get('notVerifiedByTealbook') ||
      tealbookConfirmed ||
      cert.lastModifiedTimeStamp) &&
    (attestationAgencies.size === 0 ||
      (isCertificationMatchAgencies(cert, attestationAgencies)
        ? !!cert.lastModifiedTimeStamp
        : true))

  if (debug) {
    console.log(
      'myTeamConfirmed', myTeamConfirmed,
      'myTeamRejected', myTeamRejected,
      'tealbookConfirmed', tealbookConfirmed,
      'passBaseRules', passBaseRules,
      'passAdditionalRules', passAdditionalRules,
      'cert', cert?.toJS()
    )
  }
  // team reject rule
  if (rules.excludeRules.myTeam === 'rejected' && myTeamRejected) {
    return false
  }

  // team rules
  if (
    // if ignore, both passBaseRules and passAdditionalRules need to pass
    (myTeam === 'ignore' && passAdditionalRules && passBaseRules) ||
    // if includeExpired, passAdditionalRules and either passBaseRules or teamRules need to pass
    (myTeam === 'includeExpired' &&
      passAdditionalRules &&
      (passBaseRules ||
        (myTeamConfirmed &&
          isCertificationMatchAgencies(cert, rules.baseRules.certAgencies)))) ||
    // if anyAgencies, passAdditionalRules and either passBaseRules or teamRules need to pass
    (myTeam === 'anyAgencies' &&
      passAdditionalRules &&
      (passBaseRules || myTeamConfirmed))
  ) {
    return true
  }

  // tealbook rules
  if (
    // if ignore, both passBaseRules and passAdditionalRules need to pass
    (tealbook === 'ignore' && passAdditionalRules && passBaseRules) ||
    // if includeValid, passAdditionalRules and either passBaseRules or tealbookRules need to pass
    (tealbook === 'includeValid' &&
      passAdditionalRules &&
      (passBaseRules ||
        (tealbookConfirmed &&
          isCertificationMatchAgencies(cert, rules.baseRules.certAgencies)))) ||
    // if anyAgencies, passAdditionalRules and either passBaseRules or tealbookRules need to pass
    (tealbook === 'anyAgencies' &&
      passAdditionalRules &&
      (passBaseRules || tealbookConfirmed))
  ) {
    return true
  }

  return false
}

/**
 * isSpendItemQualified
 * @param spendItem 
 * @param rules 
 * @param colleagueIds 
 * @param selectedCategories 
 * @returns boolean
 * checks if a supplier is qualified for diverse spend based on qualification rules
 * depends on the option, either any or all certs need to pass the rules.
 */
export const isSpendItemQualified = (
  spendItem: RecordOf<SpendReportDetails>,
  rules: RecordOf<QualificationRules>,
  colleagueIds: List<string>,
  selectedCategories?: List<string> // if set, supplier need to have qualified certificate for each of the categories
) => {
  let isQualified: boolean = false
  if (selectedCategories) {
    let matchAll = selectedCategories.reduce((result, cat) => {
      result[cat] = false
      return result
    }, {})

    spendItem.certifications.forEach(cert => {
      matchAll[cert.get('subCategory')] =
        matchAll[cert.get('subCategory')] ||
        isCertPassQualificationRules(cert, rules, colleagueIds)
    })

    isQualified = Object.values(matchAll).every(v => v)
  } else {
    isQualified = spendItem.certifications.some(cert => {
      return isCertPassQualificationRules(cert, rules, colleagueIds)
    })
  }

  return isQualified
}

/**
 * isSpendItemDisqualified
 * @param spendItem 
 * @param disqualificationRules 
 * @param colleagueIds 
 * @returns boolean
 * checks if a supplier is disqualified for diverse spend based on qualification rules
 */
export const isSpendItemDisqualified = (
  spendItem: RecordOf<SpendReportDetails>,
  disqualificationRules: RecordOf<ExcludeRulesType>,
  colleagueIds: List<string>
) => {
  // assuming for all certifications match the base rules
  if (spendItem.certifications.size === 0) {
    return false
  }

  const myTeam = disqualificationRules.myTeam === 'rejected'
  const rejectedByMyTeam = spendItem.certifications.every(cert =>
    isCertificationRejectByMyTeam(cert, colleagueIds)
  )

  if (myTeam && rejectedByMyTeam) {
    return true
  }

  return false
}

/**
 * updateQualifiedConfirmed
 * @param spendItem 
 * @param rules 
 * @param colleagueIds 
 * @returns updated supplier spendItem
 * updates supplier spendItem cert confirmed if it passes qualification rules
 */
export const updateQualifiedConfirmed = (
  spendItem: RecordOf<SpendReportDetails>,
  rules: RecordOf<QualificationRules>,
  colleagueIds: List<string>
) => {
  return spendItem.updateIn(['certifications'], certifications =>
    certifications.map(cert => {
      const passed = isCertPassQualificationRules(cert, rules, colleagueIds)
      return cert.setIn(['agencies', 0, 'confirmed'], passed)
    })
  )
}

/**
 * updatePotentialReasons
 * @param spendItem 
 * @param rules 
 * @param colleagueIds 
 * @param startDate 
 * @returns updated supplier spendItem
 * updates supplier spendItem cert confirmed, info.potential, and reasons
 */
export const updatePotentialReasons = (
  spendItem: RecordOf<SpendReportDetails>,
  rules: RecordOf<QualificationRules>,
  colleagueIds: List<string>,
  startDate: Moment
) => {
  const attachmentAgencies =
    rules.includeRules.completeness.get('attachmentAgencies') || List([])
  const attestation = rules.includeRules.attestation
  const attestationAgencies = attestation.get('certAgencies')

  return spendItem.updateIn(['certifications'], certifications =>
    certifications.map(cert => {
      const tealbookConfirmed = isCertificationConfirmByOther(
        cert,
        colleagueIds
      )

      let customReasons: { [key: string]: string } = {}

      const myTeamRejected = isCertificationRejectByMyTeam(cert, colleagueIds)
      if (rules.excludeRules.myTeam === 'rejected' && myTeamRejected) {
        customReasons.invalidatedByColleague = 'invalidatedByColleague'
      }

      if (
        rules.baseRules.certAgencies.size > 0 &&
        !cert.get('agencies')?.some(agency => {
          return rules.baseRules.certAgencies.includes(agency.certAgency)
        })
      ) {
        customReasons.baseRules = 'notMatchQualificationRulesAgency'
      }
      if (
        cert.getIn(['agencies', 0, 'expiration']) &&
        !moment(cert.getIn(['agencies', 0, 'expiration'])).isSameOrAfter(
          startDate,
          'day'
        )
      ) {
        customReasons.expired = 'expired'
      }
      if (
        attachmentAgencies.size > 0 &&
        !(
          isCertificationMatchAgencies(cert, attachmentAgencies) &&
          cert.attachment
        )
      ) {
        customReasons.attachmentAgencies = 'noAttachmentForGivenAgency'
      }
      if (
        attestation.get('selfCertified') &&
        cert.agencies.some(agency => agency.certAgency === 'Self Certify') &&
        !cert.lastModifiedTimeStamp
      ) {
        customReasons.selfCertified = 'requireForSelfCertified'
      }
      if (
        attestation.get('notVerifiedByTealbook') &&
        !tealbookConfirmed &&
        !cert.lastModifiedTimeStamp
      ) {
        customReasons.notVerifiedByTealbook = 'requireNotVerifiedByTealbook'
      }
      if (
        attestationAgencies.size > 0 &&
        !(isCertificationMatchAgencies(cert, attestationAgencies)
          ? !!cert.lastModifiedTimeStamp
          : true)
      ) {
        customReasons.attestationAgencies = 'requireAttestationByGivenAgency'
      }

      return Object.values(customReasons).length === 0
        ? cert.updateIn(['agencies', 0, 'info'], info => {
            return {
              ...info,
              potential: true
            }
          })
        : cert
            .updateIn(['agencies', 0, 'info'], info => {
              const alertCodes = info?.alertCodes || []
              return info
                ? {
                    ...info,
                    potential: true,
                    alertCodes: alertCodes.concat(Object.values(customReasons))
                  }
                : {
                    potential: true,
                    alertCodes: Object.values(customReasons)
                  }
            })
            .setIn(['agencies', 0, 'confirmed'], false)
    })
  )
}

/**
 * createFilteredDetailsSelector
 * @param getSubCategories 
 * @returns selector
 * gets spend details with at least one matching subcategory (if given), and filter out those don't match
 */
export const createFilteredDetailsSelector = getSubCategories => createSelector(
  getDetailsBySupplier,
  getSubCategories,
  (
    details: List<RecordOf<SpendReportDetails>>,
    subCategories: List<string>
  ) => {
    // filter out items don't contain the given subCategories
    return details
      .map(detail => {
        return filterCertificationBySubCategories(detail, subCategories)
      })
      .filter(detail => {
        return detail.certifications.size > 0
      })
  }
)

/**
 * createOverviewTotals
 * @param getRules 
 * @param getDetailsForOverview 
 * @returns selector
 * gets overview totals
 */
export const createOverviewTotals = (getRules, getDetailsForOverview) => createSelector<
  RootState,
  List<RecordOf<any>>,
  RecordOf<QualificationRules>,
  List<string | undefined>,
  {
    qualified?: { amount: number; count: number }
    potential?: { amount: number; count: number }
    disqualified?: { amount: number; count: number }
  }
>(
  getDetailsForOverview,
  getRules,
  usersSelectors.getAllColleagueIds,
  (
    details: List<RecordOf<SpendReportDetails>>,
    rules: RecordOf<QualificationRules>,
    colleagueIds: List<string>
  ) => {
    const disqualificationRules = rules.excludeRules
    return details &&
      details.reduce((result, detailsItem, key) => {
        let spendKind: 'qualified' | 'potential' | 'disqualified'
        if (isSpendItemDisqualified(
          detailsItem,
          disqualificationRules,
          colleagueIds
        )) {
          spendKind = 'disqualified'
        } else if (isSpendItemQualified(detailsItem, rules, colleagueIds)) {
          spendKind = 'qualified'
        } else {
          spendKind = 'potential'
        }

        if (result[spendKind]) {
          result[spendKind]['amount'] += detailsItem.totalAmount
          result[spendKind]['count'] += 1
        } else {
          result[spendKind] = {
            amount: detailsItem.totalAmount || 0,
            count: 1
          }
        }

        return result
      }, {})
  }
)

/**
 * createOverviewSpend
 * @param getDetailsForOverview 
 * @param getRules 
 * @param getGrouping 
 * @param getStartDate 
 * @returns sector
 * gets overview table data
 */
export const createOverviewSpend = (getDetailsForOverview, getRules, getGrouping, getStartDate) => createSelector<
  RootState,
  List<RecordOf<SpendReportDetails>>,
  List<string | undefined>,
  Moment,
  RecordOf<QualificationRules>,
  'subCategory' | 'country' | 'category' | 'spendGroup',
  OverviewSpendList
>(
  getDetailsForOverview,
  usersSelectors.getAllColleagueIds,
  getStartDate,
  getRules,
  getGrouping,
  (
    details: List<RecordOf<SpendReportDetails>>,
    colleagueIds: List<string>,
    startDate: Moment,
    rules: RecordOf<QualificationRules>,
    currentGrouping: 'subCategory' | 'country' | 'category' | 'spendGroup' = 'subCategory'
  ) => {
    const baseRules = rules.baseRules
    const disqualificationRules = rules.excludeRules
    let dupCounts: any = {}
    const overviewSpend =
      details &&
      details.reduce((result, detailsItem, key) => {
        let spendKind: 'qualified' | 'potential' | 'disqualified'
        const initialValues = {
          qualifiedAmount: 0,
          qualifiedCount: 0,
          disqualifiedAmount: 0,
          disqualifiedCount: 0,
          potentialAmount: 0,
          potentialCount: 0
        }

        let grouping = 'subCategories'
        if (currentGrouping === 'country') {
          grouping = 'locations'
        } else if (currentGrouping === 'category') {
          grouping = 'categories'
        } else if (currentGrouping === 'spendGroup') {
          grouping = 'spendGroups'
        }

        // detailsItem.subCategories // all available cert subCategories for this supplier
        let groupingData = detailsItem.get(
          grouping as
            | 'subCategories'
            | 'locations'
            | 'categories'
            | 'spendGroups'
        )
        let group: List<string> = List([])
        if (grouping === 'subCategories') {
          group = (groupingData as List<any>).filter(
            subCategory =>
              // only use the subCategories set in the baseRules

              baseRules.subCategories.size === 0
                ? true
                : baseRules.subCategories.includes(subCategory)
          )
        } else if (grouping === 'locations') {
          group = (groupingData as List<RecordOf<Location>>).map(
            (l: RecordOf<Location>) => l.country
          )
        } else if (grouping === 'categories') {
          group = (groupingData as List<RecordOf<Category>>).map(
            c => c.category
          )
        } else if (grouping === 'spendGroups') {
          group = (groupingData as List<RecordOf<SpendGroup>>).map(
            s => s.spendGroup
          )
        }
        group
          // sum up the amount for each grouping data
          .forEach((value, index, values) => {
            // supplier still have multiple categories after filter out those user cares
            if (values.size > 1) {
              dupCounts[value] = true
            }
            // remove cert that is not for the given subCategory if grouping is subCategory
            const groupingDetailsItem =
              grouping === 'subCategories'
                ? filterCertificationBySubCategories(
                    detailsItem,
                    List([value])
                  )
                : detailsItem

            // determine supplier qualification for the given grouping details
            if (
              isSpendItemDisqualified(
                groupingDetailsItem,
                disqualificationRules,
                colleagueIds
              )
            ) {
              spendKind = 'disqualified'
            } else if (
              isSpendItemQualified(groupingDetailsItem, rules, colleagueIds)
            ) {
              spendKind = 'qualified'
            } else {
              spendKind = 'potential'
            }

            let updatedDetailsItem = groupingDetailsItem
            if (spendKind === 'qualified') {
              updatedDetailsItem = updateQualifiedConfirmed(
                groupingDetailsItem,
                rules,
                colleagueIds
              )
            } else if (spendKind === 'potential') {
              updatedDetailsItem = updatePotentialReasons(
                groupingDetailsItem,
                rules,
                colleagueIds,
                startDate
              )
            }

            const subTypes =
              updatedDetailsItem?.getIn([
                'certifications',
                0,
                'agencies',
                0,
                'subTypes'
              ]) || []

            // sum up the result
            if (result[value]) {
              const amount =
                grouping === 'subCategories'
                  ? detailsItem.totalAmount
                  : detailsItem.getIn([grouping, index, 'amount'])
              result[value][`${spendKind}Amount`] += amount
              result[value][`${spendKind}Count`] += 1
              subTypes.forEach(subType => {
                if (result[value]['subTypes'][subType]) {
                  result[value]['subTypes'][subType][`${spendKind}Amount`] +=
                    detailsItem.totalAmount || 0
                  result[value]['subTypes'][subType][`${spendKind}Count`] += 1
                } else {
                  result[value]['subTypes'][subType] = Object.assign(
                    {},
                    initialValues,
                    {
                      [`${spendKind}Amount`]: detailsItem.totalAmount || 0,
                      [`${spendKind}Count`]: 1
                    }
                  )
                }
              })
            } else {
              const amount =
                grouping === 'subCategories'
                  ? detailsItem.totalAmount
                  : detailsItem.getIn([grouping, index, 'amount'])
              result[value] = Object.assign({}, initialValues, {
                [currentGrouping]: value,
                [`${spendKind}Amount`]: amount,
                [`${spendKind}Count`]: 1,
                subTypes: subTypes.reduce((result, subType) => {
                  result[subType] = Object.assign({}, initialValues, {
                    [`${spendKind}Amount`]: detailsItem.totalAmount || 0,
                    [`${spendKind}Count`]: 1
                  })
                  return result
                }, {})
              })
            }
          })

        return result
      }, {})

    // sum up qualification total
    const total = Object.values(overviewSpend).reduce(
      (total: OverviewTotalType, value: any) => {
        total['qualifiedTotalAmount'] += value.qualifiedAmount
        total['potentialTotalAmount'] += value.potentialAmount
        total['disqualifiedTotalAmount'] += value.disqualifiedAmount

        return total
      },
      {
        qualifiedTotalAmount: 0,
        potentialTotalAmount: 0,
        disqualifiedTotalAmount: 0
      }
    )

    return {
      spendItems: fromJS(
        Object.values(overviewSpend).sort(
          (item1: OverviewSpendItem, item2: OverviewSpendItem) =>
            item2.qualifiedAmount - item1.qualifiedAmount
        )
      ),
      ...(total as OverviewTotalType),
      dupCounts
    }
  }
)

const sortCertificates = (
  type: 'qualified' | 'potential' | 'disqualified',
  includeRules: RecordOf<IncludeRulesType>,
  excludeRules: RecordOf<ExcludeRulesType>
) => {
  /*
    sort the certificates base on the following factors:
      1. is cert confirmed
      2. is cert potential
      3. cert expiry date
      4. user validation
      5. tealbot validation
      6. agency ranking
    for qualified
      - confirmed
      - valid by user
      - valid by tealbot
      - highest rank
      - latest expiry
    for potential
      - potential
      - valid by user
      - valid by tealbot
      - highest rank
      - latest expiry
    for disqualified
      - invalid by user
      - highest rank
  */
  let validationPath
  if (type === 'potential') {
    validationPath = ['info', 'potential']
  } else {
    validationPath = ['confirmed']
  }

  // define verification value for the type
  const colleagueVerificationValue = type === 'disqualified' ? false : true

  const myTeamInclude = includeRules.get('myTeam')
  const tealbotInclude = includeRules.get('tealbook')
  const myTeamExclude = excludeRules.get('myTeam')

  return (
    cert1: RecordOf<Certification>,
    cert2: RecordOf<Certification>
  ) => {
    // get agency
    const agency1 = cert1.getIn(['agencies', 0])
    const agency2 = cert2.getIn(['agencies', 0])

    // check validation for the type
    let certValidation1 = 0,
      certValidation2 = 0
    certValidation1 = agency1?.getIn(validationPath) ? 1 : 0
    certValidation2 = agency2?.getIn(validationPath) ? 1 : 0

    // get last verification by colleague
    const lastColleague1 = cert1
      .get('certificationValidations')
      ?.filter(v => v.get('byColleague'))
      .last(undefined)
    const lastColleague2 = cert2
      .get('certificationValidations')
      ?.filter(v => v.get('byColleague'))
      .last(undefined)

    const colleagueHasVerified1 = !!lastColleague1 ? 1 : 0
    const colleagueHasVerified2 = !!lastColleague2 ? 1 : 0

    // check verification match
    let colleagueVerification1 = 0,
      colleagueVerification2 = 0
    if (
      (myTeamInclude !== 'ignore' && type !== 'disqualified') ||
      (myTeamExclude !== 'ignore' && type === 'disqualified')
    ) {
      // rules care about colleague's opinion
      colleagueVerification1 =
        lastColleague1?.get('confirmed') === colleagueVerificationValue ? 1 : 0
      colleagueVerification2 =
        lastColleague2?.get('confirmed') === colleagueVerificationValue ? 1 : 0
    }

    // get last validation by tealbot
    const lastTealbot1 = cert1
      .get('certificationValidations')
      ?.filter(v => !v.get('byColleague'))
      .last(undefined)
    const lastTealbot2 = cert2
      .get('certificationValidations')
      ?.filter(v => !v.get('byColleague'))
      .last(undefined)

    let tealbotVerification1 = 0,
      tealbotVerification2 = 0
    if (tealbotInclude !== 'ignore') {
      // rules care about tealbot's opinion on confirmed
      tealbotVerification1 = lastTealbot1?.get('confirmed') === true ? 1 : 0
      tealbotVerification2 = lastTealbot2?.get('confirmed') === true ? 1 : 0
    }

    // rank agency and compare expiry date if both from the same certAgency
    const agency1Rank = certAgencyRank(agency1?.get('certAgency'))
    const agency2Rank = certAgencyRank(agency2?.get('certAgency'))

    const expiration1: undefined | Date =
      agency1?.get('expiration') && new Date(agency1.get('expiration'))
    const expiration2: undefined | Date =
      agency2?.get('expiration') && new Date(agency2.get('expiration'))

    const agencyRanking =
      agency2Rank - agency1Rank !== 0
        ? agency2Rank - agency1Rank
        : ((!!expiration2 && expiration2?.getTime()) || 0) - ((!!expiration1 && expiration1?.getTime()) || 0)

    let validationRanking = 0
    if (type === 'disqualified') {
      // if type is disqualified, take colleagueValidation before certValidation
      validationRanking =
        colleagueVerification2 - colleagueVerification1 ||
        certValidation2 - certValidation1 ||
        colleagueHasVerified2 - colleagueHasVerified1
    } else {
      // if colleague invalidated it, put it at the bottom
      if (colleagueHasVerified1 && !colleagueVerification1) {
        validationRanking = 1
      } else if (colleagueHasVerified2 && !colleagueVerification2) {
        validationRanking = -1
      } else {
        validationRanking =
          certValidation2 - certValidation1 ||
          colleagueVerification2 - colleagueVerification1 ||
          colleagueHasVerified2 - colleagueHasVerified1 ||
          tealbotVerification2 - tealbotVerification1
      }
    }

    return validationRanking || agencyRanking
  }
}

const updateCertOrder = (
  spendItem: RecordOf<SpendReportDetails>,
  type: 'qualified' | 'potential' | 'disqualified',
  includeRules: RecordOf<IncludeRulesType>,
  excludeRules: RecordOf<ExcludeRulesType>
) => {
  return spendItem.updateIn(['certifications'], certifications => {
    return certifications.sort(
      sortCertificates(type, includeRules, excludeRules)
    )
  })
}

export const createSpendItemsForDetails = (getDetailsForSubCategories, getCurrentGrouping, getGroupingValues) => createSelector(
  getDetailsForSubCategories,
  getCurrentGrouping,
  getGroupingValues,
  (
    details: List<RecordOf<SpendReportDetails>>,
    currentGrouping: 'subCategory' | 'country' | 'category' | 'spendGroup',
    groupingValues: List<string>
  ) => {
    let transformDetails
    if (currentGrouping === 'subCategory') {
      transformDetails = details
    } else {
      transformDetails = details.filter(d => {
        let filterValues: List<string> = List([])
        if (currentGrouping === 'category') {
          filterValues = d.categories.map(c => c.category)
        }
        if (currentGrouping === 'country') {
          filterValues = d.locations.map(l => l.country)
        }
        if (currentGrouping === 'spendGroup') {
          filterValues = d.spendGroups.map(s => s.spendGroup)
        }

        return (
          !groupingValues ||
          groupingValues.size === 0 ||
          groupingValues.some(v => filterValues.includes(v))
        )
      })
      // update totalAmount
      if (groupingValues?.size > 0) {
        transformDetails = transformDetails.map(d => {
          let groupingAmountMap: { [key: string]: number } = {}
          if (currentGrouping === 'category') {
            groupingAmountMap = d.categories?.reduce((map, c) => {
              map[c.category] = c.amount
              return map
            }, {})
          }
          if (currentGrouping === 'country') {
            groupingAmountMap = d.locations?.reduce((map, l) => {
              map[l.country] = l.amount
              return map
            }, {})
          }
          if (currentGrouping === 'spendGroup') {
            groupingAmountMap = d.spendGroups.reduce((map, s) => {
              map[s.spendGroup] = s.amount
              return map
            }, {})
          }
          let totalAmount = 0
          groupingValues.forEach(v => {
            totalAmount += groupingAmountMap[v] || 0
          })
          return d.set('totalAmount', totalAmount)
        })
      }
    }
    return transformDetails
  }
)

export const createQualifiedDetailSpend = (getDetails, getRules, getStartDate) => createSelector<
  RootState,
  List<RecordOf<SpendReportDetails>>,
  RecordOf<QualificationRules>,
  List<string | undefined>,
  boolean,
  List<string>,
  Moment,
  [
    List<RecordOf<SpendReportDetails>>,
    number
  ]
>(
  getDetails,
  getRules,
  usersSelectors.getAllColleagueIds,
  (state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'isMatchAnyCategories']),
  (state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'selectedCategories']),
  getStartDate,
  (
    details: List<RecordOf<SpendReportDetails>>,
    rules: RecordOf<QualificationRules>,
    colleagueIds: List<string>,
    isMatchAny: boolean,
    selectedCategories: List<string>,
    startDate: Moment
  ) => {
    const qualifiedData =
      details &&
      details.filter(detailsItem => {
        return (
          isSpendItemQualified(
            detailsItem,
            rules,
            colleagueIds,
            !isMatchAny && selectedCategories?.size > 0
              ? selectedCategories
              : undefined
          ) &&
          !isSpendItemDisqualified(
            detailsItem,
            rules.excludeRules,
            colleagueIds
          )
        )
      })

    return [
      qualifiedData.map(item => {
        const itemReasons = updatePotentialReasons(
          item,
          rules,
          colleagueIds,
          startDate
        )
        const itemConfirmed = updateQualifiedConfirmed(
          itemReasons,
          rules,
          colleagueIds
        )
        return updateCertOrder(
          itemConfirmed,
          'qualified',
          rules.includeRules,
          rules.excludeRules
        )
      }),
      qualifiedData.reduce((result, item) => {
        return result + item.totalAmount
      }, 0)
    ]
  }
)

export const createPotentialDetailSpend = (getDetails, getRules, getStartDate) => createSelector<
  RootState,
  List<RecordOf<SpendReportDetails>>,
  RecordOf<QualificationRules>,
  List<string | undefined>,
  boolean,
  List<string>,
  Moment,
  [
    List<RecordOf<SpendReportDetails>>,
    number
  ]
>(
  getDetails,
  getRules,
  usersSelectors.getAllColleagueIds,
  (state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'isMatchAnyCategories']),
  (state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'selectedCategories']),
  getStartDate,
  (
    details: List<RecordOf<SpendReportDetails>>,
    rules: RecordOf<QualificationRules>,
    colleagueIds: List<string>,
    isMatchAny: boolean,
    selectedCategories: List<string>,
    startDate: Moment
  ) => {
    const potentialData =
      details &&
      details.filter(detailsItem => {
        return (
          !isSpendItemQualified(
            detailsItem,
            rules,
            colleagueIds,
            !isMatchAny && selectedCategories?.size > 0
              ? selectedCategories
              : undefined
          ) &&
          !isSpendItemDisqualified(
            detailsItem,
            rules.excludeRules,
            colleagueIds
          )
        )
      })

    return [
      potentialData.map(item => {
        const itemReasons = updatePotentialReasons(
          item,
          rules,
          colleagueIds,
          startDate
        )
        return updateCertOrder(
          itemReasons,
          'potential',
          rules.includeRules,
          rules.excludeRules
        )
      }),
      potentialData.reduce((result, item) => {
        return result + item.totalAmount
      }, 0)
    ]
  }
)

export const createDisqualifiedDetailSpend = (getDetails, getRules, getStartDate) => createSelector<
  RootState,
  List<RecordOf<SpendReportDetails>>,
  RecordOf<QualificationRules>,
  List<string | undefined>,
  Moment,
  [
    List<RecordOf<SpendReportDetails>>,
    number
  ]
>(
  getDetails,
  getRules,
  usersSelectors.getAllColleagueIds,
  getStartDate,
  (
    details: List<RecordOf<SpendReportDetails>>,
    rules: RecordOf<QualificationRules>,
    colleagueIds: List<string>,
    startDate: Moment
  ) => {
    const disqualifiedData =
      details &&
      details.filter(detailsItem => {
        return isSpendItemDisqualified(
          detailsItem,
          rules.excludeRules,
          colleagueIds
        )
      })

    return [
      disqualifiedData.map(item => {
        const itemReasons = updatePotentialReasons(
          item,
          rules,
          colleagueIds,
          startDate
        )
        const itemConfirmed = updateQualifiedConfirmed(
          itemReasons,
          rules,
          colleagueIds
        )
        return updateCertOrder(
          itemConfirmed,
          'disqualified',
          rules.includeRules,
          rules.excludeRules
        )
      }),
      disqualifiedData.reduce((result, item) => {
        return result + item.totalAmount
      }, 0)
    ]
  }
)