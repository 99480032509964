export const LOGOUT = 'LOGOUT'

export const SEND_CONTACT_US_MESSAGE = 'SEND_CONTACT_US_MESSAGE'

export const START_EDIT_MODE = 'START_EDIT_MODE'
export const STOP_EDIT_MODE = 'STOP_EDIT_MODE'

export const requestSuccess = (actionType: string) => `${actionType}_SUCCESS`
export const requestFailure = (actionType: string) => `${actionType}_FAILURE`

export const LOAD_DATA = 'LOAD_DATA'
export const UPDATE_DATA = 'UPDATE_DATA'
export const DELETE_DATA = 'DELETE_DATA'

export const LOAD_USER = 'LOAD_USER'
export const LOAD_ALL_USERS = 'LOAD_ALL_USERS'
export const UPDATE_USER = 'shared/SAVE_USER'
export const UPDATE_USER_ROLES = 'shared/UPDATE_USER_ROLES'

export const LOAD_USER_METRICS = 'shared/LOAD_USER_METRICS'

export const NOTIFY = 'shared/NOTIFY'
export const CLEAR_NOTIFICATIONS = 'shared/CLEAR_NOTIFICATIONS'

export const LOAD_COMMENTS = 'shared/LOAD_COMMENTS'
export const ADD_COMMENT = 'shared/ADD_COMMENT'
export const UPDATE_COMMENT = 'shared/UPDATE_COMMENT'
export const REMOVE_COMMENT = 'shared/REMOVE_COMMENT'

export const LOAD_ORG = 'shared/LOAD_ORG'

export const LOAD_MESSAGE_TOPIC = 'shared/LOAD_MESSAGE_TOPIC'
export const LOAD_MESSAGE_TOPICS = 'shared/LOAD_MESSAGE_TOPICS'
export const SELECT_USER_TO_MESSAGE = 'shared/SELECT_USER_TO_MESSAGE'
export const UNSELECT_USER_TO_MESSAGE = 'shared/UNSELECT_USER_TO_MESSAGE'
export const MESSAGE_USER = 'shared/MESSAGE_USER'
export const READ_MESSAGE = 'shared/READ_MESSAGE'

export const SUGGEST_ORG_UNIT = 'shared/SUGGEST_ORG_UNIT'
export const CREATE_CONCIERGE_REQUEST = 'shared/CREATE_CONCIERGE_REQUEST'

export const PRELOAD = 'shared/PRELOAD'

export const LOAD_CLIENT_FILES = 'LOAD_CLIENT_FILES'
export const LOAD_ORGUNIT_CLIENT_FILES = 'LOAD_ORGUNIT_CLIENT_FILES'
export const UPDATE_FILE_HISTORY = 'UPDATE_FILE_HISTORY'
export const UPLOAD_SPEND_FILE = 'shared/UPLOAD_SPEND_FILE'
export const UPLOAD_TIER2_SPEND_FILE = 'shared/UPLOAD_TIER2_SPEND_FILE'
export const LOAD_STAGING_HISTORY = 'shared/LOAD_STAGING_HISTORY'
export const PUBLISH_SPEND_T2 = 'shared/PUBLISH_SPEND_T2'
export const REMOVE_SPEND = 'shared/REMOVE_SPEND'
export const OPEN_REMAP_DIALOG = 'shared/OPEN_REMAP_DIALOG'
export const CLOSE_REMAP_DIALOG = 'shared/CLOSE_REMAP_DIALOG'
export const REMAP_SUPPLIER = 'shared/REMAP_SUPPLIER'
export const LOAD_INTERNAL_STATS = 'shared/insight/LOAD_INTERNAL_STATS'
export const LOAD_INTERNAL_STAT_KEYS = 'shared/insight/LOAD_INTERNAL_STAT_KEYS'
export const CLOSE_SUPPLIER_OUTREACH_REQUEST =
  'shared/insight/CLOSE_SUPPLIER_OUTREACH_REQUEST'
export const RESEND_SUPPLIER_OUTREACH_REQUEST =
  'shared/insight/RESEND_SUPPLIER_OUTREACH_REQUEST'
export const RESEND_SUPPLIER_OUTREACH_REQUEST_FOR_ESG_SURVEY =
  'shared/insight/RESEND_SUPPLIER_OUTREACH_REQUEST_FOR_ESG_SURVEY'
export const CONTACT_SUPPLIER_FOR_OUTREACH_REQUEST =
  'shared/insight/CONTACT_SUPPLIER_FOR_OUTREACH_REQUEST'
export const OPEN_FORWARD_SURVEY_REQUEST_DIALOG =
  'shared/OPEN_FORWARD_SURVEY_REQUEST_DIALOG '
export const CLOSE_FORWARD_SURVEY_REQUEST_DIALOG =
  'supplier/Survey/CLOSE_FORWARD_SURVEY_REQUEST_DIALOG'
