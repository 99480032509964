import React, { Component, ComponentProps } from 'react'
import AddUserForm from '../AddUserForm'
import Label from '../Label'
import Input from '../Input'
import { Field } from 'redux-form/immutable'
import { FormattedMessage } from 'react-intl'
import { RecordOf } from 'immutable'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Checkbox from 'shared/components/ReduxFormMaterialCheckbox'
import { withStyles, FormControlLabel } from '@material-ui/core'
import Text from 'shared/components/Text'
import ErrorIcon from '@material-ui/icons/ErrorOutline'

const StyledCheckbox = withStyles({
  root: {
    paddingRight: 14,
    width: 0
  }
})(Checkbox)

function ReduxFormPhoneInput({ input: { value, onChange, onBlur }, meta }) {
  return (
    <>
      <PhoneInput
        value={value}
        meta={meta}
        defaultCountry={'US'}
        countryOptionsOrder={['US', 'CA', 'AU', '|', '...']}
        type='tel'
        international
        placeholder='Enter phone number'
        onChange={onChange}
        onBlur={onBlur}
      />

      {meta.error?.props.defaultMessage && (
        <div className='flex items-center'>
          <ErrorIcon color='error' />
          <Text className='red ml1'>{meta.error?.props.defaultMessage}</Text>
        </div>
      )}
    </>
  )
}

function verifyPhoneNumber(value) {
  return value ? (
    isPossiblePhoneNumber(value) ? (
      undefined
    ) : (
      <FormattedMessage
        id='AddUserContactForm.InvalidPhonenumber'
        defaultMessage='Invalid phone number'
      />
    )
  ) : (
    undefined
  )
}
type Props = {
  initialValues: RecordOf<{
    isPublicSupplierContact?: boolean
    isPrivate?: boolean
    message?: string
    sendMessage?: boolean
    user?: { firstName?: string; lastName?: string; email?: string }
  }>
} & ComponentProps<typeof AddUserForm>

class AddUserContactForm extends Component<Props> {
  render() {
    const {
      creatingNewUser,
      sendCollaborateRequest,
      initialValues,
      caption,
      ...rest
    } = this.props

    const renderCheckbox = ({ input, label }) => (
      <div>
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={input.value}
              onChange={input.onChange}
              input={input}
            />
          }
          label={label}
        />
      </div>
    )

    return (
      <AddUserForm
        {...rest}
        message={initialValues.get('message')}
        creatingNewUser={creatingNewUser}
        sendCollaborateRequest={sendCollaborateRequest}
        caption={
          caption || (
            <FormattedMessage
              id='AddUserContactForm.AddContact'
              defaultMessage='Add Contact'
            />
          )
        }
        userLookupLabel={
          <FormattedMessage
            id='AddUserContactForm.InviteAContact'
            defaultMessage='Invite a contact'
          />
        }
      >
        {(creatingNewUser ||
          !(
            initialValues.getIn(['user', 'mobilePhone']) ||
            initialValues.getIn(['user', 'officePhone'])
          )) && (
          <div>
            <Label>
              <FormattedMessage
                id='AddUserContactForm.Phone'
                defaultMessage='Phone'
              />
            </Label>
            <Field
              component={ReduxFormPhoneInput}
              name='phone'
              validate={verifyPhoneNumber}
            />
          </div>
        )}
        {(creatingNewUser ||
          !(
            initialValues.getIn(['user', 'location']) ||
            initialValues.getIn(['user', 'location'])
          )) && (
          <div>
            <Label>
              <FormattedMessage
                id='AddUserContactForm.Location'
                defaultMessage='Location'
              />
            </Label>
            <Field component={Input} name='location' />
          </div>
        )}
        {!sendCollaborateRequest && (
          <>
            <Label>
              <FormattedMessage
                id='AddUserContactForm.WhyWouldThisPersonBeContacted'
                defaultMessage='Why would this person be contacted?'
              />
            </Label>
            <Field component={Input} name='contactFor' />
          </>
        )}
        {sendCollaborateRequest && initialValues && (
          <Field
            component={renderCheckbox}
            name='isPublicSupplierContact'
            inputProps={{
              'aria-label': 'add to private profile'
            }}
            label={
              <FormattedMessage
                id='AddUserContactForm.isPublicSupplierContact'
                defaultMessage='Add user to public profile'
              />
            }
          />
        )}
      </AddUserForm>
    )
  }
}

export default AddUserContactForm
