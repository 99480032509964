import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from 'shared/components/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import profileSelectors from 'buyer/SupplierProfile/selectors/profileSelectors'
import Select from 'shared/components/Select'
import Icon from '@material-ui/core/Icon'
import RootState from 'shared/models/RootState'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { addContact, requestEcovadisSupplierRating } from '../../../actions'
import { fromJS } from 'immutable'
import { makeStyles } from '@material-ui/core/styles'
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core'
import ContactForm from '../ContactForm'
import Input from 'shared/components/Input'
import analytics from 'shared/utils/analytics'

const useStyles = makeStyles(() => ({
  stepIcon: {
    color: '#eaeaf0',
  },
}))

const CustomDialogAction = withStyles({
  root: {
    marginRight: 48,
    marginBottom: 24,
  },
})(DialogActions)

const messages = defineMessages({
  domainShouldMatch: {
    id: 'AddUserForm.EmailShouldMatchTheDomainOfTheCompany',
    defaultMessage: 'Email should match the domain of the company',
  },
  supplierContact: {
    id: 'RequestSupplierRating.InviteSupplierContact',
    defaultMessage: 'Indicate a contact at the company you want to invite',
  },
  buyerContact: {
    id: 'RequestSupplierRating.AssignBuyerContact',
    defaultMessage: 'Assign a contact at your company',
  },
  assessmentCountry: {
    id: 'RequestSupplierRating.AssessmentCountry',
    defaultMessage: 'Choose a supplier location to be rated',
  },
})

type Props = {
  openRequestRatingDialog: boolean
  onHandleOpenDialog: (open: boolean) => void
}

const RequestSupplierRating = ({
  openRequestRatingDialog,
  onHandleOpenDialog,
}: Props) => {
  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()

  const steps = ['supplierContact', 'assessmentCountry', 'buyerContact']
  const supplierId = useSelector((state: RootState) =>
    profileSelectors.getDataField(state, 'id')
  )
  const supplierName = useSelector((state: RootState) =>
    profileSelectors.getDataField(state, 'name')
  )
  const contacts = useSelector(profileSelectors.getContactsInfo)
  const domains = useSelector((state: RootState) =>
    profileSelectors.getDataField(state, 'domains')
  )
  const countries = useSelector(profileSelectors.getLocationCountries)
  const currentUser = useSelector(sessionSelectors.getUser)
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const [activeStep, setActiveStep] = useState<number>(0)
  const [showAddSupplierContactForm, setShowAddSupplierContactForm] =
    useState<boolean>(!contacts.size)

  const supplierContactContent: {
    title?: string
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
  } = {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  }
  const [selectedSupplierContact, setSelectedSupplierContact] = useState(
    supplierContactContent
  )
  const [newSupplierContact, setNewSupplierContact] = useState(
    supplierContactContent
  )
  const [buyerContact, setBuyerContact] = useState<{
    firstName: string
    lastName: string
    email: string
  }>({
    firstName: currentUser.get('firstName') || '',
    lastName: currentUser.get('lastName') || '',
    email: currentUser.get('email'),
  })
  const [selectedCountry, setSelectedCountry] = useState<string>('')

  const [emailError, setEmailError] = useState<string>('')

  useEffect(() => {
    if (openRequestRatingDialog) {
      analytics.track('Ecovadis Opened', {
        eventSource: 'Supplier Profile',
        action: 'Opened',
        orgUnitId,
        orgUnitName,
      })
    }
  }, [orgUnitId, openRequestRatingDialog, orgUnitName])

  const verifyEmail = useCallback(
    (value) => {
      const errorMessage = !domains.some((domain) => {
        const parsedDomain = domain.split('@')
        return (
          value &&
          (value
            .toUpperCase()
            .endsWith(
              `@${parsedDomain[parsedDomain.length - 1].toUpperCase()}`
            ) ||
            value
              .toUpperCase()
              .endsWith(
                `.${parsedDomain[parsedDomain.length - 1].toUpperCase()}`
              ))
        )
      })
        ? intl.formatMessage(messages.domainShouldMatch)
        : undefined

      return errorMessage
    },
    [domains, intl]
  )
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setEmailError('')
    if (activeStep === 0) {
      setNewSupplierContact((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }))
    }
    if (activeStep === 2) {
      setBuyerContact((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }))
    }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const invalidSupplierEmail = newSupplierContact.email
      ? verifyEmail(newSupplierContact.email)
      : false
    if (activeStep === 2) {
      handleRequest()
    } else if (!!invalidSupplierEmail) {
      setEmailError(invalidSupplierEmail)
    } else if (!invalidSupplierEmail) {
      if (
        selectedSupplierContact.email &&
        newSupplierContact.email &&
        newSupplierContact.firstName &&
        newSupplierContact.lastName &&
        newSupplierContact.phoneNumber
      ) {
        //clear supplier Select and keep filled in form
        setSelectedSupplierContact(supplierContactContent)
      }
      setShowAddSupplierContactForm(false)
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    } else if (selectedCountry) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }
  const handleRequest = () => {
    if (newSupplierContact.email) {
      const newUser = fromJS({
        phone: newSupplierContact.phoneNumber,
        user: {
          title: newSupplierContact.title,
          firstName: newSupplierContact.firstName,
          lastName: newSupplierContact.lastName,
          email: newSupplierContact.email,
        },
      })
      dispatch(addContact(newUser))
    }

    const supplier = selectedSupplierContact.email
      ? selectedSupplierContact
      : newSupplierContact

    dispatch(
      requestEcovadisSupplierRating({
        supplierContact: supplier,
        buyerContact,
        suppliers: [supplierId],
        country: selectedCountry,
      })
    )
    analytics.track('EcoVadis Supplier Rating Requested', {
      supplierOrgUnitId: supplierId,
      supplierName: supplierName,
      orgUnitId,
      orgUnitName,
    })
    handleCloseDialog()
  }

  const handleCloseDialog = () => {
    onHandleOpenDialog(false)
    setActiveStep(0)
    onHandleOpenDialog(false)
    setShowAddSupplierContactForm(!contacts.size)
    setBuyerContact({
      firstName: '',
      lastName: '',
      email: '',
    })
    setNewSupplierContact(supplierContactContent)
    setSelectedSupplierContact(supplierContactContent)
    setEmailError('')
    setSelectedCountry('')
  }
  return (
    <>
      <Dialog
        open={openRequestRatingDialog}
        onClose={handleCloseDialog}
        fullWidth
      >
        <DialogTitle onClose={handleCloseDialog}>
          <FormattedMessage
            id='RequestSupplierRating.RequestRating'
            defaultMessage='Request Rating'
          />
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <div>
              <Stepper activeStep={activeStep} orientation='vertical'>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconProps={{
                        classes: { root: classes.stepIcon },
                      }}
                      classes={{
                        label: classes.stepIcon,
                      }}
                    >
                      <label
                        htmlFor={
                          index === 0 && !!contacts.size
                            ? `steps${index}`
                            : undefined
                        }
                      >
                        {intl.formatMessage(messages[label])}
                      </label>
                    </StepLabel>
                    <StepContent>
                      {index === 0 && (
                        <>
                          {!!contacts.size && (
                            <>
                              <div className='flex items-end mb3'>
                                <Select
                                  fullWidth
                                  required={
                                    !newSupplierContact.email &&
                                    !newSupplierContact.firstName &&
                                    !newSupplierContact.lastName
                                  }
                                  onChange={(e) => {
                                    setSelectedSupplierContact(
                                      contacts.get(e.currentTarget.value).toJS()
                                    )
                                  }}
                                  name={`steps${index}`}
                                >
                                  <FormattedMessage
                                    id='RequestSupplierRating.Select'
                                    defaultMessage='Select'
                                  >
                                    {(message) => (
                                      <option value=''>{message}</option>
                                    )}
                                  </FormattedMessage>
                                  {contacts.keySeq().map((key) => (
                                    <option key={key} value={key}>
                                      {key}
                                    </option>
                                  ))}
                                </Select>
                                <Label className='ml2'>
                                  {' '}
                                  <FormattedMessage
                                    id='RequestSupplierRating.Or'
                                    defaultMessage='Or'
                                  />
                                </Label>
                              </div>
                              <div className='flex items-end mb3'>
                                <Icon
                                  color='primary'
                                  onClick={() =>
                                    setShowAddSupplierContactForm(true)
                                  }
                                >
                                  add_circle
                                </Icon>
                                <Label>
                                  <FormattedMessage
                                    id='RequestSupplierRating.AddNewContact'
                                    defaultMessage='Add new contact'
                                  />
                                </Label>
                              </div>
                            </>
                          )}
                          {showAddSupplierContactForm && (
                            <ContactForm
                              onChangeInput={handleInputChange}
                              emailError={emailError}
                              domains={domains}
                              showAddSupplierContactForm={
                                showAddSupplierContactForm
                              }
                              selectedSupplierContact={selectedSupplierContact}
                            />
                          )}
                        </>
                      )}
                      {index === 1 && (
                        <>
                          {!!countries.size ? (
                            <>
                              <label
                                htmlFor='selectcountry'
                                className='visuallyhidden'
                              >
                                <FormattedMessage
                                  id='RequestSupplierRating.SelectACountry'
                                  defaultMessage={'Select a country: '}
                                />
                              </label>
                              <Select
                                fullWidth
                                required={!selectedCountry}
                                onChange={(e) => {
                                  setSelectedCountry(e.currentTarget.value)
                                }}
                                name='selectcountry'
                              >
                                <FormattedMessage
                                  id='RequestSupplierRating.Select'
                                  defaultMessage='Select'
                                >
                                  {(message) => (
                                    <option value=''>{message}</option>
                                  )}
                                </FormattedMessage>
                                {countries.map((country) => (
                                  <option key={country} value={country}>
                                    {country}
                                  </option>
                                ))}
                              </Select>
                            </>
                          ) : (
                            <Input
                              name='country'
                              onChange={(e) =>
                                setSelectedCountry(e.currentTarget.value)
                              }
                              required
                            />
                          )}
                        </>
                      )}
                      {index === 2 && (
                        <ContactForm
                          onChangeInput={handleInputChange}
                          isConcierge={currentUser
                            .get('email')
                            .includes('concierge')}
                          buyerContact={buyerContact}
                        />
                      )}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </DialogContent>
          <CustomDialogAction>
            <Button autoSize onClick={handleCloseDialog} secondary>
              <FormattedMessage
                id='RequestSupplierRating.Cancel'
                defaultMessage='Cancel'
              />
            </Button>
            {activeStep === 2 ? (
              <Button autoSize type='submit'>
                <FormattedMessage
                  id='RequestSupplierRating.Request'
                  defaultMessage='Request'
                />
              </Button>
            ) : (
              <Button autoSize type='submit'>
                <FormattedMessage
                  id='RequestSupplierRating.Next'
                  defaultMessage='Next'
                />
              </Button>
            )}
          </CustomDialogAction>
        </form>
      </Dialog>
    </>
  )
}

export default RequestSupplierRating
