import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageSideSection from 'shared/components/PageSideSection'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import communitiesSelectors from '../../../Communities/selectors/communitiesSelectors'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import communitiesPaths from '../../../Communities/routes/paths'
import parsePath from 'shared/utils/parsePath'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import Link from 'shared/components/Link'
import { List, RecordOf } from 'immutable'

type Props = {
  communities: List<
    RecordOf<{
      id: string
      name: string
      logoUrl: string
    }>
  >
} & RouteComponentProps

export class CommunitiesSection extends Component<Props> {
  handleViewForm(commId) {
    const { history, location } = this.props
    history.push(
      `${parsePath(communitiesPaths.communityForm, {
        communityId: commId,
        pageNum: 1
      })}?redirectFrom=${location.pathname}`
    )
  }

  render() {
    const { communities } = this.props

    return (
      <PageSideSection
        role='list'
        title={
          <FormattedMessage
            id='CommunitiesSection.OurCommunities'
            defaultMessage='Our Communities'
          />
        }
      >
        {communities.map(community => (
          <ListItem
            mini
            key={community.get('id')}
            leftAvatar={
              <Link
                to={communitiesPaths.community}
                params={{ communityId: community.get('id') }}
              >
                <OrgLogo url={community.get('logoUrl')} className='dim' />
              </Link>
            }
            primaryText={
              <Link
                to={communitiesPaths.community}
                params={{ communityId: community.get('id') }}
              >
                {community.get('name')}
              </Link>
            }
            secondaryText={
              community.has('form') ? (
                <Button
                  secondary
                  autoSize
                  size='small'
                  onClick={() => this.handleViewForm(community.get('id'))}
                >
                  <FormattedMessage
                    id='CommunitiesSection.ViewFormButton'
                    defaultMessage='View Form'
                  />
                </Button>
              ) : null
            }
          />
        ))}
      </PageSideSection>
    )
  }
}

export default connect(state => ({
  communities: communitiesSelectors.getAllAccepted(state)
}))(withRouter(CommunitiesSection))
