import React from 'react'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import CollaborateInviteDialog from '../../components/CollaborateInviteDialog'
import usersSelectors from 'shared/selectors/usersSelectors'
import { messageUser } from 'shared/actions'
import {
  openAddCollaborateContactForm,
  cancelAddingSurveyCollaborateContact,
  addSurveyCollaborateContact
} from '../../../shared/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { List, RecordOf } from 'immutable'
import User from 'shared/models/User'

type Props = {
  contacts: List<RecordOf<User>>
  messageUser: (arg: string) => void
  onClickNewUser: () => void
  onClickCancel: () => void
  addSurveyCollaborateContact: (user: { id: string }) => void
}

const CollaborateInviteDialogContainer = (props: Props) => {
  return (
    <CollaborateInviteDialog
      onMessageUser={props.messageUser}
      onAddSurveyCollaborateContact={props.addSurveyCollaborateContact}
      {...props}
    />
  )
}

export default connect(
  (state: RootState) => {
    const currentUserId = sessionSelectors.getUserId(state)
    const contacts = usersSelectors.getColleaguesExcludingUser(
      state,
      currentUserId
    )
    return {
      contacts
    }
  },
  {
    messageUser,
    onClickNewUser: openAddCollaborateContactForm,
    onClickCancel: cancelAddingSurveyCollaborateContact,
    addSurveyCollaborateContact
  }
)(CollaborateInviteDialogContainer)
