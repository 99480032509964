import React, { Component } from 'react'
import { connect } from 'react-redux'
import InsightPaper from 'shared/components/InsightPaper'
import InsightTitle from 'shared/components/InsightTitle'
import ActiveUser from '../../components/ActiveUser'
import insightsSelectors from '../../store/insightsSelectors'
import { loadInsights } from '../../store/actions'
import Scrollable from 'shared/components/Scrollable'
import parsePath from 'shared/utils/parsePath'
import paths from '../../../routes/paths'
import { FormattedMessage } from 'react-intl'
import Select from 'shared/components/Select'
import Loading from 'shared/components/Loading'
import { List, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import Grid from '@material-ui/core/Grid'

type Props = {
  isLoading: boolean
  activeUsers: List<
    RecordOf<{
      id: string
      profileUrl: string
      profilePictureUrl: string
      firstName: string
      lastName: string
      numberOfConnections: number
      numberOfTags: number
      numberOfContacts: number
      numberOfRatings: number
      numberOfFollows: number
      numberOfProfileViews: number
      numberOfSearches: number
      numberOfSessions: number
      numberOfVets: number
    }>
  >
  loadInsights: (payload: { interval?: number; from?: string }) => void
}

type State = {
  interval: number
}

export class MostActiveUsers extends Component<Props, State> {
  state = {
    interval: 90
  }

  handleIntervalChange = e => {
    const { loadInsights } = this.props

    loadInsights({ interval: e.currentTarget.value, from: 'ActiveUsers' })
    this.setState({ interval: e.currentTarget.value })
  }

  render() {
    const { activeUsers, isLoading } = this.props
    const { interval } = this.state

    if (activeUsers.size < 1) {
      return null
    }

    return (
      <Grid item md={6} xs={12}>
        <InsightTitle className='f5 fw3 pl3 mt2 mb2'>
          <div className='flex justify-between items-center'>
            <FormattedMessage
              id='MostActiveUsers.MostActiveUsers'
              defaultMessage='Most Active Users'
            />
            <div>
              <label htmlFor='filteractiveusers'>
                <FormattedMessage
                  id='MostActiveUsers.FilterBy'
                  defaultMessage='Filter by: '
                />
              </label>
              <Select
                value={interval}
                onChange={this.handleIntervalChange}
                name='filteractiveusers'
              >
                <FormattedMessage
                  id='MostActiveUsers.Last30Days'
                  defaultMessage='Last 30 Days'
                >
                  {label => <option value={30}>{label}</option>}
                </FormattedMessage>
                <FormattedMessage
                  id='MostActiveUsers.Last90Days'
                  defaultMessage='Last 90 Days'
                >
                  {label => <option value={90}>{label}</option>}
                </FormattedMessage>
                <FormattedMessage
                  id='MostActiveUsers.LastYear'
                  defaultMessage='Last Year'
                >
                  {label => <option value={365}>{label}</option>}
                </FormattedMessage>
                <FormattedMessage id='MostActiveUsers.All' defaultMessage='All'>
                  {label => <option value={''}>{label}</option>}
                </FormattedMessage>
              </Select>
            </div>
          </div>
        </InsightTitle>
        <InsightPaper>
          <div style={{ height: 276 }}>
            <Scrollable maxHeight={276}>
              {isLoading ? (
                <Loading />
              ) : (
                <ol className='list ma0 pa0'>
                  {activeUsers &&
                    activeUsers.map((user, index) => (
                      <ActiveUser
                        key={user.get('id')}
                        profileUrl={parsePath(paths.userprofile, {
                          userId: user.get('id')
                        })}
                        profilePictureUrl={user.get('profilePictureUrl')}
                        firstName={user.get('firstName')}
                        lastName={user.get('lastName')}
                        numberOfConnections={user.get('numberOfConnections')}
                        numberOfTags={user.get('numberOfTags')}
                        numberOfContacts={user.get('numberOfContacts')}
                        numberOfRatings={user.get('numberOfRatings')}
                        numberOfFollows={user.get('numberOfFollows')}
                        numberOfProfileViews={user.get('numberOfProfileViews')}
                        numberOfSearches={user.get('numberOfSearches')}
                        numberOfSessions={user.get('numberOfSessions')}
                        numberOfVets={user.get('numberOfVets')}
                        place={index + 1}
                      />
                    ))}
                </ol>
              )}
            </Scrollable>
          </div>
        </InsightPaper>
      </Grid>
    )
  }
}

export default connect(
  (state: RootState) => ({
    activeUsers: insightsSelectors.getMostActiveUsers(state),
    isLoading: state.getIn(['buyer', 'insights', 'isLoadingActiveUsers'])
  }),
  { loadInsights }
)(MostActiveUsers)
