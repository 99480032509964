import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import paths from './paths'
import LoginFormContainer from '../containers/LoginFormContainer'
import CreatePasswordForm from '../containers/CreatePasswordForm'
import ResetPasswordForm from '../containers/ResetPasswordForm'
import RegistrationComplete from '../components/RegistrationComplete'
import RegistrationWizard from '../containers/RegistrationWizard'
import { FormattedMessage } from 'react-intl'
import LoginForScanner from '../components/LoginForScanner'
import auth from 'shared/utils/api/auth'
import LoginWithTokenPage from 'shared/components/LoginWithTokenPage'
import RequestAdminApprovalPage from '../components/RequestAdminApprovalPage/RequestAdminApprovalPage'

const createRoutes = () => (
  <Switch>
    <Route path={paths.login} component={LoginFormContainer} />
    <Route
      path={paths.registrationComplete}
      render={() => (
        <RegistrationComplete
          thankYouMessage={
            <FormattedMessage
              id='accounts.createRoutes.thankYouForSigninUp'
              defaultMessage='Great! Thank you for signing up'
            />
          }
          subMessage={
            <FormattedMessage
              id='accounts.createRoutes.emailWillArriveSoon'
              defaultMessage={`
              Please note that an email should arrive within seconds.
              If you do not receive the email to your inbox, please check your spam
              and junk folders. If you have not received the e-mail, please reach out
              to support@tealbook.com for assistance`}
            />
          }
        />
      )}
    />

    <Route
      path={paths.requestRegistrationComplete}
      render={() => (
        <RegistrationComplete
          thankYouMessage={
            <FormattedMessage id='accounts.createRoutes.thankYouForSigninUp' />
          }
          subMessage={
            <FormattedMessage
              id='accounts.createRoutes.willContactSoon'
              defaultMessage='Our team will be contacting you within one business day.'
            />
          }
        />
      )}
    />

    <Route
      path={paths.resetPasswordComplete}
      render={() => (
        <RegistrationComplete
          thankYouMessage={
            <FormattedMessage
              id='accounts.createRoutes.thankYouForResetting'
              defaultMessage='Great! Thank you for resetting your password'
            />
          }
          subMessage={
            <FormattedMessage id='accounts.createRoutes.emailWillArriveSoon' />
          }
        />
      )}
    />
    <Route
      path={paths.requestAdminApproval}
      component={RequestAdminApprovalPage}
    />
    <Route path={paths.resetPassword} component={ResetPasswordForm} />
    <Route path={paths.register} component={RegistrationWizard} />
    <Route path={paths.createPassword} component={CreatePasswordForm} />
    <Route path={paths.sso}>
      <LoginWithTokenPage loginCallback={auth.loginWithSaml} />
    </Route>
    <Route path={paths.loginForScanner} component={LoginForScanner} />
    <Redirect from={paths.basePath} to={paths.login} />
  </Switch>
)

export default createRoutes
