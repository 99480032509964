import { createSelector } from 'reselect'
import { fromJS, List, RecordOf } from 'immutable'
import {
  baseRules,
  includeRules,
  excludeRules,
  diverseQualificationRules
} from '../diversityReportReducer'
import RootState from 'shared/models/RootState'
import spendReportSelectors from '../spendReportSelectors'
import { OverviewSpendItem } from '../../store/diversityReportSelectors/diversityReportSelectors'

const defaultBaseRules = fromJS({
  certAgencies: List([]),
  subCategories: List([]),
  countries: List([]),
  allSubCategories: true
})
const defaultExcludeRules = fromJS({
  myTeam: 'rejected'
})
const defaultIncludeRules = fromJS({
  myTeam: 'anyAgencies',
  tealbook: 'ignore',
  completeness: fromJS({
    attachmentAgencies: List([]),
    attachmentAgenciesSelected: false
  }),
  attestation: fromJS({
    selfCertified: false,
    notVerifiedByTealbook: false,
    certAgencies: List([]),
    certAgenciesSelected: false
  })
})

export const defaultRules = new diverseQualificationRules({
  baseRules: new baseRules(defaultBaseRules),
  includeRules: new includeRules(defaultIncludeRules),
  excludeRules: new excludeRules(defaultExcludeRules)
})

const getDetailsForOverview = spendReportSelectors.createFilteredDetailsSelector(
  (state: RootState) => fromJS([])
)

const getRules = (state: RootState) => defaultRules

const getGrouping = (state: RootState) => 'subCategory'

const getGroupingValues = (state: RootState) => fromJS([])

const getStartDate = (state: RootState) =>
  state.getIn(['buyer', 'diversityReport', 'startDate'])

export const getOverviewTotals = spendReportSelectors.createOverviewTotals(
  getRules,
  getDetailsForOverview
)

export const getOverviewSpend = spendReportSelectors.createOverviewSpend(
  getDetailsForOverview,
  getRules,
  getGrouping,
  getStartDate
)

export const getDetailsForSubCategories = spendReportSelectors.createFilteredDetailsSelector(
  (state: RootState) => {
    const selectedCategories = state.getIn([
      'buyer',
      'diversityReport',
      'selectedCategories'
    ])

    return selectedCategories && selectedCategories.size > 0
      ? selectedCategories
      : List([])
  }
)

export const getOverviewSubCategories = createSelector(
  getOverviewSpend,
  (overview: { spendItems: List<RecordOf<OverviewSpendItem>> }) => {
    const { spendItems } = overview

    return spendItems.map(item => item.get('subCategory'))
  }
)

export const getDetails = spendReportSelectors.createSpendItemsForDetails(
  getDetailsForSubCategories,
  getGrouping,
  getGroupingValues
)

export const getQualifiedDetailSpend = spendReportSelectors.createQualifiedDetailSpend(
  getDetails,
  getRules,
  getStartDate
)

export const getPotentialDetailSpend = spendReportSelectors.createPotentialDetailSpend(
  getDetails,
  getRules,
  getStartDate
)

export const getDisqualifiedDetailSpend = spendReportSelectors.createDisqualifiedDetailSpend(
  getDetails,
  getRules,
  getStartDate
)
