import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { FormattedMessage } from 'react-intl'
import LicenseTableRow from './LicenseTableRow'
import { Map, RecordOf } from 'immutable'
import { LicenseType } from 'shared/models/User'

type Props = {
  editMode: boolean
  initialValues: RecordOf<{
    buyerType: string
    categorization: string
    accountId: string
    diversityReportEnabled: boolean
    integrationPartner: boolean
    integrationsEnabled?: boolean
    sapIntegrationEnabled?: boolean
    showConcierge: string
    clientOf: string
    defaultLicenseType: LicenseType
    licenseConfig: RecordOf<{
      basic: Map<string, boolean>
      business: Map<string, boolean>
      professional: Map<string, boolean>
      advanced: Map<string, boolean>
      elite: Map<string, boolean>
    }>
  }>
}

const LicenseTable = ({ editMode, initialValues }: Props) => {
  return (
    <Table size='small'>
      <TableBody>
        <TableRow className='bg-light-gray pv3'>
          <TableCell>
            <div className='pv2'>
              <FormattedMessage
                id='SalesConfig.DataLimitations'
                defaultMessage='Data Limitations'
              />
            </div>
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Basic' defaultMessage='Basic' />
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Business' defaultMessage='Business' />
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Professional' defaultMessage='Professional' />
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Advanced' defaultMessage='Advanced' />
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Elite' defaultMessage='Elite' />
          </TableCell>
        </TableRow>
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.SuppliersWithoutARelationship'
              defaultMessage='Suppliers without a Relationship'
            />
          }
          fieldKey='accessNonRelatedSuppliers'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessNonRelatedSuppliers'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessNonRelatedSuppliers'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessNonRelatedSuppliers'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessNonRelatedSuppliers'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessNonRelatedSuppliers'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.SupplierSpend'
              defaultMessage='Supplier Spend'
            />
          }
          fieldKey='accessSupplierSpend'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessSupplierSpend'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessSupplierSpend'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessSupplierSpend'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessSupplierSpend'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessSupplierSpend'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.BasicExport'
              defaultMessage='Basic Export'
            />
          }
          fieldKey='accessExportBasic'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessExportBasic'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessExportBasic'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessExportBasic'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessExportBasic'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessExportBasic'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.AdvancedExport'
              defaultMessage='Advanced Export: Description/Contacts/Certificates/NAICS'
            />
          }
          fieldKey='accessExportAdvanced'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessExportAdvanced'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessExportAdvanced'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessExportAdvanced'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessExportAdvanced'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessExportAdvanced'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.ConfigurableExport'
              defaultMessage='Configurable Export: All fields'
            />
          }
          fieldKey='accessExportAll'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessExportAll'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessExportAll'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessExportAll'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessExportAll'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessExportAll'
          ])}
        />
        <TableRow className='bg-light-gray pv3'>
          <TableCell>
            <div className='pv2'>
              <FormattedMessage
                id='SalesConfig.FunctionalAreas'
                defaultMessage='Functional Areas'
              />
            </div>
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Basic' />
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Business' />
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Professional' />
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Advanced' />
          </TableCell>
          <TableCell align='center'>
            <FormattedMessage id='Elite' />
          </TableCell>
        </TableRow>
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.Qualifications'
              defaultMessage='Qualifications Section'
            />
          }
          fieldKey='accessQualifications'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessQualifications'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessQualifications'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessQualifications'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessQualifications'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessQualifications'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.Filters'
              defaultMessage='Discovery Filters'
            />
          }
          fieldKey='accessDiscoveryFilters'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessDiscoveryFilters'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessDiscoveryFilters'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessDiscoveryFilters'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessDiscoveryFilters'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessDiscoveryFilters'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={<FormattedMessage id='SalesConfig.RFI' defaultMessage='RFI' />}
          fieldKey='accessRFI'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessRFI'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessRFI'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessRFI'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessRFI'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessRFI'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.Contacts'
              defaultMessage='Contacts'
            />
          }
          fieldKey='accessContacts'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessContacts'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessContacts'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessContacts'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessContacts'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessContacts'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage id='SalesConfig.Chat' defaultMessage='Chat' />
          }
          fieldKey='accessChat'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessChat'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessChat'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessChat'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessChat'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessChat'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage id='SalesConfig.News' defaultMessage='News' />
          }
          fieldKey='accessNews'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessNews'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessNews'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessNews'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessNews'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessNews'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.Communities'
              defaultMessage='Communities'
            />
          }
          fieldKey='accessCommunities'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessCommunities'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessCommunities'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessCommunities'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessCommunities'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessCommunities'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <FormattedMessage
              id='SalesConfig.Insights'
              defaultMessage='Insights'
            />
          }
          fieldKey='accessInsights'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessInsights'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessInsights'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessInsights'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessInsights'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessInsights'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <>
              &rarr;{' '}
              <FormattedMessage
                id='SalesConfig.DiversityReport'
                defaultMessage='Diversity Report'
              />
            </>
          }
          fieldKey='accessDiversityReport'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessDiversityReport'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessDiversityReport'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessDiversityReport'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessDiversityReport'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessDiversityReport'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <>
              &rarr;{' '}
              <FormattedMessage
                id='SalesConfig.Tier2'
                defaultMessage='Tier 2'
              />
            </>
          }
          fieldKey='accessTier2'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessTier2'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessTier2'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessTier2'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessTier2'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessTier2'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <>
              &rarr;{' '}
              <FormattedMessage
                id='SalesConfig.EcoVadis'
                defaultMessage='EcoVadis'
              />
            </>
          }
          fieldKey='accessEcoVadis'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessEcoVadis'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessEcoVadis'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessEcoVadis'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessEcoVadis'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessEcoVadis'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <>
              &rarr;{' '}
              <FormattedMessage
                id='SalesConfig.SustainabilitySurveyReport'
                defaultMessage='Sustainability Survey Report'
              />
            </>
          }
          fieldKey='accessSustainabilitySurveyReport'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessSustainabilitySurveyReport'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessSustainabilitySurveyReport'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessSustainabilitySurveyReport'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessSustainabilitySurveyReport'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessSustainabilitySurveyReport'
          ])}
        />
        <LicenseTableRow
          editMode={editMode}
          label={
            <>
              &rarr;{' '}
              <FormattedMessage
                id='SalesConfig.SustainabilitySpendReport'
                defaultMessage='Sustainability Spend Report'
              />
            </>
          }
          fieldKey='accessSustainabilitySpendReport'
          basicAccess={initialValues?.getIn([
            'licenseConfig',
            'basic',
            'accessSustainabilitySpendReport'
          ])}
          businessAccess={initialValues?.getIn([
            'licenseConfig',
            'business',
            'accessSustainabilitySpendReport'
          ])}
          professionalAccess={initialValues?.getIn([
            'licenseConfig',
            'professional',
            'accessSustainabilitySpendReport'
          ])}
          advancedAccess={initialValues?.getIn([
            'licenseConfig',
            'advanced',
            'accessSustainabilitySpendReport'
          ])}
          eliteAccess={initialValues?.getIn([
            'licenseConfig',
            'elite',
            'accessSustainabilitySpendReport'
          ])}
        />
      </TableBody>
    </Table>
  )
}

export default LicenseTable
