import React from 'react'
import Page from 'shared/components/Page'
import TitleText from 'shared/components/TitleText'
import SubTitleText from 'shared/components/SubTitleText'
import { FormattedMessage } from 'react-intl'
import TealbookLogoWrapper from '../TealbookLogoWrapper'

const RequestAdminApprovalPage = () => {
  return (
    <Page title='Request Admin Approval' className='tc'>
      <TealbookLogoWrapper />
      <TitleText>
        <FormattedMessage
          id='RequestAdminApprovalPage.RequestAdminTitle'
          defaultMessage='Access Request Sent'
        />
      </TitleText>
      <SubTitleText>
        <FormattedMessage
          id='RequestAdminApprovalPage.RequestAdminApproval'
          defaultMessage={`We have sent a request to your organization's administrator for access review. If you require TealBook access, please contact your administrator.`}
        />
      </SubTitleText>
    </Page>
  )
}

export default RequestAdminApprovalPage
