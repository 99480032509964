import React, { Component, Fragment } from 'react'
import { Field } from 'redux-form/immutable'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import DatePicker from 'shared/components/DatePicker'
import { FormattedMessage } from 'react-intl'
import RadioButton from 'shared/components/RadioButton'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'

type Props = {
  title: string
  confirmed: boolean
  isProductLevelCertAgency?: boolean
}

class ValidationForm extends Component<Props> {
  render() {
    const { title, confirmed, isProductLevelCertAgency = false } = this.props

    return (
      <Fragment>
        <RadioButtonGroup
          inline
          title={
            isProductLevelCertAgency
            ? <FormattedMessage
              id='ValidationForm.confirmProductLevelLabel'
              defaultMessage={`Are the products you purchased certified according to the certifying agency's database?`}
            />
            : <FormattedMessage
              id='ValidationForm.confirmLabel'
              defaultMessage={`Is this company "{title}"?`}
              values={{ title }}
            />
          }
          className='mt3'
          stack
        >
          <Field
            name='confirmed'
            component={RadioButton}
            type='radio'
            label={
              isProductLevelCertAgency
              ? <FormattedMessage
                id='ValidationForm.confirmProductLevelYes'
                defaultMessage={`Yes, they are.`}
              />
              : <FormattedMessage
                id='ValidationForm.confirmYes'
                defaultMessage={`Yes, it is.`}
              />
            }
            required
            normalize={value => !!value}
            value={true as any}
          />
          <Field
            name='confirmed'
            component={RadioButton}
            type='radio'
            label={
              isProductLevelCertAgency
              ? <FormattedMessage
                id='CertificationValidation.confirmProductLevelNo'
                defaultMessage={`No, they are not.`}
              />
              : <FormattedMessage
                id='ValidationForm.confirmNo'
                defaultMessage={`No, it is not.`}
              />
            }
            required
            normalize={value => !value}
            value={false as any}
          />
        </RadioButtonGroup>
        {!isProductLevelCertAgency && confirmed && (
          <Fragment>
            <Label htmlFor='certificationNumber'>
              <FormattedMessage
                id='ValidationForm.certificateNumber'
                defaultMessage={`Certificate number (optional)`}
              />
            </Label>
            <Field name='certificationNumber' component={Input} />
            <Label htmlFor='validationExpires'>
              <FormattedMessage
                id='ValidationForm.validationExpires'
                defaultMessage={`Valid until (optional)`}
              />
            </Label>
            <Field
              name='validationExpires'
              component={DatePicker}
              minDate={new Date()}
              normalize={value => {
                return value && value.toISOString()
              }}
            />
          </Fragment>
        )}
        <Label htmlFor='note'>
          <FormattedMessage id='ValidationForm.note' defaultMessage={`Note`} />
        </Label>
        <Field name='note' component={TextArea} maxLength={200} />
      </Fragment>
    )
  }
}

export default ValidationForm
