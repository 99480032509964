import React, { useState, ChangeEvent } from 'react'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import { List, Map, RecordOf } from 'immutable'
import numberFormat from 'shared/utils/numberFormat'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Paper from 'shared/components/Paper'
import SpendFiltersSummary from '../../../Insights/containers/SpendFiltersSummary'
import { FormattedMessage, useIntl } from 'react-intl'
import iconClearAll from 'shared/assets/icons/clear-all.svg'
import Tooltip from 'shared/components/Tooltip'
import translateCertificationType from 'shared/utils/translateCertificationType'
import Switch from 'shared/components/Switch'
import iconSetting from 'shared/assets/icons/setting.svg'
import Menu from '@material-ui/core/Menu'
import MenuItem from 'shared/components/MenuItem'
import CheckMark from '@material-ui/icons/Check'
import certificationCategories from 'shared/models/CertificationCategories'
import startCase from 'lodash.startcase'
import Select from 'shared/components/Select'
import IconButton from 'shared/components/IconButton'
import { OverviewSpendList } from '../../../Insights/store/spendReportSelectors/spendReportSelectors'

export type OverviewSpendItem = {
  subCategory?: string | ''
  category?: string
  country?: string
  spendGroup?: string
  qualifiedAmount: number
  qualifiedCount: number
  potentialAmount?: number
  potentialCount?: number
  disqualifiedAmount?: number
  disqualifiedCount?: number
  subTypes?: Map<
    string,
    RecordOf<{
      qualifiedAmount: number
      qualifiedCount: number
      potentialAmount?: number
      potentialCount?: number
      disqualifiedAmount?: number
      disqualifiedCount?: number
    }>
  >
}

type Props = {
  hideFilterSummary?: boolean
  hideSubTypesBreakdown?: boolean
  hideGrouping?: boolean
  hidePotential?: boolean
  hideDisqualified?: boolean
  hideCount?: boolean
  selectedSubcategories: List<string>
  currentGrouping: 'subCategory' | 'category' | 'country' | 'spendGroup'
  isMatchAny: boolean
  overviewSpend: OverviewSpendList
  totalSpend: number
  selectSubcategory: (subCategory: string | '') => void
  clearSubcategories: () => void
  setMatchAny: (payload: boolean) => void
  selectGroupingValue?: (value: string) => void
  changeGrouping?: (
    grouping: 'subCategory' | 'category' | 'country' | 'spendGroup'
  ) => void
  clearGroupingValue?: () => void
  selectedGroupingValues?: List<string>
}

const SpendReportOverview = (props: Props) => {
  const { 
    hideFilterSummary, hideSubTypesBreakdown, hideGrouping, hidePotential, hideDisqualified, hideCount,
    selectedSubcategories, currentGrouping, isMatchAny, overviewSpend, totalSpend,
    selectedGroupingValues, selectSubcategory, clearSubcategories, setMatchAny,
    selectGroupingValue, changeGrouping, clearGroupingValue
  } = props

  const intl = useIntl()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [bySpend, setBySpend] = useState<boolean>(true)
  const [showSubTypes, setShowSubTypes] = useState<boolean>(false)


  const renderCheckboxCell = ({ cellData, rowData }: TableCellProps) => {
    const value = cellData
    const index = rowData.get('index')

    return (
      <Tooltip
        title={`Filter by ${translateCertificationType({
          intl,
          categoryType: 'diversity',
          subCategoryType: rowData.get('subCategory'),
          useAcronym: true
        })}`}
      >
        <div>
          <label htmlFor={value} className='visuallyhidden'>{value}</label>
          <input
            id={value}
            type='checkbox'
            data-index={index}
            value={value}
            checked={
              !!value &&
              (!currentGrouping || currentGrouping === 'subCategory'
                ? selectedSubcategories && selectedSubcategories.includes(value)
                : selectedGroupingValues &&
                  selectedGroupingValues.includes(value))
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation()
            }}
          />
        </div>
      </Tooltip>
    )
  }
  const renderValueCell = ({ cellData, rowData, dataKey }: TableCellProps) => {
    return (
      <div className='tr'>
        {bySpend ? '$' : ''}
        {numberFormat(cellData)}
        {showSubTypes &&
          renderSubTypesRows(cellData, rowData.get('subTypes'), dataKey)}
      </div>
    )
  }

  const handleClearAll = () => {
    if (!currentGrouping || currentGrouping === 'subCategory') {
      clearSubcategories()
    } else {
      clearGroupingValue && clearGroupingValue()
    }
  }

  const handleMenuChange = (value: boolean) => {
    setBySpend(value)
    setAnchorEl(null)
  }

  const onGroupingSelected = e => {
    e.preventDefault()
    if (changeGrouping) {
      changeGrouping(e.target.value)
      if (showSubTypes && e.target.value !== 'subCategory') {
        setShowSubTypes(false)
      }
    }
  }

  const renderSubTypesRows = (
    subCategory: string,
    subTypes: Map<
      string,
      RecordOf<{
        qualifiedAmount: number
        qualifiedCount: number
        potentialAmount?: number
        potentialCount?: number
        disqualifiedAmount?: number
        disqualifiedCount?: number
      }>
    >,
    key: string
  ) => {
    const orderedSubTypes = subTypes.keySeq().sort()

    return orderedSubTypes.map(subType => {
      let value
      if (key === '') {
        const translateSubTypes =
          certificationCategories['diversity']['subTypes']
        const translateSubTypesSubCategory =
          translateSubTypes && translateSubTypes[subCategory]
        const messageId =
          translateSubTypesSubCategory && translateSubTypesSubCategory[subType]
        value = (
          <>
            &bull;{' '}
            {messageId ? intl.formatMessage(messageId) : startCase(subType)}
          </>
        )
      } else if (key === '%') {
        const qualifiedAmount =
          subTypes.getIn([subType, 'qualifiedAmount']) || 0
        value =
        overviewSpend.qualifiedTotalAmount &&
          `${((qualifiedAmount / totalSpend) * 100).toFixed(2)}%`
      } else {
        value = `${bySpend ? '$' : ''}${numberFormat(
          subTypes.getIn([subType, key]) || 0
        )}`
      }

      return (
        <div key={subType} className='pv1 pl2 f8'>
          {value}
        </div>
      )
    })
  }

  const getRowHeight = ({ index }) => {
    const row = overviewSpend.spendItems.get(index)
    const numSubTypes = row?.get('subTypes')?.size || 0

    return numSubTypes * 21 + 52
  }

  return (
    overviewSpend && !!overviewSpend.spendItems.size
    ? <div className='mt3-5'>
      {!hideFilterSummary && (
        <div>
          <SpendFiltersSummary />
        </div>
      )}
      <div
        className={`mb2 flex ${
          !hideFilterSummary && selectedSubcategories.size > 0
            ? 'justify-between items-end'
            : 'justify-end items-center'
        }`}
      >
        {!hideFilterSummary && selectedSubcategories.size > 0 && (
          <Switch
            labelRight
            checked={!isMatchAny}
            disabled={currentGrouping !== 'subCategory'}
            label={
              <FormattedMessage
                id='SpendReportOverview.SupplierAllSelected'
                defaultMessage='Supplier includes all selected types'
              />
            }
            ariaLabel='Supplier includes all selected types'
            onChange={() => {
              setMatchAny(!isMatchAny)
            }}
          />
        )}
        <div className='tr'>
          {currentGrouping === 'subCategory' && (
            <Label className='f7 fw6 db mb1'>
              <FormattedMessage
                id='SpendReportOverview.DupCountsMessage'
                defaultMessage='* - some spend counts for multiple types'
              />
            </Label>
          )}
          {!hideSubTypesBreakdown && (
            <>
              {currentGrouping === 'subCategory' && (
                <Switch
                  labelRight
                  checked={showSubTypes}
                  label={
                    <FormattedMessage
                      id='SpendReportOverview.ShowSubTypesMessage'
                      defaultMessage='Breakout by Subtypes'
                    />
                  }
                  ariaLabel='Breakout by Subtypes'
                  onChange={() => {
                    setShowSubTypes(!showSubTypes)
                  }}
                />
              )}
              <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                <img
                  src={iconSetting}
                  alt='Config'
                  className='w1 v-mid dib ml3 pointer'
                />
              </IconButton>
              <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => handleMenuChange(true)}
                  aria-current={bySpend}
                >
                  <FormattedMessage
                    id='SpendReportOverview.BySpend'
                    defaultMessage='By Spend'
                  />
                  &nbsp;
                  {bySpend && <CheckMark color='inherit' fontSize='small' />}
                </MenuItem>
                <MenuItem
                  onClick={() => handleMenuChange(false)}
                  aria-current={!bySpend}
                >
                  <FormattedMessage
                    id='SpendReportOverview.ByCount'
                    defaultMessage='By Count'
                  />
                  &nbsp;
                  {!bySpend && <CheckMark color='inherit' fontSize='small' />}
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
      <Paper noPadding>
        <Table
          minWidth={1000}
          rowGetter={({ index }) => overviewSpend.spendItems.get(index)}
          rowCount={overviewSpend.spendItems.size}
          onRowClick={({
            rowData
          }: {
            rowData: RecordOf<OverviewSpendItem>
            event: ChangeEvent
          }) => {
            !currentGrouping || currentGrouping === 'subCategory'
              ? selectSubcategory(
                  rowData.get('subCategory') as string
                )
              : selectGroupingValue &&
                selectGroupingValue(
                  rowData.get(
                    currentGrouping as 'country' | 'category' | 'spendGroup'
                  ) || ''
                )
          }}
          rowHeight={showSubTypes ? getRowHeight : undefined}
        >
          <Column
            width={34}
            dataKey={currentGrouping || 'subCategory'}
            disableSort
            cellRenderer={renderCheckboxCell}
            headerRenderer={() =>
              ((!currentGrouping || currentGrouping === 'subCategory') &&
              selectedSubcategories?.size > 0) ||
              (currentGrouping &&
                currentGrouping !== 'subCategory' &&
                selectedGroupingValues &&
                selectedGroupingValues?.size > 0) ? (
                <img
                  src={iconClearAll}
                  alt='Clear all'
                  className='w1 v-mid dib pointer dim'
                  onClick={handleClearAll}
                />
              ) : null
            }
          />
          <Column
            dataKey={currentGrouping || 'subCategory'}
            width={150}
            cellRenderer={({ cellData, rowData }: TableCellProps) => (
              <>
                {cellData
                  ? cellData === 'null'
                    ? currentGrouping === 'country'
                      ? 'UNKNOWN'
                      : 'N/A'
                    : startCase(cellData).toUpperCase()
                  : 'OTHER'}
                {(!currentGrouping || currentGrouping === 'subCategory') &&
                overviewSpend.dupCounts[cellData]
                  ? '*'
                  : ''}
                {showSubTypes &&
                  renderSubTypesRows(cellData, rowData.get('subTypes'), '')}
              </>
            )}
            headerRenderer={
              !hideGrouping
                ? () => (
                    <Select
                      name='Grouping'
                      onChange={onGroupingSelected}
                      value={currentGrouping}
                      className={'bn f7 fw6 mid-gray ttc bg-transparent'}
                      wrapperClassName=''
                    >
                      <FormattedMessage
                        id='OverviewSpendTable.GroupingType'
                        defaultMessage='Type'
                      >
                        {text => <option value={'subCategory'}>{text}</option>}
                      </FormattedMessage>
                      <FormattedMessage
                        id='OverviewSpendTable.GroupingCategory'
                        defaultMessage='Category'
                      >
                        {text => <option value={'category'}>{text}</option>}
                      </FormattedMessage>
                      <FormattedMessage
                        id='OverviewSpendTable.GroupingCountry'
                        defaultMessage='Country'
                      >
                        {text => <option value={'country'}>{text}</option>}
                      </FormattedMessage>
                      <FormattedMessage
                        id='OverviewSpendTable.GroupingSpendGroup'
                        defaultMessage='Business Unit'
                      >
                        {text => <option value={'spendGroup'}>{text}</option>}
                      </FormattedMessage>
                    </Select>
                  )
                : () => (
                    <FormattedMessage
                      id='OverviewSpendTable.GroupingType'
                      defaultMessage='Type'
                    />
                  )
            }
          />
          <Column
            label={
              bySpend ? (
                <FormattedMessage
                  id='OverviewSpendTable.Qualified'
                  defaultMessage='Qualified'
                />
              ) : (
                <FormattedMessage
                  id='OverviewSpendTable.QualifiedCount'
                  defaultMessage='Qualified Count'
                />
              )
            }
            headerClassName='tr'
            dataKey={bySpend ? 'qualifiedAmount' : 'qualifiedCount'}
            width={150}
            cellRenderer={renderValueCell}
          />
          {!!totalSpend && (
            <Column
              label={
                <FormattedMessage
                  id='OverviewSpendTable.QualifiedSpend%'
                  defaultMessage='Qualified Spend %'
                />
              }
              headerClassName='tr'
              dataKey='qualifiedAmount'
              width={150}
              cellRenderer={({ cellData, rowData }: TableCellProps) => {
                return (
                  <div className='tr'>
                    {overviewSpend.qualifiedTotalAmount &&
                      ((cellData / totalSpend) * 100).toFixed(2)}
                    %
                    {showSubTypes &&
                      renderSubTypesRows(
                        cellData,
                        rowData.get('subTypes'),
                        '%'
                      )}
                  </div>
                )
              }}
            />
          )}
          {!hidePotential && (
            <Column
              label={
                bySpend ? (
                  <FormattedMessage
                    id='OverviewSpendTable.Potential'
                    defaultMessage='Potential'
                  />
                ) : (
                  <FormattedMessage
                    id='OverviewSpendTable.PotentialCount'
                    defaultMessage='Potential Count'
                  />
                )
              }
              headerClassName='tr'
              dataKey={bySpend ? 'potentialAmount' : 'potentialCount'}
              width={150}
              cellRenderer={renderValueCell}
            />
          )}
          {!hideDisqualified && (
            <Column
              label={
                bySpend ? (
                  <FormattedMessage
                    id='OverviewSpendTable.Disqualified'
                    defaultMessage='Disqualified'
                  />
                ) : (
                  <FormattedMessage
                    id='OverviewSpendTable.DisqualifiedCount'
                    defaultMessage='Disqualified Count'
                  />
                )
              }
              headerClassName='tr'
              dataKey={bySpend ? 'disqualifiedAmount' : 'disqualifiedCount'}
              width={150}
              cellRenderer={renderValueCell}
            />
          )}
          {!hideCount && (
            <Column
              label={
                <FormattedMessage
                  id='OverviewSpendTable.Count'
                  defaultMessage='Number of Suppliers'
                />
              }
              dataKey='count'
              width={150}
              flexGrow={1}
              headerClassName='tr'
              cellRenderer={({ cellData }: TableCellProps) => (
                <div className='tr'>{numberFormat(cellData)}</div>
              )}
            />
          )}
        </Table>
      </Paper>
      <Text className='tc mt4'>
        <FormattedMessage
          id='SpendPage.SelectAbove'
          defaultMessage='Use above to filter below'
        />
      </Text>
    </div>
    : <Text className='mt3-5'>
      <FormattedMessage
        id='OverviewSpendTable.noDataFound'
        defaultMessage='There was no data found.'
      />
    </Text>
  )
}

export default SpendReportOverview