import React, { Component } from 'react'
import { connect } from 'react-redux'
import VetDetailsPage from '../../components/VetDetailsPage'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import paths from '../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { RouteComponentProps } from 'react-router'
import { RecordOf, List } from 'immutable'
import downloadAttachment, {
  downloadAttachmentToURL
} from 'shared/utils/fileDownload/fileDownload'

type Props = {
  vet: List<
    RecordOf<{
      id: string
      name: string
      parents: RecordOf<{
        Org: string
        OrgUnit: string
      }>
    }>
  >
  match: { params: { vetBuyerSupplierId: string } }
  companyName: string
  companyOrgUnit: string
  isLoading: boolean
  status: string
} & RouteComponentProps

export class VetDetailsPageContainer extends Component<Props> {
  handleClickContinue = () => {
    const {
      history: { push },
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    push(
      parsePath(paths.vetResponse, {
        vetBuyerSupplierId
      })
    )
  }
  handleFileDownload = fileName => {
    const { vet } = this.props
    downloadAttachment(fileName, `vets/${vet.get(0)?.get('id')}/documentFile`)
  }

  getFilePreview = fileName => {
    const { vet } = this.props
    return downloadAttachmentToURL(
      fileName,
      `vets/${vet.get(0)?.get('id')}/documentFile/signedUrl`
    )
  }
  render() {
    const { vet, companyName, companyOrgUnit, isLoading, status } = this.props
    const termsAndConditionsSource =
      vet && vet.getIn(['vetDetails', 'termsAndConditionsSource'])
    const terms =
      termsAndConditionsSource &&
      termsAndConditionsSource !== 'Default' &&
      vet.getIn(['vetDetails', 'termsAndConditions'], '')

    return vet.size ? (
      <VetDetailsPage
        status={status}
        companyName={companyName}
        companyOrgUnit={companyOrgUnit}
        description={vet.getIn(['vetDetails', 'description'])}
        document={vet.getIn(['vetDetails', 'document'])}
        challenge={vet.getIn(['challenge'])}
        budget={vet.getIn(['vetDetails', 'budget'])}
        vetLocation={vet.getIn(['vetDetails', 'location'])}
        responseDate={
          vet.getIn(['vetDetails', 'responseDate']) &&
          new Date(vet.getIn(['vetDetails', 'responseDate']))
        }
        deliverables={vet.getIn(['vetDetails', 'deliverables'])}
        criticalCriteria={vet.getIn(['vetDetails', 'criticalCriteria'])}
        desiredOutcome={vet.getIn(['desiredOutcome'])}
        targetAudience={vet.getIn(['vetDetails', 'targetAudience'])}
        decisionDate={
          vet.getIn(['vetDetails', 'decisionDate']) &&
          new Date(vet.getIn(['vetDetails', 'decisionDate']))
        }
        detailedReponseRequested={vet.getIn([
          'vetDetails',
          'detailedReponseRequested'
        ])}
        responseInstructions={vet.getIn(['vetDetails', 'responseInstructions'])}
        terms={terms}
        isLoading={isLoading}
        onClickContinue={this.handleClickContinue}
        additions={vet.getIn(['vetDetails', 'additions'])}
        onDownload={this.handleFileDownload}
        getFilePreview={this.getFilePreview}
      />
    ) : null
  }
}

export const mapStateToProps = state => ({
  vet: vetBuyerSupplierSelectors.getVet(state),
  companyName: vetBuyerSupplierSelectors.getVetCompanyName(state),
  companyOrgUnit: vetBuyerSupplierSelectors.getVetCompanyOrgUnit(state),
  status: vetBuyerSupplierSelectors
    .getVetBuyerSupplier(state)
    .getIn(['collaboration', 'status']),
  isLoading: vetBuyerSupplierSelectors.getIsLoadingVet(state)
})

export default connect(mapStateToProps)(VetDetailsPageContainer)
