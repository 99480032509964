import React, { useState, memo, useMemo } from 'react'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import Text from 'shared/components/Text'
import moment from 'moment'
import { List, RecordOf } from 'immutable'
import { FormattedMessage } from 'react-intl'
import parsePath from 'shared/utils/parsePath'
import buyerPaths from '../../../routes/paths'
import Link from 'shared/components/Link'
import fileToUrl from 'shared/utils/data/fileToUrl'
import UploadedFile from 'shared/models/UploadedFile'
import OrgLogo from 'shared/components/OrgLogo'
import Button from 'shared/components/Button'

const sortSuppliers = ({ visibleSuppliers, sortDirection, sortBy }) => {
  const suppliersASC = sortedSuppliersASC({ visibleSuppliers, sortBy })
  const suppliersDESC = sortedSuppliersDESC({ visibleSuppliers, sortBy })
  return sortDirection === 'ASC' ? suppliersASC : suppliersDESC
}
const sortedSuppliersDESC = ({ visibleSuppliers, sortBy }) =>
  visibleSuppliers &&
  visibleSuppliers.sort((name1, name2) => {
    const supplier1 = name1 && name1.getIn([sortBy, 'name'])
    const supplier2 = name2 && name2.getIn([sortBy, 'name'])
    if (supplier1 > supplier2) {
      return -1
    }
    if (supplier1 < supplier2) {
      return 1
    }
    return 0
  })

const sortedSuppliersASC = ({ visibleSuppliers, sortBy }) =>
  visibleSuppliers &&
  visibleSuppliers.sort((name1, name2) => {
    const supplier1 = name1 && name1.getIn([sortBy, 'name'])
    const supplier2 = name2 && name2.getIn([sortBy, 'name'])
    if (supplier1 < supplier2) {
      return -1
    }
    if (supplier1 > supplier2) {
      return 1
    }
    return 0
  })

export type Tier2SuppliersProps = List<
  RecordOf<{
    supplier: RecordOf<{
      name: string
      domains: List<string>
      id: string
      primaryContact: RecordOf<{
        id: string
        firstName: string
        lastName: string
        primary: boolean
        email: string
        profilePicture: Map<string, string>
      }>
      officePhone: List<string>
      logo: UploadedFile
    }>
    status: string
    statusDate: string
    tier2: List<RecordOf<{ year: string; quarter: string }>>
  }>
>
type Props = {
  visibleSuppliers?: Tier2SuppliersProps
  onHandleClickSuggestedOrgUnit?: (
    orgUnit: RecordOf<{
      id: string
      name: string
      logo?: string
      domains?: List<string>
    }>
  ) => void
}
const Tier2InviteSupplierTable = memo(
  ({ visibleSuppliers, onHandleClickSuggestedOrgUnit }: Props) => {
    const [sortTable, setSortTable] = useState<{
      sortBy: string
      sortDirection: 'ASC' | 'DESC'
    }>({
      sortBy: 'supplier',
      sortDirection: 'ASC'
    })

    const sortedSuppliers = useMemo(() => {
      const { sortBy, sortDirection } = sortTable
      return sortSuppliers({ visibleSuppliers, sortDirection, sortBy })
    }, [sortTable, visibleSuppliers])

    const getRow = ({ index }) =>
      sortedSuppliers && sortedSuppliers.size > 0 && sortedSuppliers.get(index)

    const handleSortChange = ({ sortDirection, sortBy }) => {
      setSortTable({ sortBy, sortDirection })
    }
    return (
      <>
        {!sortedSuppliers || sortedSuppliers.size === 0 ? (
          <Text className='mt3'>
            <FormattedMessage
              id='Tier2InviteSupplier.InviteSuppliersDetails'
              defaultMessage='The suppliers you invite will be shown here with their progress as they load and submit spend.'
            />
          </Text>
        ) : (
          <div className={'ba b--light-gray mt2'}>
            <Table
              minWidth={900}
              rowGetter={getRow}
              rowCount={sortedSuppliers.size}
              rowClassName='pointer dim'
              sortBy={sortTable.sortBy}
              sort={handleSortChange}
              sortDirection={sortTable.sortDirection}
            >
              <Column
                label='Supplier'
                dataKey='supplier'
                width={300}
                cellRenderer={({ cellData }: TableCellProps) => (
                  <Link
                    to={parsePath(buyerPaths.supplierProfile, {
                      supplierId: cellData.get('id')
                    })}
                  >
                    <div className='flex'>
                      <div className='mr2 w2'>
                        <OrgLogo
                          url={fileToUrl(cellData.get('logo'))}
                          name={cellData.get('name')}
                        />
                      </div>
                      <div className='flex flex-column justify-center'>
                        {cellData.get('name')}
                        <Text className='word-wrap' secondary>
                          {cellData.get('domains').first()}
                        </Text>
                      </div>
                    </div>
                  </Link>
                )}
              />
              <Column
                label='Status'
                dataKey='status'
                width={200}
                cellRenderer={({ cellData }: TableCellProps) => cellData}
              />
              <Column
                label='Last Updated'
                dataKey='statusDate'
                width={200}
                cellRenderer={({ cellData }: TableCellProps) =>
                  moment(cellData).format('MMMM Do YYYY')
                }
              />
              <Column
                label='Spend Periods'
                dataKey='tier2'
                width={200}
                cellRenderer={({ rowData }: TableCellProps) => {
                  const spendGroups = rowData.get('tier2')
                  return spendGroups
                    ? spendGroups
                        .toJS()
                        .map(spend =>
                          spend.quarter
                            ? `${spend.year} Q${spend.quarter}`
                            : spend.year
                        )
                        .join(', ')
                    : undefined
                }}
              />

              <Column
                label='Action'
                dataKey='status'
                width={200}
                cellRenderer={({ rowData }: TableCellProps) => {
                  const status = rowData.get('status')
                  const supplier = rowData.get('supplier')
                  return status === 'Declined' ? (
                    <Button
                      autoSize
                      label={
                        <FormattedMessage
                          id='Tier2InviteSupplierTable.ResendInvitation'
                          defaultMessage='Resend Invitation'
                        />
                      }
                      onClick={() =>
                        onHandleClickSuggestedOrgUnit &&
                        onHandleClickSuggestedOrgUnit(supplier)
                      }
                    />
                  ) : (
                    undefined
                  )
                }}
              />
            </Table>
          </div>
        )}
      </>
    )
  }
)

export default Tier2InviteSupplierTable
