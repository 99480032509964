import React, { ReactElement } from 'react'
import sustainabilityReportSelectors from 'buyer/Insights/store/sustainabilityReportSelectors'
import { useSelector, useDispatch } from 'react-redux'
import RootState from 'shared/models/RootState'
import {
  selectDiversityCategory,
  clearAllDiversityCategories,
  setMatchAnyCategories
} from '../../store/actions'
import SpendReportOverview from 'buyer/shared/components/SpendReportOverview'
import Paper from 'shared/components/Paper'
import Loading from 'shared/components/Loading'
import { FormattedMessage } from 'react-intl'

type Props = {
  headerLabel?: string | ReactElement<FormattedMessage>
}

const SustainabilitySpendOverview = (props: Props) => {
  const dispatch = useDispatch()
  const loading = useSelector((state: RootState) => state.get('buyer').get('diversityReport').loading)
  const isMatchAny = useSelector((state: RootState) => state.getIn([
    'buyer',
    'diversityReport',
    'isMatchAnyCategories'
  ]))
  const totalSpend = useSelector((state: RootState) => state.getIn(['buyer', 'diversityReport', 'totalSpend']))
  const selectedCategories = useSelector((state: RootState) => state.getIn([
    'buyer',
    'diversityReport',
    'selectedCategories'
  ]))
  const overviewSpend = useSelector((state: RootState) => sustainabilityReportSelectors.getOverviewSpend(state))
  const { headerLabel } = props
  if (loading) {
    return <Loading />
  }

  return (
    <section className='pt4' id='overview'>
      {headerLabel && <header className='pl3-5'>
        <div>
          <h5 className='f5 mv3 fw3'>
            {headerLabel}
          </h5>
        </div>
      </header>}
      <Paper>
        <SpendReportOverview
          hideFilterSummary
          hideGrouping
          hideCount
          selectedSubcategories={selectedCategories}
          currentGrouping='subCategory'
          isMatchAny={isMatchAny}
          overviewSpend={overviewSpend}
          totalSpend={totalSpend}
          selectSubcategory={(payload) => dispatch(selectDiversityCategory(payload))}
          clearSubcategories={() => dispatch(clearAllDiversityCategories())}
          setMatchAny={(payload) => dispatch(setMatchAnyCategories(payload))}
        />
      </Paper>
    </section>
  )
}

export default SustainabilitySpendOverview