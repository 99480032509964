import React from 'react'
import { ReactComponent as HealthSafety } from 'shared/assets/images/esg/icon-health-safety.svg'
import { ReactComponent as Environment } from 'shared/assets/images/esg/icon-environment.svg'
import { ReactComponent as Diversity } from 'shared/assets/images/esg/icon-diversity.svg'
import { ReactComponent as Governance } from 'shared/assets/images/esg/icon-governance.svg'
import { ReactComponent as Privacy } from 'shared/assets/images/esg/icon-privacy.svg'
import WarningRounded from '@material-ui/icons/WarningRounded'
import { FormattedMessage } from 'react-intl'

const red = '#CC3131'
const gray = '#AAAAAA'
const teal = '#028383'

type Props = {
  pageId:
    | 'healthSafety'
    | 'environment'
    | 'diversityInclusion'
    | 'governance'
    | 'privacy'
  started: boolean
  completed: boolean
  missing: boolean
  smallOnly?: boolean
}

const BannerBadge = (props: Props) => {
  const { pageId, started, completed, missing, smallOnly } = props

  const style = {
    borderColor: missing ? red : !started && !completed ? gray : teal,
    backgroundColor: completed ? teal : 'white',
    fillColor: missing
      ? red
      : !started && !completed
      ? gray
      : completed
      ? 'white'
      : teal,
    incompleteBackground: '#F8D5CC'
  }

  const getIcon = () => {
    if (pageId === 'healthSafety') {
      return (
        <HealthSafety
          fill='white'
          style={{
            fill: style.fillColor
          }}
        />
      )
    } else if (pageId === 'environment') {
      return (
        <Environment
          fill='white'
          style={{
            fill: style.fillColor
          }}
        />
      )
    } else if (pageId === 'diversityInclusion') {
      return (
        <Diversity
          fill='white'
          style={{
            fill: style.fillColor
          }}
        />
      )
    } else if (pageId === 'governance') {
      return (
        <Governance
          fill='white'
          style={{
            fill: style.fillColor
          }}
        />
      )
    } else if (pageId === 'privacy') {
      return (
        <Privacy
          fill='white'
          style={{
            fill: style.fillColor
          }}
        />
      )
    }
    return null
  }

  return (
    <div className={`flex flex-column items-center ${missing ? 'pt4' : ''}`}>
      <div
        className={`mh2 pa2 bw1 mh3-ns pa4-ns bw2-ns br-100 ba`}
        style={{
          borderColor: style.borderColor,
          backgroundColor: style.backgroundColor
        }}
      >
        <div className={`w2 h2 ${smallOnly ? 'w2-ns h2-ns' : 'w3-ns h3-ns'}`}>
          {getIcon()}
        </div>
      </div>
      {missing && (
        <div
          className='flex justify-center items-center mt2 dib'
          style={{
            backgroundColor: style.incompleteBackground
          }}
        >
          <WarningRounded
            aria-label='Survey section incomplete'
            color='error'
          />
          <div className='ml1 red lh-copy f7 fw6 ma1'>
            <FormattedMessage
              id='BannerBadge.incomplete'
              defaultMessage='Incomplete'
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default BannerBadge
