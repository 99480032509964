import React, { useState, useMemo, ChangeEvent, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import FileInput from 'shared/components/FileInput'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Select from 'shared/components/Select'
import api from 'shared/utils/api'
import Warning from 'shared/components/Warning'
import { useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import apiRoutes from 'shared/routes/apiRoutes'
import { getByDomain } from 'shared/utils/api/buyer/buyer'
import CircularProgress from '@material-ui/core/CircularProgress'

type Props = {
  isMl?: boolean
  hideQuarter?: boolean
  disabledUpload?: boolean
  onUpload: (params: { file: File; year?: number; quarter?: number }) => void
}

const UploadSection = (props: Props) => {
  const { isMl, hideQuarter, disabledUpload, onUpload } = props
  const today = useMemo(() => new Date(), [])
  const isUploading = useSelector((state: RootState) =>
    state.getIn(['admin', 'upload', 'isUploading'])
  )
  const uploadError = useSelector((state: RootState) =>
    state.getIn(['admin', 'upload', 'uploadError'])
  )

  const [uploadQuarter, setUploadQuarter] = useState<string>(
    Math.floor(today.getMonth() / 3 + 1).toString()
  )
  const [uploadYear, setUploadYear] = useState<string>(
    today.getFullYear().toString()
  )
  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const [recomputeTrendsPressed, setRecomputeTrendsPressed] = useState<boolean>(
    false
  )
  const [computeTrendsError, setComputeTrendsError] = useState<boolean>(false)
  const [buyersByDomain, setBuyersByDomain] = useState([
    { name: 'TealBook', id: 'OrgUnit-5157071787917312-5629499534213120' }
  ])
  const [loading, setLoading] = useState(false)
  const [parentOrgUnitIdMl, setParentOrgUnitIdMl] = useState(
    'OrgUnit-5157071787917312-5629499534213120'
  )

  useEffect(() => {
    if (isMl) {
      setLoading(true)
      getByDomain('').then(result => {
        const buyerWithNameAndId = result
          .map(e => {
            return {
              name: e.name,
              id: e.id
            }
          })
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        setBuyersByDomain(buyerWithNameAndId)
        setLoading(false)
      })
    }
  }, [isMl])

  useEffect(() => {
    if (!isUploading && !uploadError) {
      setUploadFile(null)
    }
  }, [isUploading, uploadError])

  const handleFileUpload = () => {
    if (uploadFile) {
      const params = hideQuarter
        ? { file: uploadFile }
        : isMl
        ? {
            file: uploadFile,
            year: Number(uploadYear),
            quarter: Number(uploadQuarter),
            parentOrgUnitIdMl: parentOrgUnitIdMl
          }
        : {
            file: uploadFile,
            year: Number(uploadYear),
            quarter: Number(uploadQuarter)
          }
      onUpload(params)
    }
  }

  return (
    <div className='mt3'>
      <div className={`w-100 dib mb3 w-50-ns`}>
        <h5 className='db mb3 f7 fw6'>
          <FormattedMessage
            id='LoadPage.UploadFileSpend1'
            defaultMessage='Upload your spend file'
          />
        </h5>
        <div className='pr4-ns'>
          <Text>
            <FormattedMessage
              id='LoadPage.Instruction'
              defaultMessage='1. Download and open the load file template.{br}2. Review the instructions in the <b>Instructions</b> tab, then populate your spend data under the appropriate columns in the <b>Details</b> tab.{br}3. Save and drop the completed template into the uploader.{br}4. Click <b>Upload file</b>.'
              values={{
                b: b => <span className='fw8'>{b}</span>,
                br: <br />
              }}
            />
          </Text>
          <div className='flex items-end'>
            <form
              method='get'
              action={`/downloads/Tier 2 Spend Data Template.xlsx`}
            >
              <Button secondary autoSize type='submit' className='mt3'>
                <FormattedMessage
                  id='LoadPage.DownloadTemplateL'
                  defaultMessage='Download spend template'
                />
              </Button>
            </form>
            {isMl && (
              <Button
                className='ml2'
                autoSize
                disabled={recomputeTrendsPressed}
                onClick={() => {
                  api
                    .post(`${apiRoutes.loader}/util/computeTrends`)
                    .then(response => {
                      if (response) {
                        setRecomputeTrendsPressed(false)
                      } else {
                        setRecomputeTrendsPressed(false)
                        setComputeTrendsError(true)
                      }
                    })
                }}
              >
                {!recomputeTrendsPressed ? (
                  <FormattedMessage
                    id='Loader.RecomputeTrends'
                    defaultMessage='Recompute Trends'
                  />
                ) : (
                  <FormattedMessage
                    id='Loader.ComputeTrendsProcessing'
                    defaultMessage='Computing Trends in Progress...'
                  />
                )}
              </Button>
            )}
          </div>
          {computeTrendsError && (
            <Warning
              message={
                <FormattedMessage
                  id='LoadPage.RecomputeError'
                  defaultMessage='Something went wrong, please contact support.'
                />
              }
            />
          )}
        </div>
      </div>

      <div className={`w-100 dib v-top w-50-ns ${hideQuarter ? 'mt4' : ''}`}>
        {!hideQuarter && (
          <Label noPadding>
            <FormattedMessage
              id='LoadPage.UploadLabel'
              defaultMessage='Select a quarter and upload your file'
            />
          </Label>
        )}
        <FileInput
          small
          accept='.csv, .xlsx, .xls'
          limit={32}
          name='spendFile'
          isUploading={isMl ? isUploading : false}
          value={uploadFile ? uploadFile.name : ''}
          onChange={(file: File | null) => setUploadFile(file)}
        />
        {uploadError && (
          <Warning
            message={
              <FormattedMessage
                id='LoadPage.Error'
                defaultMessage='Oops, something went wrong. Please try again later.'
              />
            }
          />
        )}
        {isMl && (
          <div className={`mt2 flex items-center justify-between`}>
            <div className='w-50'>
              <label htmlFor='parentOrgUnitIdMl'>
                <FormattedMessage
                  id='LoadPage.BuyerOrgUnitForInternalMatch'
                  defaultMessage={'Buyer OrgUnit For Internal Match:'}
                />
              </label>
            </div>

            <div className='w-50'>
              <Select
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setParentOrgUnitIdMl(e.currentTarget.value)
                }}
                value={parentOrgUnitIdMl}
                fullWidth
                name='parentOrgUnitIdMl'
              >
                {buyersByDomain.map(buyer => (
                  <option key={buyer.id} value={buyer.id}>{buyer.name}</option>
                ))}
                {loading && <option>Loading...</option>}
              </Select>
            </div>
          </div>
        )}
        <div
          className={`mt2 flex items-center ${
            hideQuarter ? 'justify-end' : 'justify-between'
          }`}
        >
          {!hideQuarter && (
            <div className='w-100 w-third-ns mr3'>
              <label htmlFor='selectyear' className='visuallyhidden'>
                <FormattedMessage
                  id='LoadPage.SelectYear'
                  defaultMessage={'Select year:'}
                />
              </label>
              <Select
                disabled={isUploading}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setUploadYear(e.currentTarget.value)
                }
                value={uploadYear}
                fullWidth
                name='selectyear'
              >
                <option value={`${today.getFullYear()}`}>
                  {today.getFullYear()}
                </option>
                <option value={`${today.getFullYear() - 1}`}>
                  {today.getFullYear() - 1}
                </option>
                <option value={`${today.getFullYear() - 2}`}>
                  {today.getFullYear() - 2}
                </option>
                <option value={`${today.getFullYear() - 3}`}>
                  {today.getFullYear() - 3}
                </option>
                <option value={`${today.getFullYear() - 4}`}>
                  {today.getFullYear() - 4}
                </option>
              </Select>
            </div>
          )}
          {!hideQuarter && (
            <div className='w-100 w-third-ns mr3'>
              <label htmlFor='selectquarter' className='visuallyhidden'>
                <FormattedMessage
                  id='LoadPage.SelectqUARTER'
                  defaultMessage={'Select quarter:'}
                />
              </label>
              <Select
                disabled={isUploading}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setUploadQuarter(e.currentTarget.value)
                }
                value={uploadQuarter}
                fullWidth
                name='selectquarter'
              >
                <option value='1'>Q1</option>
                <option value='2'>Q2</option>
                <option value='3'>Q3</option>
                <option value='4'>Q4</option>
              </Select>
            </div>
          )}
          <Button
            className='w-third-ns'
            disabled={!uploadFile || isUploading || disabledUpload}
            onClick={handleFileUpload}
          >
            {!isUploading ? (
              <FormattedMessage
                id='LoadPage.UploadFile'
                defaultMessage='Upload File'
              />
            ) : (
              <div className='animate flex justify-center w-20 pv2'>
                <CircularProgress
                  style={{ height: '30%' }}
                  aria-label='Uploading...'
                />
              </div>
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UploadSection
