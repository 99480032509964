import React, { useState, useMemo } from 'react'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import PageSection from 'shared/components/PageSection'
import Page from 'shared/components/Page'
import { RecordOf, List } from 'immutable'
import Select from 'shared/components/Select'
import startCase from 'lodash.startcase'
import Button from 'shared/components/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import ErrorIcon from '@material-ui/icons/Error'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'

type NewValueProps = string | undefined

type PreValueProps = string | undefined

type LoaderDetails =
  | RecordOf<{
      status?: string
      filename?: string
      progress?: string
      loadType?: string
    }>
  | undefined

type BuyerChangeEvents = List<
  RecordOf<{
    label: string
    details: RecordOf<{
      user?: string
      keys?: List<string>
      prevVals?: string
      newVals?: string
      status?: string
      filename?: string
      progress?: string
      errorList?: List<string>
    }>
    date: string
  }>
>

type Props = {
  buyerChangeEvents: BuyerChangeEvents
}

const ChangeHistory = ({ buyerChangeEvents }: Props) => {
  const [eventType, setEventType] = useState<string>('')
  const [eventSubtype, setEventSubtype] = useState<string>('')
  const [openCompareDialog, setOpenCompareDialog] = useState<boolean>(false)
  const [newValuesState, setNewValues] = useState<NewValueProps>(undefined)
  const [prevValuesState, setPrevValues] = useState<PreValueProps>(undefined)
  const [subType, setSubType] = useState<string>('')
  const [openViewLoader, setOpenViewLoader] = useState<boolean>(false)
  const [loaderDetails, setLoaderDetails] = useState<LoaderDetails>(undefined)

  let errorList: List<string> | undefined

  const visibleChangeEvents = useMemo(
    () =>
      buyerChangeEvents.filter(eventKey => {
        if (!eventType) {
          return true
        }
        if (eventKey.get('label') && eventKey.get('label') === eventType) {
          if (eventType === 'updateBuyerSettings') {
            if (!eventSubtype) {
              return true
            }
            if (eventKey.getIn(['details', 'keys'])) {
              return eventKey.getIn(['details', 'keys']).includes(eventSubtype)
            }
          }
          return true
        }
        return false
      }),
    [buyerChangeEvents, eventSubtype, eventType]
  )

  const getRow = ({ index }) => {
    return (
      visibleChangeEvents &&
      visibleChangeEvents.size > 0 &&
      visibleChangeEvents.get(index)
    )
  }
  const handleFilterByType = e => {
    const eventType = e.target.value
    setEventType(eventType)
    setEventSubtype('')
  }
  const handleFilterBySubType = e => {
    const changeKey = e.target.value
    setEventSubtype(changeKey)
  }

  const handleCompareChangesClick = rowData => {
    if (!!rowData && !!rowData.size && rowData.get('details')) {
      let subtype: string = rowData.getIn(['details', 'keys']).join(' ')
      let newValue: any
      let prevValue: any
      try {
        newValue = Object.values(
          JSON.parse(rowData.getIn(['details', 'newVals']))
        )[0]
        prevValue = Object.values(
          JSON.parse(rowData.getIn(['details', 'prevVals']))
        )[0]
      } catch (err) {
        newValue = rowData.getIn(['details', 'newVals'])
        prevValue = rowData.getIn(['details', 'prevVals'])
      }

      if (typeof newValue === 'string' || typeof prevValue === 'string') {
        setNewValues(newValue)
        setPrevValues(prevValue)
      } else {
        setNewValues(JSON.stringify(newValue, null, '\t'))
        setPrevValues(JSON.stringify(prevValue, null, '\t'))
      }
      setSubType(startCase(subtype))
      setOpenCompareDialog(true)
    } else {
      return null
    }
  }

  const handleViewLoaderDetails = details => {
    if (details && details.get('status')) {
      errorList = details.get('errorList')
      setLoaderDetails(details)
      setOpenViewLoader(true)
    } else if (details && details.get('filename')) {
      setLoaderDetails(details)
      setOpenViewLoader(true)
    }
  }

  const handleCloseDialog = () => {
    setOpenCompareDialog(false)
    setOpenViewLoader(false)
    setNewValues('')
    setPrevValues('')
    setLoaderDetails(undefined)
  }
  const renderUpdateSettings = rowData => {
    return (
      rowData && (
        <Button
          autoSize
          onClick={() => handleCompareChangesClick(rowData)}
          label='Compare Changes'
        />
      )
    )
  }
  const renderConciergeActions = rowData => {
    return (
      rowData &&
      rowData.getIn(['details', 'action']) &&
      startCase(rowData.getIn(['details', 'action']))
    )
  }

  const renderLoaderActions = details => {
    if (details.get('status') || details.get('filename')) {
      return (
        <Button
          autoSize
          onClick={() => handleViewLoaderDetails(details)}
          label='View Actions'
        />
      )
    } else {
      return null
    }
  }

  const changeEventTypes = useMemo(
    () =>
      buyerChangeEvents
        .reduce<Array<string>>((result, currentItem) => {
          let label: string = currentItem.get('label')
          if (result.indexOf(label) < 0) {
            result.push(label)
          }
          return result
        }, [])
        .sort(),
    [buyerChangeEvents]
  )
  const changeEventSubtypes = useMemo(
    () =>
      buyerChangeEvents
        .reduce<Array<string>>((result, currentItem) => {
          // keys is a list of events
          let keys: List<string> = currentItem.getIn(['details', 'keys'])
          if (keys && keys.size > 0) {
            keys.forEach((key: string) => {
              if (result.indexOf(key) < 0) {
                result.push(key)
              }
              return result
            })
          }
          return result
        }, [])
        .sort(),
    [buyerChangeEvents]
  )

  const title = (
    <FormattedMessage id='App.ChangeHistory' defaultMessage='Change History' />
  )

  return (
    <>
      <Page title={title}>
        {visibleChangeEvents && (
          <PageSection
            noPadding
            title={
              <div className='tr'>
                <span>Change Events</span>
                <div className='w5 dib ml2'>
                  <label htmlFor='selecttype' className='visuallyhidden'>
                    <FormattedMessage
                      id='ChangeHistory.SortByType'
                      defaultMessage={'Sort by Type:'}
                    />
                  </label>
                  <Select
                    fullWidth
                    onChange={handleFilterByType}
                    name='selecttype'
                  >
                    <option value=''>All Events</option>
                    {changeEventTypes.map(eventLabel => (
                      <option key={eventLabel} value={eventLabel}>
                        {startCase(eventLabel)}
                      </option>
                    ))}
                  </Select>
                </div>
                {eventType === 'updateBuyerSettings' && (
                  <div className='w5 dib ml2'>
                    <label htmlFor='selectchanges' className='visuallyhidden'>
                      <FormattedMessage
                        id='ChangeHistory.SortByChanges'
                        defaultMessage={'Sort by Changes:'}
                      />
                    </label>
                    <Select
                      fullWidth
                      onChange={handleFilterBySubType}
                      name='selectchanges'
                    >
                      <option value=''>All Changes</option>
                      {changeEventSubtypes.map(eventKey => (
                        <option key={eventKey} value={eventKey}>
                          {startCase(eventKey)}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            }
          >
            <Table
              minWidth={920}
              rowGetter={getRow}
              rowCount={visibleChangeEvents.size}
              rowClassName='pointer dim'
            >
              <Column
                label='Date Modified'
                dataKey='date'
                width={150}
                cellRenderer={({ cellData }: TableCellProps) =>
                  cellData.slice(0, 10)
                }
              />
              <Column
                label='Type'
                width={190}
                dataKey={'index'}
                disableSort
                cellRenderer={({ rowData }: TableCellProps) =>
                  startCase(rowData.get('label'))
                }
              />
              <Column
                label='Modifier Email'
                width={250}
                dataKey={'index'}
                disableSort
                cellRenderer={({ rowData }: TableCellProps) =>
                  rowData.getIn(['details', 'user'])
                }
              />
              <Column
                label='Changes/Actions'
                width={200}
                dataKey={'index'}
                disableSort
                cellRenderer={({ rowData }: TableCellProps) => {
                  if (
                    rowData &&
                    rowData.get('label') === 'updateBuyerSettings'
                  ) {
                    return renderUpdateSettings(rowData)
                  }
                  if (rowData && rowData.get('label') === 'conciergeAction') {
                    return renderConciergeActions(rowData)
                  }
                  if (
                    rowData &&
                    rowData.get('label') === 'loader' &&
                    rowData.get('details')
                  ) {
                    return renderLoaderActions(rowData.get('details'))
                  } else {
                    return null
                  }
                }}
              />
            </Table>
          </PageSection>
        )}
      </Page>
      <Dialog open={openCompareDialog} onClose={handleCloseDialog} fullWidth>
        <DialogTitle onClose={handleCloseDialog}>
          Here's what's changed for: {subType}
        </DialogTitle>
        <DialogContent>
          <div className='pt0 ph1 pb4 ' style={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Label>New Values</Label>
                <Text>{newValuesState}</Text>
              </Grid>
              <Grid item xs={6}>
                <Label>Previous Values</Label>
                <Text>{prevValuesState}</Text>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      {loaderDetails && (
        <Dialog open={openViewLoader} onClose={handleCloseDialog} fullWidth>
          {loaderDetails.get('loadType') ? (
            <DialogTitle onClose={handleCloseDialog}>
              Load type: {loaderDetails.get('loadType')}
            </DialogTitle>
          ) : (
            <DialogTitle onClose={handleCloseDialog}>File Name</DialogTitle>
          )}
          {loaderDetails.get('status') ? (
            <DialogContent className='mb3'>
              {loaderDetails.get('status') && (
                <>
                  <Label>Load Status</Label>
                  <Text>{startCase(loaderDetails.get('status'))}</Text>
                </>
              )}

              <Label>Percent Complete</Label>
              <Text>
                {loaderDetails.get('progress')} <span>&#37;</span>
              </Text>

              {errorList && !!errorList.size && (
                <Tooltip
                  title={errorList
                    .reduce<Array<string>>((result, currentError) => {
                      if (result.indexOf(startCase(currentError)) < 0) {
                        result.push(startCase(currentError))
                      }
                      return result
                    }, [])
                    .join(', ')}
                >
                  <ErrorIcon color='error' />
                </Tooltip>
              )}
            </DialogContent>
          ) : (
            <DialogContent className='mb3'>
              <Text>{loaderDetails.get('filename')}</Text>
            </DialogContent>
          )}
        </Dialog>
      )}
    </>
  )
}
export default ChangeHistory
