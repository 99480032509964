import { select, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import profileSelectors from '../../selectors/profileSelectors'
import relationships from 'shared/utils/api/relationships'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import analytics from 'shared/utils/analytics'

function* updateRelationshipSaga(action) {
  const relationshipChanges = action.payload.toJS()
  const relationship = yield select(profileSelectors.getRelationship)

  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const orgUnitName = yield select(orgsSelectors.getCurrentUserOrgUnitName)

  let newRelationship
  const currentSupplierId = yield select(profileSelectors.getDataField, 'id')
  const currentSupplierName = yield select(
    profileSelectors.getDataField,
    'name'
  )

  if (!relationship) {
    newRelationship = yield call(
      relationships.create,
      currentSupplierId,
      relationshipChanges
    )
  } else {
    yield call(
      relationships.update,
      relationship.get('id'),
      relationshipChanges
    )
  }

  if (
    !!relationshipChanges.attributes?.existing?.status &&
    !relationship?.getIn(['attributes', 'existing', 'status'])
  ) {
    analytics.track(
      'Supplier Status Changes from "N/A" to "Spend Relationship (Existing)"',
      {
        eventSource: 'Supplier Profile',
        action: 'Clicked',
        orgUnitId,
        orgUnitName,
        supplierOrgUnitId: currentSupplierId,
        supplierName: currentSupplierName
      }
    )
  }

  return yield {
    newRelationship,
    relationshipId: relationship && relationship.get('id'),
    relationshipChanges
  }
}

export default createApiRequestSaga(updateRelationshipSaga)
