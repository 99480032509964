import { RecordOf, List, fromJS, Map } from 'immutable'
import { createSelector } from 'reselect'
import moment from 'moment'
import RootState from 'shared/models/RootState'
import {
  AttachmentType,
  SurveyResponse,
  SurveyResponseDetail
} from 'supplier/Survey/reducers/surveyReducer/surveyReducer'

export const getEsgSurvey = (state: RootState): RecordOf<SurveyResponse> => {
  return state
    .getIn(['buyer', 'supplierProfile', 'survey', 'surveyResponse'])
    ?.filter(
      (surveyResponse: RecordOf<SurveyResponse>) =>
        surveyResponse.get('type') === 'esg' &&
        !!surveyResponse.get('attestedDate')
    )
    .sort(
      (
        survey1: RecordOf<SurveyResponse>,
        survey2: RecordOf<SurveyResponse>
      ) => {
        const date1 = moment(survey1.get('attestedDate'))
        const date2 = moment(survey2.get('attestedDate'))

        return date1.isBefore(date2) ? -1 : 1
      }
    )
    .last()
}

export const getSurveyResponseDetailByKey = (
  state: RootState,
  key: string
): RecordOf<SurveyResponseDetail> => {
  return state.getIn([
    'buyer',
    'supplierProfile',
    'survey',
    'surveyResponseDetailByKey',
    key
  ])
}

const getNumberResponseByPage = (
  page: List<RecordOf<SurveyResponseDetail>> | undefined
) => {
  let responseCount: number = 0
  let range: Array<string> = []
  const parentQuestions = page?.reduce(
    (result: List<string>, currentResponse) => {
      if (
        currentResponse.get('parentQuestionId') &&
        !result.includes(currentResponse.get('parentQuestionId'))
      ) {
        result = result.push(currentResponse.get('parentQuestionId'))
      }
      return result
    },
    List([])
  )
  if (!parentQuestions?.size) {
    responseCount = page?.size || 0
  } else if (parentQuestions?.size) {
    let temp =
      page?.filter(response => {
        return (
          !response.get('parentQuestionId') &&
          response.get('answer') &&
          !parentQuestions.includes(response.get('questionId'))
        )
      }) || List([])
    responseCount = temp.size + parentQuestions.size
  }
  if (responseCount) {
    for (let i = 1; i <= responseCount; i++) {
      range.push(`Q${String(i)}`)
    }
  }
  return range
}

export const getNumberOfResponsesByPage = createSelector(
  (state: RootState) => getResponsesByPage(state),
  (responses: Map<string, List<RecordOf<SurveyResponseDetail>>>) => {
    const healthResponseCount = getNumberResponseByPage(
      responses.get('healthSafetyResponses')
    )
    const environmentResponseCount = getNumberResponseByPage(
      responses.get('environmentResponses')
    )
    const diversityResponseCount = getNumberResponseByPage(
      responses.get('diversityInclusionResponses')
    )
    const governanceResponseCount = getNumberResponseByPage(
      responses.get('governanceResponses')
    )
    const privacyResponseCount = getNumberResponseByPage(
      responses.get('privacyResponses')
    )
    return fromJS({
      healthAndSafety: healthResponseCount,
      environment: environmentResponseCount,
      diversityAndInclusion: diversityResponseCount,
      governance: governanceResponseCount,
      privacy: privacyResponseCount
    })
  }
)
export const getResponsesByPage = createSelector(
  (state: RootState) =>
    state
      .getIn([
        'buyer',
        'supplierProfile',
        'survey',
        'surveyResponseDetailByKey'
      ])
      ?.toList(),
  (responses: List<RecordOf<SurveyResponseDetail>>) => {
    const healthSafetyResponses = responses?.filter(
      response => response.get('pageId') === 'healthSafety'
    )
    const environmentResponses = responses?.filter(
      response => response.get('pageId') === 'environment'
    )
    const diversityInclusionResponses = responses?.filter(
      response => response.get('pageId') === 'diversityInclusion'
    )
    const governanceResponses = responses?.filter(
      response => response.get('pageId') === 'governance'
    )
    const privacyResponses = responses?.filter(
      response => response.get('pageId') === 'privacy'
    )
    return fromJS({
      healthSafetyResponses,
      environmentResponses,
      diversityInclusionResponses,
      governanceResponses,
      privacyResponses
    })
  }
)
export const getEsgQuickFacts = createSelector(
  (state: RootState) => getResponsesByPage(state),
  (responses: Map<string, List<RecordOf<SurveyResponseDetail>>>) => {
    const positiveResponseKeys = ['yes', 'true']
    const uniqueDocs = {
      total: {},
      healthSafetyDocs: {},
      environmentDocs: {},
      diversityInclusionDocs: {},
      governanceDocs: {},
      privacyDocs: {}
    }

    let healthSafetyCerts = 0,
      environmentCerts = 0,
      diversityInclusionCerts = 0,
      governanceCerts = 0,
      privacyCerts = 0

    let healthSafetyPositives = 0,
      environmentPositives = 0,
      diversityInclusionPositives = 0,
      governancePositives = 0,
      privacyPositives = 0

    responses.get('healthSafetyResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        healthSafetyCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.healthSafetyDocs[attachment.get('description')] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        healthSafetyPositives += 1
      }
    })
    responses.get('environmentResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        environmentCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.environmentDocs[attachment.get('description')] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        environmentPositives += 1
      }
    })
    responses.get('diversityInclusionResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        diversityInclusionCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.diversityInclusionDocs[
              attachment.get('description')
            ] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        diversityInclusionPositives += 1
      }
    })
    responses.get('governanceResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        governanceCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.governanceDocs[attachment.get('description')] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        governancePositives += 1
      }
    })
    responses.get('privacyResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        privacyCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.privacyDocs[attachment.get('description')] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        privacyPositives += 1
      }
    })

    return {
      certificatesAdded: {
        total:
          healthSafetyCerts +
          environmentCerts +
          diversityInclusionCerts +
          governanceCerts +
          privacyCerts,
        healthSafety: healthSafetyCerts,
        environment: environmentCerts,
        diversityInclusion: diversityInclusionCerts,
        governance: governanceCerts,
        privacy: privacyCerts
      },
      documentsAdded: {
        total: Object.keys(uniqueDocs.total).length,
        healthSafety: Object.keys(uniqueDocs.healthSafetyDocs).length,
        environment: Object.keys(uniqueDocs.environmentDocs).length,
        diversityInclusion: Object.keys(uniqueDocs.diversityInclusionDocs)
          .length,
        governance: Object.keys(uniqueDocs.governanceDocs).length,
        privacy: Object.keys(uniqueDocs.privacyDocs).length
      },
      positiveResponses: {
        total:
          healthSafetyPositives +
          environmentPositives +
          diversityInclusionPositives +
          governancePositives +
          privacyPositives,
        healthSafety: healthSafetyPositives,
        environment: environmentPositives,
        diversityInclusion: diversityInclusionPositives,
        governance: governancePositives,
        privacy: privacyPositives
      }
    }
  }
)

export const isVerifyAttachmentDialogOpen = (state: RootState) => {
  return state.getIn([
    'buyer',
    'supplierProfile',
    'survey',
    'openVerifyAttachmentDialog'
  ])
}

export const getActiveEsgRequestDetails = createSelector(
  (state: RootState) =>
    state.getIn([
      'buyer',
      'supplierProfile',
      'survey',
      'esgRequestDetailByKey'
    ]),
  ESGSurveyRequests => {
    return (ESGSurveyRequests || fromJS({}))
      .sort((esgRequest1, esgRequest2) => {
        const date1 = moment(esgRequest1.getIn(['created', 'date']))
        const date2 = moment(esgRequest2.getIn(['created', 'date']))

        return date1.isBefore(date2) ? -1 : 1
      })
      .last()
  }
)
