import React, { FunctionComponent, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PageSection from 'shared/components/PageSection'
import { updateVet } from '../../actions'
import Label from 'shared/components/Label'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import KeywordsAddTagForm from '../KeywordsAddTagForm'
import KeywordsContainer from '../KeywordsContainer'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'

type Props = {
  vetId: string
  isTealbot: boolean
  readonly: boolean
  links: List<
    RecordOf<{
      linkTo: string
      linkType: string
      linkValue: string
    }>
  >
  conciergeFields: RecordOf<{
    minNumSuppliers: number
    vetType: string
  }>
  updateVet: (payload) => void
}

export const VetInternal: FunctionComponent<Props> = props => {
  const [linkTo, setLinkTo] = useState('Other')
  const [linkType, setLinkType] = useState('String')
  const [linkValue, setLinkValue] = useState('')
  const [minNumSuppliers, setMinNumSuppliers] = useState(0)
  const [vetType, setVetType] = useState('')

  const { links, isTealbot, readonly, vetId, conciergeFields } = props

  useEffect(() => {
    if (links && links.size) {
      setLinkTo(links.getIn([0, 'linkTo']))
      setLinkType(links.getIn([0, 'linkType']))
      setLinkValue(links.getIn([0, 'linkValue']))
    }
    if (conciergeFields) {
      setMinNumSuppliers(conciergeFields.get('minNumSuppliers'))
      setVetType(conciergeFields.get('vetType'))
    }
  }, [links, conciergeFields])

  const handleSave = changes => {
    const { vetId, updateVet } = props
    updateVet({
      vetId,
      links: [{ linkTo, linkType, linkValue }]
    })
  }

  const handleSaveConciergeFields = () => {
    const { vetId, updateVet } = props
    updateVet({
      vetId,
      conciergeFields: { minNumSuppliers, vetType }
    })
  }

  const handleChange = e => {
    if (e.target.name === 'linkValue') {
      setLinkValue(e.target.value)
    } else if (e.target.name === 'linkTo') {
      setLinkTo(e.target.value)
    }
  }

  const referenceId:
    | RecordOf<{
        linkTo: string
        linkType: string
        linkValue: string
      }>
    | undefined = links && links.first()

  if (readonly && !referenceId) {
    return null
  }

  return (
    <PageSection
      title={
        <FormattedMessage
          id='VetInternalContainer.referenceNotShared'
          defaultMessage='Reference'
        />
      }
    >
      <KeywordsContainer vetId={vetId} />
      <KeywordsAddTagForm vetId={vetId} />
      <div className='dt w-100'>
        <div className='dtc'>
          <Label>
            <FormattedMessage
              id='VetInternalContainer.ReferenceID'
              defaultMessage='Reference ID'
            />
          </Label>
          <AutoSaveInput
            name='linkValue'
            component={Input}
            value={referenceId && referenceId.get('linkValue')}
            onChange={handleChange}
            onSave={handleSave}
          />
        </div>
        {isTealbot && (
          <div className='dtc pl3'>
            <Label htmlFor='linkTo'>
              <FormattedMessage
                id='VetInternalContainer.LinkTo'
                defaultMessage='Link To'
              />
            </Label>
            <AutoSaveInput
              name='linkTo'
              component={Select}
              value={referenceId && referenceId.get('linkTo')}
              onChange={handleChange}
              onSave={handleSave}
              fullWidth
            >
              <FormattedMessage
                id='VetInternalContainer.Other'
                defaultMessage='Other'
              >
                {message => <option value='Other'>{message}</option>}
              </FormattedMessage>

              <FormattedMessage
                id='VetInternalContainer.Challenge'
                defaultMessage='Challenge'
              >
                {message => <option value='Challenge'>{message}</option>}
              </FormattedMessage>
            </AutoSaveInput>
          </div>
        )}
      </div>
      {isTealbot && (
        <>
          <Label>Minimum number of suppliers</Label>
          <AutoSaveInput
            name='minNumSuppliers'
            component={Input}
            type='number'
            value={conciergeFields && conciergeFields.get('minNumSuppliers')}
            onChange={e => setMinNumSuppliers(parseInt(e.currentTarget.value))}
            onSave={handleSaveConciergeFields}
          />
          <Label htmlFor='vetType'>Type</Label>
          <AutoSaveInput
            name='vetType'
            component={Select}
            value={conciergeFields && conciergeFields.get('vetType')}
            onChange={e => setVetType(e.currentTarget.value)}
            onSave={handleSaveConciergeFields}
          >
            <option value='' disabled>
              Select a type
            </option>
            <option value='Full RFI  – Mini RFI containing responses from the suppliers'>
              Full RFI – Mini RFI containing responses from the suppliers
            </option>
            <option value='Supplier Searches – Gather list of suppliers and their contact information that fits the scope of search'>
              Supplier Searches – Gather list of suppliers and their contact
              information that fits the scope of search
            </option>
            <option value='Contact request – Suppliers provide a list of suppliers and ask to gather contact information'>
              Contact request – Suppliers provide a list of suppliers and ask to
              gather contact information
            </option>
            <option value='Assistance in navigating platform/setting up RFI'>
              Assistance in navigating platform/setting up RFI
            </option>
            <option value='Other'>Other</option>
          </AutoSaveInput>
        </>
      )}
    </PageSection>
  )
}

export default connect(
  state => {
    const user = sessionSelectors.getUser(state)
    const isTealbot = user && user.get('roles').includes('tealbot')
    const links = currentVetSelectors.getVetField(state, 'links')
    const vetId = currentVetSelectors.getVetField(state, 'id')
    const conciergeFields = currentVetSelectors.getVetField(
      state,
      'conciergeFields'
    )

    return {
      vetId,
      isTealbot,
      links,
      conciergeFields,
      readonly: currentVetSelectors.isReadonly(state)
    }
  },
  {
    updateVet
  }
)(VetInternal)
