import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import Text from 'shared/components/Text'
import relationshipsSelectors from '../../../shared/selectors/relationshipsSelectors'
import { List, Map, RecordOf } from 'immutable'
import ActionInfoOutline from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip'
import SpendGauge from '../../../shared/components/SpendGauge'
import SpendTrend from '../../../shared/components/SpendTrend'
import SpendDetailsTable from '../../components/SpendDetailsTable'
import SpendOverviewGraph from '../../components/SpendOverviewGraph'
import Divider from 'shared/components/Divider'
import Button from 'shared/components/Button'
import exportData from 'shared/utils/exportData'
import dateFormat from 'shared/utils/dateFormat'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import RootState from 'shared/models/RootState'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import OrgUnit from 'shared/models/OrgUnit'
import Scrollable from 'shared/components/Scrollable'

const styles = {
  attributeWrapper: 'mb2 f6',
  label: 'fw6 dark-gray pr3 w-100 w-25-ns dib v-top',
  value: 'w-100 w-70-ns dib'
}

type Props = {
  standardAttributes: Map<
    string,
    RecordOf<{
      internalSupplierId: List<string> | string
      internalCategory: List<string> | string
      spend: Map<
        string,
        RecordOf<{
          details: List<any>
          overview: Map<
            string,
            RecordOf<{
              annually: List<any>
              quarterly: List<any>
            }>
          >
        }>
      >
    }>
  >
  orgUnit: RecordOf<OrgUnit>
}

type State = {
  spendTab: string
}

export class StandardAttributes extends Component<Props, State> {
  state = {
    spendTab: 'annually'
  }

  setSpendTab = spendTab => {
    this.setState({ spendTab })
  }

  transformExportData = data => {
    const formattedData = data.map(
      ({ category, internalSupplierId, address = '', amount, spendDate }) => {
        const date = new Date(spendDate)
        return {
          internalSupplierId,
          category,
          address: address
            .split(',')
            .map(a => a.trim())
            .filter(a => !!a)
            .join(', '),
          amount: amount && amount.toFixed(2),
          spendDate: dateFormat(
            new Date(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate()
            )
          )
        }
      }
    )

    return formattedData
  }

  render() {
    const { standardAttributes, orgUnit } = this.props
    const { spendTab } = this.state
    const supplierName = orgUnit && orgUnit.get('name')

    const internalSupplierId = standardAttributes.get('internalSupplierId')
    const internalCategory = standardAttributes.get('internalCategory')
    const details =
      standardAttributes.size > 0 &&
      standardAttributes.getIn(['spend', 'details'])
    const overview =
      standardAttributes.size > 0 &&
      standardAttributes.getIn(['spend', 'overview'])
    return standardAttributes.size > 0 ? (
      <div className='mt3-5'>
        {standardAttributes.get('internalSupplierId') && (
          <div className='flex'>
            <span className={classNames('lh-copy f7', styles.label)}>
              <FormattedMessage
                id='StandardAttributes.InternalSupplier'
                defaultMessage='Internal Supplier Id'
              />
            </span>
            <span
              className={classNames('lh-copy f7 fw4 mid-gray', styles.value)}
            >
              <Scrollable maxHeight={100}>
                {List.isList(internalSupplierId)
                  ? internalSupplierId.sort().join(', ')
                  : internalSupplierId}
              </Scrollable>
            </span>
          </div>
        )}
        {standardAttributes.get('internalCategory') && (
          <div className={styles.attributeWrapper}>
            <Text>
              <span className={styles.label}>
                <FormattedMessage
                  id='StandardAttributes.InternalCategory'
                  defaultMessage='Internal Category'
                />
              </span>
              <span className={styles.value}>
                {List.isList(internalCategory)
                  ? internalCategory.join(', ')
                  : internalCategory}
              </span>
            </Text>
          </div>
        )}
        {standardAttributes.get('spend') && (
          <Fragment>
            <div className={styles.attributeWrapper}>
              <span className={styles.label}>
                <FormattedMessage
                  id='StandardAttributes.Year'
                  defaultMessage='Year'
                />
              </span>
              <span className={styles.value}>
                {standardAttributes.getIn(['spend', 'year'])}
              </span>
            </div>
            <div className={styles.attributeWrapper}>
              <span className={styles.label}>
                <FormattedMessage
                  id='StandardAttributes.Amount'
                  defaultMessage='Amount'
                />
              </span>
              <span className={styles.value}>
                {(
                  Math.round(
                    standardAttributes.getIn(['spend', 'recentAmount']) * 100
                  ) / 100
                ).toLocaleString()}{' '}
              </span>
            </div>
            {standardAttributes.getIn(['spend', 'recentPercentile']) !==
              undefined && (
              <div className={styles.attributeWrapper}>
                <span className={styles.label}>
                  <span className='dib v-mid'>
                    <FormattedMessage
                      id='StandardAttributes.Percentile'
                      defaultMessage='Percentile'
                    />
                  </span>
                  <Tooltip
                    placement='right-start'
                    title={
                      <div className='w5'>
                        <FormattedMessage
                          id='StandardAttributes.MeasureOfRelativeSpend'
                          defaultMessage='A measure of relative spend with 0 being assigned to suppliers with the lowest spend and 100 being assigned to suppliers with the highest spend.'
                        />
                      </div>
                    }
                  >
                    <ActionInfoOutline
                      className='v-mid dib'
                      style={{ height: '19px' }}
                      aria-hidden={false}
                    />
                  </Tooltip>
                </span>
                <span className={styles.value}>
                  {standardAttributes.getIn(['spend', 'recentPercentile'])}
                  %&nbsp;&nbsp;
                  <SpendGauge
                    recentPercentile={standardAttributes.getIn([
                      'spend',
                      'recentPercentile'
                    ])}
                  />
                </span>
              </div>
            )}
            {standardAttributes.getIn(['spend', 'recentTrend']) !==
              undefined && (
              <div className={styles.attributeWrapper}>
                <span className={styles.label}>
                  <FormattedMessage
                    id='StandardAttributes.Trend'
                    defaultMessage='Trend'
                  />
                </span>
                <span className={styles.value}>
                  {standardAttributes.getIn(['spend', 'recentTrend']) > 0
                    ? '+'
                    : ''}
                  {standardAttributes.getIn(['spend', 'recentTrend'])}
                  %&nbsp;&nbsp;
                  <SpendTrend
                    recentTrend={standardAttributes.getIn([
                      'spend',
                      'recentTrend'
                    ])}
                  />
                </span>
              </div>
            )}
            {details && details.size > 0 && (
              <Fragment>
                <Divider className='mt3' />
                <header className='flex items-center mb2 pt1 bb b--light-gray'>
                  <div className='flex flex-auto'>
                    <span
                      onClick={() => this.setSpendTab('annually')}
                      className={classNames(
                        'pv3 mid-gray f7 f6-ns mr3 mr4-ns',
                        spendTab === 'annually'
                          ? 'bw1 bb fw6'
                          : 'link dim pointer'
                      )}
                    >
                      <FormattedMessage
                        id='StandardAttributes.SpendOverview'
                        defaultMessage='Spend Overview'
                      />
                    </span>

                    <span
                      onClick={() => this.setSpendTab('quarterly')}
                      className={classNames(
                        'pv3 mid-gray f7 f6-ns mr3 mr4-ns',
                        spendTab === 'quarterly'
                          ? 'bw1 bb fw6'
                          : 'link dim pointer'
                      )}
                    >
                      <FormattedMessage
                        id='StandardAttributes.SpendDetails'
                        defaultMessage='Spend Details'
                      />
                    </span>
                  </div>
                  <Button
                    autoSize
                    label={
                      <FormattedMessage
                        id='StandardAttributes.ExportSpendDetails'
                        defaultMessage='Export Spend Details'
                      />
                    }
                    className='fr v-mid'
                    onClick={() =>
                      exportData.exportCSV(
                        this.transformExportData(details.toJS()),
                        `${supplierName}: Spend details`
                      )
                    }
                  />
                </header>
                {spendTab === 'quarterly' ? (
                  <SpendDetailsTable data={details} />
                ) : (
                  <SpendOverviewGraph data={overview} />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    ) : null
  }
}

type RouteProps = RouteComponentProps<{ supplierId: string }>
export default withRouter(
  connect((state: RootState, props: RouteProps) => ({
    standardAttributes: relationshipsSelectors.getStandardAttributes(
      state,
      props.match.params.supplierId
    ),
    orgUnit: orgsSelectors.getById(state, props.match.params.supplierId)
  }))(StandardAttributes)
)
