import React, { Fragment, ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import SpendOverviewData from './SpendOverviewData'
import { connect, useSelector } from 'react-redux'
import {
  setOnlyDiverse,
  setOnlyPreferred,
  setUseSpendGroup
} from '../../store/actions'
import insightsSelectors from '../../store/insightsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import Select from 'shared/components/Select'
import Label from 'shared/components/Label'
import analytics from 'shared/utils/analytics'

type Props = {
  diverse: boolean
  preferred: boolean
  useSpendGroup: boolean
  setOnlyDiverse: (diverse: boolean) => void
  setOnlyPreferred: (preferred: boolean) => void
  setUseSpendGroup: (useSpendGroup: boolean) => void
  isDiverseSpend?: boolean
  isSpendLocationActivated?: boolean
}

export const YearSpendOverview = (props: Props) => {
  const {
    useSpendGroup,
    setUseSpendGroup,
    isDiverseSpend,
    isSpendLocationActivated
  } = props

  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const sortType = useSpendGroup ? 'spendgroup' : 'category'

  const changeSort = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget

    analytics.track('Filter Category Sort Clicked', {
      eventSource: 'Diversity Report',
      action: 'Clicked',
      category: value === 'spendgroup' ? 'Business Units' : 'Spend Categories',
      orgUnitId,
      orgUnitName
    })

    setUseSpendGroup(value === 'spendgroup')
  }

  return (
    <div className='mt3'>
      <Fragment>
        <div className='w-100 w-50-ns dib v-top pr2-ns'>
          <SpendOverviewData
            type={sortType}
            customTitle={
              <div>
                <Label htmlFor={`sort${sortType}`} className='f7 mr1'>
                  <FormattedMessage
                    id='YearSpendOverview.SortedBy'
                    defaultMessage='Sorted by: '
                  />
                </Label>
                <Select
                  onChange={changeSort}
                  value={sortType}
                  name={`sort${sortType}`}
                >
                  <FormattedMessage
                    id='YearSpendOverview.SpendCategories'
                    defaultMessage='Spend Categories'
                  >
                    {message => <option value='category'>{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage
                    id='YearSpendOverview.BusinessUnits'
                    defaultMessage='Business Units'
                  >
                    {message => <option value='spendgroup'>{message}</option>}
                  </FormattedMessage>
                </Select>
              </div>
            }
          />
        </div>
        <div className='w-100 w-50-ns dib v-top pl2-ns mt3 mt0-ns'>
          <SpendOverviewData
            type='country'
            countryTitle={
              <Label className='f7 pv2 bt bb b--transparent'>
                <FormattedMessage
                  id='SpendOverviewPie.SpendCountries'
                  defaultMessage='Spend Countries'
                />
              </Label>
            }
            disabled={isDiverseSpend && isSpendLocationActivated}
          />
        </div>
      </Fragment>
    </div>
  )
}

export default connect(
  state => ({
    preferred: !!insightsSelectors.getSpendField(state, 'onlyPreferred'),
    diverse: !!insightsSelectors.getSpendField(state, 'onlyDiverse'),
    useSpendGroup: !!insightsSelectors.getSpendField(state, 'useSpendGroup')
  }),
  { setOnlyDiverse, setOnlyPreferred, setUseSpendGroup }
)(YearSpendOverview)
