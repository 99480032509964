import React, { useEffect } from 'react'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import OrgLogo from 'shared/components/OrgLogo'
import PageSideSection from 'shared/components/PageSideSection'
import ListItem from 'shared/components/List/ListItem'
import NewPostFormContainer from './containers/NewPostFormContainer'
import PostsListContainer from './containers/PostsListContainer'
import { connect } from 'react-redux'
import { loadCommunities } from 'supplier/Communities/actions'
import { getCommunities } from 'buyer/Communities/actions'
import {
  clearUnread,
  getRecentSuppliers,
  getRecentSuppliersTags,
  getSupplierCommentsAndTagsForExport
} from './actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import postsSelectors from './selectors/postsSelectors'
import buyerCommunitiesSelectors from 'buyer/Communities/selectors/communitiesSelectors'
import supplierCommunitiesSelectors from 'supplier/Communities/selectors/communitiesSelectors'
import Scrollable from 'shared/components/Scrollable'
import paths from 'buyer/routes/paths'
import Link from 'shared/components/Link'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import { FormattedMessage } from 'react-intl'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { useLocation } from 'react-router'
import { List, RecordOf, Map } from 'immutable'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import RootState from 'shared/models/RootState'
import SpotlightContainer from './containers/SpotlightContainer'
import RecentCommentsOnSupplier from './components/RecentCommentsOnSupplier'
import RecentTagsOnSupplier from './components/RecentTagsOnSupplier'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import storageManager from 'shared/utils/storageManager'

export type RecentSuppliers = {
  id: string
  name: string
  logoUrl: string
  domains: List<string>
  description: string
  recentMessage?: RecordOf<{
    body: string
    created: RecordOf<{ date: string; user: string }>
  }>
  activeUser?: RecordOf<{
    profilePictureUrl: string
    userProfileUrl: string
    firstName: string
    lastName: string
  }>
}

export type RecentSuppliersTag = {
  supplierInfo: RecordOf<{
    id: string
    domains: List<string>
    name: string
    logo: RecordOf<{ fileName: string; bucketName: string }>
  }>
  offerings: Map<string, RecordOf<{ user: string; date: string }>>
  differentiators: Map<string, RecordOf<{ user: string; date: string }>>
  privateOfferings: Map<string, RecordOf<{ user: string; date: string }>>
}
type Props = {
  language: string
  hasNoSupplierCommunities: boolean
  canPost: boolean
  isClientApp: boolean
  loadCommunitiesOnLoad: boolean
  loadClientCommunitiesOnLoad: boolean
  recentSuppliers: List<RecordOf<RecentSuppliers>>
  recentConnectedSuppliers: List<RecordOf<RecentSuppliers>>
  loadCommunities: () => void
  loadClientCommunities: () => void
  clearUnread: () => void
  getRecentSuppliers: () => void
  spotlightTag: string
  getRecentSuppliersTags: () => void
  recentSupplierTag: Map<string, RecordOf<RecentSuppliersTag>>
  currentUserId: string
  getSupplierCommentsAndTagsForExport: () => void
}

export const App = (props: Props) => {
  const location = useLocation()
  const locationHash = location.hash
  const {
    clearUnread,
    loadCommunitiesOnLoad,
    loadClientCommunitiesOnLoad,
    loadClientCommunities,
    isClientApp,
    getRecentSuppliers,
    language,
    hasNoSupplierCommunities,
    recentSuppliers,
    recentConnectedSuppliers,
    canPost,
    spotlightTag,
    recentSupplierTag,
    currentUserId,
    getSupplierCommentsAndTagsForExport,
    loadCommunities
  } = props

  useEffect(() => {
    clearUnread()

    if (loadCommunitiesOnLoad) {
      loadCommunities()
    }

    if (loadClientCommunitiesOnLoad) {
      loadClientCommunities()
    }

    if (isClientApp) {
      getRecentSuppliers()
      // getting recent tags was an intensive query, need to come up with a didn't approach until then removing the api call
      // getRecentSuppliersTags()
    }
  }, [
    loadCommunities,
    loadCommunitiesOnLoad,
    clearUnread,
    isClientApp,
    getRecentSuppliers,
    loadClientCommunitiesOnLoad,
    loadClientCommunities
  ])

  useEffect(() => {
    if (locationHash) {
      storageManager.setItem('spotlightTag', locationHash)
    }
  }, [locationHash])

  return (
    <Page title='News'>
      <ScrollToPosition />
      <Main>
        {!isClientApp && hasNoSupplierCommunities && (
          <h5 className='f4 lh-copy fw4 mv3 mb4 tc'>
            <FormattedMessage
              id='App.JoinACommunity'
              defaultMessage='Join a community so buyers know you are interested in their business'
            />
          </h5>
        )}

        <SpotlightContainer
          tag={locationHash || spotlightTag}
          isClientApp={isClientApp}
        />

        {canPost && <NewPostFormContainer />}

        <PostsListContainer />
      </Main>
      {isClientApp &&
        (recentSuppliers.size > 0 ||
          recentConnectedSuppliers.size > 0 ||
          recentSupplierTag.size > 0) && (
          <Aside>
            {recentConnectedSuppliers.size > 0 && (
              <PageSideSection
                className='mt0'
                title={
                  <FormattedMessage
                    id='App.RecentlyUpdatedSupplier'
                    defaultMessage='Recently updated suppliers'
                  />
                }
              >
                <Scrollable role='list'>
                  {recentConnectedSuppliers.take(10).map(supplier => {
                    return (
                      <ListItem
                        key={supplier.get('name')}
                        mini
                        avatarAlignTop
                        leftAvatar={
                          <Link
                            to={paths.supplierProfile}
                            params={{ supplierId: supplier.get('id') }}
                            ariaLabel={supplier.get('name')}
                          >
                            <OrgLogo
                              url={
                                supplier.get('logoUrl') ||
                                `https://logo.clearbit.com/${supplier
                                  .get('domains')
                                  .first()}`
                              }
                              name={supplier.get('name')}
                            />
                          </Link>
                        }
                        primaryText={
                          <Link
                            to={paths.supplierProfile}
                            params={{ supplierId: supplier.get('id') }}
                          >
                            {supplier.get('name')}
                          </Link>
                        }
                        secondaryText={
                          !supplier.get('description') ||
                          typeof supplier.get('description') === 'string'
                            ? supplier.get('description')
                            : supplier.getIn(['description', language || 'en'])
                        }
                      />
                    )
                  })}
                </Scrollable>
              </PageSideSection>
            )}
            {(recentSuppliers.size > 0 || recentSupplierTag.size > 0) && (
              <PageSideSection
                title={
                  <FormattedMessage
                    id='App.RecentSupplierActivity'
                    defaultMessage='Recent Supplier Activity'
                  />
                }
                actions={
                  <Button
                    autoSize
                    onClick={() => getSupplierCommentsAndTagsForExport()}
                  >
                    <FormattedMessage id='App.Export' defaultMessage='Export' />
                  </Button>
                }
              >
                {recentSuppliers.size > 0 && (
                  <>
                    <Text className='mt3'>
                      <FormattedMessage
                        id='App.Comments'
                        defaultMessage='Comments'
                      />
                    </Text>
                    <RecentCommentsOnSupplier
                      recentSuppliers={recentSuppliers}
                    />
                  </>
                )}
                {recentSupplierTag.size > 0 && (
                  <>
                    <Text className='mt3'>
                      <FormattedMessage id='App.Tags' defaultMessage='Tags' />
                    </Text>
                    <RecentTagsOnSupplier
                      recentSupplierTag={recentSupplierTag}
                      currentUserId={currentUserId}
                    />
                  </>
                )}
              </PageSideSection>
            )}
            <ClearbitCredit />
          </Aside>
        )}
    </Page>
  )
}

export default connect(
  (state: RootState) => {
    const trendingTags = postsSelectors.getTrendingTags(state)
    const savedTag = storageManager.getItem('spotlightTag')
    const spotlightTag =
      savedTag ||
      (trendingTags && trendingTags.size > 0 && trendingTags.first())
    return {
      language: state.get('i18n').locale,
      canPost: !orgsSelectors
        .getCurrentUserOrgUnit(state)
        .get('tealboardNoPost'),
      isClientApp: routingSelectors.isClientApp(state),
      hasNoSupplierCommunities:
        supplierCommunitiesSelectors.getAcceptedIds(state) &&
        supplierCommunitiesSelectors.getAcceptedIds(state).size === 0,
      loadCommunitiesOnLoad: sessionSelectors.userHasRole(state, 'supplier'),
      loadClientCommunitiesOnLoad:
        sessionSelectors.userHasRole(state, 'buyer') &&
        buyerCommunitiesSelectors.getCommunitiesList(state).size === 0,
      recentSuppliers: postsSelectors.getMergedRecentSuppliers(state),
      recentConnectedSuppliers: postsSelectors.getRecentConnectedSuppliers(
        state
      ),
      spotlightTag,
      recentSupplierTag: postsSelectors.getRecentSupplierTags(state),
      currentUserId: sessionSelectors.getUserId(state),
      commentsAndTagsForExport: state.getIn([
        'posts',
        'supplierTagsAndCommentsForExport'
      ])
    }
  },
  {
    loadCommunities,
    loadClientCommunities: getCommunities,
    clearUnread,
    getRecentSuppliers,
    getRecentSuppliersTags,
    getSupplierCommentsAndTagsForExport
  }
)(App)
