import React, { Component } from 'react'
import { connect } from 'react-redux'
import searchSelectors from '../../selectors/searchSelectors'
import Pill from 'shared/components/Pill'
import { List, RecordOf } from 'immutable'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import qs from 'qs'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import { withStyles } from '@material-ui/core'
import IconButton from 'shared/components/IconButton'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import ImageArrowUp from 'shared/assets/icons/arrow-up.svg'

type Props = {
  q: string
  suggestion: List<RecordOf<{ value: string; label: string }>>
  filters: any
  classes: any
} & RouteComponentProps

const styles = theme => ({
  hideScrollbar: {
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  leftArrow: {
    transform: 'rotate(-90deg)'
  },
  rightArrow: {
    transform: 'rotate(-90deg)'
  },
  indicatorBtn: {
    padding: '0px',
    top: '9px',
    width: '28px',
    height: '28px',
    backgroundColor: 'lightgray',
    borderRadius: '50%'
  }
})
export class SuggestionContainer extends Component<
  Props,
  { scrollX: number; scrollEnd: boolean }
> {
  private scrl: React.RefObject<HTMLUListElement>
  constructor(props) {
    super(props)
    this.scrl = React.createRef()
    this.state = {
      scrollX: 0,
      scrollEnd: false
    }
  }
  slide = (shift: number) => {
    if (this.scrl.current !== null) {
      this.scrl.current.scrollLeft += shift

      this.setState({ scrollX: this.state.scrollX + shift })

      if (
        Math.floor(
          this.scrl.current.scrollWidth - this.scrl.current.scrollLeft
        ) <= this.scrl.current.offsetWidth
      ) {
        this.setState({ scrollEnd: true })
      } else {
        this.setState({ scrollEnd: false })
      }
    }
  }
  scrollCheck = () => {
    if (this.scrl.current !== null) {
      this.setState({ scrollX: this.scrl.current.scrollLeft })
      if (
        Math.floor(
          this.scrl.current.scrollWidth - this.scrl.current.scrollLeft
        ) <= this.scrl.current.offsetWidth
      ) {
        this.setState({ scrollEnd: true })
      } else {
        this.setState({ scrollEnd: false })
      }
    }
  }
  handleClickAdditionalTerm = (suggestedQuery: string) => () => {
    const { q, ...restOfQueryString } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })
    const additionalTerm =
      suggestedQuery.indexOf(' ') === -1
        ? suggestedQuery
        : `"${suggestedQuery}"`

    const newQ = q ? `${q} ${additionalTerm}` : additionalTerm

    this.props.history.push({
      search: qs.stringify({
        ...restOfQueryString,
        q: newQ,
        filter: this.props.filters && this.props.filters.toJS()
      })
    })
  }

  render() {
    const { q = '', suggestion, classes } = this.props
    const { scrollEnd, scrollX } = this.state
    return (
      <div
        className={`relative ${!scrollEnd && 'pr4'} ${scrollX !== 0 && 'pl4'}`}
      >
        {scrollX !== 0 && (
          <IconButton
            onClick={() => this.slide(-100)}
            className={classes?.indicatorBtn}
            style={{ position: 'absolute', left: '0px' }}
          >
            <img
              src={ImageArrowUp}
              className={`v-mid ${classes?.leftArrow}`}
              alt=''
            />
          </IconButton>
        )}
        <ul
          className={
            'flex flex-wrap flex-column overflow-x-auto pa0 list pl1 ' +
            classes?.hideScrollbar
          }
          style={{ height: 42, scrollBehavior: 'smooth' }}
          aria-label='Search suggestions'
          ref={this.scrl}
        >
          {suggestion.map(suggest => {
            const key = suggest.value
            const label = suggest.label
            return (
              q.toLowerCase().indexOf(suggest.value.toLowerCase()) === -1 && (
                <li key={key}>
                  <Pill
                    key={key}
                    label={label}
                    onToggle={this.handleClickAdditionalTerm(label)}
                    squareCorner
                  />
                </li>
              )
            )
          })}
        </ul>
        {!scrollEnd && (
          <IconButton
            className={`next ${classes?.indicatorBtn}`}
            onClick={() => this.slide(+100)}
            style={{ position: 'absolute', right: '2px' }}
            aria-label='Next Suggestion'
          >
            <img
              src={ImageArrowDown}
              className={`v-mid ${classes?.rightArrow}`}
              alt=''
            />
          </IconButton>
        )}
      </div>
    )
  }
}

export const mapStateToProps = state => {
  return {
    q: searchSelectors.getQ(state),
    filters: filterOptionsSelectors.getFilterOptions(state),
    suggestion: searchSelectors.getSuggestion(state)
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(SuggestionContainer))
)
