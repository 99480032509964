import React, { useState, useMemo } from 'react'
import SupplierAppBar from './shared/components/SupplierAppBar'
import createRoutes from './routes/createRoutes'
import { connect } from 'react-redux'
import companySelectors from './shared/selectors/companySelectors'
import searchSelectors from 'buyer/Search/selectors/searchSelectors'
import SupplierOnboardingPageContainer from './shared/containers/SupplierOnboardingPageContainer'
import ErrorPage from 'shared/components/ErrorPage'
import Hidden from '@material-ui/core/Hidden'
import BottomNavigation from 'shared/components/BottomNavigation'
import BottomNavigationAction from 'shared/components/BottomNavigationAction'
import NavIcon from 'shared/components/NavIcon'
import CompanyIcon from 'shared/assets/icons/nav-company.svg'
import VetIcon from 'shared/assets/icons/nav-vet.svg'
import InsightIcon from 'shared/assets/icons/nav-insight.svg'
import CommunitiesIcon from 'shared/assets/icons/nav-communities.svg'
import MoreIcon from 'shared/assets/icons/nav-more.svg'
import CompanyTealIcon from 'shared/assets/icons/nav-company-teal.svg'
import VetTealIcon from 'shared/assets/icons/nav-vet-teal.svg'
import InsightTealIcon from 'shared/assets/icons/nav-insight-teal.svg'
import CommunitiesTealIcon from 'shared/assets/icons/nav-communities-teal.svg'
import supplier from './routes/paths'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from 'shared/components/ListItemText'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import postsSelectors from 'posting/selectors/postsSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import supplierPaths from 'supplier/routes/paths'
import { FormattedMessage } from 'react-intl'
import RootState from 'shared/models/RootState'
import Loading from 'shared/components/Loading'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import externalPaths from 'shared/routes/externalPaths'

type Props = {
  isLoading: boolean
  loadErrorMessage: boolean
  numberOfUnreadPosts: number
  numberOfUnreadMessages: number
  requiresOnboarding: boolean
  defaultTab: string
  showInsightTab: boolean
  showSuppliersTab: boolean
  queryString: string
  showDiversityTab: boolean
}

export const App = (props: Props) => {
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname

  const [moreDialogOpen, setMoreDialogOpen] = useState<boolean>(false)

  const hideAppBar = location.pathname.includes('esg-survey')

  const toggleMoreDialog = () => {
    setMoreDialogOpen(!moreDialogOpen)
  }

  const handleNavChange = (event, value) => {
    switch (value) {
      case 0:
        history.push(supplier.company)
        break
      case 1:
        history.push(supplier.communities)
        break
      case 2:
        history.push(supplier.vets)
        break
      case 3:
        history.push(supplier.insight)
        break
      case 4:
        toggleMoreDialog()
        break
      default:
        history.push(supplier.company)
        break
    }
  }

  const handleNavMenuClick = to => {
    if (to === 'knowledgeBase') {
      window.open(externalPaths.knowledgeBaseLink)
      setMoreDialogOpen(false)
    } else {
      history.push(supplier[to])
      setMoreDialogOpen(false)
    }
  }

  const {
    requiresOnboarding,
    loadErrorMessage,
    numberOfUnreadMessages,
    numberOfUnreadPosts,
    showInsightTab,
    showSuppliersTab,
    queryString,
    defaultTab,
    isLoading,
    showDiversityTab
  } = props

  const selectedBottomNav = useMemo(() => {
    let value
    if (pathname.indexOf(supplier.company) === 0) {
      value = 0
    } else if (pathname.indexOf(supplier.communities) === 0) {
      value = 1
    } else if (pathname.indexOf(supplier.vets) === 0) {
      value = 2
    } else if (pathname.indexOf(supplier.insight) === 0) {
      value = 3
    }
    return value
  }, [pathname])

  if (isLoading) {
    return <Loading />
  }

  if (loadErrorMessage) {
    return <ErrorPage />
  }

  if (requiresOnboarding) {
    return <SupplierOnboardingPageContainer />
  }

  return (
    <>
      {!hideAppBar && (
        <SupplierAppBar
          pathname={pathname}
          showInsightTab={showInsightTab}
          showSuppliersTab={showSuppliersTab}
          queryString={queryString}
          showDiversityTab={showDiversityTab}
        />
      )}
      {createRoutes(defaultTab, showSuppliersTab)}

      <Hidden mdUp>
        <BottomNavigation
          value={selectedBottomNav}
          onChange={handleNavChange}
          showLabels
        >
          <BottomNavigationAction
            label={
              <FormattedMessage
                id='SupplierApp.Company'
                defaultMessage='Company'
              />
            }
            icon={
              <NavIcon
                src={selectedBottomNav === 0 ? CompanyTealIcon : CompanyIcon}
              />
            }
          />
          <BottomNavigationAction
            label={
              <FormattedMessage
                id='SupplierApp.Communities'
                defaultMessage='Communities'
              />
            }
            icon={
              <NavIcon
                src={
                  selectedBottomNav === 1
                    ? CommunitiesTealIcon
                    : CommunitiesIcon
                }
              />
            }
          />
          <BottomNavigationAction
            label={
              <FormattedMessage id='SupplierApp.RFI' defaultMessage='RFI' />
            }
            icon={
              <NavIcon src={selectedBottomNav === 2 ? VetTealIcon : VetIcon} />
            }
          />
          {showInsightTab && (
            <BottomNavigationAction
              label={
                <FormattedMessage
                  id='SupplierApp.Insight'
                  defaultMessage='Insight'
                />
              }
              icon={
                <NavIcon
                  src={selectedBottomNav === 3 ? InsightTealIcon : InsightIcon}
                />
              }
            />
          )}
          <BottomNavigationAction
            label={
              <FormattedMessage id='SupplierApp.More' defaultMessage='More' />
            }
            icon={<NavIcon src={MoreIcon} />}
          />
        </BottomNavigation>
      </Hidden>

      <Dialog
        open={moreDialogOpen || false}
        onClose={toggleMoreDialog}
        fullWidth
      >
        <DialogTitle>Navigate to...</DialogTitle>
        <DialogContent>
          <List>
            {showDiversityTab && (
              <ListItem
                button
                onClick={() => handleNavMenuClick('diversityInvitation')}
              >
                <ListItemText>
                  <FormattedMessage
                    id='SupplierApp.Diversity'
                    defaultMessage='Diversity'
                  />
                </ListItemText>
              </ListItem>
            )}
            <ListItem button onClick={() => handleNavMenuClick('tealboard')}>
              <ListItemText numberOfNotifications={numberOfUnreadPosts && 1}>
                <FormattedMessage id='SupplierApp.News' defaultMessage='News' />
              </ListItemText>
            </ListItem>
            <ListItem button onClick={() => handleNavMenuClick('messages')}>
              <ListItemText numberOfNotifications={numberOfUnreadMessages}>
                <FormattedMessage
                  id='SupplierApp.Messages'
                  defaultMessage='Messages'
                />
              </ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={() => handleNavMenuClick('knowledgeBase')}
            >
              <ListItemText>
                <FormattedMessage
                  id='SupplierApp.KnowledgeBase'
                  defaultMessage='Knowledge Base'
                />
              </ListItemText>
            </ListItem>
            {showSuppliersTab && (
              <ListItem button onClick={() => handleNavMenuClick('search')}>
                <ListItemText>
                  <FormattedMessage
                    id='SupplierApp.Suppliers'
                    defaultMessage='Suppliers'
                  />
                </ListItemText>
              </ListItem>
            )}
          </List>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default connect((state: RootState) => {
  const searchableCommunities = companySelectors.getCompanySupplierField(
    state,
    'searchableCommunities'
  )

  const filters = {
    scope: 'All',
    sort: 'Relevance',
    communities: searchableCommunities ? [searchableCommunities.toJS()] : []
  }

  const queryString: string =
    searchSelectors.getQueryStringByRef(state, 'communitySearch') ||
    `?${qs.stringify({
      q: '',
      filter: { communities: filters.communities },
      scope: filters.scope,
      sort: filters.sort
    })}`

  const tier2Communities = state.getIn([
    'supplier',
    'tier2',
    'tier2Communities',
    'tier2CommMemberships'
  ])

  return {
    isLoading: companySelectors.isLoading(state),
    queryString,
    loadErrorMessage: companySelectors.getLoadingError(state),
    numberOfUnreadPosts: postsSelectors.getUnread(state),
    numberOfUnreadMessages: messagesSelectors.getNumberOfUnreadMessages(state),
    requiresOnboarding:
      routingSelectors.getPathname(state) === supplierPaths.onboarding,
    defaultTab: companySelectors.getCompanySupplierField(state, 'defaultTab'),
    showInsightTab: companySelectors.getCompanySupplierField(
      state,
      'showInsightTab'
    ),
    showSuppliersTab: searchableCommunities && searchableCommunities.size > 0,
    showDiversityTab: !!tier2Communities && tier2Communities.size > 0
  }
})(App)
