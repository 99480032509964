import { connect } from 'react-redux'
import ProfilePage from '../../components/ProfilePage'
import profileSelectors from '../../selectors/profileSelectors'
import vetBuyerSuppliersSelectors from '../../selectors/vetBuyerSuppliersSelectors'
import supplierRelationshipsSelectors from '../../../shared/selectors/supplierRelationshipsSelectors'
import communitiesSelectors from '../../../Communities/selectors/communitiesSelectors'
import editContactSelectors from '../../selectors/editContactSelectors'
import {
  removeContact,
  removeContactRelationship,
  editContact,
  updateContact,
  cancelEditContact,
  verifySupplier,
  requestBuyerForReference,
  loadSurveyResponse,
  loadESGRequestDetails
} from '../../actions'
import { createConciergeRequest } from 'shared/actions'
import mobileContactsSelector from 'accounts/selectors/mobileContactsSelector'
import { connectToSupplier } from '../../../shared/actions'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import relationshipsSelectors from '../../../shared/selectors/relationshipsSelectors'
import qs from 'qs'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import RootState from 'shared/models/RootState'
import integrationSelectors from 'buyer/SupplierProfile/selectors/integrationSelectors'
import surveySelectors from 'buyer/SupplierProfile/selectors/surveySelectors'

type ContainerProps = {
  location: { search: string }
}
export default connect(
  (state: RootState, props: ContainerProps) => {
    const queryParams = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
      arrayLimit: 100
    })
    const currentUserOrgUnitId = sessionSelectors.getOrgUnitId(state)
    const supplierType = profileSelectors.getDataField(state, 'supplierType')
    const supplierId = profileSelectors.getDataField(state, 'id')
    const currentSupplierRelationship = supplierRelationshipsSelectors.getSupplierRelationshipForCurrentSupplier(
      state
    )
    const customPitchDeckUrl =
      supplierType === 'basic' &&
      currentSupplierRelationship &&
      currentSupplierRelationship.getIn([
        'supplierCollaboration',
        'pitchDeckUrl'
      ])
    const customPitchDeckFileName =
      supplierType === 'basic' &&
      currentSupplierRelationship &&
      currentSupplierRelationship.getIn([
        'supplierCollaboration',
        'pitchDeckFileName'
      ])
    const supplierFollowing =
      currentSupplierRelationship &&
      currentSupplierRelationship.getIn(['supplierCollaboration', 'following'])
    const isCustomer =
      currentSupplierRelationship &&
      currentSupplierRelationship.getIn(['supplierCollaboration', 'isClient'])
    const card = cardsSelectors.getBySupplier(state, supplierId)
    const currentUser = sessionSelectors.getUser(state)
    const currentUserOrgUnit = orgsSelectors.getById(
      state,
      currentUserOrgUnitId
    )
    const relationship = relationshipsSelectors.getBySupplier(state, supplierId)

    const language = state.get('i18n').locale
    const longDescription = profileSelectors.getDataField(
      state,
      'longDescription'
    )
    const differentiatorsTags = card.get('differentiators')
    const offeringsTags = card.get('offerings')
    return {
      currentUserId: sessionSelectors.getUserId(state),
      currentUserOrgUnitId,
      currentUserOrgUnitName: currentUserOrgUnit.get('name'),
      showFollowing: supplierFollowing && !isCustomer,
      referenceScores: profileSelectors.getReferenceScores(state),
      vetTotalCount: vetBuyerSuppliersSelectors.getVetTotalCount(state),
      connected: card && card.get('connected'),
      supplierId,
      currentSupplierRelationship: currentSupplierRelationship,
      supplierName: profileSelectors.getDataField(state, 'name'),
      certifications: profileSelectors.getDataField(state, 'certifications'),
      locations: profileSelectors.getDataField(state, 'locations'),
      hasDetails: profileSelectors.hasDetails(state),
      hasCommunities: profileSelectors.hasCommunities(state),
      hasClientCommunities:
        communitiesSelectors.getCommunitiesWithoutTier2(state).size > 0,
      contacts: profileSelectors.getContacts(state),
      longDescription:
        !longDescription || typeof longDescription === 'string'
          ? longDescription
          : longDescription.get(language) || longDescription.get('en'),
      pitchDeckUrl:
        customPitchDeckUrl ||
        profileSelectors.getDataField(state, 'pitchDeckUrl'),
      serviceAreas: profileSelectors.getDataField(state, 'serviceAreas'),
      hasSimilarSuppliers: profileSelectors.hasSimilarSuppliers(state),
      isEditingContact: editContactSelectors.isEditing(state),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      isBuyerAdmin: sessionSelectors.userHasRole(state, 'buyerAdmin'),
      contactToEdit: editContactSelectors.getContact(state),
      hasSocialFeed: profileSelectors.hasSocialFeed(state),
      customers: isCustomer
        ? supplierRelationshipsSelectors
            .getClients(state)
            .filter(r => r.get('buyer') !== currentUserOrgUnitId)
        : supplierRelationshipsSelectors.getClients(state),
      scrollToDiversity: queryParams && !!queryParams.scrollToDiversity,
      scrollToReference: queryParams && !!queryParams.scrollToReference,
      scrollToTeam: queryParams && !!queryParams.scrollToTeam,
      scrollToSustainabilitySurvey:
        queryParams && !!queryParams.scrollToSustainabilitySurvey,
      mobile: !!mobileContactsSelector.get(state),
      userFirstName: currentUser.get('firstName'),
      userLastName: currentUser.get('lastName'),
      userProfilePicture: currentUser.get('profilePictureUrl'),
      userTitle: currentUser.get('title'),
      userEmail: currentUser.get('email'),
      referenceRequests: relationship && relationship.get('referenceRequests'),
      blogUrl: profileSelectors.getDataField(state, 'blogUrl'),
      videoUrls: profileSelectors.getDataField(state, 'videoUrls'),
      accessControl: sessionSelectors.getAccessControl(state),
      internalCategory: relationship && relationship.get('internalCategory'),
      differentiatorsTagCount: differentiatorsTags && differentiatorsTags.size,
      offeringsTagCount: offeringsTags && offeringsTags.size,
      ecovadisReportEnabled: integrationSelectors.getEcovadisReportEnabled(
        state
      ),
      surveyResponse: surveySelectors.getEsgSurvey(state),
      isCustomPitchDeck: !!customPitchDeckFileName,
      pitchDeckFileName:
        customPitchDeckFileName ||
        profileSelectors.getDataField(state, 'pitchDeckFileName'),
      userAccess: sessionSelectors.getUserAccess(state)
    }
  },
  {
    onRemoveContact: removeContact,
    onRemoveContactRelationship: removeContactRelationship,
    onEditContact: editContact,
    onCancelEdit: cancelEditContact,
    onUpdateContact: updateContact,
    onVerifySupplier: verifySupplier,
    connectToSupplier,
    createConciergeRequest,
    requestBuyerForReference,
    loadSurveyResponseDetails: loadSurveyResponse,
    loadActiveESGRequest: loadESGRequestDetails
  }
)(ProfilePage)
