import React, { useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RootState from 'shared/models/RootState'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import { AllocatedSpendState } from '../store/tier2Reducer'
import {
  loadTier2AllocatedSpend,
  updateTier2AllocatedSpend
} from '../store/actions'
import Loading from 'shared/components/Loading'
import ShareTier2 from '../components/ShareTier2/ShareTier2'
import dataSelectors from 'shared/selectors/dataSelectors'
import {
  BuyerOverviewSpendMap,
  SpendByQuarterItem,
  SpendByQuarterMap
} from './Report'
import { loadData } from 'shared/actions'
import { tier2DiverseSpendDataV2 } from '../store/loadTier2DiverseSpendData'

const addTotalDiverseSpendProperty = (
  buyerOverviewSpendMap: BuyerOverviewSpendMap
): SpendByQuarterMap => {
  const spendByQuarterMap: SpendByQuarterMap = new Map()
  const map = buyerOverviewSpendMap as BuyerOverviewSpendMap

  map.forEach((buyerOverviewSpendItems, key) => {
    let newValue: SpendByQuarterItem[] = buyerOverviewSpendItems
      ? buyerOverviewSpendItems.map(
          ({
            dataType,
            diverseSpend,
            quarter,
            spendType,
            totalSpend,
            year,
            id,
            isDirFileIndAgg
          }) => ({
            dataType,
            quarter,
            spendType,
            totalDiverseSpend: isDirFileIndAgg
              ? isDirFileIndAgg.totalDiverseSpend
              : diverseSpend
              ? Object.values(diverseSpend).reduce<number>(
                  (agg, spendAmount) =>
                    spendAmount ? spendAmount.spend + agg : agg,
                  0
                )
              : 0,
            totalSpend,
            year,
            stagingId: id,
            isDirFileIndAgg
          })
        )
      : []
    newValue = newValue.sort((a, b) => {
      if (a.year > b.year) {
        return -1
      }
      if (a.year < b.year) {
        return 1
      }
      if (a.quarter > b.quarter) {
        return -1
      }
      if (a.quarter < b.quarter) {
        return 1
      } else {
        return 0
      }
    })
    spendByQuarterMap.set(key, newValue)
  })

  return spendByQuarterMap
}

const ShareTier2Container = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadTier2AllocatedSpend())
  }, [dispatch])

  useEffect(() => {
    dispatch(loadData(tier2DiverseSpendDataV2))
  }, [dispatch])

  const allocatedSpend = useSelector<RootState, AllocatedSpendState>(state =>
    state.getIn(['supplier', 'tier2', 'allocatedSpend'])
  )

  const isLoadingBuyerOverviewSpendMap = useSelector<RootState, boolean>(
    state => dataSelectors.isLoading(state, 'tier2/diverseV2')
  )

  const buyerOverviewSpendMap = useSelector<RootState, BuyerOverviewSpendMap>(
    state => {
      const data = dataSelectors.getByEntity(state, 'tier2/diverseV2')
      if (data.size > 0) {
        return new Map(Object.entries(data.toJS()))
      }
      return new Map()
    }
  )

  const spendByQuarterMap = useMemo(
    () => addTotalDiverseSpendProperty(buyerOverviewSpendMap),
    [buyerOverviewSpendMap]
  )

  const handleShareAllocationSpend = useCallback(
    allocationSpend => dispatch(updateTier2AllocatedSpend(allocationSpend)),
    [dispatch]
  )

  if (allocatedSpend.isLoading || isLoadingBuyerOverviewSpendMap) {
    return <Loading />
  }

  if (!spendByQuarterMap || !spendByQuarterMap.size) {
    return (
      <Text className='mt3'>
        <FormattedMessage
          id='ShareTier2Container.NoDataMessage'
          defaultMessage='You need to publish at least one quarter of spend to start sharing your spend.'
        />
      </Text>
    )
  }
  return (
    <ShareTier2
      allocatedSpend={allocatedSpend}
      spendByQuarterMap={spendByQuarterMap}
      onShareSpendAllocation={handleShareAllocationSpend}
    />
  )
}

export default ShareTier2Container
