import React, {
  ChangeEvent,
  FunctionComponent,
  useMemo,
  useState,
  useEffect,
  useCallback
} from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'redux-form/immutable'
import FileInput from 'shared/components/FileInput'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import DatePicker from 'shared/components/DatePicker'
import Select from 'shared/components/Select'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import { validateUrl } from 'shared/utils/data/validate'
import certificationCategories, {
  CategoryType
} from 'shared/models/CertificationCategories'
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  defineMessages
} from 'react-intl'
import { List, Map, RecordOf } from 'immutable'
import diversityAgencies from './diversityAgencies'
import agencyDescriptionMessages from './agencyDescriptionMessages'
import SelfCertify from '../SelfCertify'
import Location from 'shared/models/Location'
import { InjectedFormProps } from 'redux-form'
import companySelectors from 'supplier/shared/selectors/companySelectors'
import RootState from 'shared/models/RootState'
import moment from 'moment'
import MessagingHud from './MessagingHud'

const messages = defineMessages({
  minDate: {
    id: 'CertificationForm.MinDate',
    defaultMessage:
      'Expiration Date should not be more than 3 years in the past'
  },
  maxDate: {
    id: 'CertificationForm.MaxDate',
    defaultMessage: 'Expiration Date should not be over 3 years'
  }
})

type Props = {
  category: CategoryType
  certAgency?: string
  subCategory?: string
  onCancel: () => void
  onSubmit: (vals: any) => void
  disableCategories: boolean
  isTealbot?: boolean
  editMode: boolean
  intl: IntlShape
  submitSucceeded?: boolean
  subTypes?: List<string>
  certifyingLocation?: RecordOf<Location>
  certNaics?: List<RecordOf<{ code: string; primary: boolean }>>
  qualifiedNaics?: Map<string, boolean>
  certifyingLocationKey?: string
  primaryNaics?: string
} & Partial<InjectedFormProps>

export const CertificationForm: FunctionComponent<Props> = props => {
  const [selectedAgency, setSelectedAgency] = useState<string>('')

  const {
    submitSucceeded,
    category,
    subCategory,
    certAgency,
    onCancel,
    onSubmit,
    disableCategories,
    isTealbot,
    editMode,
    intl,
    change,
    qualifiedNaics,
    certifyingLocationKey = '',
    primaryNaics,
    certifyingLocation,
    certNaics
  } = props

  const selectedLocation = useSelector((state: RootState) =>
    companySelectors.getLocationById(state, certifyingLocationKey)
  )

  const disableSubmit = useMemo(() => {
    const certifyingCountry =
      selectedLocation &&
      selectedLocation.getIn(['components', 'countryAbbrev'])

    return (
      !subCategory ||
      (certAgency === 'Self Certify' &&
        (!certifyingLocationKey ||
          (subCategory === 'sbe' &&
            certifyingCountry === 'US' &&
            (!primaryNaics || !qualifiedNaics?.get(primaryNaics)))))
    )
  }, [
    certifyingLocationKey,
    primaryNaics,
    qualifiedNaics,
    selectedLocation,
    certAgency,
    subCategory
  ])

  const handleAgencyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const agencyValue = e.currentTarget.value
      setSelectedAgency(agencyValue)
      if (change) {
        if (agencyValue === 'Other') {
          change('certAgency', '')
          change('subCategory', '')
          change('subTypes', '')
          change('diversityAgencyOther', '')
        } else if (diversityAgencies[agencyValue]) {
          change('certAgency', diversityAgencies[agencyValue].value)
          change('subCategory', diversityAgencies[agencyValue].subCategory)
        } else {
          change('certAgency', agencyValue)
          change('subCategory', '')
          change('subTypes', '')
        }
      }
    },
    [change]
  )

  const handleSubCategoryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (change && e.currentTarget.value !== 'mbe') {
        change('subTypes', '')
      }
    },
    [change]
  )

  const handleCategoryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (change) {
        change('certAgency', '')
        change('subCategory', '')
        change('subTypes', '')
        change('supplierCertified', '')
        change('diversityAgencyOther', '')
        setSelectedAgency('')
      }
    },
    [change]
  )

  useEffect(() => {
    if (submitSucceeded) {
      setSelectedAgency('')
    }
  }, [submitSucceeded])

  const subtypeDisplay = certificationCategories[category]?.subTypes
  return (
    <form onSubmit={onSubmit} data-testid='form'>
      {/* add cert -diversity, quality, etc*/}

      <Label htmlFor='category'>
        <FormattedMessage
          id='CertificationForm.AddCertificationLabel'
          defaultMessage='Add a Certification'
        />
      </Label>

      <Field
        name='category'
        component={Select}
        required
        disabled={disableCategories}
        fullWidth
        onChange={handleCategoryChange}
        props={{ id: 'category' }}
      >
        <FormattedMessage
          id='CertificationForm.SelectCategoryPlaceholder'
          defaultMessage='Please select a category'
        >
          {message => <option value=''>{message}</option>}
        </FormattedMessage>

        {Object.entries(certificationCategories).map(([key, certCategory]) => (
          <option key={key} value={key}>
            {intl.formatMessage(certCategory.title)}
          </option>
        ))}
      </Field>

      {/* once certification category selected, Diversity chooses an agency which gets matched to a Certification */}
      {!!category && (
        <>
          {/* diversity has a dropdown for certification agencies which is not editable*/}
          {!editMode && category === 'diversity' && (
            <>
              <Label htmlFor='selectcertificationagency'>
                <FormattedMessage
                  id='CertificationForm.CertAgencyLabel'
                  defaultMessage='Certification Agency'
                />
              </Label>
              <Select
                value={selectedAgency}
                onChange={handleAgencyChange}
                fullWidth
                name='selectcertificationagency'
              >
                <FormattedMessage
                  id='CertificationForm.PlaceholderAgencySelect'
                  defaultMessage='Please select a Certification Agency'
                >
                  {message => (
                    <option disabled value=''>
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                <FormattedMessage
                  id='CertificationForm.Self Certify'
                  defaultMessage='Self Certify'
                >
                  {message => <option value='Self Certify'>{message}</option>}
                </FormattedMessage>
                {diversityAgencies.map((agency, index) => (
                  <option key={agency.value} value={index}>
                    {agency.value}
                  </option>
                ))}
                <FormattedMessage
                  id='CertificationForm.other'
                  defaultMessage='Other'
                >
                  {message => <option value='Other'>{message}</option>}
                </FormattedMessage>
              </Select>
            </>
          )}
          {selectedAgency === 'Other' && (
            <>
              <Label>
                <FormattedMessage
                  id='CertificationForm.CertAgencyNameLabel'
                  defaultMessage='Agency Name'
                />
              </Label>
              <Field
                name='diversityAgencyOther'
                component={Input}
                placeholder={intl.formatMessage({
                  id: 'CertificationForm.NameOfAgencyPlaceholder',
                  defaultMessage: 'Name of agency (e.g. WEConnect or NMSDC)'
                })}
              />
            </>
          )}
          <Label htmlFor='subCategory'>
            <FormattedMessage
              id='CertificationForm.NameOfCertificationLabel'
              defaultMessage='Name of Certification'
            />
          </Label>
          <Field
            name='subCategory'
            component={Select}
            disabled={disableCategories}
            required
            onChange={handleSubCategoryChange}
            fullWidth
            props={{ id: 'subCategory' }}
          >
            <FormattedMessage
              id='CertificationForm.SelectCertificationPlaceholder'
              defaultMessage='Please select a Certification'
            >
              {message => <option value=''>{message}</option>}
            </FormattedMessage>

            {certificationCategories[category] &&
              Object.entries(
                certificationCategories[category].subCategories
              ).map(([key, subCategory]) => (
                <option key={key} value={key}>
                  {intl.formatMessage(subCategory)}
                </option>
              ))}
          </Field>
          {category === 'diversity' &&
            subCategory &&
            certAgency === 'Self Certify' && (
              <>
                <SelfCertify
                  disableCategories={disableCategories}
                  subCategory={subCategory}
                  change={change}
                  certNaics={certNaics}
                  certifyingLocation={certifyingLocation}
                  qualifiedNaics={qualifiedNaics}
                  certifyingLocationKey={certifyingLocationKey}
                  primaryNaics={primaryNaics}
                  editMode
                />
                {subCategory !== 'sbe' &&
                  agencyDescriptionMessages[subCategory] && (
                    <Text className='mt2'>
                      {intl.formatMessage(
                        agencyDescriptionMessages[subCategory]
                      )}
                    </Text>
                  )}
                {subCategory === 'hud' && (
                  <Text>
                    <MessagingHud />
                  </Text>
                )}
              </>
            )}

          {category &&
            subCategory &&
            subtypeDisplay &&
            subtypeDisplay[subCategory] && (
              <>
                <Label htmlFor='subTypes'>
                  <FormattedMessage
                    id='CertificationForm.NameOfSubTypeLabel'
                    defaultMessage='Name of Subtype'
                  />
                </Label>

                <Field
                  name='subTypes'
                  component={Select}
                  normalize={value => [value]}
                  fullWidth
                  props={{ id: 'subTypes' }}
                >
                  <FormattedMessage
                    id='CertificationForm.SelectSubtypePlaceholderOptional'
                    defaultMessage='Please select a Subtype if applicable'
                  >
                    {message => <option value=''>{message}</option>}
                  </FormattedMessage>

                  {Object.entries(subtypeDisplay[subCategory])
                    .filter(subType => !subType.includes('other'))
                    .map(([key, subType]) => (
                      <option key={key} value={key}>
                        {intl.formatMessage(subType)}
                      </option>
                    ))}
                </Field>
              </>
            )}
          {/* other certifications have a text input for their agencies*/}
          {/* when in editMode all category Certification Agencies are displayed but aren't editable */}
          {(editMode || category !== 'diversity') && (
            <>
              <Label>
                <FormattedMessage
                  id='CertificationForm.CertAgencyLabel'
                  defaultMessage='Certification Agency'
                />
              </Label>

              <Field
                name='certAgency'
                component={Input}
                placeholder={intl.formatMessage({
                  id: 'CertificationForm.NameOfAgencyPlaceholder',
                  defaultMessage: 'Name of agency (e.g. WEConnect or NMSDC)'
                })}
                disabled={disableCategories}
              />
            </>
          )}

          {/* details of certification for all categories and upload of certificate */}
          {((certAgency && certAgency !== 'Self Certify') ||
            selectedAgency === 'Other') && (
            <>
              <Label>
                <FormattedMessage
                  id='CertificationForm.UploadCertificateLabel'
                  defaultMessage='Upload {subCategory} Certificate'
                  values={{
                    subCategory: subCategory && subCategory.toUpperCase()
                  }}
                />
              </Label>
              <Field
                name='cert'
                component={FileInput}
                accept='.pdf,.png,.jpg,.jpeg,.svg,.gif'
              />

              <Label>
                <FormattedMessage
                  id='CertificationForm.CertificationExpirationLabel'
                  defaultMessage='Certification Expiration Date'
                />
              </Label>
              <Field
                name='certExpiration'
                component={DatePicker}
                normalize={value => {
                  return value && value.toISOString()
                }}
                minDate={moment().subtract(3, 'years')}
                minDateMessage={intl.formatMessage(messages.minDate)}
                maxDate={moment().add(3, 'years')}
                maxDateMessage={intl.formatMessage(messages.maxDate)}
              />

              <Label>
                <FormattedMessage
                  id='CertificationForm.CertificationNumberLabel'
                  defaultMessage='Certificate Number'
                />
              </Label>

              <Field
                name='certificateNumber'
                component={Input}
                placeholder={intl.formatMessage({
                  id:
                    'CertificationForm.CertificateNumberProvidedByTheAgencyPlaceholder',
                  defaultMessage: 'Certificate number provided by the agency'
                })}
              />
              {isTealbot && (
                <>
                  <Label>
                    <FormattedMessage
                      id='CertificationForm.SourceUrlLabel'
                      defaultMessage='Source URL'
                    />
                  </Label>
                  <Field
                    name='sourceURL'
                    component={Input}
                    placeholder={intl.formatMessage({
                      id:
                        'CertificationForm.LinkToTheCertifyingAgencyPlaceholder',
                      defaultMessage: 'Link to the certifying agency'
                    })}
                    validate={[validateUrl]}
                  />
                </>
              )}
            </>
          )}
          <Label>
            <FormattedMessage
              id='CertificationForm.SupplierVerification'
              defaultMessage='SUPPLIER VERIFICATION'
            />
          </Label>
          <Text>
            <Field
              name='supplierCertified'
              component='input'
              type='checkbox'
              required={isTealbot ? false : true}
              className='mr3'
              parse={(value, name) => !!value}
            />
            <FormattedMessage
              id='CertificationForm.SupplierVerificationMessage'
              defaultMessage={`I hereby certify that all information provided above is true and complete to the best of my knowledge. The undersigned certifies that they are authorized to sign on behalf of the Company listed above and that all the information above is true and accurate. This information I am sharing should be treated as valid until an updated form is submitted. It is the supplier's responsibility to notify TealBook if supplier's size, classification, ownership or other relevant information changes. The supplier may submit changes at any time on this same form. *`}
            />
          </Text>
          <div className='tr mt3'>
            <Button
              label={<FormattedMessage id='CancelButton' />}
              autoSize
              secondary
              onClick={onCancel}
              className='mr3'
            />
            {editMode ? (
              <Button
                label={
                  <FormattedMessage
                    id='CertificationForm.SubmitButtons'
                    defaultMessage='Update'
                  />
                }
                type='submit'
                disabled={disableSubmit}
                autoSize
              />
            ) : (
              <Button
                label={
                  <FormattedMessage
                    id='CertificationForm.SubmitButton'
                    defaultMessage='Add'
                  />
                }
                type='submit'
                disabled={disableSubmit}
                autoSize
              />
            )}
          </div>
        </>
      )}
    </form>
  )
}

export default injectIntl(CertificationForm)
