import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { notify } from 'shared/actions'
import Button from 'shared/components/Button'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import FileInput from 'shared/components/FileInput'
import Label from 'shared/components/Label'
import ListItem from 'shared/components/List/ListItem'
import Select from 'shared/components/Select'
import Text from 'shared/components/Text'
interface FileUploadResponse {
  ok: boolean
  message: string
}

type Props = {
  fileUploadHandler: (
    attachedFile: File,
    fileType: string
  ) => Promise<FileUploadResponse>
}

const SAPLoaderFileUpload = ({ fileUploadHandler }: Props) => {
  const styles = {
    header: 'db mb1 pt3 f5 fw6',
    listItemText: 'f7 fw4 pt0 pb1'
  }
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedType, setSelectedType] = useState<string | null>(null)
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false)
  const [apiResponse, setApiResponse] = useState<string>('')
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const allowUpload = Boolean(selectedFile && selectedType)

  const processFileUpload = async e => {
    e.preventDefault()
    if (selectedFile === null || selectedType === null) {
      return
    }
    setIsUploading(true)
    const response = await fileUploadHandler(selectedFile, selectedType)
    setIsUploading(false)
    setSelectedFile(null)
    setSelectedType(null)
    if (!response.ok) {
      setApiResponse(response.message)
      setOpenErrorDialog(true)
    } else {
      dispatch(notify({ message: response.message }))
    }
  }

  const closeErrorDialog = () => {
    setApiResponse('')
    setOpenErrorDialog(false)
  }

  return (
    <div className='mb3'>
      <div className='w-100 w-50-ns dib v-top pr2-ns'>
        <Label className={styles.header}>
          <FormattedMessage
            id='SAPLoader.uploadSteps'
            defaultMessage='Upload Supplier File Instructions'
          />
        </Label>
        <div>
          <ListItem
            mini
            primaryText={
              <FormattedMessage
                id='SAPLoader.uploadStep-1'
                defaultMessage='1. From the dropdown select the file type you wish to upload.'
              />
            }
          />
          <ListItem
            mini
            primaryText={
              <FormattedMessage
                id='SAPLoader.uploadStep-2'
                defaultMessage='2. Select the desired type and click upload.'
              />
            }
          />
          <ListItem
            mini
            primaryText={
              <FormattedMessage
                id='SAPLoader.uploadStep-3'
                defaultMessage='3. We will process the file.'
              />
            }
          />
          <ListItem
            mini
            primaryText={
              <FormattedMessage
                id='SAPLoader.uploadStep-4'
                defaultMessage='4. Once the file is ready, you will be notified.'
              />
            }
          />
          <ListItem
            mini
            primaryText={
              <FormattedMessage
                id='SAPLoader.uploadStep-5'
                defaultMessage='5. You can login and download the file.'
              />
            }
          />
        </div>
      </div>
      <div className='w-100 w-50-ns dib v-top pr2-ns'>
        <div className='w-100'>
          <Label className={styles.header}>
            <FormattedMessage
              id='SAPLoader.uploadFileHeader'
              defaultMessage='Select a type and upload your file'
            />
          </Label>
          <FileInput
            accept='.csv'
            className='mt3'
            name='attachedFile'
            limit={32}
            required
            value={selectedFile ? selectedFile.name : ''}
            onChange={(e: File | null) => {
              setSelectedFile(e)
            }}
            isUploading={isUploading}
          />
        </div>
        <div className='w-50-ns dib mt3 v-top'>
          <Select
            onChange={e => {
              setSelectedType(
                e.target.value === 'Select' ? null : e.target.value
              )
            }}
            name='fileType'
            value={selectedType || ''}
          >
            <FormattedMessage
              id='SAPLoader.fileTypeDefault'
              defaultMessage='Select'
            >
              {message => <option value='Select'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage
              id='SAPLoader.fileTypeDiversity'
              defaultMessage='Diversity Info'
            >
              {message => <option value='diversityInfo'>{message}</option>}
            </FormattedMessage>
            <FormattedMessage
              id='SAPLoader.fileTypeSupplierList'
              defaultMessage='Supplier List'
            >
              {message => <option value='supplierList'>{message}</option>}
            </FormattedMessage>
          </Select>
        </div>
        <div className='w-50-ns dib mt3 v-top'>
          <Button
            disabled={!allowUpload || isUploading}
            label={
              !isUploading ? (
                <FormattedMessage
                  id='SAPLoader.uploadFile'
                  defaultMessage='Upload file'
                />
              ) : (
                <FormattedMessage
                  id='SAPLoader.isUploading'
                  defaultMessage='Uploading file ...'
                />
              )
            }
            autoSize
            className='fr'
            onClick={processFileUpload}
          />
        </div>
      </div>

      <Dialog
        fullWidth
        open={openErrorDialog}
        onClose={() => closeErrorDialog()}
      >
        <DialogTitle onClose={() => closeErrorDialog()}>
          <FormattedMessage
            id='SAPLoader.FileUploadError'
            defaultMessage='We could not read the file you uploaded'
          />
        </DialogTitle>
        <DialogContent>
          <Text className='mv3 red'>{apiResponse}</Text>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'right' }}>
          <Button autoSize onClick={() => closeErrorDialog()}>
            <FormattedMessage id='CloseButton' />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SAPLoaderFileUpload
