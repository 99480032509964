import React, { FormEvent, FunctionComponent, useState } from 'react'
import Button from 'shared/components/Button'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import RadioButton from 'shared/components/RadioButton'
import Label from 'shared/components/Label'
import TextArea from 'shared/components/TextArea'
import Text from 'shared/components/Text'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import Select from 'shared/components/Select'
import { Map } from 'immutable'

const messages = defineMessages({
  healthAndSafety: {
    id: 'ReportForm.healthAndSafety',
    defaultMessage: 'Health and Safety'
  },
  environment: {
    id: 'ReportForm.environment',
    defaultMessage: 'Environment'
  },
  diversityAndInclusion: {
    id: 'ReportForm.diversityAndInclusion',
    defaultMessage: 'Diversity and Inclusion'
  },
  governance: {
    id: 'ReportForm.governance',
    defaultMessage: 'Governance'
  },
  privacy: {
    id: 'ReportForm.privacy',
    defaultMessage: 'Privacy'
  }
})

type Props = {
  orgId: string
  userId: string
  onSendContactUsMessage: (formValues: {
    reportReason?: string
    message?: string
    requestType: string
    userId: string
    orgId: string
    selectPage?: string
    selectQuestion?: string
  }) => void
  onCancel: () => void
  taskType: 'reportSupplier' | 'reportEsgSurvey'
  matchMobile: boolean
  numberOfResponsesByPage: Map<string, Array<string>>
}

const ReportForm: FunctionComponent<Props> = ({
  onSendContactUsMessage,
  orgId,
  userId,
  onCancel,
  taskType,
  matchMobile,
  numberOfResponsesByPage
}: Props) => {
  const intl = useIntl()

  const surveyPages = numberOfResponsesByPage?.keySeq()
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const [reportReason, setReportReason] = useState<string | undefined>('')
  const [reportReasonOther, setReportReasonOther] = useState<
    string | undefined
  >('')
  const [selectPage, setSelectPage] = useState<
    | 'healthAndSafety'
    | 'environment'
    | 'diversityAndInclusion'
    | 'governance'
    | 'privacy'
    | undefined
  >(undefined)
  const [selectQuestion, setSelectQuestion] = useState<string | undefined>('')

  const handleChangeReportReasonOther = e => {
    setReportReasonOther(e.currentTarget.value)
  }

  const handleChangeReportReason = e => {
    setReportReason(e.currentTarget.value)
    setReportReasonOther('')
  }
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let formValues = {
      reportReason,
      message: reportReasonOther,
      requestType: taskType,
      userId,
      orgId
    }
    if (taskType === 'reportEsgSurvey' && selectPage) {
      formValues['selectPage'] = selectPage
      formValues['selectQuestion'] = selectQuestion
    }
    onSendContactUsMessage(formValues)
    setHasSubmitted(true)
  }

  const handleChangePage = e => {
    setSelectPage(e.currentTarget.value)
  }
  const handleChangeQuestion = e => {
    setSelectQuestion(e.currentTarget.value)
  }
  return !hasSubmitted ? (
    <form onSubmit={handleSubmit}>
      {taskType === 'reportEsgSurvey' && (
        <>
          <Label noPadding>
            <FormattedMessage
              id='ReportForm.QuestionWithIssue'
              defaultMessage='Select the question you have an issue with (optional)'
            />
          </Label>
          <div className='flex mv3'>
            <div className='mr3'>
              <label htmlFor='selectsection' className='visuallyhidden'>
                <FormattedMessage
                  id='ReportForm.SelectSection'
                  defaultMessage={'Select section: '}
                />
              </label>
              <FormattedMessage
                id='ReportForm.Section'
                defaultMessage='Section'
              >
                {message => (
                  <Select onChange={handleChangePage} name='selectsection'>
                    <option value=''>{message}</option>
                    {surveyPages?.map(page => (
                      <option key={page} value={page}>
                        {intl.formatMessage(messages[page])}
                      </option>
                    ))}
                  </Select>
                )}
              </FormattedMessage>
            </div>
            <label htmlFor='selectquestion' className='visuallyhidden'>
              <FormattedMessage
                id='ReportForm.SelectQuestion'
                defaultMessage={'Select question: '}
              />
            </label>
            <FormattedMessage
              id='ReportForm.Question'
              defaultMessage='Question'
            >
              {message => (
                <Select onChange={handleChangeQuestion} name='selectquestion'>
                  <option value=''>{message}</option>
                  {selectPage &&
                    numberOfResponsesByPage.get(selectPage)?.map(response => (
                      <option key={response} value={response}>
                        {response}
                      </option>
                    ))}
                </Select>
              )}
            </FormattedMessage>
          </div>
          <RadioButtonGroup
            title={
              <FormattedMessage
                id='ReportForm.WhatsHappening'
                defaultMessage="What's happening?"
              />
            }
            stack
          >
            <RadioButton
              name='reason'
              value='issue with survey'
              label={
                <FormattedMessage
                  id='ReportForm.IssueWithSurveyItself'
                  defaultMessage='Issue with survey itself'
                />
              }
              type='radio'
              onChange={handleChangeReportReason}
              required={!reportReason}
            />
            <RadioButton
              name='reason'
              value='pdf upload issue'
              label={
                <FormattedMessage
                  id='ReportForm.PdfUploadIssue'
                  defaultMessage='PDF upload issue'
                />
              }
              type='radio'
              onChange={handleChangeReportReason}
              required={!reportReason}
            />
            <RadioButton
              name='reason'
              value='supplier response issue'
              label={
                <FormattedMessage
                  id='ReportForm.SupplierResponseIssue'
                  defaultMessage='Supplier response issue'
                />
              }
              type='radio'
              onChange={handleChangeReportReason}
              required={!reportReason}
            />
          </RadioButtonGroup>
        </>
      )}

      {taskType === 'reportSupplier' && (
        <RadioButtonGroup
          title={
            <FormattedMessage
              id='ReportForm.WhatsHappening'
              defaultMessage="What's happening?"
            />
          }
          stack
        >
          <RadioButton
            name='reason'
            value='inappropriate'
            label={
              <FormattedMessage
                id='ReportForm.TheyAreSharingInnaproproateOrOffensiveContent'
                defaultMessage='They are sharing inappropriate or offensive content'
              />
            }
            type='radio'
            onChange={handleChangeReportReason}
            required={!reportReason}
          />
          <RadioButton
            name='reason'
            value='fake or duplicate'
            label={
              <FormattedMessage
                id='ReportForm.ThisIsAFakeOrDuplicateAccount'
                defaultMessage='This is a fake or duplicate account'
              />
            }
            type='radio'
            onChange={handleChangeReportReason}
            required={!reportReason}
          />
        </RadioButtonGroup>
      )}

      <RadioButton
        name='reason'
        value='other (see message)'
        label={
          <FormattedMessage
            id='ReportForm.OtherPleaseDescribe'
            defaultMessage='Other (please describe below)'
          />
        }
        type='radio'
        onChange={handleChangeReportReason}
        required={!reportReason}
      />
      <Label>
        <FormattedMessage
          id='ReportForm.PleaseGiveUsSomeMoreInfo'
          defaultMessage='Please give us some more information so we can properly address this situation'
        />
      </Label>
      <TextArea
        name='message'
        onChange={handleChangeReportReasonOther}
        required={reportReason === 'other (see message)' && !reportReasonOther}
      />

      <div className='mv3 mt3-ns fixed static-ns bottom-2 fr'>
        <Button
          size={matchMobile ? 'large' : undefined}
          label={<FormattedMessage id='CancelButton' />}
          secondary
          autoSize
          onClick={onCancel}
          className='mr3'
        />
        <Button
          size={matchMobile ? 'large' : undefined}
          label={
            <FormattedMessage
              id='ReportForm.SubmitToTealbookForReview'
              defaultMessage='Submit to TealBook for review'
            />
          }
          type='submit'
          autoSize
        />
      </div>
    </form>
  ) : (
    <div>
      <Text>
        <FormattedMessage
          id='ReportForm.ThankYouForSubmittingAReportToTealbook'
          defaultMessage='Thank you for submitting a report to TealBook.
                Our team will be reviewing it as soon as possible.'
        />
      </Text>
      <Button
        label={<FormattedMessage id='ReportForm.Done' defaultMessage='Done' />}
        className='mt3'
        autoSize
        onClick={onCancel}
      />
    </div>
  )
}

export default ReportForm
