import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import FilterHOC from '../FilterHOC'
import CheckboxList from 'shared/components/CheckboxList'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import Text from 'shared/components/Text'
import Switch from 'shared/components/Switch'
import Button from 'shared/components/Button'
import Divider from 'shared/components/Divider'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import toggleArrayItem from 'shared/utils/toggleArrayItem'
import RootState from 'shared/models/RootState'
import searchSelectors from '../../selectors/searchSelectors'

type Props = {
  titleMap?: { [key: string]: string }
  filterKey: string
  list: List<{ value: string; label: string }>
  limit?: number
  selectedOnly?: boolean
  prefix?: string
  showMatchAny?: boolean
  selected: List<string | List<string>>
  disabled?: List<string>
  disabledMessage: string
  matchAnyListFn?: (list: List<any>) => List<any>
  onChange?: ({ key, value }: { key: string, value: any }) => void
  onToggleFilterOption: ({ key, value }: { key: string, value: any }) => void
  onChangeFilterOption: any
  showSkeleton?: boolean
  translatedMessages?: {
    [key: string]: MessageDescriptor
  }
  showValue?: boolean
  category?: string
}

type State = {
  matchAny: boolean
  conditions: any
  changed: boolean
}

export class FilterCheckboxListContainer extends Component<Props, State> {
  constructor(props) {
    super(props)

    const { selected } = props
    const isOr = selected && selected.size > 0 && List.isList(selected.first())
    
    this.state = {
      changed: false,
      matchAny: isOr,
      conditions: isOr ? selected.first().toJS() : []
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.matchAny && !state.changed) {
      const isOr =
        props.selected &&
        props.selected.size > 0 &&
        List.isList(props.selected.first())
      return {
        conditions: isOr ? props.selected.first().toJS() : []
      }
    }
    return null
  }

  handleOnChange = v => {
    const { onToggleFilterOption, filterKey, onChange } = this.props
    const { matchAny, conditions } = this.state

    if (onChange) {
      onChange({ key: filterKey, value: v })
      return null
    }

    if (matchAny) {
      this.setState({
        conditions: toggleArrayItem(conditions, v),
        changed: true
      })
    } else {
      onToggleFilterOption({ key: filterKey, value: v })
    }
  }

  handleApplyClick = () => {
    const { onChangeFilterOption, filterKey } = this.props
    const { conditions } = this.state

    onChangeFilterOption({ key: filterKey, value: [conditions] })
    this.setState({
      changed: false
    })
  }

  toggleMatchAny = () => {
    const {
      onChangeFilterOption,
      selected,
      filterKey,
      matchAnyListFn,
      list
    } = this.props

    const { matchAny } = this.state

    if (matchAny) {
      // toggle off
      this.setState({
        matchAny: false,
        changed: false
      })
      const resetValue =
        selected.size > 0 && List.isList(selected.first())
          ? (selected.first() as List<string>)
          : (selected as List<string | List<string>>)
      onChangeFilterOption({ key: filterKey, value: resetValue.toJS() })
    } else {
      // toggle on
      let newList
      if (matchAnyListFn) {
        newList = list && matchAnyListFn(list).map(item => item.value)
      }

      this.setState({
        matchAny: true,
        changed: true,
        conditions: newList
          ? selected.filter(c => newList.includes(c)).toJS()
          : selected.toJS()
      })
    }
  }

  render() {
    const {
      list,
      selected,
      disabled,
      disabledMessage,
      limit,
      selectedOnly,
      prefix,
      showMatchAny,
      matchAnyListFn,
      filterKey,
      titleMap,
      showSkeleton,
      translatedMessages,
      showValue,
      category
    } = this.props
    const { matchAny, changed, conditions } = this.state
    return (
      <div>
        {showMatchAny && (
          <div className='flex items-center justify-between mb1'>
            <Text secondary={!matchAny}>
              <FormattedMessage
                id='FilterCheckboxListContainer.matchAny'
                defaultMessage='Matches any of...'
              />
            </Text>
            <Switch
              checked={matchAny}
              onChange={this.toggleMatchAny}
              disabled={disabled ? disabled.size > 0 : false}
              ariaLabel={`Matches any of selected ${category}`}
            />
          </div>
        )}
        <CheckboxList
          prefix={prefix}
          titleMap={titleMap}
          filterKey={filterKey}
          selectedOnly={selectedOnly}
          limit={limit}
          list={matchAny && matchAnyListFn ? matchAnyListFn(list) : list}
          selected={matchAny ? List(conditions) : selected}
          disabled={disabled}
          disabledMessage={
            disabledMessage || (
              <FormattedMessage
                id='fixedFilterDefaultMessage'
                defaultMessage='This is a fixed filter that cannot be removed.'
              />
            )
          }
          onChange={this.handleOnChange}
          translatedMessages={translatedMessages}
          showValue={showValue}
          showSkeleton={showSkeleton}
          category={category}
        />
        {showMatchAny && matchAny && (
          <div className='tr'>
            <Divider className='mt1 mb2' />
            <Button
              label={
                <FormattedMessage
                  id='FilterCheckboxListContainer.apply'
                  defaultMessage='Apply'
                />
              }
              autoSize
              disabled={!changed}
              onClick={this.handleApplyClick}
            />
          </div>
        )}
      </div>
    )
  }
}

type ContainerProps = {
  filterKey: string
  selected?: List<string | List<string>>
  selectedSelector?: (state: RootState, filterKey: string) => void
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    const disabled = settingsSelectors.getFixedFilter(state, props.filterKey)
    
    return {
      showSkeleton: searchSelectors.isAggregating(state),
      selected:
        props.selected ||
        (props.selectedSelector
          ? props.selectedSelector(state, props.filterKey)
          : disabled &&
            filterOptionsSelectors.getFilterOption(state, props.filterKey) &&
            disabled.size > 0
          ? filterOptionsSelectors
              .getFilterOption(state, props.filterKey)
              .merge(disabled)
          : filterOptionsSelectors.getFilterOption(state, props.filterKey)),
      disabled:
        disabled &&
        disabled.reduce((result, value) => {
          return typeof value === 'string'
            ? result.push(value)
            : result.concat(value)
        }, List([])),
      disabledMessage: settingsSelectors.getFixedFiltersMessage(state)
    }
  },
  {
    ...FilterHOC
  }
)(FilterCheckboxListContainer)
