import React, {
  Component,
  Fragment,
  ReactNode,
  ReactElement,
  createRef,
  RefObject,
} from 'react'
import EditProfileButton from 'shared/components/EditProfileButton'
import OrgLogo from 'shared/components/OrgLogo'
import ExternalImageLink from 'shared/components/ExternalImageLink'
import FacebookIcon from 'shared/assets/icons/facebook.svg'
import LinkedinIcon from 'shared/assets/icons/linkedin.svg'
import TwitterIcon from 'shared/assets/icons/twitter.svg'
import dateFormat from 'shared/utils/dateFormat'
import SupplierAttributes from 'buyer/shared/components/SupplierAttributes'
import SupplierStatus from 'buyer/shared/components/SupplierStatus'
import Text from 'shared/components/Text'
import { withStyles } from '@material-ui/core/styles'
import CameraIcon from '@material-ui/icons/CameraEnhance'
import DeleteIcon from '@material-ui/icons/Delete'
import MaterialButton from '@material-ui/core/Button'
import UploadImageDialog from 'shared/components/UploadImageDialog'
import Tooltip from 'shared/components/Tooltip'
import Hidden from '@material-ui/core/Hidden'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import Address from 'shared/components/Address'
import LocationIndex from './LocationIndex'
import { RecordOf, List, Map } from 'immutable'
import Location from 'shared/models/Location'
import Certification from 'shared/models/Certification'
import { RouteComponentProps } from 'react-router'
import WebsiteWarning, {
  WebsiteWarningType,
} from 'shared/components/WebsiteWarning'
import parallax from 'shared/utils/parallax'
import truncate from 'lodash.truncate'

const buttonClasses = 'f7 fw4 ma0 pa0 pointer dib teal no-underline bn'

const MaterialButtonWithStyles = withStyles({
  root: {
    minWidth: 'auto',
  },
  label: {
    textTransform: 'none',
  },
  contained: {
    '&:active': {
      backgroundColor: 'white',
    },
  },
})(MaterialButton)

type Props = {
  logoUrl: string
  coverUrl?: string
  name: string
  phone?: string
  address?: RecordOf<Location> | string
  websiteUrl: string
  linkedInUrl?: string
  facebookUrl?: string
  twitterId?: string
  publicView?: boolean
  redirectFrom?: string
  attachments?: Array<{
    createdBy?: string
    createdDate?: string
    documentFileUrl?: string
    expirationDate?: string
    type?: string
  }>
  alternativeNames?: List<string>
  status?: string
  description: string
  domains?: Array<string>
  rightContent?: string | ReactNode
  cpiLocation?: RecordOf<{
    countryCpi: number
    country: string
  }>
  websiteWarning?: WebsiteWarningType
  attributes?: {
    isDoNotUse?: boolean
    isExisting?: boolean
    isNonRecruit?: boolean
    isPreferred?: boolean
    isPrivate?: boolean
    isRestricted?: boolean
    isSanctionedCompany?: boolean
    isSanctionedCountry?: boolean
    sanctionedCountryList?: List<string>
  }
  certifications?: List<[string, List<RecordOf<Certification>>]>
  diversityProgram?: boolean
  surveySubmitted?: boolean
  removeBackgroundPicture?: () => void

  onEdit?: () => void
  uploadFile?: (arg: { fieldName: string; file: string }) => void
  onDeleteLogo?: () => void
  attachmentTypes?: Map<string, Map<string, string>>
  relationshipTypes?: Map<string, Map<string, string>>
  validations?: List<
    RecordOf<{
      confirmed: boolean
      subCategory: string
      timeStamp: string
    }>
  >
  created?: Date
  isTealbot?: boolean
  isOrgUnitFrozen?: boolean
} & Partial<Pick<RouteComponentProps, 'history'>>

class CompanyProfileHeader extends Component<Props> {
  state = {
    openBackgroundPictureDialog: false,
    openUploadImageDialog: false,
    openDeleteDialog: false,
    uploadImageDialogTitle: '',
    uploadImageDialogLabel: '',
    uploadImageFieldName: '',
    uploadImageAttributes: {},
    file: '',
  }

  banner: RefObject<HTMLDivElement> = createRef()

  componentDidMount() {
    if (this.banner.current) {
      parallax.backgroundImageParallax(this.banner.current)
    }
  }

  toggleUploadImageDialog = (
    fieldName?: string,
    title?: string | ReactElement<FormattedMessage>,
    label?: string | ReactElement<FormattedMessage>,
    imgAttr?: {
      limit?: number
      imageUrl?: string
      cropWidth?: number
      cropHeight?: number
      logo?: boolean
    }
  ) => {
    this.setState({
      openUploadImageDialog: !this.state.openUploadImageDialog,
      uploadImageDialogTitle: title,
      uploadImageDialogLabel: label,
      uploadImageAttributes: imgAttr,
      uploadImageFieldName: fieldName,
    })
  }

  handleUploadImage = (fieldName) => {
    const { uploadFile } = this.props
    const { file } = this.state

    if (file && uploadFile) {
      uploadFile({ fieldName, file })
    }

    this.toggleUploadImageDialog()
  }

  handleFileChange = (file) => {
    this.setState({
      file,
    })
  }

  handleRedirectBack = () => {
    const { history, redirectFrom } = this.props
    if (history && redirectFrom) {
      history.push(redirectFrom)
    }
  }

  renderSocialSection() {
    const { facebookUrl, linkedInUrl, twitterId, publicView, onEdit } =
      this.props

    if (!facebookUrl && !linkedInUrl && !twitterId && !publicView) {
      return (
        <button onClick={onEdit} className={buttonClasses}>
          <FormattedMessage
            id='CompanyProfileHeader.AddAScoialAccount'
            defaultMessage='Add a social account'
          />
        </button>
      )
    }

    let twitterUrl
    if (twitterId) {
      if (twitterId.includes('twitter.com')) {
        twitterUrl = twitterId
      } else if (twitterId.includes('@')) {
        twitterUrl = 'twitter.com/' + twitterId.substring(1, twitterId.length)
      } else {
        twitterUrl = 'twitter.com/' + twitterId
      }
    }

    const facebook = facebookUrl ? (
      <ExternalImageLink
        imgAlt='facebook'
        href={facebookUrl}
        imgSrc={FacebookIcon}
      />
    ) : null

    const linkedin = linkedInUrl ? (
      <ExternalImageLink
        imgAlt='linkedin'
        href={linkedInUrl}
        imgSrc={LinkedinIcon}
      />
    ) : null

    const twitter = twitterUrl ? (
      <ExternalImageLink
        imgAlt='twitter'
        href={twitterUrl}
        imgSrc={TwitterIcon}
      />
    ) : null

    return (
      <div>
        {facebook} {linkedin} {twitter}
      </div>
    )
  }

  toggleDeleteDialog = () => {
    this.setState({
      openDeleteDialog: !this.state.openDeleteDialog,
    })
  }

  handleDeleteLogo = () => {
    const { onDeleteLogo } = this.props
    this.setState({
      openDeleteDialog: false,
    })
    if (onDeleteLogo) onDeleteLogo()
  }
  render() {
    const {
      logoUrl,
      name,
      alternativeNames,
      status,
      websiteUrl,
      address,
      phone,
      description,
      onEdit,
      publicView,
      children,
      redirectFrom,
      attributes,
      attachments,
      certifications,
      coverUrl,
      domains,
      removeBackgroundPicture,
      rightContent,
      cpiLocation,
      websiteWarning,
      diversityProgram,
      attachmentTypes,
      relationshipTypes,
      validations,
      created,
      isTealbot,
      isOrgUnitFrozen,
      surveySubmitted,
    } = this.props
    const {
      openUploadImageDialog,
      uploadImageDialogTitle,
      uploadImageDialogLabel,
      uploadImageAttributes,
      uploadImageFieldName,
      openDeleteDialog,
    } = this.state

    return (
      <section className='bg-white pb4 pb5-ns'>
        <div
          className={`w-100 ${coverUrl ? '' : 'bg-near-white'}`}
          style={{ height: !publicView && !coverUrl ? 75 : 'auto' }}
        >
          <div className='mw8 center relative'>
            {!publicView && (
              <div className='mw8 ph3 mt3 absolute z-3' style={{ right: 0 }}>
                {coverUrl ? (
                  <MaterialButtonWithStyles
                    variant='contained'
                    onClick={removeBackgroundPicture}
                  >
                    <DeleteIcon className='mr2' />
                    <FormattedMessage
                      id='CompanyProfileHeader.RemoveBanner'
                      defaultMessage='Remove Banner'
                    />
                  </MaterialButtonWithStyles>
                ) : (
                  <MaterialButtonWithStyles
                    variant='contained'
                    onClick={() =>
                      this.toggleUploadImageDialog(
                        'backgroundPicture',
                        <FormattedMessage
                          id='CompanyProfileHeader.UploadBannerImage'
                          defaultMessage='Upload Banner Image'
                        />,
                        <FormattedMessage
                          id='CompanyProfileHeader.BannerImage'
                          defaultMessage='Banner Image'
                        />,
                        {
                          limit: 4,
                          imageUrl: coverUrl,
                          cropWidth: 2048,
                          cropHeight: 512,
                        }
                      )
                    }
                  >
                    <CameraIcon className='mr2' />
                    <FormattedMessage
                      id='CompanyProfileHeader.AddBanner'
                      defaultMessage='Add Banner'
                    />
                  </MaterialButtonWithStyles>
                )}
              </div>
            )}
            {coverUrl && (
              <div
                className='parallax'
                style={{ backgroundImage: `url(${coverUrl})` }}
                ref={this.banner}
              >
                <img src={coverUrl} alt='banner' style={{ opacity: 0 }} />
              </div>
            )}
          </div>
        </div>
        <div className='mw8 w-100 center dt border-box nt5-ns'>
          <div className='nt4-ns dt tc w-100 mw8 ph4 ph3-ns ph4-m'>
            <div className='pr4-ns pr0 dtc-ns pt6-ns dib'>
              <div
                className='pa3 pa0-ns flex items-center bg-white br2 tc relative'
                style={{
                  height: '150px',
                  width: '150px',
                  minWidth: '150px',
                  maxHeight: '150px',
                }}
              >
                <OrgLogo name={name} url={logoUrl} />
                {!publicView && (
                  <Fragment>
                    {logoUrl ? (
                      <FormattedMessage
                        id='CompanyProfileHeader.RemoveCompanyLogo'
                        defaultMessage='Remove Company Logo'
                      >
                        {(message) => (
                          <Tooltip title={message}>
                            <MaterialButtonWithStyles
                              aria-label='company logo'
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                              }}
                              variant='contained'
                              onClick={this.toggleDeleteDialog}
                            >
                              <DeleteIcon />
                            </MaterialButtonWithStyles>
                          </Tooltip>
                        )}
                      </FormattedMessage>
                    ) : (
                      <FormattedMessage
                        id='CompanyProfileHeader.AddCompanyLogo'
                        defaultMessage='Add Company Logo'
                      >
                        {(message) => (
                          <Tooltip title={message}>
                            <MaterialButtonWithStyles
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                              }}
                              aria-label='add company logo'
                              variant='contained'
                              onClick={() =>
                                this.toggleUploadImageDialog(
                                  'logo',
                                  'Upload Company Logo',
                                  'Company logo',
                                  {
                                    logo: true,
                                    imageUrl: logoUrl,
                                  }
                                )
                              }
                            >
                              <CameraIcon />
                            </MaterialButtonWithStyles>
                          </Tooltip>
                        )}
                      </FormattedMessage>
                    )}
                  </Fragment>
                )}
              </div>
              {status && status !== 'Active' && (
                <SupplierStatus status={status} />
              )}
              {redirectFrom && typeof redirectFrom === 'string' && (
                <Hidden smDown>
                  <div className='mt2'>
                    <h5
                      className='f8 fw3 gray underline-hover pointer ma0 lh-copy dib v-mid'
                      onClick={this.handleRedirectBack}
                    >
                      &#x276e;{' '}
                      {redirectFrom.toLowerCase().includes('search') ? (
                        <FormattedMessage
                          id='CompanyProfileHeader.BackToSearchResults'
                          defaultMessage='Back to search results'
                        />
                      ) : (
                        <FormattedMessage
                          id='CompanyProfileHeader.BackToPreviousPage'
                          defaultMessage='Back to previous page'
                        />
                      )}
                    </h5>
                  </div>
                </Hidden>
              )}
            </div>
            <div className='dtc-ns mt0-ns v-top tl w-100 pt6-ns pt4'>
              <div className='w-100 dt'>
                <div className='flex items-center'>
                  <h1 className='f3 mid-gray ma0 mw6-ns mr2'>{name}</h1>
                  {websiteWarning && (
                    <WebsiteWarning warning={websiteWarning} />
                  )}
                  {(attributes || certifications || cpiLocation) && (
                    <>
                      <SupplierAttributes
                        {...attributes}
                        attachments={attachments}
                        certifications={certifications}
                        diversityProgram={diversityProgram}
                        attachmentTypes={attachmentTypes}
                        relationshipTypes={relationshipTypes}
                        validations={validations}
                        supplierName={name}
                        website={websiteUrl}
                        isSurveyCompleted={surveySubmitted}
                      />
                      {cpiLocation && cpiLocation.get('countryCpi') && (
                        <div className='dib ml1'>
                          <LocationIndex
                            countryCpi={cpiLocation.get('countryCpi')}
                            country={cpiLocation.get('country')}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                {alternativeNames && alternativeNames.size > 0 && (
                  <span className='f7 fw4 gray ma0 mt1 db'>
                    {alternativeNames.first()}
                    {alternativeNames.size > 1 && (
                      <Tooltip
                        title={alternativeNames.shift().map((name) => (
                          <div key={name}>{name}</div>
                        ))}
                      >
                        <span
                          className='bg-light-gray dark-gray br2 ml1 sliver ph1'
                          style={{ cursor: 'default' }}
                        >
                          <FormattedMessage
                            id='CompanyProfileHeader.More'
                            defaultMessage='more...'
                          />
                        </span>
                      </Tooltip>
                    )}
                  </span>
                )}
                {children}

                {!publicView && (!isOrgUnitFrozen || isTealbot) && (
                  <div className='tr border-box dtc'>
                    <EditProfileButton
                      onClick={() => {
                        document.documentElement.scrollTop = 0
                        document.body.scrollTop = 0
                        if (onEdit) onEdit()
                      }}
                      buttonTitle='Edit profile'
                    />
                  </div>
                )}
              </div>
              {isTealbot && (
                <div className='mt1'>
                  {created ? (
                    <Text className='gray'>
                      <FormattedMessage
                        id='CompanyProfileHeader.CreatedOn'
                        defaultMessage='Supplier created in tealbook: {created}'
                        values={{ created: dateFormat(created) }}
                      />
                    </Text>
                  ) : (
                    <Text className='gray'>
                      <FormattedMessage
                        id='CompanyProfileHeader.CreatedOnLegacy'
                        defaultMessage='Legacy supplier, created before 08/2017'
                      />
                    </Text>
                  )}
                </div>
              )}
              {!publicView && (
                <div className='mt1'>
                  <Text className='gray'>
                    <FormattedMessage
                      id='CompanyProfileHeader.RegisteredUnder'
                      defaultMessage='{domainsLength, plural, one {Registered under domain:} other {Registered under domains:}}'
                      values={{ domainsLength: domains ? domains.length : 0 }}
                    />{' '}
                    {`${domains && domains.join(', ')}`}
                  </Text>
                </div>
              )}
              <div className='flex justify-between'>
                <div>
                  <div className='mt3'>
                    {websiteUrl && (
                      <div className='dib mr3'>
                        <a
                          className='f7 fw4 gray ma0 dib pointer underline-hover link'
                          href={
                            websiteUrl.match(/^https?:\/\//i)
                              ? websiteUrl
                              : `http://${websiteUrl}`
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {truncate(websiteUrl, {
                            length: 100,
                            separator: /\?+/,
                          })}
                        </a>
                      </div>
                    )}

                    {!websiteUrl && !publicView && (
                      <h5 className='f7 fw4 gray ma0 i dib mr3'>
                        <FormattedMessage
                          id='CompanyProfileHeader.AddYourWebsite'
                          defaultMessage='Add your website'
                        />
                      </h5>
                    )}

                    <div className='lh-copy f7 fw4 ma0 tl gray i dib v-mid'>
                      {this.renderSocialSection()}
                    </div>
                  </div>

                  {(address || phone) && (
                    <div>
                      {address && <Address location={address} />}

                      {!address && !publicView && (
                        <a
                          href='#Locations'
                          className='f7 fw4 ma0 dib mr3 teal no-underline'
                        >
                          <FormattedMessage
                            id='CompanyProfileHeader.AddALocation'
                            defaultMessage='Add a Location'
                          />
                        </a>
                      )}

                      {phone && (
                        <p className='f7 fw4 gray ma0 dib v-mid'>
                          <FormattedMessage
                            id='CompanyProfileHeader.Tel'
                            defaultMessage='Tel: {phone}'
                            values={{ phone }}
                          />
                        </p>
                      )}
                    </div>
                  )}
                  {description && (
                    <p className='lh-copy f6 fw4 ma0 mt3 tl lh-copy dark-gray mw6-ns'>
                      {description}
                    </p>
                  )}

                  {!description && !publicView && (
                    <p className='lh-copy f7 fw4 ma0 mt3 tl lh-copy gray i mw5 mw6-l'>
                      <button onClick={onEdit} className={buttonClasses}>
                        <FormattedMessage
                          id='CompanyProfileHeader.QucikDescription'
                          defaultMessage='Add a quick description'
                        />
                      </button>{' '}
                      <FormattedMessage
                        id='CompanyProfileHeader.QucikUnderstanding'
                        defaultMessage='of your company so others can get a quick understanding of what you do.'
                      />
                    </p>
                  )}
                </div>
                {rightContent && <div>{rightContent}</div>}
              </div>
            </div>
          </div>
        </div>
        <UploadImageDialog
          open={openUploadImageDialog}
          title={uploadImageDialogTitle}
          label={uploadImageDialogLabel}
          imageAttributes={uploadImageAttributes}
          fieldName={uploadImageFieldName}
          onChange={this.handleFileChange}
          onCancel={this.toggleUploadImageDialog}
          onUpload={this.handleUploadImage}
        />

        <Dialog open={openDeleteDialog} onClose={this.toggleDeleteDialog}>
          <DialogTitle>
            <FormattedMessage
              id='CompanyProfileHeader.DeletingUser'
              defaultMessage='Remove this Logo?'
            />
          </DialogTitle>
          <DialogContent>
            <Text className='mt3'>
              <FormattedMessage
                id='CompanyProfileHeader.cantUndo'
                defaultMessage={"You won't be able to undo this."}
              />
            </Text>
          </DialogContent>
          <DialogActions>
            <Button
              label={
                <FormattedMessage
                  id='CompanyProfileHeader.Delete'
                  defaultMessage='Delete'
                />
              }
              autoSize
              caution
              onClick={this.handleDeleteLogo}
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              autoSize
              secondary
              onClick={this.toggleDeleteDialog}
            />
          </DialogActions>
        </Dialog>
      </section>
    )
  }
}

export default CompanyProfileHeader
