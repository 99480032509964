import React, { Component } from 'react'
import {
  acceptVet,
  showInterestVet,
  acceptVetCriteria,
  uploadVetPresentation,
  removeVetPresentation,
  declineVet,
  updateVetProposal
} from '../../actions'
import vetBuyerSupplierSelectors from '../../selectors/vetBuyerSupplierSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import settingsSelectors from '../../../../buyer/shared/selectors/settingsSelectors'
import { connect } from 'react-redux'
import VetResponsePage from '../../components/VetResponsePage'
import dateFormat from 'shared/utils/dateFormat'
import { fromJS } from 'immutable'
import debounce from 'debounce'
import { defaultMemoize } from 'reselect'
import Loading from 'shared/components/Loading'
import downloadAttachment, {
  downloadAttachmentToURL
} from 'shared/utils/fileDownload/fileDownload'

export class VetResponsePageContainer extends Component {
  onAcceptVet = description => {
    const {
      acceptVet,
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    acceptVet({ vetBuyerSupplierId, description })
  }

  onDeclineVet = description => {
    const {
      declineVet,
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    declineVet({ vetBuyerSupplierId, description })
  }

  onShowInterestVet = interested => {
    const {
      showInterestVet,
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    showInterestVet({ vetBuyerSupplierId, interested })
  }

  onAcceptVetCriteria = meetCriteria => {
    const {
      acceptVetCriteria,
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    acceptVetCriteria({ vetBuyerSupplierId, meetCriteria })
  }

  onUploadVetPresentation = file => {
    const {
      uploadVetPresentation,
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    if (file) {
      uploadVetPresentation({
        file,
        vetBuyerSupplierId
      })
    }
  }

  onRemoveVetPresentation = fileName => {
    const {
      removeVetPresentation,
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    if (fileName) {
      removeVetPresentation({
        fileName,
        vetBuyerSupplierId
      })
    }
  }

  handleChangePricing = debounce(({ id, priceInput }) => {
    const {
      updateVetProposal,
      proposalPricing,
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    updateVetProposal({
      vetBuyerSupplierId,
      pricing: {
        lineItems: proposalPricing
          .get('lineItems', fromJS([]))
          .update(lineItems =>
            lineItems
              .filter(item => item.get('id') !== id)
              .push(fromJS({ id, amount: priceInput }))
          )
          .toJS()
      }
    })
  }, 300)

  handleChangeQuoteByUnitPrice = quoteByUnitPrice => {
    const {
      updateVetProposal,
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    updateVetProposal({
      vetBuyerSupplierId,
      pricing: {
        unit: quoteByUnitPrice
      }
    })
  }

  handleChangeDescription = changes => {
    const {
      updateVetProposal,
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props

    updateVetProposal({
      vetBuyerSupplierId,
      ...changes
    })
  }
  handleFileDownload = fileName => {
    const {
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props
    downloadAttachment(fileName, `vets/${vetBuyerSupplierId}/responseFile`)
  }

  getFilePreview = fileName => {
    const {
      match: {
        params: { vetBuyerSupplierId }
      }
    } = this.props
    return downloadAttachmentToURL(
      fileName,
      `vets/${vetBuyerSupplierId}/responseFile/signedUrl`
    )
  }

  render() {
    const {
      submittedDate,
      submittedBy,
      isLoading,
      interested,
      meetCriteria,
      status,
      description,
      documents,
      criticalCriteria,
      detailedReponseRequested,
      includePricing,
      pricing,
      proposalPricing,
      proposalPrices,
      responseBy,
      allowWordDocs
    } = this.props

    if (isLoading) {
      return <Loading />
    }

    return (
      <VetResponsePage
        meetCriteria={meetCriteria}
        interested={interested}
        criticalCriteria={criticalCriteria}
        detailedReponseRequested={detailedReponseRequested}
        status={status}
        description={description}
        documents={documents}
        submittedDate={submittedDate}
        submittedBy={submittedBy}
        includePricing={includePricing}
        pricing={pricing}
        proposalPrices={proposalPrices}
        quoteByUnitPrice={proposalPricing.get('unit')}
        proposalPricing={proposalPricing}
        onAcceptVet={this.onAcceptVet}
        onDeclineVet={this.onDeclineVet}
        onShowInterestVet={this.onShowInterestVet}
        onAcceptVetCriteria={this.onAcceptVetCriteria}
        onUploadVetPresentation={this.onUploadVetPresentation}
        onRemoveVetPresentation={this.onRemoveVetPresentation}
        onChangePricing={this.handleChangePricing}
        onChangeQuoteByUnitPrice={this.handleChangeQuoteByUnitPrice}
        onChangeDescription={this.handleChangeDescription}
        responseBy={responseBy}
        allowWordDocs={allowWordDocs}
        onDownload={this.handleFileDownload}
        getFilePreview={this.getFilePreview}
      />
    )
  }
}

const proposalPricesSelector = defaultMemoize((pricing, proposalPricing) => {
  return (
    pricing &&
    pricing.get('lineItems') &&
    pricing.get('lineItems').reduce((result, item) => {
      const proposalPriceItem = proposalPricing
        .get('lineItems', [])
        .find(pItem => pItem.get('id') === item.get('id'))
      return {
        ...result,
        [item.get('id')]: proposalPriceItem
          ? proposalPriceItem.get('amount')
          : ''
      }
    }, {})
  )
})

export default connect(
  state => {
    const vet = vetBuyerSupplierSelectors.getVet(state)
    const vetBuyerSupplier = vetBuyerSupplierSelectors.getVetBuyerSupplier(
      state
    )
    const date =
      vetBuyerSupplier &&
      vetBuyerSupplier.getIn(['collaboration', 'proposal', 'submitted', 'date'])
    const user =
      vetBuyerSupplier &&
      vetBuyerSupplier.getIn(['collaboration', 'proposal', 'submitted', 'user'])
    const interested =
      vetBuyerSupplier &&
      vetBuyerSupplier.getIn(['collaboration', 'proposal', 'interested']) &&
      true
    const meetCriteria =
      vetBuyerSupplier &&
      vetBuyerSupplier.getIn(['collaboration', 'proposal', 'meetCriteria']) &&
      true
    const pricing = vet && vet.getIn(['vetDetails', 'pricing'], fromJS({}))
    const proposalPricing = vetBuyerSupplier
      ? vetBuyerSupplier.getIn(
          ['collaboration', 'proposal', 'pricing'],
          fromJS({})
        )
      : fromJS({})
    const status =
      vetBuyerSupplier && vetBuyerSupplier.getIn(['collaboration', 'status'])
    const supplierContacts =
      vetBuyerSupplier &&
      vetBuyerSupplier.getIn(['collaboration', 'sendToContacts'])

    return {
      hasSupplierContacts: !!supplierContacts && supplierContacts.size > 0,
      interested,
      meetCriteria,
      pricing,
      proposalPricing,
      proposalPrices: proposalPricesSelector(pricing, proposalPricing),
      includePricing: vet && vet.getIn(['vetDetails', 'includePricing']),
      status,
      description:
        vetBuyerSupplier &&
        vetBuyerSupplier.getIn(['collaboration', 'proposal', 'description']),
      documents: vetBuyerSupplier.getIn(
        ['collaboration', 'proposal', 'documents'],
        fromJS([])
      ),
      criticalCriteria: vet && vet.getIn(['vetDetails', 'criticalCriteria']),
      detailedReponseRequested:
        vet && vet.getIn(['vetDetails', 'detailedReponseRequested']),
      submittedDate: date && dateFormat(date),
      submittedBy: user && usersSelectors.getById(state, user),
      responseBy: vet && vet.getIn(['vetDetails', 'responseDate']),
      isLoading: vetBuyerSupplierSelectors.getIsLoadingVet(state),
      allowWordDocs: settingsSelectors.getAllowWordDocs(state)
    }
  },
  {
    acceptVet,
    declineVet,
    showInterestVet,
    acceptVetCriteria,
    uploadVetPresentation,
    removeVetPresentation,
    updateVetProposal
  }
)(VetResponsePageContainer)
