import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { List, RecordOf } from 'immutable'
import UserList from '../../../components/Users/UserList'
import usersSelectors from 'shared/selectors/usersSelectors'
import settingsSelectors from '../../../../buyer/shared/selectors/settingsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import ContentAdd from '@material-ui/icons/Add'
import AddUser from '../../AddUser'
import {
  openUserDialog,
  selectBulkUsers,
  resendUserInvite
} from '../../../actions'
import Fab from '@material-ui/core/Fab'
import RootState from 'shared/models/RootState'
import { RouteComponentProps } from 'react-router'
import User from 'shared/models/User'
import editUserSelectors from '../../../selectors/editUserSelectors'
import Page from 'shared/components/Page/Page'
import { FormattedMessage } from 'react-intl'

type Props = {
  openUserDialog: typeof openUserDialog
  users: List<RecordOf<User>>
  isClientApp: boolean
  isTealbot: boolean
  onClickUser: () => void
  currentUser: RecordOf<User>
  bulkUsersSelected: Partial<{
    userId: {
      id: string
      roles: string[]
      status: string
      firstName: string
      lastName: string
    }
  }>
  selectBulkUsers: () => void
  defaultLicenseType: string
  resendUserInvite: (payload: {
    userId: string[]
    currentOrgUnit: string
  }) => void
  isSupplierAdmin: boolean
  isBuyerAdmin: boolean
  currentOrgUnit: string
} & RouteComponentProps

const title = <FormattedMessage id='App.Users' defaultMessage='Users' />

const UserListContainer = (props:Props) => {
  const { openUserDialog } = props
  return (
    <Page title={title}>
      <Fragment>
        <UserList {...props} />
        <div className='fixed bottom-1 bottom-2-ns tr w-100 mw8 pr6-ns pr4'>
          <Fab onClick={openUserDialog} color='primary' aria-label='add user'>
            <ContentAdd color='inherit' />
          </Fab>
        </div>
        <AddUser />
      </Fragment>
    </Page>
  )
}

export default connect(
  (state: RootState) => ({
    users: usersSelectors.getColleaguesExcludeConciergeForAdmin(state) || List([]),
    isClientApp: !!settingsSelectors.getBuyerType(state),
    currentUser: sessionSelectors.getUser(state),
    isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
    bulkUsersSelected: editUserSelectors.getBulkUsersSelected(state)?.toJS(),
    defaultLicenseType: settingsSelectors.getDefaultLicenseType(state),
    isSupplierAdmin: sessionSelectors.userHasRole(state, 'supplierAdmin'),
    isBuyerAdmin: sessionSelectors.userHasRole(state, 'buyerAdmin'),
    currentOrgUnit: sessionSelectors.getOrgUnitId(state)
  }),
  {
    openUserDialog,
    selectBulkUsers,
    resendUserInvite
  }
)(withRouter(UserListContainer))
