import React, { useMemo, useState } from 'react'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ActionInfoOutline from '@material-ui/icons/InfoOutlined'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import moment from 'moment'

import { DataType, SpendType } from 'supplier/Insight/containers/Report'
import {
  isBuyerOverAllocated,
  getAllocatedSuppliersByStagingId
} from '../../store/tier2Selectors'
import { notify } from 'shared/actions'
import { updateAllocation } from '../../store/actions'
import numberFormat from 'shared/utils/numberFormat'
import RootState from 'shared/models/RootState'
import sessionSelectors from 'shared/selectors/sessionSelectors'

import Alert from 'shared/components/Alert'
import Button from 'shared/components/Button'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import ExternalLink from 'shared/components/ExternalLink'
import Text from 'shared/components/Text'
import Tooltip from 'shared/components/Tooltip'

import AddDiverseSupplierSpendV2, {
  DiverseSupplier
} from './AddDiverseSupplierSpendV2'
import DirectSpendAggregateLine from './DirectSpendAggregateLine'
import SpendAllocationItem from './SpendAllocationItem'
import SpendAllocationPreview from './SpendAllocationPreview'

export type DiverseSupplierSpendAllocation = {
  allocation?: number
  id: string
  name: string
  spend: number
}

export type SpendAllocationProps = {
  aggregateLoad?: string
  attestedBy?: string
  buyerId: string
  communityPlanMembershipId: string
  dataType?: DataType
  diverseSupplierAllocations?: DiverseSupplierSpendAllocation[]
  isDirFileIndAgg?: { indirectDiverseTotalSpend: number }
  onShareSpendAllocation: (spendAllocation: {
    communityPlanMembershipId: string
    quarter: number
    year: number
    overallAllocation?: number
    diverseSupplierAllocations: DiverseSupplierSpendAllocation[]
    attestedBy: string
    attestedDate: string
    buyerId: string
    stagingId: string | Array<string>
  }) => void
  overallAllocation?: number
  quarter: number
  spendType?: SpendType
  stagingId: string | Array<string>
  totalDiverseSpend: number
  year: number
}

const messages = defineMessages({
  spendSuccess: {
    id: 'SpendAllocation.SpendSuccess',
    defaultMessage: 'Diverse spend shared successfully'
  }
})

const SpendAllocation = ({
  aggregateLoad,
  attestedBy,
  buyerId,
  communityPlanMembershipId,
  dataType,
  diverseSupplierAllocations,
  isDirFileIndAgg,
  onShareSpendAllocation,
  overallAllocation,
  quarter,
  spendType,
  stagingId,
  totalDiverseSpend,
  year
}: SpendAllocationProps) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isAttest, setIsAttest] = useState<boolean>(false)
  const [showAttest, setShowAttest] = useState<boolean>(false)
  const [showOverAllocated, setShowOverAllocated] = useState<boolean>(false)

  const userId = useSelector(sessionSelectors.getUserId)

  // check if buyer over allocated for this quarter/stagingId
  const isOverAllocated = useSelector((state: RootState) =>
    isBuyerOverAllocated(state, stagingId, buyerId)
  )
  const isIndirectAggregate =
    spendType === 'indirect' && dataType === 'aggregate'

  // which suppliers already have shared allocation with any buyers
  // extra filter for indirectAgg due to use with directFile
  const allocatedSuppliers = useSelector((state: RootState) =>
    getAllocatedSuppliersByStagingId(state, stagingId, isIndirectAggregate)
  )

  const inputAriaLabel = `${year ? year : 'year'} - Quarter ${
    quarter ? quarter : ''
  } diverse spend indirect allocation`

  const spendStagingId =
    typeof stagingId === 'string'
      ? stagingId
      : stagingId?.find(id => id !== aggregateLoad)

  const isDirectAndFile =
    !isDirFileIndAgg && spendType === 'direct' && dataType === 'file'

  const isDirectAndAggregate =
    spendType === 'direct' && dataType === 'aggregate'

  const initialAllocationValues = useMemo(() => {
    return diverseSupplierAllocations?.filter(ds => !!ds.allocation)
  }, [diverseSupplierAllocations])

  const [allocationValues, setAllocationValues] = useState<
    DiverseSupplierSpendAllocation[]
  >(initialAllocationValues || [])

  const initialDiverseSuppliersToSuggest = useMemo(() => {
    if (isDirectAndFile || isDirectAndAggregate || !!isDirFileIndAgg) {
      return diverseSupplierAllocations
    }
    return diverseSupplierAllocations?.filter(ds => !ds.allocation)
  }, [
    isDirectAndFile,
    isDirectAndAggregate,
    diverseSupplierAllocations,
    isDirFileIndAgg
  ])
  const [diverseSuppliersToSuggest, setDiverseSuppliersToSuggest] = useState(
    initialDiverseSuppliersToSuggest || []
  )

  const [overallAllocationValue, setOverallAllocationValue] = useState<
    number | undefined
  >(overallAllocation)

  const directAllocatedValues = useMemo(() => {
    // calculate total the direct spend assigned to the buyer, and use it for leftover indirect spend.
    // also includes the direct spend assigned to other buyers if the suppliers are not in the current buyer.
    return (
      diverseSupplierAllocations
        ?.filter(
          ds =>
            allocatedSuppliers.includes(ds.id) ||
            !!allocationValues.map(s => s.id).includes(ds.id)
        )
        .reduce(
          (directAllocatedValues, supplier) =>
            directAllocatedValues + supplier.spend,
          0
        ) || 0
    )
  }, [allocationValues, allocatedSuppliers, diverseSupplierAllocations])

  const { totalSelectedDiverseSpend, totalAllocatedDiverseSpend } = useMemo(
    () =>
      allocationValues.reduce(
        (agg, supplier) => ({
          totalSelectedDiverseSpend:
            agg.totalSelectedDiverseSpend + supplier.spend,
          totalAllocatedDiverseSpend:
            agg.totalAllocatedDiverseSpend +
            supplier.spend * ((supplier.allocation || 0) / 100)
        }),
        {
          totalSelectedDiverseSpend: 0,
          totalAllocatedDiverseSpend: 0
        }
      ),
    [allocationValues]
  )

  // overallSpendAmount (indirect), totalDiverseSpend takes away all the direct spend across all buyers for the same stagingId.
  const overallSpendAmount =
    totalDiverseSpend -
    (directAllocatedValues > totalSelectedDiverseSpend
      ? directAllocatedValues
      : totalSelectedDiverseSpend)

  const spendAmountToDisplay = isDirFileIndAgg
    ? isDirFileIndAgg.indirectDiverseTotalSpend
    : overallSpendAmount

  const overallAllocatedSpend =
    overallSpendAmount * ((overallAllocationValue || 0) / 100)

  const canSubmitForm =
    (!attestedBy && !isEditing) || (!!attestedBy && isEditing)

  const readOnly = !canSubmitForm || isDirectAndFile || !!isDirFileIndAgg
  const hasNoAllocations = allocationValues.every(s => !s.allocation)

  const disableShare =
    canSubmitForm &&
    ((isDirectAndAggregate && hasNoAllocations && totalDiverseSpend !== 0) ||
      ((spendType === 'indirect' || spendType === 'fullSpend') &&
        overallAllocationValue === undefined &&
        hasNoAllocations))

  const showCloseIcon = canSubmitForm && !isDirectAndFile && !isDirFileIndAgg

  const handleShareConfirm = () => {
    setIsEditing(false)
    setShowAttest(false)
    onShareSpendAllocation({
      attestedBy: userId,
      attestedDate: moment().toISOString(),
      buyerId,
      communityPlanMembershipId: communityPlanMembershipId,
      diverseSupplierAllocations: allocationValues,
      overallAllocation: overallAllocationValue,
      quarter,
      year,
      stagingId
    })
    dispatch(notify({ message: intl.formatMessage(messages.spendSuccess) }))
  }

  const handleShareCancel = () => {
    setShowAttest(false)
    setIsAttest(false)
  }

  const formOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // check for over allocation
    if (canSubmitForm && !!isOverAllocated?.size) {
      setShowOverAllocated(true)
    } else {
      setShowOverAllocated(false)
      if (canSubmitForm) {
        setShowAttest(true)
      } else {
        setIsEditing(true)
      }
    }
  }

  const handleCloseIconClick = (
    supplierAllocation: DiverseSupplierSpendAllocation
  ) => {
    setDiverseSuppliersToSuggest(oldValue => [...oldValue, supplierAllocation])
    setAllocationValues(oldAllocations =>
      oldAllocations.filter(item => item.id !== supplierAllocation.id)
    )
    dispatch(
      updateAllocation({
        stagingId,
        supplierOrgUnitId: supplierAllocation.id,
        buyerOrgUnitId: buyerId,
        allocation: 0
      })
    )
  }

  const handleAllocationChange = (
    newAllocationValue: number | undefined,
    supplierAllocation: DiverseSupplierSpendAllocation
  ) =>
    setAllocationValues(oldAllocations =>
      oldAllocations.map(item =>
        item.id === supplierAllocation.id
          ? {
              ...supplierAllocation,
              allocation: newAllocationValue
            }
          : item
      )
    )

  const handleSuggestedSupplierClick = (
    newAllocationValue: DiverseSupplier
  ) => {
    setDiverseSuppliersToSuggest(oldValue =>
      oldValue.filter(val => val.id !== newAllocationValue.id)
    )
    setAllocationValues(oldAllocationValues => [
      ...oldAllocationValues,
      newAllocationValue
    ])
  }

  return (
    <>
      <form className='mt4' onSubmit={formOnSubmit}>
        {!!isOverAllocated?.size && showOverAllocated && (
          <Alert>
            <FormattedMessage
              id='SpendAllocation.OverAllocatedMessage'
              defaultMessage='Spend allocation cannot exceed 100%.'
            />
          </Alert>
        )}
        <div className='flex mt3 fw6 f5 mid-gray items-center mt1 pa2 bg-near-white'>
          <h3 className='flex-auto mb0 mt0 fw6 f5'>
            <FormattedMessage
              id='SpendAllocation.DiverseSpendTitle'
              defaultMessage='{year} - Q{quarter} Diverse Spend'
              values={{
                year: year,
                quarter: quarter
              }}
            />
          </h3>
          <span>${numberFormat(totalDiverseSpend, 2)}</span>
        </div>
        <hr />

        <SpendAllocationItem
          supplierOrgUnitId={!isDirectAndFile ? 'overallAllocation' : undefined}
          stagingId={aggregateLoad || spendStagingId}
          buyerOrgUnitId={buyerId}
          showOverAllocate={showOverAllocated}
          allocation={overallAllocationValue}
          greyedOut={isDirectAndAggregate || isDirectAndFile}
          inputAriaLabel={inputAriaLabel}
          onChangeAllocation={setOverallAllocationValue}
          readOnly={!canSubmitForm}
          spendAmount={spendAmountToDisplay}
          title={
            <div className='fw6 flex items-center'>
              <FormattedMessage
                id='SpendAllocation.IndirectSpend'
                defaultMessage='Indirect Spend'
              />
              <Tooltip
                title={
                  <FormattedMessage
                    id='SpendAllocation.IndirectTooltip'
                    defaultMessage='This is the total diverse spend still available for allocation in the quarter after removing any direct spend.'
                  />
                }
              >
                <ActionInfoOutline
                  aria-hidden={false}
                  className='ml2'
                  fontSize='small'
                  aria-label='Indirect Spend Info'
                />
              </Tooltip>
            </div>
          }
        />

        {diverseSupplierAllocations &&
          !isIndirectAggregate &&
          (isDirectAndAggregate ? (
            <div className='w-100 mv3'>
              <DirectSpendAggregateLine spend={totalDiverseSpend} />
            </div>
          ) : (
            <div className='w-70 mv3'>
              <AddDiverseSupplierSpendV2
                readOnly={readOnly}
                diverseSuppliers={diverseSuppliersToSuggest}
                onClickSuggestedSupplier={handleSuggestedSupplierClick}
              />
            </div>
          ))}

        {!isDirectAndAggregate &&
          allocationValues.map(supplierAllocation => (
            <SpendAllocationItem
              supplierOrgUnitId={supplierAllocation.id}
              stagingId={spendStagingId}
              buyerOrgUnitId={buyerId}
              showOverAllocate={showOverAllocated}
              allocation={supplierAllocation.allocation}
              inputAriaLabel={`${inputAriaLabel} for ${supplierAllocation.name}`}
              key={supplierAllocation.id}
              readOnly={readOnly}
              required
              spendAmount={supplierAllocation.spend}
              onChangeAllocation={newAllocationValue =>
                handleAllocationChange(newAllocationValue, supplierAllocation)
              }
              title={
                <>
                  {showCloseIcon && (
                    <IconButton
                      onClick={() => handleCloseIconClick(supplierAllocation)}
                      aria-label={`close ${supplierAllocation.name}`}
                    >
                      <CloseIcon aria-label='Close icon' fontSize='small' />
                    </IconButton>
                  )}
                  <span className='ml2'>{supplierAllocation.name}</span>
                </>
              }
            />
          ))}

        <div className='flex items-center justify-end mt3 f5 fw6 mid-gray'>
          <FormattedMessage
            id='SpendAllocation.TotalShared'
            defaultMessage='Total Shared: {sharedSpendAmount}'
            values={{
              sharedSpendAmount: `$${numberFormat(
                totalAllocatedDiverseSpend + overallAllocatedSpend,
                2
              )}`
            }}
          />
        </div>

        <div className='flex items-center mt3'>
          <div className='flex-auto'></div>
          <div className='w-30 flex items-center justify-end'>
            <SpendAllocationPreview
              year={year}
              quarter={quarter}
              overallAllocation={overallAllocationValue}
              diverseSupplierAllocations={allocationValues}
              stagingId={stagingId}
            />
            <Button
              type='submit'
              className='ml3'
              disabled={disableShare}
              label={
                canSubmitForm ? (
                  <FormattedMessage
                    id='SpendAllocation.Share'
                    defaultMessage='Share'
                  />
                ) : (
                  <FormattedMessage
                    id='SpendAllocation.Edit'
                    defaultMessage='Edit'
                  />
                )
              }
            />
          </div>
        </div>
      </form>
      <ConfirmationDialog
        open={showAttest}
        onClose={handleShareCancel}
        disabled={!isAttest}
        onConfirm={handleShareConfirm}
      >
        <Text>
          <input
            type='checkbox'
            className='mr2 v-mid'
            checked={isAttest}
            onChange={() => setIsAttest(() => !isAttest)}
          />
          <FormattedMessage
            id='SpendAllocation.Reaffirms'
            defaultMessage='Supplier reaffirms its compliance with the {terms}, including the warranty of accuracy of all data provided.'
            values={{
              terms: (
                <ExternalLink href={`https://tealbook.com/terms`}>
                  <FormattedMessage
                    id='UserProfile.termsOfService'
                    defaultMessage='Terms of Service'
                  />
                </ExternalLink>
              )
            }}
          />
        </Text>
      </ConfirmationDialog>
    </>
  )
}

export default SpendAllocation
