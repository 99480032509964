import { call, put } from 'redux-saga/effects'
import staging from 'shared/utils/api/staging'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { push } from 'connected-react-router'
import { notify } from 'shared/actions'
import { List, OrderedMap, RecordOf } from 'immutable'
import { UploadHistoryType } from 'supplier/Insight/containers/LoadPageContainer/LoadPageContainer'

type Action = {
  payload: {
    activeQuarter: string
    aggregate?: boolean
    buyersImpacted: any
    listType: string
    notifyAfterDelete: any
    stagingId: string
    uploadHistory: OrderedMap<string, List<RecordOf<UploadHistoryType>>>
  }
}

export function* removeSpendSaga(action: Action) {
  const {
    activeQuarter,
    aggregate,
    buyersImpacted,
    listType,
    notifyAfterDelete,
    stagingId,
    uploadHistory
  } = action.payload
  const response = yield call(staging.removeSupplierSpend, {
    stagingId,
    buyersImpacted,
    listType
  })

  if (uploadHistory && activeQuarter) {
    const availableQuarters = uploadHistory
      .keySeq()
      .filter(key => key !== activeQuarter)
    const getActiveQuarter = uploadHistory
      .get(activeQuarter)
      ?.filter(staging => staging.get('id') !== stagingId)

    // if staging rows left in activeQuarter from uploadHistory make that active tab, else if other years available in uploadHistory make the latest year/quarter the active tab
    if (Array.isArray(response) && response.length) {
      if (getActiveQuarter?.size) {
        yield put(push(`?show=${activeQuarter}`))
      } else if (availableQuarters.size) {
        yield put(push(`?show=${availableQuarters.first()}`))
      }
    }
  }

  if (response && notifyAfterDelete)
    yield put(
      notify({
        message: `Your ${
          aggregate ? 'aggregate spend' : 'file'
        } information was successfully deleted`
      })
    )

  return response
}

export default createApiRequestSaga(removeSpendSaga)
