import React, { ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleEsgReportQuestion } from '../../store/actions'
import insightsSelectors from '../../store/insightsSelectors'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import IconButton from '@material-ui/core/IconButton'
import RootState from 'shared/models/RootState'
import esgSurveyQuestions from 'shared/utils/data/defineEsgSurveyQuestions'
import { useIntl, FormattedMessage } from 'react-intl'
import ReportQuestionStat from './ReportQuestionStat'

type Props = {
  questionNumber?: number
  label?: string | ReactElement<FormattedMessage>
  pageId: string
  questionId: string
  subQuestionIds?: Array<string>
  showSubQuestions?: boolean
}

const ReportQuestion = (props: Props) => {
  const {
    questionNumber,
    label,
    pageId,
    questionId,
    subQuestionIds,
    showSubQuestions
  } = props

  const dispatch = useDispatch()
  const intl = useIntl()
  const isOpen = useSelector((state: RootState) =>
    insightsSelectors.isEsgReportQuestionOpen(state, questionId)
  )
  const stat = useSelector((state: RootState) =>
    insightsSelectors.getEsgReportQuestionStat(state, pageId, questionId)
  )
  const handleExpandClick = () => {
    dispatch(toggleEsgReportQuestion(questionId))
  }

  return (
    <div className='mv3'>
      <div className='flex items-center justify-between bg-white br1 pv2 ph3 ba b--black-10'>
        {label && (
          <label className='f5 lh-copy'>
            {questionNumber && `Q${questionNumber}.`} {label}
          </label>
        )}
        {!label && (
          <label className='f5 lh-copy'>
            {questionNumber && `Q${questionNumber}.`}{' '}
            {esgSurveyQuestions[`${pageId}-${questionId}`]
              ? intl.formatMessage(
                  esgSurveyQuestions[`${pageId}-${questionId}`],
                  {
                    b: b => <span className='fw8 underline'>{b}</span>
                  }
                )
              : questionId}
          </label>
        )}
        <IconButton onClick={handleExpandClick} aria-label='expand toggle'>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>
      {isOpen && (
        <div className='ph3 pv2'>
          <ReportQuestionStat pageId={pageId} questionId={questionId} />
          {((!!stat?.yes && subQuestionIds) ||
            (showSubQuestions && subQuestionIds)) &&
            subQuestionIds.map(subQuestionId => (
              <ReportQuestionStat
                key={subQuestionId}
                label={
                  esgSurveyQuestions[`${pageId}-${subQuestionId}`]
                    ? intl.formatMessage(
                        esgSurveyQuestions[`${pageId}-${subQuestionId}`],
                        {
                          b: b => <span className='fw8 underline'>{b}</span>
                        }
                      )
                    : subQuestionId
                }
                pageId={pageId}
                questionId={subQuestionId}
                {...stat}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default ReportQuestion
