import { call, put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { push } from 'connected-react-router'
import diversityPaths from 'supplier/Insight/diversityPaths'
import { loadStagingHistory, notify } from 'shared/actions'
import staging from 'shared/utils/api/staging'

export function* addTier2BuyerSaga(action) {
  const { year, quarter } = action.payload
  let response = yield call(staging.addBuyerTier2, action.payload)
  response = { ...response.diversityReference, loadId: response.loadId }
  yield put(loadStagingHistory({ listType: 'supplierTier2Load' }))
  yield put(push(`${diversityPaths.diversityLoad}?show=${year}-${quarter}`))
  yield put(notify({ message: 'Your diverse spend was successfully saved' }))

  return yield response
}

export default createApiRequestSaga(addTier2BuyerSaga)
