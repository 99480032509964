import React, { Component } from 'react'

/**
 * Creates an icon which goes to a link when clicked
 */

type Props = {
  href: string
  imgSrc: string
  imgAlt?: string
}

class ExternalImageLink extends Component<Props> {
  render() {
    const { href, imgSrc, imgAlt } = this.props

    return (
      <a
        href={href.match(/^https?:\/\//i) ? href : `http://${href}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          src={imgSrc}
          alt={imgAlt ? imgAlt : ''}
          className='dib pointer v-mid pr2'
          style={{ width: 20 }}
        />
      </a>
    )
  }
}

export default ExternalImageLink
