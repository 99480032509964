import React, { ReactChild } from 'react'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import Paper from 'shared/components/Paper'

type Props = {
  onHandleButtonClick: () => void
  children: ReactChild
}

const CallToAction = (props: Props) => {
  const { children, onHandleButtonClick } = props
  return (
    <Paper noPadding className='mb3'>
      <div className='pa3 flex justify-between items-center'>
        <div className='flex items-center'>
          <button
            className='f7 fw6 ml2 flex items-center bn bg-transparent pv1 pl1'
            onClick={onHandleButtonClick}
          >
            <AddCircleOutline color='primary' className='mr2' />
            {children}
          </button>
        </div>
        <Button autoSize onClick={onHandleButtonClick}>
          <FormattedMessage
            id='CallToAction.GetStarted'
            defaultMessage={'Get Started'}
          />
        </Button>
      </div>
    </Paper>
  )
}

export default CallToAction
