import React, { Component, Fragment } from 'react'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import Badge from 'shared/components/Badge'
import Tooltip from 'shared/components/Tooltip'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import iconTriangleUp from 'shared/assets/icons/triangle-up.svg'
import iconTriangleDown from 'shared/assets/icons/triangle-down.svg'
import Link from 'shared/components/Link'

const triangleDown = {
  imgUrl: iconTriangleDown,
  alt: 'Number of views this month less than number of views last month'
}

const triangleUp = {
  imgUrl: iconTriangleUp,
  alt: 'Number of views this month greater than number of views last month'
}

const useStyles = {
  focus: {
    '&:focus-visible': {
      outline: 'auto',
      outlineColor: 'black'
    }
  }
}

type Props = {
  profileUrl: string
  profilePictureUrl: string
  name: string
  viewsThisMonth: number
  viewsLastMonth: number
  place: number
  classes: any
}

const componentStyles = theme => useStyles

class ActiveSupplier extends Component<Props> {
  render() {
    const {
      profileUrl,
      profilePictureUrl,
      name,
      viewsThisMonth,
      viewsLastMonth,
      place,
      classes
    } = this.props

    const triangle = viewsThisMonth > viewsLastMonth ? triangleUp : triangleDown

    return (
      <li>
        <Link to={profileUrl} className={classNames(classes.focus)}>
          <div className='dt w-100'>
            <div className='dtc v-mid pr3 w2'>
              <Badge place={place} />
            </div>
            <div className='dtc'>
              <ListItem
                role=''
                leftAvatar={
                  <OrgLogo url={profilePictureUrl} name={name} square />
                }
                primaryText={name}
                secondaryText={
                  <Fragment>
                    <span>{viewsThisMonth}</span>&nbsp;
                    <span>
                      <FormattedMessage
                        id='ActiveSupplier.Views'
                        defaultMessage='Views'
                      />
                    </span>
                    <Tooltip
                      title={
                        <FormattedMessage
                          id='ActiveSupplier.ViewsLasMonth'
                          defaultMessage='{viewsLastMonth} views last month'
                          values={{ viewsLastMonth }}
                        />
                      }
                      tabIndex={0}
                      className={classNames(classes.focus)}
                    >
                      <img
                        src={triangle.imgUrl}
                        alt={triangle.alt}
                        style={{ verticalAlign: 'middle', marginLeft: '5px' }}
                      />
                    </Tooltip>
                  </Fragment>
                }
              />
            </div>
          </div>
        </Link>
      </li>
    )
  }
}

export default withStyles(componentStyles)(ActiveSupplier)
