import fileToUrl from './fileToUrl'
import mapValues from 'lodash.mapvalues'
import { productLevelCertAgency } from 'shared/utils/api/insight/insight'

export const parseSupplierRelationship = relationship => {
  const {
    supplierCollaboration: { pitchDeckFile, ...restCollaboration },
    buyerCollaboration: {
      certificationValidation = [],
      ...restBuyerCollaboration
    },
    ...rest
  } = relationship
  const pitchDeckUrl = fileToUrl(pitchDeckFile)
  const pitchDeckFileName = pitchDeckFile?.fileName
  const parseCertificationValidation = certificationValidation
  // remove product level tealbook validation
  .filter(
    validation => {
      return !(
        validation.userId === 'User-5157071787917312-5629499534213120-4797883672952832' && 
        productLevelCertAgency.includes(validation.certAgency)
      )
    }
  )
  .reduce(
    (result, validation) => {
      if (
        !result[
          `${validation.category}-${
            validation.subCategory
          }-${validation.timeStamp || ''}`
        ]
      ) {
        result[
          `${validation.category}-${
            validation.subCategory
          }-${validation.timeStamp || ''}`
        ] = [validation]
      } else {
        result[
          `${validation.category}-${
            validation.subCategory
          }-${validation.timeStamp || ''}`
        ].push(validation)
      }
      return result
    },
    {}
  )

  return {
    supplierCollaboration: {
      pitchDeckUrl,
      pitchDeckFileName,
      ...restCollaboration
    },
    buyerCollaboration: {
      certificationValidation: mapValues(
        parseCertificationValidation,
        value => {
          return value.sort((v1, v2) => new Date(v2.date) - new Date(v1.date))
        }
      ),
      ...restBuyerCollaboration
    },
    ...rest
  }
}

function parseSupplierRelationships(relationships) {
  const byId = relationships.reduce((result, relationship) => {
    result[relationship.id] = parseSupplierRelationship(relationship)
    return result
  }, {})

  const orgsById = relationships.reduce(
    (orgs, rel) =>
      rel.expanded
        ? {
            ...rel.expanded.OrgUnit,
            ...orgs
          }
        : orgs,
    {}
  )

  return {
    byId,
    orgsById
  }
}

export default parseSupplierRelationships
