import React from 'react'
import Text from 'shared/components/Text'
import { AttachmentType } from '../../reducers/surveyReducer/surveyReducer'
import { RecordOf } from 'immutable'
import startCase from 'lodash.startcase'
import downloadAttachment from 'shared/utils/fileDownload/fileDownload'
import useThemeColors from 'shared/utils/useThemeColors'

type Props = {
  attachment: RecordOf<AttachmentType>
}

const Attachment = ({ attachment }: Props) => {
  const colors = useThemeColors()
  return (
    attachment && (
      <div className='mv2 truncate'>
        {attachment.get('docType') && (
          <Text className='fw6' style={{ color: 'black' }}>
            {startCase(attachment.get('docType'))}
          </Text>
        )}
        <button
          className='bn bg-transparent pointer'
          onClick={() =>
            downloadAttachment(
              attachment.get('fileName'),
              `survey/surveyResponseDetail/attachment`
            )
          }
        >
          <Text
            className={`truncate ${colors.primaryText} ${colors.primaryHover} f7 fw4 no-underline `}
          >
            {attachment.get('description')}
          </Text>
        </button>
      </div>
    )
  )
}

export default Attachment
