import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import paths from '../../routes/paths'
import FileUploadMainPage from './FileUploadMainPage'
import FileUploadStep1 from './FileUploadStep1'
import FileUploadStep2 from './FileUploadStep2'
import FileUploadStep3Container from 'admin/containers/FileUploadStep3Container'

export type BusinessContextAnswersType = {
  deleteSpendData: string
  dateRangeForDeletion?: string
  modified: boolean
}

const FileUploadTab = () => {
  const [businessContextAnswers, setBusinessContextAnswers] = useState<
    BusinessContextAnswersType
  >({
    deleteSpendData: 'no',
    dateRangeForDeletion: '',
    modified: false
  })

  return (
    <Switch>
      <Route path={paths.fileUpload} exact component={FileUploadMainPage} />
      <Route path={paths.fileUploadStep1}>
        <FileUploadStep1 />
      </Route>
      <Route path={paths.fileUploadStep2}>
        <FileUploadStep2 onSaveAnswers={setBusinessContextAnswers} />
      </Route>
      <Route path={paths.fileUploadLastStep}>
        <FileUploadStep3Container
          businessContextAnswers={businessContextAnswers}
        />
      </Route>
      <Redirect to={paths.fileUploadStep1} />
    </Switch>
  )
}

export default FileUploadTab
