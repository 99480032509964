import React from 'react'
import NavBar from 'shared/components/NavBar'
import NavTab from 'shared/components/NavTab'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import OpenSuppliers from './OpenSuppliers'
import CompletedSuppliersSummary from 'buyer/Insights/containers/CompletedSuppliersSummary'

const SupplierRequests = () => {
  const location = useLocation()
  const showComplete = location.search !== '?show=open'

  return (
    <div className='pv3'>
      <NavBar style={{ paddingBottom: 0 }}>
        <li>
          <NavTab
            to={`${location.pathname}?show=complete`}
            isActive={(match, loc) =>
              !loc.search || loc.search === `?show=complete`
            }
            backgroundColor='white'
          >
            <FormattedMessage
              id='SupplierRequests.SustainabilityComplete'
              defaultMessage='Supplier Survey Completion Summary'
            />
          </NavTab>
        </li>
        <li>
          <NavTab
            to={`${location.pathname}?show=open`}
            isActive={(match, loc) => loc.search === `?show=open`}
            backgroundColor='white'
          >
            <FormattedMessage
              id='SupplierRequests.SustainabilityOpen'
              defaultMessage='Sustainability Survey Open Requests'
            />
          </NavTab>
        </li>
      </NavBar>
      <div className='b--black-10 ba pa3' style={{ marginTop: -1 }}>
        {showComplete && <CompletedSuppliersSummary />}
        {!showComplete && <OpenSuppliers />}
      </div>
    </div>
  )
}

export default SupplierRequests
