import { call, put, select } from 'redux-saga/effects'
import loader from 'shared/utils/api/loader'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { push } from 'connected-react-router'
import routingSelector from 'shared/selectors/routingSelectors'

export function* uploadSpendSaga(action) {
  let response = yield call(loader.uploadSpend, action.payload)
  const activeQuarter = yield select(routingSelector.getSearchParam, 'show')
  if (activeQuarter !== `${action.payload.year}-${action.payload.quarter}`) {
    yield put(push(`?show=${action.payload.year}-${action.payload.quarter}`))
  }
  return yield response
}

export default createApiRequestSaga(uploadSpendSaga)
