import React, { ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'

import Label from 'shared/components/Label'
import Select from 'shared/components/Select'

type Props = {
  handleChangeQuarter: (value: string) => void
  quarter: string
}

export const QuarterSelect = ({ handleChangeQuarter, quarter }: Props) => (
  <>
    <Label htmlFor='quarter'>
      <FormattedMessage
        id='UploadSpendDetail.Quarter'
        defaultMessage='Quarter'
      />
    </Label>
    <Select
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        handleChangeQuarter(e.currentTarget.value)
      }
      value={quarter}
      fullWidth
      name='quarter'
    >
      <FormattedMessage
        id='UploadSpendDetail.SelectQuarter'
        defaultMessage='Select quarter'
      >
        {msg => <option value=''>{msg}</option>}
      </FormattedMessage>
      <option value='1'>Q1</option>
      <option value='2'>Q2</option>
      <option value='3'>Q3</option>
      <option value='4'>Q4</option>
    </Select>
  </>
)
