import React, { ChangeEvent, useState } from 'react'
import Button from 'shared/components/Button'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import { useSelector, useDispatch } from 'react-redux'
import surveySelectors from '../../selectors/surveySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import {
  closeVerifyESGAttachmentDialog,
  verifyESGAttachment
} from '../../actions'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Label from 'shared/components/Label'
import TextArea from 'shared/components/TextArea'
import Input from 'shared/components/Input'
import DatePicker from 'shared/components/DatePicker'
import { RecordOf } from 'immutable'
import {
  AttachmentType,
  CertType
} from 'supplier/Survey/reducers/surveyReducer/surveyReducer'
import {
  addCertificationValidation
} from '../../../SupplierProfile/actions'
import { Moment } from 'moment'

const messages = defineMessages({
  yes: {
    id: 'VerifyAttachmentDialog.yes',
    defaultMessage: 'Yes, it does.'
  },
  no: {
    id: 'VerifyAttachmentDialog.no',
    defaultMessage: 'No, it does not.'
  }
})

type Props = {
  surveyResponseDetailId: string
  attachmentType: 'cert' | 'doc'
  attachment: RecordOf<AttachmentType> | RecordOf<CertType>
}
const VerifyAttachmentDialog = ({
  surveyResponseDetailId,
  attachmentType,
  attachment
}: Props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const userId = useSelector(sessionSelectors.getUserId)
  const isOpen = useSelector(surveySelectors.isVerifyAttachmentDialogOpen)

  const [confirmed, setConfirmed] = useState<'true' | 'false' | ''>('')
  const [note, setNote] = useState<string>('')
  const [certificationNumber, setCertificationNumber] = useState<string>('')
  const [validationExpires, setValidationExpires] = useState<Date | string>('')

  let key
  if (attachmentType === 'cert') {
    key = `${surveyResponseDetailId}-${(attachment as RecordOf<CertType>).get('timeStamp')}`
  } else {
    key = `${surveyResponseDetailId}-${(attachment as RecordOf<AttachmentType>).get('docType') || ''}-${(attachment as RecordOf<AttachmentType>).get('fileName')}`
  }

  const handleCancelClick = () => {
    setConfirmed('')
    setNote('')
    dispatch(closeVerifyESGAttachmentDialog())
  }

  const handleOkClick = () => {
    const { validations, ...restAttachment } = attachment?.toJS()
    dispatch(
      verifyESGAttachment({
        surveyResponseDetailId,
        attachment: restAttachment,
        validation: {
          orgUnitId,
          userId,
          date: new Date().toISOString(),
          timeStamp: attachment?.get('timeStamp', ''),
          confirmed: confirmed === 'true',
          note,
          validationExpires,
          certificationNumber
        }
      })
    )
    if (attachmentType === 'cert') {
      const validation = { 
        category: attachment?.get('category', ''), 
        subCategory: attachment?.get('subCategory', ''), 
        timeStamp: attachment?.get('timeStamp', ''), 
        confirmed: confirmed === 'true', 
        note,
        validationExpires,
        certificationNumber
      }

      dispatch(
        addCertificationValidation(validation)
      )
    }
    setConfirmed('')
    setNote('')
    setValidationExpires('')
    setCertificationNumber('')
  }

  const handleConfirmedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmed(event.currentTarget.value as 'true' | 'false')
    if (event.currentTarget.value === 'false') {
      setValidationExpires('')
      setCertificationNumber('')
    }
  }

  const handleNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.currentTarget.value)
  }

  const handleCertificateNumberChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCertificationNumber(event.currentTarget.value)
  }

  const handleValidationExpiresChange = (newDate: Moment) => {
    setValidationExpires(newDate.endOf('day').toDate())
  }

  return (
    <>
      <Dialog fullWidth open={isOpen === key} onClose={handleCancelClick}>
        <DialogTitle>
          {attachmentType === 'doc'
            ? <FormattedMessage
              id='VerifyAttachmentDialog.VerifyDocTitle'
              defaultMessage={'Verify a document'}
            />
            : <FormattedMessage
              id='VerifyAttachmentDialog.VerifyCertTitle'
              defaultMessage={'Verify a certification'}
            />
          }
        </DialogTitle>
        <DialogContent>
          <Label>
            <FormattedMessage
              id='VerifyAttachmentDialog.ConfirmedQuestion'
              defaultMessage={
                'Does this document adequately answer the associated question?'
              }
            />
          </Label>
          <RadioGroup
            color='primary'
            aria-label='Confirmed'
            name='confirmed'
            value={confirmed}
            onChange={handleConfirmedChange}
          >
            <FormControlLabel
              control={<Radio color='primary' size='small' value={'true'} />}
              label={intl.formatMessage(messages.yes)}
            />
            <FormControlLabel
              control={<Radio color='primary' size='small' value={'false'} />}
              label={intl.formatMessage(messages.no)}
            />
          </RadioGroup>
          {attachmentType === 'cert' && confirmed === 'true' && <>
            <Label htmlFor='certificationNumber'>
              <FormattedMessage
                id='VerifyAttachmentDialog.CertificateNumber'
                defaultMessage={'Certificate number (optional)'}
              />
            </Label>
            <Input name='certificationNumber' 
              value={certificationNumber} 
              onChange={handleCertificateNumberChange} />
            <Label htmlFor='validationExpires'>
              <FormattedMessage
                id='VerifyAttachmentDialog.ValidationExpires'
                defaultMessage={'Valid until (optional)'}
              />
            </Label>
            <DatePicker name='validationExpires' 
              value={validationExpires} 
              minDate={new Date()} 
              onChange={handleValidationExpiresChange} />
          </>}
          <Label htmlFor='note'>
            <FormattedMessage
              id='VerifyAttachmentDialog.Note'
              defaultMessage={'Note'}
            />
          </Label>
          <TextArea
            aria-label='Note'
            name='note'
            value={note}
            onChange={handleNoteChange}
            maxLength={200}
          />
        </DialogContent>
        <DialogActions>
          <Button autoSize disabled={!confirmed} onClick={handleOkClick}>
            <FormattedMessage id='OK' defaultMessage={'OK'} />
          </Button>
          <Button autoSize secondary onClick={handleCancelClick}>
            <FormattedMessage id='Cancel' defaultMessage={'Cancel'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default VerifyAttachmentDialog
