import moment from 'moment'
import OrgUnit from '../models/OrgUnit'
import User from '../models/User'

/**
 * Event Naming Conventions
 * follow object-action framework
 * Proper Case on Event, camelCase on properties
 * eg. analytics.track('Supplier Profile Viewed', { orgUnitID: 'OrgUnit-123-456', name: 'ACME Corp' })
 * every tracked event must have orgUnitId to enable Group Analytics per TOR-846
 */

const w: any = window

const identifyUser = (user: User, orgUnit: OrgUnit) => {
  const isTealbot = user.roles?.some(r => r === 'tealbot')

  let userType = 'unknown'
  if (isTealbot) {
    userType = 'concierge'
  } else if (user.roles?.some(r => r === 'buyer')) {
    userType = 'buyer'
  } else if (user.roles?.some(r => r === 'supplier')) {
    userType = 'supplier'
  }

  const isInternalUser =
    isTealbot ||
    user.email?.endsWith('@tealbook.com') ||
    user.email?.endsWith('@infinit-o.com')

  const isAdmin =
    isTealbot ||
    user.roles?.some(r => r === 'buyerAdmin' || r === 'supplierAdmin')

  // identify user
  if (w.analytics) {
    w.analytics.identify(user.id, {
      firstName: user.firstName,
      lastName: user.lastName,
      title: user.title,
      email: user.email,
      phone: user.mobilePhone,
      isSustainabilityContact: user.isSustainabilityContact,
      userId: user.id,
      userActive: user.active,
      userCreatedAt: moment(user.createdDate).format('YYYY-MM-DD'),
      userFirstLogin: moment(user.firstLogin).format('YYYY-MM-DD'),
      foundTealbook: user.foundTealbook,
      userLicenseType: user.licenseType,
      userLastModified: user.modified?.date,
      disableAllEmails: user.disableAllEmails,
      userRoles: user.roles,
      isInternalUser,
      isAdmin,
      userStatus: user.status,
      userType: userType,
      userAccountVerified: user.firstLogin && user.status === 'Normal',
      orgUnitId: user.orgUnitId,
      orgUnitName: user.orgUnitName
    })
    w.analytics.group(user.orgUnitId, {
      $name: user.orgUnitName,
      orgUnitId: user.orgUnitId,
      description:
        typeof orgUnit.description === 'object'
          ? orgUnit.description.en
          : orgUnit.description,
      domains: orgUnit.domains,
      buyerType: orgUnit.buyerType,
      buyer: orgUnit.buyer && Object.keys(orgUnit.buyer).length === 0,
      orgUnitModified: orgUnit.modified
    })
  }
}

const page = (category?: string, name?: string, properties?: object) => {
  if (w.analytics) {
    w.analytics.page(category, name, properties)
  }
}

const logout = () => {
  if (w.analytics) {
    w.analytics.track('Signed Out')
    w.analytics.reset()
  }
}

const track = (
  event: string,
  properties?: { orgUnitName: string; [key: string]: any }
) => {
  if (w.analytics) {
    w.analytics.track(event, properties)
  }
}

const group = (groupId: string, traits?: object) => {
  if (w.analytics) {
    w.analytics.track(groupId, traits)
  }
}

const delayPage = (ms: number) => (
  category?: string,
  name?: string,
  properties?: object
) => setTimeout(page, ms, category, name, properties)

const delayTrack = (ms: number) => (
  event: string,
  properties?: { orgUnitName: string; [key: string]: any }
) => setTimeout(track, ms, event, properties)

const analytics = {
  identifyUser,
  page,
  delayPage,
  delayTrack,
  track,
  group,
  logout
}

export default analytics
