import React, { Component, Fragment, ChangeEvent } from 'react'
import Button from 'shared/components/Button'
import EditProfileButton from 'shared/components/EditProfileButton'
import PitchDeck from 'shared/components/PitchDeck'
import Label from 'shared/components/Label'
import FileInput from 'shared/components/FileInput'
import TextArea from 'shared/components/TextArea'
import PageSection from 'shared/components/PageSection'
import Divider from 'shared/components/Divider'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import MaterialButton from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { downloadAttachmentToURL } from 'shared/utils/fileDownload'
import downloadAttachment from 'shared/utils/fileDownload/fileDownload'

type Props = {
  description: string
  pitchDeckUrl: string
  onDeletePitchDeck: () => void
  onSave: (params: { description: string; pitchDeckFile?: string }) => void
  pitchDeckFileName?: string
  relationshipId: string
}

type State = {
  editMode: boolean
  description: string
  pitchDeckFile: string
  openDeleteDialog: boolean
  pitchDeckObjectURL: string
}

class CustomizePitchDeck extends Component<Props, State> {
  state: State = {
    editMode: false,
    description: this.props.description,
    pitchDeckFile: '',
    openDeleteDialog: false,
    pitchDeckObjectURL: ''
  }
  componentDidMount() {
    if (this.props.pitchDeckFileName) {
      downloadAttachmentToURL(
        this.props.pitchDeckFileName,
        `supplierRelationships/${this.props.relationshipId}/pitchDeckFile/signedUrl`
      ).then(objectURL => {
        if (objectURL) {
          this.setState({ pitchDeckObjectURL: objectURL })
        }
      })
    }
  }

  handleDownload = () => {
    downloadAttachment(
      this.props.pitchDeckFileName,
      `supplierRelationships/${this.props.relationshipId}/pitchDeckFile`
    )
  }

  handleClickEdit = () => {
    this.setState({
      editMode: true
    })
  }

  handleCancelEdit = () => {
    this.setState({
      editMode: false,
      description: this.props.description,
      pitchDeckFile: ''
    })
  }

  handleChangePitchDeck = (file: any) => {
    this.setState({
      pitchDeckFile: file
    })
  }

  handleChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      description: e.currentTarget.value
    })
  }

  handleSave = () => {
    const { onSave } = this.props
    const { description, pitchDeckFile } = this.state

    onSave({
      description,
      pitchDeckFile: !pitchDeckFile ? undefined : pitchDeckFile
    })

    this.setState({
      pitchDeckFile: '',
      editMode: false
    })
  }

  toggleDeleteDialog = () => {
    this.setState({
      openDeleteDialog: !this.state.openDeleteDialog
    })
  }

  handleDeletePitchDeck = () => {
    this.setState({
      openDeleteDialog: false
    })
    this.props.onDeletePitchDeck()
  }

  render() {
    const { pitchDeckUrl, pitchDeckFileName } = this.props

    const {
      editMode,
      description,
      openDeleteDialog,
      pitchDeckObjectURL
    } = this.state

    return (
      <PageSection
        title={
          <FormattedMessage
            id='CustomizePitchDeck.CustomizePitchTitle'
            defaultMessage='Customize Pitch'
          />
        }
        actions={
          <Fragment>
            {(description || pitchDeckUrl) && !editMode && (
              <EditProfileButton onClick={this.handleClickEdit} />
            )}

            {editMode && (
              <Fragment>
                <Button
                  label={<FormattedMessage id='CancelButton' />}
                  secondary
                  autoSize
                  className='mr3'
                  onClick={this.handleCancelEdit}
                />
                <Button
                  label={
                    <FormattedMessage
                      id='CustomizePitchDeck.SaveButton'
                      defaultMessage='Save'
                    />
                  }
                  autoSize
                  onClick={this.handleSave}
                />
              </Fragment>
            )}
          </Fragment>
        }
      >
        {!description && !pitchDeckUrl && !editMode && (
          <div className='pt3-5 tc'>
            <Button
              color='primary'
              label={
                <FormattedMessage
                  id='CustomizePitchDeck.AddACustomPitchLabel'
                  defaultMessage='Add a custom pitch'
                />
              }
              autoSize
              style={{ margin: 'auto' }}
              onClick={this.handleClickEdit}
            />
          </div>
        )}

        <div
          hidden={(!description && !pitchDeckUrl) || editMode}
          className='pv3-5'
          style={{ width: 645, margin: 'auto' }}
        >
          <Label noPadding>
            <FormattedMessage
              id='CustomizePitchDeck.BasicDescriptionTitle'
              defaultMessage='Basic Description'
            />
          </Label>
          <Text>{description}</Text>
          {pitchDeckUrl && (
            <Fragment>
              <Divider className='mv3' />
              <PitchDeck
                url={pitchDeckObjectURL}
                fileName={pitchDeckFileName}
                onDownload={this.handleDownload}
              />

              <div className='nt5 ml3'>
                <MaterialButton
                  variant='contained'
                  onClick={this.toggleDeleteDialog}
                >
                  <DeleteIcon className='mr2' />
                  <FormattedMessage
                    id='CustomizePitchDeck.RemoveDeck'
                    defaultMessage='Remove Pitch Deck'
                  />
                </MaterialButton>
              </div>
            </Fragment>
          )}
        </div>

        {editMode && (
          <div className='pt3-5 nt3'>
            <Label>
              <FormattedMessage
                id='CustomizePitchDeck.BasicDescriptionTitle'
                defaultMessage='Basic Description'
              />
            </Label>
            <TextArea
              className='mb3'
              name='description'
              onChange={this.handleChangeDescription}
              value={description}
              maxLength={200}
            />

            <Label>
              <FormattedMessage
                id='CustomizePitchDeck.PitchDeckLabel'
                defaultMessage='Pitch Deck'
              />
            </Label>
            <FileInput
              name='pitchDeckFile'
              accept='.pdf,.pptx,.ppt'
              onChange={this.handleChangePitchDeck}
            />
          </div>
        )}

        <Dialog open={openDeleteDialog} onClose={this.toggleDeleteDialog}>
          <DialogTitle>
            <FormattedMessage
              id='CustomizePitchDeck.DeleteDialogTitle'
              defaultMessage='Remove this Pitch Deck File?'
            />
          </DialogTitle>
          <DialogContent>
            <Text className='mt3'>
              <FormattedMessage
                id='CustomizePitchDeck.cantUndo'
                defaultMessage={"You won't be able to undo this."}
              />
            </Text>
          </DialogContent>
          <DialogActions>
            <Button
              label={
                <FormattedMessage
                  id='CustomizePitchDeck.Delete'
                  defaultMessage='Delete'
                />
              }
              autoSize
              onClick={this.handleDeletePitchDeck}
              className='bg-red'
            />
            <Button
              label={<FormattedMessage id='CancelButton' />}
              autoSize
              secondary
              onClick={this.toggleDeleteDialog}
            />
          </DialogActions>
        </Dialog>
      </PageSection>
    )
  }
}

export default CustomizePitchDeck
