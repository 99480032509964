import { fromJS } from 'immutable'
import { requestSuccess } from 'shared/actionTypes'
import * as actionTypes from '../../actionTypes'
import { LOAD_VET, ADD_VET_SUPPLIER } from '../../../buyer/Vets/actionTypes'
import { SUGGEST_SUPPLIER } from '../../../buyer/shared/actionTypes'
import { LOAD_POSTS } from 'posting/actionTypes'
import {
  SEARCH_SUPPLIER,
  GET_ORG_CHILDREN,
  SET_COLLECTION,
  INVITE_SUPPLIER
} from '../../../buyer/Search/actionTypes'
import {
  LOAD_SUPPLIER_PROFILE,
  UPDATE_SUPPLIER_STATUS
} from 'buyer/SupplierProfile/actionTypes'
import { LOAD_COMPANY } from 'supplier/shared/actionTypes'
import {
  ADD_CUSTOMER,
  OPEN_NEW_CUSTOMER_DIALOG,
  CLOSE_NEW_CUSTOMER_DIALOG
} from 'supplier/Company/actionTypes'
import parseSupplier from '../../utils/data/parseSupplier'
import mapValues from 'lodash.mapvalues'
import mapKeys from 'lodash.mapkeys'
import { LOGIN } from 'accounts/actionTypes'
import storageManager from '../../utils/storageManager'

const punchoutOrgsById = storageManager.getItem('punchout')?.cart.reduce(
  (result, item) => ({
    ...result,
    [item.id]: item
  }),
  {}
)

const defaultState = fromJS({
  byId: punchoutOrgsById || {}, // org units indexed by their id
  suggestedIds: [], // list of ordered org unit ids
  suggestQuery: '',
  newCustomerDialogIsOpen: false,
  matchingOrgs: [],
  customerAdded: false,
  loadingSuggestions: false
})

const orgsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case requestSuccess(LOGIN):
      return state.setIn(
        ['byId', action.payload.orgUnit.id],
        fromJS(action.payload.orgUnit)
      )

    case SET_COLLECTION:
      return state.mergeDeepIn(['byId'], fromJS(action.payload))

    case requestSuccess(actionTypes.SUGGEST_ORG_UNIT):
      return action.payload.orgUnitsById
        ? state
            .mergeDeepIn(['byId'], fromJS(action.payload.orgUnitsById))
            .merge({
              suggestedIds: fromJS(action.payload.suggestedIds),
              loadingSuggestions: false
            })
        : state

    case requestSuccess(INVITE_SUPPLIER):
      return action.payload.newOrgUnit
        ? state.setIn(
            ['byId', action.payload.newOrgUnit.id],
            fromJS(parseSupplier(action.payload.newOrgUnit))
          )
        : state

    case requestSuccess(SEARCH_SUPPLIER):
      return action.payload.orgUnitsById
        ? state.update('byId', byId =>
            fromJS(action.payload.orgUnitsById).mergeDeep(byId)
          )
        : state

    case requestSuccess(SUGGEST_SUPPLIER):
    case requestSuccess(GET_ORG_CHILDREN):
    case requestSuccess(LOAD_VET):
    case requestSuccess(actionTypes.LOAD_USER):
    case requestSuccess(LOAD_POSTS):
      return action.payload.orgUnitsById
        ? state.mergeDeepIn(['byId'], fromJS(action.payload.orgUnitsById))
        : state

    case requestSuccess(ADD_VET_SUPPLIER):
      const byId = mapValues(
        mapKeys(action.payload.vetBuyerSupplier, (v, k) => v.supplier.id),
        v => parseSupplier(v.supplier)
      )
      return state.mergeIn(['byId'], fromJS(byId))

    case requestSuccess(LOAD_COMPANY):
      return state.mergeDeepIn(
        ['byId'],
        fromJS(mapValues(action.payload.expanded.OrgUnit, parseSupplier))
      )

    case requestSuccess(LOAD_SUPPLIER_PROFILE):
      const {
        expanded: { Org },
        ...org
      } = action.payload.supplier
      const parentOrg = org.parentOrg && Org && Org[org.parentOrg]

      return state
        .mergeDeepIn(
          ['byId'],
          fromJS({
            ...mapValues(
              action.payload.supplier.expanded.OrgUnit,
              parseSupplier
            ),
            ...mapValues(
              action.payload.supplierRelationships.orgsById,
              parseSupplier
            )
          })
        )
        .setIn(['byId', org.id], fromJS({ ...org, parentOrg }))

    case requestSuccess(actionTypes.LOAD_MESSAGE_TOPICS):
      if (action.payload.orgUnitsById) {
        const byId = state.get('byId')
        const orgUnitsById = fromJS(action.payload.orgUnitsById)
        return state.mergeDeepIn(
          ['byId'],
          orgUnitsById.filter((v, k) => !byId.has(k))
        )
      }

      // don't change state if there are no org units to load
      return state
    case requestSuccess(actionTypes.SELECT_USER_TO_MESSAGE):
      if (action.payload.orgUnitsById) {
        return state.mergeDeepIn(['byId'], fromJS(action.payload.orgUnitsById))
      }

      // don't change state if there are no org units to load
      return state

    case requestSuccess(UPDATE_SUPPLIER_STATUS):
      return state.setIn(
        ['byId', action.payload.id, 'status'],
        action.payload.status
      )

    case actionTypes.SUGGEST_ORG_UNIT:
      return state.merge({
        suggestedIds: fromJS([]),
        suggestQuery: action.payload,
        loadingSuggestions: true
      })

    case OPEN_NEW_CUSTOMER_DIALOG:
      return state.merge({
        newCustomerDialogIsOpen: true,
        customerAdded: false
      })

    case CLOSE_NEW_CUSTOMER_DIALOG:
      return state.merge({
        newCustomerDialogIsOpen: false,
        matchingOrgs: fromJS([]),
        customerAdded: false,
        suggestQuery: '',
        suggestedIds: fromJS([])
      })

    case requestSuccess(ADD_CUSTOMER):
      return action.payload.matchingOrgs
        ? state.merge({
            matchingOrgs: fromJS(action.payload.matchingOrgs),
            customerAdded: true
          })
        : state.merge({
            customerAdded: true,
            matchingOrgs: fromJS([]),
            suggestedIds: fromJS([]),
            suggestQuery: ''
          })

    case requestSuccess(actionTypes.LOAD_ORG):
      return state.mergeIn(
        ['byId', action.payload.supplier.id],
        fromJS(action.payload.supplier)
      )

    default:
      return state
  }
}

export default orgsReducer
