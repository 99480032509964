import React, { useState } from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../diversityPaths'
import { FormattedMessage } from 'react-intl'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import { CSSTransition } from 'react-transition-group'
import Hidden from '@material-ui/core/Hidden'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import storageManager from 'shared/utils/storageManager'
import Text from 'shared/components/Text'
import Divider from 'shared/components/Divider'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'
import TealbotHelp from './TealbotHelp'
import TealbotHelpV2 from './TealbotHelpV2'
import ExternalLink from 'shared/components/ExternalLink'

type Props = {
  compactInstruction: boolean
}

const InsightDiversityNavBar = ({ compactInstruction }: Props) => {
  const [doNotShow, setDoNotShow] = useState<boolean>(
    !!storageManager.getItem('doNotShowTier2SupplierHelp')
  )

  const handleDoNotShow = () => {
    storageManager.setItem('doNotShowTier2SupplierHelp', true)
    setDoNotShow(true)
  }

  const handleShow = () => {
    storageManager.setItem('doNotShowTier2SupplierHelp', false)
    setDoNotShow(false)
  }

  return (
    <div className='mw8 center ph3 cf'>
      <CSSTransition
        in={!doNotShow}
        timeout={300}
        classNames='tealbotHelp'
        unmountOnExit
      >
        <div className='bg-white br1 ba b--black-10 mt3-5 mb0 flex'>
          <div className='pa3-5 flex-auto'>
            <div className='lh-copy f7 fw4 mid-gray ma0'>
              <div className='flex items-center justify-between'>
                {!!compactInstruction && (
                  <div className='w3-5'>
                    <div className='w2 h2 br-pill pa1 ba b--black-10 mr1'>
                      <img
                        className='w-100'
                        src={TealbotCircleImage}
                        alt='tealbot-tip'
                      />
                    </div>
                  </div>
                )}
                <Text>
                  <FormattedMessage
                    id='DiversityReport.Tier2Help1!'
                    defaultMessage="TealBook's supplier diversity solutions deliver supplier intelligence that optimizes your supplier network and simplifies diversity reporting. Our Tier 2 Supplier Diversity solution allows you to allocate and share your diverse spend information with your customers enabling you to increase your competitiveness and better position your business as a strategic partner.{br}{br}
                    To get started, navigate to the {invitation} tab below and accept your customers' Tier 2 diversity invitation, then head over to the {load} tab to start loading your spend data.{br}{br}When you're ready to share your spend data, navigate to the {share} tab to allocate diverse spend amounts to your customer and share the data with them.{br}{br}Click {here} to learn more about our Tier 2 supplier diversity solution."
                    values={{
                      br: <br />,
                      invitation: <span className='b'>Invitation</span>,
                      load: <span className='b'>Load</span>,
                      share: <span className='b'>Share</span>,
                      here: (
                        <ExternalLink href='https://tealbookhelp.zendesk.com/hc/en-us/articles/9303972344340'>
                          {' here'}
                        </ExternalLink>
                      )
                    }}
                  />
                </Text>
              </div>
              {!!compactInstruction ? <TealbotHelpV2 /> : <TealbotHelp />}
            </div>
          </div>
          {!compactInstruction && (
            <Hidden smDown>
              <div className='ml3-ns w4 self-center'>
                <img src={TealbotCircleImage} alt='tealbot-tip' />
              </div>
            </Hidden>
          )}
          <div className='pr2 pt2'>
            <IconButton
              onClick={handleDoNotShow}
              style={{
                width: 32,
                height: 32
              }}
              aria-label='close diversity content'
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </CSSTransition>

      <div className='flex items-center'>
        <NavBar>
          <NavLink
            aria-label='Invitation'
            to={paths.diversityInvitation}
            label={
              <FormattedMessage
                id='InsightDiversityNavBar.Invitation'
                defaultMessage='Invitation'
              />
            }
          />

          <NavLink
            to={paths.diversityLoad}
            aria-label='Load'
            label={
              <FormattedMessage
                id='InsightDiversityNavBar.Load'
                defaultMessage='Load'
              />
            }
            isActive={(_, location) =>
              location.pathname.startsWith(paths.diversityLoad)
            }
          />

          <NavLink
            to={paths.diversityShare}
            aria-label='Share'
            label={
              <FormattedMessage
                id='InsightDiversityNavBar.Share'
                defaultMessage='Share'
              />
            }
            isActive={(_, location) =>
              location.pathname === paths.diversityShare
            }
          />

          <NavLink
            to={paths.diversityReport}
            aria-label='Your Diverse Spend'
            label={
              <FormattedMessage
                id='InsightDiversityNavBar.DiverseSpend'
                defaultMessage='Your Diverse Spend'
              />
            }
            isActive={(_, location) =>
              location.pathname === paths.diversityReport
            }
          />
        </NavBar>

        {doNotShow && (
          <CSSTransition
            in={doNotShow}
            timeout={100}
            classNames='tealbotHelp'
            unmountOnExit
          >
            <TealbotHelpIcon
              show={doNotShow}
              imageClassName='dim pointer w2 ma0'
              onClick={handleShow}
            />
          </CSSTransition>
        )}
      </div>
      <Divider />
    </div>
  )
}

export default InsightDiversityNavBar
