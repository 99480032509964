import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  ReactElement
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import Text from 'shared/components/Text'
import moment from 'moment'
import NotesByCell from './NotesByCell'
import ActionsColumn from './ActionsColumn'
import CompletedByUser from './CompletedByUser'
import RequestTypeByCell, {
  messages as typeMessages
} from './RequestTypeByCell'
import RequestStatusByCell, {
  parseStatus,
  messages as statusMessages
} from './RequestStatusByCell'
import RequestSentToCell from './RequestSentToCell'
import { List, RecordOf } from 'immutable'
import Task from 'shared/models/Task'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { loadSupplierProfile } from 'buyer/SupplierProfile/actions'
import SelfCertifyDialogContainer from '../../containers/SelfCertifyDialogContainer'
import ChatContactSelectContainer from '../../containers/ChatContactSelectContainer'
import ContractFormDialogContainer from '../../containers/ContractFormDialogContainer'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  closeSupplierOutreachRequest,
  resendSupplierOutreachRequest,
  resendSupplierOutreachRequestForESGSurvey,
  contactSupplierForOutreachRequest
} from 'shared/actions'
import { updateData } from 'shared/actions'
import profileSelectors from '../../../SupplierProfile/selectors/profileSelectors'
import Paper from 'shared/components/Paper'
import Card from 'shared/components/Card'
import Pagination from 'shared/components/Pagination/Pagination'
import Link from 'shared/components/Link'
import paths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import CloseStatusByCell, {
  messages as CloseStatusMessages
} from './CloseStatusByCell'

export type Attachment = {
  createdBy: string
  createdDate: Date
  documentFileUrl?: string
  expirationDate: Date
}

const pageSize = 100

type Props = {
  tasks: List<RecordOf<Task>>
  noTaskMessage?: string | ReactElement<FormattedMessage>
  isClosed?: boolean
}

const OutreachTable = (props: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { tasks, isClosed = false, noTaskMessage } = props
  const currentUserId = useSelector(sessionSelectors.getUserId)
  const isLoading = useSelector(profileSelectors.isLoading)

  const [selectedTaskId, setSelectedTaskId] = useState<string>('')
  const [selectedTaskSupplierId, setSelectedTaskSupplierId] = useState<string>(
    ''
  )
  const [selectedTaskRelationshipId, setSelectedTaskRelationshipId] = useState<
    string
  >('')
  const [isContractFormDialogOpen, setIsContractFormDialogOpen] = useState<
    boolean
  >(false)
  const [expiringAttachment, setExpiringAttachment] = useState<
    RecordOf<Attachment>
  >()
  const [isSelfCertifyDialogOpen, setIsSelfCertifyDialogOpen] = useState<
    boolean
  >(false)
  const [selectedRequestContactId, setSelectedRequestContactId] = useState<
    string
  >('')
  const [isChatContactSelectOpen, setIsChatContactSelectOpen] = useState<
    boolean
  >(false)

  const [sortBy, setSortBy] = useState<
    'dateCreated' | 'supplier' | 'type' | 'status' | 'dateCompleted'
  >(isClosed ? 'dateCompleted' : 'dateCreated')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')

  const [pageNumber, setPageNumber] = useState<number>(1)
  const totalPages = Math.ceil(tasks.size / pageSize)

  useEffect(() => {
    setPageNumber(1)
  }, [tasks])

  const start = (pageNumber - 1) * pageSize + 1
  const end =
    pageNumber * pageSize > tasks.size ? tasks.size : pageNumber * pageSize

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const sortTasks = useCallback(
    (
      tasks: List<RecordOf<Task>>,
      sortBy: 'dateCreated' | 'supplier' | 'type' | 'status' | 'dateCompleted',
      sortDirection: 'ASC' | 'DESC'
    ) => {
      const sortedTasks = tasks
        .map(task => {
          // handle special case where esgSurveyRequest is expired
          // use created date + 28 days as dateCompleted
          return task.get('type') === 'ESGSurveyRequest' &&
            task.get('status') === 'Expired'
            ? task.set(
                'dateCompleted',
                moment(task.get('dateCreated'))
                  .add(28, 'days')
                  .toISOString()
              )
            : task
        })
        .sort((task1: RecordOf<Task>, task2: RecordOf<Task>) => {
          let value1
          let value2

          switch (sortBy) {
            case 'dateCompleted':
            case 'dateCreated':
              value1 = task1.get(sortBy) || ''
              value2 = task2.get(sortBy) || ''
              return value1 && value2
                ? new Date(value1 as string).valueOf() -
                    new Date(value2 as string).valueOf()
                : 0

            case 'type':
              value1 = typeMessages[task1.get(sortBy)]
                ? intl.formatMessage(typeMessages[task1.get(sortBy)])
                : task1.get(sortBy)
              value2 = typeMessages[task2.get(sortBy)]
                ? intl.formatMessage(typeMessages[task2.get(sortBy)])
                : task2.get(sortBy)

              return value1 < value2 ? -1 : 1

            case 'status':
              if (isClosed) {
                value1 = CloseStatusMessages[task1.get(sortBy)]
                  ? intl.formatMessage(CloseStatusMessages[task1.get(sortBy)])
                  : task1.get(sortBy)
                value2 = CloseStatusMessages[task2.get(sortBy)]
                  ? intl.formatMessage(CloseStatusMessages[task2.get(sortBy)])
                  : task2.get(sortBy)
              } else {
                const status1 = parseStatus(
                  task1.get('type'),
                  task1.get('status')
                )
                const status2 = parseStatus(
                  task2.get('type'),
                  task2.get('status')
                )
                value1 = statusMessages[status1]
                  ? intl.formatMessage(statusMessages[status1])
                  : status1
                value2 = statusMessages[status2]
                  ? intl.formatMessage(statusMessages[status2])
                  : status2
              }

              return value1 < value2 ? -1 : 1

            default:
              value1 = task1.get(sortBy) || ''
              value2 = task2.get(sortBy) || ''

              return value1.toLowerCase() < value2.toLowerCase() ? -1 : 1
          }
        })

      return sortDirection === 'ASC' ? sortedTasks : sortedTasks.reverse()
    },
    [intl, isClosed]
  )

  const sortedTasks = useMemo(() => {
    return sortTasks(tasks, sortBy, sortDirection).slice(start - 1, end)
  }, [tasks, sortBy, sortDirection, sortTasks, start, end])

  const getRow = ({ index }) => {
    return sortedTasks.get(index)
  }

  const handleCloseSupplierOutreachRequest = (
    taskId: string,
    status: 'Completed' | 'Cancelled',
    currentUserId: string
  ) => {
    dispatch(closeSupplierOutreachRequest({ taskId, status, currentUserId }))
    dispatch(
      updateData({
        entity: 'tasks',
        record: {
          id: taskId,
          status
        }
      })
    )
  }

  const handleResendSupplierOutreachRequest = (
    taskId: string,
    status: string,
    currentUserId: string,
    requestedContactId: string,
    isEsgSurvey?: boolean
  ) => {
    const requestedDate = new Date().toISOString()
    dispatch(
      isEsgSurvey
        ? resendSupplierOutreachRequestForESGSurvey({
            taskId,
            requestedDate,
            currentUserId,
            requestedContactId
          })
        : resendSupplierOutreachRequest({
            taskId,
            status,
            currentUserId,
            requestedContactId
          })
    )

    dispatch(
      updateData({
        entity: 'tasks',
        record: isEsgSurvey
          ? {
              id: taskId,
              form: {
                requestedContactId
              },
              created: {
                date: requestedDate,
                user: currentUserId
              }
            }
          : {
              id: taskId,
              status,
              form: {
                requestedContactId
              }
            }
      })
    )
  }

  const handleContactSupplier = (
    taskId: string,
    status: string,
    currentUserId: string,
    requestedContactId: string
  ) => {
    dispatch(
      contactSupplierForOutreachRequest({
        taskId,
        status,
        currentUserId,
        requestedContactId
      })
    )
    dispatch(
      updateData({
        entity: 'tasks',
        record: {
          id: taskId,
          status,
          form: {
            requestedContactId
          }
        }
      })
    )
  }

  const handleCloseAction = rowData => {
    const taskId = rowData.get('id')
    if (rowData.get('type') === 'EcoVadisAssessment') {
      handleCloseSupplierOutreachRequest(taskId, 'Completed', currentUserId)
    } else {
      handleCloseSupplierOutreachRequest(taskId, 'Cancelled', currentUserId)
    }
  }
  const handleContractUploadAction = (
    taskId,
    supplierId,
    attachment,
    relationshipId
  ) => {
    setSelectedTaskId(taskId)
    setSelectedTaskSupplierId(supplierId)
    setSelectedTaskRelationshipId(relationshipId)
    setIsContractFormDialogOpen(!isContractFormDialogOpen)
    setExpiringAttachment(attachment)
  }
  const handleChatContactSelectAction = (taskId, supplierId) => {
    setSelectedTaskId(taskId)
    setSelectedTaskSupplierId(supplierId)
    setSelectedRequestContactId('')
    setIsChatContactSelectOpen(!isChatContactSelectOpen)
    dispatch(loadSupplierProfile(supplierId))
  }
  const handleResendRequestAction = (
    taskId,
    requestedContactId,
    supplierId
  ) => {
    setSelectedTaskId(taskId)
    setSelectedRequestContactId(requestedContactId)
    setSelectedTaskSupplierId(supplierId)
    setIsSelfCertifyDialogOpen(!isSelfCertifyDialogOpen)
    dispatch(loadSupplierProfile(supplierId))
  }
  const toggleContractFormDialog = () => {
    setIsContractFormDialogOpen(
      isContractFormDialogOpen => !isContractFormDialogOpen
    )
  }
  const toggleSelfCertifyDialog = () => {
    setIsSelfCertifyDialogOpen(
      isSelfCertifyDialogOpen => !isSelfCertifyDialogOpen
    )
  }
  const toggleChatContactSelectDialog = () => {
    setIsChatContactSelectOpen(
      isChatContactSelectOpen => !isChatContactSelectOpen
    )
  }
  const showActionColumn = useMemo(() => {
    for (const task of tasks) {
      if (task.get('status') === 'Results Available') {
        return true
      }
    }
    return false
  }, [tasks])
  return (
    <>
      <Paper noPadding>
        <Table
          minWidth={990}
          rowGetter={getRow}
          rowCount={sortedTasks.size}
          rowClassName='pointer dim'
          sort={handleSortChange}
          sortBy={sortBy}
          sortDirection={sortDirection}
        >
          {!isClosed && (
            <Column
              label='Requested Date'
              dataKey='dateCreated'
              width={120}
              cellRenderer={({ cellData }: TableCellProps) => {
                return <div>{moment(cellData).format('MM/DD/YYYY')}</div>
              }}
            />
          )}
          {isClosed && (
            <Column
              label='Completion Date'
              dataKey='dateCompleted'
              width={135}
              cellRenderer={({ cellData }: TableCellProps) => {
                return <div>{moment(cellData).format('MM/DD/YYYY')}</div>
              }}
            />
          )}
          <Column
            label='Supplier Name'
            dataKey='supplier'
            width={165}
            style={{
              wordWrap: 'break-word',
              whiteSpace: 'normal'
            }}
            cellRenderer={({ rowData }: TableCellProps) => {
              return (
                <Link
                  to={parsePath(paths.supplierProfile, {
                    supplierId: rowData.get('supplierId')
                  })}
                  className='underline-hover hover-teal'
                >
                  {rowData.get('supplier')}
                </Link>
              )
            }}
          />
          <Column
            label='Request Type'
            dataKey='type'
            width={150}
            cellRenderer={({ cellData }: TableCellProps) => {
              return <RequestTypeByCell outreachType={cellData} />
            }}
            style={{
              wordWrap: 'break-word',
              whiteSpace: 'normal'
            }}
          />
          {!isClosed && (
            <Column
              label='Status'
              dataKey='status'
              width={120}
              cellRenderer={({ rowData }: TableCellProps) => {
                return (
                  <RequestStatusByCell
                    outreachType={rowData.get('type')}
                    outreachStatus={rowData.get('status')}
                  />
                )
              }}
            />
          )}
          {!isClosed && (
            <Column
              label='Sent To'
              dataKey='form'
              width={200}
              cellRenderer={({ cellData, rowData }: TableCellProps) => {
                return (
                  <RequestSentToCell
                    outreachRequestedContactId={cellData?.get(
                      'requestedContactId'
                    )}
                    additionalRequestContactIds={cellData?.get(
                      'additionalRequestContactIds'
                    )}
                    outreachType={rowData.get('type')}
                    supplierName={rowData.get('supplier')}
                  />
                )
              }}
              style={{
                wordWrap: 'break-word',
                whiteSpace: 'normal'
              }}
              disableSort
            />
          )}
          {!isClosed && (
            <Column
              label='Notes'
              dataKey='notes'
              width={190}
              cellRenderer={({ cellData, rowData }: TableCellProps) => {
                return (
                  <NotesByCell
                    outreachType={rowData.get('type')}
                    outreachStatus={rowData.get('status')}
                    contractExpiryDate={rowData?.get('expiryDate')}
                    certificationAgency={rowData?.get('certAgency')}
                    certificationSubCategory={rowData?.get('subCategory')}
                    actionRequiredEcovadis={rowData
                      ?.get('progressStatus')
                      ?.toLowerCase()
                      .includes('action required')}
                    dateExpired={rowData?.get('dateCompleted')}
                    dateESGSurveyStarted={rowData?.get('dateStarted')}
                    dateESGSurveyLastViewed={rowData?.get('dateLastViewed')}
                    dateESGSurveyLastAccessed={rowData?.get('dateLastAccessed')}
                  />
                )
              }}
              style={{
                wordWrap: 'break-word',
                whiteSpace: 'normal'
              }}
              disableSort
            />
          )}
          {isClosed && (
            <Column
              label='Requested Date'
              dataKey='dateCreated'
              width={120}
              cellRenderer={({ cellData }: TableCellProps) => {
                return <div>{moment(cellData).format('MM/DD/YYYY')}</div>
              }}
            />
          )}
          {isClosed && (
            <Column
              label='Completed By'
              dataKey='completedById'
              width={170}
              cellRenderer={({ cellData, rowData }: TableCellProps) => {
                return rowData?.get('status') !== 'Expired' ? (
                  <CompletedByUser completedByUserId={cellData} />
                ) : null
              }}
              disableSort
            />
          )}
          {isClosed && (
            <Column
              label='Status'
              dataKey='status'
              width={140}
              cellRenderer={({ rowData }: TableCellProps) => {
                return (
                  <CloseStatusByCell outreachStatus={rowData.get('status')} />
                )
              }}
            />
          )}
          {isClosed && (
            <Column
              label='Summary'
              dataKey='summary'
              width={200}
              cellRenderer={({ cellData, rowData }: TableCellProps) => {
                return (
                  <NotesByCell
                    outreachType={rowData.get('type')}
                    outreachStatus={rowData?.get('status')}
                    contractExpiryDate={rowData?.get('expiryDate')}
                    certificationAgency={rowData?.get('certAgency')}
                    certificationSubCategory={rowData?.get('subCategory')}
                    archivedTask={rowData?.get('archived')}
                    dateExpired={rowData?.get('dateCompleted')}
                  />
                )
              }}
              style={{
                wordWrap: 'break-word',
                whiteSpace: 'normal'
              }}
              disableSort
            />
          )}
          {(!isClosed || (isClosed && showActionColumn)) && (
            <Column
              className={'mh2'}
              headerStyle={{ textAlign: 'right' }}
              disableSort
              dataKey='id'
              label='Actions'
              width={60}
              cellRenderer={({ rowData }) => {
                if (
                  !isClosed ||
                  (isClosed && rowData.get('status') === 'Results Available')
                )
                  return (
                    <ActionsColumn
                      rowData={rowData}
                      handleCloseAction={handleCloseAction}
                      handleContractUploadAction={handleContractUploadAction}
                      handleResendRequestAction={handleResendRequestAction}
                      handleChatContactSelectAction={
                        handleChatContactSelectAction
                      }
                    />
                  )
              }}
            />
          )}
        </Table>
        {noTaskMessage && !sortedTasks?.size && (
          <Card>
            <Text style={{ textAlign: 'center' }}>{noTaskMessage}</Text>
          </Card>
        )}
      </Paper>
      {tasks?.size > 0 && (
        <div className='flex items-center justify-between mv2'>
          <Text role='status'>
            <FormattedMessage
              id='OutreachTable.Results'
              defaultMessage={`{total, plural, one {Result 1 request} other {Results {localeStart} - {localeEnd} of {localeTotal} requests}}`}
              values={{
                total: tasks?.size || 0,
                localeTotal: (tasks?.size || 0).toLocaleString(),
                localeStart: start.toLocaleString(),
                localeEnd: end.toLocaleString()
              }}
            />
          </Text>
          {totalPages > 1 && (
            <Pagination
              totalPages={totalPages}
              currentPage={pageNumber}
              onChange={p => setPageNumber(p)}
            />
          )}
        </div>
      )}
      <ContractFormDialogContainer
        activeUserId={currentUserId}
        supplierId={selectedTaskSupplierId}
        type={'contract'}
        handleToggleContractFormDialog={toggleContractFormDialog}
        isContractFormDialogOpen={isContractFormDialogOpen}
        handleCompleteSupplierOutreachRequest={
          handleCloseSupplierOutreachRequest
        }
        taskId={selectedTaskId}
        expiringAttachmentRelationshipId={selectedTaskRelationshipId}
        expiringAttachment={expiringAttachment}
      />
      {!isLoading && selectedTaskSupplierId && selectedRequestContactId && (
        <SelfCertifyDialogContainer
          activeUserId={currentUserId}
          supplierId={selectedTaskSupplierId}
          requestedContactId={selectedRequestContactId}
          taskId={selectedTaskId}
          handleToggleSelfCertifyDialog={toggleSelfCertifyDialog}
          isSelfCertifyDialogOpen={isSelfCertifyDialogOpen}
          handleResendSupplierOutreachRequest={
            handleResendSupplierOutreachRequest
          }
        />
      )}
      {!isLoading && selectedTaskSupplierId && !selectedRequestContactId && (
        <ChatContactSelectContainer
          activeUserId={currentUserId}
          supplierId={selectedTaskSupplierId}
          taskId={selectedTaskId}
          handleToggleChatContactSelectDialog={toggleChatContactSelectDialog}
          isChatContactSelectOpen={isChatContactSelectOpen}
          onContactSupplier={handleContactSupplier}
        />
      )}
    </>
  )
}

export default OutreachTable
