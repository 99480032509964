import React, {
  FunctionComponent,
  useState,
  useEffect,
  ChangeEvent
} from 'react'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import RadioButton from 'shared/components/RadioButton'
import RadioButtonGroup from 'shared/components/RadioButton/RadioButtonGroup'
import Button from 'shared/components/Button'
import TextArea from 'shared/components/TextArea'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import PageSection from 'shared/components/PageSection'
import { FormattedMessage } from 'react-intl'
import PrimaryContacts from '../PrimaryContacts'
import VetResponsePricing from '../VetResponsePricing'
import Divider from 'shared/components/Divider'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import PricingTable from 'shared/components/PricingTable'
import { Map, List, RecordOf } from 'immutable'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import UploadedFile from 'shared/models/UploadedFile'
import FileAttachmentList from 'shared/components/FileAttachmentList'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'

const today = new Date()

type Props = {
  meetCriteria?: boolean
  interested: boolean
  criticalCriteria: List<string>
  detailedReponseRequested: boolean
  status: string
  description: string
  documents: List<RecordOf<UploadedFile>>
  submittedDate: string
  submittedBy: RecordOf<{
    firstName: string
    lastName: string
  }>
  quoteByUnitPrice: boolean
  onAcceptVet: (description: string) => void
  onDeclineVet: (description: string) => void
  onShowInterestVet: (interested: boolean) => void
  onAcceptVetCriteria: (criteria: boolean) => void
  onUploadVetPresentation: (file: File | null) => void
  onRemoveVetPresentation: (fileName: string) => void
  onChangePricing: (changes: object) => void
  onChangeQuoteByUnitPrice: (quoteByUnitPrice: boolean) => void
  onChangeDescription: (changes: { description: string }) => void
  handleDownload?: (fileName: string) => void
  getFilePreview?: (fileName: any) => Promise<string | void>
  includePricing: boolean
  pricing?: RecordOf<{
    currency: string
    lineItems: List<
      RecordOf<{
        name: string
        description: string
        quantity: number
        UOM: string
        id: string
      }>
    >
  }>
  proposalPricing: Map<string, List<RecordOf<{ id: string; amount: number }>>>
  proposalPrices: { [id: string]: number }
  responseBy: string
  hasSupplierContacts: boolean
  allowWordDocs: boolean
}

const VetResponsePage: FunctionComponent<Props> = props => {
  const { interested, meetCriteria, quoteByUnitPrice, proposalPrices } = props

  const [interestedState, setInterestedState] = useState<boolean>(interested)
  const [meetCriteriaState, setMeetCriteriaState] = useState<
    boolean | undefined
  >(meetCriteria)
  const [price, setPrice] = useState<{ [id: string]: number | '' }>(
    proposalPrices
  )
  const [quoteByUnitPriceState, setQuoteByUnitPriceState] = useState<boolean>(
    quoteByUnitPrice
  )
  const [descriptionState, setDescriptionState] = useState<string>(
    props.description
  )
  const [changingResponse, setChangingResponse] = useState<boolean>(false)
  const [interestedChanged, setInterestedChanged] = useState<boolean>(false)
  const [meetCriteriaChanged, setMeetCriteriaChanged] = useState<
    boolean | undefined
  >(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [decision, setDecision] = useState<string>('')

  useEffect(() => {
    setPrice(proposalPrices)
    setQuoteByUnitPriceState(quoteByUnitPrice)
    setInterestedChanged(interestedState !== interested)
    setMeetCriteriaChanged(meetCriteriaState !== meetCriteria)
  }, [
    interested,
    meetCriteria,
    proposalPrices,
    quoteByUnitPrice,
    interestedState,
    meetCriteriaState
  ])

  const handleChangeResponse = value => {
    const { status, criticalCriteria } = props
    if (status === 'Accepted' && criticalCriteria && criticalCriteria.size) {
      props.onAcceptVetCriteria(true)
    } else {
      props.onShowInterestVet(value)
    }
    setChangingResponse(value)
  }

  const handleInterestToggle = e => {
    const newInterested = e.target.value === 'yes'
    if (!!interestedState !== newInterested) {
      props.onShowInterestVet(newInterested)
    }
    setInterestedState(newInterested)
    setMeetCriteriaState(undefined)
  }

  const handleCriteriaToggle = e => {
    const newMeetCriteria = e.target.value === 'yes'

    if (!!meetCriteriaState !== newMeetCriteria) {
      props.onAcceptVetCriteria(newMeetCriteria)
    }
    setMeetCriteriaState(newMeetCriteria)
  }

  const handleDescriptionChange = e => {
    setDescriptionState(e.target.value)
  }

  const handleDeclineVet = () => {
    props.onDeclineVet(descriptionState)
    setChangingResponse(false)
    setDialogOpen(false)
  }

  const handleAcceptVet = () => {
    props.onAcceptVet(descriptionState)
    setChangingResponse(false)
    setDialogOpen(false)
  }

  const openConfirmationDialog = (e, decision) => {
    setDialogOpen(true)
    setDecision(decision)
  }

  const handleConfirmedDecision = () => {
    if (decision === 'Accepted') {
      handleAcceptVet()
    }
    if (decision === 'Declined') {
      handleDeclineVet()
    }
  }
  const handleChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.name
    const priceInput = e.currentTarget.value && Number(e.currentTarget.value)

    props.onChangePricing({
      id,
      priceInput
    })
    setPrice({ ...price, [id]: priceInput })
  }

  const handleChangeQuoteByUnitPrice = () => {
    const newVal = !quoteByUnitPriceState
    props.onChangeQuoteByUnitPrice(newVal)
    setQuoteByUnitPriceState(newVal)
  }

  const handleRemoveAttachment = (fileName: string) => {
    const { onRemoveVetPresentation } = props
    onRemoveVetPresentation(fileName)
  }

  const handleSaveAttachment = (file: File) => {
    const { onUploadVetPresentation } = props
    onUploadVetPresentation(file)
  }

  const {
    criticalCriteria,
    detailedReponseRequested,
    status,
    description,
    onChangeDescription,
    handleDownload,
    getFilePreview,
    documents,
    submittedDate,
    submittedBy,
    includePricing,
    pricing,
    proposalPricing,
    responseBy,
    allowWordDocs
  } = props

  const editable =
    (status === 'Opened' ||
      status === 'Interested' ||
      status === 'Meet Criteria' ||
      changingResponse) &&
    today <= new Date(responseBy)

  const lineItems = pricing && pricing.get('lineItems')

  return (
    <Page
      title={
        <FormattedMessage
          id='VetResponsePage.ResponseTitle'
          defaultMessage='Response'
        />
      }
    >
      <ScrollToPosition />
      <Main>
        {editable ? (
          <div>
            {
              <>
                <PageSection
                  title={
                    <FormattedMessage
                      id='VetResponsePage.InterestedTitle'
                      defaultMessage='Interested'
                    />
                  }
                >
                  <div className='db pt3 mb1 f7 fw6'>
                    <RadioButtonGroup
                      title={
                        <FormattedMessage
                          id='VetResponsePage.AreYouInterestedTitle'
                          defaultMessage='Are you interested in this opportunity?'
                        />
                      }
                    >
                      <RadioButton
                        label={
                          <FormattedMessage
                            id='VetResponsePage.YesLabel'
                            defaultMessage='Yes'
                          />
                        }
                        value={'yes'}
                        name='interested'
                        checked={interestedState === true}
                        onChange={handleInterestToggle}
                      />
                      <RadioButton
                        label={<FormattedMessage id='No' />}
                        value={'no'}
                        name='interested'
                        checked={
                          interestedState === false ||
                          (interestedState === undefined && interestedChanged)
                        }
                        onChange={handleInterestToggle}
                      />
                    </RadioButtonGroup>
                  </div>
                  {(interestedState === false ||
                    (interestedState === undefined && interestedChanged)) && (
                    <div>
                      <Label>
                        <FormattedMessage
                          id='VetResponsePage.RFIWhyDecliningLabel'
                          defaultMessage='Please let us know why you are declining the RFI (optional)'
                        />
                      </Label>
                      <FormattedMessage
                        id='VetResponsePage.InterestPlaceholder'
                        defaultMessage='Please let us know why you are not interested'
                      >
                        {message => (
                          <TextArea
                            placeholder={message as string}
                            maxLength={500}
                            onChange={handleDescriptionChange}
                            value={descriptionState}
                          />
                        )}
                      </FormattedMessage>
                      <div className='mt3'>
                        <Button
                          autoSize
                          type='submit'
                          label={
                            <FormattedMessage
                              id='VetResponsePage.SendButton'
                              defaultMessage='Send'
                            />
                          }
                          onClick={e => openConfirmationDialog(e, 'Declined')}
                        />
                      </div>
                    </div>
                  )}
                </PageSection>
                {interestedState &&
                  criticalCriteria &&
                  criticalCriteria.size > 0 && (
                    <PageSection
                      title={
                        <FormattedMessage
                          id='VetResponsePage.CriticalCriteriaTitle'
                          defaultMessage='Critical Criteria'
                        />
                      }
                    >
                      <div>
                        {criticalCriteria.map((criteria, index) => (
                          <div key={index} className='dt mt3'>
                            <Text>{index + 1 + '. ' + criteria}</Text>
                          </div>
                        ))}
                        <div className='dt mt3'>
                          <RadioButtonGroup
                            title={
                              <FormattedMessage
                                id='VetResponsePage.MeetCriticalCriteriaTitle'
                                defaultMessage='Do you meet all of the critical criteria?'
                              />
                            }
                          >
                            <RadioButton
                              label={
                                <FormattedMessage
                                  id='VetResponsePage.YesLabel'
                                  defaultMessage='Yes'
                                />
                              }
                              value={'yes'}
                              name='meetCriteria'
                              checked={meetCriteriaState === true}
                              onChange={handleCriteriaToggle}
                            />
                            <RadioButton
                              label={<FormattedMessage id='No' />}
                              value={'no'}
                              name='meetCriteria'
                              checked={
                                meetCriteriaState === false ||
                                (meetCriteriaState === undefined &&
                                  meetCriteriaChanged)
                              }
                              onChange={handleCriteriaToggle}
                            />
                          </RadioButtonGroup>
                        </div>
                        {(meetCriteriaState === false ||
                          (meetCriteriaState === undefined &&
                            meetCriteriaChanged)) && (
                          <div>
                            <Label>
                              <FormattedMessage
                                id='VetResponsePage.RFIWhyDecliningLabel'
                                defaultMessage='Please let us know why you are declining the RFI (optional)'
                              />
                            </Label>
                            <FormattedMessage
                              id='VetResponsePage.CriteriaNotMetPlaceholder'
                              defaultMessage='Please let us know which piece of critical criteria you do not meet'
                            >
                              {message => (
                                <TextArea
                                  placeholder={message as string}
                                  maxLength={500}
                                  onChange={handleDescriptionChange}
                                  value={descriptionState}
                                />
                              )}
                            </FormattedMessage>
                            <div className='mt3'>
                              <Button
                                autoSize
                                type='submit'
                                label={
                                  <FormattedMessage
                                    id='VetResponsePage.SendButton'
                                    defaultMessage='Send'
                                  />
                                }
                                onClick={e =>
                                  openConfirmationDialog(e, 'Declined')
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </PageSection>
                  )}
              </>
            }
            {(meetCriteriaState ||
              (interestedState &&
                (!criticalCriteria || criticalCriteria.size === 0))) && (
              <PageSection
                title={
                  <FormattedMessage
                    id='VetResponsePage.RespondToSenderTitle'
                    defaultMessage='Respond To Sender'
                  />
                }
              >
                <Label>
                  <FormattedMessage
                    id='VetResponsePage.ResponseLabel'
                    defaultMessage='Response'
                  />
                </Label>
                <FormattedMessage
                  id='VetResponsePage.RFIPersonalizedMessagePlaceholder'
                  defaultMessage='Write a personalized message to accompany your RFI submission'
                >
                  {message => (
                    <AutoSaveInput
                      component={TextArea}
                      maxLength={500}
                      value={descriptionState || description}
                      name='description'
                      placeholder={message}
                      onSave={onChangeDescription}
                      onChange={handleDescriptionChange}
                    />
                  )}
                </FormattedMessage>
                {detailedReponseRequested && (
                  <FileAttachmentList
                    limit={5}
                    allowWordDocs={allowWordDocs}
                    label={
                      <FormattedMessage
                        id='VetResponsePage.Attachment'
                        defaultMessage='Attachment'
                      />
                    }
                    attachment={documents}
                    onSave={handleSaveAttachment}
                    onRemove={handleRemoveAttachment}
                    onDownload={handleDownload}
                    getFilePreview={getFilePreview}
                  />
                )}
                {includePricing &&
                  pricing &&
                  pricing.get('lineItems') &&
                  pricing.get('lineItems').size > 0 && (
                    <VetResponsePricing
                      pricing={pricing}
                      proposalPrices={price}
                      onChangePrice={handleChangePrice}
                      onChangeQuoteByUnitPrice={handleChangeQuoteByUnitPrice}
                      quoteByUnitPrice={quoteByUnitPrice}
                    />
                  )}
                <div className='mt3'>
                  <Button
                    autoSize
                    type='submit'
                    label={
                      <FormattedMessage
                        id='VetResponsePage.SendLabel'
                        defaultMessage='Send'
                      />
                    }
                    onClick={e => openConfirmationDialog(e, 'Accepted')}
                  />
                </div>
              </PageSection>
            )}
          </div>
        ) : (
          <div>
            {!!submittedDate && (
              <div className='dtc fr'>
                <h5 className='f8 fw4 ma0 gray lh-copy tr'>
                  <b>
                    <FormattedMessage
                      id='VetResponsePage.SentOnLabel'
                      defaultMessage='Sent on'
                    />
                  </b>{' '}
                  {submittedDate}
                </h5>
                <h5 className='f8 fw4 ma0 gray lh-copy tr'>
                  <b>
                    <FormattedMessage
                      id='VetResponsePage.SentByLabel'
                      defaultMessage='Sent By'
                    />
                  </b>{' '}
                  {`${submittedBy &&
                    submittedBy.get('firstName')} ${submittedBy &&
                    submittedBy.get('lastName')}`}
                </h5>
              </div>
            )}
            {!submittedDate && new Date(responseBy) < today && (
              <div className='ma3'>
                <FormattedMessage
                  id='VetResponsePage.RFIresponseDateHasPassed'
                  defaultMessage='The response date for this RFI has passed.'
                />
              </div>
            )}
            <PageSection
              title={
                <FormattedMessage
                  id='VetResponsePage.YourResponseTitle'
                  defaultMessage='Your Response'
                />
              }
            >
              <Label>
                <FormattedMessage
                  id='VetResponsePage.WereYouInterested'
                  defaultMessage='Were you interested in this opportunity?'
                />
              </Label>
              <Text>
                {interestedState ? (
                  <FormattedMessage
                    id='VetResponsePage.YesText'
                    defaultMessage='Yes'
                  />
                ) : (
                  <FormattedMessage id='No' />
                )}
              </Text>

              {interestedState &&
                criticalCriteria &&
                criticalCriteria.size > 0 && (
                  <div>
                    <Label>
                      <FormattedMessage
                        id='VetResponsePage.CriticalCriteria'
                        defaultMessage='Did you meet all of the critical criteria?'
                      />
                    </Label>
                    <Text>
                      {meetCriteriaState ? (
                        <FormattedMessage
                          id='VetResponsePage.YesText'
                          defaultMessage='Yes'
                        />
                      ) : (
                        <FormattedMessage id='No' />
                      )}
                    </Text>
                  </div>
                )}

              {((documents && documents.size > 0) ||
                descriptionState ||
                (includePricing && lineItems && lineItems.size > 0)) && (
                <Label>
                  <FormattedMessage
                    id='VetResponsePage.ResponseTitle'
                    defaultMessage='Response'
                  />
                </Label>
              )}
              {descriptionState && <Text>{descriptionState}</Text>}
              {status !== 'Declined' && documents && documents.size > 0 && (
                <FileAttachmentList
                  readonly
                  label={<FormattedMessage id='VetResponsePage.Attachment' />}
                  attachment={documents}
                  onDownload={handleDownload}
                  getFilePreview={getFilePreview}
                />
              )}
              {status !== 'Declined' &&
                includePricing &&
                lineItems &&
                lineItems.size > 0 && (
                  <PricingTable
                    pricing={pricing}
                    proposalPricing={proposalPricing}
                  />
                )}
              {today <= new Date(responseBy) && status !== 'Cancelled' && (
                <div className='mt3'>
                  <Divider className='mb2' />
                  <Label>
                    <FormattedMessage
                      id='VetResponsePage.ResubmitMessage'
                      defaultMessage='Response submitted. You can edit your submission and resubmit up until'
                    />{' '}
                    {new Date(responseBy).toString()}
                  </Label>
                  <Button
                    autoSize
                    type='submit'
                    className='mt2'
                    label={
                      <FormattedMessage
                        id='VetResponsePage.ChangeResponse'
                        defaultMessage='Change Response'
                      />
                    }
                    onClick={() => handleChangeResponse(!!interested)}
                  />
                </div>
              )}
            </PageSection>
          </div>
        )}
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          fullWidth
        >
          <DialogTitle>
            <FormattedMessage
              id='VetResponsePage.Confirmations'
              defaultMessage='Response Confirmation:  this RFI has been {decision}{meetsCriteria}'
              values={{
                decision,
                meetsCriteria:
                  meetCriteriaState === false ? ', does not meet criteria' : ''
              }}
            />
          </DialogTitle>
          {descriptionState && (
            <DialogContent>
              <Label>Your message</Label>
              <Text>{descriptionState}</Text>
            </DialogContent>
          )}

          <DialogActions>
            <Button
              autoSize
              label={
                <FormattedMessage
                  id='VetResponsePage.Send'
                  defaultMessage='Send Response'
                />
              }
              onClick={handleConfirmedDecision}
            />
            <Button
              autoSize
              secondary
              label={<FormattedMessage id='CancelButton' />}
              onClick={() => setDialogOpen(false)}
            />
          </DialogActions>
        </Dialog>
      </Main>
      <Aside>
        <div style={{ marginTop: 38 }}>
          <PrimaryContacts readonly={!editable} />
        </div>
      </Aside>
    </Page>
  )
}

export default VetResponsePage
