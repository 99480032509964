import React, { useState, ChangeEvent } from 'react'
import { connect, useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import { List, Map, RecordOf } from 'immutable'
import numberFormat from 'shared/utils/numberFormat'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Paper from 'shared/components/Paper'
import Loading from 'shared/components/Loading'
import DiversityCategory from 'shared/models/DiversityCategory'
import {
  getOverviewSpend,
  OverviewSpendItem,
  OverviewSpendList,
} from '../../../store/diversityReportSelectors'
import {
  selectDiversityCategory,
  clearAllDiversityCategories,
  setMatchAnyCategories,
  selectGroupingValue,
  changeGrouping,
  clearGroupingValue,
} from '../../../store/actions'
import { FormattedMessage, useIntl } from 'react-intl'
import iconClearAll from 'shared/assets/icons/clear-all.svg'
import SpendFiltersSummary from '../../SpendFiltersSummary'
import { BaseRulesType } from '../../../store/diversityReportReducer'
import Tooltip from 'shared/components/Tooltip'
import translateCertificationType from 'shared/utils/translateCertificationType'
import Switch from 'shared/components/Switch'
import iconSetting from 'shared/assets/icons/setting.svg'
import Menu from '@material-ui/core/Menu'
import MenuItem from 'shared/components/MenuItem'
import CheckMark from '@material-ui/icons/Check'
import certificationCategories from 'shared/models/CertificationCategories'
import startCase from 'lodash.startcase'
import Select from 'shared/components/Select'
import IconButton from 'shared/components/IconButton'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import analytics from 'shared/utils/analytics'

type Props = {
  loading: boolean
  overviewSpend: OverviewSpendList
  selectedCategories: List<DiversityCategory>
  totalSpend: number
  selectDiversityCategory: (subCategory: DiversityCategory | '') => void
  clearAllDiversityCategories: () => void
  setMatchAnyCategories: (payload: boolean) => void
  selectGroupingValue?: (value: string) => void
  changeGrouping?: (
    grouping: 'subCategory' | 'category' | 'country' | 'spendGroup'
  ) => void
  clearGroupingValue?: () => void
  currentGrouping?: 'subCategory' | 'category' | 'country' | 'spendGroup'
  selectedGroupingValues?: List<string>
  baseRules: RecordOf<BaseRulesType>
  isMatchAny: boolean
  tier2?: boolean
  classes?: any
}

export const DiversityReportOverview = (props: Props) => {
  const intl = useIntl()

  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [bySpend, setBySpend] = useState<boolean>(true)
  const [showSubTypes, setShowSubTypes] = useState<boolean>(false)

  const {
    loading,
    tier2,
    overviewSpend,
    selectedCategories,
    selectDiversityCategory,
    clearAllDiversityCategories,
    currentGrouping,
    selectedGroupingValues,
    changeGrouping,
    clearGroupingValue,
    selectGroupingValue,
  } = props

  const overviewSpendData = overviewSpend

  if (loading) {
    return <Loading />
  }

  if (!loading && overviewSpendData && overviewSpendData.spendItems.size < 1) {
    return (
      <Paper>
        <Text className='mt3-5'>
          <FormattedMessage
            id='OverviewSpendTable.noDataFound'
            defaultMessage='There was no data found.'
          />
        </Text>
      </Paper>
    )
  }

  const renderCheckboxCell = ({ cellData, rowData }: TableCellProps) => {
    const value = cellData
    const index = rowData.get('index')

    return (
      <Tooltip
        title={`Filter by ${translateCertificationType({
          intl,
          categoryType: 'diversity',
          subCategoryType: rowData.get('subCategory'),
          useAcronym: true,
        })}`}
      >
        <input
          type='checkbox'
          data-index={index}
          value={value}
          checked={
            !!value &&
            (!currentGrouping || currentGrouping === 'subCategory' || tier2
              ? selectedCategories && selectedCategories.includes(value)
              : selectedGroupingValues &&
                selectedGroupingValues.includes(value))
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation()
          }}
        />
      </Tooltip>
    )
  }
  const renderValueCell = ({ cellData, rowData, dataKey }: TableCellProps) => {
    return (
      <div className='tr'>
        {bySpend ? '$' : ''}
        {numberFormat(cellData)}
        {showSubTypes &&
          renderSubTypesRows(cellData, rowData.get('subTypes'), dataKey)}
      </div>
    )
  }

  const handleClearAll = () => {
    if (!currentGrouping || currentGrouping === 'subCategory') {
      clearAllDiversityCategories()
    } else {
      clearGroupingValue && clearGroupingValue()
    }
  }

  const handleMenuChange = (value: boolean) => {
    setBySpend(value)
    setAnchorEl(null)
  }

  const onGroupingSelected = (e) => {
    e.preventDefault()
    if (changeGrouping) {
      changeGrouping(e.target.value)
      if (showSubTypes && e.target.value !== 'subCategory') {
        setShowSubTypes(false)
      }
    }
  }

  const renderSubTypesRows = (
    subCategory: string,
    subTypes: Map<
      string,
      RecordOf<{
        qualifiedAmount: number
        qualifiedCount: number
        potentialAmount?: number
        potentialCount?: number
        disqualifiedAmount?: number
        disqualifiedCount?: number
      }>
    >,
    key: string
  ) => {
    const orderedSubTypes = subTypes.keySeq().sort()

    return orderedSubTypes.map((subType) => {
      let value
      if (key === '') {
        const translateSubTypes =
          certificationCategories['diversity']['subTypes']
        const translateSubTypesSubCategory =
          translateSubTypes && translateSubTypes[subCategory]
        const messageId =
          translateSubTypesSubCategory && translateSubTypesSubCategory[subType]
        value = (
          <>
            &bull;{' '}
            {messageId ? intl.formatMessage(messageId) : startCase(subType)}
          </>
        )
      } else if (key === '%') {
        const qualifiedAmount =
          subTypes.getIn([subType, 'qualifiedAmount']) || 0
        value =
          overviewSpendData.qualifiedTotalAmount &&
          `${((qualifiedAmount / props.totalSpend) * 100).toFixed(2)}%`
      } else {
        value = `${bySpend ? '$' : ''}${numberFormat(
          subTypes.getIn([subType, key]) || 0
        )}`
      }

      return (
        <div key={subType} className='pv1 pl2 f8'>
          {value}
        </div>
      )
    })
  }

  const getRowHeight = ({ index }) => {
    const row = overviewSpendData.spendItems.get(index)
    const numSubTypes = row?.get('subTypes')?.size || 0

    return numSubTypes * 21 + 52
  }

  const onRowClick = ({
    rowData,
  }: {
    rowData: RecordOf<OverviewSpendItem>
    event: ChangeEvent
  }) => {
    analytics.track('Diversity Report Row Clicked', {
      eventSource: 'Diversity Report',
      action: 'Clicked',
      diversityCategoryCode: rowData.get('subCategory'),
      orgUnitId,
      orgUnitName,
    })

    tier2 || currentGrouping === 'subCategory'
      ? selectDiversityCategory(rowData.get('subCategory') as DiversityCategory)
      : selectGroupingValue &&
        selectGroupingValue(
          rowData.get(
            currentGrouping as 'country' | 'category' | 'spendGroup'
          ) || ''
        )
  }

  const renderTable = () => (
    <div className='mt3-5'>
      {!tier2 && (
        <div>
          <SpendFiltersSummary />
        </div>
      )}
      <div
        className={`mb2 flex ${
          !tier2 && selectedCategories.size > 0
            ? 'justify-between items-end'
            : 'justify-end items-center'
        }`}
      >
        {!tier2 && selectedCategories.size > 0 && (
          <Switch
            labelRight
            checked={!props.isMatchAny}
            disabled={currentGrouping !== 'subCategory'}
            label={
              <FormattedMessage
                id='DiversityReportOverview.SupplierAllSelected'
                defaultMessage='Supplier includes all selected types'
              />
            }
            ariaLabel='Supplier includes all selected types'
            onChange={() => {
              props.setMatchAnyCategories(!props.isMatchAny)
            }}
          />
        )}
        <div className='tr'>
          {currentGrouping === 'subCategory' && (
            <Label className='f7 fw6 db mb1'>
              <FormattedMessage
                id='DiversityReportOverview.DupCountsMessage'
                defaultMessage='* - some spend counts for multiple types'
              />
            </Label>
          )}
          {!tier2 && (
            <>
              {currentGrouping === 'subCategory' && (
                <Switch
                  labelRight
                  checked={showSubTypes}
                  label={
                    <FormattedMessage
                      id='DiversityReportOverview.ShowSubTypesMessage'
                      defaultMessage='Breakout by Subtypes'
                    />
                  }
                  ariaLabel='Breakout by Subtypes'
                  onChange={() => {
                    setShowSubTypes(!showSubTypes)
                  }}
                />
              )}
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <img
                  src={iconSetting}
                  alt='Config'
                  className='w1 v-mid dib ml3 pointer'
                />
              </IconButton>
              <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => handleMenuChange(true)}
                  aria-current={bySpend}
                >
                  <FormattedMessage
                    id='DiversityReportOverview.BySpend'
                    defaultMessage='By Spend'
                  />
                  &nbsp;
                  {bySpend && <CheckMark color='inherit' fontSize='small' />}
                </MenuItem>
                <MenuItem
                  onClick={() => handleMenuChange(false)}
                  aria-current={!bySpend}
                >
                  <FormattedMessage
                    id='DiversityReportOverview.ByCount'
                    defaultMessage='By Count'
                  />
                  &nbsp;
                  {!bySpend && <CheckMark color='inherit' fontSize='small' />}
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
      <Paper noPadding>
        <Table
          minWidth={1000}
          rowGetter={({ index }) => overviewSpendData.spendItems.get(index)}
          rowCount={overviewSpendData.spendItems.size}
          onRowClick={onRowClick}
          rowHeight={showSubTypes ? getRowHeight : undefined}
        >
          <Column
            width={34}
            dataKey={currentGrouping || 'subCategory'}
            disableSort
            cellRenderer={renderCheckboxCell}
            headerRenderer={() =>
              ((!currentGrouping || currentGrouping === 'subCategory') &&
                selectedCategories?.size > 0) ||
              (currentGrouping &&
                currentGrouping !== 'subCategory' &&
                selectedGroupingValues &&
                selectedGroupingValues?.size > 0) ? (
                <img
                  src={iconClearAll}
                  alt='Clear all'
                  className='w1 v-mid dib pointer dim'
                  onClick={handleClearAll}
                />
              ) : null
            }
          />
          <Column
            dataKey={currentGrouping || 'subCategory'}
            width={150}
            cellRenderer={({ cellData, rowData }: TableCellProps) => (
              <>
                {cellData ? (
                  cellData === 'null' ? (
                    currentGrouping === 'country' ? (
                      'UNKNOWN'
                    ) : (
                      'N/A'
                    )
                  ) : rowData.get('subCategory') ? (
                    <Tooltip
                      title={translateCertificationType({
                        intl,
                        categoryType: 'diversity',
                        subCategoryType: rowData.get('subCategory'),
                        useAcronym: false,
                      })}
                    >
                      <span>{cellData.toUpperCase()}</span>
                    </Tooltip>
                  ) : (
                    cellData.toUpperCase()
                  )
                ) : (
                  'OTHER'
                )}
                {(!currentGrouping || currentGrouping === 'subCategory') &&
                overviewSpendData.dupCounts[cellData]
                  ? '*'
                  : ''}
                {showSubTypes &&
                  renderSubTypesRows(cellData, rowData.get('subTypes'), '')}
              </>
            )}
            headerRenderer={
              !tier2
                ? () => (
                    <Select
                      name='Grouping'
                      onChange={onGroupingSelected}
                      value={currentGrouping}
                      className={'bn f7 fw6 mid-gray ttc bg-transparent'}
                      wrapperClassName=''
                    >
                      <FormattedMessage
                        id='OverviewSpendTable.GroupingType'
                        defaultMessage='Type'
                      >
                        {(text) => (
                          <option value={'subCategory'}>{text}</option>
                        )}
                      </FormattedMessage>
                      <FormattedMessage
                        id='OverviewSpendTable.GroupingCategory'
                        defaultMessage='Category'
                      >
                        {(text) => <option value={'category'}>{text}</option>}
                      </FormattedMessage>
                      <FormattedMessage
                        id='OverviewSpendTable.GroupingCountry'
                        defaultMessage='Country'
                      >
                        {(text) => <option value={'country'}>{text}</option>}
                      </FormattedMessage>
                      <FormattedMessage
                        id='OverviewSpendTable.GroupingSpendGroup'
                        defaultMessage='Business Unit'
                      >
                        {(text) => <option value={'spendGroup'}>{text}</option>}
                      </FormattedMessage>
                    </Select>
                  )
                : () => (
                    <FormattedMessage
                      id='OverviewSpendTable.GroupingType'
                      defaultMessage='Type'
                    />
                  )
            }
          />
          <Column
            label={
              bySpend ? (
                <FormattedMessage
                  id='OverviewSpendTable.Qualified'
                  defaultMessage='Qualified'
                />
              ) : (
                <FormattedMessage
                  id='OverviewSpendTable.QualifiedCount'
                  defaultMessage='Qualified Count'
                />
              )
            }
            headerClassName='tr'
            dataKey={bySpend ? 'qualifiedAmount' : 'qualifiedCount'}
            width={150}
            cellRenderer={renderValueCell}
          />
          {!!props.totalSpend && (
            <Column
              label={
                <FormattedMessage
                  id='OverviewSpendTable.QualifiedSpend%'
                  defaultMessage='Qualified Spend %'
                />
              }
              headerClassName='tr'
              dataKey='qualifiedAmount'
              width={150}
              cellRenderer={({ cellData, rowData }: TableCellProps) => {
                return (
                  <div className='tr'>
                    {overviewSpendData.qualifiedTotalAmount &&
                      ((cellData / props.totalSpend) * 100).toFixed(2)}
                    %
                    {showSubTypes &&
                      renderSubTypesRows(
                        cellData,
                        rowData.get('subTypes'),
                        '%'
                      )}
                  </div>
                )
              }}
            />
          )}
          {!tier2 && (
            <Column
              label={
                bySpend ? (
                  <FormattedMessage
                    id='OverviewSpendTable.Potential'
                    defaultMessage='Potential'
                  />
                ) : (
                  <FormattedMessage
                    id='OverviewSpendTable.PotentialCount'
                    defaultMessage='Potential Count'
                  />
                )
              }
              headerClassName='tr'
              dataKey={bySpend ? 'potentialAmount' : 'potentialCount'}
              width={150}
              cellRenderer={renderValueCell}
            />
          )}
          {!tier2 && (
            <Column
              label={
                bySpend ? (
                  <FormattedMessage
                    id='OverviewSpendTable.Disqualified'
                    defaultMessage='Disqualified'
                  />
                ) : (
                  <FormattedMessage
                    id='OverviewSpendTable.DisqualifiedCount'
                    defaultMessage='Disqualified Count'
                  />
                )
              }
              headerClassName='tr'
              dataKey={bySpend ? 'disqualifiedAmount' : 'disqualifiedCount'}
              width={150}
              cellRenderer={renderValueCell}
            />
          )}
          {tier2 && (
            <Column
              label={
                <FormattedMessage
                  id='OverviewSpendTable.Count'
                  defaultMessage='Number of Suppliers'
                />
              }
              dataKey='count'
              width={150}
              flexGrow={1}
              headerClassName='tr'
              cellRenderer={({ cellData }: TableCellProps) => (
                <div className='tr'>{numberFormat(cellData)}</div>
              )}
            />
          )}
        </Table>
      </Paper>
      <Text className='tc mt4'>
        <FormattedMessage
          id='SpendPage.SelectAbove'
          defaultMessage='Use above to filter below'
        />
      </Text>
    </div>
  )

  return tier2 ? renderTable() : <Paper>{renderTable()}</Paper>
}

export default connect(
  (state: RootState) => {
    return {
      loading: state.get('buyer').get('diversityReport').loading,
      isMatchAny: state.getIn([
        'buyer',
        'diversityReport',
        'isMatchAnyCategories',
      ]),
      totalSpend: state.getIn([
        'buyer',
        'insights',
        'spend',
        'overview',
        'totalSpend',
      ]),
      selectedCategories: state.getIn([
        'buyer',
        'diversityReport',
        'selectedCategories',
      ]),
      overviewSpend: getOverviewSpend(state),
      baseRules: state.getIn([
        'buyer',
        'diversityReport',
        'diverseQualificationRules',
        'baseRules',
      ]),
      currentGrouping: state.getIn([
        'buyer',
        'diversityReport',
        'currentGrouping',
      ]),
      selectedGroupingValues: state.getIn([
        'buyer',
        'diversityReport',
        'selectedGroupingValues',
      ]),
    }
  },
  {
    selectDiversityCategory,
    clearAllDiversityCategories,
    setMatchAnyCategories,
    selectGroupingValue,
    changeGrouping,
    clearGroupingValue,
  }
)(DiversityReportOverview)
