import React, { useEffect, useState } from 'react'
import EsgNav from './EsgNav'
import { FormattedMessage } from 'react-intl'
import paths from '../../../routes/paths'
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom'
import DiversityInclusion from './DiversityInclusion'
import Environment from './Environment'
import Governance from './Governance'
import HealthSafety from './HealthSafety'
import Privacy from './Privacy'
import { useSelector, useDispatch } from 'react-redux'
import parsePath from 'shared/utils/parsePath'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { loadSurveyResponse, loadSupplierProfile } from '../../actions'
import surveySelectors from '../../selectors/surveySelectors'
import profileSelectors from '../../selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import RootState from 'shared/models/RootState'
import Loading from 'shared/components/Loading'
import EsgFooter from './EsgFooter'
import Text from 'shared/components/Text'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import ReportFormDialog from 'shared/containers/ReportFormDialog'
import qs from 'qs'
import analytics from 'shared/utils/analytics'

const EsgSurvey = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { supplierId } = params
  const { search } = location
  const { redirectFrom } = qs.parse(search, { ignoreQueryPrefix: true })

  const [openReportDialog, setOpenReportDialog] = useState<boolean>(false)

  const esg = useSelector(surveySelectors.getEsgSurvey)
  const profileId = useSelector((state: RootState) =>
    profileSelectors.getDataField(state, 'id')
  )
  const supplierName = useSelector((state: RootState) =>
    profileSelectors.getDataField(state, 'name')
  )
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const handleClose = () => {
    if (redirectFrom) {
      history.push(redirectFrom)
    } else {
      history.push(parsePath(paths.supplierProfile, { supplierId }))
    }
  }
  const toggleReportDialog = () => {
    setOpenReportDialog(!openReportDialog)
  }
  useEffect(() => {
    // if stated profile isn't the one user wants to look at, reload supplier profile and esg survey
    if (profileId !== supplierId) {
      dispatch(loadSupplierProfile(supplierId))
    }
  })

  useEffect(() => {
    if (esg && profileId === supplierId) {
      dispatch(loadSurveyResponse(esg.get('id')))
      analytics.track('Sustainability Survey Responses Viewed', {
        eventSource: 'Supplier Profile',
        supplierOrgUnitId: supplierId,
        supplierName,
        orgUnitId,
        orgUnitName,
      })
    }
  }, [
    dispatch,
    esg,
    supplierId,
    profileId,
    supplierName,
    orgUnitId,
    orgUnitName,
  ])

  if (profileId !== supplierId) {
    return <Loading />
  }
  return (
    <>
      <div className='mb3 mw8 center'>
        <div className='flex items-center justify-between'>
          <h1 className='f5 fw4 f3-ns fw6-ns mb2 mb3-ns'>
            <FormattedMessage
              id='EsgSurvey.TitleResults'
              defaultMessage={'Sustainability Survey Results'}
            />
          </h1>
          <div className='flex pointer' onClick={toggleReportDialog}>
            <Text className='red mr4 flex items-center'>
              <InfoIcon aria-label='Report a problem' className='mr1' />
              <FormattedMessage
                id='EsgSurvey.ReportESGSurvey'
                defaultMessage='Report a problem'
              />
            </Text>
            <IconButton aria-label='Close' onClick={handleClose}>
              <CloseIcon fontSize='large' />
            </IconButton>
          </div>
        </div>
      </div>
      {esg?.get('id') ? (
        <>
          <EsgNav redirectFrom={redirectFrom} />
          <div className='mb3 mw8 center'>
            <Switch>
              <Route
                path={paths.supplierHealthSafety}
                component={HealthSafety}
              />
              <Route path={paths.supplierEnvironment} component={Environment} />
              <Route
                path={paths.supplierDiversityInclusion}
                component={DiversityInclusion}
              />
              <Route path={paths.supplierGovernance} component={Governance} />
              <Route path={paths.supplierPrivacy} component={Privacy} />
              <Redirect
                to={`${parsePath(paths.supplierHealthSafety, { supplierId })}`}
              />
            </Switch>
          </div>
          <EsgFooter redirectFrom={redirectFrom} />
        </>
      ) : null}

      <ReportFormDialog
        onToggleReportDialog={toggleReportDialog}
        openReportDialog={openReportDialog}
        taskType='reportEsgSurvey'
      />
    </>
  )
}

export default EsgSurvey
