import React, { Component, ReactNode } from 'react'
import {
  DatePicker as MaterialDatePicker,
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from '@material-ui/pickers'
import { FormattedMessage } from 'react-intl'
import { Moment } from 'moment'

type Props = {
  noKeyboard?: boolean
  onChange: (newDate: Moment) => void
  input?: { value: string }
  meta?: { error: string; touched: boolean }
  value?: string | Date | number
  placeholder: string
  name: string
  disablePast?: boolean
  required?: boolean
  autoComplete?: string
  maxDate?: Date | number
  minDate?: Date | number
  maxDateMessage?: ReactNode
  minDateMessage?: ReactNode
} & KeyboardDatePickerProps

class DatePicker extends Component<Props> {
  static defaultProps = {
    placeholder: 'MM/DD/YYYY'
  }

  disableOnBlur = e => {
    e.preventDefault()
  }

  render() {
    const { input, meta, value, noKeyboard, ...otherProps } = this.props
    const { value: inputValue, ...restInput } = input || { value: '' }
    const sharedProps = {
      autoOk: true,
      value: inputValue || value || null,
      ...restInput,
      ...otherProps,
      onBlur: this.disableOnBlur,
      format: 'MM/DD/YYYY',
      invalidDateMessage: (
        <FormattedMessage
          id='DatePicker.InvalidDateMessage'
          defaultMessage='Invalid date format'
        />
      )
    }

    return !!noKeyboard ? (
      <MaterialDatePicker
        {...sharedProps}
        leftArrowButtonProps={{ 'aria-label': 'go back one month' }}
        rightArrowButtonProps={{ 'aria-label': 'go forward one month' }}
        variant='inline'
        inputVariant='outlined'
      />
    ) : (
      <KeyboardDatePicker
        {...sharedProps}
        color='secondary'
        leftArrowButtonProps={{ 'aria-label': 'go back one month' }}
        rightArrowButtonProps={{ 'aria-label': 'go forward one month' }}
        variant='inline'
        inputVariant='outlined'
        KeyboardButtonProps={{
          style: {
            padding: 0
          }
        }}
        InputAdornmentProps={{ position: 'start' }}
      />
    )
  }
}

export default DatePicker
