import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import Immutable from 'immutable'

export function* setRecordAsFrozenSaga(action) {
  const { isFrozen } = Immutable.Iterable.isIterable(action.payload)
    ? action.payload.toJS()
    : action.payload

  let updatedOrgUnit = yield call(supplier.update, {
    isFrozen: isFrozen,
  })

  return yield updatedOrgUnit
}

export default createApiRequestSaga(setRecordAsFrozenSaga)
