import React, { useState } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import LoaderPage from '../../components/LoaderPage'
import { UPLOAD_FILE_TO_DATA_LOADER } from '../../store/actionTypes'
import { getFormSubmitErrors } from 'shared/reducers'
import Page from 'shared/components/Page'
import LoaderState from 'admin/components/LoaderState'

type Props = {
  errorMessage: string
  handleSubmit: () => void
}
const LoaderPageContainer = ({ errorMessage, handleSubmit }: Props) => {
  const [selectedTab, setSelectedTab] = useState(0)
  return (
    <Page title={'Data Loader'}>
      <Tabs
        value={selectedTab}
        onChange={(e, newTab) => setSelectedTab(newTab)}
        aria-label='data loader tabs'
        indicatorColor='primary'
        textColor='primary'
      >
        <Tab label='Data Loader' />
        <Tab label='Loader State' />
      </Tabs>

      {selectedTab === 0 && (
        <LoaderPage onUploadFile={handleSubmit} errorMessage={errorMessage} />
      )}
      {selectedTab === 1 && <LoaderState />}
    </Page>
  )
}

const formName = 'dataPortal/loader/uploadFile'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)

export default connectReduxForm(
  state => ({
    errorMessage: formSubmitErrorsSelector(state)
  }),
  {
    onSubmit: createSubmitFormAction(formName, UPLOAD_FILE_TO_DATA_LOADER)
  },
  {
    form: formName
  },
  LoaderPageContainer
)
