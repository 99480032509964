import React, { useState, useEffect } from 'react'
import Page from 'shared/components/Page'
import Button from 'shared/components/Button'
import FileInput from 'shared/components/FileInput'
import Card from 'shared/components/Card'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import { useDispatch } from 'react-redux'
import { uploadDataQualityFile, resetDataQualityState } from '../../store/actions'
import { useSelector } from 'react-redux'
import dataQualitySelectors from '../../store/selectors/dataQualitySelectors'
import DataQualityColumnsMapping from './DataQualityColumnsMapping'
import DataQualityUploadInstructions from './DataQualityUploadInstructions'
import { BodOutput } from '../../store/reducers/dataQualityReducer/dataQualityReducer'
import CheckboxList from 'shared/components/CheckboxList'
import { List } from 'immutable'

const DataQualityServiceUpload = () => {
  const dispatch = useDispatch()
  const [vendorFile, setVendorFile] = useState<File | null>(null)
  const [spendFile, setSpendFile] = useState<File | null>(null)

  const isUploadingVendor = useSelector(dataQualitySelectors.isUploadingVendor)
  const isUploadingSpend = useSelector(dataQualitySelectors.isUploadingSpend)

  const isSubmittingColumnsMappingVendor = useSelector(dataQualitySelectors.isSubmittingColumnsMappingVendor)
  const isSubmittingColumnsMappingSpend = useSelector(dataQualitySelectors.isSubmittingColumnsMappingSpend)

  const vendorColumnsMapping: BodOutput = useSelector(
    dataQualitySelectors.getColumnsMappingVendor
  )
  const spendColumnsMapping: BodOutput = useSelector(
    dataQualitySelectors.getColumnsMappingSpend
  )

  const completedVendorMapping: {[key: string]: string} = useSelector(
    dataQualitySelectors.getCompletedColumnsMappingVendor
  )

  const vendorJobId = useSelector(dataQualitySelectors.getVendorUploadJobId)
  const spendJobId = useSelector(dataQualitySelectors.getSpendUploadJobId)

  const vendorFilename = useSelector(dataQualitySelectors.getVendorUploadFilename)
  const spendFilename = useSelector(dataQualitySelectors.getSpendUploadFilename)

  const services = List([
    {value: "vendor", label:"Vendor", id:"0"}, 
    {value: "spend", label:"Vendor and Spend", id:"1"}
  ])
  const [service, setService] = useState("vendor")
  const [type, setType] = useState("vendor")
  const [vendorColumnsMappingComplete, setVendorColumnsMappingComplete] = useState(false)
  const [spendColumnsMappingComplete, setSpendColumnsMappingComplete] = useState(false)
  useEffect(() => {
    if(isSubmittingColumnsMappingVendor) {
      setVendorColumnsMappingComplete(true)
      if(service === 'spend'){
        setType('spend')
      }
      else {
        setSpendColumnsMappingComplete(true)
      }
    }
  }, [isSubmittingColumnsMappingVendor, service, type, vendorColumnsMappingComplete, spendColumnsMappingComplete])

  useEffect(() => {
    if(isSubmittingColumnsMappingSpend) {
      setSpendColumnsMappingComplete(true)
    }
  }, [isSubmittingColumnsMappingSpend, spendColumnsMappingComplete])

  useEffect(() => {
    if(spendColumnsMappingComplete && !isSubmittingColumnsMappingSpend && !isSubmittingColumnsMappingVendor) {
      dispatch(resetDataQualityState())
      setType('vendor')
      setService('vendor')
      setVendorFile(null)
      setSpendFile(null)
      setSpendColumnsMappingComplete(false)
      setVendorColumnsMappingComplete(false)
    }
  }, [isSubmittingColumnsMappingSpend, spendColumnsMappingComplete, isSubmittingColumnsMappingVendor, dispatch])

  const handleServiceToggle = (targetValue: String) => {
    if(targetValue === "vendor") {
      setService("vendor")
      setType('vendor')
    }
    else if (targetValue === "spend") {
      setService("spend")
    }
  }

  const handleTypeToggle = (event) => {
    event.preventDefault()
    if(type === "vendor" && service === 'spend') {
      setSpendFile(null)
      setType("spend")
    }
    else if (type === "spend") {
      setVendorFile(null)
      setType("vendor")
    }
  }
  
  const handleFileChange = (selectFile: File) => {
    if(type === "vendor") {
      setVendorFile(selectFile)
    }
    else {
      setSpendFile(selectFile)
    }
    
  }

  useEffect(() => {
    if(vendorJobId && service === 'spend' && spendFile) {
      dispatch(uploadDataQualityFile({ file: spendFile, type: 'spend', relatedId: vendorJobId}))
    }
  }, [vendorJobId, service, spendFile, dispatch])

  const handleFileUpload = () => {
    setType("vendor")
    if (vendorFile) {
      dispatch(uploadDataQualityFile({ file: vendorFile, type: 'vendor', relatedId: null }))
    }
  }

  return (
    <Page title='Data Quality Service - Upload'>
      <Main>
        <Card>
          {!vendorJobId && (
          <div>
            <label className='db mb1 f7 fw6'>Upload {type === "vendor" ? "Vendor Master" : "Spend"} File</label>
            <FileInput
              accept='.xlsx, .csv'
              className='mt3'
              name='file'
              value={type === "vendor" ? vendorFile?.name : spendFile?.name}
              onChange={handleFileChange}
            />
            <CheckboxList
              list={services}
              selected={List([service])}
              onChange={handleServiceToggle}
            ></CheckboxList>
            <div className='mt3'>
              {service === 'spend' && (
                <Button
                  autoSize
                  type='button'
                  onClick={handleTypeToggle}
                  disabled={isUploadingVendor || isUploadingSpend || (type === 'vendor' && !vendorFile)}
                >
                  {type === 'vendor' ? 'Choose Spend File' : 'Change Vendor File'}
                </Button>
              )}
              {(service === 'vendor' || type === 'spend') && (
              <Button
                autoSize
                onClick={handleFileUpload}
                disabled={isUploadingVendor || isUploadingSpend || !vendorFile || ((!spendFile || isUploadingSpend) && type === 'spend' && service === 'spend')}
              >
                Upload
              </Button>
              )}
            </div>
          </div>)}
          {type === 'vendor' && !vendorColumnsMappingComplete && !isSubmittingColumnsMappingVendor && vendorJobId && vendorFilename && vendorColumnsMapping && (
            <DataQualityColumnsMapping
              jobId={vendorJobId}
              type={type}
              filename={vendorFilename}
              columnsMapping={vendorColumnsMapping}
            />
          )}
          {type === 'spend' && service === 'spend' && !spendColumnsMappingComplete && vendorColumnsMappingComplete && !isSubmittingColumnsMappingSpend && vendorJobId && vendorColumnsMapping && spendJobId && spendFilename && spendColumnsMapping && (
            <DataQualityColumnsMapping
              jobId={spendJobId}
              type={type}
              filename={spendFilename}
              columnsMapping={spendColumnsMapping}
              altMapping={completedVendorMapping}
              altFilename={vendorFilename}
              altJobId={vendorJobId}
            />
          )}
          
        </Card>
        <Card>
          <DataQualityUploadInstructions/>
        </Card>
      </Main>
      <Aside></Aside>
    </Page>
  )
}

export default DataQualityServiceUpload
