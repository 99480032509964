import React from 'react'
import {
  useIntl,
  defineMessages,
  FormattedMessage,
  FormattedDate
} from 'react-intl'

const messages = defineMessages({
  ContractRequestClosed: {
    id: 'NotesByCell.ContractRequestClosed',
    defaultMessage: 'Contract Closed'
  },
  ContractRequestRenewed: {
    id: 'NotesByCell.ContractRequestRenewed',
    defaultMessage: 'Contract Renewed'
  },
  selfCertifySent: {
    id: 'NotesByCell.selfCertifySent',
    defaultMessage: `Self Certify Requested`
  },
  selfCertifyResent: {
    id: 'NotesByCell.selfCertifyResent',
    defaultMessage: `Self Certify Request Resent`
  },
  selfCertifyCancelled: {
    id: 'NotesByCell.selfCertifyCancelled',
    defaultMessage: `Self Certify Request Cancelled`
  },
  supplierInvitedRatedEcoVadis: {
    id: 'NotesByCell.InvitedRatedEcoVadis',
    defaultMessage: 'The Supplier is invited to be rated by EcoVadis'
  },
  actionRequiredEcovadis: {
    id: 'NotesByCell.EcoVadisActionRequired',
    defaultMessage:
      'Action Required: Please contact EcoVadis for more information.'
  },
  esgSurveySent: {
    id: 'NotesByCell.esgSurveySent',
    defaultMessage: `Sustainability Survey Requested`
  },
  esgSurveyResent: {
    id: 'NotesByCell.esgSurveyResent',
    defaultMessage: `Sustainability Survey Request Resent`
  },
  esgSurveyCancelled: {
    id: 'NotesByCell.esgSurveyCancelled',
    defaultMessage: `Sustainability Survey Request Cancelled`
  },
  esgSurveyInProgress: {
    id: 'NotesByCell.esgSurveyInProgress',
    defaultMessage: `Sustainability Survey Started`
  },
  esgSurveyStarted: {
    id: 'NotesByCell.esgSurveyStarted',
    defaultMessage: `Sustainability Survey Started on {date}`
  },
  esgSurveyLastAccessed: {
    id: 'NotesByCell.esgSurveyLastAccessed',
    defaultMessage: `The survey was last accessed by this supplier on {date}`
  },
  esgSurveyLastViewed: {
    id: 'NotesByCell.esgSurveyLastViewed',
    defaultMessage: `The survey was last viewed by this supplier on {date}`
  },
  esgSurveyViewed: {
    id: 'NotesByCell.esgSurveyViewed',
    defaultMessage: `Sustainability Survey Viewed`
  },
  esgSurveyCompleted: {
    id: 'NotesByCell.esgSurveyCompleted',
    defaultMessage: `Sustainability Survey is complete`
  },
  esgSurveyExpired: {
    id: 'NotesByCell.esgSurveyExpired',
    defaultMessage: `Sustainability Survey Expired on {date}`
  }
})

type Props = {
  outreachType: string
  outreachStatus: string
  contractExpiryDate: Date
  certificationAgency: string
  certificationSubCategory: string
  dateESGSurveyStarted?: Date
  dateESGSurveyLastViewed?: Date
  dateESGSurveyLastAccessed?: Date
  actionRequiredEcovadis?: boolean
  archivedTask?: boolean
  dateExpired: Date
}
const NotesByCell = ({
  outreachType,
  outreachStatus,
  contractExpiryDate,
  certificationAgency,
  certificationSubCategory,
  actionRequiredEcovadis,
  dateESGSurveyStarted,
  dateESGSurveyLastViewed,
  dateESGSurveyLastAccessed,
  archivedTask,
  dateExpired
}: Props) => {
  const intl = useIntl()
  const status = outreachStatus
  let notes
  if (
    outreachType === 'ExpiringContract' &&
    (status === 'Created' || status === 'WaitingForClient')
  ) {
    notes = (
      <>
        <FormattedMessage
          id='NotesByCell.ContractRequestCreated'
          defaultMessage='Contract Expiring on '
        />{' '}
        <FormattedDate
          value={contractExpiryDate}
          year='numeric'
          month='long'
          day='numeric'
          weekday='long'
        />
      </>
    )
  } else if (outreachType === 'ExpiringContract' && status === 'Cancelled') {
    notes = intl.formatMessage(messages.ContractRequestClosed)
  } else if (outreachType === 'ExpiringContract' && status === 'Completed') {
    notes = intl.formatMessage(messages.ContractRequestRenewed)
  } else if (outreachType === 'SelfCertifyRequest' && status === 'Cancelled') {
    notes = intl.formatMessage(messages.selfCertifyCancelled)
  } else if (
    outreachType === 'SelfCertifyRequest' &&
    status === 'WaitingForClient'
  ) {
    notes = intl.formatMessage(messages.selfCertifyResent)
  } else if (outreachType === 'SelfCertifyRequest' && status === 'Completed') {
    let certAgency = certificationAgency
    let subCategory = certificationSubCategory.toUpperCase()
    notes = ` ${certAgency} for ${subCategory} is complete`
  } else if (outreachType === 'SelfCertifyRequest') {
    notes = intl.formatMessage(messages.selfCertifySent)
  } else if (outreachType === 'ESGSurveyRequest' && status === 'Cancelled') {
    notes = intl.formatMessage(messages.esgSurveyCancelled)
  } else if (
    outreachType === 'ESGSurveyRequest' &&
    status === 'WaitingForClient'
  ) {
    notes = intl.formatMessage(messages.esgSurveyResent)
  } else if (outreachType === 'ESGSurveyRequest' && status === 'InProgress') {
    let dateStarted =
      dateESGSurveyStarted &&
      intl.formatDate(dateESGSurveyStarted, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      })
    let dateLastAccessed =
      dateESGSurveyLastAccessed &&
      intl.formatDate(dateESGSurveyLastAccessed, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      })
    notes = dateESGSurveyLastAccessed
      ? intl.formatMessage(messages.esgSurveyLastAccessed, {
          date: dateLastAccessed
        })
      : dateESGSurveyStarted
      ? intl.formatMessage(messages.esgSurveyStarted, { date: dateStarted })
      : intl.formatMessage(messages.esgSurveyInProgress)
  } else if (outreachType === 'ESGSurveyRequest' && status === 'SurveyViewed') {
    let dateLastViewed =
      dateESGSurveyLastViewed &&
      intl.formatDate(dateESGSurveyLastViewed, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      })
    notes = dateESGSurveyLastViewed
      ? intl.formatMessage(messages.esgSurveyLastViewed, {
          date: dateLastViewed
        })
      : intl.formatMessage(messages.esgSurveyViewed)
  } else if (outreachType === 'ESGSurveyRequest' && status === 'Completed') {
    notes = intl.formatMessage(messages.esgSurveyCompleted)
  } else if (outreachType === 'ESGSurveyRequest' && status === 'Expired') {
    const date = intl.formatDate(dateExpired, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long'
    })
    notes = intl.formatMessage(messages.esgSurveyExpired, { date })
  } else if (outreachType === 'ESGSurveyRequest') {
    notes = intl.formatMessage(messages.esgSurveySent)
  } else if (outreachType === 'EcoVadisAssessment') {
    if (actionRequiredEcovadis) {
      notes = intl.formatMessage(messages.actionRequiredEcovadis)
    }
    if (archivedTask) {
      notes = status
    } else {
      notes = intl.formatMessage(messages.supplierInvitedRatedEcoVadis)
    }
  }
  return <div className={actionRequiredEcovadis ? 'red' : ''}>{notes}</div>
}

export default NotesByCell
