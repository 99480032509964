import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Paper from 'shared/components/Paper'
import { FormattedMessage } from 'react-intl'
import BuyerRequestAccess from '../../components/EcovadisRating/BuyerRequestAccess'
import {
  EcovadisCredentials,
  getEcovadisCredentials,
  getByField,
} from 'buyer/SupplierProfile/selectors/integrationSelectors/integrationSelectors'
import ecovadisLogo from 'shared/assets/images/ecovadis-logo-transparent.png'
import dateFormat from 'shared/utils/dateFormat'
import moment from 'moment'
import {
  retrieveEcovadisAssessmentStatus,
  retrieveEcovadisScorecard,
} from 'buyer/SupplierProfile/actions'
import RootState from 'shared/models/RootState'
import profileSelectors from 'buyer/SupplierProfile/selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { RecordOf } from 'immutable'
import EcovadisRating from 'buyer/SupplierProfile/components/EcovadisRating'
import analytics from 'shared/utils/analytics'

export type EcovadisScorecard = RecordOf<{
  size: number
  expired: boolean
  published_date: string
  global_score: number
  env_score: number
  lab_score: number
  fbp_score: number
  sup_score: number
  global_trend: string
  env_trend: string
  lab_trend: string
  fbp_trend: string
  sup_trend: string
  scorecard_link: string
}>

const EcovadisRatingContainer = () => {
  const dispatch = useDispatch()
  const [mountTime] = useState(moment().toDate().getTime())
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )
  const ecovadisScorecard: EcovadisScorecard = useSelector((state: RootState) =>
    getByField(state, 'ecovadisScorecard')
  )
  const supplierId = useSelector((state: RootState) =>
    profileSelectors.getDataField(state, 'id')
  )
  const supplierName = useSelector((state: RootState) =>
    profileSelectors.getDataField(state, 'name')
  )
  const ecovadisAssessmentStatus: string | undefined = useSelector(
    (state: RootState) => getByField(state, 'ecovadisAssessmentStatus')
  )

  const buyerCredentials: EcovadisCredentials = useSelector(
    getEcovadisCredentials
  )

  useEffect(() => {
    if (ecovadisAssessmentStatus === 'resultsAvailable') {
      dispatch(retrieveEcovadisScorecard({ supplierId }))
      analytics.track('EcoVadis Supplier Rating Viewed', {
        supplierOrgUnitId: supplierId,
        supplierName,
        orgUnitId,
        orgUnitName,
      })
    }
  }, [
    dispatch,
    ecovadisAssessmentStatus,
    supplierId,
    supplierName,
    orgUnitId,
    orgUnitName,
  ])

  useEffect(() => {
    dispatch(retrieveEcovadisAssessmentStatus({ supplierId }))
    return () => {
      analytics.track('EcoVadis Tab Clicked', {
        duration: (moment().diff(mountTime) / 1000).toFixed(2),
        supplierOrgUnitId: supplierId,
        supplierName,
        orgUnitId,
        orgUnitName,
      })
    }
  }, [dispatch, supplierId, supplierName, mountTime, orgUnitId, orgUnitName])

  return (
    <Paper>
      <div
        className={
          buyerCredentials.get('credentialsSubmitted') &&
          ecovadisAssessmentStatus === 'resultsAvailable'
            ? 'mt4 pa4-ns pa2'
            : 'mt4 bg-near-white pa4'
        }
      >
        <div className='pb4-ns pb3 flex justify-between'>
          <div className='mid-gray f8 flex flex-column'>
            <FormattedMessage
              id='EcovadisRatingReport.poweredby'
              defaultMessage='Powered by'
            />

            <a
              href='https://ecovadis.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                alt='ecovadisLogo'
                src={ecovadisLogo}
                style={{ width: 75 }}
              />
            </a>
          </div>
          {buyerCredentials.get('credentialsSubmitted') &&
            !!ecovadisScorecard?.size &&
            dateFormat(ecovadisScorecard.get('published_date')) && (
              <div className='flex flex-column'>
                <div className='mid-gray f7 fw5 mb1 self-end'>
                  <FormattedMessage
                    id='EcovadisRatingContainer.published'
                    defaultMessage='Published: {date}'
                    values={{
                      date:
                        !!ecovadisScorecard?.size &&
                        dateFormat(ecovadisScorecard.get('published_date')),
                    }}
                  />
                </div>

                {!!ecovadisScorecard?.size &&
                ecovadisScorecard.get('expired') ? (
                  <>
                    <div className='red f8 self-end'>
                      <FormattedMessage
                        id='EcovadisRatingContainer.expired'
                        defaultMessage='Expired: {date}'
                        values={{
                          date:
                            !!ecovadisScorecard?.size &&
                            dateFormat(
                              moment(ecovadisScorecard.get('published_date'))
                                .add(1, 'y')
                                .toISOString()
                            ),
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className='mid-gray f8 self-end'>
                    <FormattedMessage
                      id='EcovadisRatingContainer.expiry'
                      defaultMessage='Expiry: {date}'
                      values={{
                        date:
                          !!ecovadisScorecard?.size &&
                          dateFormat(
                            moment(ecovadisScorecard.get('published_date'))
                              .add(1, 'y')
                              .toISOString()
                          ),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
        </div>

        {!buyerCredentials.get('credentialsSubmitted') ? (
          <BuyerRequestAccess />
        ) : (
          <EcovadisRating
            ecovadisAssessmentStatus={ecovadisAssessmentStatus}
            ecovadisScorecard={ecovadisScorecard}
          />
        )}
      </div>
    </Paper>
  )
}

export default EcovadisRatingContainer
