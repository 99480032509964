import React, { FormEvent, useEffect, useState, useMemo } from 'react'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as Tealbot } from 'shared/assets/images/Tealbot-Celebratory.svg'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import {
  reduxForm,
  Field,
  formValueSelector,
  isSubmitting
} from 'redux-form/immutable'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import { CREATE_PASSWORD } from 'accounts/actionTypes'
import { getFormSubmitErrors } from 'shared/reducers'
import Button from 'shared/components/Button'
import AgreeToTerms from 'shared/components/AgreeToTerms'
import RootState from 'shared/models/RootState'
import storageManager from 'shared/utils/storageManager'
import validateToken from 'accounts/containers/CreatePasswordForm/validateToken'
import formValidators from 'shared/utils/formValidators'
import { RecordOf } from 'immutable'
import Warning from 'shared/components/Warning'
import { connect } from 'react-redux'
import { InjectedFormProps } from 'redux-form'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import { useSelector } from 'react-redux'

const validateConfirmPassword = (
  value: string,
  allValues: RecordOf<FormFields>
) =>
  value && value === allValues.get('password') ? '' : "Passwords don't match"

type FormFields = {
  password: string
}

type Props = {
  submitting: boolean
  errorMessage: string
  invalid: boolean
  handleSubmit: (formValues: {}) =>
    | ((event: FormEvent<HTMLFormElement>) => void)
    | undefined
  createPasswordRequest: (userAndPassword: {
    user?: Omit<FormFields, 'password'>
    orgUnitId?: string
    redirectTo?: string
    password: string
    token: string
  }) => void
  onClose: (redirect?: boolean) => void
} & InjectedFormProps<FormFields>

const UpdatePassword = (props: Props) => {
  const {
    handleSubmit,
    invalid,
    submitting,
    createPasswordRequest,
    errorMessage,
    onClose
  } = props
  const [passwordRules, setPasswordRules] = useState<any>(undefined)
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false)
  const token = storageManager.getItem('accessToken') || ''
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)

  useEffect(() => {
    if (token) {
      validateToken(token).then(response => {
        if (response.hasValidToken) {
          setPasswordRules(response.passwordRules)
        }
      })
    }
  }, [token])

  const passwordRulesValidator = useMemo(() => {
    return formValidators.createPasswordRulesValidator(passwordRules)
  }, [passwordRules])

  const handleChangeAgreeToTerms = () => {
    setAgreeToTerms(() => !agreeToTerms)
  }

  const handleOnSubmit = (formValues: RecordOf<FormFields>) => {
    if (agreeToTerms) {
      createPasswordRequest({
        user: {
          termsAcceptedDate: new Date().toISOString()
        },
        orgUnitId,
        password: formValues.get('password'),
        token
      })
      onClose()
    }
  }

  return (
    <div className='flex mb3'>
      <div className='dib w-100 w-third-ns pt6'>
        <Tealbot />
      </div>
      <div className='dib w-100 w-two-thirds-ns flex-wrap pl3'>
        <div className='flex justify-between'>
          <Typography variant='h6'>
            <FormattedMessage
              id='UpdatePassword.Header'
              defaultMessage={
                'Thank You for completing the Sustainability Survey!'
              }
            />
          </Typography>
          <IconButton onClick={() => onClose(true)}>
            <CloseIcon />
          </IconButton>
        </div>
        <p className='lh-copy fw4 mid-gray'>
          <FormattedMessage
            id='UpdatePassword.Body1'
            defaultMessage={`Thank you for submitting your answers to the sustainability survey. Your responses are now visible to both current customers and prospects in TealBook who are interested in learning more about your organization's sustainability initiatives.`}
          />
        </p>
        <p className='lh-copy fw4 mid-gray'>
          <FormattedMessage
            id='UpdatePassword.Body2'
            defaultMessage={`To continue taking advantage of your organization's presence in TealBook, <b>create a free account</b> and claim your supplier profile.`}
            values={{
              b: b => <span className='fw8'>{b}</span>
            }}
          />
        </p>
        <p className='lh-copy fw4 mid-gray'>
          <FormattedMessage
            id='UpdatePassword.Body3'
            defaultMessage={`Sign up now and become part of the world's most comprehensive supplier network.`}
          />
        </p>
        <form onSubmit={handleSubmit(handleOnSubmit)} className='center tl'>
          <div>
            <Label htmlFor='password' required noPadding>
              <FormattedMessage
                id='CreatePasswordForm.Password'
                defaultMessage='Password'
              />
            </Label>
            <Field
              name='password'
              component={Input}
              type='password'
              validate={[passwordRulesValidator]}
              required
              autoComplete='new-password'
            />
          </div>
          <div>
            <Label htmlFor='confirmPassword' required>
              <FormattedMessage
                id='CreatePasswordForm.ConfirmPassword'
                defaultMessage='Confirm Password'
              />
            </Label>
            <Field
              name='confirmPassword'
              component={Input}
              type='password'
              validate={[validateConfirmPassword]}
              required
              autoComplete='new-confirm-password'
            />
          </div>

          <AgreeToTerms
            agreeToTerms={agreeToTerms}
            onChange={handleChangeAgreeToTerms}
            className='mt3'
          />

          {errorMessage && <Warning className='mt3' message={errorMessage} />}

          <Button
            type='submit'
            label={
              <FormattedMessage
                id='UpdatePassword.SignUp'
                defaultMessage='Sign Up'
              />
            }
            className='mt2'
            disabled={!agreeToTerms || invalid || submitting}
          />
        </form>
      </div>
    </div>
  )
}

const formName = 'createPassword'
const formSubmitErrorsSelector = getFormSubmitErrors(formName)
const selector = formValueSelector(formName)
const submittingSelector = isSubmitting(formName)

type ConnectProps = {
  onClose: () => void
}

export default connect(
  (state: RootState, props: ConnectProps) => ({
    submitting: submittingSelector(state),
    errorMessage: formSubmitErrorsSelector(state),
    password: selector(state, 'password')
  }),
  {
    createPasswordRequest: createSubmitFormAction(formName, CREATE_PASSWORD)
  }
)(
  reduxForm({
    form: formName
  })(UpdatePassword)
)
