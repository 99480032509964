import { loadESGRequestDetails } from 'buyer/SupplierProfile/actions'
import { updateData } from 'shared/actions'
import { put } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'

export function* requestNewContactForESGSurveySaga(action) {
  const requestedDate = new Date().toISOString()
  const {
    taskId,
    userId,
    supplierId,
    requestedContactId,
    additionalRequestContactIds
  } = action.payload
  if (additionalRequestContactIds && additionalRequestContactIds.length > 0) {
    yield put(
      updateData({
        entity: 'tasks',
        record: {
          id: taskId,
          form: {
            requestedContactId: requestedContactId,
            additionalRequestContactIds: additionalRequestContactIds
          },
          created: {
            date: requestedDate,
            user: userId
          }
        }
      })
    )
  } else {
    yield put(
      updateData({
        entity: 'tasks',
        record: {
          id: taskId,
          form: {
            requestedContactId: requestedContactId
          },
          created: {
            date: requestedDate,
            user: userId
          }
        }
      })
    )
  }
  return yield put(loadESGRequestDetails(supplierId))
}

export default createApiRequestSaga(requestNewContactForESGSurveySaga)
