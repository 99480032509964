import RootState from 'shared/models/RootState'
import { createSelector } from 'reselect'
import { RecordOf, Map, List, fromJS } from 'immutable'
import {
  AttachmentType,
  SurveyResponse,
  SurveyResponseDetail
} from '../../reducers/surveyReducer/surveyReducer'
import moment from 'moment'

export const isLoading = (state: RootState) => {
  return state.getIn(['supplier', 'survey', 'loading'])
}

export const isUploading = (state: RootState) => {
  return state.getIn(['supplier', 'survey', 'uploading'])
}

export const getEsgSurveyId = createSelector(
  (state: RootState) => state.getIn(['supplier', 'survey', 'byId']),
  (byId: Map<string, RecordOf<SurveyResponse>>) => {
    const surveys = byId
      ?.toList()
      .filter(survey => survey.get('type') === 'esg')
    const sortedSurveys = surveys?.sort((survey1, survey2) => {
      const date1 = moment(survey1.getIn(['created', 'date']))
      const date2 = moment(survey2.getIn(['created', 'date']))

      return date1.isBefore(date2) ? -1 : 1
    })
    const latestSurvey: RecordOf<SurveyResponse> = sortedSurveys?.last()
    return latestSurvey?.get('id')
  }
)

export const getEsgSurveyDaysRemaining = createSelector(
  (state: RootState) =>
    state.getIn(['supplier', 'survey', 'surveyInvitations']),
  surveyInvitations => {
    const activeInvitations = surveyInvitations?.filter(
      invite =>
        invite.get('status') !== 'Completed' &&
        invite.get('status') !== 'Cancelled'
    )
    const sortedInvitations = activeInvitations?.sort((invite1, invite2) => {
      const date1 = moment(invite1.getIn(['created', 'date']))
      const date2 = moment(invite2.getIn(['created', 'date']))

      return date1.isAfter(date2) ? -1 : 1
    })
    const latestInvitation = sortedInvitations?.first()
    const startDate = latestInvitation?.getIn(['created', 'date'])
    return 28 - Math.floor((Date.now() - Date.parse(startDate)) / 86400000)
  }
)

export const hasSubmitted = (state: RootState, surveyId: string) => {
  const survey: RecordOf<SurveyResponse> = state.getIn([
    'supplier',
    'survey',
    'byId',
    surveyId
  ])
  return !!survey?.get('attestedDate')
}

export const getSurveyById = (
  state: RootState,
  id: string
): RecordOf<SurveyResponse> => {
  return state.getIn(['supplier', 'survey', 'byId', id])
}

export const getSurveyResponseDetails = (
  state: RootState
): Map<string, RecordOf<SurveyResponseDetail>> => {
  return state.getIn(['supplier', 'survey', 'responseDetails'])
}

export const getResponseDetailByPageId = createSelector(
  getSurveyResponseDetails,
  (state: RootState, pageId: string) => pageId,
  (details: Map<string, RecordOf<SurveyResponseDetail>>, pageId: string) => {
    return details
      ?.toList()
      .filter(response => response.get('pageId') === pageId)
      .sort((res1, res2) => {
        return moment(res1.getIn(['created', 'date'])).isBefore(
          moment(res2.getIn(['created', 'date']))
        )
          ? 1
          : -1
      })
  }
)

export const getResponseDetailByIds = createSelector(
  getResponseDetailByPageId,
  (
    state: RootState,
    pageId: string,
    questionId: string,
    parentQuestionId: string
  ) => parentQuestionId,
  (
    state: RootState,
    pageId: string,
    questionId: string,
    parentQuestionId: string
  ) => questionId,
  (
    details: List<RecordOf<SurveyResponseDetail>>,
    parentQuestionId: string,
    questionId: string
  ) => {
    return details.find((response: RecordOf<SurveyResponseDetail>) => {
      let matchParentQuestionId = true,
        matchQuestionId = true

      if (questionId) {
        matchQuestionId = response.get('questionId') === questionId
      }

      if (parentQuestionId) {
        matchParentQuestionId =
          response.get('parentQuestionId') === parentQuestionId
      }
      return matchParentQuestionId && matchQuestionId
    })
  }
)

export const getResponsesByPage = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'healthSafety'),
  (state: RootState) => getResponseDetailByPageId(state, 'environment'),
  (state: RootState) => getResponseDetailByPageId(state, 'diversityInclusion'),
  (state: RootState) => getResponseDetailByPageId(state, 'governance'),
  (state: RootState) => getResponseDetailByPageId(state, 'privacy'),
  (
    healthSafetyResponses,
    environmentResponses,
    diversityInclusionResponses,
    governanceResponses,
    privacyResponses
  ) => {
    return fromJS({
      healthSafetyResponses,
      environmentResponses,
      diversityInclusionResponses,
      governanceResponses,
      privacyResponses
    })
  }
)

export const getEsgQuickFacts = createSelector(
  (state: RootState) => getResponsesByPage(state),
  responses => {
    const positiveResponseKeys = ['yes', 'true']
    const uniqueDocs = {
      total: {},
      healthSafetyDocs: {},
      environmentDocs: {},
      diversityInclusionDocs: {},
      governanceDocs: {},
      privacyDocs: {}
    }

    let healthSafetyCerts = 0,
      environmentCerts = 0,
      diversityInclusionCerts = 0,
      governanceCerts = 0,
      privacyCerts = 0

    let healthSafetyPositives = 0,
      environmentPositives = 0,
      diversityInclusionPositives = 0,
      governancePositives = 0,
      privacyPositives = 0

    let supplierParticipantIds: string[] = []

    responses.get('healthSafetyResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        healthSafetyCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.healthSafetyDocs[attachment.get('description')] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        healthSafetyPositives += 1
      }
      const responseCreatedBy = response.get('created')
      if (!supplierParticipantIds.includes(responseCreatedBy.get('user'))) {
        supplierParticipantIds.push(responseCreatedBy.get('user'))
      }
    })
    responses.get('environmentResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        environmentCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.environmentDocs[attachment.get('description')] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        environmentPositives += 1
      }
      const responseCreatedBy = response.get('created')
      if (!supplierParticipantIds.includes(responseCreatedBy.get('user'))) {
        supplierParticipantIds.push(responseCreatedBy.get('user'))
      }
    })
    responses.get('diversityInclusionResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        diversityInclusionCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.diversityInclusionDocs[
              attachment.get('description')
            ] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        diversityInclusionPositives += 1
      }
      const responseCreatedBy = response.get('created')
      if (!supplierParticipantIds.includes(responseCreatedBy.get('user'))) {
        supplierParticipantIds.push(responseCreatedBy.get('user'))
      }
    })
    responses.get('governanceResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        governanceCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.governanceDocs[attachment.get('description')] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        governancePositives += 1
      }
      const responseCreatedBy = response.get('created')
      if (!supplierParticipantIds.includes(responseCreatedBy.get('user'))) {
        supplierParticipantIds.push(responseCreatedBy.get('user'))
      }
    })
    responses.get('privacyResponses')?.forEach(response => {
      if (response.get('attachmentType') === 'cert') {
        privacyCerts += response.get('attachments')?.size || 0
      }
      if (response.get('attachmentType') === 'doc') {
        response
          .get('attachments')
          ?.forEach((attachment: RecordOf<AttachmentType>) => {
            uniqueDocs.privacyDocs[attachment.get('description')] = true
            uniqueDocs.total[attachment.get('description')] = true
          })
      }
      if (
        positiveResponseKeys.includes(response.get('answer')) &&
        !response.get('parentQuestionId')
      ) {
        privacyPositives += 1
      }
      const responseCreatedBy = response.get('created')
      if (!supplierParticipantIds.includes(responseCreatedBy.get('user'))) {
        supplierParticipantIds.push(responseCreatedBy.get('user'))
      }
    })

    return {
      certificatesAdded: {
        total:
          healthSafetyCerts +
          environmentCerts +
          diversityInclusionCerts +
          governanceCerts +
          privacyCerts,
        healthSafety: healthSafetyCerts,
        environment: environmentCerts,
        diversityInclusion: diversityInclusionCerts,
        governance: governanceCerts,
        privacy: privacyCerts
      },
      documentsAdded: {
        total: Object.keys(uniqueDocs.total).length,
        healthSafety: Object.keys(uniqueDocs.healthSafetyDocs).length,
        environment: Object.keys(uniqueDocs.environmentDocs).length,
        diversityInclusion: Object.keys(uniqueDocs.diversityInclusionDocs)
          .length,
        governance: Object.keys(uniqueDocs.governanceDocs).length,
        privacy: Object.keys(uniqueDocs.privacyDocs).length
      },
      positiveResponses: {
        total:
          healthSafetyPositives +
          environmentPositives +
          diversityInclusionPositives +
          governancePositives +
          privacyPositives,
        healthSafety: healthSafetyPositives,
        environment: environmentPositives,
        diversityInclusion: diversityInclusionPositives,
        governance: governancePositives,
        privacy: privacyPositives
      },
      supplierParticipantIds
    }
  }
)

export const getSurveyResponseDetailById = (
  state: RootState,
  id: string
): RecordOf<SurveyResponseDetail> => {
  return state.getIn(['supplier', 'survey', 'responseDetails', id])
}

const checkQuestionComplete = (
  response: RecordOf<SurveyResponseDetail> | undefined,
  requiredAttachments: string[] = [],
  requiredReason: string[] = [],
  notRequiredNoSubQuestion: string[] = []
) => {
  if (!response || !response.get('answer')) {
    return false
  }

  if (
    response.get('answer') === 'yes' &&
    ((requiredAttachments.includes(response.get('questionId')) &&
      !!response.get('attachments') &&
      response.get('attachments').size === 0) ||
      (requiredReason.includes(response.get('questionId')) &&
        !response.get('reason')))
  ) {
    return false
  }

  if (
    (response.get('answer') === 'preferNotToAnswer' ||
      (response.get('answer') === 'no' &&
        !notRequiredNoSubQuestion.includes(response.get('questionId')))) &&
    !response.get('reason')
  ) {
    return false
  }
  // special cases (true/false questions)
  if (
    (response.get('questionId') === 'occupationalChargesViolations' &&
      response.get('answer') === 'false' &&
      !!response.get('attachments') &&
      response.get('attachments').size === 0) ||
    (response.get('questionId') === 'negativeEnvironmentalImpacts' &&
      response.get('answer') === 'false' &&
      !!response.get('attachments') &&
      response.get('attachments').size === 0)
  ) {
    return false
  }

  return true
}

export const isHealthSafetyStarted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'healthSafety'),
  details => {
    return details?.size > 0
  }
)

export const isHealthSafetyCompleted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'healthSafety'),
  details => {
    const requiredKeys = [
      'iso45001',
      'emergencyResponsePolicies',
      'healthSafetyPolicies',
      'laggingIndicators',
      'leadingIndicators',
      'liabilityPrograms',
      'occupationalChargesViolations'
    ]

    return !requiredKeys.some(key => {
      const notRequiredNoSubQuestion = ['occupationalChargesViolations']
      const response = details?.find(detail => detail.get('questionId') === key)
      return !checkQuestionComplete(
        response,
        requiredKeys,
        [],
        notRequiredNoSubQuestion
      )
    })
  }
)

export const isQuestionAnswered = createSelector(
  getResponseDetailByIds,
  getResponseDetailByPageId,
  (
    response: RecordOf<SurveyResponseDetail>,
    details: List<RecordOf<SurveyResponseDetail>>
  ) => {
    const requiredAttachments = [
      'iso45001',
      'emergencyResponsePolicies',
      'healthSafetyPolicies',
      'laggingIndicators',
      'leadingIndicators',
      'liabilityPrograms',
      'occupationalChargesViolations',
      'iso14001',
      'thirdPartyCert',
      'ghgInventory',
      'iso50001',
      'energyConsumptionTracking',
      'waterUsageTracking',
      'wastePrograms',
      'negativeEnvironmentalImpacts',
      'formalPolicies',
      'integrateProcess',
      'diversityCertification',
      'codeOfConduct',
      'antiCorruption',
      'childForceLabour',
      'whistleblower',
      'activeEngagementProgram',
      'requireAdhereCodeOfConduct',
      'annualEsgReport'
    ]
    const requiredReason = [
      'improvingEnergyEfficiency',
      'managingWaterUsage',
      'managingWaste'
    ]
    const notRequiredNoSubQuestion = [
      'occupationalChargesViolations',
      'diversityCertification',
      '51PercentOwned'
    ]
    const complete = checkQuestionComplete(
      response,
      requiredAttachments,
      requiredReason,
      notRequiredNoSubQuestion
    )

    let childComplete1 = true
    if (
      response?.get('questionId') === 'greenhouseGasInventory' &&
      response?.get('answer') === 'yes'
    ) {
      const childrenKeys = [
        'scopeEmissions',
        'howFrequently',
        'thirdPartyCert',
        'ghgInventory'
      ]
      childComplete1 = !childrenKeys.some(key => {
        const childResponse = details?.find(
          detail => detail.get('questionId') === key
        )
        return !checkQuestionComplete(
          childResponse,
          requiredAttachments,
          requiredReason
        )
      })
    } else if (
      response?.get('questionId') === 'diversityCommitment' &&
      response?.get('answer') === 'yes'
    ) {
      const childResponse = details?.find(
        detail => detail.get('questionId') === 'publishedExternally'
      )
      const childComplete = checkQuestionComplete(childResponse)
      const subChildResponse = details?.find(
        detail => detail.get('questionId') === 'publishedExternallyLink'
      )
      const subChildComplete =
        childResponse?.get('answer') !== 'yes' ||
        checkQuestionComplete(subChildResponse)

      childComplete1 = childComplete && subChildComplete
    } else if (
      (response?.get('questionId') === 'complianceOfficer' ||
        response?.get('questionId') === 'designatedPrivacy') &&
      response?.get('answer') === 'yes'
    ) {
      const childrenKeys = ['jobTitle', 'jobTitleReportTo']
      childComplete1 = !childrenKeys.some(key => {
        const childResponse = details?.find(
          detail => detail.get('questionId') === key
        )
        return !checkQuestionComplete(childResponse)
      })
    }

    let childComplete2 = true
    if (
      response?.get('questionId') === 'ghgReductionTarget' &&
      response?.get('answer') === 'yes'
    ) {
      const childrenKeys = ['targetDateNumber', 'validatedBySBTi']
      childComplete2 = !childrenKeys.some(key => {
        const childResponse = details?.find(
          detail => detail.get('questionId') === key
        )
        return !checkQuestionComplete(
          childResponse,
          requiredAttachments,
          requiredReason
        )
      })
    } else if (
      response?.get('questionId') === '51PercentOwned' &&
      response?.get('answer') === 'yes'
    ) {
      const childResponse = details?.find(
        detail => detail.get('questionId') === 'groups'
      )
      childComplete2 = checkQuestionComplete(childResponse)
    } else if (
      response?.get('questionId') === 'useSustainabilityReportingFramework' &&
      response?.get('answer') === 'yes'
    ) {
      const childResponse = details?.find(
        detail => detail.get('questionId') === 'frameworks'
      )
      childComplete2 = checkQuestionComplete(childResponse)
    }

    return complete && childComplete1 && childComplete2
  }
)

export const isEnvironmentStarted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'environment'),
  details => {
    return details?.size > 0
  }
)

export const isEnvironmentCompleted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'environment'),
  details => {
    const requiredKeys = [
      'iso14001',
      'greenhouseGasInventory',
      'ghgReductionTarget',
      'iso50001',
      'energyConsumptionTracking',
      'waterUsageTracking',
      'wastePrograms',
      'improvingEnergyEfficiency',
      'managingWaterUsage',
      'managingWaste',
      'negativeEnvironmentalImpacts'
    ]
    return !requiredKeys.some(key => {
      const requiredAttachments = [
        'iso14001',
        'thirdPartyCert',
        'ghgInventory',
        'iso50001',
        'energyConsumptionTracking',
        'waterUsageTracking',
        'wastePrograms',
        'negativeEnvironmentalImpacts'
      ]
      const requiredReason = [
        'improvingEnergyEfficiency',
        'managingWaterUsage',
        'managingWaste'
      ]
      const notRequiredNoSubQuestion = ['negativeEnvironmentalImpacts']
      const response = details?.find(detail => detail.get('questionId') === key)
      const complete = checkQuestionComplete(
        response,
        requiredAttachments,
        requiredReason,
        notRequiredNoSubQuestion
      )

      // question with follow up question
      let childComplete1 = true
      if (
        key === 'greenhouseGasInventory' &&
        response?.get('answer') === 'yes'
      ) {
        const childrenKeys = [
          'scopeEmissions',
          'howFrequently',
          'thirdPartyCert',
          'ghgInventory'
        ]
        childComplete1 = !childrenKeys.some(key => {
          const childResponse = details?.find(
            detail => detail.get('questionId') === key
          )
          return !checkQuestionComplete(
            childResponse,
            requiredAttachments,
            requiredReason
          )
        })
      }
      let childComplete2 = true
      if (key === 'ghgReductionTarget' && response?.get('answer') === 'yes') {
        const childrenKeys = ['targetDateNumber', 'validatedBySBTi']
        childComplete2 = !childrenKeys.some(key => {
          const childResponse = details?.find(
            detail => detail.get('questionId') === key
          )
          return !checkQuestionComplete(
            childResponse,
            requiredAttachments,
            requiredReason
          )
        })
      }
      return !complete || !childComplete1 || !childComplete2
    })
  }
)

export const isDiversityInclusionStarted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'diversityInclusion'),
  details => {
    return details?.size > 0
  }
)

export const isDiversityInclusionCompleted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'diversityInclusion'),
  details => {
    const requiredKeys = [
      'diversityCommitment',
      'formalPolicies',
      'diversityTraining',
      'integrateProcess',
      'diversityCertification',
      '51PercentOwned'
    ]
    return !requiredKeys.some(key => {
      const requiredAttachments = [
        'formalPolicies',
        'integrateProcess',
        'diversityCertification'
      ]
      const notRequiredNoSubQuestion = [
        'diversityCertification',
        '51PercentOwned'
      ]
      const response = details?.find(detail => detail.get('questionId') === key)
      const complete = checkQuestionComplete(
        response,
        requiredAttachments,
        [],
        notRequiredNoSubQuestion
      )

      // question with follow up question
      let childComplete1 = true
      if (key === 'diversityCommitment' && response?.get('answer') === 'yes') {
        const childResponse = details?.find(
          detail => detail.get('questionId') === 'publishedExternally'
        )
        const childComplete = checkQuestionComplete(childResponse)
        const subChildResponse = details?.find(
          detail => detail.get('questionId') === 'publishedExternallyLink'
        )
        const subChildComplete =
          childResponse?.get('answer') !== 'yes' ||
          checkQuestionComplete(subChildResponse)

        childComplete1 = childComplete && subChildComplete
      }
      let childComplete2 = true
      if (key === '51PercentOwned' && response?.get('answer') === 'yes') {
        const childResponse = details?.find(
          detail => detail.get('questionId') === 'groups'
        )
        childComplete2 = checkQuestionComplete(childResponse)
      }
      return !complete || !childComplete1 || !childComplete2
    })
  }
)

export const isGovernanceStarted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'governance'),
  details => {
    return details?.size > 0
  }
)

export const isGovernanceCompleted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'governance'),
  details => {
    const requiredKeys = [
      'codeOfConduct',
      'antiCorruption',
      'childForceLabour',
      'whistleblower',
      'complianceOfficer',
      'activeEngagementProgram',
      'requireAdhereCodeOfConduct',
      'annualEsgReport',
      'useSustainabilityReportingFramework'
    ]
    return !requiredKeys.some(key => {
      const requiredAttachments = [
        'codeOfConduct',
        'antiCorruption',
        'childForceLabour',
        'whistleblower',
        'activeEngagementProgram',
        'requireAdhereCodeOfConduct',
        'annualEsgReport'
      ]

      const response = details?.find(detail => detail.get('questionId') === key)
      const complete = checkQuestionComplete(response, requiredAttachments)

      // question with follow up question
      let childComplete1 = true
      if (key === 'complianceOfficer' && response?.get('answer') === 'yes') {
        const childrenKeys = ['jobTitle', 'jobTitleReportTo']
        childComplete1 = !childrenKeys.some(key => {
          const childResponse = details?.find(
            detail => detail.get('questionId') === key
          )
          return !checkQuestionComplete(childResponse)
        })
      }
      let childComplete2 = true
      if (
        key === 'useSustainabilityReportingFramework' &&
        response?.get('answer') === 'yes'
      ) {
        const childResponse = details?.find(
          detail => detail.get('questionId') === 'frameworks'
        )
        childComplete2 = checkQuestionComplete(childResponse)
      }

      return !complete || !childComplete1 || !childComplete2
    })
  }
)

export const isPrivacyStarted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'privacy'),
  details => {
    return details?.size > 0
  }
)

export const isPrivacyCompleted = createSelector(
  (state: RootState) => getResponseDetailByPageId(state, 'privacy'),
  details => {
    const requiredKeys = ['complyGDPR', 'designatedPrivacy']
    return !requiredKeys.some(key => {
      const response = details?.find(detail => detail.get('questionId') === key)
      const complete = checkQuestionComplete(response)
      // question with follow up question
      let childComplete1 = true
      if (key === 'designatedPrivacy' && response?.get('answer') === 'yes') {
        const childrenKeys = ['jobTitle', 'jobTitleReportTo']
        childComplete1 = !childrenKeys.some(key => {
          const childResponse = details?.find(
            detail => detail.get('questionId') === key
          )
          return !checkQuestionComplete(childResponse)
        })
      }
      return !complete || !childComplete1
    })
  }
)

export const getSurveyResponseDetailsDocs = createSelector(
  getSurveyResponseDetails,
  (details: Map<string, RecordOf<SurveyResponseDetail>>) => {
    return details?.toList().reduce((result, currentResponse) => {
      if (currentResponse.get('attachmentType') === 'doc') {
        currentResponse
          .get('attachments')
          .forEach((item: RecordOf<AttachmentType>) => {
            let found = result.find(
              doc => doc.get('description') === item.get('description')
            )
            if (!found) {
              item = item.delete('docType')
              result = result.push(item)
            }
          })
      }
      return result
    }, List())
  }
)
