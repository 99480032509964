import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEsgReportPage } from '../../store/actions'
import ReportQuestion from './ReportQuestion'
import insightsSelectors from '../../store/insightsSelectors'
import Loading from 'shared/components/Loading'

const pageId = 'privacy'

const PrivacyReport = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(insightsSelectors.isEsgReportPageLoading)

  useEffect(() => {
    dispatch(getEsgReportPage(pageId))
  }, [dispatch])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className='ba b--black-10 bg-light-gray ph3'>
      <ReportQuestion
        questionNumber={1}
        pageId={pageId}
        questionId='complyGDPR'
      />
      <ReportQuestion
        questionNumber={2}
        pageId={pageId}
        questionId='designatedPrivacy'
      />
    </div>
  )
}

export default PrivacyReport
