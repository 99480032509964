import React, { FC, useState } from 'react'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import Page from 'shared/components/Page'
import Main from 'shared/components/Layout/Main'
import PageSection from 'shared/components/PageSection'
import Loading from 'shared/components/Loading'
import Button from 'shared/components/Button'
import Link from 'shared/components/Link'
import paths from '../../../routes/paths'
import { FormattedMessage } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import RichTextEditor, { createValueFromString } from 'react-rte'
import { Terms } from '../VetTermsPage/VetTermsPage'
import dateFormat from 'shared/utils/dateFormat'
import VetAdditions from 'buyer/Vets/components/VetAdditions'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import { List, RecordOf } from 'immutable'
import UploadedFile from 'shared/models/UploadedFile'
import FileAttachmentList from 'shared/components/FileAttachmentList'

type Props = {
  description: string
  challenge: string
  budget: string
  vetLocation: string
  responseDate: Date | string
  deliverables: string
  criticalCriteria: List<string>
  desiredOutcome: string
  targetAudience: string
  decisionDate: Date | string
  status: string
  companyName: string
  companyOrgUnit: string
  document: List<RecordOf<UploadedFile>>
  detailedReponseRequested: boolean
  responseInstructions: string
  isLoading: boolean
  onClickContinue: () => void
  terms: string
  additions: List<
    RecordOf<{
      active: string
      type: string
      date: string
      title: string
      description: string
      attachment: string
    }>
  >
  onDownload?: (fileName: any) => void
  getFilePreview?: (fileName: any) => Promise<string | void>
}

const VetDetailsPage: FC<Props> = ({
  status,
  companyName,
  companyOrgUnit,
  description,
  document,
  budget,
  vetLocation,
  responseDate,
  criticalCriteria,
  decisionDate,
  detailedReponseRequested,
  responseInstructions,
  isLoading,
  onClickContinue,
  terms,
  additions,
  onDownload,
  getFilePreview
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const toggleDialog = () => setDialogOpen(!dialogOpen)

  const visibleAdditions =
    additions && additions.filter(addition => addition.get('active'))

  return !isLoading ? (
    <Page
      title={
        <FormattedMessage
          id='VetDetailsPage.DetailsTitle'
          defaultMessage='Details'
        />
      }
    >
      <ScrollToPosition />
      <Main>
        {(status === 'Opened' ||
          status === 'Interested' ||
          status === 'Meet Criteria') && (
          <>
            <Text className='mb3'>
              <FormattedMessage
                id='VetDetailsPage.Agreement'
                defaultMessage='You have agreed to the'
              />
              &nbsp;
              <FormattedMessage
                id='VetDetailsPage.TC'
                defaultMessage='Terms and Conditions'
              >
                {message => (
                  <span
                    className='teal f7 fw4 dim no-underline pointer'
                    onClick={toggleDialog}
                  >
                    {message}
                  </span>
                )}
              </FormattedMessage>
              .
            </Text>
            <Text>
              <FormattedMessage
                id='VetDetailsPage.ReviewDetailsProvidedByClient'
                defaultMessage='Please review the details provided by the client, below.
              When you are ready, press the "Continue" button for next steps.
              If you have any questions about this process, please email'
              />
              &nbsp;
              <a
                className='teal f7 fw4 dim no-underline pointer'
                href='mailto:support@tealbook.com'
              >
                support@tealbook.com
              </a>
              .
            </Text>
          </>
        )}

        <PageSection
          title={
            <FormattedMessage
              id='VetDetailsPage.RFIOverview'
              defaultMessage='RFI Overview'
            />
          }
        >
          {companyName && companyName !== 'Anonymous' && (
            <>
              <Label>
                <FormattedMessage
                  id='VetDetailsPage.BuyerLabel'
                  defaultMessage='Buyer'
                />
              </Label>
              <Link
                to={paths.clientProfile}
                params={{ orgUnitId: companyOrgUnit }}
              >
                <h5 className='f7 fw4 dim teal ma0'>{companyName}</h5>
              </Link>
            </>
          )}
          {description && (
            <>
              <Label>
                <FormattedMessage
                  id='VetDetailsPage.SummaryLabel'
                  defaultMessage='Summary'
                />
              </Label>
              <Text> {description} </Text>
            </>
          )}
          {budget && (
            <>
              <Label>
                <FormattedMessage
                  id='VetDetailsPage.BudgetLabel'
                  defaultMessage='Budget'
                />
              </Label>
              <Text> {budget} </Text>
            </>
          )}
          {vetLocation && (
            <>
              <Label>
                <FormattedMessage
                  id='VetDetailsPage.LocationLabel'
                  defaultMessage='Location'
                />
              </Label>
              <Text> {vetLocation} </Text>
            </>
          )}
          {responseDate && (
            <>
              <Label>
                <FormattedMessage
                  id='VetDetailsPage.ResponseByLabel'
                  defaultMessage='Response By'
                />
              </Label>
              <Text> {responseDate.toString()} </Text>
            </>
          )}
          {decisionDate && (
            <>
              <Label>
                <FormattedMessage
                  id='VetDetailsPage.ProjectedDecisionDateLabel'
                  defaultMessage='Projected Decision Date'
                />
              </Label>
              <Text> {dateFormat(decisionDate)} </Text>
            </>
          )}
          <Label>
            <FormattedMessage
              id='VetDetailsPage.DetailedResponseLabel'
              defaultMessage='Detailed Response'
            />
          </Label>
          {detailedReponseRequested ? (
            <Text>
              <FormattedMessage
                id='VetDetailsPage.YesText'
                defaultMessage='Yes'
              />
            </Text>
          ) : (
            <Text>
              <FormattedMessage
                id='VetDetailsPage.NoneRequiredText'
                defaultMessage='None required'
              />
            </Text>
          )}
          {detailedReponseRequested && !!responseInstructions && (
            <>
              <Label>
                <FormattedMessage
                  id='VetDetailsPage.ResponseInstructionsLabel'
                  defaultMessage='Response Instructions'
                />
              </Label>
              <Text> {responseInstructions} </Text>
            </>
          )}
          {document && document.size > 0 && (
            <FileAttachmentList
              readonly
              label={
                <FormattedMessage
                  id='VetDetailsPage.DocumentLabel'
                  defaultMessage='Document'
                />
              }
              attachment={document}
              onDownload={onDownload}
              getFilePreview={getFilePreview}
            />
          )}
        </PageSection>
        {criticalCriteria.size > 0 && (
          <PageSection
            title={
              <FormattedMessage
                id='VetDetailsPage.CriticalCriteriaTitle'
                defaultMessage='Critical Criteria'
              />
            }
          >
            {criticalCriteria.map((criteria, index) => (
              <div key={index} className='dt mt3'>
                <Text>{index + 1 + '. ' + criteria}</Text>
              </div>
            ))}
          </PageSection>
        )}

        {visibleAdditions && <VetAdditions additions={visibleAdditions} />}

        {(status === 'Opened' ||
          status === 'Interested' ||
          status === 'Meet Criteria') && (
          <Button
            label={
              <FormattedMessage
                id='VetDetailsPage.ContinueButton'
                defaultMessage='Continue'
              />
            }
            autoSize
            className='mt3 fr'
            onClick={onClickContinue}
          />
        )}
      </Main>
      <Dialog open={dialogOpen} onClose={toggleDialog} fullWidth>
        <DialogTitle>
          <FormattedMessage id='VetDetailsPage.TC' />
        </DialogTitle>
        <DialogContent>
          {terms ? (
            <div className='rte-readonly-wrapper mt3-5'>
              <RichTextEditor
                value={createValueFromString(terms, 'markdown')}
                editorClassName='readonly'
                readOnly
              />
            </div>
          ) : (
            <div className='mt3-5'>
              <Terms />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoSize
            label={
              <FormattedMessage
                id='VetDetailsPage.Close'
                defaultMessage='Close'
              />
            }
            onClick={toggleDialog}
          />
        </DialogActions>
      </Dialog>
    </Page>
  ) : (
    <Loading />
  )
}

export default VetDetailsPage
