import React, { useState, useMemo } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import DialogTitle from 'shared/components/DialogTitle'
import Text from 'shared/components/Text'
import { useDispatch } from 'react-redux'
import Avatar from 'shared/components/Avatar'
import AddOutreachContactForm from '../../containers/AddOutreachContactForm'
import Button from 'shared/components/Button'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import AddIcon from '@material-ui/icons/AddCircle'
import Select from 'react-select'
import useSmallScreen from 'shared/utils/useSmallScreen'
import DialogActions from 'shared/components/DialogActions'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import { fromJS, List, RecordOf } from 'immutable'
import { notify } from 'shared/actions'
import ListItem from 'shared/components/List/ListItem'
import User from 'shared/models/User'

const messages = defineMessages({
  supplierOutreachContact: {
    id: 'ConnectMessageDialog.OutreachContact',
    defaultMessage:
      'uses TealBook for Supplier data management. Please accept this invitation to keep the information we have on your company current and to communicate with us regarding our procurement relationship.'
  }
})

type ChatWithContactProps = {
  firstName: string
  lastName: string
  title?: string
  profilePictureUrl?: string
}
export const ContactItem = (props: ChatWithContactProps) => {
  const { firstName, lastName, profilePictureUrl, title } = props

  return (
    <ListItem
      leftAvatar={
        <Avatar
          url={profilePictureUrl}
          className='mt1 w1-5'
          name={`${firstName || ''} ${lastName || ''}`}
        />
      }
      primaryText={
        <div className='flex items-center f7-5'>
          <span>
            {firstName} {lastName}
          </span>
        </div>
      }
      secondaryText={
        title ? <div className='f9 ma0 gray'>{title}</div> : undefined
      }
      className='ma0'
      mini
    />
  )
}

type Props = {
  contacts: List<RecordOf<User>>
  supplierId: string
  activeUserId: string
  taskId: string
  isChatContactSelectOpen: boolean
  clientName: string
  handleToggleChatContactSelectDialog: () => void
  onClickNewUser: () => void
  handleCancel: () => void
  onMessageUser: (arg: string) => void
  onContactSupplier: (
    taskId: string,
    status: string,
    currentUserId: string,
    selectedRequestContactId: string
  ) => void
  handleCancelNewUserForm: () => void
}

const ChatContactSelect = (props: Props) => {
  const {
    contacts,
    isChatContactSelectOpen,
    clientName,
    activeUserId,
    taskId,
    handleToggleChatContactSelectDialog,
    onClickNewUser,
    handleCancel,
    onMessageUser,
    onContactSupplier,
    handleCancelNewUserForm
  } = props
  const dispatch = useDispatch()
  const intl = useIntl()
  const matchMobile = useSmallScreen()
  const defaultMessage = `${clientName} ${intl.formatMessage(
    messages.supplierOutreachContact
  )}`
  const [requestAddOutreachMessage, setRequestAddOutreachMessage] = useState<
    string
  >('')
  const [selectInput, setSelectInput] = useState<string>('')
  const [selectedContactId, setSelectedContactId] = useState<string>('')
  const handleSelectInputChange = (inputValue: string) => {
    setSelectInput(inputValue)
  }
  const handleAddOutreachMessage = e => {
    setRequestAddOutreachMessage(e.target.value)
  }
  const handleContactSelected = (data, metaAction) => {
    setSelectedContactId(data.id)
    setSelectInput('')
  }
  const handleOnClickAddUserButton = () => {
    onClickNewUser()
    handleToggleChatContactSelectDialog()
  }
  const handleSubmit = e => {
    e.preventDefault()
    onMessageUser(
      fromJS({
        userToMessageId: selectedContactId,
        message: requestAddOutreachMessage
      })
    )
    onContactSupplier(
      taskId,
      'WaitingForClient',
      activeUserId,
      selectedContactId
    )
    dispatch(notify({ message: 'Message successfully sent' }))

    handleToggleChatContactSelectDialog()
  }
  const sortContacts = useMemo(() => {
    return !contacts
      ? []
      : !selectInput
      ? contacts.toJS()
      : contacts
          .sort((userA, userB) => {
            let fullNameA = `${userA.get('firstName') &&
              userA.get('firstName').trim()} ${userA.get('lastName') &&
              userA.get('lastName').trim()}`.toLowerCase()
            let fullNameB = `${userB.get('firstName') &&
              userB.get('firstName').trim()} ${userB.get('lastName') &&
              userB.get('lastName').trim()}`.toLowerCase()
            fullNameA = fullNameA.startsWith(selectInput)
              ? `0${fullNameA}`
              : fullNameA
            fullNameB = fullNameB.startsWith(selectInput)
              ? `0${fullNameB}`
              : fullNameB

            if (fullNameA > fullNameB) {
              return 1
            }
            if (fullNameA < fullNameB) {
              return -1
            }
            return 0
          })
          .toJS()
  }, [contacts, selectInput])
  const filterOption = props => {
    const user = props.data
    const userFullName = `${user.firstName &&
      user.firstName.trim()} ${user.lastName &&
      user.lastName.trim()}`.toLowerCase()

    return userFullName.includes(selectInput.toLowerCase())
  }

  const formatOptionLabel = (user, data) => {
    if (!user) {
      return null
    }
    return (
      <ContactItem
        key={user.id}
        firstName={user.firstName}
        lastName={user.lastName}
        profilePictureUrl={user.profilePictureUrl}
        title={user.title}
      />
    )
  }
  return (
    <>
      <Dialog
        onClose={handleToggleChatContactSelectDialog}
        open={isChatContactSelectOpen}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            <FormattedMessage
              id='ChatContactSelect.SelectContact'
              defaultMessage='Contact Supplier'
            />
          </DialogTitle>
          <DialogContent className='mt3'>
            <Text>
              <FormattedMessage
                id='SupplierOutreach.ContactSelectInstruction'
                defaultMessage='Select a contact to message'
              />
            </Text>
            <Label>
              <FormattedMessage
                id='ChatContactSelect.ContactSelect'
                defaultMessage='Contacts'
              />
            </Label>
            <Select
              aria-label='Select Contact'
              InputValue={selectInput}
              onInputChange={handleSelectInputChange}
              onChange={handleContactSelected}
              placeholder={
                (
                  <FormattedMessage
                    id='UserLookup.Select Contact'
                    defaultMessage='Type in Contact Name'
                  />
                ) as any
              }
              options={sortContacts as any}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
              getOptionValue={opt => opt.id || opt.value}
              noOptionsMessage={inputValue => 'No Contacts Found'}
              controlShouldRenderValue={true}
              styles={{
                control: base => ({
                  ...base,
                  height: 30
                }),
                input: base => ({
                  ...base,
                  fontSize: '.875rem',
                  height: 30
                }),
                placeholder: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                noOptionsMessage: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                option: (base, { isDisabled, isFocused }) => ({
                  ...base,
                  backgroundColor: isDisabled
                    ? 'inherit'
                    : isFocused
                    ? '#E0E0E0'
                    : 'inherit',
                  ':hover': {
                    ...base[':hover'],
                    backgroundColor: !isDisabled && '#e0e0e0'
                  }
                })
              }}
              menuPortalTarget={document.body}
            />
            <div className='mb3 mt3'>
              <div className='dib w-35'>
                <Button
                  color='primary'
                  variant='text'
                  startIcon={<AddIcon />}
                  label={
                    <FormattedMessage
                      id='ChatContactSelect.AddContact'
                      defaultMessage='Add a New Contact'
                    />
                  }
                  onClick={() => handleOnClickAddUserButton()}
                />
              </div>
            </div>
            <Label htmlFor='message'>
              <FormattedMessage
                id='ChatContactSelect.AddMessage'
                defaultMessage='Add Message'
              />
            </Label>
            <TextArea
              id='message'
              value={requestAddOutreachMessage}
              onChange={handleAddOutreachMessage}
              maxLength={500}
            />
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              label={
                <FormattedMessage
                  id='ChatContactSelect.Send'
                  defaultMessage='Send'
                />
              }
              disabled={!selectedContactId || !requestAddOutreachMessage}
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleCancel}
              secondary
              className='mr3'
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>
      <AddOutreachContactForm
        defaultMessage={defaultMessage}
        contactSupplierForOutreach={true}
        taskId={taskId}
        onClickCancel={handleCancelNewUserForm}
      />
    </>
  )
}

export default ChatContactSelect
