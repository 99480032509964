import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { List, RecordOf, OrderedMap } from 'immutable'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import dateFormat from 'shared/utils/dateFormat'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as GarbageIcon } from './delete.svg'
import { ReactComponent as GarbageIconDisabled } from './deleteDisabled.svg'
import startCase from 'lodash.startcase'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import NavBar from 'shared/components/NavBar'
import paths from '../../diversityPaths'
import Link from 'shared/components/Link'
import supplierPaths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import NavTab from 'shared/components/NavTab'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import Tooltip from 'shared/components/Tooltip'
import FileSaver from 'file-saver'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Loading from 'shared/components/Loading'
import LoadPageErrorParser from 'supplier/Insight/components/LoadPage/LoadPageErrorParser'
import ExternalLink from 'shared/components/ExternalLink'
import { makeStyles } from '@material-ui/core/styles'
import ConfirmDeleteDialog from './ConfirmDeleteDialog'
import { useSelector } from 'react-redux'
import { UploadHistoryTypeT2 } from './UploadSpendDetail/UploadSpendDetail'
import RootState from 'shared/models/RootState'
import { exportCSV } from 'shared/utils/exportData/exportData'

const messages = defineMessages({
  complete: { id: 'Complete', defaultMessage: 'Complete' },
  processing: { id: 'Processing', defaultMessage: 'Processing' },
  error: { id: 'UploadError', defaultMessage: 'Upload Error' },
  published: { id: 'Published', defaultMessage: 'Published' },
  direct: { id: 'Direct', defaultMessage: 'Direct' },
  indirect: { id: 'Indirect', defaultMessage: 'Indirect' },
  fullSpend: { id: 'FullSpend', defaultMessage: 'Full spend' }
})
const useStyles = makeStyles({
  button: {
    width: '35px',
    height: '35px',
    borderRadius: '4px',
    border: '1px solid #D3D3D3',
    backgroundColor: '#F8F8FA',
    '&:disabled': {
      backgroundColor: '#cccccc'
    },
    marginLeft: '12px'
  }
})
type Props = {
  uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryTypeT2>>>
  publishSpendT2: (payload: {
    stagingId: string
    listType: string
    buyerIds: Array<string>
  }) => void
  loadStagingHistory: (payload: { listType: string }) => void
  notify: (payload: { message: string }) => void
  isLoading: boolean
  hasConciergeRole: boolean
}

export const T2LoadPage = (props: Props) => {
  const {
    uploadHistory,
    publishSpendT2,
    loadStagingHistory,
    notify,
    isLoading,
    hasConciergeRole
  } = props
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()

  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined)
  const [confirmRemoveStaging, setConfirmRemoveStaging] = useState<string>('')
  const [rowDataType, setRowDataType] = useState<
    'file' | 'aggregate' | undefined
  >(undefined)

  const [buyersImpactedByDelete, setBuyersImpactedByDelete] = useState<
    List<RecordOf<{ buyerName: string; buyerId: string }>>
  >(List([]))
  const [exportDiverseSpend, setExportDiverseSpend] = useState<boolean>(false)
  const fileLoadErrors: List<
    string | { [key: string]: any }
  > = useSelector((state: RootState) =>
    state.getIn(['supplier', 'tier2', 'fileLoadErrors'])
  )
  const activeQuarter = useMemo(() => {
    const { show } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    return show
  }, [location.search])
  const activeHistory = useMemo(() => {
    return uploadHistory && uploadHistory.get(activeQuarter)
  }, [activeQuarter, uploadHistory])
  const rowsWithUploadError = useMemo(() => {
    const errorIndexes = activeHistory?.map((currentValue, index) => {
      if (currentValue.get('status') === 'error') {
        return index
      } else return -1
    })
    return errorIndexes?.filter(index => index !== -1)
  }, [activeHistory])

  const lastComplete = useMemo(() => {
    return activeHistory
      ? activeHistory.findIndex(
          history =>
            history.get('status') === 'published' ||
            history.get('status') === 'complete'
        )
      : -1
  }, [activeHistory])

  const isAnyProcessing = useMemo(() => {
    return (
      !!activeHistory &&
      activeHistory.some(history => history.get('status') === 'processing')
    )
  }, [activeHistory])

  const highlightIndex = useMemo(() => {
    return typeof selectedRow === 'number' ? selectedRow : lastComplete
  }, [selectedRow, lastComplete])

  const handleIntervalCall = useCallback(() => {
    if (isAnyProcessing) {
      loadStagingHistory({
        listType: 'supplierTier2Load'
      })
    }
  }, [isAnyProcessing, loadStagingHistory])

  useEffect(() => {
    if (
      !activeQuarter &&
      uploadHistory &&
      uploadHistory.size > 0 &&
      !fileLoadErrors.size
    ) {
      history.push(`?show=${uploadHistory.keySeq().first()}`)
    }
  }, [activeQuarter, history, uploadHistory, location.pathname, fileLoadErrors])

  useEffect(() => {
    setSelectedRow(undefined)
  }, [activeQuarter])

  useEffect(() => {
    const interval = global.setInterval(() => {
      handleIntervalCall()
    }, 60000)
    return () => global.clearInterval(interval)
  }, [handleIntervalCall])

  const renderFileColumn = ({ cellData, rowData }: TableCellProps) => {
    const fullFileName = rowData.get('fileName')
    const uploadUserId = rowData.get('userId')
    return (
      <div onClick={event => event.stopPropagation()}>
        <Tooltip
          title={
            <FormattedMessage
              id='LoadPage.DownloadLoadFile'
              defaultMessage='Click here to download this file, so you can compare with mapping below.'
            />
          }
        >
          {
            <span
              className='pointer near-black hover-teal no-underline'
              onClick={() =>
                handleSideBySideDownload(cellData, fullFileName, uploadUserId)
              }
            >
              {rowData.get('dataType') === 'aggregate'
                ? `Aggregate-${rowData.get('year')}-Q${rowData.get('quarter')}`
                : cellData}
            </span>
          }
        </Tooltip>
      </div>
    )
  }

  const handleSideBySideDownload = (fileName, fullFileName, uploadUserId) => {
    api
      .download(
        `${apiRoutes.loader}/loader/tier2LoadFile/${fullFileName}/${uploadUserId}`
      )
      .then(response => response.blob())
      .then(blob => FileSaver.saveAs(blob, `${fileName}`))
      .catch(err =>
        notify({
          message: err.message
        })
      )
  }

  const handleInternalDiverseSpendDownload = () => {
    api
      .download(`${apiRoutes.authService}/tier2/exportStagingDiverseSpend`)
      .then(response => response.json())
      .then(json => {
        const tier1Supplier = json[0].tier1Supplier
        exportCSV(json, `Spend Breadown-${tier1Supplier}`)
        setExportDiverseSpend(false)
      })
      .catch(err => {
        setExportDiverseSpend(false)
        notify({
          message: err.message
        })
      })
  }

  return (
    <div>
      {uploadHistory && uploadHistory.size > 0 && (
        <div
          className='overflow-x-auto overflow-y-hidden'
          style={{ paddingBottom: 3 }}
        >
          <div className='flex justify-between'>
            <h5 className='db mb3 f7 fw6'>
              <FormattedMessage
                id='T2LoadPage.DiverseSpendInformation'
                defaultMessage='Diverse spend information'
              />
            </h5>
            {hasConciergeRole && (
              <Button
                className='mt2'
                autoSize
                onClick={() => {
                  setExportDiverseSpend(true)
                  handleInternalDiverseSpendDownload()
                }}
                disabled={exportDiverseSpend}
              >
                <FormattedMessage
                  id='LoadPage.InternalExportDiverseSpend'
                  defaultMessage='Internal: export diverse spend'
                />
              </Button>
            )}
          </div>
          <Text>
            <FormattedMessage
              id='LoadPage.DiversitySpendDescription1'
              defaultMessage="Please allow us 5 to 60 minutes to process your uploaded file data. When the file status is complete, click <bold>View</bold> to verify that the supplier data is mapped correctly. When you're ready, click <bold>Publish</bold> then navigate to the <bold>Share</bold> tab to start allocating spend amounts to your customer.{br}{br}Note: You may publish only one full spend file or one set of indirect aggregate spend per quarter. Spend data from indirect aggregate amounts or full spend files may be <link>linked to multiple buyers</link> for diverse spend allocation."
              values={{
                link: link => (
                  <ExternalLink href='https://tealbookhelp.zendesk.com/hc/en-us/articles/8713635558036-Linking-published-indirect-Tier-2-diverse-spend-to-multiple-buyers'>
                    {link}
                  </ExternalLink>
                ),
                br: <br />,
                bold: text => <span className='b'>{text}</span>
              }}
            />
          </Text>
          <NavBar>
            {uploadHistory.keySeq().map(key => (
              <li key={key}>
                <NavTab
                  to={`${paths.diversityLoad}?show=${key}`}
                  isActive={(match, location) =>
                    location.search === `?show=${key}`
                  }
                >
                  {key.split('-').join('-Q')}
                </NavTab>
              </li>
            ))}
          </NavBar>
        </div>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        activeHistory &&
        activeHistory.size > 0 && (
          <>
            <div className='bg-white br1 ba b--black-10 overflow-hidden'>
              <Table
                rowGetter={({ index }) => activeHistory.get(index)}
                rowCount={activeHistory.size}
                onRowClick={({ rowData, index }) => {
                  if (
                    rowData.get('status') === 'complete' ||
                    rowData.get('status') === 'published'
                  ) {
                    setSelectedRow(index)
                  }
                }}
                highlightIndexes={
                  typeof highlightIndex === 'number'
                    ? List([highlightIndex])
                    : undefined
                }
                errorIndexes={rowsWithUploadError}
                rowHeight={(row: any): number => {
                  const buyers = activeHistory.get(row.index)?.get('buyers')
                  const numberOfBuyers = buyers?.size || 0
                  if (numberOfBuyers > 4) {
                    return 100
                  } else if (numberOfBuyers > 3) {
                    return 70
                  } else {
                    return 56
                  }
                }}
              >
                <Column
                  label={
                    <span style={{ textTransform: 'none' }}>
                      <FormattedMessage
                        id='LoadPage.BuyerName'
                        defaultMessage='Buyer(s)'
                      />
                    </span>
                  }
                  dataKey='buyer'
                  width={200}
                  style={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal'
                  }}
                  cellRenderer={({ rowData }: TableCellProps) => {
                    return rowData.toJS().buyers?.map((buyer, index) => {
                      return index > 0
                        ? `, ${buyer.buyerName}`
                        : buyer.buyerName
                    })
                  }}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Filename'
                      defaultMessage='Filename'
                    />
                  }
                  dataKey='clientFileName'
                  width={200}
                  cellRenderer={renderFileColumn}
                  style={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal'
                  }}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.SpendType'
                      defaultMessage='Spend Type'
                    />
                  }
                  dataKey='spendType'
                  width={150}
                  cellRenderer={({ rowData }: TableCellProps) => (
                    <span>
                      {intl.formatMessage(messages[rowData.get('spendType')])}
                    </span>
                  )}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.UploadDate'
                      defaultMessage='Upload Date'
                    />
                  }
                  dataKey='uploadDate'
                  width={140}
                  cellRenderer={({ cellData }: TableCellProps) =>
                    dateFormat(cellData)
                  }
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.User'
                      defaultMessage='User'
                    />
                  }
                  dataKey='uploadBy'
                  width={170}
                  cellRenderer={({ cellData, rowData }: TableCellProps) => (
                    <Link
                      to={parsePath(supplierPaths.userProfile, {
                        userId: rowData.get('userId')
                      })}
                    >
                      {cellData}
                    </Link>
                  )}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Row'
                      defaultMessage='# of Rows'
                    />
                  }
                  dataKey='rowCount'
                  width={100}
                  cellRenderer={({ cellData }: TableCellProps) =>
                    cellData !== undefined && cellData.toLocaleString()
                  }
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Status'
                      defaultMessage='Status'
                    />
                  }
                  dataKey='status'
                  width={160}
                  cellRenderer={({ rowData }: TableCellProps) => {
                    const { status, fileLoadErrors } = rowData?.toJS()
                    return (
                      <span
                        className={
                          status === 'complete'
                            ? 'green'
                            : status === 'error'
                            ? 'red'
                            : ''
                        }
                      >
                        {messages[status]
                          ? intl.formatMessage(messages[status])
                          : startCase(status)}
                        {status === 'error' &&
                          fileLoadErrors &&
                          fileLoadErrors.length && (
                            <Tooltip
                              title={
                                <LoadPageErrorParser
                                  fileLoadErrors={fileLoadErrors}
                                />
                              }
                            >
                              <InfoIcon className='mr2 v-mid' />
                            </Tooltip>
                          )}
                      </span>
                    )
                  }}
                />
                <Column
                  dataKey='status'
                  width={255}
                  flexGrow={1}
                  style={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal'
                  }}
                  cellRenderer={({ cellData, rowData }: TableCellProps) => {
                    return cellData === 'processing' ||
                      cellData === 'complete' ||
                      cellData === 'published' ? (
                      <div className='flex items-center justify-end'>
                        <Button
                          autoSize
                          onClick={() =>
                            history.push(
                              parsePath(paths.diversityLoadV2View, {
                                stagingId: rowData.get('id')
                              })
                            )
                          }
                          disabled={cellData === 'processing'}
                        >
                          <FormattedMessage
                            id='LoadPage.View'
                            defaultMessage='View'
                          />
                        </Button>
                        {rowData.get('dataType') !== 'aggregate' && (
                          <Button
                            aria-label='Publish'
                            className='ml2'
                            autoSize
                            onClick={e => {
                              e.stopPropagation()
                              publishSpendT2({
                                stagingId: rowData.get('id'),
                                listType: 'supplierTier2Load',
                                buyerIds: rowData
                                  .get('buyers')
                                  ?.map(buyer => buyer.get('buyerId'))
                                  ?.toJS()
                              })
                            }}
                            disabled={
                              cellData === 'processing' ||
                              cellData === 'published'
                            }
                          >
                            <FormattedMessage
                              id='LoadPage.Publish'
                              defaultMessage='Publish'
                            />
                          </Button>
                        )}
                        {hasConciergeRole ? (
                          <IconButton
                            disabled={cellData === 'processing'}
                            className={classes.button}
                            aria-label='Delete spend'
                            onClick={e => {
                              e.stopPropagation()
                              setRowDataType(rowData.get('dataType'))
                              setConfirmRemoveStaging(rowData.get('id'))
                              setBuyersImpactedByDelete(rowData.get('buyers'))
                            }}
                          >
                            <GarbageIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={
                              cellData !== 'complete' &&
                              rowData.get('dataType') === 'file'
                            }
                            className={classes.button}
                            aria-label='Delete spend'
                            onClick={e => {
                              e.stopPropagation()
                              setRowDataType(rowData.get('dataType'))
                              setConfirmRemoveStaging(rowData.get('id'))
                              setBuyersImpactedByDelete(rowData.get('buyers'))
                            }}
                          >
                            {(cellData === 'complete' ||
                              (rowData.get('dataType') === 'aggregate' &&
                                cellData === 'published')) && <GarbageIcon />}
                            {cellData !== 'complete' &&
                              rowData.get('dataType') === 'file' && (
                                <GarbageIconDisabled />
                              )}
                          </IconButton>
                        )}
                      </div>
                    ) : cellData === 'error' ? (
                      <FormattedMessage
                        id='LoadPage.OnError'
                        defaultMessage='Please fix errors and reload file'
                      />
                    ) : null
                  }}
                />
              </Table>
            </div>
            {activeHistory.some(
              history => history.get('status') === 'processing'
            ) && (
              <Text className='mt3 flex items-center'>
                <InfoOutlined className='mr2' />
                <FormattedMessage
                  id='LoadPage.ProcessingMessage'
                  defaultMessage='A file can take up to an hour to process.'
                />
              </Text>
            )}
          </>
        )
      )}
      <ConfirmDeleteDialog
        aggregate={rowDataType === 'aggregate'}
        stagingId={confirmRemoveStaging}
        buyersImpactedByDelete={buyersImpactedByDelete?.toJS()}
        uploadHistory={uploadHistory}
        activeQuarter={activeQuarter}
        onClose={() => {
          setRowDataType(undefined)
          setConfirmRemoveStaging('')
          setBuyersImpactedByDelete(List([]))
        }}
      />
    </div>
  )
}

export default T2LoadPage
