import React, { useCallback, useEffect, useState } from 'react'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import dateFormat from 'shared/utils/dateFormat'
import Label from 'shared/components/Label'
import Divider from 'shared/components/Divider'
import Button from 'shared/components/Button'
import { notify } from 'shared/actions'
import { useDispatch } from 'react-redux'
import exportData from 'shared/utils/exportData'
import insight from 'shared/utils/api/insight'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import Card from 'shared/components/Card'
import InputLabel from '@material-ui/core/InputLabel'
import loader from 'shared/utils/api/loader'
import Warning from 'shared/components/Warning'
import startCase from 'lodash.startcase'
import Text from 'shared/components/Text'
import Page from 'shared/components/Page'

const LoaderState = () => {
  const [selectedStatId, setSelectStatId] = useState<string>('')
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()
  const [loaderList, setLoaderList] = useState<any[]>([])
  const [statById, setStatById] = useState<any>({})
  const [otherActionsList, setOtherActionsList] = useState<any[]>([])
  const [cursorStart, setCursorStart] = useState<string>('')
  const [cursorEnd, setCursorEnd] = useState<string>('')
  const dispatch = useDispatch()

  const getLoaderStatus = useCallback(async () => {
    let detailsById = {}
    let listForLoader = []
    let listForOtherActions: Array<any> = []

    const statsPromises = [
      loader.getClientLoaderStatus('loader'),
      loader.getClientLoaderStatus('conciergeAction'),
      loader.getClientLoaderStatus('clientUpload')
    ]
    let loaderResponse, conciergeActionResponse, clientUploadResponse
    try {
      ;[
        loaderResponse,
        conciergeActionResponse,
        clientUploadResponse
      ] = await Promise.all(statsPromises)
    } catch (error) {
      console.log(`Error loading stats ${error}`)
    }

    if (loaderResponse?.results) {
      listForLoader = loaderResponse.results.map(({ details, ...rest }) => ({
        percentComplete: details.progress,
        linesRead: details.linesRead,
        user: details.user,
        body: details.body,
        ...rest
      }))
      detailsById = loaderResponse.results.reduce((result, status) => {
        result[status.id] = status.details
        return result
      }, detailsById)
    }
    if (conciergeActionResponse?.results) {
      listForOtherActions = conciergeActionResponse.results.map(
        ({ details, ...rest }) => ({
          percentComplete: details.progress,
          linesRead: details.linesRead,
          type: startCase(details.action),
          user: details.user,
          body: details.body || details,
          ...rest
        })
      )
    }

    if (clientUploadResponse?.results) {
      listForOtherActions = [
        ...listForOtherActions,
        ...clientUploadResponse.results.map(({ details, label, ...rest }) => ({
          percentComplete: details.progress,
          linesRead: details.linesRead,
          type: startCase(details.action) || startCase(label),
          user: details.user,
          body: details.body || details,
          ...rest
        }))
      ]
      listForOtherActions.sort(
        (statA, statB) =>
          new Date(statB.created.date).getTime() -
          new Date(statA.created.date).getTime()
      )
      detailsById = listForOtherActions.reduce((result, status) => {
        result[status.id] = status.body
        return result
      }, detailsById)
    }
    setCursorStart(conciergeActionResponse.cursorStart)
    setCursorEnd(conciergeActionResponse.cursorEnd)
    setLoaderList(listForLoader)
    setOtherActionsList(listForOtherActions)
    setStatById(detailsById)
  }, [])

  useEffect(() => {
    getLoaderStatus()
  }, [getLoaderStatus])

  useEffect(() => {
    if (!intervalId) {
      const id = global.setInterval(getLoaderStatus, 20000)
      setIntervalId(id)
    }

    return () => {
      if (intervalId) {
        global.clearInterval(intervalId)
      }
    }
  }, [getLoaderStatus, intervalId])

  const handleDownloadLog = (statId: string) => {
    insight
      .getStatLog(statId)
      .then((response: string[]) => {
        exportData.exportList(response, statId, 'log', false)
      })
      .catch(err =>
        dispatch(
          notify({
            message: err.message
          })
        )
      )
  }

  const renderLoadType = ({ rowData }: TableCellProps) => {
    const { body } = rowData
    let loadType
    if (body?.action === 'client') {
      loadType = 'Client Spend'
    } else if (body?.action === 'tier2') {
      loadType = 'Tier 2 Spend'
    } else {
      loadType = 'Data Load'
    }

    return <div>{loadType}</div>
  }
  return (
    <Page title={'Loader State'}>
      <Main>
        <Label>Loader Jobs Status</Label>
        {loaderList?.length > 0 && (
          <>
            <div className='bg-white br1 ba b--black-10 overflow-hidden'>
              <Table
                rowGetter={({ index }) => loaderList[index]}
                rowCount={loaderList.length}
                onRowClick={({ rowData, index }) => {
                  if (index !== undefined) {
                    setSelectStatId(rowData.id)
                  }
                }}
                minWidth={750}
              >
                <Column label={'ID'} dataKey='id' width={170} />
                <Column
                  label={'Type'}
                  dataKey='id'
                  width={120}
                  cellRenderer={renderLoadType}
                />
                <Column
                  label={'Date'}
                  dataKey='created'
                  width={80}
                  cellRenderer={({ cellData }: TableCellProps) => {
                    return cellData && dateFormat(cellData.date)
                  }}
                />
                <Column label={'%'} dataKey='percentComplete' width={40} />
                <Column label={'Lines Read'} dataKey='linesRead' width={70} />
                <Column label={'User'} dataKey='user' width={170} />
                <Column
                  label={'Action'}
                  dataKey='id'
                  width={75}
                  cellRenderer={({ cellData }) => {
                    return (
                      <Button
                        size='small'
                        variant='text'
                        onClick={() => handleDownloadLog(cellData)}
                      >
                        Download
                      </Button>
                    )
                  }}
                />
              </Table>
            </div>
            <Divider className='mv3' />
          </>
        )}
        {otherActionsList?.length > 0 && (
          <>
            <Label noPadding>Other Jobs Status</Label>
            <div className='bg-white br1 ba b--black-10 overflow-hidden'>
              <Table
                rowGetter={({ index }) => otherActionsList[index]}
                rowCount={otherActionsList.length}
                onRowClick={({ rowData, index }) => {
                  if (index !== undefined) {
                    setSelectStatId(rowData.id)
                  }
                }}
              >
                <Column label={'ID'} dataKey='id' width={175} />
                <Column
                  label={'Date'}
                  dataKey='created'
                  width={80}
                  cellRenderer={({ cellData }: TableCellProps) => {
                    return cellData && dateFormat(cellData.date)
                  }}
                />
                <Column
                  label={'% / Lines'}
                  dataKey='percentComplete'
                  width={75}
                />
                <Column label={'Type'} dataKey='type' width={130} />
                <Column label={'User'} dataKey='user' width={130} />
                <Column
                  label={'Action'}
                  dataKey='id'
                  width={75}
                  cellRenderer={({ cellData }) => {
                    return (
                      <Button
                        size='small'
                        variant='text'
                        onClick={() => handleDownloadLog(cellData)}
                      >
                        Download
                      </Button>
                    )
                  }}
                />
              </Table>
            </div>
            <Divider className='mv3' />
          </>
        )}
      </Main>
      <div className='mt4' />
      <Aside>
        <Card>
          <InputLabel>Loader State</InputLabel>
          {cursorStart && (
            <div>
              <Label>Cursor Start</Label>
              <Text>{cursorStart?.toString()}</Text>
            </div>
          )}
          {cursorEnd && (
            <div>
              <Label>Cursor End</Label>
              <Text>{cursorEnd?.toString()}</Text>
            </div>
          )}
          {statById[selectedStatId] ? (
            Object.keys(statById[selectedStatId])
              .filter(key => !!statById[selectedStatId])
              .sort()
              .map(key => {
                const value = statById[selectedStatId][key]
                return (
                  <div key={key}>
                    <Label>{startCase(key)}</Label>
                    <Text>
                      {typeof value === 'object' && !Array.isArray(value)
                        ? JSON.stringify(value)
                        : value.toString()}
                    </Text>
                  </div>
                )
              })
          ) : (
            <Warning
              message={'Select a loader job row to see the details'}
              className='mt4'
            />
          )}
        </Card>
      </Aside>
    </Page>
  )
}

export default LoaderState
