import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Column } from 'react-virtualized'
import Table from 'shared/components/Table'
import InsightTitle from 'shared/components/InsightTitle'
import startCase from 'lodash.startcase'
import Button from 'shared/components/Button'
import exportData from 'shared/utils/exportData'
import { List } from 'immutable'
import { UserReconciliationReport } from './InternalStats'
import moment from 'moment'
import Select from 'shared/components/Select'

type Props = {
  stat: string
  userReconciliationReport: List<UserReconciliationReport>
}
const UserReconciliationTable = ({ stat, userReconciliationReport }: Props) => {
  const [partnerSelected, setPartnerSelected] = useState<string>('')
  const [monthSelected, setMonthSelected] = useState<string>('')
  const [yearSelected, setYearSelected] = useState<number>(0)

  const filterByPartnerSelected = useMemo(() => {
    return userReconciliationReport && !!userReconciliationReport.size
      ? userReconciliationReport
          .reduce<string[]>((results, currentItem) => {
            let partnerSelectedInFilter = currentItem.get('partner')
            if (results.indexOf(partnerSelectedInFilter) < 0) {
              results.push(partnerSelectedInFilter)
            }
            return results
          }, [])
          .sort()
      : undefined
  }, [userReconciliationReport])

  const filterByMonthSelected = useMemo(() => {
    return userReconciliationReport && !!userReconciliationReport.size
      ? userReconciliationReport
          .reduce<string[]>((results, currentItem) => {
            let monthSelectedInFilter = currentItem.get('month')
            if (
              monthSelectedInFilter &&
              results.indexOf(monthSelectedInFilter) < 0
            ) {
              results.push(monthSelectedInFilter)
            }
            return results
          }, [])
          .sort((a, b) => {
            let months = [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
            ]
            return months.indexOf(a) - months.indexOf(b)
          })
      : undefined
  }, [userReconciliationReport])

  const filterByYearSelected = useMemo(() => {
    return userReconciliationReport && !!userReconciliationReport.size
      ? userReconciliationReport
          .reduce<number[]>((results, currentItem) => {
            let yearSelectedInFilter = currentItem.get('year')
            if (
              yearSelectedInFilter &&
              results.indexOf(yearSelectedInFilter) < 0
            ) {
              results.push(yearSelectedInFilter)
            }
            return results
          }, [])
          .sort((a, b) => b - a)
      : undefined
  }, [userReconciliationReport])

  const visibleUserReconciliationReportSelected = useMemo(() => {
    return userReconciliationReport && !!userReconciliationReport.size
      ? userReconciliationReport
          .filter(stat => {
            let currentPartner = stat.get('partner')
            let currentMonth = stat.get('month')
            let currentYear = stat.get('year')
            if (
              currentPartner &&
              currentPartner === partnerSelected &&
              currentMonth &&
              currentMonth === monthSelected &&
              currentYear &&
              currentYear === yearSelected
            ) {
              return true
            }
            if (
              currentPartner &&
              currentPartner === partnerSelected &&
              currentMonth &&
              currentMonth === monthSelected &&
              !yearSelected
            ) {
              return true
            }
            if (
              currentPartner &&
              currentPartner === partnerSelected &&
              currentYear &&
              currentYear === yearSelected &&
              !monthSelected
            ) {
              return true
            }
            if (
              currentMonth &&
              currentMonth === monthSelected &&
              currentYear &&
              currentYear === yearSelected &&
              !partnerSelected
            ) {
              return true
            }
            if (
              currentPartner &&
              currentPartner === partnerSelected &&
              !monthSelected &&
              !yearSelected
            ) {
              return true
            }
            if (
              currentMonth &&
              currentMonth === monthSelected &&
              !partnerSelected &&
              !yearSelected
            ) {
              return true
            }
            if (
              currentYear &&
              currentYear === yearSelected &&
              !partnerSelected &&
              !monthSelected
            ) {
              return true
            }
            if (!partnerSelected && !monthSelected && !yearSelected) {
              return true
            }
            return false
          })
          .sort((a, b) => {
            let month1 = a.get('month')
            let month2 = b.get('month')
            let year1 = String(a.get('year'))
            let year2 = String(b.get('year'))
            let date1 = month1 + year1
            let date2 = month2 + year2
            return (
              moment(date2, 'MMMYYYY').valueOf() -
              moment(date1, 'MMMYYYY').valueOf()
            )
          })
      : undefined
  }, [userReconciliationReport, partnerSelected, monthSelected, yearSelected])

  const onChangePartnerSelected = e => setPartnerSelected(e.target.value)
  const onChangeMonthSelected = e => setMonthSelected(e.target.value)
  const onChangeYearSelected = e => setYearSelected(parseInt(e.target.value))
  return (
    <>
      {visibleUserReconciliationReportSelected && (
        <div key={stat}>
          <div className='flex justify-between items-center mt4'>
            <InsightTitle>{startCase(stat)}</InsightTitle>
            <div className='flex items-end'>
              <label htmlFor='Partner' className='visuallyhidden'>
                <FormattedMessage
                  id='InternalStats.SelectPartner'
                  defaultMessage={'Select partner: '}
                />
              </label>
              <Select
                name='Partner'
                fullWidth
                onChange={onChangePartnerSelected}
              >
                <option value=''>Partner</option>
                {filterByPartnerSelected &&
                  filterByPartnerSelected.map(stat => (
                    <option key={stat} value={stat}>
                      {startCase(stat)}
                    </option>
                  ))}
              </Select>
              <label htmlFor='Month' className='visuallyhidden'>
                <FormattedMessage
                  id='InternalStats.SelectMonth'
                  defaultMessage={'Select month: '}
                />
              </label>
              <Select fullWidth name='Month' onChange={onChangeMonthSelected}>
                <option value=''>Month</option>
                {filterByMonthSelected &&
                  filterByMonthSelected.map((stat, index) => (
                    <option key={index} value={stat}>
                      {stat}
                    </option>
                  ))}
              </Select>
              <label htmlFor='Year' className='visuallyhidden'>
                <FormattedMessage
                  id='InternalStats.SelectYear'
                  defaultMessage={'Select year:'}
                />
              </label>
              <Select fullWidth name='Year' onChange={onChangeYearSelected}>
                <option value=''>Year</option>
                {filterByYearSelected &&
                  filterByYearSelected.map((stat, index) => (
                    <option key={index} value={stat}>
                      {stat}
                    </option>
                  ))}
              </Select>
              <div className='ml2'>
                <Button
                  onClick={() => {
                    exportData.exportCSV(
                      visibleUserReconciliationReportSelected.toJS(),
                      'User Reconciliation Report',
                      true
                    )
                  }}
                >
                  <FormattedMessage
                    id='InternalStats.Export'
                    defaultMessage='Export'
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className='bg-white br1 ba b--black-10'>
            <Table
              rowGetter={({ index }) =>
                visibleUserReconciliationReportSelected &&
                !!visibleUserReconciliationReportSelected.size &&
                visibleUserReconciliationReportSelected.get(index)
              }
              rowCount={visibleUserReconciliationReportSelected.size}
            >
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.partner'
                    defaultMessage='Partner'
                  />
                }
                width={150}
                dataKey='partner'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.user'
                    defaultMessage='User'
                  />
                }
                width={350}
                dataKey='user'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.numSearches'
                    defaultMessage='NumSearches'
                  />
                }
                width={150}
                dataKey='numSearches'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.classificationCodes'
                    defaultMessage='ClassificationCodes'
                  />
                }
                width={200}
                dataKey='classificationCodes'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.numClassificationCodes'
                    defaultMessage='NumClassificationCodes'
                  />
                }
                width={170}
                dataKey='numClassificationCodes'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.month'
                    defaultMessage='Month'
                  />
                }
                width={70}
                dataKey='month'
              />
              <Column
                label={
                  <FormattedMessage
                    id='InternalStats.year'
                    defaultMessage='Year'
                  />
                }
                width={70}
                dataKey='year'
              />
            </Table>
          </div>
        </div>
      )}
    </>
  )
}

export default UserReconciliationTable
