import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as Step1 } from './signup.svg'
import { ReactComponent as Step2 } from './add-cert.svg'
import { ReactComponent as Step3 } from './add-agency.svg'
import { ReactComponent as Step4 } from './wbe.svg'
import { ReactComponent as Step5 } from './upload-cert.svg'
import { ReactComponent as Step6 } from './verify.svg'
import Divider from 'shared/components/Divider'
import Text from 'shared/components/Text'
import ThumbUploadCert from './upload-cert-thumb.png'

const UploadCertification = () => {
  const [playVideo, setPlayVideo] = useState<boolean>(false)

  return (
    <div className='mw8 center ph3 pt2 pb4 pb5-ns'>
      <div className='ph6'>
        {!playVideo ? (
          <button
            onClick={() => setPlayVideo(true)}
            className='pa0 bw0 pointer dim'
          >
            <img alt='Play Self Certify in TealBook' src={ThumbUploadCert} />
          </button>
        ) : (
          <iframe
            width='100%'
            height='377'
            src='https://www.youtube.com/embed/E306t-YQAvo?playlist=E306t-YQAvo&rel=0&modestbranding=1&autoplay=1'
            title='Upload Certification in TealBook'
            allow='autoplay'
            frameBorder={0}
          ></iframe>
        )}
      </div>
      <ol className='list pa0 flex-ns justify-between flex-wrap'>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h2 className='mb0 f4'>1.</h2>
            <Step1 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='UploadCertification.Instructions1'
                defaultMessage={`Sign up for a free TealBook account with your email, log in, and navigate to your Profile page.`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h2 className='mb0 f4'>2.</h2>
            <Step2 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='UploadCertification.Instructions2'
                defaultMessage={`Head to the Qualifications section, and in the Add a Certification drop down menu, select Diversity.`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h2 className='mb0 f4'>3.</h2>
            <Step3 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='UploadCertification.Instructions3'
                defaultMessage={`Under Certification Agency, select one of the available agencies. If the Certification Agency is not available, select Other, and manually type in the Agency Name.`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h2 className='mb0 f4'>4.</h2>
            <Step4 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='UploadCertification.Instructions4'
                defaultMessage={`From the Name of Certification drop down menu, select the appropriate Certification Name.`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h2 className='mb0 f4'>5.</h2>
            <Step5 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='UploadCertification.Instructions5'
                defaultMessage={`Upload the appropriate certificate, and enter the certification expiration date and certificate number.`}
              />
            </p>
          </div>
        </li>
        <li className='tc tl-ns w-third-ns mt4'>
          <div className='dib tl'>
            <h2 className='mb0 f4'>6.</h2>
            <Step6 className='w5' aria-hidden='true' />
            <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
              <FormattedMessage
                id='UploadCertification.Instructions6'
                defaultMessage={`To conclude the process, check the supplier verification box, and click Add.`}
              />
            </p>
          </div>
        </li>
      </ol>
      <Divider className='mv4' />
      <Text>
        <FormattedMessage
          id='UploadCertification.Footer1b'
          defaultMessage={`1. This process can be replicated multiple times, one for each unique certificate you would like to upload.`}
        />
      </Text>
      <Text>
        <FormattedMessage
          id='UploadCertification.Footer2b'
          defaultMessage={`2. The process for uploading Quality, Information Security, Social Responsibility and Sustainability, and Food related certifications is similar.`}
        />
      </Text>
    </div>
  )
}

export default UploadCertification
