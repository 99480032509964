import React, { useState } from 'react'
import { ReactComponent as ImageArrowDown } from 'shared/assets/icons/arrow-down.svg'
import { ReactComponent as ImageArrowUp } from 'shared/assets/icons/arrow-up.svg'
import { FormattedMessage } from 'react-intl'
import DisclosureAgreement from './DisclosureAgreement'
import Divider from 'shared/components/Divider'

const buttonStyle =
  'pv2-5 ph2 flex items-center justify-between pointer bg-transparent bn w-100'

const TealbotInstructionV2 = () => {
  const [openAgreement, setOpenAgreement] = useState<boolean>(false)

  const handleAgreementClick = () => {
    setOpenAgreement(currentState => !currentState)
  }

  return (
    <div className='mt2'>
      <button className={buttonStyle} onClick={handleAgreementClick}>
        <FormattedMessage
          id='TealbotInstructionV2.Agreementupdated'
          defaultMessage='Privacy Policy and Non-Disclosure Agreement'
        />
        {openAgreement && <ImageArrowUp />}
        {!openAgreement && <ImageArrowDown />}
      </button>
      {openAgreement && (
        <div className='pl2 pv2'>
          <DisclosureAgreement />
        </div>
      )}
      <Divider />
    </div>
  )
}

export default TealbotInstructionV2
