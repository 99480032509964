import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { List, RecordOf, OrderedMap } from 'immutable'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import dateFormat from 'shared/utils/dateFormat'
import ReviewSpendItems from 'supplier/Insight/components/LoadPage/ReviewSpendItems'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Clear'
import startCase from 'lodash.startcase'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import NavBar from 'shared/components/NavBar'
import Divider from 'shared/components/Divider'
import Link from 'shared/components/Link'
import supplierPaths from 'supplier/routes/paths'
import parsePath from 'shared/utils/parsePath'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import NavTab from 'shared/components/NavTab'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import Tooltip from 'shared/components/Tooltip'
import FileSaver from 'file-saver'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import dataPortalPaths from 'data-portal/routes/paths'
import Loading from 'shared/components/Loading'
import UploadSection from 'supplier/Insight/components/LoadPage/UploadSection'
import { UploadHistoryType } from 'supplier/Insight/containers/LoadPageContainer/LoadPageContainer'
import LoadPageErrorParser from 'supplier/Insight/components/LoadPage/LoadPageErrorParser'

const messages = defineMessages({
  complete: { id: 'Complete', defaultMessage: 'Complete' },
  processing: { id: 'Processing', defaultMessage: 'Processing' },
  error: { id: 'UploadError', defaultMessage: 'Upload Error' }
})

type Props = {
  isUploading: boolean
  uploadError: boolean
  uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryType>>>
  uploadSpendFile: (payload: {
    file: File
    year: number
    quarter: number
    listType: string
    parentOrgUnitIdMl: string
  }) => void
  removeSpend: (payload: {
    stagingId: string
    listType: string
    uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryType>>>
    activeQuarter: string
  }) => void
  loadStagingHistory: (payload: { listType: string }) => void
  notify: (payload: { message: string }) => void
  isLoading: boolean
}

export const MlLoadPage = (props: Props) => {
  const {
    uploadHistory,
    uploadSpendFile,
    removeSpend,
    loadStagingHistory,
    notify,
    isLoading
  } = props

  const intl = useIntl()
  const location = useLocation()

  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined)

  const [confirmRemoveStaging, setConfirmRemoveStaging] = useState<string>('')
  const [exportStagingPressed, setExportStagingPressed] = useState<string>('')

  const activeQuarter = useMemo(() => {
    const { show } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })
    return show
  }, [location.search])

  const activeHistory = useMemo(() => {
    return uploadHistory && uploadHistory.get(activeQuarter)
  }, [activeQuarter, uploadHistory])

  const rowsWithUploadError = useMemo(() => {
    const errorIndexes = activeHistory?.map((currentValue, index) => {
      if (currentValue.get('status') === 'error') {
        return index
      } else return -1
    })
    return errorIndexes?.filter(index => index !== -1)
  }, [activeHistory])

  const lastComplete = useMemo(() => {
    return activeHistory
      ? activeHistory.findLastIndex(
          history => history.get('status') === 'complete'
        )
      : -1
  }, [activeHistory])

  const isAnyProcessing = useMemo(() => {
    return (
      !!activeHistory &&
      activeHistory.some(history => history.get('status') === 'processing')
    )
  }, [activeHistory])

  const highlightIndex = useMemo(() => {
    return typeof selectedRow === 'number' ? selectedRow : lastComplete
  }, [selectedRow, lastComplete])

  const handleIntervalCall = useCallback(() => {
    if (isAnyProcessing) {
      loadStagingHistory({
        listType: 'ml'
      })
    }
  }, [isAnyProcessing, loadStagingHistory])

  useEffect(() => {
    setSelectedRow(undefined)
  }, [activeQuarter])

  useEffect(() => {
    const interval = global.setInterval(() => {
      handleIntervalCall()
    }, 60000)
    return () => global.clearInterval(interval)
  }, [handleIntervalCall])

  const handleFileUpload = ({ file, year, quarter, parentOrgUnitIdMl }) => {
    uploadSpendFile({
      file,
      year,
      quarter,
      parentOrgUnitIdMl,
      listType: 'ml'
    })
  }

  const renderActionColumn = ({ cellData, rowData }: TableCellProps) => {
    const fullFileName = rowData.get('fileName')
    const uploadUserId = rowData.get('userId')
    return (
      <div onClick={event => event.stopPropagation()}>
        <Tooltip
          title={
            <FormattedMessage
              id='LoadPage.DownloadLoadFile'
              defaultMessage='Click here to download this file, so you can compare with mapping below.'
            />
          }
        >
          {
            <span
              className='pointer near-black hover-teal no-underline'
              onClick={() =>
                handleSideBySideDownload(cellData, fullFileName, uploadUserId)
              }
            >
              {cellData}
            </span>
          }
        </Tooltip>
      </div>
    )
  }

  const handleSideBySideDownload = (fileName, fullFileName, uploadUserId) => {
    api
      .download(
        `${apiRoutes.loader}/loader/tier2LoadFile/${fullFileName}/${uploadUserId}`
      )
      .then(response => response.blob())
      .then(blob => FileSaver.saveAs(blob, `${fileName}`))
      .catch(err =>
        notify({
          message: err.message
        })
      )
  }

  const handleStagingDownload = rowData => {
    api
      .get(`${apiRoutes.loader}/util/stagingDownload/${rowData.get('id')}/ml`)
      .then(response => {
        const [fileName] = rowData.get('clientFileName').split('.')
        FileSaver.saveAs(response.url, `Review Mapping-${fileName}`)
        setExportStagingPressed('')
      })
      .catch(err => {
        setExportStagingPressed('')
        notify({
          message: err.message
        })
      })
  }

  return (
    <div>
      <UploadSection isMl onUpload={handleFileUpload} />
      {uploadHistory && uploadHistory.size > 0 && (
        <>
          <Divider className='mt2' />
          <div
            className='overflow-x-auto overflow-y-hidden'
            style={{ paddingBottom: 3 }}
          >
            <NavBar>
              {uploadHistory.keySeq().map(key => (
                <li key={key}>
                  <NavTab
                    to={`${dataPortalPaths.supplierMatchingMl}?show=${key}`}
                    isActive={(match, location) =>
                      location.search === `?show=${key}`
                    }
                  >
                    {key.split('-').join('-Q')}
                  </NavTab>
                </li>
              ))}
            </NavBar>
          </div>
        </>
      )}
      {isLoading ? (
        <Loading />
      ) : (
        activeHistory &&
        activeHistory.size > 0 && (
          <>
            <div className='bg-white br1 ba b--black-10 overflow-hidden'>
              <Table
                rowGetter={({ index }) => activeHistory.get(index)}
                rowCount={activeHistory.size}
                onRowClick={({ rowData, index }) => {
                  if (rowData.get('status') === 'complete') {
                    setSelectedRow(index)
                  }
                }}
                highlightIndexes={
                  typeof highlightIndex === 'number'
                    ? List([highlightIndex])
                    : undefined
                }
                errorIndexes={rowsWithUploadError}
              >
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Filename'
                      defaultMessage='Filename'
                    />
                  }
                  dataKey='clientFileName'
                  width={200}
                  cellRenderer={renderActionColumn}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.UploadDate'
                      defaultMessage='Upload Date'
                    />
                  }
                  dataKey='uploadDate'
                  width={200}
                  cellRenderer={({ cellData }: TableCellProps) =>
                    dateFormat(cellData)
                  }
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.User'
                      defaultMessage='User'
                    />
                  }
                  dataKey='uploadBy'
                  width={200}
                  cellRenderer={({ cellData, rowData }: TableCellProps) => (
                    <Link
                      to={parsePath(supplierPaths.userProfile, {
                        userId: rowData.get('userId')
                      })}
                    >
                      {cellData}
                    </Link>
                  )}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Status'
                      defaultMessage='Status'
                    />
                  }
                  dataKey='status'
                  width={200}
                  cellRenderer={({ rowData }: TableCellProps) => {
                    const { status, fileLoadErrors } = rowData?.toJS()
                    return (
                      <span
                        className={
                          status === 'complete'
                            ? 'dib br2 pv2 ph2 tc f6 white bg-dark-green'
                            : status === 'error'
                            ? 'dib br2 pv1 ph2 tc f6 white bg-red'
                            : ''
                        }
                      >
                        {messages[status]
                          ? intl.formatMessage(messages[status])
                          : startCase(status)}
                        {status === 'error' &&
                          fileLoadErrors &&
                          fileLoadErrors.length && (
                            <Tooltip
                              title={
                                <LoadPageErrorParser
                                  fileLoadErrors={fileLoadErrors}
                                />
                              }
                            >
                              <InfoIcon
                                aria-hidden='false'
                                className='ml1 v-mid'
                              />
                            </Tooltip>
                          )}
                      </span>
                    )
                  }}
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Row'
                      defaultMessage='# of Rows'
                    />
                  }
                  dataKey='rowCount'
                  width={140}
                  cellRenderer={({ cellData, rowData }: TableCellProps) =>
                    cellData !== undefined && cellData.toLocaleString()
                  }
                />
                <Column
                  label={
                    <FormattedMessage
                      id='LoadPage.Actions'
                      defaultMessage='Actions'
                    />
                  }
                  dataKey='status'
                  width={260}
                  flexGrow={1}
                  headerClassName='tr'
                  cellRenderer={({ cellData, rowData }: TableCellProps) => {
                    return cellData === 'complete' ? (
                      <div className='flex items-center justify-end'>
                        {rowData.get('rowCount') > 0 && (
                          <Button
                            className='ml2'
                            autoSize
                            onClick={e => {
                              e.stopPropagation()
                              handleStagingDownload(rowData)
                              setExportStagingPressed(rowData.get('id'))
                            }}
                            disabled={
                              exportStagingPressed === rowData.get('id')
                            }
                          >
                            {exportStagingPressed !== rowData.get('id') ? (
                              <FormattedMessage
                                id='LoadPage.Export'
                                defaultMessage='Export'
                              />
                            ) : (
                              <FormattedMessage
                                id='LoadPage.Exporting1'
                                defaultMessage='Exporting'
                              />
                            )}
                          </Button>
                        )}
                        {cellData === 'complete' && (
                          <IconButton
                            aria-label='Delete spend'
                            onClick={e => {
                              e.stopPropagation()
                              setConfirmRemoveStaging(rowData.get('id'))
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </div>
                    ) : cellData === 'error' ? (
                      <FormattedMessage
                        id='LoadPage.OnError'
                        defaultMessage='Please fix errors and reload file'
                      />
                    ) : null
                  }}
                />
              </Table>
            </div>
            {activeHistory.some(
              history => history.get('status') === 'processing'
            ) && (
              <Text className='mt3 flex items-center'>
                <InfoOutlined className='mr2' />
                <FormattedMessage
                  id='LoadPage.ProcessingMessage'
                  defaultMessage='A file can take up to an hour to process.'
                />
              </Text>
            )}
          </>
        )
      )}
      <ConfirmationDialog
        open={!!confirmRemoveStaging}
        onClose={() => setConfirmRemoveStaging('')}
        onConfirm={() =>
          removeSpend({
            stagingId: confirmRemoveStaging,
            listType: 'ml',
            uploadHistory,
            activeQuarter
          })
        }
      >
        <Text>
          <FormattedMessage
            id='LoadPage.ConfirmDeleteStaging1'
            defaultMessage='Are you sure you want to remove the this spend?'
          />
        </Text>
      </ConfirmationDialog>
      {highlightIndex > -1 && activeHistory && activeHistory.size > 0 && (
        <ReviewSpendItems
          stagingId={activeHistory?.getIn([highlightIndex, 'id'])}
          isEditable={
            activeHistory?.getIn([highlightIndex, 'status']) !== 'published'
          }
          entity='stagingRow'
          label={
            activeHistory
              ? `${activeHistory.getIn([
                  highlightIndex,
                  'year'
                ])}-Q${activeHistory.getIn([
                  highlightIndex,
                  'quarter'
                ])} - ${activeHistory.getIn([
                  highlightIndex,
                  'clientFileName'
                ])}`
              : ''
          }
        />
      )}
    </div>
  )
}

export default MlLoadPage
