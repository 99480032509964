import React from 'react'
import Paper from 'shared/components/Paper'
import AppConfigNav from './AppConfigNav'
import Divider from 'shared/components/Divider'
import { Switch, Route } from 'react-router'
import paths from '../../routes/paths'
import AppConfigSRM from './AppConfigSRM'
import AppConfigRFI from './AppConfigRFI'
import AppConfigSecurity from './AppConfigSecurity'
import AppConfigTools from './AppConfigTools'
import AppConfigIntegrationsContainer from 'admin/containers/AppConfigIntegrationsContainer'
import Page from 'shared/components/Page/Page'
import { FormattedMessage } from 'react-intl'

type Props = {
  isBuyerAdmin: boolean
  isTealbot: boolean
}

const title = (
  <FormattedMessage id='App.AppConfig' defaultMessage='App Config' />
)

const AppConfig = ({ isBuyerAdmin, isTealbot }: Props) => {
  return (
    <>
      <Page title={title}>
        <AppConfigNav isTealbot={isTealbot} />
        <Divider />
        <Paper className='mt4 pt3-5'>
          <Switch>
            {isBuyerAdmin && (
              <Route exact path={paths.appConfig} component={AppConfigSRM} />
            )}
            {isTealbot && (
              <Route path={paths.appRFI} component={AppConfigRFI} />
            )}
            {isTealbot && (
              <Route path={paths.appSecurity} component={AppConfigSecurity} />
            )}
            {isTealbot && (
              <Route path={paths.appTools} component={AppConfigTools} />
            )}
            {(isBuyerAdmin || isTealbot) && (
              <Route
                path={paths.integrations}
                component={AppConfigIntegrationsContainer}
              />
            )}
          </Switch>
        </Paper>
      </Page>
    </>
  )
}

export default AppConfig
