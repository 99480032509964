import React, {
  useRef,
  useState,
  useMemo,
  memo,
  ChangeEvent,
  useCallback,
  useEffect,
  ReactElement
} from 'react'
import { RecordOf, List } from 'immutable'
import Tealbot from 'shared/assets/icons/tealbot-circle.png'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import FileInput from 'shared/components/FileInput'
import Checkbox from '@material-ui/core/Checkbox'
import AddCertContainer from '../../containers/AddCertContainer'
import { useSelector, useDispatch } from 'react-redux'
import {
  saveSurveyResponse,
  uploadSurveyAttachment,
  removeSurveyAttachment
} from '../../actions'
import surveySelectors from '../../selectors/surveySelectors'
import RootState from 'shared/models/RootState'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Clear'
import Certificate from '../../containers/CertificateContainer'
import Attachment from '../Attachment'
import {
  AttachmentType,
  CertType
} from '../../reducers/surveyReducer/surveyReducer'
import Divider from 'shared/components/Divider'
import Select from 'shared/components/Select'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ReactComponent as ImageArrowDown } from 'shared/assets/icons/arrow-down.svg'
import { ReactComponent as ImageArrowUp } from 'shared/assets/icons/arrow-up.svg'
import useThemeColors from 'shared/utils/useThemeColors'
import { notify } from 'shared/actions'

const messages = defineMessages({
  yes: { id: 'Yes', defaultMessage: 'Yes' },
  no: { id: 'No', defaultMessage: 'No' },
  uploadedDocuments: {
    id: 'uploadedDocuments',
    defaultMessage: 'Uploaded Documents'
  },
  fileTypeError: {
    id: 'fileTypeError',
    defaultMessage: 'File upload failed. Please upload a PDF document.'
  }
})

type Props = {
  pageId: string
  parentQuestionId?: string
  questionId: string
  type?: 'cert' | 'doc'
  label?: string | ReactElement<FormattedMessage>
  buttonLabel?: string
  multiple?: boolean
  uploadDocTypes?: Array<{ value: string; label: string }>
  certSuggestion?: List<
    RecordOf<{
      category: string
      certAgency: string
      certExpiration: string
      certificateNumber: string
      certificationUrl: string
      subCategory: string
      timeStamp: string
    }>
  >
  certCategory?:
    | 'diversity'
    | 'quality'
    | 'security'
    | 'sustainability'
    | 'food'
  certSubCategory?: string
}

const AttachmentQuestion = (props: Props) => {
  const {
    type,
    label,
    buttonLabel,
    certSuggestion,
    certCategory,
    certSubCategory,
    pageId,
    parentQuestionId,
    questionId,
    multiple,
    uploadDocTypes
  } = props
  const dispatch = useDispatch()
  const intl = useIntl()
  const colors = useThemeColors()

  const addButton = useRef<HTMLLabelElement>(null)

  const questionResponse = useSelector((state: RootState) =>
    surveySelectors.getResponseDetailByIds(
      state,
      pageId,
      questionId,
      parentQuestionId
    )
  )
  const isUploading = useSelector(surveySelectors.isUploading)
  const existingDocs = useSelector(surveySelectors.getSurveyResponseDetailsDocs)
  const isEditing = useSelector((state: RootState) => {
    return state.getIn(['supplier', 'survey', 'editing'])
  })
  const [attachmentTypeSelected, setAttachmentTypeSelected] = useState<string>(
    ''
  )
  const [certSuggestSelected, setCertSuggestSelected] = useState<string>('')
  const [existingDocsSelected, setExistingDocsSelected] = useState<string>('')

  const [docTypeSelected, setTypeSelected] = useState<string>('')
  const [expandUploadedDocuments, setExpandUploadedDocuments] = useState<
    boolean
  >(false)
  const [expandExistingDocuments, setExpandExistingDocuments] = useState<
    boolean
  >(false)

  const attachments = questionResponse?.get('attachments')
  const attachmentType = questionResponse?.get('attachmentType')

  const filteredCertSuggestion = useMemo(() => {
    return certSuggestion?.filter(cert => {
      const index = attachments?.findIndex((att: RecordOf<CertType>) => {
        return att.get('timeStamp') === cert.get('timeStamp')
      })
      return !attachments || index === -1
    })
  }, [certSuggestion, attachments])

  const filteredExistingDocs: List<RecordOf<AttachmentType>> = useMemo(() => {
    if ((!!uploadDocTypes?.length && !docTypeSelected) || isEditing) {
      return List([])
    }
    if (!!uploadDocTypes?.length && !!docTypeSelected) {
      return existingDocs
    }
    return existingDocs?.filter(doc => {
      const index = attachments?.findIndex(
        (att: RecordOf<AttachmentType>) =>
          att.get('description') === doc.get('description')
      )
      return !attachments || index === -1
    })
  }, [existingDocs, attachments, uploadDocTypes, isEditing, docTypeSelected])

  useEffect(() => {
    if (!type && attachmentType) {
      setAttachmentTypeSelected(attachmentType)
    }
  }, [attachmentType, type])

  const saveResponse = useCallback(
    attachments => {
      dispatch(
        saveSurveyResponse({
          surveyResponseDetailId: questionResponse?.get('id'),
          surveyId: questionResponse?.get('surveyResponseId'),
          ...questionResponse?.toJS(),
          attachmentType: type || attachmentTypeSelected,
          attachments
        })
      )
    },
    [dispatch, type, questionResponse, attachmentTypeSelected]
  )

  const handleUploadAttachmentChange = (file: File) => {
    if (file && file.name.toLowerCase().endsWith('.pdf')) {
      dispatch(
        uploadSurveyAttachment({
          surveyResponseDetailId: questionResponse?.get('id'),
          docType: uploadDocTypes && docTypeSelected,
          file
        })
      )
      setTypeSelected('')
    } else {
      dispatch(notify({ message: intl.formatMessage(messages.fileTypeError) }))
    }
  }

  const handleRemoveAttachment = (
    attachment: RecordOf<AttachmentType> | RecordOf<CertType>
  ) => {
    dispatch(
      removeSurveyAttachment({
        attachmentType,
        surveyResponseDetailId: questionResponse?.get('id'),
        attachment: attachment.toJS()
      })
    )
    setCertSuggestSelected('')
  }

  const handleSuggestedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const timeStamp = e.currentTarget.value
    setCertSuggestSelected(timeStamp)

    const cert = filteredCertSuggestion?.find(
      suggest => suggest.get('timeStamp') === timeStamp
    )
    const category = cert?.get('category')
    const subCategory = cert?.get('subCategory')
    const attachment = {
      category,
      subCategory,
      timeStamp
    }

    const tempAttachments = attachments?.toJS() || []
    saveResponse([...tempAttachments, attachment])
  }
  const handleExistingDocChange = (e: ChangeEvent<HTMLInputElement>) => {
    const description = e.currentTarget.value
    setExistingDocsSelected(description)

    const doc = filteredExistingDocs?.find(
      exists => exists.get('description') === description
    )
    const fileName = doc?.get('fileName')
    const bucketName = doc?.get('bucketName')
    const attachment = {
      fileName,
      bucketName,
      description
    }
    if (docTypeSelected) {
      attachment['docType'] = docTypeSelected
    }
    const tempAttachments = attachments?.toJS() || []
    saveResponse([...tempAttachments, attachment])
    setExistingDocsSelected('')
    setTypeSelected('')
  }

  const handleDocTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setTypeSelected(e.currentTarget.value)
  }

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    let answer = e.currentTarget.value
    setAttachmentTypeSelected(answer)
  }

  const toggleExpandedUploadedDocuments = () => {
    setExpandUploadedDocuments(!expandUploadedDocuments)
  }

  const toggleExpandedExistingDocuments = () => {
    setExpandExistingDocuments(!expandExistingDocuments)
  }
  let isUploadedAttachmentRendered: boolean = false
  let isExistingAttachmentRendered: boolean = false
  return (
    <>
      {!type && (
        <div className='flex justify-between items-start'>
          <div className='mv2 pt1 w-two-thirds'>
            <Text className='fw6'>
              <FormattedMessage
                id='QuestionAttachment.certified'
                defaultMessage='Is it certified?'
              />
            </Text>
          </div>
          <div className='flex items-center justify-end w-third'>
            <RadioGroup
              color='primary'
              aria-label='answer'
              name='answer'
              value={attachmentTypeSelected}
              onChange={handleRadioChange}
              row
            >
              <FormControlLabel
                control={<Radio color='primary' size='small' value={'cert'} />}
                label={intl.formatMessage(messages.yes)}
                disabled={!!attachments?.size}
              />
              <FormControlLabel
                control={<Radio color='primary' size='small' value={'doc'} />}
                label={intl.formatMessage(messages.no)}
                disabled={!!attachments?.size}
              />
            </RadioGroup>
          </div>
        </div>
      )}
      {(type === 'cert' || attachmentTypeSelected === 'cert') &&
        (!attachments?.size || multiple) &&
        !!filteredCertSuggestion?.size && (
          <div className='mb3'>
            <div className='flex items-center'>
              <div className='w2 dib mr2'>
                <img src={Tealbot} alt='Tealbot' className='w-100' />
              </div>
              <Text>
                <FormattedMessage
                  id='QuestionAttachment.Suggested'
                  defaultMessage='Suggested certificates'
                />
              </Text>
            </div>
            {filteredCertSuggestion.map(cert => (
              <div
                key={cert.get('timeStamp')}
                className='br1 b--light-silver ba ph2 mv1 w-50-ns flex justify-between items-center'
              >
                <div>
                  <Certificate certTimeStamp={cert.get('timeStamp')} />
                </div>
                <Checkbox
                  name='attachment'
                  aria-label='Select Certificate'
                  checked={certSuggestSelected.includes(cert.get('timeStamp'))}
                  value={cert.get('timeStamp')}
                  size='small'
                  color='primary'
                  onChange={handleSuggestedChange}
                />
              </div>
            ))}
            <Divider className='w-50-ns mt3' />
          </div>
        )}
      {attachments && attachments?.size > 1 && (
        <div>
          <span className='f7 fw6 mv0 dib mr1'>
            {intl.formatMessage(messages.uploadedDocuments)}
          </span>

          {expandUploadedDocuments ? (
            <IconButton
              aria-label='Collapse'
              onClick={() => toggleExpandedUploadedDocuments()}
            >
              <ImageArrowUp />
            </IconButton>
          ) : (
            <IconButton
              aria-label='Expand'
              onClick={() => toggleExpandedUploadedDocuments()}
            >
              <ImageArrowDown />
            </IconButton>
          )}
          {!expandUploadedDocuments && (
            <button
              className={`bn bg-transparent dib br-pill ph2 pv1 white f7 fw6 pointer dim ${colors.primaryContained}`}
              aria-label={`Expand to see ${attachments?.size -
                1} collapsed documents`}
              onClick={() => toggleExpandedUploadedDocuments()}
            >
              +{attachments?.size - 1}
            </button>
          )}
        </div>
      )}
      {attachments &&
        attachments.map((attachment, i) => {
          if (
            attachments?.size > 1 &&
            !expandUploadedDocuments &&
            isUploadedAttachmentRendered
          ) {
            return null
          } else {
            isUploadedAttachmentRendered = true
          }
          return (
            <div
              key={`attachment-${i}`}
              className='br1 b--light-silver ba ph2 pv1 w-50-ns flex justify-between items-center mv1'
            >
              {attachmentType === 'doc' && (
                <Attachment
                  attachment={attachment as RecordOf<AttachmentType>}
                />
              )}
              {attachmentType === 'cert' && (
                <Certificate
                  certTimeStamp={(attachment as RecordOf<CertType>).get(
                    'timeStamp'
                  )}
                />
              )}
              <div className='mr2'>
                <IconButton
                  onClick={() => handleRemoveAttachment(attachment)}
                  aria-label='Remove'
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )
        })}
      {(multiple || !attachments || !attachments.size) &&
        (type === 'doc' || attachmentTypeSelected === 'doc') && (
          <div className='w-50-ns'>
            <div className='br1 b--light-silver ba pa2 flex justify-between items-center mt1'>
              {uploadDocTypes ? (
                <>
                  <label
                    htmlFor='selectdocumenttype'
                    className='visuallyhidden'
                  >
                    <FormattedMessage
                      id='QuestionAttachment.DocType'
                      defaultMessage='Please select document type'
                    />
                  </label>
                  <Select
                    value={docTypeSelected}
                    onChange={handleDocTypeChange}
                    name='selectdocumenttype'
                  >
                    <FormattedMessage
                      id='QuestionAttachment.DocType'
                      defaultMessage='Please select document type'
                    >
                      {message => <option value=''>{message}</option>}
                    </FormattedMessage>
                    {uploadDocTypes.map(docType => (
                      <option key={docType.value} value={docType.value}>
                        {docType.label}
                      </option>
                    ))}
                  </Select>
                </>
              ) : (
                <Text>
                  {label || (
                    <FormattedMessage
                      id='QuestionAttachment.ProvideDocumentation'
                      defaultMessage='Upload supporting documentation'
                    />
                  )}
                </Text>
              )}
              <div className='ml2'>
                <Button
                  autoSize
                  onClick={() => addButton.current?.click()}
                  disabled={isUploading || (uploadDocTypes && !docTypeSelected)}
                >
                  {buttonLabel || (
                    <FormattedMessage
                      id='QuestionAttachment.Upload'
                      defaultMessage='Upload'
                    />
                  )}
                </Button>
                <label hidden ref={addButton}>
                  <FileInput
                    name='responseFile'
                    accept={'.pdf'}
                    onChange={handleUploadAttachmentChange}
                    value=''
                  />
                </label>
              </div>
            </div>
            <Text style={{ direction: 'rtl' }} className='ma1'>
              <FormattedMessage
                id='QuestionAttachment.PdfType'
                defaultMessage='<em>Accepted file types: PDF</em>'
                values={{
                  em: em => <em>{em}</em>
                }}
              />
            </Text>
          </div>
        )}
      {(multiple || !attachments || !attachments.size) &&
        (type === 'doc' || attachmentTypeSelected === 'doc') &&
        !!filteredExistingDocs?.size && (
          <div className='mt3'>
            <Divider className='w-50-ns mb3' />
            <div className='flex items-center'>
              <div className='w2 dib mr2'>
                <img src={Tealbot} alt='Tealbot' className='w-100' />
              </div>
              <Text>
                <FormattedMessage
                  id='QuestionAttachment.ExisitngDocs'
                  defaultMessage='Existing documents'
                />
              </Text>
              {filteredExistingDocs?.size > 1 && (
                <>
                  {expandExistingDocuments ? (
                    <IconButton
                      aria-label='Collapse'
                      onClick={() => toggleExpandedExistingDocuments()}
                    >
                      <ImageArrowUp />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label='Expand'
                      onClick={() => toggleExpandedExistingDocuments()}
                    >
                      <ImageArrowDown />
                    </IconButton>
                  )}
                  {!expandExistingDocuments && (
                    <button
                      className={`bn bg-transparent dib br-pill ph2 pv1 white f7 fw6 pointer dim ${colors.primaryContained}`}
                      aria-label={`Expand to see ${filteredExistingDocs?.size -
                        1} collapsed documents`}
                      onClick={() => toggleExpandedExistingDocuments()}
                    >
                      +{filteredExistingDocs?.size - 1}
                    </button>
                  )}
                </>
              )}
            </div>
            {filteredExistingDocs.map(doc => {
              if (
                filteredExistingDocs?.size > 1 &&
                !expandExistingDocuments &&
                isExistingAttachmentRendered
              ) {
                return null
              } else {
                isExistingAttachmentRendered = true
              }
              return (
                <div
                  key={doc.get('description')}
                  className='br1 b--light-silver ba ph2 mv1 w-50-ns flex justify-between items-center'
                >
                  <div>
                    <Attachment attachment={doc as RecordOf<AttachmentType>} />
                  </div>
                  <Checkbox
                    name='attachment'
                    inputProps={{
                      'aria-label': 'Select Exisiting Document'
                    }}
                    checked={existingDocsSelected.includes(
                      doc.get('description')
                    )}
                    value={doc.get('description')}
                    size='small'
                    color='primary'
                    disabled={!!uploadDocTypes && !docTypeSelected}
                    onChange={handleExistingDocChange}
                  />
                </div>
              )
            })}
          </div>
        )}
      {(!attachments || !attachments.size || multiple) &&
        (type === 'cert' || attachmentTypeSelected === 'cert') && (
          <AddCertContainer
            certCategory={certCategory}
            certSubCategory={certSubCategory}
            hasSuggestion={
              filteredCertSuggestion && filteredCertSuggestion.size > 0
            }
          />
        )}
    </>
  )
}

export default memo(AttachmentQuestion)
