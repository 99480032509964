import * as actionTypes from '../actionTypes'
import takeFirst from 'shared/utils/sagas/takeFirst'
import { all, takeLatest } from 'redux-saga/effects'
import updateSettingsSaga from './updateSettingsSaga'
import loadClientDataSaga from './loadClientDataSaga'
import uploadClientFileSaga from './uploadClientFileSaga'
import addUserSaga from './addUserSaga'
import bulkDeleteUsersSaga from './bulkDeleteUsersSaga'
import bulkUpdateStatusSaga from './bulkUpdateStatusSaga'
import bulkUpdateRolesSaga from './bulkUpdateRolesSaga'
import resendUserInviteSaga from './resendUserInviteSaga'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import user from 'shared/utils/api/user'
import bulkAddUsersSaga from './bulkAddUsersSaga'
import linkBuyerWithIntegrationSaga from './linkBuyerWithIntegrationSaga'
import buyer from 'shared/utils/api/buyer'
import deleteClientSpendByIdSaga from './deleteClientSpendByIdSaga'

export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.UPDATE_SETTINGS, updateSettingsSaga),
    takeLatest(actionTypes.LOAD_CLIENT_DATA, loadClientDataSaga),
    takeLatest(actionTypes.UPLOAD_CLIENT_FILE, uploadClientFileSaga),
    takeFirst(actionTypes.ADD_USER, addUserSaga),
    takeLatest(actionTypes.BULK_ADD_USERS, bulkAddUsersSaga),
    takeFirst(actionTypes.BULK_DELETE_USERS, bulkDeleteUsersSaga),
    takeLatest(actionTypes.BULK_UPDATE_STATUS, bulkUpdateStatusSaga),
    takeLatest(actionTypes.BULK_UPDATE_ROLES, bulkUpdateRolesSaga),
    takeLatest(actionTypes.RESEND_USER_INVITE, resendUserInviteSaga),
    takeLatest(
      actionTypes.GENERATE_USER_TOKEN,
      createApiRequestSaga(user.generateUserToken, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeLatest(
      actionTypes.LINK_BUYER_WITH_INTEGRATION,
      linkBuyerWithIntegrationSaga
    ),
    takeFirst(
      actionTypes.LOAD_WEBHOOK_CONFIGS,
      createApiRequestSaga(buyer.loadWebhooks, {
        notifyOnError: true
      })
    ),
    takeFirst(
      actionTypes.SAVE_WEBHOOK_CONFIG,
      createApiRequestSaga(buyer.saveWebhook, {
        usePayload: true,
        returnPayload: true,
        notifyOnError: true,
        notifyOnSuccessMessage: 'Webhook Configuration Saved'
      })
    ),
    takeLatest(actionTypes.DELETE_CLIENT_SPEND_BY_ID, deleteClientSpendByIdSaga)
  ])
}
