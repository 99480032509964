import React from 'react'
import SummaryRow from '../LoadSummary/SummaryRow'

type Props = {
  year: string
  quarter: number
  subCategories: {
    subCategory: string
    subType?: string | undefined
    spend: number
  }[]
}

const ReviewAggregateLoad = (props: Props) => {
  const { year, quarter, subCategories } = props

  return (
    <SummaryRow
      quarter={`${year}-Q${quarter}`}
      initalSubCategories={subCategories}
      isReadOnly={true}
    />
  )
}
export default ReviewAggregateLoad
