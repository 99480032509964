import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import createSubmitSaga from 'shared/utils/sagas/createSubmitSaga'
import auth from 'shared/utils/api/auth'
import paths from 'accounts/routes/paths'
import analytics from 'shared/utils/analytics'

export function* registerSaga(action) {
  try {
    yield call(auth.register, action.payload.toJS())
    yield put(push(paths.registrationComplete))
    analytics.track('Account Created', action.payload.toJS())
  } catch (err) {
    throw err
  }
}

export default createSubmitSaga(registerSaga)
