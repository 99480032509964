import React, { Component, Fragment, ReactNode } from 'react'
import { List } from 'immutable'
import AddTagForm from 'buyer/SupplierProfile/components/AddTagForm'
import Pill from 'shared/components/Pill'
import Scrollable from 'shared/components/Scrollable'

const styles = {
  tagsWrapper: 'w-100 pt3 ph3-5 pb3'
}

export type TagsFormProps = {
  addLabel: ReactNode | string
  onDeleteTag: (pill: string) => void
  onSubmitNewTag: () => void
  inputPlaceholder: string
  tags: List<string>
  limit: number
  footerNote: string
  showButton: boolean
  onChange: () => void
  inputId: string
}

class TagsForm extends Component<TagsFormProps> {
  render() {
    const {
      addLabel,
      tags,
      onDeleteTag,
      onSubmitNewTag,
      inputPlaceholder,
      limit,
      footerNote,
      showButton,
      onChange,
      inputId
    } = this.props

    return (
      <div>
        {tags && tags.size > 0 && (
          <Fragment>
            <Scrollable>
              <div className={styles.tagsWrapper}>
                {tags.map(pill => (
                  <Pill
                    key={pill}
                    label={pill}
                    onRequestDelete={() => onDeleteTag(pill)}
                  />
                ))}
              </div>
            </Scrollable>
          </Fragment>
        )}

        <AddTagForm
          inputId={inputId}
          label={addLabel}
          onSubmit={onSubmitNewTag}
          inputPlaceholder={inputPlaceholder}
          numberOfTags={tags && tags.size}
          limit={limit}
          footerNote={footerNote}
          showButton={showButton}
          onChange={onChange}
        />
      </div>
    )
  }
}

export default TagsForm
