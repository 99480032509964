import React from 'react'
import { FormattedMessage } from 'react-intl'

import ExternalLink from 'shared/components/ExternalLink'
import Text from 'shared/components/Text'

export const SpendInformationText = () => (
  <Text>
    <FormattedMessage
      id='UploadSpendDetail.Description'
      defaultMessage='Complete the fields below to get started. Choose <bold>File</bold> data type to <loadLink>upload a detailed breakdown of your spend</loadLink> data for the selected quarter or <bold>Aggregate</bold> data type to manually <totalLink>enter your total direct or indirect spend</totalLink> amounts for each diversity category for the selected quarter.{br}{br}<bold>Indirect</bold> and <bold>Full spend</bold> spend types may be <spendLink>linked to multiple buyers</spendLink> for diverse spend allocation. You may publish only one full spend file or one set of Indirect Aggregate spend amounts per quarter.{br}{br}When you enter <bold>Direct Aggregate</bold> spend for a buyer in one quarter, you may enter only Direct Aggregate amounts for all other buyers within the same quarter.'
      values={{
        loadLink: link => (
          <ExternalLink href='https://tealbookhelp.zendesk.com/hc/en-us/articles/360052687131-How-do-I-upload-my-spend-data-for-Tier-2-Diversity-program-'>
            {link}
          </ExternalLink>
        ),
        totalLink: link => (
          <ExternalLink href='https://tealbookhelp.zendesk.com/hc/en-us/articles/8710229148820'>
            {link}
          </ExternalLink>
        ),
        spendLink: link => (
          <ExternalLink href='https://tealbookhelp.zendesk.com/hc/en-us/articles/8713635558036-Linking-published-indirect-Tier-2-diverse-spend-to-multiple-buyers'>
            {link}
          </ExternalLink>
        ),
        br: <br />,
        bold: text => <span className='b'>{text}</span>
      }}
    />
  </Text>
)
