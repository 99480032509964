import React, { ReactNode } from 'react'
import InsightWidget from 'shared/components/InsightWidget'
import TagCloud from 'shared/components/TagCloud'
import paths from '../../../routes/paths'
import qs from 'qs'
import { Map } from 'immutable'
import { useHistory } from 'react-router'

type Props = {
  title: string | ReactNode
  noData: string | ReactNode
  tags?: Map<string, number>
}

const TagCloudWidget = (props: Props) => {
  const history = useHistory()
  return (
    <InsightWidget title={props.title}>
      <div className='tc pv3'>
        {props.tags && props.tags.size > 0 ? (
          <TagCloud
            colors={[
              '#1E6A77',
              '#007EB3',
              '#247BA1',
              '#0000A8',
              '#6B5400',
              '#408500',
              '#BD3D20',
              '#541C33'
            ]}
            tags={props.tags.toJSON()}
            onClick={tag => {
              const queryString = qs.stringify({
                q: tag.value
              })

              history.push(`${paths.search}?${queryString}`)
            }}
          />
        ) : (
          props.noData
        )}
      </div>
    </InsightWidget>
  )
}

export default TagCloudWidget
