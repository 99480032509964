import { fromJS } from 'immutable'
import {
  UPDATE_CUSTOMIZE_PITCH_DECK,
  FOLLOW_CLIENT,
  LOAD_SUPPLIER_INSIGHT
} from 'supplier/shared/actionTypes'
import {
  LOAD_STAGING_HISTORY,
  REMAP_SUPPLIER,
  requestFailure
} from 'shared/actionTypes'
import { REMOVE_CUSTOMER } from 'supplier/Company/actionTypes'
import {
  LOAD_DATA,
  UPDATE_DATA,
  DELETE_DATA,
  LOAD_CLIENT_FILES,
  requestSuccess,
  REMOVE_SPEND,
  PUBLISH_SPEND_T2,
  UPLOAD_SPEND_FILE,
  CLOSE_SUPPLIER_OUTREACH_REQUEST,
  LOAD_ORGUNIT_CLIENT_FILES,
  UPDATE_FILE_HISTORY,
  RESEND_SUPPLIER_OUTREACH_REQUEST,
  RESEND_SUPPLIER_OUTREACH_REQUEST_FOR_ESG_SURVEY,
  CONTACT_SUPPLIER_FOR_OUTREACH_REQUEST
} from '../../actionTypes'
import mergeDeepOverwriteLists from '../../utils/mergeDeepOverwriteLists'
import { parseStagingRow } from 'supplier/Insight/components/LoadPage/ReviewSpendItems'
import { GET_OUTREACH_LIST } from 'buyer/Insights/store/actionTypes'

/**
 * {
 *   isLoading: {
 *     [entityName]: boolean
 *   },
 *   [entityName]: {
 *     [entityId]: {}
 *   }
 * }
 */
const dataReducer = (state = fromJS({}), action) => {
  switch (action.type) {
    case LOAD_DATA:
    case LOAD_CLIENT_FILES:
    case LOAD_ORGUNIT_CLIENT_FILES:
      return state.setIn(['isLoading', action.payload.entity], true)

    case requestSuccess(UPDATE_DATA):
    case requestSuccess(LOAD_DATA):
    case requestSuccess(LOAD_CLIENT_FILES):
    case requestSuccess(LOAD_ORGUNIT_CLIENT_FILES):
    case requestSuccess(GET_OUTREACH_LIST):
      return mergeDeepOverwriteLists(
        state.setIn(['isLoading', action.payload.entity], false),
        action.payload.data
      )

    case GET_OUTREACH_LIST:
      return state
        .setIn(['isLoading', action.payload.entity], true)
        .delete(action.payload.entity)

    case requestSuccess(DELETE_DATA):
      let pathArray = [action.payload.entity, action.payload.recordId]
      if (action.payload.fieldName) {
        pathArray = [...pathArray, ...action.payload.fieldName.split('.')]
      }
      return state.deleteIn(pathArray)

    case UPDATE_CUSTOMIZE_PITCH_DECK:
      return state.setIn(['isLoading', 'supplierRelationships'], true)

    case requestSuccess(UPDATE_CUSTOMIZE_PITCH_DECK):
    case requestSuccess(FOLLOW_CLIENT):
      return state
        .setIn(['isLoading', 'supplierRelationships'], false)
        .mergeDeepIn(
          ['supplierRelationships', action.payload.id],
          fromJS(action.payload)
        )

    case requestSuccess(REMOVE_CUSTOMER):
      return state
        .setIn(['isLoading', 'supplierRelationships'], false)
        .mergeDeepIn(
          ['supplierRelationships', action.payload.id],
          fromJS({
            supplierCollaboration: {
              isClient: false,
              requestVerify: false
            },
            buyerCollaboration: {
              verifyClient: 'unspecified'
            }
          })
        )

    case requestSuccess(LOAD_SUPPLIER_INSIGHT):
      return action.payload.orgUnits
        ? state.update('orgUnits', orgUnits =>
            mergeDeepOverwriteLists(orgUnits, fromJS(action.payload.orgUnits))
          )
        : state

    case REMOVE_SPEND:
    case PUBLISH_SPEND_T2:
    case UPLOAD_SPEND_FILE:
    case LOAD_STAGING_HISTORY:
      return state
        .setIn(['isLoading', 'stagingHistory'], true)
        .set('stagingHistory', fromJS([]))
    case requestFailure(UPLOAD_SPEND_FILE):
      return state.setIn(['isLoading', 'stagingHistory'], false)
    case requestSuccess(REMOVE_SPEND):
    case requestSuccess(PUBLISH_SPEND_T2):
    case requestSuccess(UPLOAD_SPEND_FILE):
    case requestSuccess(LOAD_STAGING_HISTORY):
      return state
        .setIn(['isLoading', 'stagingHistory'], false)
        .set('stagingHistory', fromJS(action.payload))

    case requestSuccess(REMAP_SUPPLIER):
      const stagingId = action.payload.stagingId
      const stagingRowId = action.payload.id
      const stagingRowIndex = state
        .getIn(['stagingRow', stagingId])
        .findIndex(stagingRow => stagingRow.get('id') === stagingRowId)

      return stagingRowIndex !== -1
        ? state.updateIn(
            ['stagingRow', stagingId, stagingRowIndex],
            stagingRow =>
              stagingRow.merge(fromJS(parseStagingRow(action.payload)))
          )
        : state

    case UPDATE_FILE_HISTORY:
      const uploadedFile = fromJS(action.payload.uploadedFile)
      return state.updateIn(['orgUnitClientFiles'], orgUnitClientFiles =>
        orgUnitClientFiles?.unshift(uploadedFile)
      )

    case CLOSE_SUPPLIER_OUTREACH_REQUEST:
      const taskId = action.payload.taskId
      const status = action.payload.status
      const currentUserId = action.payload.currentUserId
      return state.updateIn(['supplierOutreachTasks', taskId], task =>
        task
          .set('status', status)
          .set('archived', true)
          .update('statusChanges', statusChange =>
            statusChange.push(fromJS({ user: currentUserId }))
          )
      )
    case RESEND_SUPPLIER_OUTREACH_REQUEST:
    case CONTACT_SUPPLIER_FOR_OUTREACH_REQUEST:
      const taskId2 = action.payload.taskId
      const status2 = action.payload.status
      const currentUserId2 = action.payload.currentUserId
      const requestedContactId = action.payload.requestedContactId
      return state.updateIn(['supplierOutreachTasks', taskId2], task =>
        task
          .set('status', status2)
          .update('statusChanges', statusChange =>
            statusChange.push(fromJS({ user: currentUserId2 }))
          )
          .setIn(['form', 'requestedContactId'], requestedContactId)
      )
    case RESEND_SUPPLIER_OUTREACH_REQUEST_FOR_ESG_SURVEY:
      const taskId3 = action.payload.taskId
      const requestedContactId3 = action.payload.requestedContactId
      const createdDate = action.payload.requestedDate
      const currentUser = action.payload.currentUserId
      return state.updateIn(['supplierOutreachTasks', taskId3], task =>
        task
          .setIn(['created', 'date'], createdDate)
          .setIn(['created', 'user'], currentUser)
          .setIn(['form', 'requestedContactId'], requestedContactId3)
      )

    default:
      return state
  }
}

export default dataReducer
