import React, { memo, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Label from 'shared/components/Label'
import Select from 'shared/components/Select'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { Field } from 'redux-form/immutable'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip/Tooltip'
import GlobalMessagingSBE from '../CertificationForm/GlobalMessagingSBE'
import companySelectors from 'supplier/shared/selectors/companySelectors'
import Location from 'shared/models/Location'
import { Map, List, RecordOf } from 'immutable'
import RootState from 'shared/models/RootState'
import parseLocationToString from 'shared/utils/data/parseLocationToString'
import ClassificationCode from 'shared/models/ClassificationCode'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import ClassificationCodesContainer from 'shared/containers/ClassificationCodesContainer'
import NaicsSearchContainer from 'shared/containers/NaicsSearchContainer'
import Naics from 'shared/models/Naics'
import { naicsFootnote } from 'shared/models/NaicsStd'
import NaicsStd from 'shared/utils/getNaicsStd'
import Grade from '@material-ui/icons/Grade'
import IconButton from '@material-ui/core/IconButton'
import useThemeColors from 'shared/utils/useThemeColors'

const normalizeCode = (code: string): string => {
  if (Naics[code]) {
    return code
  } else if (
    code.length === 6 &&
    code.slice(-1) === '0' &&
    Naics[code.slice(0, -1)]
  ) {
    return code.slice(0, -1)
  } else if (code.length > 4) {
    let modifiedCode = code
    while (modifiedCode.length > 4) {
      modifiedCode = modifiedCode.slice(0, -1)
      if (Naics[modifiedCode]) {
        return modifiedCode
      }
    }
  }
  return ''
}

type Props = {
  locations: any //List<RecordOf<Location>> | undefined // available locations to choose from
  naics: List<string> | undefined // available naics code
  classificationCodesBySchema: Map<string, List<ClassificationCode>>
} & ContainerProps

export const SelfCertify = memo(
  ({
    subCategory,
    locations,
    naics,
    classificationCodesBySchema,
    qualifiedNaics,
    certifyingLocationKey,
    primaryNaics,
    change
  }: Props) => {
    const [openNaicsDialog, setOpenNaicsDialog] = useState<boolean>(false)
    const [footnote, setFootnote] = useState<string>('')

    const certifyingLocation = useMemo<RecordOf<Location>>(
      () =>
        certifyingLocationKey &&
        locations.find(
          location => location.get('location') === certifyingLocationKey
        ),
      [certifyingLocationKey, locations]
    )

    useEffect(() => {
      if ((!locations || locations.size === 0) && change) {
        change('certifyingLocationKey', '')
      }
    }, [locations, certifyingLocationKey, change])

    useEffect(() => {
      if ((!naics || naics.size === 0) && change) {
        change('primaryNaics', '')
      }
    }, [naics, change])

    const toggleNaicsDialog = () => {
      setOpenNaicsDialog(() => !openNaicsDialog)
    }

    const handleSetPrimaryNaics = code => {
      if (change) {
        change('primaryNaics', code)
      }
    }

    return (
      <>
        <div className='flex'>
          <Label>
            <FormattedMessage
              id='SelfCertify.CertifyingLocation'
              defaultMessage='Certifying Location'
            />
          </Label>
          <Tooltip
            title={
              <FormattedMessage
                id='SelfCertify.CertifyingLocationHelp'
                defaultMessage={`To self-certify, you need to select the primary business location from one of the locations listed on your company's TealBook profile. This location will be stored and included on the certificate.`}
              />
            }
          >
            <InfoIcon
              aria-label='location information icon'
              className='ml2 mt2'
            />
          </Tooltip>
        </div>

        {locations && locations.size > 0 ? (
          <Field
            name='certifyingLocationKey'
            component={Select}
            required
            fullWidth
          >
            <FormattedMessage
              id='SelfCertify.SelectLocation'
              defaultMessage='Please select a location'
            >
              {message => (
                <option value='' disabled>
                  {message}
                </option>
              )}
            </FormattedMessage>
            {locations.map((location, index) => (
              <option
                key={`${index}-${location.get('location')}`}
                value={location.get('location')}
              >
                {`${location.get('description')} - ${parseLocationToString(
                  location
                )}`}
              </option>
            ))}
          </Field>
        ) : (
          <div className='bg-white pa2'>
            <Text className='red'>
              <FormattedMessage
                id='SelfCertify.LocationWarning'
                defaultMessage='You need to add your primary business location before proceeding.'
              />
            </Text>
          </div>
        )}

        {subCategory === 'sbe' &&
          certifyingLocation &&
          certifyingLocation.getIn(['components', 'countryAbbrev']) !==
            'US' && <GlobalMessagingSBE />}
        {subCategory === 'sbe' &&
          certifyingLocation &&
          certifyingLocation.getIn(['components', 'countryAbbrev']) ===
            'US' && (
            <>
              <div className='flex items-center justify-between bb'>
                <div className='flex-auto'>
                  <Label>
                    <FormattedMessage
                      id='SelfCertify.NAICS'
                      defaultMessage='NAICS'
                    />
                  </Label>
                </div>
                <div className='w-30 flex justify-between'>
                  <div className='flex items-center'>
                    <Label>
                      <FormattedMessage
                        id='SelfCertify.Primary'
                        defaultMessage='Primary'
                      />
                    </Label>
                    <Tooltip
                      title={
                        <FormattedMessage
                          id='SelfCertify.PrimaryHelp'
                          defaultMessage='Identify which of the NAICS codes you would consider your primary business.'
                        />
                      }
                    >
                      <InfoIcon
                        aria-label='primary help icon'
                        className='ml1 mt2'
                      />
                    </Tooltip>
                  </div>
                  <div className='flex items-center'>
                    <Label>
                      <FormattedMessage
                        id='SelfCertify.Qualified'
                        defaultMessage='Qualified?'
                      />
                    </Label>
                    <Tooltip
                      title={
                        <FormattedMessage
                          id='SelfCertify.QualifiedHelp'
                          defaultMessage='By clicking in the check box next to a NAICS code you are confirming that your company meets the requirement shown to qualify as small.'
                        />
                      }
                    >
                      <InfoIcon
                        aria-label='qualified icon'
                        className='ml1 mt2'
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              {naics &&
                naics.size > 0 &&
                naics.map((code, index) => {
                  const rules = NaicsStd.getNaicsRules(code)
                  return (
                    <NaicsRule
                      key={`${index}-${code}`}
                      code={code}
                      employees={rules?.get('employees')}
                      revenue={rules?.get('revenue')}
                      footnote={rules?.get('footnote')}
                      primaryNaics={primaryNaics}
                      onNoteClick={setFootnote}
                      onPrimaryClick={handleSetPrimaryNaics}
                    />
                  )
                })}
              <div className='flex justify-between items-center mt2'>
                <Text className='red mr3 w-70 flex-auto'>
                  {!primaryNaics ? (
                    <FormattedMessage
                      id='SelfCertify.NaicsWarning1'
                      defaultMessage='You need to identify the NAICS code that describes your primary business.'
                    />
                  ) : !qualifiedNaics || !qualifiedNaics.get(primaryNaics) ? (
                    <FormattedMessage
                      id='SelfCertify.NaicsWarning2'
                      defaultMessage='Only select the checkbox next to the NAICS codes where your company meets the requirements to qualify as small.'
                    />
                  ) : null}
                </Text>
                <Button autoSize onClick={toggleNaicsDialog}>
                  <FormattedMessage
                    id='EditNAICS'
                    defaultMessage='Edit NAICS'
                  />
                </Button>
              </div>
            </>
          )}

        {subCategory === 'sbe' &&
          certifyingLocation &&
          certifyingLocation.getIn(['components', 'countryAbbrev']) ===
            'US' && (
            <Text className='mt3' role='comment'>
              <FormattedMessage
                id='SelfCertify.SBEMessage'
                defaultMessage={`<b>IMPORTANT NOTICE FOR US BUSINESSES:</b> Under 15 U.S.C. 645(d), any person who misrepresents firm’s status as a small business in order to obtain a subcontract, that is to be included as a part or all of a goal contained in subcontracting plan required pursuant to a section (8d) of Small Business Act, shall (1)be punished by imposition of a fine, imprisonment, or both; (2) be subject to administrative remedies, and (3) ineligible for participation in progress conducted under the authority of the act.`}
                values={{ b: b => <b>{b}</b> }}
              />
            </Text>
          )}
        <Dialog open={openNaicsDialog} fullWidth onClose={toggleNaicsDialog}>
          <DialogTitle onClose={toggleNaicsDialog}>
            <FormattedMessage
              id='SelfCertify.NaicsDialog'
              defaultMessage='Edit Company NAICS Code'
            />
          </DialogTitle>
          <DialogContent>
            {!!naics && (
              <ClassificationCodesContainer
                classificationCodesBySchema={classificationCodesBySchema}
                schemaOrder={['naics']}
                editable
              />
            )}
            <NaicsSearchContainer
              customButtonLabel={
                <FormattedMessage
                  id='SelfCertify.SearchToAAdd'
                  defaultMessage='Search for NAICS Codes to Add'
                />
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleNaicsDialog}>
              <FormattedMessage id='Close' defaultMessage='Close' />
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={!!footnote} fullWidth onClose={() => setFootnote('')}>
          <DialogTitle onClose={() => setFootnote('')}>
            <FormattedMessage
              id='SelfCertify.FootnoteDialog'
              defaultMessage='Note'
            />
          </DialogTitle>
          <DialogContent>
            <ol className='lh-copy f7 fw4 mid-gray ma0 pl3 pt3'>
              {!!naicsFootnote[footnote] &&
                naicsFootnote[footnote].map((note, index) => (
                  <li key={`note-${index}`}>
                    <Text className='mb2'>{note}</Text>
                  </li>
                ))}
            </ol>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFootnote('')}>
              <FormattedMessage id='Close' defaultMessage='Close' />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
)

type NaicsRuleProps = {
  code: string
  revenue: string
  employees: string
  footnote: string
  primaryNaics: string | undefined
  onPrimaryClick: (code: string) => void
  onNoteClick: (code: string) => void
}

const NaicsRule = memo((props: NaicsRuleProps) => {
  const {
    code,
    employees,
    revenue,
    footnote,
    primaryNaics,
    onPrimaryClick,
    onNoteClick
  } = props
  const colors = useThemeColors()
  const intl = useIntl()
  return (
    <div key={code}>
      <div className='flex justify-between bg-white pv2'>
        <div className='flex-auto w-70 ph2'>
          <Text>
            {code}&nbsp;
            {Naics[normalizeCode(code)] && (
              <FormattedMessage {...Naics[normalizeCode(code)]} />
            )}
          </Text>
          {!!employees && (
            <Text secondary>
              <FormattedMessage
                id='GetNaicsStd.EmployeesRules'
                defaultMessage='To qualify, your company must have fewer than {value} employees.'
                values={{ value: employees }}
              />
            </Text>
          )}
          {!!revenue && (
            <Text secondary>
              <FormattedMessage
                id='GetNaicsStd.RevenueRules'
                defaultMessage='To qualify, your annual revenue must be less than $ {value}M USD.'
                values={{ value: revenue }}
              />
            </Text>
          )}
          {!!footnote && (
            <Text secondary>
              <span
                className={`underline dim pointer ${colors.primaryText}`}
                onClick={() => onNoteClick(footnote)}
              >
                <FormattedMessage
                  id='SelfCertify.SeeNote'
                  defaultMessage='See note'
                />
              </span>
            </Text>
          )}
        </div>
        <div className='w-30 flex justify-between items-center'>
          <div className='w-50 tc'>
            {(!!employees || !!revenue) && (
              <IconButton
                aria-label='Set Primary'
                component='span'
                onClick={() => onPrimaryClick(code)}
              >
                <Grade
                  style={{
                    color: code === primaryNaics ? 'gold' : 'lightgray'
                  }}
                />
              </IconButton>
            )}
          </div>
          <div className='w-50 tc'>
            {(!!employees || !!revenue) && (
              <Field
                component='input'
                type='checkbox'
                name={`qualifiedNaics.${code}`}
                value={
                  Naics[normalizeCode(code)]
                    ? intl.formatMessage(Naics[normalizeCode(code)])
                    : code
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

type ContainerProps = {
  editMode: boolean
  disableCategories: boolean
  subCategory: string
  qualifiedNaics?: Map<string, boolean>
  certifyingLocationKey?: string
  primaryNaics?: string
  certifyingLocation?: RecordOf<Location>
  certNaics?: List<RecordOf<{ code: string; primary: boolean }>>
  change?: (field: String, value: any) => void
}

const SelfCertifyContainer = (props: ContainerProps) => {
  const { editMode, certifyingLocation, subCategory } = props
  const locations = useSelector((state: RootState) =>
    companySelectors.getUsableValidLocations(
      state,
      subCategory,
      (editMode && certifyingLocation?.get('location')) || ''
    )
  )

  const classificationCodesBySchema = useSelector((state: RootState) =>
    companySelectors.getClassificationCodesBySchema(state, 'naics')
  )
  const naics = useSelector(companySelectors.getNaicsLeaf)

  return (
    <SelfCertify
      locations={locations}
      naics={naics}
      classificationCodesBySchema={classificationCodesBySchema}
      {...props}
    />
  )
}
export default SelfCertifyContainer
