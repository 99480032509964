import { call, select } from 'redux-saga/effects'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import searchSelectors from 'buyer/Search/selectors/searchSelectors'
import filterOptionsSelectors from 'buyer/Search/selectors/filterOptionsSelectors'
import * as api from 'shared/utils/api/api'
import apiRoutes from 'shared/routes/apiRoutes'
import analytics from 'shared/utils/analytics'
import { getSearchTrackingInfo } from 'buyer/shared/utils/getSearchTrackingInfo'

export function* sendSearchFeedbackSaga(action) {
  const { rating, ratingDescription } = action.payload
  const queryString = yield select(routingSelectors.getSearch)
  const userId = yield select(sessionSelectors.getUserId)
  const searchId = yield select(searchSelectors.getSearchId)
  const query = yield select(searchSelectors.getQ)
  const resultsCount = yield select(searchSelectors.getResultsCount)
  const filters = yield select(filterOptionsSelectors.getAllFilterOptions)
  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const orgUnitName = yield select(orgsSelectors.getCurrentUserOrgUnitName)

  return yield call(
    (params) =>
      api
        .post(`${apiRoutes.authService}/feedback/userSearch`, params)
        .then(() =>
          analytics.track('Supplier Search Rating Submitted', {
            eventSource: 'Supplier Search',
            action: 'Rating Submitted',
            orgUnitId,
            orgUnitName,
            ...getSearchTrackingInfo(filters, query, resultsCount),
          })
        ),
    {
      rating,
      ratingDescription,
      userId,
      searchId,
      queryString: queryString?.substr(1) || 'q=',
    }
  )
}

export default createApiRequestSaga(sendSearchFeedbackSaga)
