import React, { FC } from 'react'
import CircleBadge from 'shared/components/CircleBadge'
import { injectIntl, defineMessages, IntlShape } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'
import Grid from '@material-ui/core/Grid'

const messages = defineMessages({
  followersRef: {
    id: 'ReferenceScores.FollowersRef',
    defaultMessage: 'Number of people following this company'
  },
  followersSupplierRef: {
    id: 'ReferenceScores.FollowersSupplierRef',
    defaultMessage: 'Number of people following your company'
  },
  followers: {
    id: 'ReferenceScores.Followers',
    defaultMessage: 'Followers'
  },
  contactsRef: {
    id: 'ReferenceScores.ContactsRef',
    defaultMessage:
      "Number of people who have directly connected to one of this supplier's contacts"
  },
  contactsSupplierRef: {
    id: 'ReferenceScores.ContactsSupplierRef',
    defaultMessage:
      'Number of people who have directly connected to one of your contacts'
  },
  contacts: {
    id: 'ReferenceScores.ConnectedContacts',
    defaultMessage: 'Contacts'
  },
  buyersRef: {
    id: 'ReferenceScores.BuyersRef',
    defaultMessage:
      'Number of purchasing organizations which have indicated they have a relationship to this company'
  },
  buyersSupplierRef: {
    id: 'ReferenceScores.BuyersSupplierRef',
    defaultMessage:
      'Number of purchasing organizations which have indicated they have a relationship to your company'
  },
  buyers: {
    id: 'ReferenceScores.ConnectedBuyers',
    defaultMessage: 'Buyers'
  },
  vetsRef: {
    id: 'ReferenceScores.VetsRef',
    defaultMessage: 'Number of opportunities this company has participated in'
  },
  vetsSupplierRef: {
    id: 'ReferenceScores.VetsSupplierRef',
    defaultMessage: 'Number of opportunities your company has participated in'
  },
  vets: {
    id: 'ReferenceScores.ParticipatedRFIs',
    defaultMessage: 'RFIs'
  },
  completenessRef: {
    id: 'ReferenceScores.CompletenessRef',
    defaultMessage: 'Percentage of profile completeness'
  },
  completenessSupplierRef: {
    id: 'ReferenceScores.CompletenessSupplierRef',
    defaultMessage: 'Percentage of profile completeness'
  },
  completeness: {
    id: 'ReferenceScores.Completeness',
    defaultMessage: 'Completeness'
  }
})

const scoreOrder = ['buyers', 'contacts', 'followers', 'vets', 'completeness']

const colors = {
  buyers: '#0E6CFF',
  contacts: '#D24416',
  followers: '#152C54',
  vets: '#168276',
  completeness: '#007DB3'
}

type Props = {
  followers: number
  contacts: number
  buyers: number
  vets: number
  completeness: number
  orgNum?: number
  isSupplier?: boolean
  intl: IntlShape
}

export const ReferenceScores: FC<Props> = props => {
  const { isSupplier, intl } = props

  const scoresWithValue = scoreOrder.filter(p => !!props[p])
  const oddNumberOfRef = scoresWithValue.length % 2 === 1

  return (
    <Grid container spacing={2}>
      {scoresWithValue.map((ref, index) => (
        <Grid
          key={`score-${ref}`}
          item
          xs={oddNumberOfRef && index === 0 ? 12 : 6}
        >
          <Tooltip
            title={
              messages[`${ref}${isSupplier ? 'Supplier' : ''}Ref`]
                ? intl.formatMessage(
                    messages[`${ref}${isSupplier ? 'Supplier' : ''}Ref`]
                  )
                : ref
            }
          >
            <CircleBadge
              className='w3 h3'
              style={{ backgroundColor: colors[ref] }}
              label={messages[ref] ? intl.formatMessage(messages[ref]) : ref}
            >
              {props[ref]}
            </CircleBadge>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  )
}

export default injectIntl(ReferenceScores)
