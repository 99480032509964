import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { saveCompanyInfo, deletePitchDeck } from '../../../actions'
import FileInput from 'shared/components/FileInput'
import Text from 'shared/components/Text'
import companySelectors from '../../../../shared/selectors/companySelectors'
import mobileContactsSelector from 'accounts/selectors/mobileContactsSelector'
import PitchDeck from 'shared/components/PitchDeck'
import MaterialButton from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from 'shared/components/DialogActions'
import DialogTitle from 'shared/components/DialogTitle'
import Button from 'shared/components/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import { downloadAttachmentToURL } from 'shared/utils/fileDownload'
import downloadAttachment from 'shared/utils/fileDownload/fileDownload'

type Props = {
  pitchDeckFileName: string
  onDeletePitchDeck: () => void
  mobile: boolean
  saveCompanyInfo: (payload: { pitchDeckFile: null | File }) => void
}

export const PitchDeckEditForm = (props: Props) => {
  const [ newPitchDeckFile, setNewPitchDeckFile ] = useState<null | File>(null)
  const [ openDeleteDialog, setOpenDeleteDialog ] = useState<boolean>(false)
  const [ pitchDeckUrl, setPitchDeckUrl ] = useState<string>('')

  const { mobile, pitchDeckFileName, saveCompanyInfo, onDeletePitchDeck } = props

  useEffect(() => {
    if (pitchDeckFileName) {
      downloadAttachmentToURL(
        pitchDeckFileName,
        'supplier/pitchDeckFile/signedUrl'
      ).then(objectURL =>
        setPitchDeckUrl(objectURL)
      )
    }
  }, [pitchDeckFileName])

  const handleChangePitchDeck = newPitchDeckFile => {
    setNewPitchDeckFile(newPitchDeckFile)
  }

  const handleSavePitchDeck = () => {
    saveCompanyInfo({
      pitchDeckFile: newPitchDeckFile
    })

    handleCancelSavingPitchDeck()
  }

  const handleCancelSavingPitchDeck = () => {
    setNewPitchDeckFile(null)
  }

  const toggleDeleteDialog = () => {
    setOpenDeleteDialog(open => !open)
  }

  const handleDeletePitchDeck = () => {
    setOpenDeleteDialog(false)
    onDeletePitchDeck()
  }

  return (
    <div className='pt3-5 flex flex-column'>
      {pitchDeckFileName ? (
        <PitchDeck
          url={pitchDeckUrl}
          mobile={mobile}
          fileName={pitchDeckFileName}
          onDownload={() =>
            downloadAttachment(pitchDeckFileName, 'supplier/pitchDeckFile')
          }
        />
      ) : (
        <Text className='teal'>
          <FormattedMessage
            id='PitchDeckEditForm.ShowProspectiveClientsText'
            defaultMessage='Show prospective clients what your company can do for
              them. A pitch deck is fastest way to show what you can do.'
          />
        </Text>
      )}

      {!pitchDeckFileName && (
        <>
          <Label className='db mt3 tc'>
            <FormattedMessage
              id='PitchDeckEditForm.AddPitchDeck'
              defaultMessage='Add Pitch Deck'
            />
          </Label>

          <FileInput
            name='pitchDeckFile'
            accept='.pdf,.pptx,.ppt'
            onChange={handleChangePitchDeck}
            value=''
          />
        </>
      )}

      {!!pitchDeckFileName && (
        <div className='mt3-ns'>
          <MaterialButton
            variant='contained'
            onClick={toggleDeleteDialog}
          >
            <DeleteIcon />
            <span className='ml2'>
              <FormattedMessage
                id='PitchDeckEditForm.RemovePitchDeck'
                defaultMessage='Remove Pitch Deck'
              />
            </span>
          </MaterialButton>
        </div>
      )}

      <Dialog
        open={!!newPitchDeckFile}
        onClose={handleCancelSavingPitchDeck}
      >
        <DialogContent>
          <Text className='mt3'>
            <FormattedMessage
              id='PitchDeckEditForm.SaveTheNewPitch'
              defaultMessage='Save the new pitch deck file?'
            />
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            secondary
            label={<FormattedMessage id='CancelButton' />}
            onClick={handleCancelSavingPitchDeck}
          />
          <Button
            label={
              <FormattedMessage
                id='PitchDeckEditForm.SaveButton'
                defaultMessage='Save'
              />
            }
            onClick={handleSavePitchDeck}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={toggleDeleteDialog}>
        <DialogTitle>
          <FormattedMessage
            id='PitchDeckEditForm.DeletingUser'
            defaultMessage='Remove this Pitch Deck File?'
          />
        </DialogTitle>
        <DialogContent>
          <Text className='mt3'>
            <FormattedMessage
              id='PitchDeckEditForm.cantUndo'
              defaultMessage={"You won't be able to undo this."}
            />
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            label={
              <FormattedMessage
                id='PitchDeckEditForm.Delete'
                defaultMessage='Delete'
              />
            }
            autoSize
            onClick={handleDeletePitchDeck}
            className='bg-red'
          />
          <Button
            label={<FormattedMessage id='CancelButton' />}
            autoSize
            secondary
            onClick={toggleDeleteDialog}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default connect(
  state => ({
    mobile: !!mobileContactsSelector.get(state),
    pitchDeckFileName: companySelectors
      .getCompanySupplierField(state, 'pitchDeckFile')
      ?.get('fileName')
  }),
  {
    saveCompanyInfo,
    onDeletePitchDeck: deletePitchDeck
  }
)(PitchDeckEditForm)
