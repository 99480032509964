import { connect } from 'react-redux'
import PersonalPage from '../../components/PersonalPage'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import profileSelectors from '../../selectors/profileSelectors'
import editContactSelectors from '../../selectors/editContactSelectors'
import {
  removeContact,
  editContact,
  updateContact,
  cancelEditContact,
  verifySupplier
} from '../../actions'
import RootState from 'shared/models/RootState'

type ContainerProps = {
  match: { params: { supplierId: string } }
}
export default connect(
  (state: RootState, props: ContainerProps) => {
    const card = cardsSelectors.getBySupplier(
      state,
      props.match.params.supplierId
    )
    const contacts = card.get('contacts')
    const supplierId = profileSelectors.getDataField(state, 'id')
    const offerings = card.get('offerings')
    const differentiators = card.get('differentiators')
    const relationship = profileSelectors.getRelationship(state)
    const attachments = relationship && relationship.get('attachments')

    return {
      hasPersonalEndorsements:
        (offerings && offerings.size > 0) ||
        (differentiators && differentiators.size > 0),
      hasAttachments: !!(attachments && attachments.size > 0),
      contacts,
      supplierId,
      isEditingContact: editContactSelectors.isEditing(state),
      contactToEdit: editContactSelectors.getContact(state),
      supplierName: profileSelectors.getDataField(state, 'name')
    }
  },
  {
    onRemoveContact: removeContact,
    onEditContact: editContact,
    onCancelEdit: cancelEditContact,
    onUpdateContact: updateContact,
    onVerifySupplier: verifySupplier
  }
)(PersonalPage)
