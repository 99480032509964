import { stringToObject } from 'shared/utils/parseSuppliersSearch/parseSuppliersSearch'

const safeSpread = (y: any[] | undefined) => (y !== undefined ? y : [])

export const getSearchTrackingInfo = (
  searchFilters: any,
  q: string,
  resultsCount: number
) => {
  const jsFilters = searchFilters.toJS()

  const { includes, ...rest } = stringToObject(q)

  const advancedSearchOptions = {
    searchStringExcludes: rest?.excludes,
    nameContainsPhrase: rest?.includesName,
    nameExcludesPhrase: rest?.excludesName,
    descriptionContainsPhrase: rest?.includesDescription,
    descriptionExcludesPhrase: rest?.excludesDescription,
    offeringContainsPhrase: rest?.includesOfferings,
    offeringExcludesPhrase: rest?.excludesOfferings
  }

  const similarSupplierSearchTargetIds = jsFilters.similarSuppliers

  const isAdvancedSearch = Object.values(advancedSearchOptions).some(
    option => option !== undefined
  )

  const totalAdvanceOptions = Object.values(rest).reduce(
    (total, options) => (total += options.length),
    0
  )

  const mainFilters = {
    attachmentFilter: jsFilters.attachments,
    businessUnitFilter: jsFilters.spendGroups,
    communityFilter: jsFilters.communities,
    corporateRelationshipsFilter: jsFilters.attributes,
    emailFilter: jsFilters.emailAvailable,
    followingFilter: jsFilters.scope !== 'all' ? jsFilters.scope : undefined,
    internalCategoryFilter: jsFilters.internalCategory,
    locationFilter: [
      ...safeSpread(jsFilters.country),
      ...safeSpread(jsFilters.state),
      ...safeSpread(jsFilters.city)
    ],
    naicsCode: jsFilters.sector,
    qualification: [
      ...safeSpread(jsFilters.diversity),
      ...safeSpread(jsFilters.sustainability),
      ...safeSpread(jsFilters.quality),
      ...safeSpread(jsFilters.food),
      ...safeSpread(jsFilters.security)
    ],
    ratingFilter: jsFilters.relationshipRating,
    supplierUpdatedFilter: jsFilters.supplierUpdated,
    sustainabilitySurveyFilter: jsFilters.sustainabilitySurveyCompleted,
    warningFilter: jsFilters.warnings
  }

  const subFilters = {
    state: jsFilters.state,
    diversity: jsFilters.diversity,
    sustainability: jsFilters.sustainability,
    quality: jsFilters.quality,
    food: jsFilters.food,
    security: jsFilters.security
  }

  const totalMainFilters = [
    mainFilters.attachmentFilter,
    mainFilters.businessUnitFilter,
    mainFilters.communityFilter,
    mainFilters.corporateRelationshipsFilter?.length > 0,
    mainFilters.emailFilter,
    mainFilters.followingFilter,
    mainFilters.internalCategoryFilter,
    mainFilters.locationFilter?.length > 0,
    mainFilters.naicsCode,
    mainFilters.qualification?.length > 0,
    mainFilters.ratingFilter,
    mainFilters.supplierUpdatedFilter,
    mainFilters.sustainabilitySurveyFilter,
    mainFilters.warningFilter
  ].filter(filter => !!filter).length

  const totalSubFilters = [
    jsFilters.state,
    jsFilters.diversity && jsFilters.diversity.length > 1,
    jsFilters.sustainability && jsFilters.sustainability.length > 1,
    jsFilters.quality && jsFilters.quality.length > 1,
    jsFilters.food && jsFilters.food.length > 1,
    jsFilters.security && jsFilters.security.length > 1
  ].filter(filter => !!filter).length

  return {
    advancedSearchOptions,
    isAdvancedSearch,
    mainFilters,
    searchStringEntered: includes,
    similarSupplierSearchTargetIds,
    subFilters,
    totalAdvanceOptions,
    totalMainFilters,
    totalSubFilters,
    totalSearchResultsReturned: resultsCount
  }
}
