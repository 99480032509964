import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { SAVE_COMPANY_INFO } from '../../../actionTypes'
import { Field } from 'redux-form/immutable'
import companySelectors from '../../../../shared/selectors/companySelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import createEditForm from 'shared/utils/createEditForm'
import Label from 'shared/components/Label'
import TextArea from 'shared/components/TextArea'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import Select from 'shared/components/Select'
import { supportedLanguages } from '../../../../../i18n'
import RootState from 'shared/models/RootState'
import { Map } from 'immutable'

const placeholder = 'Tell us about what your company has to offer.'
const formName = 'supplier/company/description'
const EditForm = createEditForm({
  formName,
  submitActionType: SAVE_COMPANY_INFO,
})

type Props = {
  longDescription: Map<string, string>
  language: string
  isFrozen: boolean
  isTealbot: boolean
}

export const CompanyDescriptionEditForm: FC<Props> = ({
  longDescription,
  language,
  isFrozen,
  isTealbot,
}) => {
  const [currentDescriptionLanguage, setCurrentDescriptionLanguage] =
    useState<string>(language)

  return (
    <EditForm
      placeholder={placeholder}
      showReadModeContent={!!longDescription}
      readModeContent={
        <Text>
          {longDescription.get(language) || longDescription.get('en')}
        </Text>
      }
      initialValues={{
        longDescription,
      }}
      editButtonTitle='Edit company description'
      canEditSection={!isFrozen || isTealbot}
    >
      <div className='flex justify-between items-center mt3 mb1'>
        <Label htmlFor='longDescription' className='db f7 fw6'>
          <FormattedMessage
            id='CompanyDescriptionEditForm.CompanyDescriptionLabel'
            defaultMessage='Company Description'
          />
        </Label>
        <label htmlFor='selectlanguage' className='visuallyhidden'>
          <FormattedMessage
            id='CompanyDescriptionEditForm.SelectLanguageForDescription'
            defaultMessage={'Language for description:'}
          />
        </label>
        <Select
          onChange={(e) => setCurrentDescriptionLanguage(e.currentTarget.value)}
          value={currentDescriptionLanguage}
          name='selectlanguage'
        >
          {supportedLanguages.map(({ key, title }) => (
            <option key={key} value={key}>
              {title}
            </option>
          ))}
        </Select>
      </div>
      <Field
        name={`longDescription.${currentDescriptionLanguage}`}
        maxLength='2000'
        component={TextArea}
        type='text'
        placeholder={placeholder}
        height={150}
      />
    </EditForm>
  )
}

export default connect((state: RootState) => ({
  longDescription: companySelectors.getLongDescription(state),
  language: state.get('i18n').locale,
  isFrozen: state.getIn(['supplier', 'company', 'data', 'isFrozen']),
  isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
}))(CompanyDescriptionEditForm)
