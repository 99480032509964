import React from 'react'
import ParentQuestion from '../ParentQuestion'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import AttachmentQuestion from '../AttachmentQuestion'
import OptionsQuestion from '../OptionsQuestion'
import TextQuestion from '../TextQuestion'
import Text from 'shared/components/Text'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import DropdownQuestion from '../DropdownQuestion'

const messages = defineMessages({
  placeholderAdditionalInfo: {
    id: 'placeholderAdditionalInfo',
    defaultMessage: 'Please provide any additional details.'
  }
})

const pageId = 'governance'

type Props = {
  showMissing?: boolean
}

const Governance = ({ showMissing = false }: Props) => {
  const intl = useIntl()
  return (
    <div>
      <ScrollToPosition />
      <Text className='fw6'>
        <FormattedMessage
          id='Governance.policies'
          defaultMessage='Q1. Does your company have any of the following policies in place?'
        />
      </Text>
      <div className='ml3'>
        <ParentQuestion
          pageId={pageId}
          parentQuestionId='polices'
          questionId='codeOfConduct'
          question={
            <FormattedMessage
              id='Governance.codeOfConduct'
              defaultMessage='Code of Conduct or similar compliance and ethics policy'
            />
          }
          warning={showMissing}
        >
          <AttachmentQuestion
            pageId={pageId}
            parentQuestionId='polices'
            questionId='codeOfConduct'
            type='doc'
          />
        </ParentQuestion>
        <ParentQuestion
          pageId={pageId}
          parentQuestionId='polices'
          questionId='antiCorruption'
          question={
            <FormattedMessage
              id='Governance.antiCorruption'
              defaultMessage='Anti-Corruption (Anti-Bribery) Policy'
            />
          }
          warning={showMissing}
        >
          <AttachmentQuestion
            pageId={pageId}
            parentQuestionId='polices'
            questionId='antiCorruption'
            type='doc'
          />
        </ParentQuestion>
        <ParentQuestion
          pageId={pageId}
          parentQuestionId='polices'
          questionId='childForceLabour'
          question={
            <FormattedMessage
              id='Governance.childForceLabour'
              defaultMessage='Child and Forced Labour Policy'
            />
          }
          warning={showMissing}
        >
          <AttachmentQuestion
            pageId={pageId}
            parentQuestionId='polices'
            questionId='childForceLabour'
            type='doc'
          />
        </ParentQuestion>
        <ParentQuestion
          pageId={pageId}
          parentQuestionId='polices'
          questionId='whistleblower'
          question={
            <FormattedMessage
              id='Governance.whistleblower'
              defaultMessage='Whistleblower Policy'
            />
          }
          warning={showMissing}
        >
          <AttachmentQuestion
            pageId={pageId}
            parentQuestionId='polices'
            questionId='whistleblower'
            type='doc'
          />
        </ParentQuestion>
      </div>
      <ParentQuestion
        pageId={pageId}
        questionId='complianceOfficer'
        question={
          <FormattedMessage
            id='Governance.complianceOfficer'
            defaultMessage='Q2. Does your company have a designated Compliance Officer or similar position?'
          />
        }
        warning={showMissing}
      >
        <div className='pt3'>
          <DropdownQuestion
            className='mb3'
            pageId={pageId}
            parentQuestionId='complianceOfficer'
            questionId='jobTitle'
            question={
              <FormattedMessage
                id='Governance.jobTitle'
                defaultMessage='What is their title?'
              />
            }
          />
          <DropdownQuestion
            pageId={pageId}
            parentQuestionId='complianceOfficer'
            questionId='jobTitleReportTo'
            question={
              <FormattedMessage
                id='Governance.jobTitleReportTo'
                defaultMessage='To whom (what position title) do they report?'
              />
            }
          />
        </div>
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='activeEngagementProgram'
        question={
          <FormattedMessage
            id='Governance.activeEngagementProgram'
            defaultMessage='Q3. Do you have an active engagement program that you use to determine the interests of your key stakeholders? If yes, please attach relevant documentation.'
          />
        }
        warning={showMissing}
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='activeEngagementProgram'
          type='doc'
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='requireAdhereCodeOfConduct'
        question={
          <FormattedMessage
            id='Governance.requireAdhereCodeOfConduct'
            defaultMessage='Q4. Do you require your contractors and suppliers to adhere to a "Code of Conduct"?'
          />
        }
        warning={showMissing}
      >
        <AttachmentQuestion
          type='doc'
          pageId={pageId}
          questionId='requireAdhereCodeOfConduct'
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='annualEsgReport'
        question={
          <FormattedMessage
            id='Governance.annualEsgReport'
            defaultMessage='Q5. Does your organization publish an annual ESG, sustainability or corporate social responsibility report? If yes, please attach the most recent report.'
          />
        }
        warning={showMissing}
      >
        <AttachmentQuestion
          pageId={pageId}
          questionId='annualEsgReport'
          type='doc'
        />
      </ParentQuestion>
      <ParentQuestion
        pageId={pageId}
        questionId='useSustainabilityReportingFramework'
        question={
          <FormattedMessage
            id='Governance.sustainabilityReportingFramework'
            defaultMessage='Q6. Do you currently use sustainability reporting framework(s)? If yes, please specify.'
          />
        }
        warning={showMissing}
      >
        <OptionsQuestion
          pageId={pageId}
          parentQuestionId='useSustainabilityReportingFramework'
          questionId='frameworks'
          options={[
            'GRI',
            'SASB',
            "SBTi's Corporate Net Zero Standard",
            'SDGs',
            'TCFD',
            'UN PRI'
          ]}
        />
      </ParentQuestion>
      <TextQuestion
        pageId={pageId}
        questionId='note'
        fieldType='textarea'
        question={
          <FormattedMessage
            id='optionalAdditionalNote'
            defaultMessage='Additional information (optional)'
          />
        }
        placeholder={intl.formatMessage(messages.placeholderAdditionalInfo)}
      />
    </div>
  )
}

export default Governance
