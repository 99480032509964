import React, { Component } from 'react'
import { Field } from 'redux-form/immutable'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Button from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'

type Props = {
  isPrimary?: boolean
  onCancel: () => void
  handleSubmit: () => void
  intl: IntlShape
}

class CompanyLocationEditForm extends Component<Props> {
  render() {
    const { isPrimary, onCancel, handleSubmit, intl } = this.props

    return (
      <form onSubmit={handleSubmit} className='bg-near-white pa2'>
        <Field
          component={Input}
          name='description'
          placeholder={intl.formatMessage({
            id: 'CompanyLocationEditForm.DescriptionofThisAddressPlaceholder',
            defaultMessage:
              'Description of this address (e.g. Toronto, Headquarter)'
          })}
          className='mb2'
          required
        />
        <Field
          component={TextArea}
          name='address'
          placeholder={intl.formatMessage({
            id: 'CompanyLocationEditForm.PhysicalAddressPlaceholder',
            defaultMessage: 'Physical address (e.g. 30 Camden St., Toronto, ON)'
          })}
          required
        />
        <Field
          component={Input}
          name='phone'
          placeholder={intl.formatMessage({
            id: 'CompanyLocationEditForm.PhoneNumberPlaceholder',
            defaultMessage: 'Phone Number'
          })}
          className='mt2'
        />

        <div className='flex mt2'>
          <div className='flex-auto'>
            <Field
              name='isPrimary'
              component={Checkbox}
              label={
                <FormattedMessage
                  id='CompanyLocationEditForm.PrimaryAddressLabel'
                  defaultMessage='Primary Address'
                />
              }
              checked={isPrimary || undefined}
            />
          </div>

          <Button
            label={<FormattedMessage id='CancelButton' />}
            secondary
            autoSize
            onClick={onCancel}
            className='mr3'
          />
          <Button
            label={
              <FormattedMessage
                id='CompanyLocationEditForm.SaveButton'
                defaultMessage='Save'
              />
            }
            type='submit'
            autoSize
          />
        </div>
      </form>
    )
  }
}

export default injectIntl(CompanyLocationEditForm)
