import { List, RecordOf } from 'immutable'
import { ModifiedUploadHistoryType } from './UploadSpendDetail'

const notNullOrEmpty = <T>(value: List<T>) => value && value.size > 0
type DataAndSpendOptions = [string[], string[]]

export const getOptions = (
  activeStagings: List<RecordOf<ModifiedUploadHistoryType>>,
  fullSpendFileStagings: List<RecordOf<ModifiedUploadHistoryType>>,
  buyer: string,
  dataType: string
) => {
  const activeAggregateStagings = activeStagings?.filter(
    staging => staging.get('dataType') === 'aggregate'
  )

  const aggregateIndirectStagings = activeAggregateStagings?.filter(
    staging => staging.get('spendType') === 'indirect'
  )

  const directAggregateStagings = activeAggregateStagings?.filter(
    staging => staging.get('spendType') === 'direct'
  )

  const buyerHasIndirectAggregate = aggregateIndirectStagings?.some(
    staging =>
      staging.get('buyers').includes(buyer) || staging.get('buyers').size === 0
  )

  const buyerHasFullSpend = fullSpendFileStagings?.some(
    staging =>
      staging.get('buyers').includes(buyer) || staging.get('buyers').size === 0
  )

  const quarterHasDirectSpend = activeStagings?.some(
    staging =>
      staging.get('dataType') === 'file' &&
      staging.get('spendType') === 'direct'
  )

  const buyerHasDirectAggregate = directAggregateStagings?.some(staging =>
    staging.get('buyers').includes(buyer)
  )

  const quarterHasFullSpendPublished = fullSpendFileStagings?.some(
    staging => staging.get('status') === 'published'
  )

  const quarterHasDirectAggregate = notNullOrEmpty(directAggregateStagings)
  const quarterHasFullSpend = notNullOrEmpty(fullSpendFileStagings)
  const quarterHasIndirectAggregate = notNullOrEmpty(aggregateIndirectStagings)

  const dataTypeOptions = getDataTypeOptions(
    buyerHasDirectAggregate,
    buyerHasFullSpend,
    buyerHasIndirectAggregate,
    quarterHasDirectAggregate,
    quarterHasDirectSpend,
    quarterHasFullSpend,
    quarterHasFullSpendPublished,
    quarterHasIndirectAggregate
  )

  const spendTypeOptions = getSpendTypeOptions(
    buyerHasDirectAggregate,
    buyerHasFullSpend,
    buyerHasIndirectAggregate,
    dataType,
    quarterHasDirectAggregate,
    quarterHasDirectSpend,
    quarterHasFullSpend,
    quarterHasFullSpendPublished,
    quarterHasIndirectAggregate
  )

  return [dataTypeOptions, spendTypeOptions] as DataAndSpendOptions
}

export function getDataTypeOptions(
  buyerHasDirectAggregate: boolean,
  buyerHasFullSpend: boolean,
  buyerHasIndirectAggregate: boolean,
  quarterHasDirectAggregate: boolean,
  quarterHasDirectSpend: boolean,
  quarterHasFullSpend: boolean,
  quarterHasFullSpendPublished: boolean,
  quarterHasIndirectAggregate: boolean
): string[] {
  const defaultOptions = ['file', 'aggregate']
  let customOptions

  if (quarterHasIndirectAggregate && buyerHasIndirectAggregate) {
    customOptions = ['file']
  } else if (quarterHasIndirectAggregate && !buyerHasIndirectAggregate) {
    customOptions = ['file', 'aggregate']
  } else if (
    !quarterHasIndirectAggregate &&
    quarterHasFullSpend &&
    buyerHasFullSpend
  ) {
    customOptions = ['file']
  } else if (
    !quarterHasIndirectAggregate &&
    quarterHasFullSpend &&
    !buyerHasFullSpend &&
    quarterHasFullSpendPublished
  ) {
    customOptions = ['file']
  } else if (
    !quarterHasIndirectAggregate &&
    quarterHasFullSpend &&
    !buyerHasFullSpend &&
    !quarterHasFullSpendPublished
  ) {
    customOptions = ['file']
  } else if (
    !quarterHasIndirectAggregate &&
    !quarterHasFullSpend &&
    quarterHasDirectSpend
  ) {
    customOptions = ['file', 'aggregate']
  } else if (
    !quarterHasIndirectAggregate &&
    !quarterHasFullSpend &&
    !quarterHasDirectSpend &&
    !quarterHasDirectAggregate
  ) {
    customOptions = ['file', 'aggregate']
  } else if (
    !quarterHasIndirectAggregate &&
    !quarterHasFullSpend &&
    !quarterHasDirectSpend &&
    quarterHasDirectAggregate &&
    buyerHasDirectAggregate
  ) {
    customOptions = []
  } else if (
    !quarterHasIndirectAggregate &&
    !quarterHasFullSpend &&
    !quarterHasDirectSpend &&
    quarterHasDirectAggregate &&
    !buyerHasDirectAggregate
  ) {
    customOptions = ['aggregate']
  }

  return customOptions || defaultOptions
}

export const getSpendTypeOptions = (
  buyerHasDirectAggregate: boolean,
  buyerHasFullSpend: boolean,
  buyerHasIndirectAggregate: boolean,
  dataType: string,
  quarterHasDirectAggregate: boolean,
  quarterHasDirectSpend: boolean,
  quarterHasFullSpend: boolean,
  quarterHasFullSpendPublished: boolean,
  quarterHasIndirectAggregate: boolean
): string[] => {
  let defaultOptions = ['fullSpend', 'direct', 'indirect']
  let customOptions

  if (quarterHasIndirectAggregate && buyerHasIndirectAggregate) {
    customOptions = ['direct']
  } else if (quarterHasIndirectAggregate && !buyerHasIndirectAggregate) {
    customOptions = dataType === 'file' ? ['direct'] : ['indirect']
  } else if (
    !quarterHasIndirectAggregate &&
    quarterHasFullSpend &&
    buyerHasFullSpend
  ) {
    customOptions = ['fullSpend']
  } else if (
    !quarterHasIndirectAggregate &&
    quarterHasFullSpend &&
    !buyerHasFullSpend &&
    quarterHasFullSpendPublished
  ) {
    customOptions = ['fullSpend']
  } else if (
    !quarterHasIndirectAggregate &&
    quarterHasFullSpend &&
    !buyerHasFullSpend &&
    !quarterHasFullSpendPublished
  ) {
    customOptions = ['fullSpend']
  } else if (
    !quarterHasIndirectAggregate &&
    !quarterHasFullSpend &&
    quarterHasDirectSpend
  ) {
    customOptions = dataType === 'file' ? ['direct'] : ['indirect']
  } else if (
    !quarterHasIndirectAggregate &&
    !quarterHasFullSpend &&
    !quarterHasDirectSpend &&
    !quarterHasDirectAggregate
  ) {
    customOptions = defaultOptions
  } else if (
    !quarterHasIndirectAggregate &&
    !quarterHasFullSpend &&
    !quarterHasDirectSpend &&
    quarterHasDirectAggregate &&
    buyerHasDirectAggregate
  ) {
    customOptions = []
  } else if (
    !quarterHasIndirectAggregate &&
    !quarterHasFullSpend &&
    !quarterHasDirectSpend &&
    quarterHasDirectAggregate &&
    !buyerHasDirectAggregate
  ) {
    customOptions = ['direct']
  }

  let options = customOptions || defaultOptions
  // clean up impossible combination
  if (dataType === 'file') {
    options = options.filter(o => o !== 'indirect')
  } else if (dataType === 'aggregate') {
    options = options.filter(o => o !== 'fullSpend')
  }
  return options
}
