import React, { ChangeEvent, ReactNode, useMemo } from 'react'
import Input from 'shared/components/Input'
import numberFormat from 'shared/utils/numberFormat'
import { useDispatch, useSelector } from 'react-redux'
import { updateAllocation } from '../../store/actions'
import {
  getStagingAllocationBySupplierId,
  getAllocatedSuppliersByStagingId,
  getOverallAllocationByStagingId
} from '../../store/tier2Selectors'
import RootState from 'shared/models/RootState'

type Props = {
  allocation?: number
  greyedOut?: boolean
  inputAriaLabel?: string
  onChangeAllocation: (newValue?: number) => void
  readOnly?: boolean
  required?: boolean
  spendAmount: number
  title: ReactNode
  supplierOrgUnitId?: string
  buyerOrgUnitId?: string
  stagingId?: string
  showOverAllocate?: boolean
}

const SpendAllocationItem = ({
  allocation,
  greyedOut,
  inputAriaLabel,
  onChangeAllocation,
  readOnly,
  required,
  spendAmount,
  title,
  supplierOrgUnitId = '',
  buyerOrgUnitId,
  stagingId = '',
  showOverAllocate
}: Props) => {
  const dispatch = useDispatch()

  const totalAllocation = useSelector((state: RootState) =>
    getStagingAllocationBySupplierId(state, stagingId, supplierOrgUnitId)
  )

  // which suppliers already have shared allocation with any buyers
  const allocatedSuppliers = useSelector((state: RootState) =>
    getAllocatedSuppliersByStagingId(state, stagingId)
  )

  const overallAllocations = useSelector((state: RootState) =>
    getOverallAllocationByStagingId(state, stagingId)
  )

  // if overall (indirect) allocation has shared, but no direct allocation from other buyers,
  // then direct allocation has to be less than leftover indirect allocation.
  const maxAllocation = useMemo(() => {
    if (
      allocatedSuppliers?.filter(s => s !== 'overallAllocation').length === 0 &&
      overallAllocations?.size > 0
    ) {
      const tempOverall = overallAllocations.toJS()
      return (
        100 -
        Object.keys(tempOverall).reduce((total, buyerId) => {
          if (buyerId !== buyerOrgUnitId) {
            return total + tempOverall[buyerId][0]
          } else {
            return total
          }
        }, 0)
      )
    } else {
      return undefined
    }
  }, [allocatedSuppliers, overallAllocations, buyerOrgUnitId])

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const percentage = e.target.value
    let allocate = 0
    if (percentage.length > 0) {
      allocate =
        Number(percentage) > 100
          ? 100
          : Number(percentage) < 0
          ? 0
          : Number(percentage)
      onChangeAllocation(allocate)
    } else {
      onChangeAllocation()
    }
    if (stagingId && supplierOrgUnitId && buyerOrgUnitId) {
      dispatch(
        updateAllocation({
          stagingId,
          supplierOrgUnitId,
          buyerOrgUnitId,
          allocation: allocate
        })
      )
    }
  }

  const tachyonClassNames = `flex items-center mt2 f7 ${greyedOut &&
    'black-30'}`

  return (
    <div className={tachyonClassNames}>
      <div className='w-40'>{title}</div>
      <div className='w-30 tr flex items-center justify-end'>
        <span>${numberFormat(spendAmount, 2)}</span>
        <span className='ml3'>&times;</span>
        <Input
          value={allocation !== undefined ? allocation : ''}
          onChange={handleOnChange}
          wrapperClassName='dib ml3 mr2 w-40'
          className='f7'
          type='number'
          min={0}
          max={100}
          step={0.0001}
          maxLength={3}
          required={required}
          readOnly={readOnly || greyedOut}
          ariaLabel={inputAriaLabel}
          error={
            maxAllocation !== undefined
              ? (allocation || 0) > maxAllocation
              : !!showOverAllocate && totalAllocation > 100
          }
        />
        <span>&#37;</span>
      </div>
      <span className='flex-auto tr'>
        {allocation
          ? `$${numberFormat(spendAmount * (allocation / 100), 2)}`
          : ''}
      </span>
    </div>
  )
}

export default SpendAllocationItem
