import React, { useEffect, useState } from 'react'
import auth from 'shared/utils/api/auth'
import { FormattedMessage } from 'react-intl'
import ParklandLogo from './Parkland_Logo.png'
import fileToUrl from 'shared/utils/data/fileToUrl'

type Props = {
  invitedBy?: string
}

type LookupOrgUnit = {
  alternativeNames: string[]
  id: string
  name: string
  supplier: {
    logo?: {
      bucketName: string
      description: string
      fileName: string
    }
  }
}

const parklandDomain = 'parkland.ca'

const CustomInstructionsLogo = (props: Props) => {
  const { invitedBy } = props
  const [orgUnit, setOrgUnit] = useState<LookupOrgUnit | undefined>(undefined)
  const [cleanDomain, setCleanDomain] = useState<string>('')

  useEffect(() => {
    if (invitedBy) {
      auth.checkOrgUnitByEmail(invitedBy).then(({ names, cleanDomain }) => {
        setOrgUnit(names && names[0])
        setCleanDomain(cleanDomain)
      })
    }
  }, [invitedBy])
  return orgUnit ? (
    <div className='mw8 center'>
      <h2 className='f4 fw6'>
        <FormattedMessage
          id='CustomInstructionsLogo.Header'
          defaultMessage={`The {name} Survey`}
          values={{
            name: orgUnit.name
          }}
        />
      </h2>
      {cleanDomain === parklandDomain && (
        <img className='w5' alt='Parkland Logo' src={ParklandLogo} />
      )}
      {cleanDomain !== parklandDomain && orgUnit.supplier.logo && (
        <img
          className='w4'
          alt={`${orgUnit.name} Logo`}
          src={fileToUrl(orgUnit.supplier.logo)}
        />
      )}
    </div>
  ) : null
}

export default CustomInstructionsLogo
