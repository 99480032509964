import React from 'react'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import CompletedSuppliers from 'buyer/Insights/components/SustainabilityReport/CompletedSuppliers'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import { List, RecordOf } from 'immutable'

export type CompletedSuppliersProps = {
  completedSuppliers: List<
    RecordOf<{
      id: string
      name: string
      attestedDate: any
      userName: string
    }>
  >
}

const CompletedSuppliersSummary = (props: CompletedSuppliersProps) => {
  return <CompletedSuppliers pageSize={100} {...props} />
}

export default connect((state: RootState) => {
  return {
    completedSuppliers: insightsSelectors.getSustainabilityReportCompletedSuppliers(
      state
    )
  }
})(CompletedSuppliersSummary)
