import React from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import { FormattedMessage } from 'react-intl'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import RootState from 'shared/models/RootState'
import { useSelector } from 'react-redux'
import useUserAccess from 'shared/utils/useUserAccess'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'

const AppConfigNav = ({ isTealbot }) => {
  const integrationsEnabled = useSelector((state: RootState) =>
    settingsSelectors.getField(state, 'integrationsEnabled')
  )
  const userAccess = useUserAccess() as LicenseAccess

  return (
    <NavBar>
      <NavLink
        label={<FormattedMessage id='AppConfigNav.SRM' defaultMessage='SRM' />}
        to={paths.appConfig}
        exact
      />
      {integrationsEnabled && userAccess.accessEcoVadis && (
        <NavLink
          label={
            <FormattedMessage
              id='AppConfigNav.Integrations'
              defaultMessage='Integrations'
            />
          }
          to={paths.integrations}
          exact
        />
      )}
      {isTealbot && (
        <>
          <NavLink
            label={
              <FormattedMessage id='AppConfigNav.RFI' defaultMessage='RFI' />
            }
            to={paths.appRFI}
            exact
          />
          <NavLink
            label={
              <FormattedMessage
                id='AppConfigNav.Security'
                defaultMessage='Security & Privacy'
              />
            }
            to={paths.appSecurity}
            exact
          />
          <NavLink
            label={
              <FormattedMessage
                id='AppConfigNav.Tools'
                defaultMessage='Tools'
              />
            }
            to={paths.appTools}
            exact
          />
        </>
      )}
    </NavBar>
  )
}

export default AppConfigNav
