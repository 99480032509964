import React, { useState } from 'react'
import FileInput from 'shared/components/FileInput'
import Switch from 'shared/components/Switch'
import Divider from 'shared/components/Divider'
import Warning from 'shared/components/Warning'
import { FormattedMessage } from 'react-intl'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import Page from 'shared/components/Page'
import Text from 'shared/components/Text'
import { useDispatch } from 'react-redux'
import { loadClientData } from 'admin/actions'
import { fromJS } from 'immutable'

const styles = {
  section: 'mb3'
}

const ClientSpendLoad = () => {
  const dispatch = useDispatch()
  const [enableNewLoader, setEnableNewLoader] = useState<boolean>(false)
  const [fileUpload, setFileUpload] = useState<File | null>(null)

  const handleLoadClientSpend = e => {
    setFileUpload(null)
    e.preventDefault()
    dispatch(
      loadClientData(
        fromJS({
          attachedFile: fileUpload,
          enableNewLoader
        })
      )
    )
  }

  return (
    <Page title={'Spend Load'}>
      <div className='flex items-center mb2'>
        <Text className='mr3 mt2'>{'Enable "/newLoader"'}</Text>
        <Switch
          checked={enableNewLoader}
          onChange={() =>
            setEnableNewLoader(enableNewLoader => !enableNewLoader)
          }
        />
      </div>
      <Divider className='mv3' />

      <Label>Load Client Spend</Label>
      <form onSubmit={handleLoadClientSpend}>
        <div className={styles.section}>
          <FileInput
            accept='.txt, .json, .csv, .xlsx'
            className='mt3'
            name='attachedFile'
            limit={1000}
            required
            value={fileUpload ? fileUpload.name : ''}
            onChange={(file: File | null) => {
              setFileUpload(file)
            }}
          />
          <Warning
            message={
              <FormattedMessage
                id='Loader.MakeSureTheFileMeetsTheRequiredFormat'
                defaultMessage='Make sure the file meets the required format'
              />
            }
            className='mt2 mb4'
          />
          <Button
            label={'Upload Spend'}
            type='submit'
            autoSize
            className='mt3'
            disabled={!fileUpload}
          />
        </div>
      </form>
    </Page>
  )
}

export default ClientSpendLoad
