import React, { useState, useMemo, useEffect, ChangeEvent } from 'react'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import Input from 'shared/components/Input'
import Text from 'shared/components/Text'
import Paper from 'shared/components/Paper'
import { Column, TableCellProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import { List, RecordOf } from 'immutable'
import dateFormat from 'shared/utils/dateFormat'
import Link from 'shared/components/Link'
import parsePath from 'shared/utils/parsePath'
import paths from 'buyer/routes/paths'
import useThemeColors from 'shared/utils/useThemeColors'
import { useLocation } from 'react-router-dom'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg'
import { useDispatch } from 'react-redux'
import {
  closeSuppliersByQuestionDialog
} from '../../store/actions'
import Pagination from 'shared/components/Pagination/Pagination'

export const messages = defineMessages({
  filterPlaceholder: {
    id: 'CompletedSuppliers.FilterPlaceholder',
    defaultMessage: 'Search for a Supplier'
  }
})
type Props = {
  pageSize: number
  suppliersByQuestion?: boolean
  completedSuppliers: List<
    RecordOf<{
      id: string
      name: string
      attestedDate?: any
      userName?: string
    }>
  >
}

const CompletedSuppliers = ({
  pageSize,
  completedSuppliers,
  suppliersByQuestion
}: Props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const colors = useThemeColors()
  const location = useLocation()

  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')
  const [sortBy, setSortBy] = useState<'name' | 'userName' | 'attestedDate'>(
    'name'
  )
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [filterValue, setFilterValue] = useState<string>('')

  const sortedSuppliers: List<any> = useMemo(() => {
    return completedSuppliers
      ?.filter(supplier => {
        return (
          !filterValue ||
          supplier
            .get('name')
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        )
      })
      .sort((supplier1, supplier2) => {
        let sortValue1, sortValue2

        if (sortBy === 'attestedDate') {
          sortValue1 = new Date(supplier1.get('attestedDate'))
          sortValue2 = new Date(supplier2.get('attestedDate'))
        } else {
          sortValue1 = supplier1.get(sortBy)
          sortValue2 = supplier2.get(sortBy)
        }

        if (sortDirection === 'ASC') {
          return sortValue1 > sortValue2 ? 1 : -1
        } else {
          return sortValue1 < sortValue2 ? 1 : -1
        }
      })
  }, [completedSuppliers, sortBy, sortDirection, filterValue])

  const totalPages = Math.ceil(sortedSuppliers.size / pageSize)

  useEffect(() => {
    setPageNumber(1)
  }, [sortedSuppliers])
  const start = (pageNumber - 1) * pageSize + 1
  const end =
    pageNumber * pageSize > sortedSuppliers.size
      ? sortedSuppliers.size
      : pageNumber * pageSize

  const pageTable = sortedSuppliers.slice(start - 1, end)

  const handleFilterValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.currentTarget.value)
  }

  const getRow = ({ index }) => {
    return pageTable && pageTable.size > 0 && pageTable.get(index)
  }

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  return completedSuppliers?.size > 0 ? (
    <>
      <div className='flex justify-between mb3'>
        <div className='w-50'>
          <Input
            ariaLabel='Search'
            value={filterValue}
            onChange={handleFilterValueChange}
            placeholder={intl.formatMessage(messages.filterPlaceholder)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className='w50'></div>
      </div>
      <Paper noPadding>
        <Table
          rowGetter={getRow}
          rowCount={pageTable.size}
          sort={handleSortChange}
          sortDirection={sortDirection}
          sortBy={sortBy}
        >
          <Column
            label={
              <FormattedMessage
                id='CompletedSuppliers.SupplierName'
                defaultMessage='Supplier Name'
              />
            }
            dataKey='name'
            width={300}
            cellRenderer={({ rowData }: TableCellProps) => {
              return (
                <Link
                  to={parsePath(paths.supplierProfile, {
                    supplierId: rowData.get('id')
                  })}
                  className='underline-hover hover-teal'
                >
                  {rowData.get('name')}
                </Link>
              )
            }}
          />
          {!suppliersByQuestion && (
            <Column
              label={
                <FormattedMessage
                  id='CompletedSuppliers.CompletionDate'
                  defaultMessage='Completion Date'
                />
              }
              dataKey='attestedDate'
              width={150}
              cellRenderer={({ cellData }: TableCellProps) =>
                dateFormat(cellData)
              }
            />
          )}
          {!suppliersByQuestion && (
            <Column
              label={
                <FormattedMessage
                  id='CompletedSuppliers.CompletionBy'
                  defaultMessage='Completion By'
                />
              }
              dataKey='userName'
              width={300}
            />
          )}
          <Column
            disableSort
            label={
              <FormattedMessage
                id='CompletedSuppliers.Action'
                defaultMessage='Action'
              />
            }
            dataKey='id'
            width={150}
            cellRenderer={({ cellData }: TableCellProps) => {
              return (
                <div>
                  <Link
                    to={`${parsePath(paths.supplierHealthSafety, {
                      supplierId: cellData
                    })}?redirectFrom=${location.pathname}`}
                    className={colors.primaryText}
                    onClick={() => dispatch(closeSuppliersByQuestionDialog())}
                  >
                    <FormattedMessage
                      id='CompletedSuppliers.ViewResponses'
                      defaultMessage='View Responses'
                    />
                  </Link>
                </div>
              )
            }}
          />
        </Table>
        {!sortedSuppliers.size && (
          <Text className='tc pv3'>
            <FormattedMessage
              id='CompletedSuppliers.NoMatch'
              defaultMessage='Your search did not match any suppliers'
            />
          </Text>
        )}
      </Paper>
      {sortedSuppliers.size > 0 && (
        <div className='flex items-center justify-between mv2'>
          <Text role='status'>
            <FormattedMessage
              id='CompletedSuppliers.Results'
              defaultMessage={`{total, plural, one {Result 1 supplier} other {Results {localeStart} - {localeEnd} of {localeTotal} suppliers}}`}
              values={{
                total: sortedSuppliers?.size || 0,
                localeTotal: (sortedSuppliers?.size || 0).toLocaleString(),
                localeStart: start.toLocaleString(),
                localeEnd: end.toLocaleString()
              }}
            />
          </Text>
          {totalPages > 1 && (
            <Pagination
              totalPages={totalPages}
              currentPage={pageNumber}
              onChange={p => setPageNumber(p)}
            />
          )}
        </div>
      )}
    </>
  ) : null
}

export default CompletedSuppliers
