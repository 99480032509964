import React, { Component } from 'react'
import { Field } from 'redux-form/immutable'
import { List } from 'immutable'
import { UPDATE_CRITERIA } from '../../actionTypes'
import createEditForm from 'shared/utils/createEditForm'
import TextArea from 'shared/components/TextArea'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'

const placeholder =
  'What capabilities/qualifications are critical for suppliers to be considered?'
const formName = 'vet/criticalCriteria'
// const EditForm = createEditForm(formName)

type Props = {
  vetId: string
  vetIsReadonly: boolean
  criticalCriteria: List<string>
  onRemoveCriteria: (arg: {
    vetId: string
    index: number
    criticalCriteria: string
  }) => void
}

export class CriticalCriteriaEditFrom extends Component<Props> {
  formIndex = 0

  renderCriticalCriteria() {
    const {
      vetId,
      criticalCriteria,
      onRemoveCriteria,
      vetIsReadonly
    } = this.props

    return criticalCriteria.map((c, index) => {
      let EditForm = createEditForm({
        formName: `${formName}${this.formIndex++}`,
        submitActionType: UPDATE_CRITERIA
      })

      return (
        <EditForm
          key={`${index}-${c}`}
          placeholder={placeholder}
          showReadModeContent
          isReadonly={vetIsReadonly}
          readModeContent={
            <div>
              <p className='lh-copy f7 fw4 mid-gray ma0'>
                {index + 1}. {c}
              </p>
              {!vetIsReadonly && (
                <Button
                  autoSize
                  size='small'
                  caution
                  label={
                    <FormattedMessage
                      id='CriticalCriteriaEditForm.Remove'
                      defaultMessage='Remove'
                    />
                  }
                  className='mt2'
                  onClick={() =>
                    onRemoveCriteria({ vetId, index, criticalCriteria: c })
                  }
                />
              )}
            </div>
          }
          initialValues={{
            fromCriteria: c,
            toCriteria: c,
            vetId,
            index
          }}
          canEditSection={true}
        >
          <div>
            <Field name='toCriteria' component={TextArea} />
            <Field name='fromCriteria' component='input' type='hidden' />
            <Field name='vetId' component='input' type='hidden' />
            <Field name='index' component='input' type='hidden' />
          </div>
        </EditForm>
      )
    })
  }

  render() {
    return <div>{this.renderCriticalCriteria()}</div>
  }
}

export default CriticalCriteriaEditFrom
