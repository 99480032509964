import React, { Component } from 'react'
import MyVetsContainer from '../../containers/MyVetsContainer'
import Page from 'shared/components/Page'
import { FormattedMessage } from 'react-intl'

export class VetsPage extends Component {
  render() {
    return (
      <Page title={<FormattedMessage id='VetsPage.RFI' defaultMessage='RFI' />}>
        <MyVetsContainer />
      </Page>
    )
  }
}

export default VetsPage
