import React, { ReactNode, useState } from 'react'
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  Cell,
  LabelList,
  YAxis,
  XAxis,
  Legend,
  Tooltip
} from 'recharts'
import { useIntl, defineMessages } from 'react-intl'
import insightsSelectors from '../../store/insightsSelectors'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import { ownerGroups } from 'supplier/Survey/components/EsgSurvey/DiversityInclusion'
import Text from 'shared/components/Text'
import Card from '@material-ui/core/Card'
import { getSuppliersByQuestion } from 'buyer/Insights/store/actions'
import CompletedSuppliersStatsDialog from 'buyer/Insights/containers/CompletedSuppliersStatsDialog'
import Loading from 'shared/components/Loading'
import Label from 'shared/components/Label'

const barColors = ['#02C0B5', '#E8173B', '#FFC33A']
const noPieColors = ['#25C281', '#F1CD5A', '#006098']
const notAnswerPieColors = ['#4EA1FF', '#FF8462', '#006098']

const messages = defineMessages({
  true: {
    id: 'ReportQuestionStat.True',
    defaultMessage: 'True'
  },
  false: {
    id: 'ReportQuestionStat.False',
    defaultMessage: 'False'
  },
  yes: {
    id: 'ReportQuestionStat.Yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'ReportQuestionStat.No',
    defaultMessage: 'No'
  },
  notAnswer: {
    id: 'ReportQuestionStat.NotAnswer',
    defaultMessage: 'Prefer not to answer'
  },
  noPlan: {
    id: 'ReportQuestionStat.noPlan',
    defaultMessage: `No Plan`
  },
  withinAYear: {
    id: 'ReportQuestionStat.withinAYear',
    defaultMessage: `Within a year`
  },
  notRelevantToMyCompany: {
    id: 'ReportQuestionStat.notRelevantToMyCompany',
    defaultMessage: `Not relevant to my company`
  },
  notRelevantToMyIndustry: {
    id: 'ReportQuestionStat.notRelevantToMyIndustry',
    defaultMessage: `Not relevant to my industry`
  },
  other: {
    id: 'ReportQuestionStat.other',
    defaultMessage: `Other`
  },
  noPlanLegend: {
    id: 'ReportQuestionStat.noPlanLegend',
    defaultMessage:
      'We do not do this at this time and we do not plan to do this anytime soon.'
  },
  withinAYearLegend: {
    id: 'ReportQuestionStat.withinAYearLegend',
    defaultMessage:
      'We do not do this at this time but we plan to start doing this within a year.'
  },
  notRelevantToMyCompanyLegend: {
    id: 'ReportQuestionStat.notRelevantToMyCompanyLegend',
    defaultMessage: 'This question is not relevant to my company.'
  },
  notRelevantToMyIndustryLegend: {
    id: 'ReportQuestionStat.notRelevantToMyIndustryLegend',
    defaultMessage: 'This question is not relevant to my industry.'
  },
  otherLegend: {
    id: 'ReportQuestionStat.otherLegend',
    defaultMessage: 'Other'
  },
  overallBarTitle: {
    id: 'ReportQuestionStat.OverallBarTitle',
    defaultMessage: `Overall answers`
  },
  noPieTitle: {
    id: 'ReportQuestionStat.NoPieTitle',
    defaultMessage: `Suppliers who've answered No`
  },
  notAnswerPieTitle: {
    id: 'ReportQuestionStat.NotAnswerPieTitle',
    defaultMessage: `Suppliers who've preferred not to answer`
  }
})

type Props = {
  label?: ReactNode
  pageId: string
  questionId: string
  yes: number
  no: number
  notAnswer: number
  noReasonNoPlan: number
  noReasonWithinYear: number
  noReasonOther: number
  notAnswerReasonNotMyCompany: number
  notAnswerReasonNotMyIndustry: number
  notAnswerReasonOther: number
  getSuppliersByQuestion: ({
    answer,
    questionId,
    pageId,
    isNot,
    reason
  }: {
    answer: string
    questionId: string
    pageId: string
    isNot: Array<string | undefined>
    reason?: string
  }) => void
  isLoadingSupplierQuestionStats: boolean
}

const hideNoPieQuestionId = [
  '51PercentOwned',
  'diversityCertification',
  'negativeEnvironmentalImpacts',
  'occupationalChargesViolations'
]
const trueOrFalseQuestionsId = [
  'occupationalChargesViolations',
  'negativeEnvironmentalImpacts'
]

export const ReportQuestionStat = (props: Props) => {
  const intl = useIntl()
  const {
    label,
    questionId,
    pageId,
    yes,
    no,
    notAnswer,
    noReasonNoPlan,
    noReasonWithinYear,
    noReasonOther,
    notAnswerReasonNotMyCompany,
    notAnswerReasonNotMyIndustry,
    notAnswerReasonOther,
    getSuppliersByQuestion,
    isLoadingSupplierQuestionStats
  } = props

  const [dialogTitleState, setDialogTitle] = useState<ReactNode>(null)
  const [questionIdState, setSelectedQuestionId] = useState<string>('')
  
  const barData = trueOrFalseQuestionsId.includes(questionId)
    ? [
        {
          name: 'true',
          value: yes
        },
        {
          name: 'false',
          value: no
        }
      ]
    : [
        {
          name: 'yes',
          value: yes
        },
        {
          name: 'no',
          value: no
        },
        {
          name: 'notAnswer',
          value: notAnswer
        }
      ]
  const barTotal = yes + no + notAnswer
  const noPieData = [
    {
      name: 'noPlan',
      value: noReasonNoPlan
    },
    {
      name: 'withinAYear',
      value: noReasonWithinYear
    },
    {
      name: 'other',
      value: noReasonOther
    }
  ]
  const noPieTotal = noReasonNoPlan + noReasonWithinYear + noReasonOther
  const notAnswerData = [
    {
      name: 'notRelevantToMyCompany',
      value: notAnswerReasonNotMyCompany
    },
    {
      name: 'notRelevantToMyIndustry',
      value: notAnswerReasonNotMyIndustry
    },
    {
      name: 'other',
      value: notAnswerReasonOther
    }
  ]
  const notAnswerTotal =
    notAnswerReasonNotMyCompany +
    notAnswerReasonNotMyIndustry +
    notAnswerReasonOther

  const renderLabel = props => {
    const { x, y, width, value } = props
    const label = intl.formatMessage(messages[value])
    const labelWidth = width * 2 - 4
    const labelX = x - width / 2
    return (
      <svg
        width={labelWidth}
        height={60}
        x={labelX}
        y={y - 14 * Math.ceil(label.length / 12)}
      >
        <foreignObject width={labelWidth} height={60}>
          <div className='f9 tc' style={{ width: labelWidth }}>
            {label}
          </div>
        </foreignObject>
      </svg>
    )
  }

  const renderNoAnswerLegend = props => {
    const { payload } = props
    return (
      <ul className='list pl0 pt2'>
        {payload.map(
          (
            entry: { color: any; payload: { percent: number }; value: any },
            index: any
          ) => (
            <li key={`item-${index}`}>
              <span className='flex mb2'>
                <span
                  className='dib w1 h1 br-pill mr1'
                  style={{ backgroundColor: entry.color }}
                ></span>
                <span className='f8 w-80' style={{ padding: '0.1rem' }}>
                  {`${(
                    Math.round(entry.payload.percent * 1000) / 10
                  ).toLocaleString()}% ${intl.formatMessage(
                    messages[`${entry.value}Legend`]
                  )}`}
                </span>
              </span>
            </li>
          )
        )}
      </ul>
    )
  }

  const renderTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0]?.payload || {}
      return (
        <Card className='pa3'>
          <Text className='f7'>{`${intl.formatMessage(
            messages[name]
          )}: ${value?.toLocaleString()}`}</Text>
        </Card>
      )
    }

    return null
  }
  const handleViewSupplierResponses = ({
    reason = '',
    questionId,
    pageId,
    answer,
    label
  }) => {
    let dialogTitle = <Label>{label}</Label>
    let isNot: string[] = []
    if (reason) {
      dialogTitle = (
        <>
          <Label>{`${label}: `}</Label>
          <Label noPadding>{reason}</Label>
        </>
      )
      if (reason === 'Other') {
        reason = ''
        if (answer === 'no') {
          isNot = [
            'We do not do this at this time and we do not plan to do this anytime soon.',
            'We do not do this at this time but we plan to start doing this within a year.'
          ]
        }
        if (answer === 'preferNotToAnswer') {
          isNot = [
            'This question is not relevant to my company.',
            'This question is not relevant to my industry.'
          ]
        }
      }
    }
    setDialogTitle(dialogTitle)
    setSelectedQuestionId(questionId)
    getSuppliersByQuestion({
      answer: answer === 'notAnswer' ? 'preferNotToAnswer' : answer,
      questionId,
      pageId,
      isNot,
      reason
    })
  }

  return (
    <>
      {isLoadingSupplierQuestionStats && questionId === questionIdState && <Loading />}
      <div className='mb3'>
        {label && <label className='db f6 lh-copy mb3'>{label}</label>}
        {questionId === '51PercentOwned' && ( // special case
          <ul className='list pl2'>
            {ownerGroups.map(group => (
              <li key={group}>
                <Text secondary>{group}</Text>
              </li>
            ))}
          </ul>
        )}
        {
          <div className='flex flex-wrap'>
            <div>
              <label className='ml3 mb1 f7 fw6'>
                {intl.formatMessage(messages.overallBarTitle)}
              </label>
              <BarChart
                width={200}
                height={200}
                data={barData}
                margin={{ top: 30 }}
                barGap={10}
                onClick={e =>
                  //onClick on first Bar didn't always work, but having handler here always worked for any bar clicked
                  handleViewSupplierResponses({
                    answer: e.activePayload[0]?.payload.name,
                    pageId,
                    questionId,
                    label: `${intl.formatMessage(
                      messages.overallBarTitle
                    )}: ${intl.formatMessage(
                      messages[e.activePayload[0]?.payload.name]
                    )}`
                  })
                }
              >
                <XAxis
                  dataKey={'value'}
                  tickFormatter={value =>
                    `${(
                      Math.round((value / barTotal) * 1000) / 10
                    ).toLocaleString()}%`
                  }
                  tick={{ fontSize: '0.75rem' }}
                />
                <YAxis
                  dataKey={'value'}
                  type='number'
                  width={32}
                  domain={[0, dataMax => dataMax + Math.round(dataMax / 4)]}
                  tickFormatter={value => value.toLocaleString()}
                  tick={{ fontSize: '0.75rem' }}
                  allowDecimals={false}
                />
                <Tooltip content={renderTooltip} offset={1} />
                <Bar
                  dataKey={'value'}
                  barSize={32}
                >
                  {barData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={barColors[index]} />
                  ))}
                  <LabelList
                    dataKey='name'
                    position='top'
                    content={renderLabel}
                  />
                </Bar>
              </BarChart>
            </div>
            <div className='pl3'>
              {!hideNoPieQuestionId.includes(questionId) && !!noPieTotal && (
                <>
                  <label className='ml3 mb1 f7 fw6'>
                    {intl.formatMessage(messages.noPieTitle)}
                  </label>
                  <PieChart width={400} height={200}>
                    <Legend
                      width={200}
                      height={200}
                      align='right'
                      layout='vertical'
                      iconType={'circle'}
                      content={renderNoAnswerLegend}
                    />
                    <Tooltip content={renderTooltip} offset={1} />
                    <Pie
                      dataKey={'value'}
                      data={noPieData}
                      cx={'50%'}
                      onClick={e =>
                        handleViewSupplierResponses({
                          reason: intl.formatMessage(
                            messages[`${e.payload.name}Legend`]
                          ),
                          pageId,
                          questionId,
                          answer: 'no',
                          label: intl.formatMessage(messages.noPieTitle)
                        })
                      }
                    >
                      {noPieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={noPieColors[index]} />
                      ))}
                    </Pie>
                  </PieChart>
                </>
              )}
            </div>
            <div className='pl3'>
              {!!notAnswerTotal && (
                <>
                  <label className='ml3 mb1 f7 fw6'>
                    {intl.formatMessage(messages.notAnswerPieTitle)}
                  </label>
                  <PieChart width={400} height={200}>
                    <Legend
                      width={200}
                      height={200}
                      align='right'
                      layout='vertical'
                      iconType={'circle'}
                      content={renderNoAnswerLegend}
                    />
                    <Tooltip content={renderTooltip} offset={1} />
                    <Pie
                      dataKey={'value'}
                      data={notAnswerData}
                      cx={'50%'}
                      onClick={e =>
                        handleViewSupplierResponses({
                          reason: intl.formatMessage(
                            messages[`${e.payload.name}Legend`]
                          ),
                          pageId,
                          questionId,
                          answer: 'preferNotToAnswer',
                          label: intl.formatMessage(messages.notAnswerPieTitle)
                        })
                      }
                    >
                      {notAnswerData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={notAnswerPieColors[index]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </>
              )}
            </div>
          </div>
        }
      </div>
      <CompletedSuppliersStatsDialog dialogTitle={dialogTitleState} onChangeQuestionId={setSelectedQuestionId} onChangeDialogTitle={setDialogTitle}/>
    </>
  )
}

type ConnectProps = {
  label?: ReactNode
  pageId: string
  questionId: string
  stat?: any
}

const ReportQuestionStatContainer = (props: ConnectProps) => {
  const { stat, ...rest } = props
  return stat ? <ReportQuestionStat {...rest} {...stat} /> : null
}

export default connect(
  (state: RootState, props: ConnectProps) => ({
    stat: insightsSelectors.getEsgReportQuestionStat(
      state,
      props.pageId,
      props.questionId
    ),
    isLoadingSupplierQuestionStats: state.getIn([
      'buyer',
      'insights',
      'sustainabilityReport',
      'isLoadingQuestionStats'
    ])
  }),
  {
    getSuppliersByQuestion
  }
)(ReportQuestionStatContainer)
