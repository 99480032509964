import React, { ChangeEvent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Label from 'shared/components/Label'
import Select from 'shared/components/Select'

type Props = {
  dataTypeOptions: string[]
  dataType: string
  messages: any
  handleChangeDataType: (value: string) => void
}

export const DataTypeSelect = ({
  dataTypeOptions,
  dataType,
  messages,
  handleChangeDataType
}: Props) => {
  const intl = useIntl()
  return (
    <>
      <Label htmlFor='dataType'>
        <FormattedMessage
          id='UploadSpendDetail.DataType'
          defaultMessage='Data type'
        />
      </Label>
      <Select
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          handleChangeDataType(e.currentTarget.value)
        }
        value={dataType}
        fullWidth
        name='dataType'
      >
        <FormattedMessage
          id='UploadSpendDetail.SelectDataType'
          defaultMessage='Select data type'
        >
          {msg => <option value=''>{msg}</option>}
        </FormattedMessage>
        {dataTypeOptions.map(o => (
          <option key={o} value={o}>
            {intl.formatMessage(messages[o])}
          </option>
        ))}
      </Select>
    </>
  )
}
