import React, { Component, ReactNode } from 'react'
import Paper from 'shared/components/Paper'
import classNames from 'classnames'
import Divider from '../Divider'

const styles = {
  wrapper: 'pt4-ns pt3',
  header: 'f7 fw6 ma0 dt w-100',
  title: 'f7 fw6 ma0 dtc v-mid',
  actions: 'dtc tr',
  conciergeAction: 'pt4-ns',
}

type Props = {
  title: string | ReactNode
  hideTitle?: boolean
  actions?: ReactNode
  children: ReactNode
  noPadding?: boolean
  className?: string
  conciergeAction?: ReactNode
  style?: { marginTop: number }
  role?: string
}

class PageSideSection extends Component<Props> {
  render() {
    const {
      title,
      actions,
      children,
      noPadding,
      className,
      hideTitle,
      role,
      conciergeAction,
    } = this.props

    const accessabilityRole = role !== undefined ? role : ''

    return (
      <section className={className || styles.wrapper}>
        <Paper noPadding={noPadding}>
          {conciergeAction && (
            <>
              <div className={styles.conciergeAction}>{conciergeAction}</div>{' '}
              <Divider className='mt3 mb2' />
            </>
          )}
          <div
            className={classNames(
              styles.header,
              !hideTitle && !conciergeAction && 'pt3-5'
            )}
          >
            <h4
              className={classNames(
                styles.title,
                hideTitle && 'clip',
                noPadding && 'pl3-5'
              )}
            >
              {title}
            </h4>

            {actions && <div className={styles.actions}>{actions}</div>}
          </div>
          <main className={!hideTitle ? 'mt2' : ''} role={accessabilityRole}>
            {children}
          </main>
        </Paper>
      </section>
    )
  }
}

export default PageSideSection
