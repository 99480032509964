import React from 'react'
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    '&:focus-visible, &:hover': {
      color: 'white',
      backgroundColor: '#008584'
    },
    '&$selected:focus-visible, &$selected:hover': {
      color: 'white',
      backgroundColor: '#006B6B'
    }
  },
  selected: {}
})
type Props = Omit<MenuItemProps, 'button'>

const CustomMenuItem = React.forwardRef((props: Props, ref: any) => {
  const classes = useStyles()
  const { children, ...restProps } = props
  return (
    <MenuItem
      classes={{
        root: classes.root,
        selected: classes.selected
      }}
      {...restProps}
    >
      {children}
    </MenuItem>
  )
})

export default CustomMenuItem
