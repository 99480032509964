import React, { Component } from 'react'
import { connect } from 'react-redux'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import { updateVetBuyerSupplier, removeResponseFile } from '../../actions'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List } from 'immutable'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import FileAttachmentList from 'shared/components/FileAttachmentList'
import UploadFile from 'shared/models/UploadedFile'
import RootState from 'shared/models/RootState'
import downloadAttachment, {
  downloadAttachmentToURL
} from 'shared/utils/fileDownload/fileDownload'

type Props = {
  vetBuyerSupplierId: string
  responseFile: List<RecordOf<UploadFile>>
  removeResponseFile: (params: {
    vetBuyerSupplierId: string
    fileName: string
  }) => void
  updateVetBuyerSupplier: (changesToVBS: {
    vetBuyerSupplierId: string
    responseFile: File
  }) => void
  allowWordDocs: boolean
}

export class ResponseFileContainer extends Component<Props> {
  fileInput: any = {}

  handleDownload = fileName => {
    const { vetBuyerSupplierId } = this.props
    downloadAttachment(fileName, `vets/${vetBuyerSupplierId}/documentFile`)
  }

  getFilePreview = fileName => {
    const { vetBuyerSupplierId } = this.props
    return downloadAttachmentToURL(
      fileName,
      `vets/${vetBuyerSupplierId}/documentFile/signedUrl`
    )
  }

  handleSaveResponseFile = (responseFile: File) => {
    const { updateVetBuyerSupplier, vetBuyerSupplierId } = this.props
    if (responseFile) {
      updateVetBuyerSupplier({
        vetBuyerSupplierId,
        responseFile
      })
    }
  }

  handleRemoveResponseFile = (fileName: string) => {
    const { vetBuyerSupplierId, removeResponseFile } = this.props
    removeResponseFile({ vetBuyerSupplierId, fileName })
  }

  render() {
    const { responseFile, allowWordDocs } = this.props

    return (
      <div className='w-100 dt bt bb b--light-gray ph3-5 pb3'>
        <FileAttachmentList
          limit={10}
          allowWordDocs={allowWordDocs}
          attachment={responseFile}
          label={
            <FormattedMessage
              id='ResponseFileContainer.InternalAttachment'
              defaultMessage='Internal Attachment'
            />
          }
          onSave={this.handleSaveResponseFile}
          onRemove={this.handleRemoveResponseFile}
          onDownload={this.handleDownload}
          getFilePreview={this.getFilePreview}
        />
      </div>
    )
  }
}

type ContainerProps = {
  vetBuyerSupplierId: string
}
export default connect(
  (state: RootState, props: ContainerProps) => ({
    responseFile: currentVetSuppliersSelectors.getResponseFile(
      state,
      props.vetBuyerSupplierId
    ),
    allowWordDocs: settingsSelectors.getAllowWordDocs(state)
  }),
  {
    updateVetBuyerSupplier,
    removeResponseFile
  }
)(ResponseFileContainer)
