import { call, put, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import apiRoutes from 'shared/routes/apiRoutes'
import api from 'shared/utils/api'
import { notify, updateFileHistory } from 'shared/actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import moment from 'moment'
import paths from 'admin/routes/paths'
import analytics from 'shared/utils/analytics'

const acceptedMimeTypes = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/csv': 'csv',
  'application/vnd.ms-excel': 'xls'
}

export function* uploadClientFileSaga(action) {
  const file = action.payload.file
  const questionnaireResponses = action.payload.questionnaireResponses
  const history = action.payload.history
  const isSpendFileIncluded = action.payload.isSpendFileIncluded

  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const orgUnitName = yield select(orgsSelectors.getCurrentUserOrgUnitName)

  if (!acceptedMimeTypes[file.type]) {
    return yield put(
      notify({
        message: `File Upload Failed: ${file.type} is not an accepted file type`
      })
    )
  }
  const serverEndpoint = `${apiRoutes.loader}/loader/signedUrl`
  const uploadLink = yield call(api.post, serverEndpoint, {
    filename: file.name,
    filetype: acceptedMimeTypes[file.type],
    questionnaireResponses: questionnaireResponses
  })
  const response = yield call(window.fetch, uploadLink.url, {
    body: file,
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    method: 'PUT'
  })
  if (response.ok) {
    yield put(
      notify({
        message: 'Thanks for your data submission.'
      })
    )
    // update file history
    const userId = yield select(sessionSelectors.getUserId)
    const linkedOrgUnitId = yield select(sessionSelectors.getOrgUnitId)
    const user = yield select(usersSelectors.getById, userId)
    let uploadLinkElems = uploadLink.url.split('/')
    let fileNameElems = uploadLinkElems[4].split('-')
    let fileIdentifier = fileNameElems[0]
    let timestamp = parseInt(fileIdentifier)
    let fullFileName = [fileIdentifier, userId, file.name].join('-')
    let date = moment(timestamp).format('YYYY-MM-DD')
    let email = user.get('email') || ''
    let uploadedFile = {
      date,
      userId,
      fileName: file.name,
      fullFileName,
      linkedOrgUnitId,
      email
    }
    yield put(updateFileHistory({ uploadedFile }))
    //upload client files to tealbook drive
    yield call(api.post, `${apiRoutes.loader}/loader/uploadClientFileToDrive`, {
      uploadedFile: uploadedFile,
      filetype: acceptedMimeTypes[file.type]
    })

    analytics.track(
      isSpendFileIncluded ? 'Spend Uploaded' : 'Vendor Master Uploaded',
      {
        eventSource: 'Admin Upload',
        action: 'Uploaded',
        fileName: file.name,
        orgUnitId,
        orgUnitName
      }
    )

    history.push(paths.fileUpload)
  } else {
    yield put(
      notify({
        message: 'File Upload Failed'
      })
    )
  }
  return yield { ok: response.ok }
}

export default createApiRequestSaga(uploadClientFileSaga)
