import React, { Component, ReactNode } from 'react'
import ImageClear from 'shared/assets/icons/clear.svg'
import ImageSearch from 'shared/assets/icons/search.svg'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'

type Props = {
  value: string
  placeholder: string | ReactNode
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  onChange: (value: string) => void
  onClear: () => void
}

class SearchForm extends Component<Props> {
  handleChange = e => {
    this.props.onChange(e.currentTarget.value)
  }

  render() {
    const { value, onSubmit, onClear, placeholder } = this.props

    return (
      <form className='dt v-mid w-100' onSubmit={onSubmit}>
        <div className='pv2 ph3 ba b--black-10 bg-white br2 w-100 dtc tl f5 v-mid relative'>
          <img src={ImageSearch} alt='search' className='v-mid mr2' />

          <input
            aria-label={placeholder as string}
            type='text'
            name='search'
            placeholder={placeholder as string}
            value={value}
            onChange={this.handleChange}
            className='fw4 input-reset outline-0 b--none mid-gray pa0 v-mid dib w-70 w-80-l'
            autoComplete='off'
          />

          <img
            src={ImageClear}
            alt='clear'
            className='pointer dim absolute'
            onClick={onClear}
            style={{ width: '20px', right: '15px' }}
          />
        </div>
        <Button
          type='submit'
          className='dtc ml2 fr'
          size='large'
          wrapperStyle={{ width: 100 }}
        >
          <FormattedMessage id='SearchForm.Search' defaultMessage='Search' />
        </Button>
      </form>
    )
  }
}

export default SearchForm
