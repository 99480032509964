import React, { Component } from 'react'
import connectReduxForm from 'shared/utils/connectReduxForm'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import AddTagForm from '../../components/AddTagForm'
import { ADD_TAG } from '../../actionTypes'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'

type Props = {
  handleSubmit: (arg: string) => () => void
  addTag: string
  numberOfTags: number
  intl: IntlShape
}
export class PrivateOfferingsAddTagForm extends Component<Props> {
  render() {
    const { handleSubmit, addTag, numberOfTags, intl } = this.props

    return (
      <AddTagForm
        inputId='privateOfferingsNewTag'
        onSubmit={handleSubmit(addTag)}
        inputPlaceholder={intl.formatMessage({
          id: 'PrivateOfferingsAddTagForm.PrivateOfferings',
          defaultMessage: 'Offering (ex: Marketing)'
        })}
        footerNote={
          <FormattedMessage
            id="PrivateOfferingsAddTagForm.NoteYourTagsAren'tVisibile"
            defaultMessage="NOTE: These tags aren't visible outside of your company."
          />
        }
        numberOfTags={numberOfTags}
      />
    )
  }
}

const formName = 'buyer/supplier/privateOfferings'

export default connectReduxForm(
  undefined,
  {
    addTag: createSubmitFormAction(formName, ADD_TAG)
  },
  {
    form: formName,
    initialValues: {
      tagsFieldName: 'privateOfferings'
    }
  },
  injectIntl(PrivateOfferingsAddTagForm)
)
