import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import RootState from 'shared/models/RootState'
import { closeCongratsSurveyDialog } from '../../actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import GeneralMessage from './GeneralMessage'
import UpdatePassword from './UpdatePassword'
import { useHistory } from 'react-router-dom'
import paths from '../../routes/paths'

const CongratsSurveyCompleteDialog = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(sessionSelectors.getUser)
  const lastLogin = user.get('lastLogin')
  const isTealbot = user.get('roles')?.includes('tealbot')
  const isNewUser = !lastLogin && !isTealbot
  const open = useSelector((state: RootState) =>
    state.getIn(['supplier', 'survey', 'openCongratsDialog'])
  )

  const handleOnClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(closeCongratsSurveyDialog())
    }
  }

  const handleCloseClick = (redirect: boolean = false) => {
    handleOnClose(null, 'done')
    if (redirect) {
      history.push(paths.company)
    }
  }

  return open ? (
    <Dialog
      aria-labelledby='You have completed the Sustainability Survey'
      open={!!open}
      onClose={handleOnClose}
    >
      <DialogContent>
        {!isNewUser && <GeneralMessage onClose={handleCloseClick} />}
        {isNewUser && <UpdatePassword onClose={handleCloseClick} />}
      </DialogContent>
    </Dialog>
  ) : null
}

export default CongratsSurveyCompleteDialog
