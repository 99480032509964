import React, { Component, ReactNode } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomExpansionPanel = withStyles({
  root: {
    margin: 0
  }
})(ExpansionPanel)

type Props = {
  title: string | ReactNode
}
class FilterExpansionPanel extends Component<Props> {
  render() {
    const { title, children } = this.props

    return (
      <CustomExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            aria-level={1}
            aria-live='polite'
            variantMapping={{ body1: 'h2' }}
          >
            {title}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className='w-100'>{children}</div>
        </ExpansionPanelDetails>
      </CustomExpansionPanel>
    )
  }
}

export default FilterExpansionPanel
