import React from 'react'
import SummaryRow from './SummaryRow'

type Props = {
  country?: string
  year: string
  quarter: string
  onSave: (
    subCategories: Array<{
      subCategory: string
      subType?: string
      spend: number
    }>
  ) => void
  onCancel: () => void
}

const AddSummaryRow = (props: Props) => {
  const { country, year, quarter, onSave, onCancel } = props
  return (
    <div>
      <SummaryRow
        addSummary={country}
        quarter={`${year}-Q${quarter}`}
        initalSubCategories={[]}
        onCancel={onCancel}
        onSave={onSave}
      />
    </div>
  )
}

export default AddSummaryRow
