import React, { Component, Fragment } from 'react'
import { updateVet, removeAttachment } from '../../actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import currentVetSelectors from '../../selectors/currentVetSelectors'
import currentVetSuppliersSelectors from '../../selectors/currentVetSuppliersSelectors'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Input from 'shared/components/Input'
import TextArea from 'shared/components/TextArea'
import Select from 'shared/components/Select'
import Divider from 'shared/components/Divider'
import { connect } from 'react-redux'
import AutoSaveInput from 'shared/components/AutoSaveInput'
import DatePicker from 'shared/components/DatePicker'
import DateTimePicker from 'shared/components/DateTimePicker'
import PageSection from 'shared/components/PageSection'
import Switch from 'shared/components/Switch'
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  IntlShape
} from 'react-intl'
import Scrollable from 'shared/components/Scrollable'
import { Terms } from 'supplier/Vets/components/VetTermsPage/VetTermsPage'
import RichTextEditor, {
  createValueFromString,
  createEmptyValue
} from 'react-rte'
import ImageArrowDown from 'shared/assets/icons/arrow-down.svg'
import ImageArrowUp from 'shared/assets/icons/arrow-up.svg'
import Loading from 'shared/components/Loading'
import { rteMessages } from 'posting/components/NewPostForm/NewPostForm'
import { List, RecordOf } from 'immutable'
import UploadedFile from 'shared/models/UploadedFile'
import moment from 'moment'
import FileAttachmentList from 'shared/components/FileAttachmentList'
import RootState from 'shared/models/RootState'
import downloadAttachment, {
  downloadAttachmentToURL
} from 'shared/utils/fileDownload/fileDownload'

defineMessages({
  'VetInvitationOverviewContainer.responseInstructionsDefaultValue': {
    id: 'VetInvitationOverviewContainer.responseInstructionsDefaultValue',
    defaultMessage:
      'Please provide a brief description and a 5 to 10 slide presentation.'
  }
})

const dateFormatter = new global.Intl.DateTimeFormat('en-US').format

type Props = {
  vetId: string
  budget: string
  description: string
  location: string
  responseDate?: Date | number
  decisionDate?: Date | number
  detailedReponseRequested: boolean
  responseInstructions: string
  hasInvited: boolean
  inviteSuppliers: boolean
  termsAndConditionsSource?: string
  termsAndConditions: string
  isTealbot: boolean
  buyerName: string
  buyerTermsAndConditions: string
  isReadonly: boolean
  document: List<RecordOf<UploadedFile>>
  isOwner: boolean
  isVetApprover: boolean
  updateVet: (vetId: string) => void
  removeAttachment: (obj: { vetId: string; fileName: string }) => void
  intl: IntlShape
  allowWordDocs: boolean
  supplierApprovedAfterResponseDate?: Map<string, any>
  decision: string
}

type State = {
  loading: boolean
  showTerms: boolean
  currentDetailedReponseRequested: boolean
  responseInstructions: string
  rteValue: any
  buyerTermsAndConditionsValue: {}
  responseByError: string
}

export class VetInvitationOverviewContainer extends Component<Props, State> {
  state: State = {
    loading: false,
    showTerms: false,
    currentDetailedReponseRequested: this.props.detailedReponseRequested,
    responseInstructions: this.props.responseInstructions,
    rteValue: createEmptyValue(),
    buyerTermsAndConditionsValue: createEmptyValue(),
    responseByError: ''
  }

  static getDerivedStateFromProps(nextProps, state) {
    return (nextProps.termsAndConditions &&
      nextProps.termsAndConditions !== state.termsAndConditions) ||
      nextProps.buyerTermsAndConditions !== state.buyerTermsAndConditions ||
      (state.loading && nextProps.inviteSuppliers)
      ? {
          termsAndConditions: nextProps.termsAndConditions,
          rteValue: createValueFromString(
            nextProps.termsAndConditions || '',
            'markdown'
          ),
          buyerTermsAndConditions: nextProps.buyerTermsAndConditions,
          buyerTermsAndConditionsValue: createValueFromString(
            nextProps.buyerTermsAndConditions || '',
            'markdown'
          ),
          loading:
            state.loading && nextProps.inviteSuppliers ? false : state.loading
        }
      : null
  }

  handleSave = changes => {
    const { vetId, updateVet } = this.props

    updateVet({
      vetId,
      ...changes
    })
  }

  handleChangeDate = (fieldName, newDate) => {
    this.handleSave({
      [fieldName]: newDate && newDate.toDate()
    })
  }

  handleChangeDetailedReponseRequested = e => {
    const newValue = e.target.value === 'yes'

    const responseInstructions = newValue
      ? this.props.intl.formatMessage({
          id: 'VetInvitationOverviewContainer.responseInstructionsDefaultValue'
        })
      : ''

    this.setState({
      currentDetailedReponseRequested: newValue,
      responseInstructions
    })

    this.handleSave({
      detailedReponseRequested: newValue,
      responseInstructions
    })
  }

  handleCommunicationChange = () => {
    const { inviteSuppliers } = this.props
    this.setState({
      loading: true
    })
    this.handleSave({ inviteSuppliers: !inviteSuppliers })
  }

  handleDeleteAttachment = (fileName: string) => {
    const { removeAttachment, vetId } = this.props
    removeAttachment({ vetId, fileName })
  }

  handleSaveAttachment = (document: File) => {
    this.handleSave({
      document
    })
  }

  handleRTEChange = value => {
    this.setState({
      rteValue: value
    })
  }

  handleRTEBlur = e => {
    const { rteValue } = this.state
    this.handleSave({
      termsAndConditions: rteValue
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? rteValue.toString('markdown').substr(0, 10000)
        : ''
    })
  }

  toggleTerm = () => {
    this.setState({ showTerms: !this.state.showTerms })
  }

  handleTermsAndConditionsSourceChange = changes => {
    const { buyerTermsAndConditions } = this.props

    if (changes.termsAndConditionsSource === 'Buyer') {
      changes['termsAndConditions'] = buyerTermsAndConditions
    }

    this.handleSave(changes)
  }
  handleDownload = fileName => {
    const { vetId } = this.props
    downloadAttachment(fileName, `vets/${vetId}/documentFile`)
  }

  getFilePreview = fileName => {
    const { vetId } = this.props
    return downloadAttachmentToURL(
      fileName,
      `vets/${vetId}/documentFile/signedUrl`
    )
  }

  renderDateInputs = () => {
    const {
      isReadonly,
      hasInvited,
      responseDate,
      decisionDate,
      supplierApprovedAfterResponseDate,
      decision
    } = this.props
    return isReadonly ? (
      <Fragment>
        <Label required>
          <FormattedMessage
            id='VetInvitationOverviewContainer.ResponseBy'
            defaultMessage='Response By'
          />
        </Label>
        <Text>{responseDate && dateFormatter(responseDate)}</Text>

        <Label required>
          <FormattedMessage
            id='VetInvitationOverviewContainer.CompletionDate'
            defaultMessage='Completion Date'
          />
        </Label>
        <Text>{decisionDate && dateFormatter(decisionDate)}</Text>
      </Fragment>
    ) : (
      <Fragment>
        <Label htmlFor='responseDate' required>
          <FormattedMessage
            id='VetInvitationOverviewContainer.ResponseBy'
            defaultMessage='Response By'
          />
        </Label>
        <div className='w-100 w-50-ns pr2-ns'>
          <DateTimePicker
            name='responseDate'
            disablePast
            minDate={hasInvited ? responseDate : undefined}
            minDateMessage={
              <FormattedMessage
                id='VetInvitationOverviewContainer.ResponseByMinMessage1'
                defaultMessage='You cannot invite a new supplier when response date is before today'
              />
            }
            maxDate={decisionDate}
            maxDateMessage={
              <FormattedMessage
                id='VetInvitationOverviewContainer.ResponseByMaxMessage'
                defaultMessage='Response date could not be after decision date'
              />
            }
            value={responseDate}
            onChange={newDate => {
              let hasError = false
              if (hasInvited && newDate) {
                if (!newDate.isValid()) {
                  this.setState({
                    responseByError: 'Please enter a valid date.'
                  })
                  hasError = true
                } else if (moment(responseDate).isAfter(newDate)) {
                  this.setState({
                    responseByError:
                      'Response By date must be set in the future of the current one.'
                  })
                  hasError = true
                }
              }

              if (!hasInvited || !hasError) {
                this.setState({
                  responseByError: ''
                })
                this.handleChangeDate(
                  'responseDate',
                  newDate && newDate.minute(0)
                )
              }
            }}
            required
            autoComplete='new-date'
            error={!!this.state.responseByError}
            helperText={this.state.responseByError}
          />
        </div>

        <Label htmlFor='decisionDate' required>
          <FormattedMessage
            id='VetInvitationOverviewContainer.DecisionDate'
            defaultMessage='Decision Date'
          />
        </Label>
        <div className='w-100 w-50-ns pr2-ns dib v-top'>
          <DatePicker
            name='decisionDate'
            disablePast
            minDate={responseDate}
            minDateMessage={
              decision === 'In Progress' ||
              supplierApprovedAfterResponseDate ? (
                <FormattedMessage
                  id='VetInvitationOverviewContainer.DecisionDateMinMessage1'
                  defaultMessage='You cannot invite a new supplier when completion date is before today'
                />
              ) : (
                ''
              )
            }
            value={decisionDate}
            onChange={newDate =>
              this.handleChangeDate(
                'decisionDate',
                newDate &&
                  newDate
                    .hour(23)
                    .minute(59)
                    .second(59)
              )
            }
            required
            autoComplete='new-date'
          />
        </div>
      </Fragment>
    )
  }

  render() {
    const {
      currentDetailedReponseRequested,
      responseInstructions,
      showTerms,
      loading
    } = this.state
    const {
      description,
      budget,
      location,
      detailedReponseRequested,
      isReadonly,
      hasInvited,
      inviteSuppliers,
      document,
      isOwner,
      isVetApprover,
      termsAndConditionsSource,
      buyerTermsAndConditions,
      isTealbot,
      buyerName,
      intl,
      allowWordDocs
    } = this.props

    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'BLOCK_TYPE_DROPDOWN',
        'HISTORY_BUTTONS'
      ],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_DROPDOWN: [
        {
          label: intl.formatMessage(rteMessages.normalLabel),
          style: 'unstyled'
        },
        {
          label: intl.formatMessage(rteMessages.headingLabel),
          style: 'header-three'
        }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
      ]
    }

    return (
      <>
        <PageSection
          title={
            isReadonly || !isOwner || hasInvited ? (
              inviteSuppliers ? (
                <FormattedMessage
                  id='VetInvitationOverviewContainer.inviteSuppliersEnabled'
                  defaultMessage='Supplier Communications Enabled'
                />
              ) : (
                <FormattedMessage
                  id='VetInvitationOverviewContainer.inviteSuppliersDisabled'
                  defaultMessage='Supplier Communications Disabled'
                />
              )
            ) : (
              <FormattedMessage
                id='VetInvitationOverviewContainer.VetOverview'
                defaultMessage='Enable Supplier Communications'
              />
            )
          }
          actions={
            !isReadonly &&
            !hasInvited &&
            (isVetApprover || isOwner) && (
              <div>
                <Switch
                  checked={inviteSuppliers}
                  onChange={this.handleCommunicationChange}
                />
              </div>
            )
          }
        >
          <Text className='mt3-5'>
            <FormattedMessage
              id='VetInvitationOverviewContainer.RFIMessage'
              defaultMessage='Enabling supplier communication allows you to share this RFI with suppliers and get quick responses or detailed submissions from them.'
            />
          </Text>
          {!isReadonly &&
            !hasInvited &&
            !inviteSuppliers &&
            !(isVetApprover || isOwner) && (
              <Text className='mt3-5'>
                <FormattedMessage
                  id='VetInvitationOverviewContainer.RFIPermissionMessage'
                  defaultMessage='Supplier communications have not been enabled for this RFI. Only the RFI owner or a user with RFI Approver permissions can change this setting. Contact your administrator or the TealBook concierge if you need assistance.'
                />
              </Text>
            )}
          {inviteSuppliers && (
            <Fragment>
              {isReadonly || (hasInvited && !isTealbot) ? (
                <div>
                  <Label required>
                    <FormattedMessage
                      id='VetInvitationOverviewContainer.Summary'
                      defaultMessage='Summary for Suppliers'
                    />
                  </Label>
                  <Text>{description}</Text>
                  {budget && (
                    <Fragment>
                      <Label>
                        <FormattedMessage
                          id='VetInvitationOverviewContainer.Budget'
                          defaultMessage='Budget'
                        />
                      </Label>
                      <Text>{budget}</Text>
                    </Fragment>
                  )}
                  {location && (
                    <Fragment>
                      <Label>
                        <FormattedMessage
                          id='VetInvitationOverviewContainer.Location'
                          defaultMessage='Location'
                        />
                      </Label>
                      <Text>{location}</Text>
                    </Fragment>
                  )}

                  {this.renderDateInputs()}

                  {detailedReponseRequested === true ||
                  detailedReponseRequested === false ? (
                    <Fragment>
                      <Label>
                        <FormattedMessage
                          id='VetInvitationOverviewContainer.DetailedResponse'
                          defaultMessage='Detailed Response'
                        />
                      </Label>
                      <Text>
                        {detailedReponseRequested === true ? 'Yes' : 'No'}
                      </Text>
                    </Fragment>
                  ) : (
                    <Text secondary className='pt3-5'>
                      <FormattedMessage
                        id='VetInvitationOverviewContainer.PresentationSlides'
                        defaultMessage='Would you like to receive a customized response with presentation slides from each supplier interested in your business?'
                      />
                    </Text>
                  )}
                  {detailedReponseRequested === true && !!responseInstructions && (
                    <Fragment>
                      <Label>
                        <FormattedMessage
                          id='VetInvitationOverviewContainer.ResponseInstructions'
                          defaultMessage='Response Instructions'
                        />
                      </Label>
                      <Text>{responseInstructions}</Text>
                    </Fragment>
                  )}
                  {document && document.size > 0 && (
                    <FileAttachmentList
                      readonly
                      attachment={document}
                      onDownload={this.handleDownload}
                      getFilePreview={this.getFilePreview}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Label required>
                    <FormattedMessage id='VetInvitationOverviewContainer.Summary' />
                  </Label>
                  <FormattedMessage
                    id='VetInvitationOverviewContainer.ProvideASummaryOfYourNeedsAndGoals'
                    defaultMessage='Provide a summary of your needs and goals'
                  >
                    {message => (
                      <AutoSaveInput
                        component={TextArea}
                        value={description}
                        name='description'
                        maxLength={500}
                        placeholder={message}
                        required
                        onSave={this.handleSave}
                      />
                    )}
                  </FormattedMessage>

                  <Label htmlFor='budget'>
                    <FormattedMessage
                      id='VetInvitationOverviewContainer.Budget'
                      defaultMessage='Budget'
                    />
                  </Label>
                  <AutoSaveInput
                    name='budget'
                    component={Select}
                    fullWidth
                    value={budget}
                    onSave={this.handleSave}
                  >
                    <FormattedMessage
                      id='VetInvitationOverviewContainer.Unspecified'
                      defaultMessage='Unspecified'
                    >
                      {message => (
                        <option value='Unspecified'>{message}</option>
                      )}
                    </FormattedMessage>
                    <option value='$100,000-$250,000'>$100,000-$250,000</option>
                    <option value='$250,000-$500,000'>$250,000-$500,000</option>
                    <option value='$500,000-$1,000,000'>
                      $500,000-$1,000,000
                    </option>
                    <option value='$1,000,000-$2,000,000'>
                      $1,000,000-$2,000,000
                    </option>
                    <option value='$2,000,000+'>$2,000,000+</option>
                  </AutoSaveInput>

                  <Label htmlFor='location'>
                    <FormattedMessage
                      id='VetInvitationOverviewContainer.Location'
                      defaultMessage='Location'
                    />
                  </Label>
                  <FormattedMessage
                    id='VetInvitationOverviewContainer.OperateFormSpecidicLocaiton'
                    defaultMessage='Does the supplier need to know about or operate from a specific location?'
                  >
                    {message => (
                      <AutoSaveInput
                        component={Input}
                        value={location}
                        name='location'
                        placeholder={message}
                        onSave={this.handleSave}
                        required
                      />
                    )}
                  </FormattedMessage>

                  {this.renderDateInputs()}

                  <Label>
                    <FormattedMessage
                      id='VetInvitationOverviewContainer.WouldLikeToReceiveADetailedResponse'
                      defaultMessage='Would you like to receive a detailed response?'
                    />
                  </Label>
                  <label className='f7 dib mid-gray mr2'>
                    <FormattedMessage
                      id='VetInvitationOverviewContainer.Yes'
                      defaultMessage='Yes'
                    />
                    <input
                      className='ml1'
                      name='detailedReponseRequested'
                      type='radio'
                      value='yes'
                      checked={currentDetailedReponseRequested}
                      onChange={this.handleChangeDetailedReponseRequested}
                    />
                  </label>

                  <label className='f7 dib mid-gray'>
                    <FormattedMessage id='No' />
                    <input
                      className='ml1'
                      name='detailedReponseRequested'
                      type='radio'
                      value='no'
                      checked={!currentDetailedReponseRequested}
                      onChange={this.handleChangeDetailedReponseRequested}
                    />
                  </label>

                  {currentDetailedReponseRequested && (
                    <div>
                      <Label>
                        <FormattedMessage
                          id='VetInvitationOverviewContainer.ResponseInstructions'
                          defaultMessage='Response Instructions'
                        />
                      </Label>
                      <FormattedMessage
                        id='VetInvitationOverviewContainer.WhatKindOfDetailsTheSupplierShouldProvide'
                        defaultMessage='What kind of details the supplier should provide?'
                      >
                        {message => (
                          <AutoSaveInput
                            name='responseInstructions'
                            component={TextArea}
                            maxLength={500}
                            placeholder={message}
                            value={responseInstructions}
                            onSave={this.handleSave}
                          />
                        )}
                      </FormattedMessage>
                    </div>
                  )}

                  <FileAttachmentList
                    limit={10}
                    allowWordDocs={allowWordDocs}
                    attachment={document}
                    label={
                      <FormattedMessage
                        id='VetInvitationOverviewContainer.Attachment'
                        defaultMessage='Attachment to Share'
                      />
                    }
                    onSave={this.handleSaveAttachment}
                    onRemove={this.handleDeleteAttachment}
                    onDownload={this.handleDownload}
                    getFilePreview={this.getFilePreview}
                  />
                </div>
              )}
              <div className='pointer dt w-100 mt2' onClick={this.toggleTerm}>
                <Label className='db pt3 pb1 f7 fw6 pointer dtc'>
                  <FormattedMessage
                    id='VetInvitationOverviewContainer.TermsCondition'
                    defaultMessage='Terms and Conditions'
                  />
                </Label>
                <div className='dtc w-10 tr'>
                  <img
                    src={showTerms ? ImageArrowUp : ImageArrowDown}
                    className='v-mid'
                    alt=''
                  />
                </div>
              </div>
              {!hasInvited && showTerms && (
                <div className='mb2'>
                  {!buyerTermsAndConditions && (
                    <label className='f7 dib mid-gray mr2'>
                      <FormattedMessage
                        id='VetInvitationOverviewContainer.TermsDefault'
                        defaultMessage='Default'
                      />
                      <input
                        className='ml1'
                        name='termsAndConditionsSource'
                        type='radio'
                        value='Default'
                        checked={
                          !termsAndConditionsSource ||
                          termsAndConditionsSource === 'Default'
                        }
                        onChange={e =>
                          this.handleTermsAndConditionsSourceChange({
                            termsAndConditionsSource: 'Default'
                          })
                        }
                      />
                    </label>
                  )}
                  {buyerTermsAndConditions && (
                    <label className='f7 dib mid-gray mr2'>
                      {buyerName}
                      <input
                        className='ml1'
                        name='termsAndConditionsSource'
                        type='radio'
                        value='Buyer'
                        checked={
                          !termsAndConditionsSource ||
                          termsAndConditionsSource === 'Buyer'
                        }
                        onChange={e =>
                          this.handleTermsAndConditionsSourceChange({
                            termsAndConditionsSource: 'Buyer'
                          })
                        }
                      />
                    </label>
                  )}
                  <label className='f7 dib mid-gray mr2'>
                    <FormattedMessage
                      id='VetInvitationOverviewContainer.TermsCustom'
                      defaultMessage='Custom'
                    />
                    <input
                      className='ml1'
                      name='termsAndConditionsSource'
                      type='radio'
                      value='Custom'
                      checked={termsAndConditionsSource === 'Custom'}
                      onChange={e =>
                        this.handleTermsAndConditionsSourceChange({
                          termsAndConditionsSource: 'Custom'
                        })
                      }
                    />
                  </label>
                </div>
              )}
              {showTerms &&
                (termsAndConditionsSource === 'Default' ? (
                  <Scrollable>
                    <Terms />
                  </Scrollable>
                ) : (
                  <div
                    className={
                      isReadonly ||
                      hasInvited ||
                      termsAndConditionsSource === 'Buyer'
                        ? 'rte-readonly-wrapper'
                        : ''
                    }
                  >
                    <RichTextEditor
                      value={
                        termsAndConditionsSource === 'Buyer'
                          ? this.state.buyerTermsAndConditionsValue
                          : this.state.rteValue
                      }
                      onChange={this.handleRTEChange}
                      onBlur={this.handleRTEBlur}
                      toolbarConfig={toolbarConfig}
                      placeholder={
                        <FormattedMessage
                          id='VetInvitationOverviewContainer.TermsPlaceholder'
                          defaultMessage='Customize terms and conditions'
                        />
                      }
                      editorClassName='react-rte-editor'
                      toolbarClassName='react-rte-toolbar'
                      readOnly={
                        isReadonly ||
                        hasInvited ||
                        termsAndConditionsSource === 'Buyer'
                      }
                    />
                  </div>
                ))}
              <Divider />
            </Fragment>
          )}
        </PageSection>
        {loading && <Loading />}
      </>
    )
  }
}

export default connect(
  (state: RootState) => {
    const currentUserId = sessionSelectors.getUserId(state)
    const users = currentVetSelectors.getVetField(state, 'users')
    const owner = users && users.find(user => user.lead)
    const isOwner = owner && owner.user === currentUserId
    const isVetApprover = sessionSelectors.userHasRole(
      state,
      'buyerVetApprover'
    )
    const buyerTermsAndConditions = settingsSelectors.getField(
      state,
      'buyerTermsAndConditions'
    )
    const buyerName = settingsSelectors.getField(state, 'name')
    return {
      buyerName,
      buyerTermsAndConditions,
      isOwner,
      isVetApprover,
      hasInvited: currentVetSuppliersSelectors.hasInvited(state),
      isReadonly: currentVetSelectors.isReadonly(state),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      allowWordDocs: settingsSelectors.getAllowWordDocs(state),
      supplierApprovedAfterResponseDate: currentVetSelectors.getSupplierApprovedAfterResponseDate(
        state
      ),
      decision: currentVetSelectors.getDecision(state)
    }
  },
  {
    updateVet,
    removeAttachment
  }
)(injectIntl(VetInvitationOverviewContainer))
