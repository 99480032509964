import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import FilterCertificationContainer from '../FilterCertificationContainer'
import FilterCheckboxListContainer from '../FilterCheckboxListContainer'
import { List } from 'immutable'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import { FormattedMessage } from 'react-intl'
import Divider from '../../../../shared/components/Divider'

type Props = {
  showDiversity: boolean
  showQuality: boolean
  showSecurity: boolean
  showSustainability: boolean
  showFood: boolean
  isFilteredDiversity: boolean
  isFilteredQuality: boolean
  isFilteredSecurity: boolean
  isFilteredSustainability: boolean
  isFilteredFood: boolean
  category?: string
}

const diversityFilterCheckboxes = List([
  {
    value: 'diversity',
    label: 'Include Expired and Potential'
  }
])

const qualityFilterCheckboxes = List([
  {
    value: 'quality',
    label: 'Include Expired and Potential'
  }
])

const securityFilterCheckboxes = List([
  {
    value: 'security',
    label: 'Include Expired and Potential'
  }
])

const sustainabilityFilterCheckboxes = List([
  {
    value: 'sustainability',
    label: 'Include Expired and Potential'
  }
])
const foodFilterCheckboxes = List([
  {
    value: 'food',
    label: 'Include Expired and Potential'
  }
])
export class FilterCertificationsContainer extends Component<Props> {
  render() {
    const {
      showDiversity,
      showQuality,
      showSecurity,
      showSustainability,
      showFood,
      isFilteredDiversity,
      isFilteredQuality,
      isFilteredSecurity,
      isFilteredSustainability,
      isFilteredFood,
      category
    } = this.props

    return (
      <Fragment>
        {showDiversity && (
          <Fragment>
            <FilterCertificationContainer
              category='diversity'
              categoryLabel={
                <FormattedMessage
                  id='FilterCertificationsContainer.Diversity'
                  defaultMessage='Diversity'
                />
              }
              filterCategory={category}
              showMatchAny
            />
            {isFilteredDiversity && (
              <div className='pl2 mb2'>
                <Divider className='mt1' />
                <FilterCheckboxListContainer
                  prefix='exclude-'
                  list={diversityFilterCheckboxes}
                  filterKey='includeUnQualified'
                  selectedSelector={filterOptionsSelectors.getByKey}
                  category={category}
                />
              </div>
            )}
          </Fragment>
        )}
        {showQuality && (
          <Fragment>
            <FilterCertificationContainer
              category='quality'
              categoryLabel={
                <FormattedMessage
                  id='FilterCertificationsContainer.Quality'
                  defaultMessage='Quality'
                />
              }
              filterCategory={category}
              showMatchAny
            />
            {isFilteredQuality && (
              <div className='pl2 mb2'>
                <Divider className='mt1' />
                <FilterCheckboxListContainer
                  prefix='exclude-'
                  list={qualityFilterCheckboxes}
                  filterKey='includeUnQualified'
                  selectedSelector={filterOptionsSelectors.getByKey}
                  category={category}
                />
              </div>
            )}
          </Fragment>
        )}
        {showSecurity && (
          <Fragment>
            <FilterCertificationContainer
              category='security'
              categoryLabel={
                <FormattedMessage
                  id='FilterCertificationsContainer.Security'
                  defaultMessage='Security'
                />
              }
              categoryLabelTip={
                <FormattedMessage
                  id='FilterCertificationsContainer.InformationSecurity'
                  defaultMessage='Information Security'
                />
              }
              filterCategory={category}
              showMatchAny
            />
            {isFilteredSecurity && (
              <div className='pl2 mb2'>
                <Divider className='mt1' />
                <FilterCheckboxListContainer
                  prefix='exclude-'
                  list={securityFilterCheckboxes}
                  filterKey='includeUnQualified'
                  selectedSelector={filterOptionsSelectors.getByKey}
                  category={category}
                />
              </div>
            )}
          </Fragment>
        )}
        {showSustainability && (
          <Fragment>
            <FilterCertificationContainer
              category='sustainability'
              categoryLabel={
                <FormattedMessage
                  id='FilterCertificationsContainer.Sustainability'
                  defaultMessage='Sustainability'
                />
              }
              categoryLabelTip={
                <FormattedMessage
                  id='FilterCertificationsContainer.SocialResponsiblityAndSustainability'
                  defaultMessage='Social Responsibility and Sustainability'
                />
              }
              filterCategory={category}
              showMatchAny
            />
            {isFilteredSustainability && (
              <div className='pl2 mb2'>
                <Divider className='mt1' />
                <FilterCheckboxListContainer
                  prefix='exclude-'
                  list={sustainabilityFilterCheckboxes}
                  filterKey='includeUnQualified'
                  selectedSelector={filterOptionsSelectors.getByKey}
                  category={category}
                />
              </div>
            )}
          </Fragment>
        )}
        {showFood && (
          <Fragment>
            <FilterCertificationContainer
              category='food'
              categoryLabel={
                <FormattedMessage
                  id='FilterCertificationsContainer.Food'
                  defaultMessage='Food'
                />
              }
              categoryLabelTip={
                <FormattedMessage
                  id='FilterCertificationsContainer.Food'
                  defaultMessage='Food'
                />
              }
              filterCategory={category}
              showMatchAny
            />
            {isFilteredFood && (
              <div className='pl2 mb2'>
                <Divider className='mt1' />
                <FilterCheckboxListContainer
                  prefix='exclude-'
                  list={foodFilterCheckboxes}
                  filterKey='includeUnQualified'
                  selectedSelector={filterOptionsSelectors.getByKey}
                  category={category}
                />
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default connect(state => ({
  isFilteredDiversity: filterOptionsSelectors.getByKey(state, 'diversity'),
  isFilteredSustainability: filterOptionsSelectors.getByKey(
    state,
    'sustainability'
  ),
  isFilteredSecurity: filterOptionsSelectors.getByKey(state, 'security'),
  isFilteredQuality: filterOptionsSelectors.getByKey(state, 'quality'),
  isFilteredFood: filterOptionsSelectors.getByKey(state, 'food')
}))(FilterCertificationsContainer)
