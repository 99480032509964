import React, { useEffect, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { capitalize } from '@material-ui/core'

import Button from 'shared/components/Button'
import Link from 'shared/components/Link'
import Page from 'shared/components/Page'
import Text from 'shared/components/Text'

import dataSelectors from 'shared/selectors/dataSelectors'
import { loadStagingHistory } from 'shared/actions'
import RootState from 'shared/models/RootState'

import diversityPaths from '../../diversityPaths'
import { SpendInformationText } from './UploadSpendDetail/SpendInformationText'

import ConfirmDeleteDialog from './ConfirmDeleteDialog'
import ReviewAggregateLoad from './ReviewAggregateLoad'
import ReviewSpendItems from './ReviewSpendItems'
import ViewLoadHeader from './ViewLoadHeader'

const translation = defineMessages({
  file: {
    id: 'ViewDownloadDetail.File',
    defaultMessage: 'File'
  },
  aggregate: {
    id: 'ViewDownloadDetail.Aggregate',
    defaultMessage: 'Aggregate'
  },
  direct: {
    id: 'ViewDownloadDetail.Direct',
    defaultMessage: 'Direct'
  },
  indirect: {
    id: 'ViewDownloadDetail.Indirect',
    defaultMessage: 'Indirect'
  },
  fullSpend: {
    id: 'ViewDownloadDetail.FullSpend',
    defaultMessage: 'Full spend'
  }
})

const ViewLoadDetail = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const intl = useIntl()

  useEffect(() => {
    dispatch(loadStagingHistory({ listType: 'supplierTier2Load' }))
  }, [dispatch])

  const spendHistory = useSelector((state: RootState) =>
    dataSelectors.getStagingById(state, params.stagingId)
  )?.toJS()
  const buyers = spendHistory?.buyers.map(buyers => buyers.buyerName).join(', ')

  const [confirmRemoveStaging, setConfirmRemoveStaging] = useState<string>('')
  const [buyersImpactedByDelete, setBuyersImpactedByDelete] = useState<
    Array<{ buyerName: string; buyerId: string }>
  >([])

  return (
    <Page
      title={
        <FormattedMessage
          id='UploadSpendDetail.UploadSpendInformation'
          defaultMessage='Upload Spend Information'
        />
      }
    >
      <nav>
        <Link
          to={`${diversityPaths.diversityLoad}?show=${spendHistory?.year}-${spendHistory?.quarter}`}
          className='f7'
        >
          &lsaquo;{' '}
          <FormattedMessage
            id='UploadSpendDetail.BackToFileHistory'
            defaultMessage='Back to file history'
          />
        </Link>
      </nav>
      <h2 className='f5 fw3'>
        <FormattedMessage
          id='UploadSpendDetail.Title1'
          defaultMessage='Add your supplier spend information'
        />
      </h2>

      {spendHistory && <SpendInformationText />}

      {spendHistory && (
        <ViewLoadHeader
          buyers={buyers}
          year={spendHistory.year}
          quarter={spendHistory.quarter}
          dataType={
            translation[spendHistory.dataType]
              ? intl.formatMessage(translation[spendHistory.dataType])
              : capitalize(spendHistory.dataType)
          }
          spendType={
            translation[spendHistory.spendType]
              ? intl.formatMessage(translation[spendHistory.spendType])
              : capitalize(spendHistory.spendType)
          }
        />
      )}
      {spendHistory && spendHistory.dataType === 'file' && (
        <ReviewSpendItems
          stagingId={params.stagingId}
          isEditable={spendHistory.status !== 'published'}
          entity='stagingRow'
          label={
            spendHistory
              ? `${
                  spendHistory.clientFileName
                } | Uploaded on: ${intl.formatDate(spendHistory.uploadDate, {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                })}`
              : ''
          }
          exportMapping={spendHistory.clientFileName}
        />
      )}
      {!spendHistory && (
        <Text>
          <FormattedMessage
            id='ViewLoadDetail.Error'
            defaultMessage='Your spend information could not be loaded.'
          />
        </Text>
      )}
      {spendHistory && spendHistory.dataType === 'aggregate' && (
        <ReviewAggregateLoad
          year={spendHistory.year}
          quarter={spendHistory.quarter}
          subCategories={spendHistory.subCategories}
        />
      )}
      <div style={{ float: 'right' }}>
        <Button
          autoSize
          secondary
          disabled={
            spendHistory?.dataType === 'file' &&
            spendHistory?.status !== 'complete'
          }
          onClick={e => {
            e.stopPropagation()
            setConfirmRemoveStaging(spendHistory?.id)
            setBuyersImpactedByDelete(spendHistory?.buyers)
          }}
          className='mt3'
        >
          <FormattedMessage
            id='ViewLoadDetail.Delete'
            defaultMessage='Delete'
          />
        </Button>
      </div>
      <ConfirmDeleteDialog
        aggregate={spendHistory?.dataType === 'aggregate'}
        stagingId={confirmRemoveStaging}
        buyersImpactedByDelete={buyersImpactedByDelete}
        redirectToUploadPage={spendHistory?.dataType === 'aggregate'}
        redirectToHistoryPage={spendHistory?.dataType === 'file'}
        activeQuarter={`${spendHistory?.year}-${spendHistory?.quarter}`}
        onClose={() => {
          setConfirmRemoveStaging('')
          setBuyersImpactedByDelete([])
        }}
      />
    </Page>
  )
}
export default ViewLoadDetail
