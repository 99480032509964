import React, { Component } from 'react'
import Search from '../../../Search'
import { FormattedMessage } from 'react-intl'
import Page from 'shared/components/Page'

type Props = {
  disableSuggest: boolean
}
const title = <FormattedMessage id='App.Suppliers' defaultMessage='Suppliers' />

class SearchPage extends Component<Props> {
  render() {
    return (
      <Page title={title}>
        <div className='mw8 w-100 center border-box pt3-ns ph3 ph3-ns ph4-m ph0-ns cf'>
          <Search disableSuggest={this.props.disableSuggest} />
        </div>
      </Page>
    )
  }
}

export default SearchPage
