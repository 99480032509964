import React from 'react'
import { Route, Switch, Redirect } from 'react-router'
import GeneralPage from '../containers/GeneralPage'
import SpendPage from '../containers/SpendPage'
import paths from './paths'
import DiversityReport from '../containers/DiversityReport'
import ConsolidationPage from '../containers/ConsolidationPage'
import Tier2Diversity from '../containers/Tier2Diversity'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'
import InternalStatsContainer from 'shared/containers/InternalStatsContainer'
import SupplierOutreachContainer from '../containers/SupplierOutreach'
import ReportSnapshotList from '../components/ReportSnapshotList'
import CompareReports from '../components/ReportSnapshotList/CompareReports'
import ViewReport from '../components/ReportSnapshotList/ViewReport'
import SustainabilityReport from '../components/SustainabilityReport'

const createRoutes = ({
  userAccess
}: {
  userAccess: Partial<LicenseAccess>
}) => (
  <Switch>
    <Route exact path={paths.general} component={GeneralPage} />
    {userAccess.accessSupplierSpend && (
      <Route path={paths.spend} component={SpendPage} />
    )}
    {userAccess.accessSupplierSpend && userAccess.accessDiversityReport && (
      <Route exact path={paths.spendDiverse} component={DiversityReport} />
    )}
    <Route exact path={paths.consolidation} component={ConsolidationPage} />
    {userAccess.accessSupplierSpend && userAccess.accessTier2 && (
      <Route path={paths.tier2} component={Tier2Diversity} />
    )}
    <Route path={paths.internalStats}>
      <InternalStatsContainer type='client' />
    </Route>
    <Route exact path={paths.supplierOutreach}>
      <SupplierOutreachContainer />
    </Route>
    {userAccess.accessSupplierSpend && userAccess.accessDiversityReport && (
      <Route exact path={paths.reportSnapshot}>
        <ReportSnapshotList />
      </Route>
    )}
    {userAccess.accessSupplierSpend && userAccess.accessDiversityReport && (
      <Route path={paths.reportSnapshotView} exact>
        <ViewReport />
      </Route>
    )}
    {userAccess.accessSupplierSpend && userAccess.accessDiversityReport && (
      <Route path={paths.reportSnapshotCompare}>
        <CompareReports />
      </Route>
    )}
    {(userAccess.accessSustainabilitySpendReport ||
      userAccess.accessSustainabilitySurveyReport) && (
      <Route path={paths.sustainabilityReport}>
        <SustainabilityReport />
      </Route>
    )}
    <Redirect from={paths.basePath} to={paths.general} />
  </Switch>
)

export default createRoutes
