import React from 'react'
import RootState from 'shared/models/RootState'
import usersSelectors from 'shared/selectors/usersSelectors'
import { useSelector } from 'react-redux'
import Pill from 'shared/components/Pill'
import List from 'shared/components/List'
import ListItem from 'shared/components/List/ListItem'

type Props = {
  outreachRequestedContactId: string
  additionalRequestContactIds: Array<string>
  outreachType: string
  supplierName: string
}

const renderAdditionalEmails = additionalEmails => {
  return (
    <List>
      {additionalEmails &&
        additionalEmails.map(email => (
          <ListItem className='mv2' mini primaryText={email} key={email}/>
        ))}
    </List>
  )
}
const RequestSentToCell = ({
  outreachRequestedContactId,
  additionalRequestContactIds,
  outreachType,
  supplierName
}: Props) => {
  let emailList
  let label
  const email: string = useSelector((state: RootState) => {
    const user =
      state.getIn(['data', 'users', outreachRequestedContactId]) ||
      usersSelectors.getById(state, outreachRequestedContactId)
    return user?.get('email')
  })
  let additionalEmails: Array<String> = useSelector((state: RootState) =>
    usersSelectors.getUsersEmail(state, additionalRequestContactIds)
  )
  if (additionalEmails.length > 0) {
    emailList = renderAdditionalEmails(additionalEmails)
    label = '+' + additionalEmails.length
  }

  if (outreachType === 'ExpiringContract' && !outreachRequestedContactId) {
    return <div>{supplierName}</div>
  } else {
    return additionalEmails.length ? (
      <div>
        {email}{' '}
        <Pill
          pillStyle={'dib pv1 ph1 mr1 ml1 mt1'}
          label={label}
          title={emailList}
        />
      </div>
    ) : (
      <div>{email}</div>
    )
  }
}

export default RequestSentToCell
