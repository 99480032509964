import React, { useMemo } from 'react'
import AddTeamForm from './AddTeamForm'
import ContactCardContainer from 'shared/containers/ContactCardContainer'
import paths from 'supplier/routes/paths'
import { List, RecordOf } from 'immutable'
import Contact from '../../../../shared/models/Contact'
import Scrollable from 'shared/components/Scrollable'
import { useSelector, useDispatch } from 'react-redux'
import { editContact, updateContact } from '../../actions'
import RootState from 'shared/models/RootState'
import UpdateContactDialog from '../UpdateContactDialog'

type Props = {
  contacts: List<RecordOf<Contact>>
  onRemoveContact: (params: string) => void
  isEmailDomain: boolean
  isTealbot: boolean
  isAdmin: boolean
}

export const OurTeamSection = (props: Props) => {
  const dispatch = useDispatch()
  const editingContactId = useSelector((state: RootState) =>
    state.getIn(['supplier', 'company', 'editingContact'])
  )
  const isEditDialogOpen = !!editingContactId
  const { contacts, onRemoveContact, isEmailDomain, isTealbot, isAdmin } = props

  const editingContact = useMemo(() => {
    return editingContactId
      ? contacts?.find(contact => contact.get('user') === editingContactId)
      : undefined
  }, [contacts, editingContactId])

  const handleEditContact = (user: string) => {
    dispatch(editContact(user))
  }

  const handleUpdateContact = contact => {
    dispatch(updateContact(contact))
  }

  return (
    <div>
      {contacts && contacts.size > 0 && (
        <div className='ph3-5 pb3-5' role='list'>
          <Scrollable>
            {contacts.map(contact => (
              <ContactCardContainer
                baseUrl={paths.basePath}
                key={contact.get('user')}
                userId={contact.get('user')}
                contact={contact}
                onRemove={() => onRemoveContact(contact.get('user'))}
                onEdit={() => handleEditContact(contact.get('user'))}
                isEditable={isTealbot || isAdmin}
              />
            ))}
          </Scrollable>
        </div>
      )}
      {(!isEmailDomain || (contacts && contacts.size === 0) || isTealbot) && (
        <AddTeamForm
          exactMatch={isEmailDomain && !isTealbot}
          isTealbot={isTealbot}
        />
      )}
      {editingContact && (
        <UpdateContactDialog
          contact={editingContact}
          open={isEditDialogOpen}
          onClose={() => handleEditContact('')}
          onSubmit={handleUpdateContact}
        />
      )}
    </div>
  )
}

export default OurTeamSection
