import React, { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import BusinessIcon from '@material-ui/icons/Business'
import Skeleton from '@material-ui/lab/Skeleton'
import numberFormat from 'shared/utils/numberFormat'
import Tooltip from 'shared/components/Tooltip'

type Props = {
  isLoading?: boolean
  label: ReactElement<FormattedMessage>
  colorClass?: string
  value: number
  count: number
  htmlColor?: string
}

const TotalBox = (props: Props) => {
  const htmlColor = props.htmlColor ? props.htmlColor : ''
  const colorClass = props.colorClass ? props.colorClass : ''
  return (
    <>
      {props.isLoading ? (
        <Skeleton animation='wave' height={23} />
      ) : (
        <div
          className={`${colorClass} f4 fw6 tl`}
          style={{ color: `${htmlColor}` }}
        >
          {`$${numberFormat(props.value || 0, 0)}`}
        </div>
      )}
      <div
        className={`${colorClass} f7 flex justify-between items-center`}
        style={{ color: `${htmlColor}` }}
      >
        {props.label}
        {!props.isLoading && (
          <div className='flex items-center'>
            <Tooltip
              title={
                <FormattedMessage
                  id='DiversityReportSummary.NumSuppliers'
                  defaultMessage='Number of Suppliers: {count}'
                  values={{ count: numberFormat(props.count) }}
                />
              }
            >
              <BusinessIcon
                aria-label='Count'
                className={colorClass}
                style={{ color: `${htmlColor}` }}
                fontSize='small'
                tabIndex={-1}
              />
            </Tooltip>
            {numberFormat(props.count)}
          </div>
        )}
      </div>
    </>
  )
}

export default TotalBox