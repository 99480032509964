import React, { useEffect, useState } from 'react'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import InsightTitle from 'shared/components/InsightTitle'
import SustainabilitySurveyNav from './SustainabilitySurveyNav'
import { Switch, Route, Redirect } from 'react-router-dom'
import paths from '../../routes/paths'
import HealthSafetyReport from './HealthSafetyReport'
import EnvironmentReport from './EnvironmentReport'
import DiversityReport from './DiversityReport'
import GovernanceReport from './GovernanceReport'
import PrivacyReport from './PrivacyReport'
import Overview from './Overview'
import SupplierRequests from './SupplierRequests'
import { FormattedMessage } from 'react-intl'
import Page from 'shared/components/Page'
import { useDispatch, useSelector } from 'react-redux'
import { loadSustainabilityReportOverview } from 'buyer/Insights/store/actions'
import insightsSelectors from 'buyer/Insights/store/insightsSelectors'
import Loading from 'shared/components/Loading'
import analytics from 'shared/utils/analytics'
import moment from 'moment'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

const SustainabilityOverview = () => {
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const dispatch = useDispatch()
  const [mountTime] = useState(moment().toDate().getTime())
  useEffect(() => {
    dispatch(loadSustainabilityReportOverview())
    return () => {
      analytics.track('Sustainability Survey Summary Viewed', {
        duration: Number((moment().diff(mountTime) / 1000).toFixed(2)),
        orgUnitId,
        orgUnitName,
      })
    }
  }, [dispatch, mountTime, orgUnitId, orgUnitName])

  const isLoading = useSelector(insightsSelectors.isLoadingSustainabilityReport)

  if (isLoading) {
    return <Loading />
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='SustainabilityOverview.SustainabilitySurveyOverview'
          defaultMessage='Sustainability Survey Overview'
        />
      }
    >
      <ScrollToPosition />
      <Overview />

      <SupplierRequests />

      <InsightTitle>
        <FormattedMessage
          id='SustainabilityOverview.SustainabilitySurveyReport'
          defaultMessage='Sustainability Survey Report'
        />
      </InsightTitle>
      <SustainabilitySurveyNav />
      <Switch>
        <Route
          path={paths.sustainabilityReportHealthSafety}
          component={HealthSafetyReport}
        />
        <Route
          path={paths.sustainabilityReportEnvironment}
          component={EnvironmentReport}
        />
        <Route
          path={paths.sustainabilityReportDiversity}
          component={DiversityReport}
        />
        <Route
          path={paths.sustainabilityReportGovernance}
          component={GovernanceReport}
        />
        <Route
          path={paths.sustainabilityReportPrivacy}
          component={PrivacyReport}
        />
        <Redirect to={paths.sustainabilityReportHealthSafety} />
      </Switch>
    </Page>
  )
}

export default SustainabilityOverview
