import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Button from 'shared/components/Button'
import Label from 'shared/components/Label'
import analytics from 'shared/utils/analytics'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

const BuyerRequestAccess = () => {
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const handleClick = () => {
    window.open('https://invite.ecovadis.com/en/tealbook', '_blank')
    analytics.track('EcoVadis Account Requested', { orgUnitId, orgUnitName })
  }

  return (
    <div className='flex flex-column items-center pb5'>
      <Label className='ns-mb5 mb4'>
        <FormattedMessage
          id='BuyerRequestAccess.EncourageEcovadisSignUp'
          defaultMessage='You do not have an EcoVadis Integration Account'
        />
      </Label>
      <Button
        label={
          <FormattedMessage
            id='BuyerRequestAccess.requestEcovadisAccount'
            defaultMessage='Request EcoVadis Account Access'
          />
        }
        onClick={handleClick}
      />
    </div>
  )
}

export default BuyerRequestAccess
