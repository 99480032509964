import { List, Record, RecordOf, fromJS } from 'immutable'
import * as actionTypes from '../actionTypes'
import { requestSuccess, requestFailure } from 'shared/actionTypes'
import UploadedFile from 'shared/models/UploadedFile'
import {
  ADD_OUTREACH_CONTACT,
  CANCEL_ADDING_OUTREACH_CONTACT,
  LOAD_SUPPLIER_PROFILE
} from 'buyer/SupplierProfile/actionTypes'

export type Contact = {
  userId: string
  firstName: string
  lastName: string
  status:
    | 'Registered'
    | 'Normal'
    | 'Expired'
    | 'Blocked'
    | 'Inactive'
    | 'No e-mail'
    | 'Blocked failed login'
  sustainabilityContact?: boolean
  email: string
  title?: string
  profilePicture?: UploadedFile
  profilePictureUrl?: string
  myCompany?: boolean
  snovContact?: boolean
}

export type BulkSupplier = {
  supplier: {
    orgUnitId: string
    name: string
  }
  contacts: Array<Contact>
  ytdSpend?: number
}

export const BulkSupplierRecord = Record<BulkSupplier>({
  supplier: {
    orgUnitId: '',
    name: ''
  },
  contacts: [],
  ytdSpend: undefined
})

export type NewUser = {
  orgUnit: string
  userId: string
}

export type BulkActions = {
  loading: boolean
  suppliers?: List<RecordOf<BulkSupplier>>
  creatingOutreach: boolean
  supplierProfileLoadDone?: boolean
  newUser?: NewUser
}

const defaultState: RecordOf<BulkActions> = fromJS({
  loading: false,
  suppliers: List([]),
  creatingOutreach: false,
  supplierProfileLoadDone: false
})

const bulkActionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_BULK_ESG_SUPPLIERS:
      return state.set('loading', true)

    case requestFailure(actionTypes.GET_BULK_ESG_SUPPLIERS):
      return state.set('loading', false)

    case requestSuccess(actionTypes.GET_BULK_ESG_SUPPLIERS):
      return state
        .set('loading', false)
        .set(
          'suppliers',
          List(action.payload.map(item => new BulkSupplierRecord(item)))
        )
    case actionTypes.CREATE_BULK_OUTREACH:
      return state.set('creatingOutreach', true)

    case requestSuccess(actionTypes.CREATE_BULK_OUTREACH):
      return state
        .set('creatingOutreach', false)
        .update('suppliers', requests => {
          return requests?.filter(({ supplier }) => {
            return !action.payload.bulkRequests.find(
              ({ id }) => id === supplier.orgUnitId
            )
          })
        })

    case requestSuccess(ADD_OUTREACH_CONTACT):
      return action.payload.newUser
        ? state
            .update('suppliers', requests => {
              const index = requests?.findIndex(
                x => x.supplier.orgUnitId === action.payload.newUser.orgUnitId
              )
              return index !== undefined && index >= 0
                ? requests?.update(index, request =>
                    request.update('contacts', contacts => {
                      contacts.unshift({
                        userId: action.payload.userId,
                        firstName: action.payload.newUser.firstName,
                        lastName: action.payload.newUser.lastName,
                        status: action.payload.newUser.status,
                        sustainabilityContact:
                          action.payload.newUser.sustainabilityContact,
                        email: action.payload.newUser.email,
                        title: action.payload.newUser.title,
                        profilePictureUrl:
                          action.payload.newUser.profilePictureUrl,
                        myCompany: action.payload.newUser.myCompany
                      })
                      return contacts
                    })
                  )
                : requests
            })
            .set('newUser', {
              orgUnit: action.payload.newUser.orgUnitId,
              userId: action.payload.newUser.id
            })
        : state
    case requestSuccess(LOAD_SUPPLIER_PROFILE):
      return state.set('supplierProfileLoadDone', true)
    case CANCEL_ADDING_OUTREACH_CONTACT:
      return state.set('newUser', undefined)
    default:
      return state
  }
}

export default bulkActionsReducer
