import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { logout } from '../../actions'
import UserNav from '../../components/UserNav'
import sessionSelectors from '../../selectors/sessionSelectors'

type Props = {
  isTealbot: boolean
  logout: typeof logout
  hideMyAccount?: boolean
  profilePictureUrl: string
  firstName: string
  lastName: string
}

export const UserNavContainer: FunctionComponent<Props> = props => {
  const {
    profilePictureUrl,
    children,
    hideMyAccount,
    firstName,
    lastName
  } = props

  const handleLogout = () => {
    const { isTealbot, logout } = props

    logout({
      isTealbot
    })
  }

  return (
    <UserNav
      profilePictureUrl={profilePictureUrl}
      logout={handleLogout}
      hideMyAccount={hideMyAccount}
      firstName={firstName}
      lastName={lastName}
    >
      {children}
    </UserNav>
  )
}

export default connect(
  state => {
    return {
      profilePictureUrl: sessionSelectors
        .getUser(state)
        .get('profilePictureUrl'),
      isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
      firstName: sessionSelectors.getUser(state).get('firstName'),
      lastName: sessionSelectors.getUser(state).get('lastName')
    }
  },
  {
    logout
  }
)(UserNavContainer)
