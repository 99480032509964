import React, { Component } from 'react'

const styles = {
  label: 'db pt3 mb1 f7 fw6',
  noPaddingLabel: 'db mb1 f7 fw6'
}

/**
 * Creates an html label with addition styling for an html element passed through props
 */
type Props = {
  /** Indicates the id or name of the element you want to create a label for */
  htmlFor?: string
  className?: string
  required?: boolean
  noPadding?: boolean
  role?: string
}

class Label extends Component<Props> {
  render() {
    const { className, required, noPadding, htmlFor, ...rest } = this.props
    return (
      <label
        htmlFor={htmlFor}
        className={
          className || (noPadding ? styles.noPaddingLabel : styles.label)
        }
        {...rest}
      >
        {this.props.children} {required && <span className='dark-red'>*</span>}
      </label>
    )
  }
}

export default Label
