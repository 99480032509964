import { call, select, put } from 'redux-saga/effects'
import supplier from 'shared/utils/api/supplier'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import tasks from 'shared/utils/api/tasks'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import companySelectors from '../../../shared/selectors/companySelectors'
import { updateData } from 'shared/actions'
import dataSelectors from 'shared/selectors/dataSelectors'

export function* addCustomerSaga(action) {
  const {
    orgUnitId,
    companyName,
    companyWebsite,
    contactEmail,
    contactName,
    ignoreMatchingOrgs
  } = action.payload

  // if adding a new customer need to validate the domains and email concierge about it
  if (!orgUnitId) {
    let matchingOrgs

    // try to get the customer by its domain
    if (companyWebsite) {
      matchingOrgs = yield call(supplier.getBuyerByDomain, companyWebsite)
    }

    // if couldn't find it by its domain try again with the contact email
    if (
      contactEmail &&
      !ignoreMatchingOrgs &&
      (!matchingOrgs || !matchingOrgs.found)
    ) {
      matchingOrgs = yield call(supplier.getBuyerByDomain, contactEmail)
    }

    // the customer must be confirmed if we found multiple matching orgs
    if (!ignoreMatchingOrgs && matchingOrgs && matchingOrgs.found) {
      return yield {
        matchingOrgs: matchingOrgs.names
      }
    }

    // if we didn't find anything or the user want to skip matching orgs
    // send an email to the concierge saying that we need to create a new buyer
    // and link a dummy org to the supplier
    if (ignoreMatchingOrgs || !matchingOrgs || !matchingOrgs.found) {
      const currentUser = yield select(sessionSelectors.getUser)
      const company = yield select(companySelectors.getCompanyHeaderInfo)

      yield call(tasks.sendConciergeRequest, {
        requestType: 'supplierAddingTheirClients',
        orgUnitId: currentUser.get('orgUnitId'),
        userId: currentUser.get('id'),
        user: `${currentUser.get('firstName')} ${currentUser.get('lastName')}`,
        name: company.name,
        domains: company.domains,
        companyName,
        companyWebsite,
        contactEmail,
        contactName
      })
    }
  }

  const supplierRelationships = yield select(
    dataSelectors.getByEntity,
    'supplierRelationships'
  )
  const existingSupplierRelationship =
    supplierRelationships &&
    supplierRelationships.find(
      item => item.get('buyer') && item.get('buyer') === orgUnitId
    )

  yield put(
    updateData({
      entity: 'supplierRelationships',
      record: {
        id:
          existingSupplierRelationship &&
          existingSupplierRelationship.get('id'),
        buyer: orgUnitId,
        buyerName: companyName,
        supplierCollaboration: {
          isClient: true,
          contactName,
          contactEmail
        }
      }
    })
  )

  return yield {}
}

export default createApiRequestSaga(addCustomerSaga, {
  dontResetForm: true
})
