import { call, put } from 'redux-saga/effects'
import storageManager from '../../utils/storageManager'
import auth from 'shared/utils/api/auth'
import user from 'shared/utils/api/user'
import { requestSuccess, requestFailure } from '../../actions'
import { LOGIN } from 'accounts/actionTypes'
import JwtDecode from 'jwt-decode'
import analytics from 'shared/utils/analytics'
import accountsPaths from 'accounts/routes/paths'

export default function* validateTokenSaga() {
  const accessToken = storageManager.getItem('accessToken')
  const refreshToken = storageManager.getItem('refreshToken')
  const location = window.location
  const currentPath = location.pathname

  // fail login if there are no tokens
  if (
    (!accessToken && !refreshToken) ||
    currentPath.startsWith(accountsPaths.sso)
  ) {
    yield put(requestFailure(LOGIN))
  } else {
    const newTokens = yield call(auth.refreshTokens, refreshToken)
    if (newTokens) {
      const { partnerConcierge, restriction } = JwtDecode(newTokens.access)
      const { userInfo, orgUnit } = yield call(user.get)
      let accessControl = 'full'
      if (restriction) {
        accessControl = restriction
      } else if (!!storageManager.getItem('punchout')) {
        accessControl = 'punchout'
      }

      analytics.identifyUser(userInfo, orgUnit)
      yield put(
        requestSuccess(LOGIN, {
          userInfo,
          orgUnit,
          partnerConcierge,
          accessControl
        })
      )
    } else {
      storageManager.removeItem('accessToken')
      storageManager.removeItem('refreshToken')
      storageManager.removeItem('punchout')
      yield put(requestFailure(LOGIN))
    }
  }
}
