import React from 'react'
import { List, RecordOf, OrderedMap } from 'immutable'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import { UploadHistoryTypeT2 } from './UploadSpendDetail/UploadSpendDetail'
import { ListItem } from '@material-ui/core'
import { removeSpend } from 'shared/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import paths from '../../diversityPaths'
import diversityPaths from '../../diversityPaths'

type Props = {
  aggregate?: boolean
  stagingId: string
  buyersImpactedByDelete: Array<{ buyerName: string; buyerId: string }>
  uploadHistory?: OrderedMap<string, List<RecordOf<UploadHistoryTypeT2>>>
  activeQuarter: string
  onClose: () => void
  redirectToUploadPage?: boolean
  redirectToHistoryPage?: boolean
}

export const ConfirmDeleteDialog = (props: Props) => {
  const {
    aggregate,
    stagingId,
    buyersImpactedByDelete,
    uploadHistory,
    activeQuarter,
    onClose,
    redirectToUploadPage,
    redirectToHistoryPage
  } = props
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <ConfirmationDialog
      open={!!stagingId}
      onClose={onClose}
      onConfirm={() => {
        dispatch(
          removeSpend({
            aggregate,
            stagingId,
            listType: 'supplierTier2Load',
            uploadHistory,
            activeQuarter,
            notifyAfterDelete: true,
            buyersImpacted: buyersImpactedByDelete?.map(buyer => buyer.buyerId)
          })
        )
        if (redirectToUploadPage) {
          history.push(paths.diversityLoadV2)
        } else if (redirectToHistoryPage) {
          history.push(`${diversityPaths.diversityLoad}?show=${activeQuarter}`)
        }
      }}
    >
      <Text>
        <FormattedMessage
          id='ConfirmDeleteDialog.ConfirmDeleteStaging1'
          defaultMessage='Are you sure you want to delete this information?'
        />
      </Text>
      <Text className='mt3'>
        <FormattedMessage
          id='ConfirmDeleteDialog.ConfirmDeleteImpact'
          defaultMessage='This will impact:'
        />
        {buyersImpactedByDelete?.map(buyer => (
          <ListItem style={{ display: 'list-item' }} key={stagingId}>
            {buyer.buyerName}
          </ListItem>
        ))}
      </Text>
      <Text className='mt3'>
        <FormattedMessage
          id='ConfirmDeleteDialog.ConfirmDeleteStaging2'
          defaultMessage='Their information will have to be re-uploaded.'
        />
      </Text>
    </ConfirmationDialog>
  )
}

export default ConfirmDeleteDialog
