import React, { ChangeEvent } from 'react'
import Select from 'shared/components/Select'
import Input from 'shared/components/Input'
import { FormattedMessage, useIntl } from 'react-intl'
import certificationCategories from 'shared/models/CertificationCategories'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

type SubCategoryRowProps = {
  subCategory: string
  subType?: string
  spend?: number
  onSubCategoryChange: (value: string) => void
  onSubTypeChange: (value: string) => void
  onSpendChange: (value?: string) => void
  isReadOnly?: boolean
  onRemove: () => void
}
const SubCategoryRow = (props: SubCategoryRowProps) => {
  const {
    isReadOnly,
    subCategory,
    subType = '',
    spend,
    onSubCategoryChange,
    onSubTypeChange,
    onSpendChange,
    onRemove
  } = props
  const intl = useIntl()
  return (
    <div className='flex justify-between items-center pb1 mb1'>
      <div className='flex items-center'>
        <label htmlFor='certification' className='visuallyhidden'>
          <FormattedMessage
            id='SubCategoryRow.Certification'
            defaultMessage={'Certification'}
          />
        </label>
        {!isReadOnly && (
          <IconButton onClick={onRemove} aria-label='Remove category'>
            <CloseIcon />
          </IconButton>
        )}
        <Select
          name='certification'
          disabled={isReadOnly}
          value={subCategory}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            onSubCategoryChange(e.currentTarget.value)
          }
        >
          <FormattedMessage
            id='SubCategoryRow.SubCategoryPlaceholder'
            defaultMessage='Please select a Certification'
          >
            {message => (
              <option value='' disabled hidden>
                {message}
              </option>
            )}
          </FormattedMessage>
          {certificationCategories['diversity'] &&
            Object.entries(
              certificationCategories['diversity'].subCategories
            ).map(([key, subCategory]) => (
              <option key={key} value={key}>
                {intl.formatMessage(subCategory)}
              </option>
            ))}
        </Select>
        {!(isReadOnly && !subType) &&
          certificationCategories.diversity.subTypes &&
          certificationCategories.diversity.subTypes[subCategory] && (
            <span className='ml2'>
              <label htmlFor='subtype' className='visuallyhidden'>
                <FormattedMessage
                  id='SubCategoryRow.Subtype'
                  defaultMessage={'Subtype'}
                />
              </label>
              <Select
                name='subtype'
                disabled={isReadOnly}
                value={subType}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  onSubTypeChange(e.currentTarget.value)
                }
              >
                <FormattedMessage
                  id='SubCategoryRow.SelectSubtypePlaceholderOptional'
                  defaultMessage='Please select a Subtype if applicable'
                >
                  {message => (
                    <option value='' disabled hidden>
                      {message}
                    </option>
                  )}
                </FormattedMessage>
                {Object.entries(
                  certificationCategories.diversity.subTypes[subCategory]
                )
                  .filter(subType => !subType.includes('other'))
                  .map(([key, subType]) => (
                    <option key={key} value={key}>
                      {intl.formatMessage(subType)}
                    </option>
                  ))}
              </Select>
            </span>
          )}
      </div>
      <div className='w4'>
        <label htmlFor='spend' className='visuallyhidden'>
          <FormattedMessage
            id='SubCategoryRow.Spend'
            defaultMessage={'Spend'}
          />
        </label>
        <FormattedMessage
          id='SubCategoryRow.SpendInUSD'
          defaultMessage='Spend in USD'
        >
          {placeholder => (
            <Input
              name='spend'
              disabled={isReadOnly}
              type='number'
              value={spend}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onSpendChange(e.currentTarget.value || undefined)
              }
              placeholder={placeholder?.toString()}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  )
}

export default SubCategoryRow
