import React, { useEffect, useState, useMemo, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import { List, RecordOf } from 'immutable'
import { createSelector } from 'reselect'
import Label from 'shared/components/Label'
import Table from 'shared/components/Table'
import { Column, TableCellProps } from 'react-virtualized'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'
import { loadData } from 'shared/actions'
import { remapSupplier, openRemapDialog } from 'shared/actions'
import fileToUrl from 'shared/utils/data/fileToUrl'
import OrgLogo from 'shared/components/OrgLogo'
import EditButton from 'shared/components/EditProfileButton'
import RemapSpendItemDialog from './RemapSpendItemDialog'
import Text from 'shared/components/Text'
import LinearProgress from '@material-ui/core/LinearProgress'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Tooltip from 'shared/components/Tooltip'
import Select from 'shared/components/Select'
import Button from 'shared/components/Button'
import api from 'shared/utils/api'
import apiRoutes from 'shared/routes/apiRoutes'
import FileSaver from 'file-saver'
import { notify } from 'shared/actions'

const unmappedSupplierID = 'OrgUnit-5157071787917312-5742743685693440'
const normalizeDomain = (domain: string) =>
  domain && domain.replace('www.', '').replace('missing.link', '')

export const parseStagingRow = ({
  errors = [],
  supplier,
  matches,
  ...rest
}) => {
  const {
    logoFile,
    supplier: innerSupplier = {}, // this is used on the response from remapping API, because of slightly different structure
    domains = [],
    ...restSupplier
  } = supplier || {}
  const status = errors.length
    ? 'error'
    : supplier.supplierId === unmappedSupplierID ||
      (!supplier.supplierId && !supplier.id)
    ? 'notFound'
    : 'found'

  return {
    logoUrl: fileToUrl(logoFile || innerSupplier.logo),
    domains: domains.map(normalizeDomain),
    ...restSupplier,
    status,
    errors,
    matches: matches?.map(({ domain, ...restMatch }) => ({
      domain: normalizeDomain(domain),
      ...restMatch
    })),
    ...rest
  }
}

const parseStagingMapping = mapping => {
  return mapping.map(parseStagingRow)
}

const createParser = id => mapping => ({ [id]: parseStagingMapping(mapping) })

export type SpendLineItemType = {
  id: string
  internalSupplierId: string
  name?: string
  domains?: List<string>
  logoUrl?: string
  websiteUrl?: string
  totalAmount: number
  status: string
  errors?: List<string>
  header: RecordOf<{
    name: string
    domains?: List<string>
    internalSupplierId: List<string>
    address?: string
    email?: string
    phone?: string
  }>
  matches: List<
    RecordOf<{
      domains: List<string>
      name: string
      foundSource: string
      confidence: number
      matchedOn: string
    }>
  >
}

type Props = {
  spendData: List<RecordOf<SpendLineItemType>>
  label: string
  isLoading: boolean
  loadData: typeof loadData
  remapSupplier: typeof remapSupplier
  openRemapDialog: () => void
  exportMapping?: string
  suggestion?: boolean
} & ContainerProps

export const ReviewSpendItems = (props: Props) => {
  const {
    spendData,
    stagingId,
    isLoading,
    isEditable,
    loadData,
    remapSupplier,
    openRemapDialog,
    label,
    entity,
    exportMapping,
    suggestion
  } = props
  const [sortBy, setSortBy] = useState<
    'name' | 'status' | 'internalSupplierId' | 'totalAmount'
  >('totalAmount')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')
  const [showStatus, setShowStatus] = useState<
    'all' | 'error' | 'found' | 'notFound'
  >('all')
  const [remapStagingRow, setRemapStagingRow] = useState<
    RecordOf<SpendLineItemType> | undefined
  >(undefined)
  const [exportStagingPressed, setExportStagingPressed] = useState<boolean>(
    false
  )

  const handleStagingDownload = () => {
    api
      .get(
        `${apiRoutes.loader}/util/stagingDownload/${stagingId}/supplierTier2Load`
      )
      .then(response => {
        FileSaver.saveAs(
          response.url,
          `Review Mapping-${exportMapping?.split('.')[0]}`
        )
        setExportStagingPressed(false)
      })
      .catch(err => {
        setExportStagingPressed(false)
        notify({
          message: err.message
        })
      })
  }

  useEffect(() => {
    if (stagingId && entity) {
      const query = `staging/rows/${stagingId}`

      loadData({
        entity: entity,
        query,
        resultParser: createParser(stagingId)
      })
    }
  }, [stagingId, entity, loadData])

  const handleRemap = payload => {
    remapSupplier({
      name: remapStagingRow?.getIn(['header', 'name']),
      ...payload
    })
  }

  const filteredSpend = useMemo(() => {
    const spend =
      showStatus === 'all'
        ? spendData?.filter(
            item => item.getIn(['header', 'name']) !== 'Unmapped Suppliers'
          ) // removing Unmapped Suppliers from tier 1 copy
        : spendData?.filter(
            item =>
              item.get('status') === showStatus &&
              item.getIn(['header', 'name']) !== 'Unmapped Suppliers'
          ) // removing Unmapped Suppliers from tier 1 copy
    const sortSpend = spend?.sort((item1, item2) => {
      let valueA =
        (sortBy === 'name'
          ? item1.get('status') === 'found'
            ? item1.get('name')
            : item1.getIn(['header', 'name'])
          : item1.get(sortBy)) || ''
      let valueB =
        (sortBy === 'name'
          ? item2.get('status') === 'found'
            ? item2.get('name')
            : item2.getIn(['header', 'name'])
          : item2.get(sortBy)) || ''

      valueA = typeof valueA === 'string' ? valueA.toLocaleLowerCase() : valueA
      valueB = typeof valueB === 'string' ? valueB.toLocaleLowerCase() : valueB

      if (sortDirection === 'ASC') {
        return valueA < valueB ? -1 : 1
      } else {
        return valueA > valueB ? -1 : 1
      }
    })
    return sortSpend
  }, [showStatus, sortBy, sortDirection, spendData])

  const handleSortChange = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const handleShowChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const status = e.currentTarget.value as
      | 'all'
      | 'error'
      | 'found'
      | 'notFound'
    setShowStatus(status)
  }

  return (
    <>
      <div className='mt3'>
        {!suggestion && (
          <h5 className='db mb3 f7 fw6'>
            <FormattedMessage
              id='ReviewMapping.Title'
              defaultMessage='Review Mapping'
            />
          </h5>
        )}

        {isEditable && (
          <Text className='mb3'>
            <FormattedMessage
              id='ReviewMapping.Description'
              defaultMessage='Click the pencil icon to edit <link>supplier mapping</link>.'
              values={{
                link: link => (
                  <ExternalLink href='https://tealbookhelp.zendesk.com/hc/en-us/articles/8713583024276'>
                    {link}
                  </ExternalLink>
                )
              }}
            />
          </Text>
        )}
        {!suggestion && (
          <div className='flex justify-between items-center'>
            <Label className='db mb1 f7 fw6 flex-auto'>
              <span className='f7 fw4 mid-gray'>{label}</span>
            </Label>

            {isLoading && (
              <div className='w3 mr2'>
                <LinearProgress aria-label='Loading the Review Mapping table' />
              </div>
            )}

            {exportMapping && (
              <Button
                className='ml2 mr3'
                autoSize
                onClick={() => {
                  setExportStagingPressed(true)
                  handleStagingDownload()
                }}
                disabled={exportStagingPressed}
              >
                {!exportStagingPressed ? (
                  <FormattedMessage
                    id='LoadPage.Export'
                    defaultMessage='Export'
                  />
                ) : (
                  <FormattedMessage
                    id='LoadPage.Exporting1'
                    defaultMessage='Exporting'
                  />
                )}
              </Button>
            )}
            <div>
              <Label className='dib mb1 f7 fw6' htmlFor='reviewspenditems'>
                <FormattedMessage
                  id='ReviewSpendItems.ShowOnly'
                  defaultMessage='Show'
                />
                :
              </Label>
              &nbsp;
              <Select
                value={showStatus}
                onChange={handleShowChange}
                name='reviewspenditems'
              >
                <FormattedMessage
                  id='ReviewSpendItems.All'
                  defaultMessage='All'
                >
                  {message => <option value='all'>{message}</option>}
                </FormattedMessage>
                <FormattedMessage
                  id='ReviewSpendItems.Found'
                  defaultMessage='Found'
                >
                  {message => <option value='found'>{message}</option>}
                </FormattedMessage>
                <FormattedMessage
                  id='ReviewSpendItems.NotFound'
                  defaultMessage='Not Found'
                >
                  {message => <option value='notFound'>{message}</option>}
                </FormattedMessage>
                <FormattedMessage
                  id='ReviewSpendItems.Error'
                  defaultMessage='Error'
                >
                  {message => <option value='error'>{message}</option>}
                </FormattedMessage>
              </Select>
            </div>
          </div>
        )}
        <div className='bg-white br1 ba b--black-10 overflow-hidden mt2'>
          {filteredSpend && filteredSpend.size > 0 ? (
            <Table
              rowGetter={({ index }) => filteredSpend.get(index)}
              rowCount={filteredSpend.size}
              sort={handleSortChange}
              sortDirection={sortDirection}
              sortBy={sortBy}
            >
              {isEditable && (
                <Column
                  dataKey='id'
                  disableSort
                  width={40}
                  cellRenderer={({ cellData, rowData }: TableCellProps) => {
                    return rowData.get('status') !== 'error' ? (
                      <EditButton
                        onClick={() => {
                          setRemapStagingRow(rowData)
                          openRemapDialog()
                        }}
                      />
                    ) : null
                  }}
                />
              )}
              <Column
                label={
                  <FormattedMessage
                    id='SupplierSpendPage.Name'
                    defaultMessage='Name'
                  />
                }
                dataKey='name'
                width={500}
                cellRenderer={({ cellData, rowData }: TableCellProps) => {
                  const status = rowData.get('status')
                  const name = rowData.get('name')
                  const domains = rowData.getIn(['domains'])
                  const logoUrl = rowData.get('logoUrl')
                  return status === 'found' ? (
                    <div className='flex items-center'>
                      <div className='w2 h2 mr2 flex items-center'>
                        <OrgLogo url={logoUrl} />
                      </div>
                      <div className='flex-auto'>
                        <div className='fw6'>{name}</div>
                        {domains &&
                          domains.size > 0 &&
                          domains.map((domain: string, index: number) => (
                            <span key={domain}>
                              {index > 0 && ', '}
                              <ExternalLink href={domain}>
                                {domain}
                              </ExternalLink>
                            </span>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div>{rowData.getIn(['header', 'name'])}</div>
                  )
                }}
              />
              <Column
                label={
                  <FormattedMessage
                    id='SupplierSpendPage.SupplierId'
                    defaultMessage='Supplier ID'
                  />
                }
                dataKey='internalSupplierId'
                width={200}
              />
              <Column
                label={
                  <FormattedMessage
                    id='SupplierSpendPage.Amount'
                    defaultMessage='Amount'
                  />
                }
                dataKey='totalAmount'
                width={150}
                headerClassName='tr'
                cellRenderer={({ cellData }: TableCellProps) => (
                  <div className='tr'>
                    {cellData.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </div>
                )}
              />
              <Column
                dataKey='status'
                width={100}
                label={
                  <FormattedMessage
                    id='SupplierSpendPage.Status'
                    defaultMessage='Status'
                  />
                }
                cellRenderer={({ cellData, rowData }: TableCellProps) => {
                  const errors = rowData.get('errors')
                  return cellData === 'error' ? (
                    <div className='dark-red'>
                      <FormattedMessage
                        id='SupplierSpendPage.Error'
                        defaultMessage='Error'
                      />
                      <Tooltip
                        title={
                          <div>
                            {errors?.map(error => {
                              return <div key={error}>{error}</div>
                            })}
                          </div>
                        }
                        classes={{ popper: 'mw5' }}
                      >
                        <ErrorOutlineIcon color='error' className='v-mid ml2' />
                      </Tooltip>
                    </div>
                  ) : cellData === 'notFound' ? (
                    <div className='text-gold'>
                      <FormattedMessage
                        id='SupplierSpendPage.NotFound'
                        defaultMessage='Not Found'
                      />
                    </div>
                  ) : (
                    <div className='dark-green'>
                      <FormattedMessage
                        id='SupplierSpendPage.Found'
                        defaultMessage='Found'
                      />
                    </div>
                  )
                }}
              />
            </Table>
          ) : (
            <div className='mv3'>
              <Text className='tc'>
                <FormattedMessage
                  id='SupplierSpendPage.NoData'
                  defaultMessage='No Data'
                />
              </Text>
            </div>
          )}
        </div>
      </div>
      <RemapSpendItemDialog
        spendLineItem={remapStagingRow}
        onRemap={handleRemap}
      />
    </>
  )
}

const getStagingSpend = createSelector(
  (state: RootState, stagingId: string, entity: string) =>
    state.getIn(['data', entity, stagingId]),
  (spendLines: List<RecordOf<SpendLineItemType>>) => {
    return (
      spendLines &&
      spendLines
        .filter(line => !!line.getIn(['header', 'name']))
        .sort((line1, line2) => {
          return line2.get('totalAmount') - line1.get('totalAmount')
        })
    )
  }
)

type ContainerProps = {
  stagingId: string
  isEditable?: boolean
  entity: 'stagingRow' | 'stagingMapped' | 'stagingUnmapped' | 'stagingError'
}

export default connect(
  (state: RootState, props: ContainerProps) => {
    return {
      isLoading: state.getIn(['data', 'isLoading', props.entity]),
      spendData: getStagingSpend(state, props.stagingId, props.entity)
    }
  },
  { loadData, remapSupplier, openRemapDialog }
)(ReviewSpendItems)
