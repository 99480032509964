import React, { Component, Fragment, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import postsSelectors from '../../selectors/postsSelectors'
import Popover from '@material-ui/core/Popover'
import Button from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import Scrollable from 'shared/components/Scrollable'
import Text from 'shared/components/Text'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List } from 'immutable'
import RootState from 'shared/models/RootState'

type Props = {
  communities: List<RecordOf<{ id: string; name: string }>>
  value?: { communityId?: string }
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export class SelectCommunity extends Component<Props> {
  inputField: any

  state = {
    open: false,
    anchorEl: null
  }

  componentDidMount() {
    if (this.inputField) {
      this.inputField.addEventListener('focus', function() {
        this.blur()
      })
    }
  }

  togglePopover = e => {
    this.setState({
      open: !this.state.open,
      anchorEl: e.currentTarget
    })
  }

  render() {
    const { communities, value = {}, onChange } = this.props
    const { open, anchorEl } = this.state

    return (
      <Fragment>
        <input
          ref={input => {
            this.inputField = input
          }}
          required
          value={Object.values(value).join(',') || (open ? '1' : '')}
          className='pa0 ma0 fr o-0'
          onChange={() => true}
          style={{
            width: '1px',
            border: 0
          }}
          aria-label='Select community'
        />
        {Object.values(value).length > 0 ? (
          <Fragment>
            <Text className='dib v-mid truncate mw4-5 mw5-ns'>
              {Object.values(value).join(', ')},&nbsp;
            </Text>
            <Button
              onClick={this.togglePopover}
              label={
                <FormattedMessage
                  id='SelectCommunity.ChangeLabel'
                  defaultMessage='Change...'
                />
              }
              secondary
              autoSize
            />
          </Fragment>
        ) : (
          <Button
            onClick={this.togglePopover}
            label={
              <FormattedMessage
                id='SelectCommunity.ChooseACommunityLabel'
                defaultMessage='Choose a community...'
              />
            }
            variant='text'
            autoSize
          />
        )}
        <Popover open={open} onClose={this.togglePopover} anchorEl={anchorEl}>
          <div className='pa3'>
            <Scrollable maxHeight={200}>
              {communities &&
                communities.map(comm => (
                  <div className='pa1' key={comm.get('id')}>
                    <Checkbox
                      value={comm.get('id')}
                      name={comm.get('name')}
                      label={comm.get('name')}
                      labelFontLight
                      checked={!!value[comm.get('id')]}
                      onChange={onChange}
                    />
                  </div>
                ))}
            </Scrollable>
          </div>
        </Popover>
      </Fragment>
    )
  }
}

export default connect((state: RootState) => ({
  communities: postsSelectors.getCommunities(state)
}))(SelectCommunity)
