import { put, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import auth from 'shared/utils/api/auth'
import user from '../../utils/api/user'
import { requestSuccess } from '../../actions'
import { LOGIN } from 'accounts/actionTypes'
import storageManager from 'shared/utils/storageManager'
import analytics from 'shared/utils/analytics'

export default function* logoutSaga(action) {
  const tealbotTokens = {
    access: storageManager.getItem('tealbotAccessToken'),
    refresh: storageManager.getItem('tealbotRefreshToken')
  }
  const tealbotUrl = storageManager.getItem('tealbotUrl') || ''

  yield call(auth.logout)

  analytics.logout()

  if (
    action.payload?.isTealbot &&
    tealbotTokens.access &&
    tealbotTokens.refresh
  ) {
    storageManager.setItem('accessToken', tealbotTokens.access)
    storageManager.setItem('refreshToken', tealbotTokens.refresh)
    try {
      const { userInfo, orgUnit } = yield call(user.get)
      analytics.identifyUser(userInfo, orgUnit)
      analytics.track('Signed In')
      yield put(push(tealbotUrl))
      yield put(requestSuccess(LOGIN, {userInfo, orgUnit}))
    } catch (err) {
      yield put(push('/'))
    }
  } else {
    yield put(push('/'))
  }
}
