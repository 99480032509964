import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { call, select } from 'redux-saga/effects'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import profileSelectors from '../../selectors/profileSelectors'
import users from 'shared/utils/api/users'
import cards from 'shared/utils/api/cards'
import FormError from 'shared/utils/FormError'

//1 email: users.invite api calls email method and sends optional message

export function* addContactSaga(action) {
  const {
    user,
    contactFor,
    phone,
    location,
    isPrivate,
    message,
    sendMessage,
    ignoreEmail,
    isCreatedbyEsgRequest
  } = action.payload.toJS()

  const currentSupplierId = yield select(profileSelectors.getDataField, 'id')
  const currentCard = yield select(
    cardsSelectors.getBySupplier,
    currentSupplierId
  )
  const cardId = currentCard && currentCard.get('id')
  let userId = user.id

  // validate that an email is there if it is a new user
  if (!userId && !user.email) {
    throw new FormError('Please select a user')
  }

  // need to create non-existing users
  let newUser
  if (!userId) {
    newUser = yield call(users.inviteUser, {
      user,
      message: sendMessage ? message : undefined,
      orgUnitId: currentSupplierId,
      ignoreEmail,
      isCreatedbyEsgRequest
    })
    userId = newUser.id
  }

  // create a card if is not there yet
  let newCard
  if (
    !currentCard ||
    currentCard.size === 0 ||
    currentCard.get('placeholderCard') ||
    currentCard.get('id').endsWith('-1')
  ) {
    newCard = yield call(cards.create, {
      supplier: currentSupplierId,
      contacts: [
        {
          user: userId,
          contactFor,
          phone,
          location,
          connected: true,
          isPrivate
        }
      ]
    })
  } else {
    // add the contact to the card
    const contacts = currentCard
      .setIn(['contacts', userId], {
        user: userId,
        contactFor,
        phone,
        location,
        connected: true,
        isPrivate
      })
      .get('contacts')
      .toList()
      .toJS()

    yield call(cards.update, cardId, {
      contacts
    })
  }

  return yield {
    newUser,
    userId,
    contactFor,
    phone,
    location,
    isPrivate,
    cardId,
    newCard
  }
}

export default createApiRequestSaga(addContactSaga)
