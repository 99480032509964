import { RecordOf, fromJS } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { CLOSE_CONGRATS_SURVEY_DIALOG } from '../../../Company/actionTypes'
import { requestSuccess } from 'shared/actionTypes'
import { List } from 'immutable'

export type SurveyResponse = {
  id: string
  type: string
  attestedBy: string
  attestedDate: Date
  sustainabilitySurveyCert?: RecordOf<AttachmentType>
}

export type ValidationType = {
  orgUnitId: string
  userId: string
  confirmed: boolean
  date: string
  timeStamp: string
  note?: string
  certificationNumber?: string
  validationExpires?: string
}

export type CertType = {
  category: string
  subCategory: string
  timeStamp: string // unique key reference to certification on supplier
  validations?: List<RecordOf<ValidationType>>
}

export type AttachmentType = {
  docType?: string
  bucketName: string
  description: string
  fileName: string
  validations?: List<RecordOf<ValidationType>>
}

export type SurveyResponseDetail = {
  id: string
  pageId:
    | 'healthSafety'
    | 'diversityInclusion'
    | 'environment'
    | 'governance'
    | 'privacy'
  parentQuestionId: string
  questionId: string
  answerType: string
  reasonType: string
  answer: string
  reason: string
  note: string
  attachmentType: 'cert' | 'doc'
  attachments: List<RecordOf<AttachmentType> | RecordOf<CertType>>
  surveyResponseId: string
  answerSources: List<'customText' | 'metric'>
}

const defaultState = fromJS({
  byId: {},
  responseDetails: {},
  loading: false,
  uploading: false,
  editing: false,
  openCollaborateDialog: false,
  openCongratsDialog: false,
  surveyInvitations: []
})

export const surveyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ALL_SURVEY:
      return state.set('loading', true)

    case requestSuccess(actionTypes.LOAD_ALL_SURVEY):
      return state.set('byId', fromJS(action.payload)).set('loading', false)

    case requestSuccess(actionTypes.SAVE_SURVEY):
      return state
        .mergeIn(['byId'], fromJS({ [action.payload.id]: action.payload }))
        .set('editing', false)

    case requestSuccess(actionTypes.SUBMIT_SURVEY):
      return state
        .mergeIn(['byId'], fromJS({ [action.payload.id]: action.payload }))
        .set('editing', false)
        .set('openCongratsDialog', true)

    case requestSuccess(actionTypes.LOAD_ALL_SURVEY_INVITATIONS):
      return state.set('surveyInvitations', fromJS(action.payload))

    case CLOSE_CONGRATS_SURVEY_DIALOG:
      return state.set('openCongratsDialog', false)

    case requestSuccess(actionTypes.TAKE_ESG_SURVEY):
      return state
        .mergeIn(['byId'], fromJS({ [action.payload.id]: action.payload }))
        .set('responseDetails', fromJS({}))

    case requestSuccess(actionTypes.LOAD_SURVEY_RESPONSE):
      return state.set('responseDetails', fromJS(action.payload))

    case actionTypes.UPLOAD_SURVEY_ATTACHMENT:
      return state.set('uploading', true)

    case requestSuccess(actionTypes.UPLOAD_SURVEY_ATTACHMENT):
      const { surveyResponseDetailId, ...restDetail } = action.payload
      return state
        .mergeIn(
          ['responseDetails', surveyResponseDetailId],
          fromJS(restDetail)
        )
        .set('uploading', false)

    case requestSuccess(actionTypes.SAVE_SURVEY_RESPONSE):
      return state.mergeIn(
        ['responseDetails', action.payload.id],
        fromJS(action.payload)
      )
    case requestSuccess(actionTypes.REMOVE_SURVEY_ATTACHMENT):
      return state.updateIn(
        ['responseDetails', action.payload.surveyResponseDetailId],
        responseDetail => {
          let deleteIndex
          if (action.payload.attachmentType === 'doc') {
            deleteIndex = responseDetail
              .get('attachments')
              .findIndex(
                attachment =>
                  attachment.get('fileName') ===
                  action.payload.attachment.fileName
              )
          }
          if (action.payload.attachmentType === 'cert') {
            deleteIndex = responseDetail
              .get('attachments')
              .findIndex(
                attachment =>
                  attachment.get('timeStamp') ===
                  action.payload.attachment.timeStamp
              )
          }
          return responseDetail !== -1
            ? responseDetail.updateIn(['attachments'], attachments =>
                attachments.delete(deleteIndex)
              )
            : responseDetail
        }
      )
    case requestSuccess(actionTypes.EDIT_SURVEY):
      return state
        .setIn(
          ['byId', action.payload.response.id],
          fromJS(action.payload.response)
        )
        .set('responseDetails', fromJS(action.payload.responseDetails))
        .set('editing', true)

    case requestSuccess(actionTypes.REMOVE_SURVEY):
      return state
        .deleteIn(['byId', action.payload])
        .set('editing', false)
        .set('responseDetails', fromJS({}))

    case actionTypes.OPEN_COLLABORATE_INVITE_DIALOG:
      return state.set('openCollaborateDialog', true)

    case actionTypes.CLOSE_COLLABORATE_INVITE_DIALOG:
      return state.set('openCollaborateDialog', false)

    default:
      return state
  }
}

export default surveyReducer
