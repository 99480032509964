import React, { Component } from 'react'
import { TagCloud as ReactTagCloud } from 'react-tagcloud'
import Link from 'shared/components/Link'

const styles = {
  margin: '5px 20px',
  verticalAlign: 'middle',
  display: 'inline-block',
  wordBreak: 'break-word'
}

type TagCloudProps = {
  tags: {
    [key: string]: number
  }
  colors: Array<string>
  colorOptions?: {}
  limit?: number
  minSize?: number
  maxSize?: number
  onClick?: (tag: Tag) => void
  linkParser?: (tag: string) => string
}

class TagCloud extends Component<TagCloudProps> {
  customerRenderer(tag, size, color) {
    const fontSize = size + 'px'
    const key = tag.key || tag.value
    const style = Object.assign({}, styles, { color, fontSize })

    return tag.link ? (
      <span role='listitem' key={key}>
        <Link
          to={tag.link}
          className='tag-cloud-tag pointer grow-large'
          style={style}
        >
          {tag.value}
        </Link>
      </span>
    ) : (
      <span
        role='listitem'
        className='tag-cloud-tag pointer grow-large'
        style={style}
        key={key}
      >
        {tag.value}
      </span>
    )
  }

  render() {
    const {
      tags,
      minSize = 14,
      maxSize = 50,
      colorOptions,
      limit,
      colors,
      onClick,
      linkParser,
      ...rest
    } = this.props

    const tagKeys = Object.keys(tags)
    const data = tagKeys
      .reduce<Tag[]>((result, tag, index) => {
        if (colors) {
          const random = tagKeys.length > colors.length
          result.push({
            value: tag,
            count: tags[tag],
            link: linkParser ? linkParser(tag) : '',
            color:
              colors[random ? Math.floor(Math.random() * colors.length) : index]
          })
        } else {
          result.push({
            value: tag,
            link: linkParser ? linkParser(tag) : '',
            count: tags[tag]
          })
        }

        return result
      }, [])
      .sort((tagA, tagB) => {
        return tagB.count - tagA.count
      })

    return (
      <ReactTagCloud
        role='list'
        tags={limit ? data.slice(0, limit - 1) : data}
        minSize={minSize}
        maxSize={maxSize}
        colorOptions={colorOptions}
        disableRandomColor={colors && true}
        renderer={this.customerRenderer}
        onClick={onClick}
        {...rest}
      />
    )
  }
}

export type Tag = {
  value: string
  count: number
  color?: string
  link?: string
}

export default TagCloud
