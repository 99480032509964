import { call, select } from 'redux-saga/effects'
import suppliers from 'shared/utils/api/suppliers'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import mapValues from 'lodash.mapvalues'
import mapKeys from 'lodash.mapkeys'
import analytics from 'shared/utils/analytics'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

export function* loadSupplierProfileSaga(action) {
  const {
    supplier,
    communities = {},
    cards = {},
    users = {},
    tags = {},
    relationship = {},
    supplierRelationships = {},
    surveyResponse,
  } = yield call(suppliers.getById, action.payload, action.meta)
  const cardsIdsByUser = mapValues(
    mapKeys(cards, (card) => card.owner),
    (card) => card.id
  )
  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const orgUnitName = yield select(orgsSelectors.getCurrentUserOrgUnitName)

  analytics.track('Supplier Profile Viewed', {
    supplierName: supplier.name,
    supplierDomains: supplier.domains,
    supplierOrgUnitId: supplier.id,
    orgUnitId,
    orgUnitName,
  })

  return yield {
    supplier,
    communities,
    users,
    combinedTags: tags,
    cardsById: cards,
    cardsIdsByUser,
    relationship,
    supplierRelationships,
    surveyResponse,
  }
}

export default createApiRequestSaga(loadSupplierProfileSaga)
