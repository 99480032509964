import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import CompletedSuppliers from 'buyer/Insights/components/SustainabilityReport/CompletedSuppliers'
import { closeSuppliersByQuestionDialog } from 'buyer/Insights/store/actions'
import { List, RecordOf } from 'immutable'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'

export type CompletedSuppliersStatsDialogsProps = {
  suppliersStatsByQuestion: List<
    RecordOf<{
      id: string
      name: string
    }>
  >
  openQuestionsDialog: boolean
  closeSuppliersByQuestionDialog: () => void
  dialogTitle: ReactNode
  onChangeQuestionId: (questionId: string) => void
  onChangeDialogTitle: (dialogTitle: ReactNode) => void
}

const CompletedSuppliersStatsDialog = ({
  suppliersStatsByQuestion,
  openQuestionsDialog,
  closeSuppliersByQuestionDialog,
  dialogTitle,
  onChangeQuestionId,
  onChangeDialogTitle,
}: CompletedSuppliersStatsDialogsProps) => {

  const handleCloseDialog = () => {
    closeSuppliersByQuestionDialog()
    onChangeQuestionId('')
    onChangeDialogTitle(null)
  }

  return (
    <Dialog
      open={!!dialogTitle && openQuestionsDialog}
      onClose={handleCloseDialog}
      fullWidth
      aria-labelledby={'dialog displaying questions and responses by supplier'}
    >
      <DialogTitle onClose={handleCloseDialog}>{dialogTitle}</DialogTitle>
      <DialogContent>
        <CompletedSuppliers
          pageSize={50}
          completedSuppliers={suppliersStatsByQuestion}
          suppliersByQuestion
        />
      </DialogContent>
    </Dialog>
  )
}

export default connect(
  (state: RootState) => {
    return {
      suppliersStatsByQuestion: state.getIn([
        'buyer',
        'insights',
        'sustainabilityReport',
        'completedSuppliersByQuestion'
      ]),
      openQuestionsDialog: state.getIn([
        'buyer',
        'insights',
        'sustainabilityReport',
        'openQuestionsStatsDialog'
      ])
    }
  },
  { closeSuppliersByQuestionDialog }
)(CompletedSuppliersStatsDialog)
