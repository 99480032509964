import { defineMessages, useIntl, FormattedMessage } from 'react-intl'
import React from 'react'
import Text from 'shared/components/Text'

type Props = {
  fileLoadErrors: Array<string | { [key: string]: any }>
}

const LoadPageErrorParser = ({ fileLoadErrors }: Props) => {
  const messages = defineMessages({
    wrongFormat: {
      id: 'LoadPage.wrongFormat',
      defaultMessage:
        'The dates entered in {rows} are invalid. Please enter date in the format YYYY-MM-DD.'
    },
    wrongQuarter: {
      id: 'LoadPage.wrongQuarter',
      defaultMessage:
        'The date entered in {rows} are not in the selected quarter.'
    },
    missingRequiredData: {
      id: 'LoadPage.missingRequiredData1',
      defaultMessage:
        '{rows} is missing required data in "{columns}"  column. Please ensure that the required column fields are populated in the correct format, and try uploading again.'
    },
    missingDetails: {
      id: 'LoadPage.missingDetails',
      defaultMessage:
        'We could not find the Details tab in your spend file. Please ensure that your spend data is populated in the Details tab and try uploading again.'
    },
    missingColumns: {
      id: 'LoadPage.missingColumns1',
      defaultMessage:
        'Required data for "{columns}" is missing. Please ensure that the required column fields are populated in the correct format and try uploading again.'
    }
  })

  const intl = useIntl()
  const loadErrorCodes =
    fileLoadErrors[0] && typeof fileLoadErrors[0] === 'object'
      ? fileLoadErrors[0]
      : undefined
  const loadErrors =
    fileLoadErrors[0] && typeof fileLoadErrors[0] === 'string'
      ? fileLoadErrors[0]
      : undefined

  const errorString: Array<any> = []
  if (loadErrorCodes) {
    if (loadErrorCodes.missingDetailsTab) {
      errorString.push(intl.formatMessage(messages.missingDetails))
    }
    if (loadErrorCodes.missingRequiredData) {
      errorString.push(
        intl.formatMessage(messages.missingRequiredData, {
          rows:
            loadErrorCodes.missingRequiredData.rows?.length > 5
              ? 'Multiple rows'
              : 'Row(s) ' + loadErrorCodes.missingRequiredData.rows?.join(', '),
          columns: loadErrorCodes.missingRequiredData.missingData?.join(', ')
        })
      )
    }
    if (loadErrorCodes.missingColumns) {
      errorString.push(
        intl.formatMessage(messages.missingColumns, {
          columns: loadErrorCodes.missingColumns.join(', ')
        })
      )
    }
    if (loadErrorCodes.wrongFormat) {
      errorString.push(
        intl.formatMessage(messages.wrongFormat, {
          rows:
            loadErrorCodes.wrongFormat.rows.length > 5
              ? 'multiple rows'
              : 'row(s) ' + loadErrorCodes.wrongFormat.rows?.join(', ')
        })
      )
    }
    if (loadErrorCodes.wrongQuarterMsg) {
      errorString.push(
        intl.formatMessage(messages.wrongQuarter, {
          rows:
            loadErrorCodes.wrongQuarterMsg.rows?.length > 5
              ? 'multiple rows'
              : 'row(s) ' + loadErrorCodes.wrongQuarterMsg.rows?.join(', ')
        })
      )
    }
  }

  return (
    <span>
      <Text>
        <FormattedMessage
          id='LoadPage.ColumnFormatError'
          defaultMessage='Column format error:'
        />
      </Text>
      {errorString && (
        <ul className='red f7'>
          {errorString.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )}
      {loadErrors && (
        <ul className='red f7'>
          <li key={loadErrors}>{loadErrors}</li>
        </ul>
      )}
      <Text>
        <FormattedMessage
          id='LoadPage.ErrorReview'
          defaultMessage='Please check mandatory fields and/or check column formatting and try again.'
        />
      </Text>
    </span>
  )
}
export default LoadPageErrorParser
