import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const loadAllSurvey = createAction(actionTypes.LOAD_ALL_SURVEY)
export const loadSurveyById = createAction(actionTypes.LOAD_SURVEY_By_ID)
export const saveSurvey = createAction(actionTypes.SAVE_SURVEY)
export const removeSurvey = createAction(actionTypes.REMOVE_SURVEY)
export const loadSurveyResponse = createAction(actionTypes.LOAD_SURVEY_RESPONSE)
export const saveSurveyResponse = createAction(actionTypes.SAVE_SURVEY_RESPONSE)
export const uploadSurveyAttachment = createAction(
  actionTypes.UPLOAD_SURVEY_ATTACHMENT
)
export const removeSurveyAttachment = createAction(
  actionTypes.REMOVE_SURVEY_ATTACHMENT
)
export const submitSurvey = createAction(actionTypes.SUBMIT_SURVEY)
export const takeEsgSurvey = createAction(actionTypes.TAKE_ESG_SURVEY)
export const editSurvey = createAction(actionTypes.EDIT_SURVEY)
export const openCollaborateInviteDialog = createAction(
  actionTypes.OPEN_COLLABORATE_INVITE_DIALOG
)
export const closeCollaborateInviteDialog = createAction(
  actionTypes.CLOSE_COLLABORATE_INVITE_DIALOG
)
export const loadAllSurveyInvitations = createAction(
  actionTypes.LOAD_ALL_SURVEY_INVITATIONS
)
