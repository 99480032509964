import React, { Component, RefObject } from 'react'
import { connect } from 'react-redux'
import routingSelectors from 'shared/selectors/routingSelectors'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import MessageContainer from '../../containers/MessageContainer'
import { readMessage, openForwardSurveyRequestDialog } from 'shared/actions'
import buyerPaths from 'buyer/routes/paths'
import supplierPaths from 'supplier/routes/paths'
import parsePath from 'shared/utils/parsePath'
import Loading from 'shared/components/Loading'
import scrollTo from 'shared/utils/scrollTo'
import { List, RecordOf } from 'immutable'

type Props = {
  topicId: string
  messages: List<
    RecordOf<{
      id: string
      body: string
      createdDate: Date
      createdBy: string
      supplierProfileId: string
      shareRequestSelfCertifyLink?: boolean
    }>
  >
  currentUserId: string
  userToMessageId: string
  loadingConversation?: boolean
  isClientApp: boolean
  readMessage: (id: string) => void
  dialog?: boolean
  openForwardSurveyRequestDialog: () => void
}

export class ConversationWithUser extends Component<Props> {
  scrollToForm(ref: RefObject<HTMLDivElement>) {
    const dialog = document.querySelector<any>('#message-dialog-content')

    if (ref && ref.current) {
      scrollTo(dialog, ref.current.offsetTop - 150, 500)
    }
  }

  componentDidMount() {
    const { topicId, readMessage } = this.props
    if (topicId) {
      readMessage(topicId)
    }
  }

  componentDidUpdate(oldProps) {
    const { topicId, readMessage } = this.props
    if (topicId && oldProps.topicId !== topicId) {
      readMessage(topicId)
    }
  }

  handleForwardSurveyRequest = () => {
    this.props.openForwardSurveyRequestDialog()
  }

  render() {
    const {
      messages,
      currentUserId,
      loadingConversation,
      isClientApp
    } = this.props

    return (
      <div>
        {loadingConversation ? (
          <Loading />
        ) : (
          messages &&
          messages.map((message, index) => (
            <MessageContainer
              key={message.get('id')}
              body={message.get('body')}
              createdDate={message.get('createdDate')}
              mine={currentUserId === message.get('createdBy')}
              userId={message.get('createdBy')}
              supplierId={message.get('supplierProfileId')}
              shareRequestSelfCertifyLink={message.get(
                'shareRequestSelfCertifyLink'
              )}
              profileUrl={
                isClientApp
                  ? parsePath(buyerPaths.userprofile, {
                      userId: message.get('createdBy')
                    })
                  : parsePath(supplierPaths.userProfile, {
                      userId: message.get('createdBy')
                    })
              }
              callbackOnMount={
                index === messages.size - 1 ? this.scrollToForm : undefined
              }
              isMessageBeingReadByReceiver={
                currentUserId !== message.get('createdBy')
              }
              handleForwardSurveyRequest={this.handleForwardSurveyRequest}
            />
          ))
        )}
      </div>
    )
  }
}

export default connect(
  state => {
    const userToMessageId = messagesSelectors.getUserToMessage(state)
    const isClientApp = routingSelectors.getPathname(state).includes('client')
    return {
      currentUserId: sessionSelectors.getUserId(state),
      messages: messagesSelectors.getConversationWithUser(
        state,
        userToMessageId
      ),
      topicId: messagesSelectors.getTopicIdByUserId(state, userToMessageId),
      loadingConversation: messagesSelectors.isLoadingConversation(state),
      isClientApp,
      userToMessageId
    }
  },
  {
    readMessage,
    openForwardSurveyRequestDialog
  }
)(ConversationWithUser)
