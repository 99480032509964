import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import survey from './image-survey.png'
import tealbookGraphic from './image-tealbook-graphic.svg'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import ExternalLink from 'shared/components/ExternalLink'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import CustomInstructionsLogo from '../CustomInstructionsLogo'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import VerifyEmailDialog from './VerifyEmailDialog'
import analytics from 'shared/utils/analytics'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

type Props = {
  needVerification: boolean
  handleExitInstructionsPage: () => void
}

const SurveyInstructions = (props: Props) => {
  const location = useLocation()
  const { invitedBy } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const { needVerification, handleExitInstructionsPage } = props
  const [isVerified, setIsVerified] = useState<boolean>(false)

  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  useEffect(() => {
    analytics.track('Sustainability Survey Instructions Viewed', {
      orgUnitId,
      orgUnitName,
    })
  }, [orgUnitId, orgUnitName])

  return (
    <div>
      <ScrollToPosition />
      <CustomInstructionsLogo invitedBy={invitedBy} />
      <div className='mw8 center sph3'>
        <div className='lh-copy f5 ma0 mt3'>
          <Label className='db pt3 mb1 f5 fw6'>
            <FormattedMessage
              id='SurveyInstructions.Label'
              defaultMessage='Instructions'
            />
          </Label>
          <ol>
            <li>
              <FormattedMessage
                id='SurveyInstructionsOne'
                defaultMessage='There are a total of 5 sections.'
              />
            </li>
            <li>
              <FormattedMessage
                id='SurveyInstructionsTwo'
                defaultMessage='All questions must be answered before the survey can be submitted.'
              />
            </li>
            <li>
              <FormattedMessage
                id='SurveyInstructionsThree'
                defaultMessage='Do not enter any PII (Personal Identifiable Information).'
              />
            </li>
            <li>
              <FormattedMessage
                id='SurveyInstructionsFour'
                defaultMessage='Your progress will be automatically saved, therefore you can exit the session and complete it at a later date.'
              />
            </li>
            <li>
              <FormattedMessage
                id='SurveyInstructionsFive'
                defaultMessage='You can invite other members of your organization to collaborate on the survey at any time.'
              />
            </li>
          </ol>
        </div>
      </div>
      <div className='center tc'>
        <Button
          autoSize
          className='mr3'
          component={ExternalLink}
          href='https://tealbookhelp.zendesk.com/hc/en-us/articles/4418116788500-Sustainability-Survey'
          secondary
          size='large'
        >
          <FormattedMessage
            id='SurveyInstructions.LearnMore'
            defaultMessage='Learn More'
          />
        </Button>
        <Button
          onClick={handleExitInstructionsPage}
          label={
            <FormattedMessage
              id='SurveyInstructions.Continue'
              defaultMessage='Continue'
            />
          }
          autoSize
          size='large'
        />
      </div>
      <div className='mw8 center pv4 pv5-ns ph3'>
        <div className='flex flex-wrap justify-between'>
          <div className='w-50-ns'>
            <label className='db pt3 mb1 f5 fw6'>
              <FormattedMessage
                id='SustainabilityLandingPage.WhatIsTealbook'
                defaultMessage={`What is TealBook?`}
              />
            </label>
            <p className='lh-copy f5 ma0 mt4'>
              <FormattedMessage
                id='SustainabilityLandingPage.TealbookMessage'
                defaultMessage={`TealBook is a platform that your customers are using to get more information about suppliers like you. Customers also use TealBook to find and discover new suppliers that fulfill their needs. For more information on what TealBook is and why you don't want to miss out, <link>check out this article</link>.`}
                values={{
                  link: (link) => (
                    <ExternalLink
                      className='fw8 dark-teal'
                      href='https://tealbookhelp.zendesk.com/hc/en-us/articles/360050807492-Joining-Tealbook-What-s-in-it-for-me-'
                    >
                      {link}
                    </ExternalLink>
                  ),
                }}
              />
            </p>
          </div>
          <div className='w-100 w-third-ns mt3'>
            <img
              src={tealbookGraphic}
              alt='ipad'
              width='100%'
              aria-hidden='true'
            />
          </div>
        </div>
      </div>
      <div className='tl bg-near-white'>
        <div className='mw8 center pt2 pb4 pv4 pv5-ns ph3'>
          <div className='flex flex-wrap justify-between'>
            <div className='w-100 w-third-ns'>
              <img
                src={survey}
                alt='sustainability survey'
                width='100%'
                aria-hidden='true'
              />
            </div>
            <div className='w-50-ns'>
              <label className='db pt3 mb1 f5 fw6'>
                <FormattedMessage
                  id='SustainabilityLandingPage.WhatIsSustainability'
                  defaultMessage={`What is the TealBook sustainability survey?`}
                />
              </label>
              <p className='lh-copy f5 ma0 mt4'>
                <FormattedMessage
                  id='SustainabilityLandingPage.SustainabilityMessage1'
                  defaultMessage={`The TealBook Sustainability Survey is a 5 part assessment 
                  questionnaire containing questions relating to your 
                  organization's <b>Health and Safety</b> measures, <b>Environment</b>, 
                  <b>Diversity and Inclusion</b>, <b>Governance</b> and <b>Privacy</b> policies. By 
                  completing this survey, your company will also be discoverable to 
                  more potential customers!`}
                  values={{
                    b: (b) => <span className='fw8'>{b}</span>,
                  }}
                />
              </p>
              <p className='lh-copy f5 ma0 mt4'>
                <FormattedMessage
                  id='SustainabilityLandingPage.SustainabilityMessage2'
                  defaultMessage={`For more information on what sustainability is and what the 
                  survey entails, <link>check out this article</link>.`}
                  values={{
                    link: (link) => (
                      <ExternalLink
                        className='fw8 dark-teal'
                        href='https://tealbookhelp.zendesk.com/hc/en-us/articles/4418116788500-Sustainability-Survey'
                      >
                        {link}
                      </ExternalLink>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <VerifyEmailDialog
        open={!!needVerification && !isVerified}
        onVerified={() => setIsVerified(true)}
      />
    </div>
  )
}

export default SurveyInstructions
