import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'

import twitterIcon from './twitter-color.svg'
import tealboardIcon from './tealbook-icon.svg'
import PageSideSection from 'shared/components/PageSideSection'
import ExternalLink from 'shared/components/ExternalLink'
import Scrollable from 'shared/components/Scrollable'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import PopupPost from '../PopupPost'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List } from 'immutable'
import Button from 'shared/components/Button'
import useThemeColors from 'shared/utils/useThemeColors'
import analytics from 'shared/utils/analytics'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

type PostType = RecordOf<{
  active: boolean
  broadcast: boolean
  body: string
  comments?: Array<string>
  created: { date: string; user: string }
  date: string
  expanded: {}
  id: string
  image?: any
  iso: string
  logo?: string
  markRead?: Array<string>
  modified: { date: string; user: string }
  orgUnit: string
  pageName?: string
  parents: {}
  postId: string
  siblings: {}
  supplierId: string
  text: string
  title: string
  type: string
  upVotes?: Array<string>
  visibility: Array<string>
  impressionsCount: number
  markReadCount: number
}>

type Props = {
  feed: List<PostType>
  onClickCallback?: (postId: string) => void
}

const SocialFeed = (props: Props) => {
  const colors = useThemeColors()
  const [showAll, setShowAll] = useState<boolean>(false)
  const [isViewPostDialogOpen, setIsViewPostDialogOpen] =
    useState<boolean>(false)
  const [index, setIndex] = useState<number>(-1)

  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const handleDialogClose = () => {
    setIsViewPostDialogOpen(false)
    setIndex(-1)
  }

  const handleViewPost = (post: PostType, index: number) => {
    const { feed, onClickCallback } = props

    analytics.track('Social Feed Item Clicked', {
      eventSource: 'Supplier Profile',
      action: 'Clicked',
      itemDate: post.get('date'),
      socialNetwork: post.get('type'),
      itemText: post.get('text'),
      orgUnitId,
      orgUnitName,
    })

    setIsViewPostDialogOpen(true)
    setIndex(index)

    if (onClickCallback) {
      onClickCallback(feed.getIn([index, 'id']))
    }
  }

  const renderPost = (post: PostType, index: number) => {
    const type = post.get('type')
    const icon = twitterIcon
    const link = `https://twitter.com/${post.get('pageName')}/status/${post.get(
      'postId'
    )}`
    return (
      <div key={post.get('postId')} role='listitem'>
        <div className='dt mt2'>
          {type !== 'tealboard' ? (
            <ExternalLink href={link}>
              <div className='dtc v-top w1'>
                <img
                  src={icon}
                  alt={`twitter ${post.get('pageName')}`}
                  className='br2 w-100 dim'
                />
              </div>
            </ExternalLink>
          ) : (
            <div
              className='dtc v-top w1 pointer'
              onClick={() => handleViewPost(post, index)}
            >
              <img
                src={tealboardIcon}
                alt={`twitter ${post.get('pageName')}`}
                className='br2 w-100 dim'
              />
            </div>
          )}
          <div className='dtc v-top pl2'>
            <h5 className='f8 fw4 ma0 gray'>{post.get('date')}</h5>
          </div>
        </div>
        <div className='w-100 dt mb3'>
          <div className='db v-top'>
            {type !== 'tealboard' ? (
              <ExternalLink href={link}>
                <h5
                  className={`f8 fw4 ma0 gray lh-copy pointer dim ${colors.primaryHover}`}
                >
                  {post.get('text')}
                </h5>
              </ExternalLink>
            ) : (
              <h5
                className={`f8 fw4 ma0 gray lh-copy pointer dim ${colors.primaryHover}`}
                onClick={() => handleViewPost(post, index)}
              >
                {post.get('text')}
              </h5>
            )}
          </div>
          <div className='tr'>
            {post.get('impressionsCount') && (
              <span className='f8 fw4 mt1 ml2 gray'>
                <FormattedMessage
                  id='SocialFeed.View'
                  defaultMessage='{count, plural, one {{count} view} other {{count} views}}'
                  values={{ count: post.get('impressionsCount') }}
                />
              </span>
            )}

            {post.get('markReadCount') && (
              <span className='f8 fw4 mt1 ml2 gray'>
                <FormattedMessage
                  id='SocialFeed.Read'
                  defaultMessage='{count, plural, one {{count} read} other {{count} reads}}'
                  values={{ count: post.get('markReadCount') }}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <PageSideSection
        title={
          <FormattedMessage
            id='SocialFeed.SocialFeed'
            defaultMessage='Social Feed'
          />
        }
      >
        <Scrollable role='list'>
          <div className='mt3'>
            {props.feed.take(showAll ? props.feed.size : 5).map((post, i) => {
              return renderPost(post, i)
            })}
          </div>
          {props.feed.size > 5 && !showAll && (
            <Button
              size='small'
              variant='text'
              className='mt2'
              label={
                <FormattedMessage
                  id='SocialFeed.SeeMore'
                  defaultMessage='See more'
                />
              }
              onClick={() => setShowAll(true)}
            />
          )}
        </Scrollable>
      </PageSideSection>

      <Dialog onClose={handleDialogClose} open={isViewPostDialogOpen} fullWidth>
        <DialogTitle>
          <FormattedMessage
            id='SocialFeed.ViewPost'
            defaultMessage='View post'
          />
        </DialogTitle>
        <DialogContent className='mt3'>
          {index > -1 && (
            <PopupPost
              userId={props.feed.getIn([index, 'created', 'user'])}
              createdDate={props.feed.getIn([index, 'date'])}
              title={props.feed.getIn([index, 'title'])}
              body={props.feed.getIn([index, 'body'])}
              image={props.feed.getIn([index, 'image'])}
            />
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default SocialFeed
