import React from 'react'
import { useSelector } from 'react-redux'
import surveySelectors from '../../selectors/surveySelectors'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import paths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import dateFormat from 'shared/utils/dateFormat'
import Typography from '@material-ui/core/Typography'
import FactsCard from './FactsCard'
import { Link } from 'react-router-dom'
import downloadAttachment from 'shared/utils/fileDownload/fileDownload'
import useThemeColors from 'shared/utils/useThemeColors'
import RootState from 'shared/models/RootState'
import profileSelectors from 'buyer/SupplierProfile/selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import analytics from 'shared/utils/analytics'

const SustainabilityQuickFacts = () => {
  const colors = useThemeColors()
  const params: { supplierId: string } = useParams()
  const survey = useSelector(surveySelectors.getEsgSurvey)
  const quickFacts = useSelector(surveySelectors.getEsgQuickFacts)
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const { supplierId } = params
  const surveyCertFile = survey.get('sustainabilitySurveyCert')
  const companyName = useSelector((state: RootState) =>
    profileSelectors.getDataField(state, 'name')
  )
  return (
    <div className='mt3-5'>
      <div className='flex justify-between items-center'>
        <Typography component='h3' variant='subtitle1'>
          <FormattedMessage
            id='SustainabilityQuickFacts.Title'
            defaultMessage='Quick Facts'
          />
        </Typography>
        <Text>
          <FormattedMessage
            id='SustainabilityQuickFacts.LastUpdated'
            defaultMessage='Last Updated:'
          />{' '}
          {dateFormat(survey?.get('attestedDate'))}
        </Text>
      </div>
      <hr
        style={{
          height: 1,
        }}
        className='bg-dark-gray w-100 ma0 pa0 bn mt1'
      />
      <div className='mt3 flex-ns'>
        <div className='dib w-100 w-third-ns mr3-ns mv2'>
          <FactsCard
            backgroundColor='#F2F4F4'
            label={
              <FormattedMessage
                id='SustainabilityQuickFacts.Certs'
                defaultMessage={'Certificates added'}
              />
            }
            {...quickFacts.certificatesAdded}
          />
        </div>
        <div className='dib w-100 w-third-ns mh3-ns mv2'>
          <FactsCard
            backgroundColor='#FAEED3'
            label={
              <FormattedMessage
                id='SustainabilityQuickFacts.UniqueDocs'
                defaultMessage={'Unique documents uploaded'}
              />
            }
            {...quickFacts.documentsAdded}
          />
        </div>
        <div className='dib w-100 w-third-ns ml3-ns mv2'>
          <FactsCard
            backgroundColor='#C7EDEB'
            label={
              <FormattedMessage
                id='SustainabilityQuickFacts.Positives'
                defaultMessage={'No. of positive responses'}
              />
            }
            {...quickFacts.positiveResponses}
          />
        </div>
      </div>
      <div className='mt3 flex items-center justify-between'>
        <div className='flex items-center'>
          <Text className='pr3'>
            <FormattedMessage
              id='SustainabilityQuickFacts.Message'
              defaultMessage='This supplier has completed the Sustainability Survey'
            />
          </Text>
          {surveyCertFile && (
            <Button
              variant='text'
              aria-label='Download certificate of completion'
              className={colors.primaryText}
              onClick={() => {
                analytics.track('Sustainability Survey Certificate Clicked', {
                  eventSource: 'Supplier Profile',
                  action: 'Clicked',
                  orgUnitId,
                  orgUnitName,
                })
                downloadAttachment(
                  surveyCertFile?.get('fileName'),
                  'survey/sustainabilityCertification',
                  `${companyName} Certificate of Completion`
                )
              }}
            >
              <FormattedMessage
                id='SurveyResponse.Certificate'
                defaultMessage='Download certificate of completion'
              />
            </Button>
          )}
        </div>
        <Button
          autoSize
          component={Link}
          to={parsePath(paths.supplierEsgSurvey, { supplierId })}
        >
          <FormattedMessage
            id='SustainabilityQuickFacts.ViewResponses'
            defaultMessage='View Responses'
          />
        </Button>
      </div>
    </div>
  )
}

export default SustainabilityQuickFacts
