import React, { Component } from 'react'
import { connect } from 'react-redux'
import Page from 'shared/components/Page'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import CommunityCardContainer from '../CommunityCardContainer'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import SubTitleText from 'shared/components/SubTitleText'
import storageManager from 'shared/utils/storageManager'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import { FormattedMessage } from 'react-intl'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import { CSSTransition } from 'react-transition-group'
import { Map } from 'immutable'
import Hidden from '@material-ui/core/Hidden'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import chatbot from 'shared/utils/chatbot'
import RootState from 'shared/models/RootState'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'

const styles = {
  dl: 'ma0',
  dt: 'fw6',
  dd: 'mb2 ml3'
}
type Props = {
  communities: Map<string, string>
  isTealbot: boolean
}
type State = {
  doNotShow: boolean
}
export class CommunitiesPageContainer extends Component<Props, State> {
  state: State = {
    doNotShow: !!storageManager.getItem('doNotShowBuyerCommunitiesInfo')
  }

  handleDoNotShow = () => {
    storageManager.setItem('doNotShowBuyerCommunitiesInfo', true)
    this.setState({ doNotShow: true })
  }

  handleShow = () => {
    storageManager.setItem('doNotShowBuyerCommunitiesInfo', false)
    this.setState({ doNotShow: false })
  }

  render() {
    const { communities, isTealbot } = this.props
    const { doNotShow } = this.state

    return (
      <Page
        title={
          <FormattedMessage
            id='CommunitiesPageContainer.Communities'
            defaultMessage='Communities'
          />
        }
      >
        <ScrollToPosition />
        <div className='flex'>
          <SubTitleText>
            <FormattedMessage
              id='CommunitiesPageContainer.BuyerCommunitiesInfo'
              defaultMessage='Communities can help you organize your suppliers and prospective suppliers into groups and manage your communications with them.'
            />
          </SubTitleText>

          <div className='ml3 mt1 w4'>
            <CSSTransition
              in={doNotShow}
              timeout={100}
              classNames='tealbotHelp'
              unmountOnExit
            >
              <TealbotHelpIcon
                show={doNotShow}
                imageClassName='mt2 fr dim pointer'
                onClick={this.handleShow}
              />
            </CSSTransition>
          </div>
        </div>

        <CSSTransition
          in={!doNotShow}
          timeout={300}
          classNames='tealbotHelp'
          unmountOnExit
        >
          <div className='bg-white br1 ba b--black-10 mb3 flex'>
            <div className='pa3-5'>
              <div className='lh-copy f7 fw4 mid-gray ma0'>
                <dl className={styles.dl}>
                  <dt className={styles.dt}>
                    <FormattedMessage
                      id='CommunitiesPageContainer.BuyerCommunitiesInfoDT1'
                      defaultMessage='Public / Private'
                    />
                    :
                  </dt>
                  <dd className={styles.dd}>
                    <FormattedMessage
                      id='CommunitiesPageContainer.BuyerCommunitiesInfoDD1'
                      defaultMessage='If you make a community Public then any supplier can join, for Private communities, you need to send the supplier an invitation to the community from the search list or their profile.'
                    />
                  </dd>
                  <dt className={styles.dt}>
                    <FormattedMessage
                      id='CommunitiesPageContainer.BuyerCommunitiesInfoDT2'
                      defaultMessage='Posts'
                    />
                    :
                  </dt>
                  <dd className={styles.dd}>
                    <FormattedMessage
                      id='CommunitiesPageContainer.BuyerCommunitiesInfoDD2'
                      defaultMessage='When you create a post, you can target it to a specific audience by selecting the appropriate community.'
                    />
                  </dd>
                  <dt className={styles.dt}>
                    <FormattedMessage
                      id='CommunitiesPageContainer.BuyerCommunitiesInfoDT3'
                      defaultMessage='Searching'
                    />
                    :
                  </dt>
                  <dd className={styles.dd}>
                    <FormattedMessage
                      id='CommunitiesPageContainer.BuyerCommunitiesInfoDD3'
                      defaultMessage='In the Search List, you can limit your results based on the communities a supplier has been invited to.'
                    />
                  </dd>
                  <dt className={styles.dt}>
                    <FormattedMessage
                      id='CommunitiesPageContainer.BuyerCommunitiesInfoDT4'
                      defaultMessage='Forms'
                    />
                    :
                  </dt>
                  <dd className={styles.dd}>
                    <FormattedMessage
                      id='CommunitiesPageContainer.BuyerCommunitiesInfoDD4'
                      defaultMessage='If you have a form you want a group of your suppliers to fill in, it can be associated with a community and they can be invited to complete it.'
                    />
                  </dd>
                </dl>
              </div>
            </div>

            <Hidden smDown>
              <div className='ml3-ns w4 self-center'>
                <img src={TealbotCircleImage} alt='tealbot-tip' />
              </div>
            </Hidden>

            <div className='pa2'>
              <IconButton
                onClick={this.handleDoNotShow}
                style={{
                  width: 32,
                  height: 32
                }}
                aria-label='Close help card'
                color='secondary'
              >
                <CloseIcon htmlColor='#00101D' />
              </IconButton>
            </div>
          </div>
        </CSSTransition>
        {(!communities || communities.size === 0) && (
          <>
            <SubTitleText>
              <FormattedMessage
                id='CommunitiesPageContainer.NoCommunity'
                defaultMessage='Want to join a community or create a new one? {contactSupport} and they will be happy to help.'
                values={{
                  contactSupport: (
                    <span className='teal pointer dim' onClick={chatbot.open}>
                      <FormattedMessage
                        id='CommunitiesPageContainer.ContactSupport'
                        defaultMessage={`Contact support`}
                      />
                    </span>
                  )
                }}
              />
            </SubTitleText>
          </>
        )}
        {communities &&
          communities.keySeq().map(communityId => {
            return (
              <CommunityCardContainer
                key={communityId}
                communityId={communityId}
                isTealbot={isTealbot}
              />
            )
          })}
      </Page>
    )
  }
}

export default connect((state: RootState) => ({
  isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
  communities: communitiesSelectors.getCommunities(state)
}))(CommunitiesPageContainer)
