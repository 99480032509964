import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import ContactCardContainer from 'shared/containers/ContactCardContainer'
import ConnectionsStackContainer from '../../../shared/containers/ConnectionsStackContainer'
import UpdateContactForm from '../../containers/UpdateContactForm'
import DialogTitle from 'shared/components/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import paths from 'buyer/routes/paths'
import ConnectMessageDialogContainer from 'buyer/shared/containers/ConnectMessageDialogContainer'
import AddContactForm from '../../containers/AddContactForm'
import { Map, RecordOf, List } from 'immutable'
import Contact from '../../../../shared/models/Contact'
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth'
import Scrollable from 'shared/components/Scrollable'

type Props = {
  contacts: List<RecordOf<Contact>>
  isEditingContact: boolean
  isTealbot: boolean
  isBuyerAdmin: boolean
  supplierId: string
  contactToEdit: RecordOf<Contact>
  supplierName: string
  currentUserId?: string
  isEditable?: boolean
  readOnly?: boolean
  hideConnections?: boolean
  hideAddNew?: boolean
  onRemoveContact: (arg: string) => void
  onRemoveContactRelationship: (params: {
    contactId: string
    supplierId: string
  }) => void
  onEditContact: (arg: Contact) => void
  onUpdateContact: (arg: Map<{}, {}>) => void
  onCancelEdit: () => void
} & WithWidthProps

export class ContactsSection extends Component<Props> {
  state = {
    openMessageDialog: false,
    userToMessageId: '',
    connectContact: false
  }

  handleClickConnect = userId => {
    this.setState({
      openMessageDialog: true,
      userToMessageId: userId,
      connectContact: true
    })
  }

  handleClickMessage = userId => {
    this.setState({
      openMessageDialog: true,
      userToMessageId: userId
    })
  }

  handleOpenDialog = dialogName => {
    this.setState({
      [`open${dialogName}`]: true
    })
  }

  handleCloseDialog = dialogName => {
    this.setState({
      [`open${dialogName}`]: false,
      userToMessageId: '',
      connectContact: false
    })
  }

  render() {
    const {
      contacts,
      isEditingContact,
      isTealbot,
      isBuyerAdmin,
      supplierId,
      onRemoveContact,
      onRemoveContactRelationship,
      onEditContact,
      onUpdateContact,
      onCancelEdit,
      contactToEdit,
      currentUserId,
      isEditable,
      width,
      readOnly,
      hideConnections,
      hideAddNew
    } = this.props

    const matchMobile = width && ['xs', 'sm'].includes(width)

    const { openMessageDialog, userToMessageId, connectContact } = this.state
    return (
      <>
        {contacts && contacts.size > 0 && (
          <div className='ph3-5 pb3-5'>
            <Scrollable role='list'>
              {contacts.map(contact => (
                <ContactCardContainer
                  isCurrentUser={
                    currentUserId
                      ? contact.get('user') === currentUserId
                      : false
                  }
                  isEditable={currentUserId ? contact.get('mine') : isEditable}
                  isEditing={isEditingContact}
                  isTealbot={isTealbot}
                  isBuyerAdmin={isBuyerAdmin}
                  baseUrl={paths.basePath}
                  key={contact.get('user')}
                  userId={contact.get('user')}
                  contact={contact}
                  onRemove={
                    readOnly
                      ? undefined
                      : () => onRemoveContact(contact.get('user'))
                  }
                  onDelete={
                    readOnly
                      ? undefined
                      : () =>
                          onRemoveContactRelationship({
                            contactId: contact.get('user'),
                            supplierId: supplierId
                          })
                  }
                  onEdit={
                    readOnly ? undefined : () => onEditContact(contact.toJS())
                  }
                  connected={contact.get('connected')}
                  disableOpen={readOnly}
                  onUpdate={
                    readOnly
                      ? undefined
                      : additionalValues =>
                          onUpdateContact(
                            Map({
                              user: { id: contact.get('user') },
                              connected: true,
                              ...additionalValues
                            })
                          )
                  }
                  onClickConnect={
                    readOnly ? undefined : this.handleClickConnect
                  }
                  onUnfollow={
                    readOnly
                      ? undefined
                      : () =>
                          onUpdateContact(
                            Map({
                              user: { id: contact.get('user') },
                              connected: false,
                              connectFor: ''
                            })
                          )
                  }
                  onClickMessage={
                    readOnly ? undefined : this.handleClickMessage
                  }
                >
                  {!hideConnections && (
                    <div>
                      <ConnectionsStackContainer
                        contactConnection
                        noPadding
                        supplierId={supplierId}
                        userId={contact.get('user')}
                      />
                    </div>
                  )}
                </ContactCardContainer>
              ))}
            </Scrollable>
          </div>
        )}
        {!hideAddNew && (
          <div>
            <AddContactForm useList isClientApp />
          </div>
        )}

        <Dialog
          open={!!isEditingContact}
          onClose={onCancelEdit}
          disableBackdropClick
          fullWidth
          fullScreen={matchMobile}
        >
          <DialogTitle>
            <FormattedMessage
              id='ProfilePage.EditTeamMember'
              defaultMessage='Edit team member'
            />
          </DialogTitle>
          <DialogContent>
            <UpdateContactForm
              contact={contactToEdit}
              onClickCancel={onCancelEdit}
            />
          </DialogContent>
        </Dialog>

        <ConnectMessageDialogContainer
          supplierId={supplierId}
          userId={userToMessageId}
          open={openMessageDialog}
          onClose={() => this.handleCloseDialog('MessageDialog')}
          connectContact={connectContact}
        />
      </>
    )
  }
}

export default withWidth()(ContactsSection)
