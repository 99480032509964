import { createSelector } from 'reselect'
import { List, fromJS } from 'immutable'
import buyerCommunitiesSelectors from 'buyer/Communities/selectors/communitiesSelectors'
import supplierCommunitiesSelectors from 'supplier/Communities/selectors/communitiesSelectors'
import dateFormat from 'shared/utils/dateFormat'
import moment from 'moment'
import truncate from 'lodash.truncate'
import removeMarkdown from 'remove-markdown'
import RootState from 'shared/models/RootState'
import usersSelectors from 'shared/selectors/usersSelectors'
import parsePath from 'shared/utils/parsePath'
import paths from 'buyer/routes/paths'

export const getPosts = (state: RootState) => state.getIn(['posts', 'byId'])
export const getById = (state: RootState, id: string) =>
  state.getIn(['posts', 'byId', id])
export const getPostIdsSortByDate = (state: RootState) =>
  state.getIn(['posts', 'sortByDate'])
export const getPostComments = (state: RootState, id: string) =>
  state.getIn(['posts', 'byId', id, 'comments'])
export const getUnread = (state: RootState) => state.getIn(['posts', 'unread'])
export const isLoading = (state: RootState) =>
  state.getIn(['posts', 'isLoading'])
export const getRecentSuppliers = (state: RootState) =>
  state.getIn(['posts', 'recentSuppliers'])
export const getRecentConnectedSuppliers = (state: RootState) =>
  state.getIn(['posts', 'recentConnectedSuppliers'])
export const getRecentSupplierTags = (state: RootState) =>
  state.getIn(['posts', 'recentSupplierTags'])

export const getMergedRecentSuppliers = createSelector(
  (state: RootState) => state,
  getRecentSuppliers,
  (state, suppliersInfo) => {
    return (
      suppliersInfo &&
      suppliersInfo
        .map(supplierInfo => {
          const userId = supplierInfo.getIn([
            'recentMessage',
            'created',
            'user'
          ])
          const user = userId && usersSelectors.getById(state, userId)
          return (
            user &&
            supplierInfo.merge({
              activeUser: {
                profilePictureUrl: user.get('profilePictureUrl'),
                userProfileUrl: parsePath(paths.userprofile, {
                  userId: userId
                }),
                firstName: user.get('firstName'),
                lastName: user.get('lastName')
              }
            })
          )
        })
        .filter(supplier => supplier)
    )
  }
)

export const getPostsByUserId = createSelector(
  (state: RootState, userId: string) =>
    state.getIn(['posts', 'byUserId', userId]),
  getPosts,
  (postIds = List(), posts) => {
    return (
      posts &&
      posts
        .toList()
        .filter(post => {
          return postIds.includes(post.get('id'))
        })
        .map(post => {
          const { ...restPost } = post.toJS()
          return fromJS({
            date: `${dateFormat(restPost.created.date)} at ${moment(
              restPost.created.date
            ).format('hh:mm a')}`,
            iso: restPost.created.date,
            logo: null,
            pageName: null,
            postId: restPost.id,
            text:
              restPost.title ||
              truncate(removeMarkdown(restPost.body), {
                length: 50,
                separator: /,? +/
              }),
            type: 'tealboard',
            ...restPost
          })
        })
    )
  }
)

export const getCommunities = createSelector(
  buyerCommunitiesSelectors.getCommunitiesList,
  supplierCommunitiesSelectors.getAllAccepted,
  (buyerCommunities: List<any>, supplierCommunities: List<any>) => {
    // map them by their id
    let postsById = {}

    if (buyerCommunities) {
      buyerCommunities.forEach(community => {
        postsById[community.get('id')] = community
      })
    }
    if (supplierCommunities) {
      supplierCommunities.forEach(community => {
        postsById[community.get('id')] = community
      })
    }

    return List(Object.values(postsById))
  }
)

export const getPostStat = createSelector(getById, post => {
  const markReadCount = post.get('markReadCount')
  const impressionsCount = post.get('impressionsCount')
  const upVotesCount = post.get('upVotesCount')
  const comments = post.get('comments')

  return {
    numLikes: upVotesCount,
    numComments: comments && comments.size,
    numRead: markReadCount,
    numImpressions: impressionsCount
  }
})

export const getTrendingTags = (state: RootState) =>
  state.getIn(['posts', 'trendingTags'])

export const getPostsByTag = createSelector(
  (state: RootState, tag: string) => state.getIn(['posts', 'byTag', tag]),
  (posts: List<any>) => {
    const sortedPosts =
      posts &&
      posts.sort((postA, postB) => {
        return new Date(postA.getIn(['created', 'date'])) >
          new Date(postB.getIn(['created', 'date']))
          ? -1
          : 1
      })
    return sortedPosts
  }
)
