import React, { Component, Fragment } from 'react'
import { Field } from 'redux-form/immutable'
import Label from 'shared/components/Label'
import Input from 'shared/components/Input'
import Grid from '@material-ui/core/Grid'
import Checkbox from 'shared/components/Checkbox'
import Button from 'shared/components/Button'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'

type Props = {
  address: string
  onSubmit: () => void
  onCancel: () => void
  intl: IntlShape
}

class CompanyLocationAddForm extends Component<Props> {
  render() {
    const { onSubmit, onCancel, address, intl } = this.props

    return (
      <form className='bg-near-white ph2 pb3' onSubmit={onSubmit}>
        <div className='ph2'>
          <Label htmlFor='address'>
            <FormattedMessage
              id='CompanyLocationAddForm.AddressLabel'
              defaultMessage='Address'
            />
          </Label>
          <Field
            component={Input}
            name='address'
            className='mb2'
            placeholder={intl.formatMessage({
              id: 'CompanyLoctionAddForm.AddressPlaceholder',
              defaultMessage:
                'Physical address (e.g. 30 Camden St., Toronto, ON)'
            })}
            required
          />
        </div>

        {!!address && (
          <Fragment>
            <Grid container>
              <Grid item sm className='ph2'>
                <Label htmlFor='description'>
                  <FormattedMessage
                    id='CompanyLocationAddForm.NameLabel'
                    defaultMessage='Name'
                  />
                </Label>
                <Field
                  component={Input}
                  name='description'
                  placeholder={intl.formatMessage({
                    id: 'CompanyLoctionAddForm.AddressDescriptionPlaceholder',
                    defaultMessage:
                      'Description of this address (e.g. Toronto, Headquarter)'
                  })}
                  required
                  autoComplete='on'
                />
              </Grid>

              <Grid item sm className='ph2'>
                <Label htmlFor='phone'>
                  <FormattedMessage
                    id='CompanyLocationAddForm.PhoneLabel'
                    defaultMessage='Phone'
                  />
                </Label>
                <Field
                  component={Input}
                  name='phone'
                  placeholder={intl.formatMessage({
                    id: 'CompanyLoctionAddForm.PhoneNumberPlaceholder',
                    defaultMessage: 'Phone number'
                  })}
                  autoComplete='tel'
                />
              </Grid>
            </Grid>

            <Field
              name='isPrimary'
              component={Checkbox}
              label='Primary Address'
              className='ml2 mt3'
            />

            <div className='tr mr2'>
              <Button
                label={<FormattedMessage id='CancelButton' />}
                onClick={onCancel}
                secondary
                autoSize
              />
              <Button
                type='submit'
                label={
                  <FormattedMessage
                    id='CompanyLoctionAddForm.AddButton'
                    defaultMessage='Add'
                  />
                }
                autoSize
                className='mt3 ml3'
              />
            </div>
          </Fragment>
        )}
      </form>
    )
  }
}

export default injectIntl(CompanyLocationAddForm)
