import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Certification from 'shared/models/Certification'
import CertificationCategories from 'shared/models/CertificationCategories'
import { RecordOf } from 'immutable'
import Text from 'shared/components/Text'
import dateFormat from 'shared/utils/dateFormat'
import downloadAttachment from 'shared/utils/fileDownload/fileDownload'
import iconFile from 'shared/assets/icons/file.svg'
import IconButton from 'shared/components/IconButton'

type Props = {
  certificate: RecordOf<Certification>
}

const Certificate = ({ certificate }: Props) => {
  const intl = useIntl()

  return certificate ? (
    <div className='mv2 truncate'>
      <Text className='fw6' style={{ color: 'black' }}>
        {intl.formatMessage(
          CertificationCategories[certificate.get('category')].subCategories[
            certificate.get('subCategory')
          ]
        )}
      </Text>
      {!!certificate.get('certAgency') && (
        <Text>
          <FormattedMessage
            id='Certificate.Agency'
            defaultMessage='Certified by '
          />
          {certificate.get('certAgency')}
        </Text>
      )}
      {!!certificate.get('certExpiration') && (
        <Text>
          <FormattedMessage
            id='Certificate.Expires'
            defaultMessage='Expires on '
          />
          {dateFormat(certificate.get('certExpiration'), undefined, false)}
        </Text>
      )}
      {certificate.getIn(['cert', 'fileName']) && (
        <IconButton
          onClick={() =>
            downloadAttachment(
              certificate.getIn(['cert', 'fileName']),
              `supplier/certification`
            )
          }
          variant='text'
          wrapperStyle={{ color: '#028383' }}
        >
          <>
            <img src={iconFile} alt='Attachment' className='h1 v-mid mr1' />
            <FormattedMessage
              id='Certificate.Download'
              defaultMessage='Download Certificate'
            />
          </>
        </IconButton>
      )}
    </div>
  ) : (
    <Text>
      <FormattedMessage
        id='Certificate.Unavailable'
        defaultMessage='Unavailable'
      />
    </Text>
  )
}

export default Certificate
