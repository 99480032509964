import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import { getFormValues } from 'redux-form/immutable'
import { createSelector } from 'reselect'
import AddUserContactForm from 'shared/components/AddUserContactForm'
import companySelectors from '../../../shared/selectors/companySelectors'
import { RecordOf } from 'immutable'
import { ADD_SURVEY_COLLABORATE_CONTACT } from '../../../shared/actionTypes'
import newCollaborateContactSelectors from '../../../shared/selectors/newCollaborateContactSelectors'
import createAddForm from 'shared/utils/createAddForm'
import sessionSelectors from 'shared/selectors/sessionSelectors'

const formName = 'supplier/survey/addSurveyCollaborateContact'
const formValueSelector = getFormValues(formName)

type FormData = RecordOf<{
  user: RecordOf<{
    email: string
  }>
  sendMessage: string
}>

type Props = {
  defaultMessage: string
  sendCollaborateRequest?: boolean
  onClickCancel: () => void
  isPublicSupplierContact?: boolean
}

const getInitialValues = createSelector(
  newCollaborateContactSelectors.getSelectedUser,
  newCollaborateContactSelectors.getNewUser,
  (selectedUser, newUser) => ({
    user: selectedUser || newUser,
    isPrivate: false,
    message: 'I would like to add you as a contact in TealBook.',
    sendMessage: false
  })
)

export default connect((state: RootState, props: Props) => {
  const selectedUser = newCollaborateContactSelectors.getSelectedUser(state)
  const addSuccess = newCollaborateContactSelectors.addSuccess(state)
  const formValues = formValueSelector(state) as FormData
  const user = sessionSelectors.getUser(state)
  const initialValues = getInitialValues(state)
  initialValues['isPublicSupplierContact'] = props.isPublicSupplierContact

  return {
    caption: 'Add new Contact for Survey Collaboration',
    email: formValues && formValues.getIn(['user', 'email']),
    sendMessage: formValues && formValues.getIn(['sendMessage']),
    enablePublicPrivate: false,
    domains: companySelectors.getCompanyField(state, 'domains') || [],
    addingContact: newCollaborateContactSelectors.isAddingContact(state),
    creatingNewUser: newCollaborateContactSelectors.isCreatingNewUser(state),
    forContact: true,
    addSuccess,
    addedContactId: selectedUser && selectedUser.get('id'),
    addedContactName:
      selectedUser &&
      `${selectedUser.get('firstName') || ''} ${selectedUser.get('lastName') ||
        ''}`,
    initialValues,
    hideLookup: true,
    defaultMessage: props.defaultMessage,
    sendCollaborateRequest: props.sendCollaborateRequest,
    currentUserId: user && user.get('id'),
    onClickCancel: props.onClickCancel
  }
})(
  createAddForm(
    {
      form: formName,
      submitActionType: ADD_SURVEY_COLLABORATE_CONTACT
    },
    AddUserContactForm
  )
)
