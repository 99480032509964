import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import RootState from 'shared/models/RootState'
import { FormattedMessage } from 'react-intl'
import DiversityReportOverview from './DiversityReportOverview'
import DiversityReportDetails from './DiversityReportDetails'
import ScrollToPosition from 'shared/components/ScrollToPosition'
import InsightTitle from 'shared/components/InsightTitle'
import {
  getOverviewSpend,
  OverviewSpendList,
  getCurrentGrouping,
} from '../../store/diversityReportSelectors'
import Button from 'shared/components/Button'
import { exportOverview } from './exportUtils'
import analytics from 'shared/utils/analytics'
import moment from 'moment'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

type Props = {
  show: 'qualified' | 'disqualified' | 'potential'
  currentGrouping?: 'subCategory' | 'category' | 'country' | 'spendGroup'
  showDiversityReportDetails: boolean
  overviewSpend: OverviewSpendList
  totalSpend: number
  startDate: string
  endDate: string
}

export const DiversityReport = (props: Props) => {
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const [show, setShow] = useState<'qualified' | 'disqualified' | 'potential'>(
    props.show
  )
  const [mountTime] = useState(moment().toDate().getTime())
  useEffect(() => {
    setShow(props.show)
  }, [props.show])

  useEffect(() => {
    return () => {
      analytics.track('Diversity Report Viewed', {
        duration: Number((moment().diff(mountTime) / 1000).toFixed(2)),
        orgUnitId,
        orgUnitName,
      })
    }
  }, [mountTime, orgUnitId, orgUnitName])

  const handleExportClick = () => {
    analytics.track('Diversity Report Export Clicked', {
      eventSource: 'Diversity Report',
      action: 'Clicked',
      orgUnitId,
      orgUnitName,
    })

    exportOverview(
      props.overviewSpend.spendItems,
      props.totalSpend,
      orgUnitId,
      orgUnitName,
      props.currentGrouping
    )
  }

  return (
    <>
      <ScrollToPosition />
      <InsightTitle>
        <div className='flex items-center justify-between'>
          <span>
            <FormattedMessage
              id='DiversityReport.DiversityReport'
              defaultMessage='Diversity Report'
            />
          </span>
          {props.overviewSpend?.spendItems.size > 0 && (
            <Button
              disabled={!props.showDiversityReportDetails}
              onClick={handleExportClick}
              aria-label='Export'
            >
              <FormattedMessage id='Export' defaultMessage='Export' />
            </Button>
          )}
        </div>
      </InsightTitle>
      <DiversityReportOverview />
      {props.showDiversityReportDetails && (
        <DiversityReportDetails
          show={show}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      )}
    </>
  )
}

export default connect((state: RootState) => ({
  overviewSpend: getOverviewSpend(state),
  totalSpend: state.getIn([
    'buyer',
    'insights',
    'spend',
    'overview',
    'totalSpend',
  ]),
  currentGrouping: getCurrentGrouping(state),
  showDiversityReportDetails: !state.get('buyer').get('diversityReport')
    .loading,
}))(DiversityReport)
