import React, { ReactElement } from 'react'
import { connect, useDispatch } from 'react-redux'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Label from 'shared/components/Label'
import Button from 'shared/components/Button'
import UserListItemContainer from 'shared/containers/UserListItemContainer'
import { FormattedMessage } from 'react-intl'
import useSmallScreen from 'shared/utils/useSmallScreen'
import { notify } from 'shared/actions'
import {
  cancelAddingOutreachContact,
  requestForProfileUpdate,
  requestforSelfCertify,
} from 'buyer/SupplierProfile/actions'

type Props = {
  userId: string
  title?: string | ReactElement<FormattedMessage>
  description?: string | ReactElement<FormattedMessage>
  open: boolean
  onClose: () => void
  supplierId?: string
  isProfileUpdateRequest?: boolean
  supplierName?: string
}

export const ConnectRequestDialog = (props: Props) => {
  const {
    open,
    description,
    userId,
    onClose,
    title,
    supplierId,
    isProfileUpdateRequest,
    supplierName,
  } = props

  const matchMobile = useSmallScreen()
  const dispatch = useDispatch()

  const handleRequestUpdate = (e) => {
    e.preventDefault()
    dispatch(
      requestForProfileUpdate({
        supplierId,
        requestedContactId: userId,
      })
    )
    dispatch(notify({ message: 'Request successfully sent' }))
    dispatch(cancelAddingOutreachContact())
  }

  const handleRequestSelfCert = (e) => {
    e.preventDefault()
    dispatch(
      requestforSelfCertify({
        supplierId,
        supplierName,
        requestedContactId: userId,
      })
    )
    dispatch(notify({ message: 'Request successfully sent' }))
    dispatch(cancelAddingOutreachContact())
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      disableBackdropClick
      fullScreen={matchMobile}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form
          id='messageDialog'
          onSubmit={
            isProfileUpdateRequest ? handleRequestUpdate : handleRequestSelfCert
          }
        >
          {description && <Label>{description}</Label>}
          <div className='mb3-ns mb4 pt3 pt0-ns'>
            <UserListItemContainer mini userId={userId} />
          </div>
        </form>
      </DialogContent>
      <DialogActions className='fixed static-ns bottom-1'>
        <Button
          size={matchMobile ? 'large' : undefined}
          label={
            <FormattedMessage
              id='ConnectMessageDialog.Send'
              defaultMessage='Send'
            />
          }
          type='submit'
          form='messageDialog'
        />
        <Button
          size={matchMobile ? 'large' : undefined}
          label={<FormattedMessage id='CancelButton' />}
          onClick={onClose}
          secondary
          autoSize
        />
      </DialogActions>
    </Dialog>
  )
}

export default connect(null, {
  notify,
})(ConnectRequestDialog)
