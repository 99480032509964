import React, { useEffect } from 'react'
import Page from 'shared/components/Page'
import Paper from 'shared/components/Paper'
import { FormattedMessage } from 'react-intl'
import InsightDiversityNavBar from '../InsightDiversityNavBar'
import { Switch, Route } from 'react-router'
import Report from '../../containers/Report'
import Tier2InvitationContainer from '../../containers/Tier2InvitationContainer'
import LoadPageContainer from '../../containers/LoadPageContainer'
import paths from '../../diversityPaths'
import ShareTier2Container from '../../containers/ShareTier2Container'
import { useDispatch } from 'react-redux'
import { loadStagingHistory } from 'shared/actions'
import CallToAction from './CallToAction'
import { useHistory } from 'react-router'

const DiversityReport = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(loadStagingHistory({ listType: 'supplierTier2Load' }))
  }, [dispatch])

  return (
    <>
      <InsightDiversityNavBar compactInstruction />
      <Page
        title={
          <FormattedMessage
            id='DiversityReport.DiversityReport'
            defaultMessage='Diversity'
          />
        }
      >
        <Switch>
          <Route path={paths.diversityLoad} exact>
            <CallToAction
              onHandleButtonClick={() => history.push(paths.diversityLoadV2)}
            >
              <FormattedMessage
                id='CallToAction.AddSpendInfo'
                defaultMessage={'Add your supplier spend information'}
              />
            </CallToAction>
          </Route>
        </Switch>
        <Paper>
          <Switch>
            <Route path={paths.diversityInvitation}>
              <Tier2InvitationContainer />
            </Route>
            <Route path={paths.diversityLoad}>
              <LoadPageContainer />
            </Route>
            <Route exact path={paths.diversityReport}>
              <Report />
            </Route>
            <Route path={paths.diversityShare}>
              <ShareTier2Container />
            </Route>
          </Switch>
        </Paper>
      </Page>
    </>
  )
}

export default DiversityReport
