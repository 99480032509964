import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as ChevronLeft } from 'shared/assets/icons/circle-chevron-left-solid.svg'
import { ReactComponent as ChevronRight } from 'shared/assets/icons/circle-chevron-right-solid.svg'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import Button from 'shared/components/Button'

let showPagesSize = 3

type Props = {
  onChange: (p: number) => void
  currentPage: number
  totalPages: number
}

const Pagination = (props: Props) => {
  const { onChange, currentPage, totalPages } = props
  if (totalPages < showPagesSize) {
    showPagesSize = totalPages
  }
  const pages = Array(showPagesSize)
    .fill(0)
    .map((v, i) => i)
  const isFirstHalf = currentPage / totalPages < 0.5
  const showDropDown = totalPages > pages.length + 1
  let showPages
  /*
    if current page is >= total - showPagesSize, then show the last showPagesSize pages
    if current page is <= showPagesSize, then show first showPagesSize pages
    if current page is in first half, then show showPagesSize pages forward from current
    if current page is in last half, then show showPagesSize pages backward from current
  */
  if (currentPage > totalPages - pages.length) {
    pages.reverse()
    showPages = pages.map(v => totalPages - v)
  } else if (currentPage <= pages.length) {
    showPages = pages.map(v => v + 1)
  } else if (isFirstHalf) {
    showPages = pages.map(v => v + currentPage)
  } else {
    pages.reverse()
    showPages = pages.map(v => currentPage - v)
  }

  /* figure out the pages in dropdown menu */
  let menuPages
  if (showDropDown) {
    menuPages = Array(
      isFirstHalf
        ? totalPages - showPages[showPages.length - 1] - 1
        : showPages[0] - 2
    )
      .fill(0)
      .map((v, i) =>
        isFirstHalf
          ? showPages[showPages.length - 1] + i + 1
          : showPages[0] - i - 1
      )
    if (!isFirstHalf) {
      menuPages.reverse()
    }
  }

  const handleOnChange = value => {
    onChange(value)
  }

  return (
    <nav>
      {currentPage !== 1 && (
        <IconButton
          aria-label='Previous Page'
          onClick={() => handleOnChange(currentPage - 1)}
        >
          <ChevronLeft fill='#028383' />
        </IconButton>
      )}
      {showPages.length < totalPages && !isFirstHalf && (
        <Button
          aria-label='Page 1'
          autoSize
          variant='text'
          onClick={() => handleOnChange(1)}
          aria-current={currentPage === 1 ? 'page' : 'false'}
        >
          <span className={`${currentPage === 1 ? 'fw6' : 'fw4'}`}>1</span>
        </Button>
      )}
      {isFirstHalf &&
        showPages.map(p => (
          <Button
            key={p}
            aria-label={`Page ${p}`}
            autoSize
            variant='text'
            onClick={() => handleOnChange(p)}
            aria-current={currentPage === p ? 'page' : 'false'}
          >
            <span className={`${currentPage === p ? 'fw6' : 'fw4'}`}>{p}</span>
          </Button>
        ))}
      {showDropDown && (
        <DropDownMenu ariaLabel='Select a page'>
          {menuPages.map(p => (
            <MenuItem
              key={p}
              aria-label={`Page ${p}`}
              onClick={() => handleOnChange(p)}
            >
              {p}
            </MenuItem>
          ))}
        </DropDownMenu>
      )}
      {showPages.length < totalPages && isFirstHalf && (
        <Button
          aria-label={`Page ${totalPages}`}
          autoSize
          variant='text'
          onClick={() => handleOnChange(totalPages)}
          aria-current={currentPage === totalPages ? 'page' : 'false'}
        >
          <span className={`${currentPage === totalPages ? 'fw6' : 'fw4'}`}>
            {totalPages}
          </span>
        </Button>
      )}
      {!isFirstHalf &&
        showPages.map(p => (
          <Button
            key={p}
            aria-label={`Page ${p}`}
            autoSize
            variant='text'
            onClick={() => handleOnChange(p)}
            aria-current={currentPage === p ? 'page' : 'false'}
          >
            <span className={`${currentPage === p ? 'fw6' : 'fw4'}`}>{p}</span>
          </Button>
        ))}
      {currentPage !== totalPages && (
        <IconButton
          aria-label='Next Page'
          onClick={() => handleOnChange(currentPage + 1)}
        >
          <ChevronRight fill='#028383' />
        </IconButton>
      )}
    </nav>
  )
}

export default Pagination
