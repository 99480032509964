import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Text from 'shared/components/Text'
import { Map, List } from 'immutable'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'
import Scrollable from 'shared/components/Scrollable'
import qs from 'qs'
import classificationCodeSchemas from 'shared/models/ClassificationCodeSchemas'
import ClassificationCode from 'shared/models/ClassificationCode'
import naics from 'shared/models/Naics'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import analytics from 'shared/utils/analytics'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

type Props = {
  classificationCodesBySchema?: Map<string, List<ClassificationCode>>
  schemaOrder?: Array<string>
  removeClassificationCode?: (arg: { schema: string; code: string }) => void
  editable?: boolean
}

const ClassificationCodes = (props: Props) => {
  const {
    classificationCodesBySchema,
    schemaOrder = ['naics', 'unspsc'],
    removeClassificationCode,
    editable = false,
  } = props

  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const location = useLocation()
  const isSupplierApp = location.pathname.startsWith('/supplier')

  const getInputCode = (schema: string, code: string) => {
    let newCode: string
    switch (schema) {
      case 'naics':
        newCode = naics[code]
          ? code
          : code.length === 6 &&
            code.slice(-1) === '0' &&
            naics[code.slice(0, -1)]
          ? code.slice(0, -1)
          : ''
        break
      default:
        newCode = ''
    }
    return newCode
  }

  const getLink = (schema: string, classificationCode: ClassificationCode) => {
    let link: string = ''
    switch (schema) {
      case 'naics':
        let code = getInputCode(schema, classificationCode.get('code'))
        if (!!code) {
          link = `https://www.census.gov/naics/?${qs.stringify({
            input: code,
            details: code,
            year: 2017,
          })}`
        }
        break
      default:
        link = ''
    }
    return link
  }

  const getName = (schema: string, classificationCode: ClassificationCode) => {
    let name: React.ReactNode = ''
    switch (schema) {
      case 'naics':
        let code = getInputCode(schema, classificationCode.get('code'))
        if (!!code) {
          name = <FormattedMessage {...naics[code]} />
        }
        break
      default:
        name = classificationCode.get('name')
    }
    return name
  }

  const logNaicsClick = (code?: string, name?: string, schema?: string) => {
    if (!isSupplierApp) {
      analytics.track('NAICS Code Clicked', {
        eventSource: 'Supplier Profile',
        action: 'Clicked',
        orgUnitId,
        orgUnitName,
        code,
        name,
        schema,
      })
    }
  }

  return classificationCodesBySchema ? (
    <div>
      {schemaOrder &&
        schemaOrder.map((schema) => {
          const codeList = classificationCodesBySchema.get(schema)
          return (
            codeList && (
              <Fragment key={`${schema}`}>
                <h4 className='db mt3 mb1 f7 fw6'>
                  {classificationCodeSchemas[schema] ? (
                    <FormattedMessage {...classificationCodeSchemas[schema]} />
                  ) : (
                    `${schema.toUpperCase()} Code`
                  )}
                </h4>
                <Scrollable role='list' maxHeight={180}>
                  {codeList
                    .sort((a, b) => (a.get('code') < b.get('code') ? -1 : 1))
                    .map((classificationCode) => (
                      <div
                        key={classificationCode.get('code')}
                        className='dt mb2 dt--fixed'
                        role='listitem'
                        onClick={() =>
                          logNaicsClick(
                            classificationCode.get('code'),
                            classificationCode.get('name'),
                            classificationCode.get('schema')
                          )
                        }
                      >
                        <div className='dtc w3'>
                          <Text>
                            {getLink(schema, classificationCode) ? (
                              <ExternalLink
                                href={getLink(schema, classificationCode)}
                                aria-label={classificationCode.get('code')}
                              >
                                {classificationCode.get('code')}
                              </ExternalLink>
                            ) : (
                              classificationCode.get('code')
                            )}
                          </Text>
                        </div>
                        <div className='dtc w-auto'>
                          <Text>
                            {getLink(schema, classificationCode) ? (
                              <ExternalLink
                                href={getLink(schema, classificationCode)}
                                aria-label={classificationCode.get('name')}
                              >
                                {getName(schema, classificationCode)}
                              </ExternalLink>
                            ) : (
                              getName(schema, classificationCode)
                            )}
                          </Text>
                        </div>
                        {editable && removeClassificationCode && (
                          <div className='dtc w2'>
                            <IconButton
                              aria-label='clear'
                              onClick={() =>
                                removeClassificationCode({
                                  schema: classificationCode.get('schema'),
                                  code: classificationCode.get('code'),
                                })
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    ))}
                </Scrollable>
              </Fragment>
            )
          )
        })}
    </div>
  ) : null
}

export default ClassificationCodes
