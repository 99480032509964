import { connect } from 'react-redux'
import RootState from 'shared/models/RootState'
import {
  openAddOutreachContactForm,
  cancelAddingOutreachContact,
  loadSupplierProfile
} from '../../../../buyer/SupplierProfile/actions'
import BulkOutreach from 'buyer/Insights/components/BulkOutreach'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import bulkActionsSelectors from 'buyer/Insights/store/bulkActionsSelectors'

export default connect(
  (state: RootState) => ({
    currentOrgName: settingsSelectors.getName(state),
    loading: bulkActionsSelectors.isLoading(state),
    suppliers: bulkActionsSelectors.getSuppliers(state),
    isCreatingOutreach: bulkActionsSelectors.isCreatingOutreach(state),
    isSupplierProfileLoadDone: bulkActionsSelectors.isSupplierProfileLoadDone(
      state
    ),
    newUser: bulkActionsSelectors.getNewUser(state)
  }),
  {
    onClickNewUser: openAddOutreachContactForm,
    onClickCancel: cancelAddingOutreachContact,
    loadSupplierProfile: loadSupplierProfile
  }
)(BulkOutreach)
