import * as api from '../api'
import apiRoutes from '../../../routes/apiRoutes'

const apiRoute = `${apiRoutes.authService}/staging`

export const getStagingHistory = ({ listType }) => {
  return api.get(`${apiRoute}/list/${listType}`).then(list => {
    return list && parseStagingHistory(list)
  })
}

export const getStagingDetails = ({ stagingId }) => {
  return api.get(`${apiRoute}/lookup/${stagingId}`)
}

export const removeSupplierSpend = ({
  stagingId,
  listType,
  buyersImpacted
}) => {
  return api.remove(`${apiRoute}/${stagingId}`, { buyersImpacted }).then(() => {
    return getStagingHistory({ listType })
  })
}

export const publishSupplierSpendT2 = ({ stagingId, listType, buyerIds }) => {
  return api
    .put(`${apiRoute}/publishV2/${stagingId}`, { buyerIds })
    .then(() => {
      return getStagingHistory({ listType })
    })
}

export const publishClientSpend = ({ stagingId, listType }) => {
  return api
    .post(`${apiRoutes.loader}/client/publishCatalog/${stagingId}`)
    .then(() => {
      return getStagingHistory({ listType })
    })
}

const parseStagingHistory = stagingHistory => {
  return stagingHistory.map(({ created, ...rest }) => ({
    uploadDate: new Date(created.date),
    uploadBy: created.user,
    userId: created.user,
    ...rest
  }))
}

export const remapStagingRow = ({ stagingRowId, orgUnitId }) => {
  return api.post(`${apiRoute}/rows/update/${stagingRowId}`, { orgUnitId })
}

export const getDiverseSummaryList = () => {
  return api.get(`${apiRoute}/ui/list/`).then(list => {
    return list
  })
}

export const createDiverseSummary = summary => {
  return api.post(`${apiRoute}/ui/create`, summary)
}

export const removeDiverseSummary = loadId => {
  return api.remove(`${apiRoute}/ui/remove?loadId=${loadId}`)
}
export const addBuyerTier2 = ({ stagingId, buyerId }) => {
  return api.put(`${apiRoute}/addBuyer/${stagingId}`, { buyerId })
}
