import React, { Component } from 'react'
import Select from '../Select'
import { SelectProps } from '../Select/Select'

interface Props extends SelectProps {
  placeholder: string
  options: any
}

class SelectFromList extends Component<Props> {
  render() {
    const { options, placeholder, ...rest } = this.props

    return (
      <Select {...rest} fullWidth>
        {placeholder && <option value={placeholder}>{placeholder}</option>}
        {options.map(option => (
          <option key={option.get('key')} value={option.get('key')}>
            {option.get('label')}
          </option>
        ))}
      </Select>
    )
  }
}

export default SelectFromList
