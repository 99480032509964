import React, { ReactChild } from 'react'
import AppLogoContainer from 'shared/containers/AppLogoContainer'
import { ReactComponent as Step1 } from './step1.svg'
import { ReactComponent as Step2 } from './step2.svg'
import { ReactComponent as Step3 } from './step3.svg'
import ipad from './image-ipad.svg'
import survey from './image-survey.png'
import yellowTriangle from './yellow-triangle.svg'
import lightTealTriangle from './light-teal-triangle.svg'
import { FormattedMessage } from 'react-intl'
import ExternalLink from 'shared/components/ExternalLink'

type Props = {
  children: ReactChild
  client?: string
  iframe?: boolean
}

export const SustainabilityLandingPage = (props: Props) => {
  const { children } = props

  return (
    <div>
      <div className='bg-teal pt2 pb5-ns'>
        <div className='mw8 center ph3-ns'>
          <div className='tl mv3 mv4-ns mh3 mh0-ns'>
            <AppLogoContainer className='w4 w5-ns' white />
          </div>
          <div className='flex-ns justify-between'>
            <div className='w-100 w-two-thirds-ns pa3 pa0-ns tl'>
              <h1 className='f2 f1-ns lh-title white fw6 mt0'>
                <FormattedMessage
                  id='SustainabilityLandingPage.Title'
                  defaultMessage={`Create your free TealBook Account!`}
                />
              </h1>
              <p className='lh-copy fw4 ma0 f5 f4-ns white'>
                <FormattedMessage
                  id='SustainabilityLandingPage.Message'
                  defaultMessage={`Your customer has invited you to complete <b>TealBook's Sustainability Survey</b> so they can learn more about your company's environmental, social, and/or governance related initiatives.`}
                  values={{
                    b: b => <span className='fw8'>{b}</span>
                  }}
                />
              </p>
              <ExternalLink
                href='https://tealbookhelp.zendesk.com/hc/en-us/articles/4418116788500-Sustainability-Survey'
                className='black ttu mv3 mt4-ns dib ph3 pv2 ph4-ns pv3-ns bg-near-white br-pill f4 f3-ns'
              >
                <FormattedMessage
                  id='SustainabilityLandingPage.LearnMore'
                  defaultMessage={`Learn More`}
                />
              </ExternalLink>
            </div>
            <div>{children}</div>
          </div>
        </div>
      </div>
      <section
        className='tl bg-near-white'
        style={{
          backgroundImage: `url(${lightTealTriangle})`,
          backgroundPosition: `top right`,
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='mw8 center ph3 pt2 pb4 pv4 pv5-ns'>
          <label className='f4 f3-ns fw6'>
            <FormattedMessage
              id='SustainabilityLandingPage.Instructions'
              defaultMessage={`Instructions`}
            />
          </label>
          <ol className='list pa0 flex-ns justify-between'>
            <li className='tc tl-ns'>
              <div className='dib tl'>
                <h3 className='mb0 f4'>1.</h3>
                <Step1 className='w5' aria-hidden='true' />
                <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
                  <FormattedMessage
                    id='SustainabilityLandingPage.Instructions1'
                    defaultMessage={`Sign up for a free TealBook account with your email`}
                  />
                </p>
              </div>
            </li>
            <li className='tc tl-ns'>
              <div className='dib tl'>
                <h3 className='mb0 f4'>2.</h3>
                <Step2 className='w5' aria-hidden='true' />
                <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
                  <FormattedMessage
                    id='SustainabilityLandingPage.Instructions2'
                    defaultMessage={`Navigate to the Chat box in the top right corner of the screen`}
                  />
                </p>
              </div>
            </li>
            <li className='tc tl-ns'>
              <div className='dib tl'>
                <h3 className='mb0 f4'>3.</h3>
                <Step3 className='w5' aria-hidden='true' />
                <p className='lh-copy fw4 dark-gray ma0 w5 f5 tc mt3'>
                  <FormattedMessage
                    id='SustainabilityLandingPage.Instructions3'
                    defaultMessage={`Click the link in the chat from your customer to start the survey!`}
                  />
                </p>
              </div>
            </li>
          </ol>
        </div>
      </section>
      <section
        className='tl bg-white'
        style={{
          backgroundImage: `url(${yellowTriangle})`,
          backgroundPositionY: '-468px',
          backgroundPositionX: 'right',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='mw8 center pt2 pt4 pt5-ns ph3'>
          <div className='flex flex-wrap justify-between'>
            <div className='w-two-thirds-ns'>
              <label className='f4 f3-ns fw6'>
                <FormattedMessage
                  id='SustainabilityLandingPage.WhatIsTealbook'
                  defaultMessage={`What is TealBook?`}
                />
              </label>
              <p className='lh-copy fw4 mid-gray ma0 f5 f4-ns mt4 mr4'>
                <FormattedMessage
                  id='SustainabilityLandingPage.TealbookMessage'
                  defaultMessage={`TealBook is a platform that your customers are using to get more information about suppliers like you. Customers also use TealBook to find and discover new suppliers that fulfill their needs. For more information on what TealBook is and why you don't want to miss out, <link>check out this article</link>.`}
                  values={{
                    link: link => (
                      <ExternalLink
                        className='f5 f4-ns fw8'
                        href='https://tealbookhelp.zendesk.com/hc/en-us/articles/360050807492-Joining-Tealbook-What-s-in-it-for-me-'
                      >
                        {link}
                      </ExternalLink>
                    )
                  }}
                />
              </p>
            </div>
            <div className='w-100 w-third-ns'>
              <img src={ipad} alt='ipad' width='100%' aria-hidden='true' />
            </div>
          </div>
        </div>
      </section>
      <section className='tl bg-near-white'>
        <div className='mw8 center pt2 pb4 pv4 pv5-ns ph3'>
          <div className='flex flex-wrap justify-between'>
            <div className='w-100 w-third-ns'>
              <img
                src={survey}
                alt='sustainability survey'
                width='100%'
                aria-hidden='true'
              />
            </div>
            <div className='w-two-thirds-ns'>
              <label className='f4 f3-ns fw6 ml4-ns mt3 db'>
                <FormattedMessage
                  id='SustainabilityLandingPage.WhatIsSustainability'
                  defaultMessage={`What is the TealBook sustainability survey?`}
                />
              </label>
              <p className='lh-copy fw4 mid-gray ma0 f5 f4-ns mt4 ml4-ns'>
                <FormattedMessage
                  id='SustainabilityLandingPage.SustainabilityMessage1'
                  defaultMessage={`The TealBook Sustainability Survey is a 5 part assessment 
                  questionnaire containing questions relating to your 
                  organization's <b>Health and Safety</b> measures, <b>Environment</b>, 
                  <b>Diversity and Inclusion</b>, <b>Governance</b> and <b>Privacy</b> policies. By 
                  completing this survey, your company will also be discoverable to 
                  more potential customers!`}
                  values={{
                    b: b => <span className='fw8'>{b}</span>
                  }}
                />
              </p>
              <p className='lh-copy fw4 mid-gray ma0 f5 f4-ns mt4 ml4-ns'>
                <FormattedMessage
                  id='SustainabilityLandingPage.SustainabilityMessage2'
                  defaultMessage={`For more information on what sustainability is and what the 
                  survey entails, <link>check out this article</link>.`}
                  values={{
                    link: link => (
                      <ExternalLink
                        className='f5 f4-ns fw8'
                        href='https://tealbookhelp.zendesk.com/hc/en-us/articles/4418116788500-Sustainability-Survey'
                      >
                        {link}
                      </ExternalLink>
                    )
                  }}
                />
              </p>
              <p className='lh-copy fw4 mid-gray f6 f5-ns mt4 ml4-ns'>
                <FormattedMessage
                  id='SustainabilityLandingPage.SustainabilityQuestion'
                  defaultMessage={`If you have any questions, comments, or concerns, please reach out to <link>suppliersupport@tealbook.com</link>.`}
                  values={{
                    link: link => (
                      <a
                        className='fw8'
                        href='mailto:suppliersupport@tealbook.com'
                      >
                        {link}
                      </a>
                    )
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SustainabilityLandingPage
