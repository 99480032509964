import React, { Fragment, useState, useEffect, useCallback } from 'react'
import ExternalLink from 'shared/components/ExternalLink'
import UserProfileCard from './UserProfileCard'
import Avatar from 'shared/components/Avatar'
import Immutable, { fromJS, List, Map, RecordOf } from 'immutable'
import Page from 'shared/components/Page'
import EditProfileButton from 'shared/components/EditProfileButton'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import SupplierConnectionsContainer from '../../containers/SupplierConnectionsContainer'
import TagCloudContainer from '../../containers/TagCloudContainer'
import VetsListContainer from '../../containers/VetsListContainer'
import CommentsAboutUser from '../../containers/CommentsAboutUser'
import ChangePassword from '../ChangePassword'
import MessageIcon from '@material-ui/icons/MessageOutlined'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import OrgLogo from 'shared/components/OrgLogo'
import Link from 'shared/components/Link'
import buyerRoutes from 'buyer/routes/paths'
import ScrollToTopOnMount from 'shared/components/ScrollToTopOnMount'
import BlockIcon from '@material-ui/icons/Block'
import Paper from 'shared/components/Paper'
import Button from 'shared/components/Button'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import IphoneIcon from '@material-ui/icons/PhoneIphone'
import LocationIcon from '@material-ui/icons/LocationOn'
import UserListItem from 'shared/components/UserListItem'
import withUser from 'shared/utils/withUser'
import Checkbox from 'shared/components/Checkbox'
import ConnectMessageDialogContainer from 'buyer/shared/containers/ConnectMessageDialogContainer'
import buyerPaths from 'buyer/routes/paths'
import parsePath from 'shared/utils/parsePath'
import SingleConnectionIcon from 'shared/assets/icons/connected-one.svg'
import MultiConnectionIcon from 'shared/assets/icons/connected-multi.svg'
import PrivateConnectionIcon from 'shared/assets/icons/connected-private.svg'
import Tooltip from 'shared/components/Tooltip'
import { ColorExtractor } from 'react-color-extractor'
import Address from 'shared/components/Address'
import Location from 'shared/models/Location'
import Scrollable from 'shared/components/Scrollable'
import EditUserRoles from '../EditUserRoles'
import BlockUser from '../BlockUser'
import ClearbitCredit from 'shared/components/ClearbitCredit'
import SocialFeed from 'buyer/SupplierProfile/components/SocialFeed'
import camelCase from 'lodash.camelcase'
import LanguageIcon from '@material-ui/icons/Language'
import supplierRoutes from '../../../supplier/routes/paths'
import Label from 'shared/components/Label'
import { LicenseType } from 'shared/models/User'
import { buyerRoles, supplierRoles } from '../EditUserRoles/roles'

const styles = {
  fieldWrapper: 'mb2 mh2',
  suggestText: 'fw4 gray i pointer bn bg-transparent',
  fieldText: 'gray'
}

const ConnectedUserListItem = withUser(UserListItem)

const messages = defineMessages({
  rightAway: {
    id: 'UserProfile.RightAway',
    defaultMessage: 'Right Away'
  },
  daily: {
    id: 'UserProfile.Daily',
    defaultMessage: 'Daily'
  },
  weekly: {
    id: 'UserProfile.Weekly',
    defaultMessage: 'Weekly'
  },
  never: {
    id: 'UserProfile.Never',
    defaultMessage: 'Never'
  }
})

type Props = {
  // user data
  userId: string
  firstName: string
  lastName: string
  email: string
  title?: string
  department?: string
  officePhone?: string
  mobilePhone?: string
  location?: RecordOf<Location> | string
  profilePictureUrl?: string
  linkedInUrl?: string
  disableAllEmails?: boolean
  options: RecordOf<{
    message: {
      frequency: string
    }
  }>
  contactFor?: string
  onClickMessage?: () => void
  orgUnit: Map<any, any>
  isPublic?: boolean
  status?: string
  lastLogin: Date
  roles: List<string>
  canEditProfile?: boolean
  canChangePassword?: boolean
  language: string
  firstLogin?: string
  isCurrentUser?: boolean
  isCurrentUserBuyer?: boolean
  isConnected?: boolean
  isPrivate?: boolean
  isBuyer?: boolean
  isSupplier?: boolean
  colleagueIsConnected?: boolean
  connectedCards?: List<Map<string, any>>
  created?: Date
  licenseType?: LicenseType

  // other props
  onEdit?: () => void
  showMetrics?: boolean
  showComments?: boolean
  canMessage?: boolean
  isTealbot?: boolean
  isBuyerAdmin?: boolean
  isSupplierAdmin?: boolean
  isOrgAdmin?: boolean
  isServerAdmin?: boolean
  isClientApp?: boolean
  blocked?: boolean
  getCardsBySupplierId: ({ supplierId: string }) => void
  feed?: List<any>
  onUpdateStatus: (arg: string) => void
  onUpdateRoles: (arg: any) => void
  updateContact: (
    payload: RecordOf<{
      supplierId?: string
      user: { id: string }
      connected: boolean
      connectFor: string
    }>
  ) => void
  markPostRead: (postId: string) => void
  basePath: string
}

export const UserProfile = (props: Props) => {
  const [orgLogoBlobUrl, setOrgLogoBlobUrl] = useState<string | null>(null)
  const [openConnectDialog, setOpenConnectDialog] = useState<boolean>(false)
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState<boolean>(false)
  const [isRolesDialogOpen, setIsRolesDialogOpen] = useState<boolean>(false)
  const [rolesState, setRolesState] = useState<List<string>>(
    props.roles || List([])
  )
  const intl = useIntl()

  const {
    onEdit,
    userId,
    firstName,
    lastName,
    email,
    title,
    roles,
    isPublic,
    status,
    officePhone,
    mobilePhone,
    location,
    department,
    profilePictureUrl,
    linkedInUrl,
    disableAllEmails,
    options,
    showMetrics,
    showComments,
    canEditProfile,
    canChangePassword,
    isTealbot,
    isBuyerAdmin,
    isSupplierAdmin,
    canMessage,
    onClickMessage,
    onUpdateStatus,
    language,
    isCurrentUser,
    orgUnit,
    isClientApp,
    blocked,
    connectedCards,
    isConnected,
    isPrivate,
    isBuyer,
    isSupplier,
    isOrgAdmin,
    isServerAdmin,
    isCurrentUserBuyer,
    colleagueIsConnected,
    firstLogin,
    feed,
    markPostRead,
    getCardsBySupplierId,
    lastLogin,
    created,
    basePath,
    licenseType
  } = props

  const defaultBannerColor = '#00bcab'

  const setBannerColor = useCallback((color: string | [string]) => {
    const banner = document.querySelector('#profile-banner') as HTMLElement
    if (banner) {
      banner.style.backgroundColor =
        typeof color === 'string' ? color : color[0]
    }
  }, [])

  useEffect(() => {
    const logoUrl = orgUnit.get('logoUrl')
    if (logoUrl) {
      fetch(logoUrl)
        .then(response => {
          return response.blob()
        })
        .then(blob => {
          const imageUrl = URL.createObjectURL(blob)
          setOrgLogoBlobUrl(imageUrl)
        })
        .catch(e => {
          setBannerColor(defaultBannerColor)
        })
    } else {
      setBannerColor(defaultBannerColor)
    }
  }, [orgUnit, setBannerColor])

  useEffect(() => {
    if (isCurrentUserBuyer && (!connectedCards || !connectedCards.size)) {
      getCardsBySupplierId({ supplierId: orgUnit.get('id') })
    }
  }, [getCardsBySupplierId, connectedCards, orgUnit, isCurrentUserBuyer])

  const toggleConnectDialog = () => {
    setOpenConnectDialog(!openConnectDialog)
  }

  const handleDisconnect = () => {
    const { updateContact, userId, orgUnit } = props
    updateContact(
      fromJS({
        supplierId: orgUnit.get('id'),
        user: { id: userId },
        connected: false,
        isPrivate: true,
        connectFor: ''
      })
    )
  }

  const handleCloseDialogStatus = () => {
    setIsStatusDialogOpen(false)
  }

  const handleUpdateStatus = () => {
    if (status === 'Blocked') {
      onUpdateStatus(lastLogin ? 'Normal' : 'Registered')
    } else {
      setIsStatusDialogOpen(true)
    }
  }

  const blockUser = () => {
    setIsStatusDialogOpen(true)
    const { onUpdateStatus } = props
    onUpdateStatus('Blocked')
    handleCloseDialogStatus()
  }

  const handleEditRoles = () => {
    const { roles } = props
    setIsRolesDialogOpen(true)
    setRolesState(roles)
  }

  const handleCloseDialogRoles = () => {
    setIsRolesDialogOpen(false)
  }

  const handleUpdateRoles = () => {
    setIsRolesDialogOpen(true)
    const { onUpdateRoles } = props
    onUpdateRoles(rolesState.toJS())
    setIsRolesDialogOpen(false)
  }

  const toggleRole = e => {
    let tempRoles = rolesState.slice()
    const index = tempRoles.indexOf(e.target.name)
    // if deselect buyer or supplier, deselects related roles
    // if select a role in buyer or supplier group but not 'buyer' or 'supplier', selects buyer or supplier
    if (index > 0 && e.target.name === 'buyer') {
      tempRoles = tempRoles.filter(role => !role.startsWith('buyer'))
    }
    if (index > 0 && e.target.name === 'supplier') {
      tempRoles = tempRoles.filter(role => !role.startsWith('supplier'))
    }
    if (index === -1) {
      tempRoles = tempRoles.push(e.target.name)
      if (
        tempRoles.some(role => Object.keys(buyerRoles).includes(role)) &&
        !tempRoles.includes('buyer')
      ) {
        tempRoles = tempRoles.push('buyer')
      }
      if (
        tempRoles.some(role => Object.keys(supplierRoles).includes(role)) &&
        !tempRoles.includes('supplier')
      ) {
        tempRoles = tempRoles.push('supplier')
      }
    } else {
      tempRoles = tempRoles.delete(index)
    }
    setRolesState(tempRoles)
  }

  const handleShareChange = e => {
    const { updateContact, userId, orgUnit } = props
    updateContact(
      fromJS({
        supplierId: orgUnit.get('id'),
        user: { id: userId },
        isPrivate: e.currentTarget.checked
      })
    )
  }

  const [, domain] = email?.split('@') || []
  return (
    <Page
      title={
        isCurrentUser ? 'My Account' : `${firstName || ''} ${lastName || ''}`
      }
    >
      {orgLogoBlobUrl && (
        <ColorExtractor src={orgLogoBlobUrl} getColors={setBannerColor} />
      )}
      {canEditProfile && (
        <div className='tr pt2'>
          <EditProfileButton onClick={onEdit} />
        </div>
      )}
      <ScrollToTopOnMount />
      <Main>
        <Paper noPadding>
          <div className='h4 tr relative'>
            <div
              id='profile-banner'
              className='animate-background o-30 absolute top-0 right-0 left-0 bottom-0'
              style={{ backgroundColor: '#eee' }}
            />
            <h5 className='ma3 mb2 dib f5 fw1'>
              {isPublic ? (
                <FormattedMessage
                  id='UserProfile.Public'
                  defaultMessage='Public Profile'
                />
              ) : (
                <FormattedMessage
                  id='UserProfile.PrivateProfile'
                  defaultMessage='Private Profile'
                />
              )}
              &nbsp;
              {isBuyer && isSupplier ? (
                <FormattedMessage
                  id='UserProfile.BuyerAndSupplier'
                  defaultMessage='(Buyer and Supplier)'
                />
              ) : isBuyer ? (
                <FormattedMessage
                  id='UserProfile.Buyer'
                  defaultMessage='(Buyer)'
                />
              ) : (
                <FormattedMessage
                  id='UserProfile.Supplier'
                  defaultMessage='(Supplier)'
                />
              )}
            </h5>
            {isTealbot && created && (
              <div className='f8 fw4 mr3'>
                <FormattedMessage
                  id='UserProfile.ProfileCreated'
                  defaultMessage='Profile created {created}'
                  values={{ created: created.toLocaleDateString() }}
                />
              </div>
            )}
            {firstLogin && (
              <div className='f8 fw4 mr3'>
                <FormattedMessage
                  id='UserProfile.MemberSince'
                  defaultMessage='Member since {firstLogin}'
                  values={{ firstLogin }}
                />
              </div>
            )}
          </div>
          <div className='flex nt5 ph3 pb3 relative items-start justify-between'>
            <Avatar
              url={profilePictureUrl}
              className='w4 w4'
              name={`${firstName || ''} ${lastName || ''}`}
            />
            <div className='w-100 mt5 pt2 db flex justify-between items-center'>
              <div className='dib'>
                {isConnected &&
                  (isPrivate ? (
                    <Tooltip
                      title={
                        <FormattedMessage
                          id='UserProfile.PrivateConnection'
                          defaultMessage='Your private connection'
                        />
                      }
                    >
                      <img
                        src={PrivateConnectionIcon}
                        alt='private connection'
                        className='w1 ml2'
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <FormattedMessage
                          id='UserProfile.YourConnection'
                          defaultMessage='Your shared connection'
                        />
                      }
                    >
                      <img
                        src={SingleConnectionIcon}
                        alt='Connected'
                        className='w1 ml2'
                      />
                    </Tooltip>
                  ))}

                {colleagueIsConnected && (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id='UserProfile.ColleaguesConnection'
                        defaultMessage='Colleagues connection'
                      />
                    }
                  >
                    <img
                      src={MultiConnectionIcon}
                      alt='colleagues connection'
                      className='w1 ml2'
                    />
                  </Tooltip>
                )}
              </div>

              {orgUnit && orgUnit.size > 0 && (
                <div className='flex items-center'>
                  {isClientApp || isCurrentUserBuyer ? (
                    <>
                      <Link
                        to={buyerRoutes.supplierProfile}
                        params={{ supplierId: orgUnit.get('id') }}
                        ariaLabel={`Link to ${orgUnit.get('name')}`}
                      >
                        <div className='w2 h2 mr2 flex items-center'>
                          <OrgLogo url={orgUnit.get('logoUrl')} />
                        </div>
                      </Link>
                      <Link
                        to={buyerRoutes.supplierProfile}
                        params={{ supplierId: orgUnit.get('id') }}
                      >
                        <span className='f6 fw4 mid-gray'>
                          {orgUnit.get('name')}
                        </span>
                      </Link>
                    </>
                  ) : (
                    <>
                      <div className='w2 h2 mr2 flex items-center'>
                        <OrgLogo url={orgUnit.get('logoUrl')} />
                      </div>
                      <span className='f6 fw4 mid-gray'>
                        {orgUnit.get('name')}
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='ph3 pb3'>
            <div className='dib w-100 w-50-ns v-top'>
              <div className={styles.fieldWrapper}>
                {(firstName || lastName) && (
                  <h3 className='flex items-center f4 fw4 gray mv0'>
                    {firstName} {lastName}
                    {blocked && (
                      <Tooltip
                        title={
                          <FormattedMessage
                            id='UserProfile.Inactive'
                            defaultMessage='Inactive'
                          />
                        }
                      >
                        <BlockIcon fontSize='small' className='ml2' />
                      </Tooltip>
                    )}
                  </h3>
                )}

                {canEditProfile && isCurrentUser && !firstName && !lastName && (
                  <button
                    className={`f4 ${styles.suggestText}`}
                    onClick={onEdit}
                  >
                    <FormattedMessage
                      id='UserProfile.FullName'
                      defaultMessage='Add your full name'
                    />
                  </button>
                )}
              </div>

              <div className={styles.fieldWrapper}>
                {!!title && <div className='f5 fw4 gray'>{title}</div>}

                {canEditProfile && isCurrentUser && !title && (
                  <button
                    className={`f5 ${styles.suggestText}`}
                    onClick={onEdit}
                  >
                    <FormattedMessage
                      id='UserProfile.AddRole'
                      defaultMessage='Add your current role'
                    />
                  </button>
                )}
              </div>

              <div className={styles.fieldWrapper}>
                {!!department && (
                  <div className='f5 fw4 gray'>{department}</div>
                )}

                {canEditProfile && isCurrentUser && !department && (
                  <button
                    className={`f5 ${styles.suggestText}`}
                    onClick={onEdit}
                  >
                    <FormattedMessage
                      id='UserProfile.AddDepartment'
                      defaultMessage='Add your department'
                    />
                  </button>
                )}
              </div>

              {isTealbot && (
                <Fragment>
                  {roles && (
                    <div className={styles.fieldWrapper}>
                      <span className={`${styles.fieldText}`}>
                        <FormattedMessage
                          id='UserProfile.Roles'
                          defaultMessage='Roles'
                        />
                        : {roles && roles.join(', ')}
                      </span>
                    </div>
                  )}
                  {licenseType && (
                    <div className={styles.fieldWrapper}>
                      <span className={`${styles.fieldText}`}>
                        <FormattedMessage
                          id='UserProfile.LicenseType'
                          defaultMessage='License Type'
                        />
                        : {licenseType}
                      </span>
                    </div>
                  )}
                  {status && (
                    <div className={styles.fieldWrapper}>
                      <span className={`${styles.fieldText}`}>
                        <FormattedMessage
                          id='UserProfile.Status'
                          defaultMessage='Status'
                        />
                        : {status}
                      </span>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
            <div className='dib w-100 w-50-ns v-top'>
              <div className={styles.fieldWrapper}>
                {!!email &&
                  (blocked ? (
                    <div className='flex items-center f6 fw4 gray truncate'>
                      <EmailIcon fontSize='small' />
                      <span className={`ml2 f6 fw4 strike`}>{email}</span>
                    </div>
                  ) : (
                    <div className='flex items-center f6 fw4 gray truncate'>
                      <EmailIcon fontSize='small' />
                      <a
                        className={`ml2 teal no-underline dim`}
                        href={`mailto:${email}`}
                      >
                        {email}
                      </a>
                    </div>
                  ))}
              </div>
              <div className={styles.fieldWrapper}>
                {!!officePhone && (
                  <div className='flex items-center f6 fw4 gray'>
                    <PhoneIcon fontSize='small' />
                    <a
                      className='ml2 teal no-underline dim'
                      href={`tel:${officePhone}`}
                    >
                      {officePhone}
                    </a>
                  </div>
                )}

                {!officePhone && isCurrentUser && canEditProfile && (
                  <button
                    className={`f6 ${styles.suggestText}`}
                    onClick={onEdit}
                  >
                    <FormattedMessage
                      id='UserProfile.AddAOfficeNumber'
                      defaultMessage='Add a office number'
                    />
                  </button>
                )}
              </div>

              <div className={styles.fieldWrapper}>
                {!!mobilePhone && (
                  <div className='flex items-center f6 fw4 gray'>
                    <IphoneIcon fontSize='small' />
                    <a
                      className='ml2 teal no-underline dim'
                      href={`tel:${mobilePhone}`}
                    >
                      {mobilePhone}
                    </a>
                  </div>
                )}

                {!mobilePhone && isCurrentUser && canEditProfile && (
                  <button
                    className={`f6 ${styles.suggestText}`}
                    onClick={onEdit}
                  >
                    <FormattedMessage
                      id='UserProfile.AddAMobileNumber'
                      defaultMessage='Add a mobile number'
                    />
                  </button>
                )}
              </div>

              <div className={styles.fieldWrapper}>
                {location &&
                Immutable.isImmutable(location) &&
                location.get('address') ? (
                  <div className='flex items-center f6 fw4 gray'>
                    <LocationIcon fontSize='small' />
                    <Address noIcon location={location} className='ml2 dib' />
                  </div>
                ) : canEditProfile && isCurrentUser ? (
                  <button
                    className={`f6 ${styles.suggestText}`}
                    onClick={onEdit}
                  >
                    <FormattedMessage
                      id='UserProfile.AddCurrentWorkingLocation'
                      defaultMessage='Add your current working location'
                    />
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          {(canMessage || isConnected) && (
            <div className='flex items-center bg-near-white pa3'>
              {canMessage && (
                <Button
                  autoSize
                  secondary
                  onClick={onClickMessage}
                  disabled={blocked}
                  label={
                    <div className='dib flex items-center'>
                      <MessageIcon />
                      &nbsp;
                      <FormattedMessage
                        id='UserProfile.Chat'
                        defaultMessage='Chat'
                      />
                    </div>
                  }
                />
              )}
              {isConnected && !blocked && (
                <div className='ml3'>
                  <FormattedMessage
                    id='UserProfile.KeptPrivate'
                    defaultMessage='Keep connection private'
                  >
                    {message => (
                      <Checkbox
                        labelFontLight
                        label={message}
                        checked={isPrivate === true}
                        onChange={handleShareChange}
                      />
                    )}
                  </FormattedMessage>
                </div>
              )}
            </div>
          )}
        </Paper>

        {showMetrics && (
          <div className='pt4-ns'>
            <TagCloudContainer
              userId={userId}
              title={
                <FormattedMessage
                  id='UserProfile.Interests'
                  defaultMessage='Interests'
                />
              }
            />
          </div>
        )}
        {((basePath.includes('/client') && isSupplier) ||
          basePath.includes('/supplier')) && (
          <div className='pt4'>
            <Paper>
              <Label>
                <FormattedMessage
                  id='UserProfile.NewUserMain'
                  defaultMessage='Not sure how to add a new user or service account? ie. marketing@ {domain}'
                  values={{ domain }}
                />
              </Label>

              <Link
                to={`${supplierRoutes.company}?scrollToOurTeamSection=true`}
                className='teal f6 mt2'
              >
                <FormattedMessage
                  id='UserProfile.NewUserClickHere'
                  defaultMessage='Click here to be taken to Our Team Section on the Company page'
                />
              </Link>
            </Paper>
          </div>
        )}

        {showComments && <CommentsAboutUser userId={userId} />}
      </Main>

      <Aside>
        {canChangePassword && <ChangePassword userId={userId} />}
        <div className='mb4-ns'>
          <UserProfileCard
            title={
              <FormattedMessage
                id='UserProfile.AdditionalInfo'
                defaultMessage='Additional Information'
              />
            }
          >
            <div className='flex items-center'>
              <Tooltip
                title={
                  <FormattedMessage
                    id='UserProfile.Language'
                    defaultMessage='Language'
                  />
                }
              >
                <LanguageIcon
                  className='mr2'
                  aria-label='Language'
                  role='img'
                  tabIndex={0}
                  aria-hidden={false}
                />
              </Tooltip>
              <span className='f7 fw4 mid-gray ma0 lh-copy'>{language}</span>
            </div>
            {linkedInUrl && (
              <div>
                <ExternalLink
                  href={
                    linkedInUrl.indexOf('/') === -1
                      ? `www.linkedin.com/in/${linkedInUrl}`
                      : linkedInUrl
                  }
                >
                  <FormattedMessage
                    id='UserProfile.LinkedInProfile'
                    defaultMessage='LinkedIn Profile'
                  />
                </ExternalLink>
              </div>
            )}
            <div>
              <ExternalLink href={`https://tealbook.com/terms`}>
                <FormattedMessage
                  id='UserProfile.termsOfService'
                  defaultMessage='Terms of Service'
                />
              </ExternalLink>
            </div>
          </UserProfileCard>
        </div>

        {((connectedCards && connectedCards.size > 0) ||
          ((isClientApp || isCurrentUserBuyer) &&
            !isCurrentUser &&
            !blocked)) && (
          <div className='mt3 mt0-ns mb3 mb4-ns'>
            <UserProfileCard
              title={
                <FormattedMessage
                  id='UserProfile.ConnectedWith'
                  defaultMessage='Connected With'
                />
              }
            >
              {connectedCards && (
                <Scrollable>
                  {connectedCards.toList().map(card => {
                    const contact = card
                      .get('contacts')
                      .find(contact => contact.get('user') === userId)

                    return (
                      <FormattedMessage
                        id='UserProfile.ContactFor'
                        defaultMessage='Contact for:'
                        key={card.get('owner')}
                      >
                        {message => (
                          <ConnectedUserListItem
                            className='mv2'
                            userId={card.get('owner')}
                            profileUrl={parsePath(buyerPaths.userprofile, {
                              userId: card.get('owner')
                            })}
                            mini
                            secondaryText={
                              !!contact.get('contactFor')
                                ? `${message} ${contact.get('contactFor')}`
                                : undefined
                            }
                          />
                        )}
                      </FormattedMessage>
                    )
                  })}
                </Scrollable>
              )}
              {(isClientApp || isCurrentUserBuyer) && !isCurrentUser && (
                <div className='mt2'>
                  {!isConnected ? (
                    !blocked ? (
                      <FormattedMessage
                        id='UserProfile.Connect'
                        defaultMessage='Connect'
                      >
                        {message => (
                          <Button
                            label={message}
                            onClick={toggleConnectDialog}
                          />
                        )}
                      </FormattedMessage>
                    ) : null
                  ) : (
                    <FormattedMessage
                      id='UserProfile.Disconnect'
                      defaultMessage='Disconnect'
                    >
                      {message => (
                        <Button
                          secondary
                          label={message}
                          onClick={handleDisconnect}
                        />
                      )}
                    </FormattedMessage>
                  )}
                </div>
              )}
            </UserProfileCard>
          </div>
        )}

        {canEditProfile && isCurrentUser && (
          <div className='mb3 mb0-ns'>
            <UserProfileCard
              title={
                <FormattedMessage
                  id='UserProfile.Preferences'
                  defaultMessage='Preferences'
                />
              }
            >
              <div className='mt3'>
                <span className='f7 fw4 mid-gray ma0 lh-copy'>
                  <FormattedMessage
                    id='UserProfile.MessageDeliver'
                    defaultMessage='I would like to be notified when I get a message from another user'
                  />{' '}
                  <b>
                    {intl.formatMessage(
                      messages[
                        camelCase(
                          (options &&
                            options.getIn(['message', 'frequency'])) ||
                            'daily'
                        )
                      ]
                    )}
                  </b>
                </span>
              </div>
              <div className='mt3'>
                {disableAllEmails && (
                  <span className='f7 fw4 mid-gray ma0'>
                    <FormattedMessage
                      id='UserProfile.YouAreCurrently'
                      defaultMessage='You are currently'
                    />{' '}
                    <b>
                      <FormattedMessage
                        id='UserProfile.Unsubscribed'
                        defaultMessage='unsubscribed'
                      />
                    </b>{' '}
                    <FormattedMessage
                      id='UserProfile.FromTealbookEmails'
                      defaultMessage='from all emails and updates from TealBook.'
                    />
                  </span>
                )}

                {!disableAllEmails && (
                  <span className='f7 fw4 mid-gray ma0'>
                    <FormattedMessage
                      id='UserProfile.YouAreCurrently'
                      defaultMessage='You are currently'
                    />{' '}
                    <b>
                      <FormattedMessage
                        id='UserProfile.Subscribed'
                        defaultMessage='subscribed'
                      />
                    </b>{' '}
                    <FormattedMessage
                      id='UserProfile.ToTealbookEmails'
                      defaultMessage='to all emails and updates from TealBook.'
                    />
                  </span>
                )}
              </div>
            </UserProfileCard>
          </div>
        )}

        {showMetrics && (
          <VetsListContainer
            userId={userId}
            title={
              <FormattedMessage
                id='UserProfile.CurrentRFIs'
                defaultMessage='Current RFIs'
              />
            }
          />
        )}
        {showMetrics && <SupplierConnectionsContainer userId={userId} />}
        {feed && feed.size > 0 && (
          <SocialFeed feed={feed} onClickCallback={markPostRead} />
        )}
        {canEditProfile && (isTealbot || isBuyerAdmin || isSupplierAdmin) && (
          <div className='pt4-ns pt3'>
            <UserProfileCard>
              {(isOrgAdmin || isBuyerAdmin || isSupplierAdmin) && (
                <EditUserRoles
                  isOrgAdmin={isOrgAdmin}
                  isBuyerAdmin={isBuyerAdmin}
                  isSupplierAdmin={isSupplierAdmin}
                  roles={roles}
                  stateRoles={rolesState}
                  isRolesDialogOpen={isRolesDialogOpen}
                  handleCloseDialog={handleCloseDialogRoles}
                  toggleRole={toggleRole}
                  handleEditRoles={handleEditRoles}
                  handleUpdateRoles={handleUpdateRoles}
                  isTealbot={isTealbot}
                  isServerAdmin={isServerAdmin}
                />
              )}

              {(isTealbot || isBuyerAdmin || isSupplierAdmin) && (
                <BlockUser
                  handleCloseDialog={handleCloseDialogStatus}
                  blockUser={blockUser}
                  isStatusDialogOpen={isStatusDialogOpen}
                  handleUpdateStatus={handleUpdateStatus}
                  firstName={firstName}
                  lastName={lastName}
                  status={props.status}
                />
              )}
            </UserProfileCard>
          </div>
        )}

        <ClearbitCredit />
      </Aside>

      {(isClientApp || isCurrentUserBuyer) && (
        <ConnectMessageDialogContainer
          supplierId={orgUnit.get('id')}
          userId={userId}
          open={openConnectDialog}
          onClose={toggleConnectDialog}
          connectContact
        />
      )}
    </Page>
  )
}

export default UserProfile
