import React from 'react'
import NavBar from 'shared/components/NavBar'
import NavLink from 'shared/components/NavBar/NavLink'
import paths from '../../routes/paths'
import { FormattedMessage } from 'react-intl'
import Divider from 'shared/components/Divider'
import Button from 'shared/components/Button'
import insightsSelectors from '../../store/insightsSelectors'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleEsgReportQuestion,
  collapseAllEsgReportQuestions
} from '../../store/actions'

const pageQuestionIds: { [key: string]: string[] } = {
  'health-safety': [
    'iso45001',
    'emergencyResponsePolicies',
    'healthSafetyPolicies',
    'laggingIndicators',
    'leadingIndicators',
    'liabilityPrograms',
    'occupationalChargesViolations'
  ],
  environment: [
    'iso14001',
    'greenhouseGasInventory',
    'ghgReductionTarget',
    'iso50001',
    'energyConsumptionTracking',
    'waterUsageTracking',
    'wastePrograms',
    'environmentQuestion8',
    'negativeEnvironmentalImpacts'
  ],
  diversity: [
    'diversityCommitment',
    'formalPolicies',
    'diversityTraining',
    'integrateProcess',
    'diversityCertification',
    '51PercentOwned'
  ],
  governance: [
    'governanceQuestion1',
    'complianceOfficer',
    'activeEngagementProgram',
    'requireAdhereCodeOfConduct',
    'annualEsgReport',
    'useSustainabilityReportingFramework'
  ],
  privacy: ['complyGDPR', 'designatedPrivacy']
}

const SustainabilitySurveyNav = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const currentPath = location.pathname
  const tab = currentPath.split('/').pop()

  const showQuestions = useSelector(insightsSelectors.getEsgReportShowQuestions)

  const isAllExpanded =
    pageQuestionIds[tab] &&
    pageQuestionIds[tab].every(key => !!showQuestions.get(key))

  const expandAllClick = () => {
    if (pageQuestionIds[tab]) {
      dispatch(toggleEsgReportQuestion(pageQuestionIds[tab]))
    }
  }

  const collapseAllClick = () => {
    if (pageQuestionIds[tab]) {
      dispatch(collapseAllEsgReportQuestions(pageQuestionIds[tab]))
    }
  }

  return (
    <>
      <div className='flex justify-between items-center'>
        <NavBar>
          <NavLink
            label={
              <FormattedMessage
                id='SustainabilitySurveyNav.HealthSafety'
                defaultMessage='Health and Safety'
              />
            }
            to={paths.sustainabilityReportHealthSafety}
          />
          <NavLink
            label={
              <FormattedMessage
                id='SustainabilitySurveyNav.Environment'
                defaultMessage='Environment'
              />
            }
            to={paths.sustainabilityReportEnvironment}
          />
          <NavLink
            label={
              <FormattedMessage
                id='SustainabilitySurveyNav.Diversity'
                defaultMessage='Diversity'
              />
            }
            to={paths.sustainabilityReportDiversity}
          />
          <NavLink
            label={
              <FormattedMessage
                id='SustainabilitySurveyNav.Governance'
                defaultMessage='Governance'
              />
            }
            to={paths.sustainabilityReportGovernance}
          />
          <NavLink
            label={
              <FormattedMessage
                id='SustainabilitySurveyNav.Privacy'
                defaultMessage='Privacy'
              />
            }
            to={paths.sustainabilityReportPrivacy}
          />
        </NavBar>
        <div className='w4 tr'>
          {isAllExpanded ? (
            <Button autoSize onClick={collapseAllClick}>
              <FormattedMessage
                id='SustainabilitySurveyNav.CollapseAll'
                defaultMessage='Collapse All'
              />
            </Button>
          ) : (
            <Button autoSize onClick={expandAllClick}>
              <FormattedMessage
                id='SustainabilitySurveyNav.ExpandAll'
                defaultMessage='Expand All'
              />
            </Button>
          )}
        </div>
      </div>
      <Divider className='mb3' />
    </>
  )
}

export default SustainabilitySurveyNav
