import React, {
  ComponentType,
  FunctionComponent,
  useEffect,
  useState,
  useCallback
} from 'react'
import paths from '../../routes/paths'
import parsePath from 'shared/utils/parsePath'
import { List } from 'immutable'
import { Column, TableCellProps, TableHeaderProps } from 'react-virtualized'
import Table from 'shared/components/Table'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import Loading from 'shared/components/Loading'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import SubTitleText from 'shared/components/SubTitleText'
import CloseIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  IntlShape
} from 'react-intl'
import camelCase from 'lodash.camelcase'
import TealbotCircleImage from 'shared/assets/icons/tealbot-circle.png'
import classNames from 'classnames'
import Tooltip from 'shared/components/Tooltip'
import VetContactUsDialogContainer from '../../containers/VetContactUsDialogContainer'
import ActionInfoOutline from '@material-ui/icons/InfoOutlined'
import { CSSTransition } from 'react-transition-group'
import Hidden from '@material-ui/core/Hidden'
import { RouteComponentProps } from 'react-router'
import { Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import vetStatusMessages from 'i18n/defaultMessages/VetStatusMessages'
import chatbot from 'shared/utils/chatbot'
import storageManager from 'shared/utils/storageManager'
import TealbotHelpIcon from 'shared/components/TealbotHelpIcon'

const tableHeader = defineMessages({
  id: {
    id: 'MyVets.ID',
    defaultMessage: 'ID'
  },
  projectName: {
    id: 'MyVets.ProjectName',
    defaultMessage: 'Project Name'
  },
  owner: {
    id: 'MyVets.Owner',
    defaultMessage: 'Owner'
  },
  lastModified: {
    id: 'MyVets.ModifiedDate',
    defaultMessage: 'Last Modified'
  },
  status: {
    id: 'MyVets.Status',
    defaultMessage: 'Status'
  },
  suppliers: {
    id: 'MyVets.Suppliers',
    defaultMessage: 'Suppliers'
  }
})

const vetIsActive = vet => {
  const decisionDate =
    vet.get('decisionDate') && new Date(vet.get('decisionDate'))
  const plannedCompletionDate =
    vet.get('plannedCompletionDate') &&
    new Date(vet.get('plannedCompletionDate'))

  const latestDecisionDate =
    decisionDate && plannedCompletionDate
      ? // use the latest decision date
        decisionDate > plannedCompletionDate
        ? decisionDate
        : plannedCompletionDate
      : // use whichever is defined
        decisionDate || plannedCompletionDate

  const modifiedDate =
    vet.get('modifiedDate') && new Date(vet.get('modifiedDate'))
  const createdDate = vet.get('createdDate') && new Date(vet.get('createdDate'))
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const latestDate = latestDecisionDate
    ? latestDecisionDate > modifiedDate
      ? latestDecisionDate
      : modifiedDate
    : modifiedDate

  // active if greatest of created, modified, decisionDate and plannedCompletionDate within 4 weeks of today, and status not Close || Cancelled
  // setDate returns milliseconds (type number), so newDate is used to convert back to date format (type Date) in order to compare to latestDate(type Date) and createdDate(type Date)
  return (
    vet.get('status') !== 'Closed' &&
    vet.get('status') !== 'Cancelled' &&
    new Date(today.setDate(today.getDate() - 28)) <= (latestDate || createdDate)
  )
}

type Props = {
  vets: List<string>
  isLoadingVets: boolean
  isVetApprover: boolean
  currentUserId: string
  onStartNewVet: () => void
  onLoadVets: () => void
} & RouteComponentProps

export const MyVets: FunctionComponent<Props & { intl: IntlShape }> = props => {
  const [filter, setFilter] = useState<string>('Active')
  const [filteredVets, setFilteredVets] = useState<List<any>>(
    props.vets || List([])
  )
  const [sortBy, setSortBy] = useState<string>('date')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')
  const [onlyMyVets, setOnlyMyVets] = useState<boolean>(true)
  const [openContactUsDialog, setOpenContactUsDialog] = useState<boolean>(false)
  const [doNotShow, setDoNotShow] = useState<boolean>(
    !!storageManager.getItem('doNotShowVetInfo')
  )

  const { onLoadVets, vets } = props
  useEffect(() => {
    onLoadVets()
  }, [onLoadVets])

  const getRow = ({ index }) => {
    const row = filteredVets.size > 0 && filteredVets.get(index)
    return row
  }

  const handleRowClick = ({ rowData }) => {
    props.history.push(
      `${parsePath(paths.vetDetails, {
        vetId: rowData.get('id')
      })}`
    )
  }

  const filterVets = useCallback(
    (vets, filter, onlyMyVets) => {
      const { currentUserId } = props

      const filteredVets =
        filter === 'Active'
          ? sortVets(
              sortBy,
              sortDirection,
              vets
                .filter(vet => vetIsActive(vet))
                .map(vet =>
                  vet.update('status', status =>
                    status === 'Sent' && vet.get('responded')
                      ? 'Reviewing'
                      : status
                  )
                )
            )
          : filter === 'Archived'
          ? sortVets(
              sortBy,
              sortDirection,
              vets
                .filter(vet => !vetIsActive(vet))
                .map(vet =>
                  vet.update('status', status =>
                    status === 'Sent' && vet.get('responded')
                      ? 'Reviewing'
                      : status
                  )
                )
            )
          : sortVets(sortBy, sortDirection, vets)

      return onlyMyVets
        ? filteredVets.filter(vet =>
            vet.get('users').some(user => user.get('user') === currentUserId)
          )
        : filteredVets
    },
    [props, sortBy, sortDirection]
  )

  useEffect(() => {
    setFilteredVets(filterVets(vets, filter, onlyMyVets))
  }, [vets, filter, onlyMyVets, filterVets])

  const sortVets = (sortBy, sortDirection, vets) => {
    const field = sortBy === 'date' ? 'lastModified' : sortBy

    return vets.sort((vetA, vetB) => {
      const valueA =
        typeof vetA.get(field) === 'string'
          ? vetA.get(field).toLowerCase()
          : vetA.get(field)
      const valueB =
        typeof vetB.get(field) === 'string'
          ? vetB.get(field).toLowerCase()
          : vetB.get(field)

      if (sortDirection === 'ASC') {
        return valueA < valueB ? -1 : 1
      } else {
        return valueA > valueB ? -1 : 1
      }
    })
  }

  const handleFilterChange = e => {
    const filter = e.target.value
    setFilter(filter)
    setFilteredVets(filterVets(props.vets, filter, onlyMyVets))
  }

  const handleSortChange = ({ sortBy, sortDirection }) => {
    const vets = sortVets(sortBy, sortDirection, filteredVets)
    setSortBy(sortBy)
    setSortDirection(sortDirection)
    setFilteredVets(vets)
  }

  const renderStatusCell = ({ cellData, rowData }: TableCellProps) => {
    const { intl } = props

    let color = ''
    if (cellData === 'In Progress') {
      color = 'dark-blue'
    } else if (cellData === 'Sent') {
      color = 'dark-green'
    } else if (cellData === 'Reviewing') {
      color = 'gold'
    } else if (cellData === 'Closed' || cellData === 'Cancelled') {
      color = 'dark-red'
    }

    const text = vetStatusMessages[camelCase(cellData)]
      ? intl.formatMessage(vetStatusMessages[camelCase(cellData)])
      : cellData
    return <div className={`dib br2 pa1 w-100 ${color}`}>{text}</div>
  }

  const renderSuppliersCell = ({ cellData, ...rest }: TableCellProps) => {
    return (
      cellData &&
      cellData
        .filter(supplier => !!supplier)
        .map((supplier, index) => {
          const color =
            index === 0 ? 'dark-blue' : index === 1 ? 'dark-green' : 'gold'
          return index === 0 ? (
            <span key={`suppliers-${color}`} className={color}>
              {supplier}
            </span>
          ) : (
            <span key={`suppliers-${color}`}>
              {' '}
              / <span className={color}>{supplier}</span>
            </span>
          )
        })
    )
  }

  const renderSupplierHeader = ({ label }: TableHeaderProps) => {
    return (
      <span
        className='ReactVirtualized__Table__headerTruncatedText'
        key='label'
      >
        {label}
        <Tooltip
          className='tooltip'
          aria-label='Type of Suppliers'
          placement='right-start'
          role='tooltip'
          tabIndex={0}
          title={
            <div className='w5'>
              <Text className='fw6 ma2'>
                <FormattedMessage
                  id='MyVets.SuppliersExplain'
                  defaultMessage='These are the number of suppliers through the stages of the process:'
                />
              </Text>
              <Text className='ma2'>
                <FormattedMessage
                  id='MyVets.SuppliersExplainDetail'
                  defaultMessage='{blue} - Suggested Suppliers / {green} – Invited Suppliers / {gold} – Suppliers that have responded'
                  values={{
                    blue: (
                      <span className='blue'>
                        <FormattedMessage
                          id='MyVets.Blue'
                          defaultMessage='Blue'
                        />
                      </span>
                    ),
                    green: (
                      <span className='green'>
                        <FormattedMessage
                          id='MyVets.Green'
                          defaultMessage='Green'
                        />
                      </span>
                    ),
                    gold: (
                      <span className='gold'>
                        <FormattedMessage
                          id='MyVets.Gold'
                          defaultMessage='Gold'
                        />
                      </span>
                    )
                  }}
                />
              </Text>
            </div>
          }
        >
          <ActionInfoOutline className='v-mid dib' style={{ height: '19px' }} />
        </Tooltip>
      </span>
    )
  }

  const handleDoNotShow = doNotShow => {
    storageManager.setItem('doNotShowVetInfo', doNotShow)
    setDoNotShow(doNotShow)
  }

  const handleNewVetClick = () => {
    const {
      history: { push }
    } = props
    push(paths.newVet)
  }

  const toggleMyVetFilter = () => {
    setOnlyMyVets(!onlyMyVets)
    setFilteredVets(filterVets(props.vets, filter, !onlyMyVets))
  }

  const toggleContactUsDialog = () => {
    setOpenContactUsDialog(!openContactUsDialog)
  }

  const renderAllVetsText = () => {
    return <FormattedMessage id='MyVets.AllRFIs' defaultMessage='All RFIs' />
  }

  const { isLoadingVets, isVetApprover, intl } = props

  return !isLoadingVets ? (
    <div>
      <SubTitleText>
        <FormattedMessage
          id='MyVets.ManagingSupplierRFI'
          defaultMessage='RFI: Managing Supplier Lists and Selections'
        />
      </SubTitleText>

      <CSSTransition
        in={!doNotShow}
        timeout={300}
        classNames='tealbotHelp'
        unmountOnExit
      >
        <div className='bg-white br1 ba b--black-10 flex'>
          <div className='pa3-5 flex-auto'>
            <Text>
              <FormattedMessage
                id='MyRFIs.FlexibleTool'
                defaultMessage='An RFI is a flexible tool you can use to:'
              />
            </Text>
            <div className='db'>
              <div className='dib w-100 w-70-ns'>
                <ul>
                  <li>
                    <Text>
                      <FormattedMessage
                        id='MyVets.ListOfSuppliers'
                        defaultMessage='Capture a list of Suppliers and share them with your team'
                      />
                    </Text>
                  </li>
                  <li>
                    <Text>
                      <FormattedMessage
                        id='MyVets.PreQualification'
                        defaultMessage='Run a pre-qualification process or RFI with submissions from Suppliers'
                      />
                    </Text>
                  </li>
                  <li>
                    <Text>
                      <FormattedMessage
                        id='MyVets.SelectMultipleSuppliers'
                        defaultMessage='Select one or more suppliers for an opportunity. If you need help with a supplier search or RFI, our concierge team would be happy to assist.'
                      />
                    </Text>
                  </li>
                </ul>
              </div>
              <div className='dib w-100 w-30-ns pl3-ns'>
                <h5>
                  <FormattedMessage
                    id='MyVets.NeedMoreHelp'
                    defaultMessage='Need More Help?'
                  />
                </h5>
                <span
                  className='teal f7 fw6 pointer'
                  onClick={chatbot.open}
                  tabIndex={0}
                  role='link'
                >
                  <FormattedMessage
                    id='VetContactUsDialog.ContactUs'
                    defaultMessage='Contact us'
                  />
                </span>
                &nbsp;
                <span className='lh-copy f7 fw4 mid-gray ma0'>
                  <FormattedMessage
                    id='MyVets.ConciergeMessage'
                    defaultMessage='with a few details and the Concierge will be happy to assist you.'
                  />
                </span>
              </div>
            </div>
          </div>
          <Hidden smDown>
            <div className='ml3-ns w4 self-center'>
              <img src={TealbotCircleImage} alt='tealbot-tip' />
            </div>
          </Hidden>
          <div className='pr2 pt2'>
            <IconButton
              aria-label='Hide Suggestion'
              onClick={() => handleDoNotShow(true)}
              style={{
                width: 32,
                height: 32
              }}
            >
              <CloseIcon style={{ color: '#737373' }} />
            </IconButton>
          </div>
        </div>
      </CSSTransition>

      <section>
        <header className='flex items-center mt4 pt1 bb b--light-gray'>
          <div className='flex flex-auto'>
            <button
              style={{ border: 'none', background: 'none' }}
              aria-current='page'
              onClick={
                isVetApprover && !onlyMyVets ? toggleMyVetFilter : undefined
              }
              className={classNames(
                'pv3 mid-gray f7 f6-ns mr3 mr4-ns',
                onlyMyVets && 'bw1 bb fw6',
                isVetApprover && 'link dim pointer'
              )}
            >
              <FormattedMessage id='MyVets.MyRFIs' defaultMessage='RFI' />
            </button>
            <span
              onClick={
                isVetApprover && onlyMyVets ? toggleMyVetFilter : undefined
              }
              className={classNames(
                'pv3 f7 f6-ns mr3 mr4-ns',
                !onlyMyVets && 'bw1 bb fw6',
                isVetApprover ? 'mid-gray pointer link dim' : 'gray'
              )}
            >
              {isVetApprover ? (
                renderAllVetsText()
              ) : (
                <Tooltip
                  title={
                    <FormattedMessage
                      id='buyer/MyVets'
                      defaultMessage='Contact your administrator to get access to RFIs that you have not been invited to.'
                    />
                  }
                >
                  <span>{renderAllVetsText()}</span>
                </Tooltip>
              )}
            </span>
          </div>
          <Hidden smDown>
            <Button
              style={{ color: '#c2d1d1' }}
              label={
                <FormattedMessage
                  id='MyVets.StartaNewRFI'
                  defaultMessage='Start a new RFI'
                />
              }
              onClick={handleNewVetClick}
              className='w-auto mr3'
            />
          </Hidden>

          <Hidden mdUp>
            <div className='fixed tr w-100 pr4 pb4 bottom-2 z-1 mb2'>
              <Fab color='primary' aria-label='add' onClick={handleNewVetClick}>
                <AddIcon />
              </Fab>
            </div>
          </Hidden>

          <Label htmlFor='sortRFIs' className='di'>
            <FormattedMessage id='MyVets.SortBy' defaultMessage={'Sort by:'} />
            &nbsp;
          </Label>
          <Select
            name='sortRFIs'
            wrapperClassName='ba pa2 b--black-10 bg-white w4'
            onChange={handleFilterChange}
            value={filter}
          >
            <FormattedMessage id='MyVets.All' defaultMessage='All'>
              {message => (
                <option value='' aria-label='All'>
                  {message}
                </option>
              )}
            </FormattedMessage>

            <FormattedMessage id='MyVets.Active' defaultMessage='Active'>
              {message => (
                <option value='Active' aria-label='Active'>
                  {message}
                </option>
              )}
            </FormattedMessage>

            <FormattedMessage id='MyVets.Archived' defaultMessage='Archived'>
              {message => (
                <option value='Archived' aria-label='Archived'>
                  {message}
                </option>
              )}
            </FormattedMessage>
          </Select>

          <CSSTransition
            in={doNotShow}
            timeout={100}
            classNames='tealbotHelp'
            unmountOnExit
          >
            <TealbotHelpIcon
              show={doNotShow}
              imageClassName='mh2 w2 dim pointer'
              onClick={() => handleDoNotShow(false)}
            />
          </CSSTransition>
          {doNotShow && (
            <Hidden smDown>
              <div>
                <div className='f7 fw6'>
                  <FormattedMessage id='MyVets.NeedMoreHelp' />
                </div>
                <div
                  className='teal f7 fw6 pointer'
                  onClick={toggleContactUsDialog}
                  role='link'
                >
                  <FormattedMessage id='VetContactUsDialog.ContactUs' />
                </div>
              </div>
            </Hidden>
          )}
        </header>

        {filteredVets && filteredVets.size > 0 ? (
          <div className='bg-white br1 ba b--black-10 overflow-hidden mt2'>
            <Table
              minWidth={692}
              rowGetter={getRow}
              rowCount={filteredVets.size}
              onRowClick={handleRowClick}
              rowClassName='pointer dim'
              sort={handleSortChange}
              sortDirection={sortDirection}
              sortBy={sortBy}
            >
              <Column
                label={intl.formatMessage(tableHeader.id)}
                dataKey={'vetId'}
                width={100}
              />
              <Column
                label={intl.formatMessage(tableHeader.projectName)}
                dataKey='name'
                width={200}
              />
              <Column
                label={intl.formatMessage(tableHeader.owner)}
                dataKey={'owner'}
                width={200}
              />
              <Column
                label={intl.formatMessage(tableHeader.lastModified)}
                dataKey={'date'}
                width={150}
              />
              <Column
                label={intl.formatMessage(tableHeader.status)}
                dataKey={'status'}
                width={150}
                cellRenderer={renderStatusCell}
              />
              <Column
                label={intl.formatMessage(tableHeader.suppliers)}
                dataKey={'suppliers'}
                width={120}
                disableSort
                cellRenderer={renderSuppliersCell}
                headerRenderer={renderSupplierHeader}
              />
            </Table>
          </div>
        ) : (
          <div className='pa4'>
            <Text>
              <FormattedMessage
                id='MyVets.NoRFIs'
                defaultMessage='There are no {filter} RFIs, start a new one!'
                values={{ filter }}
              />
            </Text>
          </div>
        )}
      </section>
      <VetContactUsDialogContainer
        notOnVet
        open={openContactUsDialog}
        onRequestClose={toggleContactUsDialog}
      />
    </div>
  ) : (
    <Loading />
  )
}

export default injectIntl(MyVets) as ComponentType<Props>
