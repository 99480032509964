import { createSelector } from 'reselect'
import { Map, List, fromJS } from 'immutable'
import sortStrings from 'shared/utils/sortStrings'
import relationshipsSelectors from '../../../shared/selectors/relationshipsSelectors'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import communitiesSelectors from '../../../Communities/selectors/communitiesSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import supplierRelationshipsSelectors from '../../../shared/selectors/supplierRelationshipsSelectors'
import RootState from 'shared/models/RootState'
import ClassificationCode from 'shared/models/ClassificationCode'
import moment from 'moment'

export const getDataField = (state: RootState, fieldName: string) => {
  return state.getIn(['buyer', 'supplierProfile', 'profile', 'data', fieldName])
}
export const getLocationCountries = createSelector(
  (state: RootState) => getDataField(state, 'locations'),
  locations =>
    locations.reduce((result, currentLocation) => {
      if (currentLocation.get('country')) {
        if (!result.includes(currentLocation.get('country'))) {
          result = [...result, currentLocation.get('country')]
        }
      }
      return fromJS(result)
    }, [])
)
export const getPhoneNumber = createSelector(
  (state: RootState) =>
    state.getIn(['buyer', 'supplierProfile', 'profile', 'data']),
  data => {
    let phoneNumber = data.getIn(['phones', 'office', 0])
    if (!phoneNumber) {
      const locations = data.getIn(['supplier', 'locations']) || List([])
      const locationWithPhone = locations.find(loc => !!loc.get('phone'))
      phoneNumber = locationWithPhone && locationWithPhone.get('phone')
    }
    return phoneNumber || ''
  }
)

export const hasDetails = (state: RootState) => {
  const serviceAreas = getDataField(state, 'serviceAreas')
  return serviceAreas && serviceAreas.size > 0
}

const getActiveCommunities = communitiesSelectors.createGetSupplierCommunitiesSelector(
  ['Accepted', 'Invited']
)
export const hasCommunities = (state: RootState) => {
  const communities = getActiveCommunities(state, getDataField(state, 'id'))
  return communities && communities.size > 0
}

export const getRelationship = (state: RootState) => {
  const id = getDataField(state, 'id')
  return relationshipsSelectors.getBySupplier(state, id)
}

export const getRelationshipAttributes = createSelector(
  getRelationship,
  usersSelectors.getUsers,
  (relationship, users) => {
    const attributes = relationship && relationship.get('attributes')
    return attributes
      ? attributes.map(v =>
          v.setIn(
            ['modified', 'userInfo'],
            users.get(v.getIn(['modified', 'user']))
          )
        )
      : fromJS({})
  }
)

export const getPersonalRelationship = (state: RootState) => {
  const supplierId = getDataField(state, 'id')
  const card = cardsSelectors.getBySupplier(state, supplierId)
  const attributes = card && card.get('attributes')
  return attributes && List(attributes.filterNot(v => v === '').valueSeq())
}

export const getMyCard = (state: RootState) => {
  const userId = sessionSelectors.getUserId(state)
  const supplierId = getDataField(state, 'id')
  return (
    cardsSelectors.getByUserSupplier(state, supplierId, userId) || fromJS({})
  )
}

export const getColleaguesCards = createSelector(
  sessionSelectors.getUserId,
  getCardsBySupplier,
  cardsSelectors.getAll,
  (userId, cardsBySupplier, cards) => {
    return (
      cardsBySupplier &&
      cardsBySupplier
        .filter((cardId, cardOwner) => {
          return (
            cards.getIn([cardId, 'connected']) !== false && cardOwner !== userId
          )
        })
        .sort((cardIdA, cardIdB) => {
          const cardA = cards.get(cardIdA)
          const cardB = cards.get(cardIdB)
          if (cardA && cardB) {
            return (
              cardB.get('offerings').size +
              cardB.get('differentiators').size -
              (cardA.get('offerings').size + cardA.get('differentiators').size)
            )
          } else {
            return 0
          }
        })
        .toList()
    )
  }
)

export const hasConnections = (state: RootState) => {
  const cards = getColleaguesCards(state)
  return cards && cards.size > 0
}

export const isLoading = (state: RootState) =>
  getSupplierProfile(state, 'isFetching')
export const getErrorMessage = (state: RootState) =>
  getSupplierProfile(state, 'fetchErrorMessage')

export const hasSimilarSuppliers = (state: RootState) => {
  const relatedCompanies = getDataField(state, 'relatedCompany')
  return relatedCompanies && relatedCompanies.size > 0
}

export const getSimilarSuppliers = (state: RootState) =>
  state.getIn(['buyer', 'supplierProfile', 'profile', 'data', 'relatedCompany'])

export const getSimilarSupplierById = (
  state: RootState,
  supplierId: string
) => {
  return state.getIn([
    'buyer',
    'supplierProfile',
    'profile',
    'data',
    'expanded',
    'OrgUnit',
    supplierId
  ])
}
export const getSiblingSupplierById = (
  state: RootState,
  supplierId: string
) => {
  return state.getIn([
    'buyer',
    'supplierProfile',
    'profile',
    'data',
    'siblings',
    supplierId
  ])
}

export const getParentOrg = state => {
  const parentOrg = state.getIn([
    'buyer',
    'supplierProfile',
    'profile',
    'data',
    'parentOrg'
  ])

  return state.getIn([
    'buyer',
    'supplierProfile',
    'profile',
    'data',
    'expanded',
    'Org',
    parentOrg
  ])
}

export const getCardsWithReviews = createSelector(
  cardsSelectors.getAll,
  getCardsBySupplier,
  (allCards, cardsBySupplier) => {
    return (
      cardsBySupplier &&
      cardsBySupplier
        .map(cardId => allCards.get(cardId))
        .filter(card => {
          const responses = card.getIn(['ratingResponses', 'responses'])
          const preferred = card.getIn(['ratingResponses', 'preferred'])
          return (!!responses && responses.size > 0) || !!preferred
        })
    )
  }
)

export const getUserRatings = createSelector(
  getCardsWithReviews,
  settingsSelectors.getRatingQuestions,
  (state: RootState) => state.questions.byId,
  sessionSelectors.getUserId,
  (
    cardsWithReviews: Map<string, any>,
    ratingQuestions: Map<string, any>,
    allQuestions,
    currentUserId
  ) => {
    const reviews =
      cardsWithReviews &&
      cardsWithReviews.map(card =>
        fromJS({
          cardId: card.get('id'),
          userId: card.get('owner'),
          comment: card.getIn(['ratingResponses', 'comment']),
          preferred: card.getIn(['ratingResponses', 'preferred']),
          preferredCategories: card.getIn([
            'ratingResponses',
            'preferredCategories'
          ]),
          lastUpdated: card.getIn(['ratingResponses', 'date']),
          ratings: ratingQuestions.map(question => ({
            label: allQuestions[question.get('question')]?.label,
            value: card.getIn([
              'ratingResponses',
              'responses',
              question.get('question')
            ])
          })),
          averageRating: calculateCardReview(ratingQuestions, card),
          likes: card.getIn(['ratingResponses', 'likes'])
        })
      )

    if (reviews && reviews.size > 0) {
      const sortedReviews = reviews
        .delete(currentUserId)
        .toList()
        .sort((reviewA, reviewB) => {
          const dateA: Date = new Date(reviewA.get('lastUpdated'))
          const dateB: Date = new Date(reviewB.get('lastUpdated'))
          return dateB < dateA ? -1 : 1
        })

      const currentUserReview = reviews.get(currentUserId)
      return currentUserReview
        ? sortedReviews.unshift(currentUserReview)
        : sortedReviews
    }
  }
)

export const getOverallRatings = createSelector(
  getCardsWithReviews,
  settingsSelectors.getRatingQuestions,
  (cardsWithReviews, buyerQuestions) => {
    const tally =
      (cardsWithReviews &&
        cardsWithReviews.reduce((result, card) => {
          const cardReview = calculateCardReview(buyerQuestions, card)
          result['reviewsTotal'] = result['reviewsTotal']
            ? result['reviewsTotal'] + cardReview
            : cardReview
          const preferred = card.getIn(['ratingResponses', 'preferred'])
          if (preferred) {
            result[preferred] = result[preferred] ? result[preferred] + 1 : 1
          }
          return result
        }, {})) ||
      {}

    return {
      averageRating:
        tally.reviewsTotal &&
        Math.round((tally.reviewsTotal / cardsWithReviews.size) * 2) / 2,
      numberOfReviews: cardsWithReviews && cardsWithReviews.size,
      ...tally
    }
  }
)

export const getOverallRatingsDetails = createSelector(
  getCardsWithReviews,
  settingsSelectors.getRatingQuestions,
  (state: RootState) => state.questions.byId,
  (
    cardsWithReviews: Map<string, any>,
    buyerQuestions: List<any>,
    allQuestions
  ) => {
    return buyerQuestions.map(bQuestion => ({
      label: allQuestions[bQuestion.get('question')]?.label,
      value: calculateQuestionAverageRating(bQuestion, cardsWithReviews)
    }))
  }
)

export const getConnections = createSelector(
  sessionSelectors.getUserId,
  getCardsBySupplier,
  cardsSelectors.getAll,
  usersSelectors.getUsers,
  (currentUserId, cardIds, allCards, allUsers) => {
    const result =
      cardIds &&
      cardIds.size > 0 &&
      cardIds
        .delete(currentUserId)
        .toList()
        .filter(cardId => !!allCards.getIn([cardId, 'connected']))
        .sort((cardIdA, cardIdB) => {
          const cardA = allCards.get(cardIdA)
          const userA = allUsers.get(cardA.get('owner'))
          const contactForA = cardA.get('contactFor') || ''

          const cardB = allCards.get(cardIdB)
          const userB = allUsers.get(cardB.get('owner'))
          const contactForB = cardB.get('contactFor') || ''

          // sometimes these users dont exist
          if (!userA || !userB) {
            return 0
          }

          return sortStrings(
            `${
              contactForA.startsWith('Primary')
                ? 0
                : contactForA.startsWith('Secondary')
                ? 1
                : 3
            } ${userA.get('firstName')} ${userA.get('lastName')}`,
            `${
              contactForB.startsWith('Primary')
                ? 0
                : contactForB.startsWith('Secondary')
                ? 1
                : 3
            } ${userB.get('firstName')} ${userB.get('lastName')}`
          )
        })

    // the supplier has no card
    if (!result) {
      return
    }

    const currentUserCardId = cardIds.get(currentUserId)
    const currentUserCard = allCards.get(currentUserCardId)

    if (currentUserCard && currentUserCard.get('connected')) {
      return result.unshift(cardIds.get(currentUserId))
    } else {
      return result
    }
  }
)

function getSupplierProfile(state, propName) {
  return state.getIn(['buyer', 'supplierProfile', 'profile', propName])
}

export function getCardsBySupplier(state) {
  const supplierId = getDataField(state, 'id')
  return cardsSelectors.getAllBySupplier(state, supplierId)
}

function getSupplierContacts(state) {
  return getDataField(state, 'contacts')
}

const getUserContacts = createSelector(
  state => getDataField(state, 'id'),
  sessionSelectors.getUserId,
  state => state.getIn(['buyer', 'cards', 'bySupplier']),
  state => state.getIn(['buyer', 'cards', 'byId']),
  (supplierId, currentUserId, cardsBySupplier, allCards) => {
    const cardId = cardsBySupplier.getIn([supplierId, currentUserId])
    const card =
      (cardId && !cardId.endsWith('-1') && allCards && allCards.get(cardId)) ||
      fromJS({})
    const contactOwner = card.get('owner')
    const contacts = card.get('contacts')
    return (
      contacts &&
      contacts.map(contact =>
        contact.set('contactOwner', contactOwner).set('mine', true)
      )
    )
  }
)

function getAllContacts(state) {
  const supplierId = getDataField(state, 'id')
  return cardsSelectors.getAllContactsBySupplier(state, supplierId) || Map({})
}

export const getContacts = createSelector(
  getSupplierContacts,
  getUserContacts,
  getAllContacts,
  getRelationship,
  (supplierContacts, userContacts, allContacts, relationship) => {
    // filter all public contacts
    const publicContacts = allContacts.filter(
      contact => contact.get('isPrivate') === false
    )
    const relationshipContacts = relationship && relationship.get('contacts')
    const clientContacts = publicContacts
      .merge(userContacts)
      .merge(relationshipContacts)

    return supplierContacts && clientContacts
      ? supplierContacts
          .toMap()
          .mapKeys((k, v) => v.get('user'))
          .merge(clientContacts)
          .toList()
          .sort((contactA, contactB) => {
            if (contactA.has('mine') && contactA.get('connected')) {
              return -1
            }
            if (contactA.has('mine') && contactB.get('connected')) {
              return 1
            }
            if (contactA.has('mine')) {
              return -1
            }
            if (contactB.has('mine')) {
              return 1
            }
            if (contactA.get('source') === 'company') {
              return -1
            }
            if (contactB.get('source') === 'company') {
              return 1
            }
            if (contactA.get('isPrivate') === false) {
              return -1
            }
            if (contactB.get('isPrivate') === false) {
              return 1
            }
            return 0
          })
      : List([])
  }
)

export const getContactsInfo = createSelector(
  getContacts,
  usersSelectors.getUsers,
  (contacts, users) => {
    const contactsInfo = contacts.reduce((result, currentUser) => {
      const user = users.get(currentUser.get('user'))
      const firstName = user?.get('firstName')
      const lastName = user?.get('lastName')
      const email = user?.get('email')
      const phoneNumber =
        user?.get('officePhone') ||
        user?.get('mobilePhone') ||
        currentUser?.get('phone')
      if (
        user &&
        !user.get('blocked') &&
        firstName &&
        lastName &&
        email &&
        phoneNumber
      ) {
        const name = `${user.get('firstName')} ${user.get('lastName')}`
        if (!result[name]) {
          result = {
            ...result,
            [name]: {
              title: user.get('title') || '',
              firstName,
              lastName,
              email,
              phoneNumber
            }
          }
        }
      }
      return result
    }, {})
    return fromJS(contactsInfo)
  }
)

export function calculateCardReview(ratingQuestions, card) {
  const reviewResult = ratingQuestions.reduce(
    (result, question) => {
      const responseRating =
        card.getIn([
          'ratingResponses',
          'responses',
          question.get('question')
        ]) || 0
      return {
        sum: result.sum + responseRating * question.get('weight'),
        weight: result.weight + (!responseRating ? 0 : question.get('weight'))
      }
    },
    {
      sum: 0,
      weight: 0
    }
  )

  return reviewResult.sum / reviewResult.weight
}

function calculateQuestionAverageRating(question, cards) {
  const resultRating = cards.reduce(
    (result, card) => {
      const responseRating =
        card.getIn([
          'ratingResponses',
          'responses',
          question.get('question')
        ]) || 0
      return {
        total: result.total + responseRating,
        numberOfReviews: result.numberOfReviews + (!responseRating ? 0 : 1)
      }
    },
    {
      total: 0,
      numberOfReviews: 0
    }
  )

  return Math.round((resultRating.total / resultRating.numberOfReviews) * 2) / 2
}

export const getSocialFeed = (state, count) => {
  if (!count) {
    return state.getIn(['buyer', 'supplierProfile', 'profile', 'socialFeed'])
  } else {
    return state
      .getIn(['buyer', 'supplierProfile', 'profile', 'socialFeed'])
      .slice(0, count)
  }
}

export const hasSocialFeed = state =>
  state.getIn(['buyer', 'supplierProfile', 'profile', 'socialFeed']).size > 0

export const getUsersWithoutCards = createSelector(
  usersSelectors.getColleagues,
  getCardsBySupplier,
  (colleagues: List<any>, cardsBySupplier) => {
    return (
      colleagues &&
      colleagues.filter(
        colleague =>
          !cardsBySupplier.has(colleague.get('id')) &&
          !colleague.get('email').startsWith('tealbot@') &&
          (colleague.get('status') || '').toLowerCase() !== 'blocked'
      )
    )
  }
)

export const getCompletenessPercentage = createSelector(
  (state: RootState) =>
    state.getIn(['buyer', 'supplierProfile', 'profile', 'data']),
  supplierRelationshipsSelectors.getClients,
  (supplier, clients) => {
    if (!supplier) {
      return 0
    }

    const description = supplier.get('description') || fromJS({ en: '' })
    const longDescription =
      supplier.get('longDescription') || fromJS({ en: '' })
    const address = supplier.getIn(['locations', 0, 'address'])
    const pitchDeckFile = supplier.get('pitchDeckUrl')
    const contacts = supplier.get('contacts')
    const offerings = supplier.get('offerings')
    const differentiators = supplier.get('differentiators')
    const serviceAreas = supplier.get('serviceAreas')
    const linkedInUrl = supplier.get('linkedInUrl')
    const twitterId = supplier.get('twitterId')
    const facebookUrl = supplier.get('facebookUrl')
    const blogUrl = supplier.get('blogUrl')
    const videoUrl = supplier.getIn(['videoUrls', 0])
    const certifications = supplier.get('certifications')

    return (
      12 +
      (description.toList().filter((d: string) => !!d).size > 0 ||
      linkedInUrl ||
      twitterId ||
      facebookUrl
        ? 11
        : 0) +
      (longDescription.toList().filter((d: string) => !!d).size > 0 ||
      pitchDeckFile
        ? 11
        : 0) +
      (contacts && contacts.size ? 11 : 0) +
      ((offerings && offerings.size) ||
      (differentiators && differentiators.size)
        ? 11
        : 0) +
      (serviceAreas && serviceAreas.size ? 11 : 0) +
      (address ? 11 : 0) +
      (blogUrl || videoUrl || (certifications && certifications.size > 0)
        ? 11
        : 0) +
      (clients && clients.size ? 11 : 0)
    )
  }
)

export const getReferenceScores = createSelector(
  (state: RootState) =>
    state.getIn([
      'buyer',
      'supplierProfile',
      'profile',
      'data',
      'expanded',
      'relDetails',
      'count'
    ]),
  (state: RootState) =>
    state.getIn([
      'buyer',
      'supplierProfile',
      'profile',
      'data',
      'expanded',
      'cardDetails',
      'cardCnt'
    ]),
  (state: RootState) =>
    state.getIn([
      'buyer',
      'supplierProfile',
      'profile',
      'data',
      'expanded',
      'cardDetails',
      'contactsCnt'
    ]),
  (state: RootState) =>
    state.getIn([
      'buyer',
      'supplierProfile',
      'profile',
      'data',
      'expanded',
      'cardDetails',
      'numOrg'
    ]),
  getCompletenessPercentage,
  (
    relationshipDetailsCount,
    cardCount,
    contactsCount,
    orgCount,
    completeness
  ) => {
    return {
      relationshipDetailsCount,
      cardCount,
      contactsCount,
      orgCount,
      completeness
    }
  }
)

export const getPrimaryLocation = createSelector(
  (state: RootState) =>
    state.getIn([
      'buyer',
      'supplierProfile',
      'profile',
      'data',
      'locations',
      0
    ]),
  location => {
    return (
      location &&
      fromJS({
        address: location.get('address'),
        components: {
          road: location.get('road'),
          house_number: location.get('houseNumber'),
          city: location.get('city'),
          state: location.get('state'),
          postcode: location.get('postalCode'),
          country: location.get('country'),
          lat: location.get('lat'),
          long: location.get('long'),
          countryCpi: location.get('countryCpi'),
          level: location.get('level'),
          po_box: location.get('poBox')
        }
      })
    )
  }
)

export const getCpiLocation = createSelector(
  (state: RootState) =>
    state.getIn(['buyer', 'supplierProfile', 'profile', 'data', 'locations']),
  locations => {
    const cpiLocation =
      locations &&
      locations.reduce((result, location) => {
        return !result
          ? location
          : location.get('countryCpi', 100) < result.get('countryCpi', 100)
          ? location
          : result
      })

    return cpiLocation
      ? fromJS({
          countryCpi: cpiLocation.get('countryCpi'),
          country: cpiLocation.get('country')
        })
      : fromJS({})
  }
)

export const getClassificationCodes = (state: RootState) =>
  state.getIn([
    'buyer',
    'supplierProfile',
    'profile',
    'data',
    'classificationCodes'
  ])

export const groupClassificationCodesBySchema = createSelector(
  getClassificationCodes,
  classificationCodes => {
    let schemaMap: Map<string, List<ClassificationCode>> = Map()
    classificationCodes &&
      classificationCodes.forEach(classificationCode => {
        const schema =
          classificationCode.get('schema')?.toLowerCase() || 'naics'
        let codeList = schemaMap.get(schema)
        schemaMap = codeList
          ? schemaMap.set(schema, codeList.push(classificationCode))
          : schemaMap.set(schema, List([classificationCode]))
      })
    return schemaMap
  }
)
export const getFormResponses = createSelector(
  communitiesSelectors.getCommunitiesBySupplierId,
  communitiesSelectors.getMembershipsBySupplierId,
  (communities, memberships) => {
    const responsesByMembershipId = memberships.filter(
      value => value && value.getIn(['response', 'status']) === 'submitted'
    )
    const communityIds = responsesByMembershipId?.reduce(
      (result, currentMembership) => [
        ...result,
        currentMembership.getIn(['parents', 'Community'])
      ],
      []
    )
    let formsByCommunityId =
      communityIds &&
      communities.filter((value, key) => communityIds.includes(key))?.valueSeq()

    const availableForms = formsByCommunityId.map(value => {
      return fromJS({
        label: value.getIn(['form', 'name']),
        key: value.get('id')
      })
    })
    return fromJS({ formsByCommunityId, availableForms })
  }
)

export const getCertificationByTimeStamp = createSelector(
  (state, timeStamp) => getDataField(state, 'certifications'),
  (state, timeStamp) => timeStamp,
  (certifications, timeStamp) => {
    const list = certifications?.reduce((result, category) => {
      result = result.concat(category.get(1))
      return result
    }, List([]))
    return list?.find(cert => cert.get('timeStamp') === timeStamp)
  }
)
export const getContactsForOutreachRequest = createSelector(
  getContacts,
  usersSelectors.getUsers,
  (contacts, allUsers) => {
    return contacts
      .map(c => allUsers.get(c.get('user')))
      .filter(user => user && !user.get('blocked'))
  }
)
export const getESGSurveyRequests = (state: RootState) =>
  state.getIn(['buyer', 'supplierProfile', 'survey', 'esgRequestDetailByKey'])

export const getActiveESGSurveyRequest = createSelector(
  getESGSurveyRequests,
  ESGSurveyRequests => {
    const activeRequests = ESGSurveyRequests?.filter(
      request =>
        request.get('status') !== 'Completed' &&
        request.get('status') !== 'Cancelled'
    )
    return (activeRequests || fromJS({}))
      .sort((esgRequest1, esgRequest2) => {
        const date1 = moment(esgRequest1.getIn(['created', 'date']))
        const date2 = moment(esgRequest2.getIn(['created', 'date']))

        return date1.isBefore(date2) ? -1 : 1
      })
      .last()
  }
)

export const getUninvitedContactsForESGRequest = createSelector(
  getActiveESGSurveyRequest,
  getContacts,
  usersSelectors.getUsers,
  (createdActiveRequest, contacts, allUsers) => {
    let invitedContacts: List<string>
    let additionalRequestContacts: List<string>
    if (createdActiveRequest) {
      invitedContacts = fromJS([
        createdActiveRequest.getIn(['form', 'requestedContactId'])
      ])
      additionalRequestContacts = createdActiveRequest.getIn([
        'form',
        'additionalRequestContactIds'
      ])
      invitedContacts = additionalRequestContacts
        ? invitedContacts.concat(additionalRequestContacts)
        : invitedContacts
    }
    const uninvitedContacts = contacts.filter(
      c => !invitedContacts?.includes(c.get('user'))
    )
    return uninvitedContacts
      .map(c => allUsers.get(c.get('user')))
      .filter(user => user && !user.get('blocked'))
  }
)
