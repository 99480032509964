import { select, call } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import camelCase from 'lodash.camelcase'
import profileSelectors from '../../selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import cards from 'shared/utils/api/cards'
import Immutable, { fromJS, Set } from 'immutable'
import analytics from 'shared/utils/analytics'

export const previouslyTagged = (newTrimTags, combinedTags, tagsFieldName) =>
  newTrimTags.some((tag) => {
    if (combinedTags.hasIn([tagsFieldName, tag])) {
      return true
    } else {
      return false
    }
  })

export function* addTagSaga(action) {
  const { tagsFieldName, newTag, ignoreSplit, isToggled } =
    Immutable.Iterable.isIterable(action.payload)
      ? action.payload.toJS()
      : action.payload

  const newTrimTags = ignoreSplit
    ? [newTag]
    : newTag
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => !!tag)
  const newTags = newTrimTags.reduce((result, tag) => {
    result[camelCase(tag)] = tag
    return result
  }, {})
  const newTagsMap = newTrimTags.reduce((result, tag) => {
    result[camelCase(tag)] = {
      name: tag,
      count: 1,
      supplierTagged: false,
      userTagged: true,
      colleagueTagged: false,
    }
    return result
  }, {})

  // get current supplier and corresponding card
  const currentSupplierId = yield select(profileSelectors.getDataField, 'id')
  const currentCard = yield select(
    cardsSelectors.getBySupplier,
    currentSupplierId
  )
  const combinedTags = yield select((state) =>
    state.getIn(['buyer', 'supplierProfile', 'combinedTags'])
  )
  const userId = yield select(sessionSelectors.getUserId)
  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const orgUnitName = yield select(orgsSelectors.getCurrentUserOrgUnitName)

  analytics.track('Supplier Tags Created', {
    eventSource: 'Supplier Profile',
    action: 'Tag Created',
    tags: newTrimTags,
    supplierId: currentSupplierId,
    orgUnitId,
    orgUnitName,
  })

  let newCard
  let cardTags
  let tagsUnchanged
  let isInput = isToggled ? false : true
  // check if tag from multi input already exists in combinedTags. If so don't merge oldVal in combinedTags, just update Card
  const previouslyTaggedByUser = previouslyTagged(
    newTrimTags,
    combinedTags,
    tagsFieldName
  )

  if (
    !currentCard ||
    currentCard.size === 0 ||
    currentCard.get('placeholderCard') ||
    currentCard.get('id').endsWith('-1')
  ) {
    // create a card if is not there yet
    newCard = yield call(cards.create, {
      supplier: currentSupplierId,
      [tagsFieldName]: newTrimTags,
    })
  } else {
    // update the card's tags if the tag is not there
    cardTags = currentCard.getIn([tagsFieldName]).toList().toSet()
    const combinedTags = cardTags.union(Set(newTrimTags))

    if (cardTags.size < combinedTags.size) {
      // combined tags have more than original tags
      yield call(cards.update, currentCard.get('id'), {
        [tagsFieldName]: combinedTags.toJS(),
      })
    } else {
      tagsUnchanged = true
    }
  }

  return yield {
    cardId: currentCard.get('id'),
    tagsFieldName,
    newTags,
    newCard,
    combinedTags: combinedTags
      .updateIn([tagsFieldName], (value) =>
        value.mergeWith((oldVal, newVal) => {
          return tagsUnchanged || (isInput && previouslyTaggedByUser)
            ? oldVal
            : oldVal.merge(
                fromJS({
                  count: oldVal.get('count') + 1,
                  userTagged: true,
                  colleagues: oldVal.get('colleagues')
                    ? oldVal.get('colleagues').push(userId)
                    : [userId],
                })
              )
        }, fromJS(newTagsMap))
      )
      .toJS(),
  }
}

export default createApiRequestSaga(addTagSaga)
