import React, { ReactNode, useState, useMemo, useRef } from 'react'
import Menu from '@material-ui/core/Menu'
import Avatar from '../Avatar'
import UserNavItem from './UserNavItem'
import MyAccountUserNavItemContainer from '../../containers/MyAccountUserNavItemContainer'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'shared/components/Tooltip'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
/**
 * UserNav is an Avatar that drops down a popover from material-ui when clicked with any child elements plus a logout button inside<br/>
 * (don't use this directly use UserNavContainer)
 */

type Props = {
  /** this function is run when the logout option is selected */
  logout: () => void
  /** this is the url provided to the Avatar that the UserNav displays */
  profilePictureUrl: string
  hideMyAccount?: boolean
  children: ReactNode
  firstName: string
  lastName: string
}
const useStyles = makeStyles({
  icon: {
    transition: 'all .15s ease-in',
    '&:hover': {
      filter: 'invert(0.2)'
    }
  }
})

const UserNav = (props: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isMenuOpenedWithKeyboard, setIsMenuOpenedWithKeyboard] = useState<
    boolean
  >(false)
  const anchorEl = useRef<HTMLDivElement>(null)
  const classes = useStyles()

  const closeMenu = () => {
    setIsMenuOpen(false)
    setIsMenuOpenedWithKeyboard(false)
  }

  const handleClickAvatar = e => {
    e.preventDefault()
    setIsMenuOpen(true)
  }
  const handleEnterAvatar = e => {
    if (e.key === 'Enter') {
      setIsMenuOpen(true)
      setIsMenuOpenedWithKeyboard(true)
    }
  }

  const handleListKeyDown = e => {
    if (e.key === 'Tab') {
      e.preventDefault()
      setIsMenuOpen(false)
    } else if (e.key === 'Escape') {
      setIsMenuOpen(false)
    } else if (
      (e.key === 'ArrowDown' || e.key === 'ArrowUp') &&
      !isMenuOpenedWithKeyboard
    ) {
      setIsMenuOpenedWithKeyboard(true)
    }
  }

  const {
    logout,
    profilePictureUrl,
    children,
    hideMyAccount,
    firstName,
    lastName
  } = props

  const childrenWithProps = useMemo(
    () =>
      React.Children.map(children, (child: JSX.Element) => {
        if (!!children && children[0] === child) {
          React.cloneElement(child, {
            onClick: closeMenu,
            focus: hideMyAccount && isMenuOpenedWithKeyboard
          })
        } else {
          React.cloneElement(child, { onClick: closeMenu, focus: false })
        }
      }),
    [children, hideMyAccount, isMenuOpenedWithKeyboard]
  )

  return (
    <>
      <Tooltip
        title={
          <FormattedMessage
            id={'UserNav.UserTip'}
            defaultMessage={'Access your profile, help and other features'}
          />
        }
      >
        <div ref={anchorEl}>
          <FormattedMessage
            id={'UserNav.UserTip'}
            defaultMessage={'Access your profile, help and other features'}
          >
            {message => (
              <Button
                id='avatar'
                aria-label='My Profile'
                className={`flex items-end w3 ${
                  !profilePictureUrl ? classes.icon : ''
                }`}
                onClick={handleClickAvatar}
                onKeyDown={handleEnterAvatar}
                title={message?.toString()}
                aria-expanded={isMenuOpen}
                focusVisibleClassName='focus-outline'
                disableFocusRipple
              >
                <Avatar
                  url={profilePictureUrl}
                  className='w2'
                  name={`${firstName || ''} ${lastName || ''}`}
                />
                <span
                  style={{
                    width: 0,
                    height: 0,
                    borderLeft: '5px solid transparent',
                    borderRight: '5px solid transparent',
                    borderTop: '5px solid #777',
                    marginTop: 20
                  }}
                />
              </Button>
            )}
          </FormattedMessage>
        </div>
      </Tooltip>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl.current}
        id={'profile-menu'}
        onClose={closeMenu}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        getContentAnchorEl={null}
        onKeyDown={handleListKeyDown}
      >
        {!hideMyAccount && (
          <MyAccountUserNavItemContainer
            onClick={closeMenu}
            focus={isMenuOpenedWithKeyboard}
          />
        )}
        {childrenWithProps}
        <UserNavItem
          onClick={logout}
          label={
            <div>
              <FormattedMessage
                id={'UserNav.logout'}
                defaultMessage={'Logout'}
              />
            </div>
          }
          focus={
            hideMyAccount && !childrenWithProps && isMenuOpenedWithKeyboard
          }
        />
      </Menu>
    </>
  )
}

export default UserNav
