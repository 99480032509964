import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import profileSelectors from '../../selectors/profileSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import CertificationCategoryTitle from 'shared/components/CertificationCategoryTitle'
import certificationCategories, {
  CategoryType
} from 'shared/models/CertificationCategories'
import CertificationContainer from '../CertificationContainer'
import ValidationContainer from '../ValidationContainer'
import Text from 'shared/components/Text'
import Certification from 'shared/models/Certification'
import { RecordOf, List } from 'immutable'
import RootState from 'shared/models/RootState'
import { useIntl } from 'react-intl'
import { AccessControl } from 'shared/models/AccessControl'
import { FormattedMessage } from 'react-intl'
import CertAttributes from 'shared/components/CertAttributes'
import Tooltip from 'shared/components/Tooltip'
import startCase from 'lodash.startcase'
import { ReactComponent as Check } from 'shared/assets/icons/check-solid.svg'
import { ReactComponent as Question } from 'shared/assets/icons/question-solid.svg'
import { alertCodes as codeMessages } from 'shared/utils/data/defineMessages'
import { alertCodesDescriptions } from 'shared/utils/data/defineMessages'
import { ReactComponent as ImageArrowDown } from 'shared/assets/icons/arrow-down.svg'
import { ReactComponent as ImageArrowUp } from 'shared/assets/icons/arrow-up.svg'
import useThemeColors from 'shared/utils/useThemeColors'
import parseLocationToString from 'shared/utils/data/parseLocationToString'
import downloadAttachment from 'shared/utils/fileDownload/fileDownload'

type Props = {
  certifications: List<[CategoryType, List<RecordOf<Certification>>]>
  supplierId: string
  accessControl: AccessControl
}

export const DiversitySectionContainer = (props: Props) => {
  const intl = useIntl()
  const colors = useThemeColors()
  const { certifications, supplierId, accessControl } = props
  const [expanded, setExpanded] = useState<{ [key: string]: string }>({})
  const subCategoriesCount = useMemo(() => {
    return certifications?.reduce((counts, [cat, certs]) => {
      certs?.forEach(cert => {
        if (counts[cert.get('subCategory')]) {
          counts[cert.get('subCategory')] += 1
        } else {
          counts[cert.get('subCategory')] = 1
        }
      })
      return counts
    }, {})
  }, [certifications])

  const toggleSubCategory = subCategory => {
    setExpanded(
      Object.assign({}, expanded, { [subCategory]: !expanded[subCategory] })
    )
  }
  const downloadDiversityCert = attachmentName => {
    downloadAttachment(attachmentName, `suppliers/certification/attachment`)
  }

  return (
    <>
      {certifications &&
        certifications.map(([categoryKey, certs]) => {
          const title = certificationCategories[categoryKey]
            ? intl.formatMessage(certificationCategories[categoryKey].title)
            : categoryKey

          let currentSubCategory: string = ''
          let isSubCategoryRendered: { [subCategory: string]: boolean } = {}

          return (
            <div key={categoryKey} className='pt3-5'>
              <CertificationCategoryTitle
                title={title}
                icon={
                  certificationCategories[categoryKey] &&
                  certificationCategories[categoryKey].icon
                }
              />
              {certs &&
                certs.map((certification, index) => {
                  const status = certification.get('confirmed')
                    ? 'confirmed'
                    : certification.getIn(['info', 'potential'])
                    ? 'potential'
                    : undefined
                  const alertCodes = certification.getIn(['info', 'alertCodes'])
                  const subCategory = certification.get('subCategory')
                  const showSubCategoryHeader: boolean =
                    currentSubCategory !== subCategory
                  currentSubCategory = subCategory

                  if (
                    subCategoriesCount[subCategory] > 1 &&
                    !expanded[subCategory] &&
                    isSubCategoryRendered[subCategory]
                  ) {
                    return null
                  } else {
                    isSubCategoryRendered[subCategory] = true
                  }

                  return (
                    <div key={`${index}-${categoryKey}${subCategory}`}>
                      {showSubCategoryHeader && (
                        <div className='mt3'>
                          {categoryKey === 'diversity' &&
                            status &&
                            (status === 'confirmed' ? (
                              <Tooltip
                                title={
                                  <FormattedMessage
                                    id='CertAttributes.ConfirmedHelp'
                                    defaultMessage='Qualified: Certificate is active and passed automated quality assurance.'
                                  />
                                }
                              >
                                <span className='mr1'>
                                  <Check height={12} fill='#31b800' />
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={
                                  <>
                                    <FormattedMessage
                                      id='CertAttributes.PotentialHelp'
                                      defaultMessage='Potential: Issues with certificate are '
                                    />
                                    <br />
                                    {alertCodes &&
                                      alertCodes?.map(code => (
                                        <p key={code}>
                                          {`${
                                            codeMessages[code]
                                              ? intl.formatMessage(
                                                  codeMessages[code]
                                                )
                                              : startCase(code)
                                          } (${code}): ${
                                            codeMessages[code]
                                              ? intl.formatMessage(
                                                  alertCodesDescriptions[code]
                                                )
                                              : startCase(code)
                                          }`}
                                        </p>
                                      ))}
                                  </>
                                }
                              >
                                <span className='mr1'>
                                  <Question height={12} fill='#fcba03' />
                                </span>
                              </Tooltip>
                            ))}
                          <h5 className='f7 fw6 mv0 dib mr2'>
                            {categoryKey &&
                            subCategory &&
                            certificationCategories[categoryKey] &&
                            certificationCategories[categoryKey].subCategories[
                              subCategory
                            ]
                              ? intl.formatMessage(
                                  certificationCategories[categoryKey]
                                    .subCategories[subCategory]
                                )
                              : subCategory}
                          </h5>
                          {subCategoriesCount[subCategory] > 1 &&
                            (expanded[subCategory] ? (
                              <span
                                className='dim pointer mr2'
                                onClick={() => toggleSubCategory(subCategory)}
                              >
                                <ImageArrowUp />
                              </span>
                            ) : (
                              <span
                                className='dim pointer mr2'
                                onClick={() => toggleSubCategory(subCategory)}
                              >
                                <ImageArrowDown
                                  onClick={() => toggleSubCategory(subCategory)}
                                />
                              </span>
                            ))}
                          {subCategoriesCount[subCategory] > 1 &&
                            !expanded[subCategory] && (
                              <div
                                className={`dib br-pill ph2 pv1 white f7 fw6 pointer dim ${colors.primaryContained}`}
                                onClick={() => toggleSubCategory(subCategory)}
                              >
                                {subCategoriesCount[subCategory] - 1}
                              </div>
                            )}
                        </div>
                      )}
                      <CertificationContainer
                        expirationDate={certification.get('certExpiration')}
                        attachmentName={certification.getIn([
                          'cert',
                          'fileName'
                        ])}
                        handleDownload={downloadDiversityCert}
                        sourceUrl={certification.get('sourceURL')}
                        certAgency={certification.get('certAgency')}
                        certificateNumber={certification.get(
                          'certificateNumber'
                        )}
                        category={categoryKey}
                        subCategory={subCategory}
                        timeStamp={certification.get('timeStamp')}
                        lastModified={
                          certification.get('lastModifiedTimeStamp') ||
                          certification.get('loadDate') ||
                          (certification.get('note')?.toLowerCase() ===
                          'supplier website'
                            ? certification.get('timeStamp')
                            : undefined)
                        }
                        supplierCertified={certification.get(
                          'supplierCertified'
                        )}
                        esgSurveyDate={certification.get('esgSurveyDate')}
                        supplierName={certification.get('supplierName')}
                        subTypes={certification.get('subTypes')}
                        status={status}
                        alertCodes={alertCodes}
                        rightContent={
                          accessControl.access === 'full' ? (
                            <ValidationContainer
                              supplierId={supplierId}
                              category={categoryKey}
                              subCategory={certification.get('subCategory')}
                              timeStamp={certification.get('timeStamp')}
                              certAgency={certification.get('certAgency')}
                              certificateNumber={certification.get(
                                'certificateNumber'
                              )}
                            />
                          ) : null
                        }
                      >
                        {certification.get('certifyingLocation') && (
                          <>
                            <Text>
                              <FormattedMessage id='CertifyingCountry' />:{' '}
                              {certification.getIn([
                                'certifyingLocation',
                                'components',
                                'country'
                              ])}
                            </Text>
                          </>
                        )}
                        {/* extra code until patch is ran: certification?.getIn(['company', 'locations', 0]) || certification?.getIn(['company', 'address']) */}
                        <CertAttributes
                          certName={certification.getIn(['company', 'name'])}
                          certAddress={
                            parseLocationToString(
                              certification?.getIn(['company', 'location'])
                            ) ||
                            parseLocationToString(
                              certification?.getIn(['company', 'locations', 0])
                            ) ||
                            certification?.getIn(['company', 'address'])
                          }
                          certDuns={certification.getIn(['company', 'duns'])}
                          certParentDuns={certification.getIn([
                            'company',
                            'parentDuns'
                          ])}
                          websiteUrl={certification.getIn([
                            'company',
                            'websiteUrl'
                          ])}
                          contact={certification.getIn(['company', 'contact'])}
                          confirmed={certification.get('confirmed')}
                          potential={certification.getIn(['info', 'potential'])}
                          alertCodes={certification.getIn([
                            'info',
                            'alertCodes'
                          ])}
                          infoCodes={certification.getIn(['info', 'infoCodes'])}
                          validationCodes={certification.getIn([
                            'info',
                            'validationCodes'
                          ])}
                          naicsCodes={certification.get('certNaics')}
                        />
                      </CertificationContainer>
                    </div>
                  )
                })}
            </div>
          )
        })}
    </>
  )
}

export default connect((state: RootState) => {
  const certifications = profileSelectors.getDataField(state, 'certifications')

  return {
    supplierId: profileSelectors.getDataField(state, 'id'),
    certifications,
    accessControl: sessionSelectors.getAccessControl(state)
  }
})(DiversitySectionContainer)
