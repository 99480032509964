import React, { ChangeEvent, FormEvent, useState } from 'react'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Text from 'shared/components/Text'
import Label from 'shared/components/Label'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import CloseIcon from '@material-ui/icons/Close'
import Divider from 'shared/components/Divider'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '../../actions'
import RootState from 'shared/models/RootState'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import isEmail from 'validator/lib/isEmail'
import { List } from 'immutable'
import Scrollable from 'shared/components/Scrollable'

type Props = {
  intl: IntlShape
}
const ConciergePartnersSetting = (props: Props) => {
  const { intl } = props
  const dispatch = useDispatch()
  const partners: List<string> = useSelector((state: RootState) =>
    settingsSelectors.getSetting(state, 'partners')
  )
  const [newPartner, setNewPartner] = useState<string>('')
  const [invalidEmailError, setInvalidEmailError] = useState<string[]>([])

  const verifyEmail = (emailString: string) => {
    const emailArray = emailString
      .split(',')
      .map(input => input.trim())
      .filter(input => !!input)

    return emailArray.reduce(
      (result: { invalidEmail: string[]; validEmail: string[] }, currEmail) => {
        isEmail(currEmail)
          ? result.validEmail.push(currEmail)
          : result.invalidEmail.push(currEmail)
        return result
      },
      { invalidEmail: [], validEmail: [] }
    )
  }

  const handleSubmitPartner = (
    e: FormEvent<HTMLFormElement>,
    newPartner: string
  ) => {
    e.preventDefault()
    const verifiedEmail = verifyEmail(newPartner)

    if (verifiedEmail.invalidEmail.length === 0) {
      const newPartners = [...partners.toJS(), ...verifiedEmail.validEmail]
      const uniquePartners = newPartners.filter(
        (email, index) => newPartners.indexOf(email) === index
      )
      if (uniquePartners.toString() !== partners.toJS().toString()) {
        dispatch(
          updateSettings({
            partners: uniquePartners
          })
        )
      }
      setNewPartner('')
    } else {
      setInvalidEmailError(verifiedEmail.invalidEmail)
    }
  }

  const handleChangePartner = (e: ChangeEvent<HTMLInputElement>) => {
    setInvalidEmailError([])
    setNewPartner(e.currentTarget.value)
  }

  const handleRemoveClick = email => {
    const index = partners?.indexOf(email)
    if (index !== undefined && index !== -1) {
      dispatch(
        updateSettings({
          partners: partners.splice(index, 1).toJS()
        })
      )
    }
  }

  return (
    <>
      <Label>
        <FormattedMessage
          id='ConciergePartnersSetting.3rdPartyAccess'
          defaultMessage='3rd Party Access'
        />
      </Label>
      <form onSubmit={e => handleSubmitPartner(e, newPartner)}>
        <div className='flex items-top justify-between mt1'>
          <Input
            name={`newPartners`}
            type='text'
            value={newPartner}
            onChange={handleChangePartner}
            placeholder={intl.formatMessage({
              id: 'ConciergePartnersSetting.EnterEmail',
              defaultMessage: 'Enter User Email Address'
            })}
            meta={{
              error: invalidEmailError.length
                ? `${invalidEmailError.join(', ')} ${
                    invalidEmailError.length > 1 ? 'are' : 'is'
                  } not a valid email`
                : undefined,
              touched: true
            }}
            wrapperClassName='flex-auto'
          />
          <Button autoSize type='submit' className='ml2' disabled={!newPartner}>
            <FormattedMessage id='Add' defaultMessage='Add' />
          </Button>
        </div>
      </form>
      {partners?.size > 0 && <Divider className='mv2' />}
      <Scrollable>
        {partners?.map((partner, index) => (
          <div
            className='bg-white br1 ba b--black-10 f7 pa2 flex items-center justify-between'
            key={partner}
          >
            <Text>{partner}</Text>
            <CloseIcon
              onClick={() => {
                handleRemoveClick(partner)
              }}
              className='pointer dim'
            />
          </div>
        ))}
      </Scrollable>
    </>
  )
}

export default injectIntl(ConciergePartnersSetting)
