import React, { ReactElement } from 'react'
import Select from 'react-select'
import { FormattedMessage } from 'react-intl'
import { List, RecordOf } from 'immutable'
import User from 'shared/models/User'
import Button from 'shared/components/Button'
import AddIcon from '@material-ui/icons/AddCircle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import useSmallScreen from 'shared/utils/useSmallScreen'

type Props = {
  InputValue: string
  onInputChange: (inputValue: string) => void
  onChange: (data, metaAction) => void
  contacts: List<RecordOf<User>> | List<undefined>
  formatOptionLabel: (user, data) => any
  filterOption: (props: any) => boolean
  handleOnClickAddUser: () => void
  onClose: any
  open: any
  onSubmit: any
  onCancel: any
  fullWidth: boolean
  disableBackdropClick: boolean
  fullScreen: boolean
  description?: string | ReactElement<FormattedMessage>
}

const RequestDialog = ({
  InputValue,
  onInputChange,
  onChange,
  contacts,
  formatOptionLabel,
  filterOption,
  handleOnClickAddUser,
  onClose,
  open,
  onSubmit,
  onCancel,
  description,
}: Props) => {
  const matchMobile = useSmallScreen()

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      disableBackdropClick
      fullScreen={matchMobile}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>{description}</DialogTitle>
        <DialogContent className='mt3'>
          <Select
            aria-label='Select Contact'
            InputValue={InputValue}
            onInputChange={onInputChange}
            onChange={onChange}
            placeholder={
              (
                <FormattedMessage
                  id='UserLookup.Select Contact'
                  defaultMessage='Type in Contact Name'
                />
              ) as any
            }
            options={contacts.toJS()}
            formatOptionLabel={formatOptionLabel}
            filterOption={filterOption}
            getOptionValue={(opt) => opt.id || opt.value}
            noOptionsMessage={(inputValue) => 'No Contacts Found'}
            controlShouldRenderValue={true}
            styles={{
              control: (base) => ({
                ...base,
                height: 30,
              }),
              input: (base) => ({
                ...base,
                fontSize: '.875rem',
                height: 30,
              }),
              placeholder: (base) => ({
                ...base,
                fontSize: '.875rem',
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              noOptionsMessage: (base) => ({ ...base, fontSize: '.875rem' }),
              option: (base, { isDisabled, isFocused }) => ({
                ...base,
                backgroundColor: isDisabled
                  ? 'inherit'
                  : isFocused
                  ? '#E0E0E0'
                  : 'inherit',
                ':hover': {
                  ...base[':hover'],
                  backgroundColor: !isDisabled && '#e0e0e0',
                },
              }),
            }}
            menuPortalTarget={document.body}
          />
          <div className='mb3 mt3'>
            <div className='dib w-35'>
              <Button
                color='primary'
                variant='text'
                startIcon={<AddIcon />}
                label={
                  <FormattedMessage
                    id='SupplierProfileHeader.AddContact'
                    defaultMessage='Add a New Contact'
                  />
                }
                onClick={() => handleOnClickAddUser()}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className='fixed static-ns bottom-1'>
          <Button
            size={matchMobile ? 'large' : undefined}
            label={
              <FormattedMessage
                id='SupplierProfileHeader.Send'
                defaultMessage='Send'
              />
            }
            type='submit'
          />
          <Button
            size={matchMobile ? 'large' : undefined}
            label={<FormattedMessage id='CancelButton' />}
            onClick={onCancel}
            secondary
            autoSize
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default RequestDialog
