import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import parsePath from 'shared/utils/parsePath'
import useThemeColors from 'shared/utils/useThemeColors'

// tachyons class names
const linkClassNames = 'near-black no-underline pointer'

/**
 * Creates a clickable link that routes the user to a specific path passed into the "to" prop
 */

type Props = {
  params?: { [key: string]: string }
  className?: string
  ariaLabel?: string
} & LinkProps

const Link = forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
  const { to, params, className, ariaLabel, ...rest } = props
  const linkTo = params ? parsePath(to as string, params) : to
  const colors = useThemeColors()
  return (
    <RouterLink
      to={linkTo}
      className={classNames(linkClassNames, className, colors.primaryLinkHover)}
      aria-label={ariaLabel}
      ref={ref}
      {...rest}
    />
  )
})

export default Link
