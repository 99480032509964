import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import ListItem from 'shared/components/List/ListItem'
import Avatar from 'shared/components/Avatar'
import SustainabilityIcon from 'shared/assets/icons/sustainable.svg'
import Tooltip from 'shared/components/Tooltip'

const messages = defineMessages({
  isSustainabilityContact: {
    id: 'CollaborateInviteDialog.isSustainabilityContact',
    defaultMessage: 'Sustainability Contact'
  }
})
type ChatWithContactProps = {
  firstName: string
  lastName: string
  title?: string
  profilePictureUrl?: string
  isSustainabilityContact: boolean
  isEsgSurvey: boolean
  role?: string
}
export const ContactItem = (props: ChatWithContactProps) => {
  const {
    firstName,
    lastName,
    profilePictureUrl,
    title,
    isSustainabilityContact,
    isEsgSurvey,
    role
  } = props
  const intl = useIntl()

  return (
    <ListItem
      role={role}
      leftAvatar={
        <Avatar
          url={profilePictureUrl}
          className='mt1 w1-5'
          name={`${firstName || ''} ${lastName || ''}`}
        />
      }
      primaryText={
        <div className='flex'>
          <div className='flex items-center f7-5'>
            <span>
              {firstName} {lastName}
            </span>
          </div>
          {isEsgSurvey && isSustainabilityContact && (
            <Tooltip
              title={intl.formatMessage(messages.isSustainabilityContact)}
            >
              <img
                src={SustainabilityIcon}
                alt={intl.formatMessage(messages.isSustainabilityContact)}
                className='w1 ml2'
              />
            </Tooltip>
          )}
        </div>
      }
      secondaryText={
        title ? <div className='f9 ma0 gray'>{title}</div> : undefined
      }
      className='ma0'
      mini
    />
  )
}

export default ContactItem
