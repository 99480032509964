import { takeLatest, takeEvery, all, call } from 'redux-saga/effects'
import takeFirst from 'shared/utils/sagas/takeFirst'
import * as actionTypes from '../actionTypes'
import { REMOVE_LABEL_FROM_SUPPLIER } from '../../Search/actionTypes'
import loadSupplierProfileSaga from './loadSupplierProfileSaga'
import addTagSaga from './addTagSaga'
import removeTagSaga from './removeTagSaga'
import deletePrivateOfferingsSaga from './deletePrivateOfferingsSaga'
import updateRelationshipSaga from './updateRelationshipSaga'
import getVetBuyerSuppliersSaga from './getVetBuyerSuppliersSaga'
import updateCardSaga from './updateCardSaga2'
import addContactSaga from './addContactSaga'
import removeContactSaga from './removeContactSaga'
import removeContactRelationshipSaga from './removeContactRelationshipSaga'
import updateContactSaga from './updateContactSaga'
import addPersonalRelationshipSaga from './addPersonalRelationshipSaga'
import removePersonalRelationshipSaga from './removePersonalRelationshipSaga'
import updateAttachmentSaga from './updateAttachmentSaga'
import removeAttachmentSaga from './removeAttachmentSaga'
import renewAttachmentSaga from './renewAttachmentSaga'
import requestForProfileUpdateSaga from './requestForProfileUpdateSaga'
import requestForSelfCertifySaga from './requestForSelfCertifySaga'
import requestForESGSurveySaga from './requestForESGSurveySaga'
import requestNewContactForESGSurveySaga from './requestNewContactForESGSurveySaga'
import upvoteResponseSaga from './upvoteResponseSaga'
import clearVoteResponseSaga from './clearVoteResponseSaga'
import loadSocialFeedSaga from './loadSocialFeedSaga'
import addSupplierCommentSaga from './addSupplierCommentSaga'
import removeRatingResponsesSaga from './removeRatingResponsesSaga'
import batchAddPersonalRelationshipSaga from './batchAddPersonalRelationshipSaga'
import addCertificationValidationSaga from './addCertificationValidationSaga'
import removeCertificationValidationSaga from './removeCertificationValidationSaga'
import verifySupplierSaga from './verifySupplierSaga'
import removeRelationshipSaga from './removeRelationshipSaga'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import suppliers from 'shared/utils/api/suppliers'
import requestForBuyerReferenceSaga from './requestBuyerForReferenceSaga'
import partnerConsumer from 'shared/utils/api/partnerConsumer'
import requestEcovadisSupplierRatingSaga from 'buyer/SupplierProfile/sagas/requestEcovadisSupplierRatingSaga'
import survey from 'shared/utils/api/survey'
import relationships from 'shared/utils/api/relationships'

export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.REMOVE_CONTACT, removeContactSaga),
    takeLatest(
      actionTypes.REMOVE_CONTACT_RELATIONSHIP,
      removeContactRelationshipSaga
    ),
    takeLatest(actionTypes.UPDATE_CONTACT, updateContactSaga),
    takeFirst(actionTypes.ADD_CONTACT, addContactSaga),
    takeFirst(actionTypes.ADD_OUTREACH_CONTACT, addContactSaga),
    takeLatest(actionTypes.UPDATE_CARD, updateCardSaga),
    takeLatest(actionTypes.LOAD_SUPPLIER_PROFILE, loadSupplierProfileSaga),
    takeFirst(actionTypes.ADD_TAG, addTagSaga),
    takeLatest(actionTypes.REMOVE_TAG, removeTagSaga),
    takeLatest(
      actionTypes.DELETE_PRIVATE_OFFERINGS,
      deletePrivateOfferingsSaga
    ),
    takeLatest(actionTypes.UPDATE_RELATIONSHIP, updateRelationshipSaga),
    takeFirst(actionTypes.REMOVE_RELATIONSHIP, removeRelationshipSaga),
    takeLatest(actionTypes.GET_VET_BUYER_SUPPLIERS, getVetBuyerSuppliersSaga),
    takeEvery(
      actionTypes.ADD_PERSONAL_RELATIONSHIP,
      addPersonalRelationshipSaga
    ),
    takeLatest(
      [actionTypes.REMOVE_PERSONAL_RELATIONSHIP, REMOVE_LABEL_FROM_SUPPLIER],
      removePersonalRelationshipSaga
    ),
    takeLatest(
      actionTypes.UPDATE_RELATIONSHIP_ATTACHMENT,
      updateAttachmentSaga
    ),
    takeLatest(
      actionTypes.REMOVE_RELATIONSHIP_ATTACHMENT,
      removeAttachmentSaga
    ),
    takeLatest(actionTypes.RENEW_RELATIONSHIP_ATTACHMENT, renewAttachmentSaga),
    takeLatest(
      actionTypes.REQUEST_FOR_PROFILE_UPDATE,
      requestForProfileUpdateSaga
    ),
    takeLatest(actionTypes.REQUEST_FOR_SELF_CERTIFY, requestForSelfCertifySaga),
    takeLatest(actionTypes.REQUEST_FOR_ESG_SURVEY, requestForESGSurveySaga),
    takeLatest(
      actionTypes.REQUEST_NEW_CONTACT_FOR_ESG_SURVEY,
      requestNewContactForESGSurveySaga
    ),
    takeLatest(actionTypes.UPVOTE_RESPONSE, upvoteResponseSaga),
    takeLatest(actionTypes.CLEAR_VOTE_RESPONSE, clearVoteResponseSaga),
    takeLatest(actionTypes.LOAD_SOCIAL_FEED, loadSocialFeedSaga),
    takeLatest(actionTypes.ADD_SUPPLIER_COMMENT, addSupplierCommentSaga),
    takeLatest(actionTypes.DELETE_REVIEW, removeRatingResponsesSaga),
    takeLatest(
      actionTypes.BATCH_ADD_PERSONAL_RELATIONSHIP,
      batchAddPersonalRelationshipSaga
    ),
    takeLatest(
      actionTypes.ADD_CERTIFICATION_VALIDATION,
      addCertificationValidationSaga
    ),
    takeLatest(
      actionTypes.REMOVE_CERTIFICATION_VALIDATION,
      removeCertificationValidationSaga
    ),
    takeFirst(actionTypes.VERIFY_SUPPLIER, verifySupplierSaga),
    takeFirst(
      actionTypes.UPDATE_SUPPLIER_STATUS,
      createApiRequestSaga(suppliers.updateStatus, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      actionTypes.REQUEST_BUYER_REFERENCE,
      requestForBuyerReferenceSaga
    ),
    takeFirst(
      actionTypes.REQUEST_ECOVADIS_SUPPLIER_RATING,
      requestEcovadisSupplierRatingSaga
    ),
    takeFirst(
      actionTypes.RETRIEVE_ECOVADIS_ASSESSMENT_STATUS,
      createApiRequestSaga(partnerConsumer.retrieveEcovadisAssessmentStatus, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      actionTypes.RETRIEVE_ECOVADIS_SCORECARD,
      createApiRequestSaga(partnerConsumer.retrieveEcovadisScorecard, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      actionTypes.LOAD_SURVEY,
      createApiRequestSaga(survey.getSurveys, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      actionTypes.LOAD_SURVEY_RESPONSE,
      createApiRequestSaga(survey.getSurveyResponses, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      actionTypes.LOAD_ESG_REQUEST_DETAILS,
      createApiRequestSaga(survey.getESGSurveyRequestDetails, {
        usePayload: true,
        notifyOnError: true
      })
    ),
    takeFirst(
      actionTypes.VERIFY_ESG_ATTACHMENT,
      createApiRequestSaga(function*(action) {
        return yield call(survey.verifyAttachment, action.payload)
      })
    ),
    takeFirst(
      actionTypes.TRANSFER_SPEND,
      createApiRequestSaga(function*(action) {
        return yield call(relationships.transferSpend, action.payload, {
          usePayload: true,
          returnPayload: true
        })
      })
    )
  ])
}
