import React, { useEffect, useCallback } from 'react'
import Page from '../shared/components/Page'
import Paper from '../shared/components/Paper'
import Main from '../shared/components/Layout/Main'
import Aside from '../shared/components/Layout/Aside'
import MessageBoxContainer from './containers/MessageBoxContainer'
import Text from '../shared/components/Text'
import H2 from '../shared/components/H2'
import Divider from '../shared/components/Divider'
import { selectUserToMessage, loadMessageTopics } from '../shared/actions'
import { connect } from 'react-redux'
import usersSelectors from '../shared/selectors/usersSelectors'
import messagesSelectors from 'shared/selectors/messagesSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import ConversationListItemContainer from './containers/ConversationListItemContainer'
import { exitMessagesApp } from './actions'
import buyerPaths from 'buyer/routes/paths'
import supplierPaths from 'supplier/routes/paths'
import parsePath from 'shared/utils/parsePath'
import Scrollable from 'shared/components/Scrollable'
import { Theme, createStyles, withStyles } from '@material-ui/core/styles'
import UserLookup from './components/UserLookup'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import RootState from 'shared/models/RootState'
import { RecordOf, List } from 'immutable'
import User from 'shared/models/User'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import useSmallScreen from 'shared/utils/useSmallScreen'
import sessionSelectors from 'shared/selectors/sessionSelectors'

export const UserLookupContainer = connect(state => ({
  users: messagesSelectors.getUsersThatCanMessage(state)
}))(UserLookup)

const styles = (theme: Theme) =>
  createStyles({
    aside: {
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        top: 50
      }
    }
  })

type Props = {
  currentUserId: string
  isClientApp: boolean
  messageTopicId: string
  userToMessage: RecordOf<User>
  conversations: List<
    RecordOf<{
      closed: boolean
      conversationWithUser: string
      createdBy: string
      createdDate: Date
      id: string
      lastMessage: string
      lastMessageBy: string
      lastMessageDate: Date
      numUnreadMessages: number
    }>
  >
  classes: { aside: string }
  loadMessageTopics: (params: {
    userInfo: {
      id: string
    }
  }) => void
  exitMessagesApp: () => void
  selectUserToMessage: (userToMessage: {
    messageTopicId?: string
    userToMessage: string
    loading: boolean
  }) => void
}

export const App = (props: Props) => {
  const {
    loadMessageTopics,
    exitMessagesApp,
    currentUserId,
    isClientApp
  } = props
  const isSmallScreen = useSmallScreen()
  const history = useHistory()

  useEffect(() => {
    loadMessageTopics({
      userInfo: {
        id: currentUserId
      }
    })
    return exitMessagesApp
  }, [loadMessageTopics, exitMessagesApp, currentUserId])

  const updatePath = useCallback(
    (messageTopicId?: string) => {
      let url = ''
      if (messageTopicId) {
        url = parsePath(
          isClientApp ? buyerPaths.messageTopic : supplierPaths.messageTopic,
          { messageTopicId }
        )
      } else {
        url = isClientApp ? buyerPaths.messages : supplierPaths.messages
      }

      history.push(url)
    },
    [isClientApp, history]
  )

  const handleClickUser = useCallback(
    (userId: string) => {
      const { selectUserToMessage, conversations } = props
      const conversation = conversations.find(
        c => c.get('conversationWithUser') === userId
      )
      const messageTopicId = conversation && conversation.get('id')

      updatePath(messageTopicId)

      selectUserToMessage({
        messageTopicId,
        userToMessage: userId,
        loading: true
      })
    },
    [props, updatePath]
  )

  const handleClickConversation = useCallback(
    topic => {
      const { selectUserToMessage, messageTopicId } = props

      if (messageTopicId !== topic.get('id')) {
        updatePath(topic.get('id'))

        selectUserToMessage({
          userToMessage: topic.get('conversationWithUser'),
          loading: true
        })
      }
    },
    [props, updatePath]
  )

  const { userToMessage, conversations, messageTopicId } = props

  return (
    <Page title='Messages'>
      <Aside left className={props.classes.aside}>
        <Paper noPadding>
          <H2 className='pl3'>
            <FormattedMessage
              id='App.Conversation'
              defaultMessage='Conversation'
            />
          </H2>
          <Divider />
          <FormattedMessage
            id='App.WhoDoYouWantToChatWith'
            defaultMessage='Who do you want to Chat with?'
          >
            {message => (
              <UserLookupContainer
                placeholder={message}
                onClickUser={handleClickUser}
              />
            )}
          </FormattedMessage>
          <Divider />

          <Scrollable maxHeight={500} role='list'>
            {conversations &&
              conversations.map(topic => (
                <div role='listitem' key={topic.get('id')}>
                  <div
                    className='pointer'
                    role='button'
                    key={topic.get('id')}
                    onClick={() => handleClickConversation(topic)}
                    onKeyPress={e =>
                      e.key === 'Enter' ? handleClickConversation(topic) : ''
                    }
                  >
                    <ConversationListItemContainer
                      userId={topic.get('conversationWithUser')}
                      lastMessage={topic.get('lastMessage')}
                      lastMessageDate={topic.get('lastMessageDate')}
                      lastMessageBy={topic.get('lastMessageBy')}
                      numUnreadMessages={topic.get('numUnreadMessages')}
                      selected={messageTopicId === topic.get('id')}
                    />
                  </div>
                </div>
              ))}
          </Scrollable>

          {(!conversations || conversations.size < 1) && (
            <Text className='pa4 tc'>
              <FormattedMessage
                id='App.ThereAreNoCurrentMessages'
                defaultMessage='There are no current messages.
                  Reach out to others to start a conversation.'
              />
            </Text>
          )}
        </Paper>
      </Aside>

      {isSmallScreen ? (
        <Dialog fullScreen open={!!(userToMessage || messageTopicId)}>
          <DialogContent id='message-dialog-content' style={{ padding: 0 }}>
            <Main className='flex flex-column'>
              {(userToMessage || messageTopicId) && (
                <MessageBoxContainer
                  messageTopicId={messageTopicId}
                  updatePath={updatePath}
                  dialog
                />
              )}
            </Main>
          </DialogContent>
        </Dialog>
      ) : (
        <Main className='mt3 mt0-l'>
          {userToMessage || messageTopicId ? (
            <MessageBoxContainer
              messageTopicId={messageTopicId}
              updatePath={updatePath}
            />
          ) : (
            <Text className='tc mt3'>
              <FormattedMessage
                id='App.PickAPersonToChatWithFirst'
                defaultMessage='Pick a person to chat with first'
              />
            </Text>
          )}
        </Main>
      )}
    </Page>
  )
}

type ContainerProps = {
  match: { params: { messageTopicId: string } }
}
export default connect(
  (state: RootState, props: ContainerProps) => {
    const userToMessageId = state.getIn(['mess', 'userToMessage'])
    const isClientApp = routingSelectors.getPathname(state).includes('client')
    const messageTopicId = props.match.params.messageTopicId

    return {
      currentUserId: sessionSelectors.getUserId(state),
      isClientApp,
      messageTopicId,
      userToMessage:
        userToMessageId && usersSelectors.getById(state, userToMessageId),
      conversations: messagesSelectors.getConversations(state)
    }
  },
  {
    selectUserToMessage,
    loadMessageTopics,
    exitMessagesApp
  }
)(withStyles(styles)(App))
