import React from 'react'
import Scrollable from 'shared/components/Scrollable'
import ListItem from 'shared/components/List/ListItem'
import OrgLogo from 'shared/components/OrgLogo'
import Link from 'shared/components/Link'
import paths from 'buyer/routes/paths'
import fileToUrl from 'shared/utils/data/fileToUrl'
import Text from 'shared/components/Text'
import dateFormat from 'shared/utils/dateFormat'
import { FormattedMessage } from 'react-intl'
import Pill from 'shared/components/Pill'
import withUser from 'shared/utils/withUser'
import UserListItem from 'shared/components/UserListItem'
import { RecentSuppliersTag } from 'posting/App'
import { RecordOf, Map } from 'immutable'

const UserItem = withUser(UserListItem)
type Props = {
  recentSupplierTag: Map<string, RecordOf<RecentSuppliersTag>>
  currentUserId: string
}
const RecentTagsOnSupplier = ({ recentSupplierTag, currentUserId }: Props) => {
  const renderTags = tags => {
    return tags
      ? tags.entrySeq().map(([tag, user]) => (
          <Pill
            label={tag}
            key={tag}
            color={
              currentUserId === user.get('user') ? 'primary' : 'primaryLight'
            }
            title={
              <div className='pa2'>
                <Text className='fw6'>{dateFormat(user.get('date'))}</Text>
                <UserItem
                  key={user.get('user')}
                  userId={user.get('user')}
                  mini
                  className='mt2'
                />
              </div>
            }
          />
        ))
      : null
  }

  return (
    <Scrollable role='list'>
      {recentSupplierTag.entrySeq().map(([supplierId, tags]) => {
        return (
          <ListItem
            key={supplierId}
            mini
            primaryText={
              <div className='flex items-center mb2'>
                <div className='w2 h2 flex-none mr2'>
                  <Link to={paths.supplierProfile} params={{ supplierId }}>
                    <OrgLogo
                      url={fileToUrl(tags.getIn(['supplierInfo', 'logo']))}
                      name={tags.getIn(['supplierInfo', 'name'])}
                    />
                  </Link>
                </div>
                <Link to={paths.supplierProfile} params={{ supplierId }}>
                  {tags.getIn(['supplierInfo', 'name'])}
                </Link>
              </div>
            }
            secondaryText={
              <>
                {tags.get('offerings') && tags.get('offerings').size > 0 && (
                  <>
                    <Text>
                      <FormattedMessage
                        id='RecentTagsOnSupplier.Offerings'
                        defaultMessage='Offerings'
                      />
                      :{' '}
                    </Text>
                    {renderTags(tags.get('offerings'))}
                  </>
                )}
                {tags.get('differentiators') &&
                  tags.get('differentiators').size > 0 && (
                    <div className='mt2'>
                      <Text>
                        <FormattedMessage
                          id='RecentTagsOnSupplier.Differentiators'
                          defaultMessage='Differentiators'
                        />
                        :{' '}
                      </Text>
                      {renderTags(tags.get('differentiators'))}
                    </div>
                  )}
                {tags.get('privateOfferings') &&
                  tags.get('privateOfferings').size > 0 && (
                    <div className='mt2'>
                      <Text>
                        <FormattedMessage
                          id='RecentTagsOnSupplier.InternalOfferings'
                          defaultMessage='Internal Offerings'
                        />
                        :{' '}
                      </Text>
                      {renderTags(tags.get('privateOfferings'))}
                    </div>
                  )}
              </>
            }
          />
        )
      })}
    </Scrollable>
  )
}

export default RecentTagsOnSupplier
