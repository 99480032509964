import React, { Component, Fragment } from 'react'
import Page from 'shared/components/Page'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import FileInput from 'shared/components/FileInput'
import Card from 'shared/components/Card'
import Warning from 'shared/components/Warning'
import { Field } from 'redux-form/immutable'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import InputLabel from '@material-ui/core/InputLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import formValidators from 'shared/utils/formValidators'
import Switch from 'shared/components/Switch'
import Main from 'shared/components/Layout/Main'
import Aside from 'shared/components/Layout/Aside'
import loader from 'shared/utils/api/loader'
import startCase from 'lodash.startcase'
import TealbotTips from 'shared/components/TealbotTips'

type Props = {
  onUploadFile: () => void
  errorMessage: string
}
type State = {
  entryFileType: 'attachFile' | 'enterFileName'
  loaderState: {}
  stateCounter: number
}

class LoaderPage extends Component<Props, State> {
  intervalId: any

  state: State = {
    entryFileType: 'attachFile',
    loaderState: {},
    stateCounter: 0
  }

  getLoaderState = () => {
    loader
      .getStatus()
      .then(response => {
        const counter = this.state.stateCounter + 1

        this.setState({
          loaderState: response,
          stateCounter: counter
        })

        if (counter === 20) {
          clearInterval(this.intervalId)
        }
      })
      .catch(console.error)
  }

  componentDidMount() {
    this.getLoaderState()
    this.intervalId = global.setInterval(this.getLoaderState, 20000)
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  handleEntryFileTypeChange = e => {
    this.setState({
      entryFileType: e.target.value
    })
  }

  render() {
    const { onUploadFile, errorMessage } = this.props
    const { entryFileType, loaderState } = this.state

    return (
      <Page title='Loader'>
        <Main>
          <Card>
            <TealbotTips noLabel>
              <Text>
                Use this form to load organizations and their data. If you want
                to load supplier master or spend data, sign into the appropriate
                Buyer and load from there.
              </Text>
            </TealbotTips>
            <form className='mt3' onSubmit={onUploadFile}>
              <InputLabel>Kind of Update</InputLabel>
              <Field
                name='updateType'
                className='mb3'
                required
                component={({
                  input: { onChange, value },
                  meta: { error },
                  ...rest
                }) => (
                  <RadioGroup onChange={onChange} value={value} {...rest}>
                    <FormControlLabel
                      control={
                        <Radio
                          color='primary'
                          inputProps={{
                            name: 'updateType',
                            required: true
                          }}
                        />
                      }
                      value='update'
                      label='Update'
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          color='primary'
                          inputProps={{
                            name: 'updateType',
                            required: true
                          }}
                        />
                      }
                      value='upsert'
                      label='Upsert'
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          color='primary'
                          inputProps={{
                            name: 'updateType',
                            required: true
                          }}
                        />
                      }
                      value='create'
                      label='Create'
                    />
                    {error}
                  </RadioGroup>
                )}
              />

              <InputLabel>Attach a file or enter a file name</InputLabel>
              <RadioGroup
                name='entryFileType'
                onChange={this.handleEntryFileTypeChange}
                value={entryFileType}
              >
                <FormControlLabel
                  control={<Radio color='primary' />}
                  value='attachFile'
                  label='Attach a File'
                />
                <FormControlLabel
                  control={<Radio color='primary' />}
                  value='enterFileName'
                  label='Enter FileName'
                />
              </RadioGroup>

              {entryFileType === 'attachFile' && (
                <Fragment>
                  <Field
                    component={FileInput}
                    accept='.txt, .json, .csv, .xlsx'
                    className='mt3'
                    name='attachedFile'
                    limit={1000}
                    required
                  />
                  <Warning
                    message='Make sure the file meets the required format'
                    className='mt2 mb4'
                  />
                </Fragment>
              )}

              {entryFileType === 'enterFileName' && (
                <Fragment>
                  <InputLabel>Enter File Name</InputLabel>
                  <Field
                    component={Input}
                    name='fileName'
                    required
                    className='mb4'
                  />
                </Fragment>
              )}

              <Label className='mt3' htmlFor='body'>
                Request Body (must be JSON)
              </Label>
              <Field
                component={TextArea}
                name='body'
                id='body'
                validate={[formValidators.isJSON]}
              />

              <div className='mt3'>
                <Field
                  component={Switch}
                  name='overrideSupplier'
                  label='Update profiles owned by suppliers?'
                  className='mt3 db'
                />
              </div>

              <div className='mt3'>
                <Field
                  component={Switch}
                  name='useLocalServer'
                  label='Use local server https://localhost:3001'
                  className='mt3 db'
                />
              </div>

              {errorMessage && (
                <Warning message={errorMessage} className='mt4 mb3' />
              )}

              <Button
                label='Upload File'
                type='submit'
                autoSize
                className='mt3'
              />
            </form>
          </Card>
        </Main>
        <Aside>
          <Card>
            <InputLabel>Loader State</InputLabel>
            {Object.keys(loaderState)
              .filter(key => loaderState[key])
              .sort()
              .map(key => {
                return (
                  <div key={key}>
                    <Label>{startCase(key)}</Label>
                    <Text>{loaderState[key].toString()}</Text>
                  </div>
                )
              })}
          </Card>
        </Aside>
      </Page>
    )
  }
}

export default LoaderPage
