import { FormattedMessage } from 'react-intl'
import React from 'react'
import Label from 'shared/components/Label'
import FileInput from 'shared/components/FileInput'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import useThemeColors from 'shared/utils/useThemeColors'
import exportData from 'shared/utils/exportData'
import { ReactComponent as DownloadIcon } from 'shared/assets/icons/download.svg'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: 'dark-grey',
      marginTop: '4px',
      marginBottom: '10px'
    },
    downloadTemplateStyles: {
      marginTop: '20px',
      float: 'right'
    }
  })
)

type Props = {
  isUploading: boolean
  handleUploadFileChange: (file: File | null) => void
  fileUpload: File | null
}
const BulkAddUsers = (props: Props) => {
  const { isUploading, handleUploadFileChange, fileUpload } = props
  const classes = useStyles()
  const colorClasses = useThemeColors()

  const downloadTemplate = () => {
    const data = [
      {
        firstName: 'John',
        lastName: 'Doe',
        email: 'johndoe@somedomain.com',
        roles: ''
      },
      {
        firstName: 'Anne',
        lastName: 'Frank',
        email: 'annefrank@somedomain.com',
        roles:
          'Supplier|Supplier Admin|Buyer|Buyer RFI Approver|Buyer Admin|Buyer Data Admin|Buyer Data Export'
      }
    ]
    exportData.exportCSV(data, 'Template')
  }

  return (
    <>
      <Label>
        <div className={classes.root}>
          <FormattedMessage
            id='BulkAddUsers.Instructions'
            defaultMessage='Instructions: Please use the excel template to add users in bulk'
          />
        </div>
      </Label>
      <FileInput
        accept='.csv, .xls, .xlsx'
        limit={32}
        onChange={handleUploadFileChange}
        required
        isUploading={isUploading}
        value={fileUpload ? fileUpload.name : ''}
        aria-live='assertive'
        aria-label={
          !isUploading && fileUpload?.name
            ? 'Uploaded file ' + fileUpload.name
            : ''
        }
      />
      <div className={classes.downloadTemplateStyles}>
        <span
          title='Example template csv file'
          className={`pointer ${colorClasses.primaryText}`}
          onClick={() => downloadTemplate()}
          tabIndex={0}
          role='button'
        >
          <DownloadIcon height='16' />
          <FormattedMessage
            id='BulkAddUsers.DownloadTemplate'
            defaultMessage='Download Template'
          />
        </span>
      </div>
    </>
  )
}

export default BulkAddUsers
