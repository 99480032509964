import React, { useState } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form/immutable'
import { RouteComponentProps } from 'react-router-dom'
import createSubmitFormAction from 'shared/utils/createSubmitFormAction'
import Card from 'shared/components/Card'
import RegistrationPage1 from './RegistrationPage1'
import RegistrationPage2 from './RegistrationPage2'
import RegistrationPage3 from './RegistrationPage3'
import auth from 'shared/utils/api/auth'
import { REGISTER } from '../../actionTypes'
import { fromJS } from 'immutable'
import UploadedFile from 'shared/models/UploadedFile'
import { changeLanguage } from 'i18n/store/actions'
import RootState from 'shared/models/RootState'
import qs from 'qs'
import DefaultLandingPage from '../../components/DefaultLandingPage'
import SustainabilityLandingPage from '../../components/SustainabilityLandingPage'
import SelfCertificationLandingPage from '../../components/SelfCertificationLandingPage'

type Props = {
  email: string
  language: string
  client?: string
  iframe?: boolean
  campaign?: 'sustainability' | 'self-certification'
  register: (arg: string) => void
  onChangeLanguage: (lang: string) => void
} & RouteComponentProps

export const RegistrationWizard = (props: Props) => {
  const [page, setPage] = useState<number>(1)
  const [forbidden, setForbidden] = useState<boolean>(false)
  const [matchedOrgs, setMatchedOrgs] = useState<
    Array<{
      id: string
      supplier: { logo: UploadedFile }
      name: string
      domains: string[]
      alternativeNames: string[]
    }>
  >([])

  const {
    email,
    register,
    language,
    location,
    client,
    iframe,
    campaign,
    onChangeLanguage
  } = props

  const nextPage = () => {
    // check for the org unit to decide what's the second step
    if (page === 1) {
      auth.checkOrgUnitByEmail(email).then(({ found, forbidden, names }) => {
        // if the org unit is found in our database,
        // ask them to select it to confirm we got the right one
        if (found && names) {
          setPage(2)
          setMatchedOrgs(names)
          // if the org unit is forbidden then ask them more info
        } else if (forbidden) {
          setPage(3)
          setForbidden(forbidden)
        } else {
          // otherwise, it looks like this is a brand new org unit,
          // register them as is, and we'll create an org unit
          register(
            fromJS({
              email
            })
          )
        }
      })
    } else {
      setPage(currentPage => currentPage + 1)
    }
  }

  const renderRegisterForm = (requestUrl = '') => {
    return (
      <Card>
        {page === 1 && (
          <RegistrationPage1
            onSubmit={nextPage}
            language={language}
            onChangeLanguage={onChangeLanguage}
            location={location}
            requestUrl={requestUrl}
          />
        )}

        {page === 2 && (
          <RegistrationPage2 nextPage={nextPage} matchedOrgs={matchedOrgs} />
        )}

        {page === 3 && <RegistrationPage3 hideNoneOfTheAbove={forbidden} />}
      </Card>
    )
  }

  if (campaign === 'sustainability') {
    return (
      <SustainabilityLandingPage>
        {renderRegisterForm('/supplier/esg-survey/health-safety')}
      </SustainabilityLandingPage>
    )
  } else if (campaign === 'self-certification') {
    return (
      <SelfCertificationLandingPage>
        {renderRegisterForm('/supplier/company#qualifications')}
      </SelfCertificationLandingPage>
    )
  } else {
    return (
      <DefaultLandingPage client={client} iframe={iframe}>
        {renderRegisterForm()}
      </DefaultLandingPage>
    )
  }
}

const formName = 'accounts/registration'
const selector = formValueSelector(formName)

export default connect(
  (state: RootState, props: RouteComponentProps) => {
    const { client, options, campaign } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    })

    const iframe = !!options && options.split(',').includes('iframe')

    return {
      client,
      iframe,
      campaign,
      email: selector(state, 'email'),
      language: state.get('i18n').locale
    }
  },
  {
    register: createSubmitFormAction(formName, REGISTER),
    onChangeLanguage: changeLanguage
  }
)(RegistrationWizard)
