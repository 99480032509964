import React, { ChangeEvent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Label from 'shared/components/Label'
import Select from 'shared/components/Select'

type Props = {
  setSpendType: React.Dispatch<React.SetStateAction<string>>
  spendTypeOptions: string[]
  spendType: string
  messages: any
}

export const SpendTypeSelect = ({
  setSpendType,
  spendTypeOptions,
  spendType,
  messages
}: Props) => {
  const intl = useIntl()
  return (
    <>
      <Label htmlFor='spendType'>
        <FormattedMessage
          id='UploadSpendDetail.SpendType'
          defaultMessage='Spend type'
        />
      </Label>
      <Select
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          setSpendType(e.currentTarget.value)
        }
        value={spendType}
        fullWidth
        name='spendType'
      >
        <FormattedMessage
          id='UploadSpendDetail.SelectSpendType'
          defaultMessage='Select spend type'
        >
          {msg => <option value=''>{msg}</option>}
        </FormattedMessage>
        {spendTypeOptions.map(o => (
          <option key={o} value={o}>
            {intl.formatMessage(messages[o])}
          </option>
        ))}
      </Select>
    </>
  )
}
