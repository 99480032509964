import React, { Component, ReactElement } from 'react'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import { RecordOf, List } from 'immutable'
import UploadedFile from 'shared/models/UploadedFile'
import { FormattedMessage } from 'react-intl'
import FileInput from 'shared/components/FileInput'
import PitchDeck from 'shared/components/PitchDeck'
import DeleteIcon from '@material-ui/icons/Delete'
import Fab from '@material-ui/core/Fab'

type Props = {
  readonly?: boolean
  limit?: number
  allowWordDocs?: boolean
  attachment: List<RecordOf<UploadedFile>>
  label?: string | ReactElement<FormattedMessage>
  onSave?: (file: File) => void
  onRemove?: (filename: string) => void
  onDownload?: (fileName: string) => void
  getFilePreview?: (fileName: any) => Promise<string | void>
}

type State = {
  newAttachment?: File | null
  removeAttachment?: string | null
  attachmentDialog: boolean
  fileURLs: (string | void)[]
}

class FileAttachmentList extends Component<Props, State> {
  fileInput: any = {}

  state: State = {
    newAttachment: null,
    removeAttachment: null,
    attachmentDialog: false,
    fileURLs: []
  }

  componentDidMount() {
    const { attachment, getFilePreview } = this.props
    const attachmentList = attachment || List([])
    if (getFilePreview) {
      Promise.all(
        attachmentList.map(document => getFilePreview(document.get('fileName')))
      ).then(allFileUrls => this.setState({ fileURLs: allFileUrls }))
    }
  }
  componentDidUpdate(prevProps) {
    const { attachment, getFilePreview } = this.props
    const attachmentList = attachment || List([])
    const prevAttachmentList = prevProps.attachment || List([])
    const newAttachmentDifference = attachmentList.filter(
      x => !prevAttachmentList.includes(x)
    )
    if (!!newAttachmentDifference.size && getFilePreview) {
      getFilePreview(
        newAttachmentDifference.get(0)?.get('fileName')
      ).then(newFileUrls =>
        this.setState({ fileURLs: [...this.state.fileURLs, newFileUrls] })
      )
    }
  }
  handleChangeNewAttachment = newAttachment => {
    this.setState({
      newAttachment,
      attachmentDialog: true
    })
  }

  handleChangeRemoveAttachment = removeAttachment => {
    this.setState({
      removeAttachment,
      attachmentDialog: true
    })
  }

  handleCancelAttachmentActions = () => {
    this.setState({
      newAttachment: null,
      removeAttachment: null,
      attachmentDialog: false
    })
  }

  handleSaveAttachment = () => {
    const { onSave } = this.props
    const { newAttachment } = this.state
    if (newAttachment && onSave) {
      onSave(newAttachment)
    }
    this.handleCancelAttachmentActions()
  }

  handleDeleteAttachment = () => {
    const { onRemove } = this.props
    const { removeAttachment, fileURLs } = this.state
    if (removeAttachment && onRemove) {
      const fileUrl = fileURLs.find(
        fileUrl => fileUrl && fileUrl.includes(removeAttachment)
      )
      onRemove(removeAttachment)
      this.setState({
        fileURLs: fileURLs.filter(url => url !== fileUrl)
      })
    }
    this.setState({
      attachmentDialog: false
    })
  }

  render() {
    const {
      attachment,
      allowWordDocs,
      label,
      limit,
      readonly,
      onDownload
    } = this.props
    const {
      newAttachment,
      removeAttachment,
      attachmentDialog,
      fileURLs
    } = this.state
    const fileTypes = ['.pdf', '.pptx', '.xlsx', '.csv', '.doc', '.docx']
    //filter for settings selector 'allowWordDocs'
    const acceptedFileTypes = allowWordDocs
      ? fileTypes.join(', ')
      : fileTypes.filter(doc => doc !== '.doc' && doc !== '.docx').join(', ')

    const attachmentList = attachment || List([])

    return (
      <div className='w-100 dt bg-white'>
        <div className='w-100 pv3 flex items-center'>
          <div className='f7 fw6 ma0 flex-auto dib'>
            {label}
            {limit && (
              <FormattedMessage
                id='FileAttachmentList.LimitTo'
                defaultMessage=' (Up to {limit} files)'
                values={{ limit }}
              />
            )}
          </div>
          {!readonly && (!limit || limit > attachmentList.size) && (
            <>
              <Button
                secondary
                autoSize
                size='small'
                onClick={() => this.fileInput.click()}
              >
                <FormattedMessage
                  id='FileAttachmentList.AttachAFile'
                  defaultMessage='Attach a file'
                />
              </Button>
              <label
                hidden
                ref={label => {
                  this.fileInput = label
                }}
              >
                <FileInput
                  name='responseFile'
                  accept={acceptedFileTypes}
                  onChange={this.handleChangeNewAttachment}
                  value=''
                />
              </label>
            </>
          )}
        </div>
        {!readonly && (
          <Text>
            <FormattedMessage
              id='FileAttachmentList.AttachmentSize'
              defaultMessage='Maximum file size is 35MB'
            />
            <br />
            <FormattedMessage
              id='FileAttachmentList.AttachmentFormatUpdate'
              defaultMessage='Supported files {acceptedFileTypes}'
              values={{ acceptedFileTypes }}
            />
          </Text>
        )}
        {attachmentList &&
          attachmentList.size > 0 &&
          onDownload &&
          attachmentList.map((file, index) => {
            return (
              <div key={file.get('fileName')} className='pv2 relative'>
                <PitchDeck
                  url={
                    fileURLs.find(
                      fileUrl =>
                        fileUrl && fileUrl.includes(file.get('fileName'))
                    ) || ''
                  }
                  postfix={`#${index + 1}`}
                  label={file.get('description')}
                  fileName={file.get('fileName')}
                  onDownload={() => onDownload(file.get('fileName'))}
                />
                {!readonly && (
                  <div
                    className='absolute'
                    style={{ top: '1.25rem', right: '2rem' }}
                  >
                    <Fab
                      size='small'
                      onClick={() =>
                        this.handleChangeRemoveAttachment(file.get('fileName'))
                      }
                    >
                      <DeleteIcon />
                    </Fab>
                  </div>
                )}
              </div>
            )
          })}
        <ConfirmationDialog
          open={attachmentDialog}
          onClose={this.handleCancelAttachmentActions}
          onConfirm={
            !newAttachment
              ? this.handleDeleteAttachment
              : this.handleSaveAttachment
          }
          confirmLabel={
            !newAttachment ? (
              <FormattedMessage
                id='FileAttachmentList.Delete'
                defaultMessage='Remove'
              />
            ) : (
              <FormattedMessage
                id='FileAttachmentList.UploadButton'
                defaultMessage='Upload'
              />
            )
          }
        >
          <Text>
            {removeAttachment && (
              <FormattedMessage
                id='FileAttachmentList.RemoveMessage'
                defaultMessage={
                  'Are you sure you want to remove this attachment?'
                }
              />
            )}
            {newAttachment && (
              <FormattedMessage
                id='FileAttachmentList.SaveMessage'
                defaultMessage='Do you want to upload <bold>{filename}</bold>?'
                values={{
                  bold: str => <b>{str}</b>,
                  filename: newAttachment.name
                }}
              />
            )}
          </Text>
          {newAttachment && newAttachment.name.endsWith('xlsx') && (
            <Text className='red fw8 mt3'>
              <FormattedMessage
                id='FileAttachmentList.Warning'
                defaultMessage='Are you sure you want to post this Excel file? You are responsible for ensuring that it is free of viruses.'
              />
            </Text>
          )}
        </ConfirmationDialog>
      </div>
    )
  }
}

export default FileAttachmentList
