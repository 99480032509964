import { put, select } from 'redux-saga/effects'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import { inviteToCommunities } from '../../actions'
import communitiesSelectors from '../../selectors/communitiesSelectors'
import Immutable, { fromJS } from 'immutable'
import analytics from 'shared/utils/analytics'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'

export function* batchInviteToCommunitiesSaga(action) {
  const { communities, supplierIds } = Immutable.isImmutable(action.payload)
    ? action.payload.toJS()
    : action.payload

  const orgUnitId = yield select(sessionSelectors.getOrgUnitId)
  const orgUnitName = yield select(orgsSelectors.getCurrentUserOrgUnitName)

  analytics.track('Supplier Added To Community', {
    eventSource: 'Supplier List',
    action: 'Added To Community',
    supplierIds: supplierIds,
    communitiesSelected: communities,
    orgUnitId,
    orgUnitName,
  })

  for (let i = 0; i < supplierIds.length; i++) {
    const supplierCommunityIds = yield select(
      communitiesSelectors.getBySupplierId,
      supplierIds[i]
    )
    let copyCommunities
    if (supplierCommunityIds) {
      copyCommunities = Object.assign({}, communities)
      supplierCommunityIds.toArray().forEach((commId) => {
        copyCommunities[commId] = false
      })
    }
    yield put(
      inviteToCommunities(
        fromJS({
          communities: copyCommunities || communities,
          supplierId: supplierIds[i],
        })
      )
    )
  }
}

export default createApiRequestSaga(batchInviteToCommunitiesSaga)
