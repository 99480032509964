import { put, select } from 'redux-saga/effects'
import searchSelectors from '../../selectors/searchSelectors'
import routingSelectors from 'shared/selectors/routingSelectors'
import settingsSelectors from 'buyer/shared/selectors/settingsSelectors'
import filterOptionsSelectors from '../../selectors/filterOptionsSelectors'
import { push } from 'connected-react-router'
import qs from 'qs'
import { List } from 'immutable'
import mergeDeepOverwriteLists from 'shared/utils/mergeDeepOverwriteLists'

const isMergeable = a =>
  a &&
  typeof a === 'object' &&
  typeof a.mergeWith === 'function' &&
  !List.isList(a)

export default function* updateQueryStringSaga(action) {
  const q = yield select(searchSelectors.getQ)
  const fixedFilters = yield select(settingsSelectors.getFixedFilters)
  const filter = yield select(filterOptionsSelectors.getFilterOptions)
  const scope = yield select(filterOptionsSelectors.getFilterScope)
  const sort = yield select(filterOptionsSelectors.getSort)
  const pathname = yield select(routingSelectors.getPathname)
  const attachments = filter.get('attachments')
  const includeUnQualified = yield select(
    filterOptionsSelectors.getByKey,
    'includeUnQualified'
  )
  const attachmentsExpiredExcluded = yield select(
    filterOptionsSelectors.getByKey,
    'attachmentsExpiredExcluded'
  )
  const similarSuppliers = yield select(
    filterOptionsSelectors.getByKey,
    'similarSuppliers'
  )
  const isSupplier = !pathname.includes('client')
  const updateIncludeUnQualified = (action.type === 'REMOVE_FILTER_OPTION' && !!includeUnQualified)
    ? includeUnQualified.filter(category => category !== action.payload.key)
    : includeUnQualified

  const queryString = {
    q,
    filter:
      isSupplier && isMergeable(filter) && isMergeable(fixedFilters)
        ? mergeDeepOverwriteLists(filter, fixedFilters).toJS()
        : filter.toJS(),
    sort: sort === 'Similarity' && !similarSuppliers ? 'Relevance' : sort,
    scope,
    includeUnQualified: (updateIncludeUnQualified && !!updateIncludeUnQualified.size) ? updateIncludeUnQualified.toJS() : undefined,
    attachmentsExpiredExcluded:
      attachments && attachments.size > 0 && attachmentsExpiredExcluded
        ? true
        : undefined,
    similarSuppliers:
      similarSuppliers && similarSuppliers.size > 0
        ? similarSuppliers && similarSuppliers.toJS()
        : undefined
  }
  yield put(push(`${pathname}?${qs.stringify(queryString)}`))
}
