import React from 'react'
import PageSection from 'shared/components/PageSection'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import RequestSustainabilitySurvey from '../RequestSustainabilitySurvey'
import surveySelectors from 'buyer/SupplierProfile/selectors/surveySelectors'
import SustainabilityQuickFacts from '../SustainabilityQuickFacts'
import useUserAccess from 'shared/utils/useUserAccess'
import { LicenseAccess } from 'buyer/shared/reducers/settingsReducer'

type Props = {
  supplierId: string
}

const SustainabilitySection = (props: Props) => {
  const survey = useSelector(surveySelectors.getEsgSurvey)
  const userAccess = useUserAccess() as LicenseAccess
  const attestedDate = survey?.get('attestedDate')

  return userAccess.accessSustainabilitySurveyReport ? (
    <PageSection
      title={
        <FormattedMessage
          id='SustainabilitySection.SustainabilityTitle'
          defaultMessage='Sustainability Survey'
        />
      }
    >
      {!attestedDate && <RequestSustainabilitySurvey />}
      {!!attestedDate && <SustainabilityQuickFacts />}
    </PageSection>
  ) : null
}

export default SustainabilitySection
