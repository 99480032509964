import React from 'react'


const DataQualityUploadInstructions = () => {
  return (
    <>
      <>DQ Upload Instructions</>
      <ol type="1">
        <li>Choose option "Vendor" to run just a vendor job or "Vendor and Spend" to run a vendor and spend job</li>
        <li> If "Vendor" was selected:
          <ol type="a">
            <li>Add your file by clicking on the input box or dragging your file over</li>
            <li>Once the file is added, the "Upload" button will change colors, please click on this to upload your file</li>
          </ol>
        </li>
        <li>If "Vendor and Spend" was selected:
          <ol>
            <li>Add your vendor file using the input box, then click on "Choose Spend File"</li>
            <li>Now add your spend file using the input box, then click on "Upload" once it changes colors</li>
          </ol>
        </li>
        <li>Please wait until you see the column mappings appear below. If you chose "Vendor and Spend" the spend column mapping will appear after you have completed the vendor column mapping</li>
        <li>To view results or if you refreshed the page after you clicked "Upload", access the "Results" page using the tab above to continue</li>
      </ol>
      
    </>
  )
}

export default DataQualityUploadInstructions