import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RootState from 'shared/models/RootState'
import dataSelectors from 'shared/selectors/dataSelectors'
import ReviewSpendItems from '../ReviewSpendItems'
import SummaryRow from '../../LoadSummary/SummaryRow'
import Button from 'shared/components/Button'
import { UploadHistoryTypeT2 } from './UploadSpendDetail'
import { RecordOf } from 'immutable'
import { FormattedMessage } from 'react-intl'
import { addTier2Buyer } from '../../../store/actions'
import Label from 'shared/components/Label'
import Paper from 'shared/components/Paper'
import Text from 'shared/components/Text'

type Props = {
  stagingId: string
  buyerId: string
}

const SuggestSpendLoad = ({ stagingId, buyerId }: Props) => {
  const dispatch = useDispatch()

  const staging:
    | undefined
    | RecordOf<UploadHistoryTypeT2> = useSelector((state: RootState) =>
    dataSelectors.getStagingById(state, stagingId)
  )
  const subCategories = staging?.get('subCategories')?.toJS() || []

  const handleUseThisInformationClick = () => {
    const year = staging?.get('year')
    const quarter = staging?.get('quarter')
    dispatch(addTier2Buyer({ stagingId, buyerId, year, quarter }))
  }

  return (
    <section className='mt3'>
      <Paper>
        {staging?.get('dataType') === 'aggregate' && (
          <Label>
            <FormattedMessage
              id='SuggestSpendLoad.SuggestTitleAgg'
              defaultMessage={'Aggregate spend data available for this quarter'}
            />
          </Label>
        )}
        {staging?.get('dataType') === 'file' && (
          <Label>
            <FormattedMessage
              id='SuggestSpendLoad.SuggestTitlefile'
              defaultMessage={'File spend data available for this quarter'}
            />
          </Label>
        )}
        <Text className='mt3'>
          <FormattedMessage
            id='SuggestSpendLoad.Suggest1'
            defaultMessage={
              'You’ve already uploaded spend data for {quarter}. Click {useInfo} to proceed and navigate to the Share tab to allocate spend amounts to this customer.'
            }
            values={{
              quarter: `${staging?.get('year')}-Q${staging?.get('quarter')}`,
              useInfo: <span className='b'>Use this information</span>,
              share: <span className='b'>Share</span>
            }}
          />
        </Text>
        {staging?.get('dataType') === 'file' && (
          <ReviewSpendItems
            stagingId={stagingId}
            entity='stagingRow'
            label={`${staging.get('year')}-Q${staging.get(
              'quarter'
            )} - ${staging.get('clientFileName')}`}
            suggestion
          />
        )}
        {staging?.get('dataType') === 'aggregate' && (
          <SummaryRow
            quarter={`${staging.get('year')}-Q${staging.get('quarter')}`}
            initalSubCategories={subCategories}
            isReadOnly
          />
        )}
      </Paper>
      <div className='mt3 flex justify-end'>
        <Button
          autoSize
          onClick={handleUseThisInformationClick}
          aria-label='Use this information'
        >
          <FormattedMessage
            id='SuggestSpendLoad.UseThisInformation'
            defaultMessage={'Use this information'}
          />
        </Button>
      </div>
    </section>
  )
}

export default SuggestSpendLoad
