import React, { Component, ReactNode, MouseEvent, KeyboardEvent } from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import classNames from 'classnames'

type Props = {
  onChange: (
    e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
  ) => void
  checked?: boolean
  label?: string | ReactNode
  input?: WrappedFieldInputProps
  name?: string
  labelRight?: boolean
  labelFontLight?: boolean
  disabled?: boolean
  ariaLabel?: string
  isFreezeOption?: boolean
}

class Switch extends Component<Props> {
  render() {
    const {
      input,
      checked,
      label,
      labelRight,
      labelFontLight,
      onChange,
      disabled,
      ariaLabel,
      isFreezeOption,
    } = this.props

    const switchClass =
      (!disabled && checked) || (input && input.value && !disabled)
        ? 'bg-teal'
        : 'bg-black-50'
    const dotClass = checked || (input && input.value) ? 'fr' : 'fl'
    const labelClass = `${labelFontLight ? 'fw4' : 'fw6'} f7 dib v-mid ${
      labelRight ? 'ml2' : 'mr2'
    }`

    return (
      <div className='dib'>
        {label && !labelRight && <div className={labelClass}>{label}</div>}
        <button
          role='switch'
          type='button'
          aria-checked={checked ? checked : false}
          aria-label={ariaLabel ? ariaLabel : 'switch'}
          aria-disabled={disabled}
          onClick={
            disabled
              ? undefined
              : input
              ? () => input.onChange(!input.value)
              : onChange
          }
          className={
            isFreezeOption
              ? classNames(
                  `v-mid relative w2 pa1 ml3-l br-pill dib v-mid bn`,
                  !disabled && `pointer`,
                  switchClass
                )
              : classNames(
                  `v-mid relative w2 pa1 br-pill dib v-mid bn`,
                  !disabled && `pointer`,
                  switchClass
                )
          }
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !disabled) {
              input ? input.onChange(!input.value) : onChange(e)
            }
          }}
        >
          <div
            className={`br-100 bg-white ${dotClass}`}
            style={{ width: '0.75rem', height: '0.75rem' }}
            aria-hidden='true'
          ></div>
        </button>
        {label && labelRight && <div className={labelClass}>{label}</div>}
      </div>
    )
  }
}

export default Switch
