import { createAction } from 'redux-actions'
import * as actionTypes from './actionTypes'

export const searchForOrganization = createAction(
  actionTypes.SEARCH_FOR_ORGANIZATION
)
export const changeUserEmail = createAction(actionTypes.CHANGE_USER_EMAIL)
export const editUserEmail = createAction(actionTypes.EDIT_USER_EMAIL)
export const connectUserToOrg = createAction(actionTypes.CONNECT_USER_TO_ORG)
export const startCreatingOrg = createAction(actionTypes.START_CREATING_ORG)
export const stopCreatingOrg = createAction(actionTypes.STOP_CREATING_ORG)
export const becomeTealbot = createAction(
  actionTypes.BECOME_TEALBOT,
  payload => payload,
  ({ redirectTo }) => ({ redirectTo })
)

export const makeSupplierABuyer = createAction(
  actionTypes.MAKE_SUPPLIER_A_BUYER
)

export const startConnectingUserToOrg = createAction(
  actionTypes.START_CONNECTING_USER_TO_ORG
)
export const stopConnectingUserToOrg = createAction(
  actionTypes.STOP_CONNECTING_USER_TO_ORG
)
export const addContact = createAction(actionTypes.ADD_CONTACT)
export const removeContact = createAction(actionTypes.REMOVE_CONTACT)
export const selectSuggestedUser = createAction(
  actionTypes.SELECT_SUGGESTED_USER
)

export const deleteSupplier = createAction(actionTypes.DELETE_SUPPLIER)
export const dropIndex = createAction(actionTypes.DROP_INDEX)
export const reindexSuppliers = createAction(actionTypes.REINDEX_SUPPLIERS)
export const reindexSomeSuppliers = createAction(actionTypes.REINDEX_SOME_SUPPLIERS)
export const updateMapping = createAction(actionTypes.UPDATE_MAPPING)
export const reindexUsers = createAction(actionTypes.REINDEX_USERS)
export const addUserMapping = createAction(actionTypes.ADD_USER_MAPPING)
export const backupDatastore = createAction(actionTypes.BACKUP_DATASTORE)
export const flushCache = createAction(actionTypes.FLUSH_CACHE)
export const runPatch = createAction(actionTypes.RUN_PATCH)
export const syncDiversity = createAction(actionTypes.SYNC_DIVERSITY)

export const joinCommunity = createAction(actionTypes.JOIN_COMMUNITY)
export const moveSupplier = createAction(actionTypes.MOVE_SUPPLIER)
export const removeFromCommunity = createAction(
  actionTypes.REMOVE_FROM_COMMUNITY
)
export const getReasonKey = createAction(actionTypes.GET_REASON_KEY)
export const closeLoginDialog = createAction(actionTypes.CLOSE_LOGIN_DIALOG)

export const uploadDataQualityFile = createAction(
  actionTypes.UPLOAD_DATA_QUALITY_FILE
)
export const getDataQualityJobs = createAction(
  actionTypes.GET_DATA_QUALITY_JOBS
)
export const confirmColumnMapping = createAction(
  actionTypes.CONFIRM_COLUMNS_MAPPING
)

export const getDataQualityJobStatus = createAction(actionTypes.GET_JOB_STATUS)

export const clearDataQualityJobStatus = createAction(
  actionTypes.CLEAR_JOB_STATUS
)

export const resetDataQualityState = createAction(
  actionTypes.RESET_JOB_STATE
)
