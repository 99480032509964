import React, { useState } from 'react'
import Button from 'shared/components/Button'
import { FormattedMessage } from 'react-intl'
import { RecordOf, List } from 'immutable'
import {
  AttachmentType,
  CertType,
  ValidationType
} from 'supplier/Survey/reducers/surveyReducer/surveyReducer'
import VerifyAttachmentDialog from './VerifyAttachmentDialog'
import { useSelector, useDispatch } from 'react-redux'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import { openVerifyESGAttachmentDialog } from '../../actions'
import Popover from '@material-ui/core/Popover'
import Avatar from 'shared/components/Avatar'
import ListItem from 'shared/components/List/ListItem'
import Label from 'shared/components/Label'
import Text from 'shared/components/Text'
import dateFormat from 'shared/utils/dateFormat'
import User from 'shared/models/User'
import supplierRelationshipsSelectors from '../../../shared/selectors/supplierRelationshipsSelectors'

type Props = {
  surveyResponseDetailId: string
  attachmentType: 'cert' | 'doc'
  attachment: RecordOf<AttachmentType> | RecordOf<CertType>
}

const VerifyAttachment = (props: Props) => {
  const dispatch = useDispatch()
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const users = useSelector(usersSelectors.getUsers)
  const certificationValidation = useSelector(supplierRelationshipsSelectors.getCertificationValidation)

  const { surveyResponseDetailId, attachmentType, attachment } = props
  const [popover, setPopover] = useState<{
    open: boolean
    anchorEl: any
  }>({
    open: false,
    anchorEl: null
  })

  let validations: List<RecordOf<ValidationType>> | undefined
  if (attachmentType === 'cert') {
    validations = (attachment as RecordOf<CertType>).get('validations')

    // use validations from supplier profile as source of truth from my company
    const buyerValidations = certificationValidation && certificationValidation.get(`${attachment.get('category', '')}-${attachment.get('subCategory', '')}-${attachment.get('timeStamp', '') || ''}`)
    // filter my company validations out from validations
    validations = validations?.filter(v => v.get('orgUnitId') !== orgUnitId)
    // concat validations with buyerValidations
    if (buyerValidations?.size > 0) {
      validations = validations 
        ? buyerValidations.map(v => v.set('orgUnitId', orgUnitId)).concat(validations) 
        : buyerValidations.map(v => v.set('orgUnitId', orgUnitId))
    }
    // sort validations by date
    validations = validations?.sort((v1, v2) => {
      return new Date(v1.get('date')) > new Date(v2.get('date'))
        ? 1
        : -1
    })
  } else {
    validations = (attachment as RecordOf<AttachmentType>).get('validations')
  }

  const validatedByMyCompany = validations?.filter(
    v => v.get('orgUnitId') === orgUnitId
  )
  const numConfirmed = validations?.filter(v => v.get('confirmed')).size || 0
  const numInvalid = validations?.filter(v => !v.get('confirmed')).size || 0

  const latestValidation: RecordOf<ValidationType> | undefined =
    validatedByMyCompany?.last() || validations?.last()
  let confirmed
  if (
    validatedByMyCompany &&
    validatedByMyCompany.size > 0 &&
    latestValidation
  ) {
    confirmed = latestValidation.get('confirmed')
  } else if (numConfirmed || numInvalid) {
    confirmed = numConfirmed >= numInvalid
  }

  const confirmedByColleague: boolean =
    orgUnitId === latestValidation?.get('orgUnitId')
  const confirmedBy: RecordOf<User> | undefined = users.get(
    latestValidation?.get('userId') || ''
  )

  const handleVerifyClick = () => {
    let key
    if (attachmentType === 'cert') {
      key = `${surveyResponseDetailId}-${(attachment as RecordOf<CertType>).get('timeStamp')}`
    } else {
      key = `${surveyResponseDetailId}-${(attachment as RecordOf<AttachmentType>).get('docType') || ''}-${(attachment as RecordOf<AttachmentType>).get('fileName')}`
    }
    dispatch(openVerifyESGAttachmentDialog(key))
  }

  const togglePopover = (e?) => {
    setPopover(po => ({
      anchorEl: e ? e.currentTarget : null,
      open: !po.open
    }))
  }

  const generatePopoverText = () => {
    if (confirmedByColleague && confirmed) {
      return (
        <FormattedMessage
          id='VerifyAttachment.userVerifiedDate'
          defaultMessage={`Verified {date}`}
          values={{ date: dateFormat(latestValidation?.get('timeStamp')) }}
        />
      )
    } else if (confirmedByColleague && !confirmed) {
      return (
        <FormattedMessage
          id='VerifyAttachment.userInvalidDate'
          defaultMessage={`Verified Invalid {date}`}
          values={{ date: dateFormat(latestValidation?.get('timeStamp')) }}
        />
      )
    } else if (!confirmedByColleague && confirmed) {
      return (
        <FormattedMessage
          id='VerifyAttachment.Verified'
          defaultMessage={`Verified`}
        />
      )
    } else if (!confirmedByColleague && !confirmed) {
      return (
        <FormattedMessage
          id='VerifyAttachment.Invalid'
          defaultMessage={`Invalid`}
        />
      )
    }
  }

  return (
    <div>
      {confirmed === true && (
        <Button
          onClick={togglePopover}
          autoSize
          size='small'
          className='mt1'
          label={
            <FormattedMessage
              id='ValidationContainer.verified'
              defaultMessage='Verified...'
            />
          }
        />
      )}
      {confirmed === false && (
        <Button
          onClick={togglePopover}
          caution
          autoSize
          size='small'
          className='mt1'
          label={
            <FormattedMessage
              id='ValidationContainer.invalid'
              defaultMessage='Invalid...'
            />
          }
        />
      )}
      {confirmed === undefined && (
        <Button variant='text' onClick={handleVerifyClick}>
          <FormattedMessage
            id='VerifyAttachment.CanYouVerifyThis'
            defaultMessage='Can you verify this?'
          />
        </Button>
      )}

      <VerifyAttachmentDialog
        surveyResponseDetailId={surveyResponseDetailId}
        attachment={attachment}
        attachmentType={attachmentType}
      />
      {validations && validations.size > 0 && (
        <Popover
          open={popover.open}
          anchorEl={popover.anchorEl}
          onClose={togglePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <div style={{ minWidth: 250 }}>
            <ListItem
              className='pa3 pb0'
              mini
              leftAvatar={
                <Avatar
                  url={
                    confirmedByColleague
                      ? confirmedBy?.get('profilePictureUrl')
                      : ''
                  }
                  className='w2'
                  name={
                    confirmedByColleague
                      ? `${confirmedBy?.get('firstName') ||
                          ''} ${confirmedBy?.get('lastName') || ''}`
                      : ''
                  }
                />
              }
              primaryText={
                <span>
                  {confirmedByColleague ? (
                    `${confirmedBy?.get('firstName') || ''} ${confirmedBy?.get(
                      'lastName'
                    ) || ''}`
                  ) : (
                    <FormattedMessage
                      id='VerifyAttachment.anonymous'
                      defaultMessage={`Anonymous Votes`}
                    />
                  )}
                </span>
              }
              secondaryText={generatePopoverText()}
            />
            <div className='ph3 nmt2'>
              {confirmed && latestValidation?.get('certificationNumber') && (
                <>
                  <Label>
                    <FormattedMessage
                      id='VerifyAttachment.certificationNumberLabel'
                      defaultMessage={`Certificate Number`}
                    />
                  </Label>
                  <Text>{latestValidation?.get('certificationNumber')}</Text>
                </>
              )}
              {confirmed && latestValidation?.get('validationExpires') && (
                <>
                  <Label>
                    <FormattedMessage
                      id='VerifyAttachment.validationExpiresLabel'
                      defaultMessage={`Valid Until`}
                    />
                  </Label>
                  <Text>
                    {dateFormat(latestValidation?.get('validationExpires'))}
                  </Text>
                </>
              )}
              {latestValidation?.get('note') && (
                <>
                  <Label>
                    <FormattedMessage
                      id='VerifyAttachment.noteLabel'
                      defaultMessage={`Note`}
                    />
                  </Label>
                  <Text>{latestValidation?.get('note')}</Text>
                </>
              )}
            </div>
            <div className='ph3 pv2 bt b--light-gray tr mt2'>
              <Button
                size='small'
                onClick={() => {
                  togglePopover()
                  handleVerifyClick()
                }}
                autoSize
                label={
                  <FormattedMessage
                    id='VerifyAttachment.addNew'
                    defaultMessage='Add New'
                  />
                }
              />
            </div>
          </div>
        </Popover>
      )}
    </div>
  )
}

export default VerifyAttachment
